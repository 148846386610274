<template>
  <v-card>
    <v-card-title>
      {{ $t('text.segmentSetting.search') }}<span class="caption">&nbsp;&#040;{{ $t('text.segmentSetting.announce')}}&#041;</span>
    </v-card-title>
    <v-card-text>
      <!-- セグメント基本情報 -->
      <v-row v-if="isSearchingUsers" :justify="'center'" class="fill-height ma-3">
        <v-progress-circular indeterminate color="primary"/>
      </v-row>
      <v-row v-else>
        <!-- セグメント該当ユーザー -->
        <v-col cols="5">
          <div v-if="isScv" class="caption pb-1">カスタマー</div>
          <div v-else-if="isUnknown" class="caption pb-1">匿名客</div>
          <v-card outlined tile height="138px">
            <v-card-text class="text-left px-3 pt-3 pb-0">
              <v-icon v-if="isScv" color="primary">ic-customer-M</v-icon>
              <v-icon v-else-if="isUnknown">ic-anonymous-M</v-icon>
              {{ segmentUserTotal }}{{ $t(`text.unitHuman`) }}
            </v-card-text>
            <v-card-text class="text-center pt-0">
              <segment-user-circle-chart
                :isUnknown="isUnknown"
                :isScv="isScv"
                :count="segmentUserTotal"
                :total="userTotal" />
            </v-card-text>
          </v-card>
        </v-col>
        <!-- セグメント属性(期間、フィルタ条件) -->
        <v-col cols="7">
          <div class="caption pb-1">条件</div>
          <segment-config-list style="height: 138px; overflow: auto;"
            :filterTextObject="filterTextObject"
            :buildType="buildType"/>
        </v-col>
      </v-row>
      <!-- セグメントユーザー -->
      <v-row>
        <!-- セグメントユーザーリスト -->
        <v-col cols="12">
          <result-user-list
            :isSearching="isSearchingUsers"
            :isUnknown="isUnknown"
            :isScv="isScv"
            :userList="userList"
            @clickList="selectedUserId = $event" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-center pt-1 pb-7">
      <v-btn color="denial" @click="$emit('close')">{{ $t('btn.cancel') }}</v-btn>
    </v-card-actions>
    <!-- セグメントユーザー詳細 -->
    <v-navigation-drawer
      v-model="showUserDetail"
      width="80%"
      app
      fixed
      hide-overlay
      temporary
      right
    >
      <information-bar>
        <template #left>
          <v-btn @click="showUserDetail = false">
            {{ $t(`btn.close`) }}
          </v-btn>
        </template>
        <template #right v-if="searchCondition.userType === USER_TYPES.USER_UNKNOWN.value">
          <v-btn :to="{ name: 'UserUnknownDetail', params: { unknownId: selectedUserId } }">{{ $t('btn.open') }}</v-btn>
          <v-btn class="mx-5" icon x-small :to="{ name: 'UserUnknownDetail', params: { unknownId: selectedUserId } }" target="_blank"><v-icon>mdi-open-in-new</v-icon></v-btn>
        </template>
        <template #right v-else-if="searchCondition.userType === USER_TYPES.SCV.value">
          <v-btn :to="{ name: 'UserScvDetail', params: { scvId: selectedUserId } }">{{ $t('btn.open') }}</v-btn>
          <v-btn class="mx-5" icon x-small :to="{ name: 'UserScvDetail', params: { scvId: selectedUserId } }" target="_blank"><v-icon>mdi-open-in-new</v-icon></v-btn>
        </template>
      </information-bar>
      <div v-if="showUserDetail">
        <!-- 匿名客 -->
        <user-unknown-detail
          v-if="searchCondition.userType === USER_TYPES.USER_UNKNOWN.value"
          :unknownId="selectedUserId"
        />
        <!-- SCV -->
        <user-scv-detail
          v-else-if="searchCondition.userType === USER_TYPES.SCV.value"
          :scvId="selectedUserId"
        />
      </div>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
// component
import userCircleChart from '@/components/public/Segment/segmentUserCircleChart'
import resultUserList from './resultComponents/userList.vue'
import segmentConfigList from '@/components/public/Segment/segmentConfigList'
import userScvDetail from '@/views/userScvDetail'
import userUnknownDetail from '@/views/userUnknownDetail'
// enum
import USER_TYPES from '@/enum/USER_TYPES'
// util
import displayConverter from '@/utils/displayConverter'
import enumUtil from '@/utils/enumUtil'
import segmentTexts from '@/utils/segmentTexts'
import notifyUtil from '@/utils/notifyUtil'
import segmentFilterUtil from '@/utils/segmentFilterUtil'
// plugin
import moment from 'moment-timezone'
moment.updateLocale('ja', {
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
})

const RATIO = 100 // 百分率計算用

export default {
  components: {
    'result-user-list': resultUserList,
    'segment-user-circle-chart': userCircleChart,
    'segment-config-list': segmentConfigList,
    'user-scv-detail': userScvDetail,
    'user-unknown-detail': userUnknownDetail,
  },
  mixins: [
    USER_TYPES,
    displayConverter,
    enumUtil,
    segmentTexts,
    notifyUtil,
    segmentFilterUtil,
  ],
  props: {
    /** 初回検索済みフラグ */
    isSearched: {
      type: Boolean,
      default: false,
    },
    /** 現在選択中のセグメント構築種別 */
    buildType: {
      type: Number,
      default: 1,
    },
    /** 検索時の条件 */
    searchCondition: {
      type: Object,
    },
    /** ユーザー検索中フラグ */
    isSearchingUsers: {
      type: Boolean,
      default: true,
    },
    /** セグメント実績データ */
    segmentSummaryData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    /** ユーザー一覧 */
    userList: {
      type: Array,
      default: () => { return [] },
    },
    /** ユーザー総数 */
    userTotal: {
      type: Number,
      default: 0,
    },
    /** セグメントに紐付くユーザー総数 */
    segmentUserTotal: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      /**
       * 選択中のユーザーID
       * @type {Number}
       */
      selectedUserId: null,
    }
  },
  computed: {
    filterTextObject () {
      if (this.searchCondition.filterRuleJson) {
        return this.createSegmentFilterText(this.searchCondition.filterRuleJson)
      }
      return {
        logicalOperatorType: '',
        filterTextList: [],
      }
    },
    isUnknown () {
      return this.searchCondition.userType === this.USER_TYPES.USER_UNKNOWN.value
    },
    isScv () {
      return this.searchCondition.userType === this.USER_TYPES.SCV.value
    },
    /**
     * 匿名客/顧客総数に対するセグメント匿名客/顧客数の割合
     * @type {Number}
     */
    totalRatio () {
      return this.userTotal ? (this.segmentUserTotal / this.userTotal * RATIO) : 0
    },
    showUserDetail: {
      get () {
        return this.selectedUserId !== null
      },
      set (newVal) {
        if (newVal === false) this.selectedUserId = null
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.v-sheet--outlined {
  height: 100%;
  .v-progress-circular {
    .v-progress-circular__info {
      font-size: 17px;
    }
  }
}
.summary_header {
  margin: 10px;
}
.user-list {
  max-height: 46vh;
}
</style>
