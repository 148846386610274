<template>
  <v-data-table
    :headers="tableHeaders"
    hide-default-footer
    :items="dataSourceImportHistories"
    :loading="loading"
    disable-pagination
    disable-sort
  >
    <template #item.csvImportType="{ item }">
      <span v-if="item.csvImportType === CSV_IMPORT_TYPE.REPLACEMENT.value">
        {{ CSV_IMPORT_TYPE.REPLACEMENT.text }}
      </span>
      <span v-else-if="item.csvImportType === CSV_IMPORT_TYPE.DIFFERENCE.value">
        {{ CSV_IMPORT_TYPE.DIFFERENCE.text }}
      </span>
    </template>
    <template #item.targetFilesJson="{ item }">
      <div v-for="(fileName, index) in splitComma(item.targetFilesJson)" :key="item.dataSourcePeriodicImportHistoryId + index">
        {{fileName}}
      </div>
    </template>
    <template #item.dataSourceImportStatus="{ item }">
      <v-chip v-if="item.dataSourceImportStatus === EXEC_STATUS.PROCESSING.value" color="ongoing">
        <span>{{ EXEC_STATUS.PROCESSING.text }}</span>
      </v-chip>
      <v-chip v-else-if="item.dataSourceImportStatus === EXEC_STATUS.SUCCESS.value" color="success">
        <span>&nbsp;&nbsp;{{ EXEC_STATUS.SUCCESS.text }}&nbsp;&nbsp;</span>
      </v-chip>
      <v-chip v-else-if="item.dataSourceImportStatus === EXEC_STATUS.FAILED.value" color="fault">
        <span>&nbsp;&nbsp;{{ EXEC_STATUS.FAILED.text }}&nbsp;&nbsp;</span>
      </v-chip>
    </template>
    <template #item.errorMessage="{ item }">
      <v-tooltip v-if="item.errorMessage" bottom>
        <template #activator="{ on }">
          <span v-on="on">
            {{ item.errorMessage | ellipsis(20,'...') }}
          </span>
        </template>
        <span class="break-all">
          {{ item.errorMessage }}
        </span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
// enum
import EXEC_STATUS from '@/enum/EXEC_STATUS'
import CSV_IMPORT_TYPE from '@/enum/CSV_IMPORT_TYPE'
// util
import enumUtil from '@/utils/enumUtil'

export default {
  name: 'dataSourceImportHistoryTable',
  mixins: [
    EXEC_STATUS,
    CSV_IMPORT_TYPE,
    enumUtil,
  ],
  props: {
    dataSourceImportHistories: {
      type: Array,
      default () {
        return []
      },
    },
    loading: {
      type: Boolean,
      default () {
        return false
      },
    },
  },
  data () {
    return {
      tableHeaders: [
        /* 取り込み種別の判定が正しくできていないため、暫定対応として画面上非表示にする
        {
          text: this.$t('table.dataSourceImportHistories.column1'),
          value: 'csvImportType',
          width: '10%',
        },
        */
        {
          text: this.$t('table.dataSourceImportHistories.column3'),
          value: 'targetFilesJson',
          width: '30%',
        },
        {
          text: this.$t('table.dataSourceImportHistories.column4'),
          value: 'dataSourceImportStatus',
          width: '7%',
        },
        {
          text: this.$t('table.dataSourceImportHistories.column5'),
          value: 'startDatetime',
          width: '13%',
        },
        {
          text: this.$t('table.dataSourceImportHistories.column6'),
          value: 'endDatetime',
          width: '13%',
        },
        {
          text: this.$t('table.dataSourceImportHistories.column7'),
          value: 'errorMessage',
          width: '18%',
        },
      ],
    }
  },
  methods: {
    splitComma (inputFileName) {
      return inputFileName?.split(',')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
</style>
