export default {
  data () {
    return {
      DATE_INTERVAL_TYPES: {
        ONE_DAY: { text: '1日', value: 1 },
        SEVEN_DAYS: { text: '7日', value: 2 },
        THIRTY_DAYS: { text: '30日', value: 3 },
        NINETY_DAYS: { text: '90日', value: 4 },
      },
    }
  },
}
