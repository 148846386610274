import axios from '@/axios'

const accountRole = {
  namespaced: true,
  state: {
    loadingAccountRoles: false,
    creatingAccountRole: false,
    updatingAccountRole: false,
    deletingAccountRole: false,
  },
  mutations: {
    setLoadingAccountRoles (state, newVal) {
      state.loadingAccountRoles = newVal
    },
    setCreatingAccountRole (state, newVal) {
      state.creatingAccountRole = newVal
    },
    setUpdatingAccountRole (state, newVal) {
      state.updatingAccountRole = newVal
    },
    setDeletingAccountRole (state, newVal) {
      state.deletingAccountRole = newVal
    },
  },
  actions: {
    async getAccountRoles ({ commit }) {
      commit('setLoadingAccountRoles', true)
      return axios.get('/role/')
        .finally(() => {
          commit('setLoadingAccountRoles', false)
        })
    },
    async getAccountRole ({ commit }, roleId) {
      return axios.get('/role/' + roleId + '/')
    },
    async createRole ({ commit }, requestParameter) {
      commit('setCreatingAccountRole', true)
      return axios.post('/role/', {
        roleName: requestParameter.roleName,
        note: requestParameter.note,
        authority: requestParameter.authority,
      }).finally(() => {
        commit('setCreatingAccountRole', false)
      })
    },
    async updateRole ({ commit }, requestParameter) {
      commit('setUpdatingAccountRole', true)
      return axios.put('/role/' + requestParameter.roleId + '/', {
        roleName: requestParameter.roleName,
        note: requestParameter.note,
        authority: requestParameter.authority,
      }).finally(() => {
        commit('setUpdatingAccountRole', false)
      })
    },
    async deleteRole ({ commit }, roleId) {
      commit('setDeletingAccountRole', true)
      await axios.delete('/role/' + roleId + '/')
        .finally(() => {
          commit('setDeletingAccountRole', false)
        })
    },
    async getRoleAccounts ({ commit }, roleId) {
      return axios.get('/role/' + roleId + '/accounts/')
    },
  },
}
export default accountRole
