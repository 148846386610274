var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataSource.connectorType === _vm.CONNECTOR_TYPES.CSV.value || _vm.dataSource.connectorType === _vm.CONNECTOR_TYPES.API.value)?[_c('form',{attrs:{"data-vv-scope":"schema"},on:{"submit":function($event){$event.preventDefault();return _vm.onSaveSchemaClick()}}},[(_vm.dataSource.dataSourceId)?_c('v-data-table',{attrs:{"headers":_vm.schemaTableHeaders,"items":_vm.headerMetaColumns,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","depressed":"","x-small":"","color":"denial","disabled":item.index === _vm.userIdentifyColumn || item.index === _vm.datetimeIdentifyColumn || _vm.transactionIdentifierColumn.includes(item.index)},on:{"click":function($event){return _vm.onClickRemoveSchemaColumnButton(item.index)}}},on),[_c('v-icon',[_vm._v("ic-close-S")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])])]}},{key:"item.key",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{attrs:{"disabled":_vm.dataSource.userIdentifyColumn > 0,"hide-details":""},model:{value:(_vm.userIdentifyColumn),callback:function ($$v) {_vm.userIdentifyColumn=_vm._n($$v)},expression:"userIdentifyColumn"}},[_c('v-radio',{attrs:{"value":Number(item.index),"color":"inputSelectionControl"}})],1)]}},{key:"item.datetimeIdentifyColumn",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{attrs:{"disabled":_vm.dataSource.datetimeIdentifyColumn > 0,"hide-details":""},model:{value:(_vm.datetimeIdentifyColumn),callback:function ($$v) {_vm.datetimeIdentifyColumn=_vm._n($$v)},expression:"datetimeIdentifyColumn"}},[_c('v-radio',{attrs:{"value":Number(item.index),"color":"inputSelectionControl"}})],1)]}},{key:"item.transactionIdentifierColumn",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"pb-2",attrs:{"disabled":(_vm.dataSource.transactionIdentifierColumn || {}).length > 0,"hide-details":"","value":item.index,"color":"inputSelectionControl"},model:{value:(_vm.transactionIdentifierColumn),callback:function ($$v) {_vm.transactionIdentifierColumn=$$v},expression:"transactionIdentifierColumn"}})]}},{key:"item.logicalName",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"dense":"","hide-details":"auto","name":'logicalName[' + item.index + ']',"data-vv-as":_vm.$t("form.dataSource.columnName"),"error-messages":_vm.errors.collect('schema.logicalName[' + item.index + ']')},model:{value:(item.logicalName),callback:function ($$v) {_vm.$set(item, "logicalName", $$v)},expression:"item.logicalName"}})]}},{key:"item.physicalName",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required|dataSourceSchemaPhysicalNameSyntax'),expression:"'required|dataSourceSchemaPhysicalNameSyntax'"}],attrs:{"dense":"","hide-details":"auto","name":'physicalName[' + item.index + ']',"data-vv-as":_vm.$t("form.dataSource.columnName"),"error-messages":_vm.errors.collect('schema.physicalName[' + item.index + ']')},model:{value:(item.physicalName),callback:function ($$v) {_vm.$set(item, "physicalName", $$v)},expression:"item.physicalName"}})]}},{key:"item.dataType",fn:function(ref){
var item = ref.item;
return [_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"dense":"","hide-details":"auto","items":_vm.dataTypes,"item-text":"label","item-value":"value","name":'dataType[' + item.index + ']',"data-vv-as":_vm.$t("form.dataType"),"error-messages":_vm.errors.collect('schema.dataType[' + item.index + ']')},model:{value:(item.dataType),callback:function ($$v) {_vm.$set(item, "dataType", $$v)},expression:"item.dataType"}})]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":_vm.schemaTableHeaders.length}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","depressed":"","disabled":_vm.disabledUpdateSchema},on:{"click":_vm.onClickAddSchemaColumnButton}},on),[_c('v-icon',[_vm._v("ic-add")])],1)]}}],null,false,2683971359)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.add')))])])],1)])]},proxy:true}],null,false,414955560)}):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"denial"},on:{"click":_vm.onCancelClick}},[_vm._v(_vm._s(_vm.$t('btn.cancel')))]),_c('v-btn',{attrs:{"type":"submit","color":"primary","disabled":_vm.disabledUpdateSchema}},[_vm._v(_vm._s(_vm.$t('btn.update')))]),_c('v-spacer')],1)],1)]:(_vm.dataSource.connectorType === _vm.CONNECTOR_TYPES.WEB.value ||
      _vm.dataSource.connectorType === _vm.CONNECTOR_TYPES.ANDROID.value ||
      _vm.dataSource.connectorType === _vm.CONNECTOR_TYPES.IOS.value)?[_c('v-data-table',{attrs:{"headers":_vm.schemaTableHeaders,"items":_vm.webMasterHeader,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.key",fn:function(ref){
      var item = ref.item;
return [_c('v-radio-group',{attrs:{"value":_vm.WEB_IDENTIFY_COLUMN_INDEX,"disabled":"","hide-details":""}},[_c('v-radio',{attrs:{"disabled":"","value":Number(item.index),"color":"inputSelectionControl"}})],1)]}},{key:"item.dataType",fn:function(ref){
      var item = ref.item;
return [_c('v-select',{attrs:{"readonly":"","dense":"","hide-details":"","items":_vm.dataTypes,"item-text":"label","item-value":"value"},model:{value:(item.dataType),callback:function ($$v) {_vm.$set(item, "dataType", $$v)},expression:"item.dataType"}})]}}])})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }