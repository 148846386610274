export default {
  data () {
    return {
      DATA_SOURCE_TYPES: {
        MASTER: { text: '顧客マスタ', value: 1, icon: 'mdi-card-account-details-outline' },
        TRANSACTION: { text: 'トランザクション', value: 2, icon: 'mdi-cart-outline' },
      },
    }
  },
}
