<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>デフォルト</v-card-title>
          <v-card-text>text contents</v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined>
          <v-card-title>outlined</v-card-title>
          <v-card-text>text contents</v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card raised>
          <v-card-title>raised</v-card-title>
          <v-card-text>text contents</v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat>
          <v-card-title>flat</v-card-title>
          <v-card-text>text contents</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Cards',
}
</script>
