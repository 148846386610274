<template>
  <v-container fluid>
    <v-row>
      <v-col class="text-right mb-2">
        <v-btn
          color="primary"
          :disabled="isInProcessCreation || checkingInProcessCreation"
          :loading="loadingScvSettings || loadingTransactionSummaries"
          @click="openDialog = true"
        >
          新規CSVファイル作成
        </v-btn>
        <v-btn class="ml-5" @click="getCsvDownloadHistory()"><v-icon>ic-reload</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-data-table
      class="mb-8"
      disable-filtering
      disable-pagination
      disable-sort
      hide-default-footer
      :headers="tableHeaders"
      :items="csvDownloadHistory"
      :loading="loadingCsvDownloadHistory"
      fixed-header>
      <template #item.execStatus="{ item }">
        <v-chip readonly v-if="item.execStatus === CSV_DOWNLOAD_REQUEST_EXEC_STATUS.WAITING.value" color="pending">
          {{ enumUtil.getEnumFromValue(CSV_DOWNLOAD_REQUEST_EXEC_STATUS, item.execStatus).text }}
        </v-chip>
        <v-chip readonly v-else-if="item.execStatus === CSV_DOWNLOAD_REQUEST_EXEC_STATUS.PROCESSING.value" color="ongoing">
          {{ enumUtil.getEnumFromValue(CSV_DOWNLOAD_REQUEST_EXEC_STATUS, item.execStatus).text }}
        </v-chip>
        <v-chip readonly v-else-if="item.execStatus === CSV_DOWNLOAD_REQUEST_EXEC_STATUS.SUCCESS.value" color="success">
          {{ enumUtil.getEnumFromValue(CSV_DOWNLOAD_REQUEST_EXEC_STATUS, item.execStatus).text }}
        </v-chip>
        <v-chip readonly v-else-if="item.execStatus === CSV_DOWNLOAD_REQUEST_EXEC_STATUS.FAILED.value" color="fault">
          {{ enumUtil.getEnumFromValue(CSV_DOWNLOAD_REQUEST_EXEC_STATUS, item.execStatus).text }}
        </v-chip>
      </template>
      <template #item.csvDownloadEnabled="{ item }">
        <div v-if="item.csvDownloadEnabled">
          <v-btn
            fab
            depressed
            x-small
            color="denial"
            @click="deleteCsvFile(item.segmentCustomerCsvRequestHistoryId)"
          >
            <v-icon>ic-close-S</v-icon>
          </v-btn>
          <v-btn
            small
            target="_blank"
            :href="downloadCsvFile(item.segmentCustomerCsvRequestHistoryId)"
          >ダウンロード</v-btn>
        </div>
      </template>
    </v-data-table>
    <v-row align="center" justify="space-between" class="mt-0">
      <v-col offset="4" cols="4">
        <v-pagination :value="page" @input="changePage" :length="pages" total-visible="7" :disabled="loadingCsvDownloadHistory" />
      </v-col>
    </v-row>
    <column-choice
      :openDialog="openDialog"
      :init-columns="initColumns"
      :segmentId="segmentId"
      @switchDialog="openDialog = $event"
      @reloadCsvDownloadHistory="reloadCsvDownloadHistory()"
    />
  </v-container>
</template>

<script>
// enum
import CSV_DOWNLOAD_REQUEST_EXEC_STATUS from '@/enum/CSV_DOWNLOAD_REQUEST_EXEC_STATUS'
// util
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'
// components
import columnChoice from '@/components/SegmentDetail/columnChoice'

const DEFAULT_LIMIT = 10

export default {
  name: 'innerCsvDownload',
  mixins: [
    CSV_DOWNLOAD_REQUEST_EXEC_STATUS,
    enumUtil,
    notifyUtil,
  ],
  components: {
    columnChoice,
  },
  data () {
    return {
      tableHeaders: [
        {
          text: 'リクエスト日時',
          value: 'startDatetime',
          width: '20%',
        },
        {
          text: 'ダウンロード準備終了日時',
          value: 'endDatetime',
          width: '20%',
        },
        {
          text: '結果',
          value: 'execStatus',
          width: '10%',
        },
        {
          text: 'エラーメッセージ',
          value: 'errorMessage',
          width: '30%',
          sortable: false,
        },
        {
          text: 'ダウンロード',
          value: 'csvDownloadEnabled',
          width: '20%',
        },
      ],
      csvDownloadHistory: [],
      segmentId: Number(this.$route.params.segmentId),
      page: 1,
      pages: 1,
      isInProcessCreation: false,
      openDialog: false,
      initColumns: [],
    }
  },
  computed: {
    loadingCsvDownloadHistory () {
      return this.$store.state.segments.loadingCsvDownloadHistory
    },
    checkingInProcessCreation () {
      return this.$store.state.segments.checkingInProcessCreation
    },
    loadingScvSettings () {
      return this.$store.state.scvSettings.loadingScvSettings
    },
    loadingTransactionSummaries () {
      return this.$store.state.transactionSummary.loadingTransactionSummaries
    },
  },
  created () {
    this.getCsvDownloadHistory()
    this.checkForInProcessCreation()
    this.$store.dispatch('scvSettings/getValidScvSettings')
      .then((res) => {
        let i = 1
        for (const scvColumn of JSON.parse(JSON.stringify(res.scvSettings))) {
          this.initColumns.push({ columnType: 'SCV', index: i, text: scvColumn.scvLogicalColumnName, value: scvColumn.scvPhysicalColumnName })
          i++
        }
        this.$store.dispatch('transactionSummary/getAllTransactionSummaries')
          .then((res) => {
            for (const transactionSummary of res.data.data.transactionSummarySettingList) {
              this.initColumns.push({ columnType: 'TransactionSummarySetting', index: i, value: transactionSummary.transactionSummarySettingId, text: transactionSummary.transactionSummaryName })
              i++
            }
          })
      })
  },
  methods: {
    getCsvDownloadHistory () {
      this.$store.dispatch('segments/getCsvDownloadHistory', { segmentId: this.segmentId, page: this.page, limit: DEFAULT_LIMIT })
        .then((res) => {
          this.csvDownloadHistory = res.segmentCustomerCsvRequestHistoryList
          this.pages = Math.ceil(res.filteredTotalCount / DEFAULT_LIMIT) || 1
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    checkForInProcessCreation () {
      this.$store.dispatch('segments/checkInProcessCreation', this.segmentId)
        .then((res) => {
          if (res.proceed) {
            this.isInProcessCreation = true
            setTimeout(() => {
              this.checkForInProcessCreation()
            }, 10000)
          } else if (this.isInProcessCreation) {
            this.page = 1
            this.getCsvDownloadHistory()
            this.notifySuccessMessage('CSVファイルの作成が終了しました')
            this.isInProcessCreation = false
          }
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    deleteCsvFile (segmentCustomerCsvRequestHistoryId) {
      this.$store.dispatch('segments/deleteCsvFile', segmentCustomerCsvRequestHistoryId)
        .then(() => {
          this.getCsvDownloadHistory()
        })
    },
    downloadCsvFile (segmentCustomerCsvRequestHistoryId) {
      return '//' + document.location.href.match(/\/\/([^:/]+)/)[1] + '/api/segment/' + segmentCustomerCsvRequestHistoryId + '/csv/download/'
    },
    changePage (newPage) {
      if (this.page !== newPage) {
        this.page = newPage
        this.getCsvDownloadHistory()
      }
    },
    reloadCsvDownloadHistory () {
      this.page = 1
      this.getCsvDownloadHistory()
      this.checkForInProcessCreation()
    },
  },
}
</script>

<style scoped>

</style>
