export default {
  data () {
    return {
      HOUR_LIST: {
        HOUR_0: { text: '00', value: '00' },
        HOUR_1: { text: '01', value: '01' },
        HOUR_2: { text: '02', value: '02' },
        HOUR_3: { text: '03', value: '03' },
        HOUR_4: { text: '04', value: '04' },
        HOUR_5: { text: '05', value: '05' },
        HOUR_6: { text: '06', value: '06' },
        HOUR_7: { text: '07', value: '07' },
        HOUR_8: { text: '08', value: '08' },
        HOUR_9: { text: '09', value: '09' },
        HOUR_10: { text: '10', value: '10' },
        HOUR_11: { text: '11', value: '11' },
        HOUR_12: { text: '12', value: '12' },
        HOUR_13: { text: '13', value: '13' },
        HOUR_14: { text: '14', value: '14' },
        HOUR_15: { text: '15', value: '15' },
        HOUR_16: { text: '16', value: '16' },
        HOUR_17: { text: '17', value: '17' },
        HOUR_18: { text: '18', value: '18' },
        HOUR_19: { text: '19', value: '19' },
        HOUR_20: { text: '20', value: '20' },
        HOUR_21: { text: '21', value: '21' },
        HOUR_22: { text: '22', value: '22' },
        HOUR_23: { text: '23', value: '23' },
      },
    }
  },
}
