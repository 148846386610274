export default {
  data () {
    return {
      enumUtil: {
        convertForSelectList (enumObj) {
          const list = []
          for (const key of Object.keys(enumObj)) {
            list.push({ text: enumObj[key].text, value: enumObj[key].value })
          }
          return list
        },
        getEnumFromValue (enumObj, value) {
          for (const key of Object.keys(enumObj)) {
            if (enumObj[key].value === Number(value)) return enumObj[key]
          }
          return null
        },
        getEnumFromSomeProperty (enumObj, prop, value) {
          for (const key of Object.keys(enumObj)) {
            if (enumObj[key][prop] === Number(value)) return enumObj[key]
          }
          return null
        },
        getEnumFromText (enumObj, text) {
          for (const key of Object.keys(enumObj)) {
            if (enumObj[key].text === String(text)) return enumObj[key]
          }
          return null
        },
      },
    }
  },
}
