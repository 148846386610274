<template>
  <div>
    <v-dialog
      :value="openDialog"
      @input="$emit('switchDialog', $event)"
      width="40%"
      :retain-focus="false"
      persistent
    >
      <v-card>
        <v-card-title>
          新規CSVファイルを作成しますか？
        </v-card-title>
        <v-card-text>
          ファイル作成には時間がかかる場合があります。<br>
          過去のCSVファイルは削除され、ダウンロードできなくなります。<br>
          ダウンロードする項目と順番は右記のダウンロード項目選択から選択できます。
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="denial" @click="$emit('switchDialog', false)">{{ $t('btn.no') }}</v-btn>
          <v-btn color="primary" @click="csvDownloadRequest()">{{ $t('btn.yes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      :value="openDialog"
      @input="$emit('switchDialog', $event)"
      width="366"
      app
      fixed
      temporary
      right
      stateless
      hide-overlay
    >
      <v-list-item class="px-6">
        <v-list-item-content class="py-4">
          <v-list-item-title class="text-h6">
            ダウンロード項目選択
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-card outlined tile class="mx-6 mb-4 item-inPageScroll_y">
        <v-subheader>
          <v-checkbox class="pt-5" :label="$t(`form.selectAll`)" :value="checkedAllColumns" @click="toggle()" />
        </v-subheader>
        <v-list v-if="selectedColumns" dense subheader>
          <v-list-item-group
            v-model="selectedColumns"
            multiple
          >
            <draggable v-model="sortedColumns">
              <v-list-item v-for="column in sortedColumns" :key="column.value" :value="column.value" draggable>
                <template #default="{ active }">
                  <v-checkbox  :input-value="active" hide-details class="mt-0 pt-0"/>
                  <v-list-item-content>
                    <v-list-item-title>{{ column.text }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon class="draggableIcon">ic-handle-S</v-icon>
                  </v-list-item-icon>
                </template>
              </v-list-item>
            </draggable>
          </v-list-item-group>
        </v-list>
      </v-card>
      <div class="text-center">
        <v-btn class="mr-3" @click="reset()">リセット</v-btn>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
// plugin
import draggable from 'vuedraggable'
// util
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'columnChoice',
  mixins: [
    notifyUtil,
  ],
  components: {
    draggable,
  },
  props: {
    openDialog: {
      type: Boolean,
    },
    initColumns: {
      type: Array,
    },
    segmentId: {
      type: Number,
    },
  },
  data () {
    return {
      sortedColumns: [],
      selectedColumns: [],
    }
  },
  watch: {
    openDialog: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.reset()
        }
      },
    },
  },
  computed: {
    checkedAllColumns () {
      return this.selectedColumns.length === this.initColumns.length
    },
  },
  methods: {
    csvDownloadRequest () {
      if (this.selectedColumns.length === 0) {
        this.notifyErrorMessage(this.$t('notify.error.segments.noCsvDownloadColumn'))
        return
      }
      this.$emit('switchDialog', false)
      let requestColumns = {}
      requestColumns = this.sortedColumns.filter(column => this.selectedColumns.includes(column.value))
      requestColumns = requestColumns.map((column, index) => {
        if (column.columnType === 'SCV') {
          return { columnType: 'SCV', index: index + 1, logicalName: column.text, physicalName: column.value }
        } else if (column.columnType === 'TransactionSummarySetting') {
          return { columnType: 'TransactionSummarySetting', index: index + 1, transactionSummarySettingName: column.text, transactionSummarySettingId: column.value }
        }
        return column
      })
      this.$store.dispatch('segments/csvDownloadRequest', { segmentId: this.segmentId, requestColumns: requestColumns })
        .then(() => {
          this.$emit('reloadCsvDownloadHistory')
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    reset () {
      this.sortedColumns = JSON.parse(JSON.stringify(this.initColumns))
      this.selectedColumns = []
      for (const column of this.initColumns) {
        this.selectedColumns.push(column.value)
      }
    },
    toggle () {
      const selectedColumns = []
      if (!this.checkedAllColumns) {
        for (const column of this.initColumns) {
          selectedColumns.push(column.value)
        }
      }
      this.selectedColumns = selectedColumns
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-navigation-drawer) using($integral-core-theme) {
  box-shadow: -3px 0px 6px map-deep-get($integral-core-theme, 'views', 'scvs', 'navigation-drawer', 'default', 'box-shadow') !important;
  .v-subheader {
    background-color: map-deep-get($integral-core-theme, 'views', 'scvs', 'navigation-drawer', 'default', 'background');

    ::v-deep.v-label {
      color: map-deep-get($integral-core-theme, 'views', 'scvs', 'navigation-drawer', 'default', 'text') !important;
    }

    .v-input {
      &.v-input--is-label-active {
        ::v-deep.v-label {
          color: map-deep-get($integral-core-theme, 'views', 'scvs', 'navigation-drawer', 'active', 'text') !important;
        }
      }
    }
  }

  .v-list {
    .v-list-item {
      color: map-deep-get($integral-core-theme, 'views', 'scvs', 'navigation-drawer', 'default', 'text') !important;
      border: 1px solid map-deep-get($integral-core-theme, 'views', 'scvs', 'navigation-drawer', 'default', 'border');
      background-color: map-deep-get($integral-core-theme, 'views', 'scvs', 'navigation-drawer', 'default', 'background');

      &.v-list-item--active {
        color: map-deep-get($integral-core-theme, 'views', 'scvs', 'navigation-drawer', 'active', 'text') !important;
        background-color: map-deep-get($integral-core-theme, 'views', 'scvs', 'navigation-drawer', 'default', 'background');
      }

      &::before {
        opacity: 0;
      }
    }
  }
}

.v-navigation-drawer {
  z-index: 205;
  .item-inPageScroll_y {
    overflow-y: hidden;
    height: 80vh;
  }

  .v-list-item__title {
    display: flex;
    word-break: break-all;
    white-space: normal;
  }

  .v-subheader {
    margin-left: 13px;
    ::v-deep.v-label {
      font-size: 13px;
    }
  }

  .v-list {
    overflow-y: scroll;
    height: 90%;
    .v-list-item {
      margin: 8px 12px 0;

      .draggableIcon {
        cursor: move;
      }
    }
  }
}
</style>
