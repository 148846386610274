<template>
  <v-data-table
    disable-filtering
    disable-pagination
    disable-sort
    hide-default-footer
    :headers="tableHeaders"
    :items="accountRoles"
    :loading="loading"
    @click:row="onClickEditButton"
  >
    <template #item.delete="{ item }">
      <v-btn v-if="item.editable === true" fab depressed x-small color="denial" class="ml-1" @click.stop="onClickDeleteButton(item)" :disabled="!canDelete">
        <v-icon>ic-close-S</v-icon>
      </v-btn>
    </template>
    <template #item.action="{ item }">
      <v-btn small @click.stop="onClickShowAccountsButton(item)">所属アカウント表示</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
// util
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'

const DEFAULT_LIMIT = 10

export default {
  name: 'accountsTable',
  mixins: [
    enumUtil,
    notifyUtil,
  ],
  props: {
    accountRoles: {
      type: Array,
    },
    pageIndex: {
      type: Number,
      default: 1,
    },
    loading: {
      type: Boolean,
      default () {
        return false
      },
    },
  },
  data () {
    return {
      tableHeaders: [
        {
          value: 'delete',
          width: '1%',
          sortable: false,
        },
        {
          text: '権限名',
          value: 'roleName',
          width: '20%',
          sortable: false,
        },
        {
          text: '備考',
          value: 'note',
          sortable: false,
        },
        {
          value: 'action',
          width: '1%',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['canDelete']),
    /**
     * 表示対象ページのインデックス
     * @type {Number}
     */
    index: {
      get () {
        return this.pageIndex
      },
      set (newVal) {
        this.$emit('changePage', newVal)
      },
    },
    /**
     * 一覧テーブルのページ数
     * @return {Number}
     */
    pageLength () {
      return Math.ceil(this.$store.state.accounts.accounts.length / DEFAULT_LIMIT) || 0
    },
  },
  methods: {
    onClickEditButton (role) {
      this.$emit('edit', role.roleId)
    },
    onClickDeleteButton (roleId) {
      this.$emit('delete', roleId)
    },
    onClickShowAccountsButton (role) {
      this.$emit('showAccounts', role)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-data-table {
  ::v-deep tbody tr {
    cursor: pointer;
  }
}
</style>
