import moment from 'moment-timezone'

export default {
  data () {
    return {
      momentUtil: {
        /**
         * 現在日付(YYYYMMDD)を取得
         * @return {String} 現在日付
         */
        getCurrentDate () {
          return process.env.VUE_APP_DEBUG_DATETIME
            ? moment(new Date(process.env.VUE_APP_DEBUG_DATETIME)).tz('Asia/Tokyo').format('YYYYMMDD')
            : moment().tz('Asia/Tokyo').format('YYYYMMDD')
        },
        /**
         * 現在日付(YYYY-MM-DD)を取得
         * @return {String} 現在日付
         */
        getCurrentDateIncludeHyphen () {
          return process.env.VUE_APP_DEBUG_DATETIME
            ? moment(new Date(process.env.VUE_APP_DEBUG_DATETIME)).tz('Asia/Tokyo').format('YYYY-MM-DD')
            : moment().tz('Asia/Tokyo').format('YYYY-MM-DD')
        },
        /**
         * 現在日時(YYYY-MM-DD HH:mm:ss)を取得
         * @return {String} 現在日時
         */
        getCurrentDateTime () {
          return process.env.VUE_APP_DEBUG_DATETIME
            ? moment(new Date(process.env.VUE_APP_DEBUG_DATETIME)).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss')
            : moment().tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss')
        },
        /**
         * 現在日時を基にサマリ用の現在日付(YYYYMMDD)を取得
         * @return {String} 現在日付
         */
        getCurrentDateForSummary () {
          const now = process.env.VUE_APP_DEBUG_DATETIME ? new Date(process.env.VUE_APP_DEBUG_DATETIME) : new Date(moment().tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss'))
          let currentDate = ''
          // 0時30分までは当日日付のサマリデータが存在しないため、0時台の場合は前日の日付でデータを取得する
          if (now.getHours() === 0) {
            const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)
            currentDate = moment(yesterday, 'YYYY-MM-DD').tz('Asia/Tokyo').format('YYYYMMDD')
          } else {
            currentDate = moment(now).tz('Asia/Tokyo').format('YYYYMMDD')
          }
          return currentDate
        },
      },
    }
  },
}
