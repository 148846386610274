export default {
  data () {
    return {
      OPERATOR_TYPE: {
        // IN: { text: 'いずれかに該当する', value: 1, symbol: 'IN' },
        // NOT_IN: { text: 'いずれにも該当しない', value: 2, symbol: 'NOT_IN' },
        GT: { text: '超過', value: 3, symbol: 'GT' },
        GT_EQ: { text: '以上', value: 4, symbol: 'GT_EQ' },
        LT: { text: '未満', value: 5, symbol: 'LT' },
        LT_EQ: { text: '以下', value: 6, symbol: 'LT_EQ' },
        EQ: { text: '等しい', value: 7, symbol: 'EQ' },
        NOT_EQ: { text: '等しくない', value: 8, symbol: 'NOT_EQ' },
        PREFIX_MATCH: { text: 'で始まる', value: 9, symbol: 'F_MATCH' },
        PARTIAL_MATCH: { text: '含む', value: 10, symbol: 'LIKE' },
        BACKWARD_MATCH: { text: 'で終わる', value: 11, symbol: 'P_MATCH' },
        NOT_PARTIAL_MATCH: { text: '含まない', value: 12, symbol: 'NOT_LIKE' },
        IS_NULL: { text: '持っていない', value: 13, symbol: 'IS NULL' },
        IS_NOT_NULL: { text: '持っている', value: 14, symbol: 'IS NOT NULL' },
        AFTER_YEAR_AGO: { text: '年前超過', value: 15, symbol: 'AFTER_YEAR_AGO' },
        SINCE_YEAR_AGO: { text: '年前以降', value: 16, symbol: 'SINCE_YEAR_AGO' },
        BEFORE_YEAR_AGO: { text: '年前未満', value: 17, symbol: 'BEFORE_YEAR_AGO' },
        UNTIL_YEAR_AGO: { text: '年前以前', value: 18, symbol: 'UNTIL_YEAR_AGO' },
        YEAR_AGO: { text: '年前', value: 19, symbol: 'YEAR_AGO' },
        AFTER_MONTH_AGO: { text: 'ヶ月前超過', value: 20, symbol: 'AFTER_MONTH_AGO' },
        SINCE_MONTH_AGO: { text: 'ヶ月前以降', value: 21, symbol: 'SINCE_MONTH_AGO' },
        BEFORE_MONTH_AGO: { text: 'ヶ月前未満', value: 22, symbol: 'BEFORE_MONTH_AGO' },
        UNTIL_MONTH_AGO: { text: 'ヶ月前以前', value: 23, symbol: 'UNTIL_MONTH_AGO' },
        MONTH_AGO: { text: 'ヶ月前', value: 24, symbol: 'MONTH_AGO' },
        AFTER_DAY_AGO: { text: '日前超過', value: 25, symbol: 'AFTER_DAY_AGO' },
        SINCE_DAY_AGO: { text: '日前以降', value: 26, symbol: 'SINCE_DAY_AGO' },
        BEFORE_DAY_AGO: { text: '日前未満', value: 27, symbol: 'BEFORE_DAY_AGO' },
        UNTIL_DAY_AGO: { text: '日前以前', value: 28, symbol: 'UNTIL_DAY_AGO' },
        DAY_AGO: { text: '日前', value: 29, symbol: 'DAY_AGO' },
        DAY_NEXT: { text: '日後', value: 30, symbol: 'DAY_NEXT' },
        WITHIN_DAY_AGO: { text: '日以内', value: 31, symbol: 'WITHIN_DAY_AGO', prepend: '過去' },
        WITHIN_DAY_NEXT: { text: '日以内', value: 32, symbol: 'WITHIN_DAY_NEXT', prepend: '翌' },
        DAY_AGO_EXCEPT_YEAR: { text: '日前 (月日のみ対象)', value: 33, symbol: 'DAY_AGO_EXCEPT_YEAR' },
        DAY_NEXT_EXCEPT_YEAR: { text: '日後 (月日のみ対象)', value: 34, symbol: 'DAY_NEXT_EXCEPT_YEAR' },
        WITHIN_DAY_AGO_EXCEPT_YEAR: { text: '日以内 (月日のみ対象)', value: 35, symbol: 'WITHIN_DAY_AGO_EXCEPT_YEAR', prepend: '過去' },
        WITHIN_DAY_NEXT_EXCEPT_YEAR: { text: '日以内 (月日のみ対象)', value: 36, symbol: 'WITHIN_DAY_NEXT_EXCEPT_YEAR', prepend: '翌' },
      },
    }
  },
}
