<template>
  <v-navigation-drawer
    :value="openDrawer"
    @input="$emit('switchDrawer', $event)"
    width="366"
    app
    fixed
    temporary
    right
  >
    <v-list-item class="px-6">
      <v-list-item-content class="py-4">
        <v-list-item-title class="text-h6">
          {{ $t('text.scvs.columnDisplaySetting') }}
          <v-spacer />
          <v-btn icon @click="closeDrawer()">
            <v-icon>ic-close-M</v-icon>
          </v-btn>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-card outlined tile class="mx-6 mb-4 item-inPageScroll_y">
      <v-list v-if="activeColumnIds" dense subheader>
        <v-list-item-group
          v-model="activeColumnIds"
          multiple
        >
          <draggable v-model="sortedScvColumns">
            <v-list-item v-for="(column, i) in sortedScvColumns" :key="i" :value="column.value" draggable>
              <template #default="{ active }">
                <v-list-item-content>
                  <v-list-item-title>{{ column.text }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon v-if="active">ic-visibility-S</v-icon>
                  <v-icon v-else>ic-visibility-off-S</v-icon>
                </v-list-item-icon>
                <v-list-item-icon>
                  <v-icon class="draggableIcon">ic-handle-S</v-icon>
                </v-list-item-icon>
              </template>
            </v-list-item>
          </draggable>
        </v-list-item-group>
      </v-list>
    </v-card>
    <div class="text-center">
      <v-btn color="denial" @click="resetColumnSetting">リセット</v-btn>
    </div>
  </v-navigation-drawer>
</template>

<script>
// plugin
import draggable from 'vuedraggable'

export default {
  name: 'columnDisplaySetting',
  components: {
    draggable,
  },
  props: {
    openDrawer: {
      type: Boolean,
    },
    scvColumns: {
      type: Array,
    },
  },
  computed: {
    activeColumnIds: {
      get () {
        return this.$store.state.scvs.activeColumnIds
      },
      set (newVal) {
        this.$store.dispatch('scvs/updateActiveColumnIds', newVal)
      },
    },
    sortedScvColumns: {
      get () {
        return this.scvColumns.slice().sort((a, b) => this.$store.state.scvs.sortedColumnIds?.indexOf(a.value) - this.$store.state.scvs.sortedColumnIds?.indexOf(b.value))
      },
      set (newVal) {
        this.$store.dispatch('scvs/updateSortedColumnIds', newVal.map(column => column.value))
        this.$emit('sortScvColumns', newVal)
      },
    },
  },
  methods: {
    closeDrawer () {
      this.$emit('switchDrawer', false)
    },
    resetColumnSetting () {
      this.$store.dispatch('scvs/resetActiveColumnIds')
      this.$store.dispatch('scvs/resetSortedColumnIds')

      const defaultScvColumnIds = this.$store.state.scvSettings.scvSettings
        .map((scvSetting) => {
          return { text: scvSetting.scvLogicalColumnName, value: scvSetting.scvColumnId, custom: scvSetting.isCustom, scvPhysicalColumnName: scvSetting.scvPhysicalColumnName }
        })
      this.$emit('sortScvColumns', defaultScvColumnIds)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-navigation-drawer) using ($integral-core-theme) {
  box-shadow: -3px 0px 6px map-deep-get($integral-core-theme, 'views', 'scvs', 'navigation-drawer', 'default', 'box-shadow') !important;
  .v-list {
    .v-list-item {
      color: map-deep-get($integral-core-theme, 'views', 'scvs', 'navigation-drawer', 'default', 'text') !important;
      border: 1px solid map-deep-get($integral-core-theme, 'views', 'scvs', 'navigation-drawer', 'default', 'border');
      background-color: map-deep-get($integral-core-theme, 'views', 'scvs', 'navigation-drawer', 'default', 'background');
      &.v-list-item--active {
        color: map-deep-get($integral-core-theme, 'views', 'scvs', 'navigation-drawer', 'active', 'text') !important;
        background-color: map-deep-get($integral-core-theme, 'views', 'scvs', 'navigation-drawer', 'default', 'background');
      }
      &::before {
        opacity: 0;
      }
    }
  }
}

.v-navigation-drawer {
  .item-inPageScroll_y {
    height: 80vh;
  }
  .v-list-item__title {
    display: flex;
  }
  .v-list {
    .v-list-item {
      margin: 8px 12px 0;
      .draggableIcon {
        cursor: move;
      }
    }
  }
}
</style>
