<template>
  <v-container>
    <v-row>
      <v-col><v-btn @click="hasError = !hasError">error toggle ({{hasError}})</v-btn></v-col>
      <v-col><v-btn @click="hideDetails = !hideDetails">hide-details toggle ({{hideDetails}})</v-btn></v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field label="label" placeholder="入力してください" persistentPlaceholder :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field label="password" type="password" append-icon="mdi-eye" :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field value="clearable" clearable :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field label="disabled" value="value" disabled :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-select
          :items="Array.from({length: 200}, (x, i) => i + 1)"
          clearable
          label="selectbox"
          placeholder="選択してください"
          menu-props="auto"
          persistent-placeholder
          :hide-details="hideDetails"
          :error="hasError"
          :error-messages="errorMessages"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          :items="[{text: 1, value: 1},{text: 2, value: 2, disabled: true},{text: 3, value: 3}]"
          clearable
          label="selectbox with disabled option"
          placeholder="選択してください"
          menu-props="auto"
          persistent-placeholder
          :hide-details="hideDetails"
          :error="hasError"
          :error-messages="errorMessages"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          :items="Array.from({length: 200}, (x, i) => i + 1)"
          disabled
          :value="1"
          label="disabled selectbox"
          menu-props="auto"
          :hide-details="hideDetails"
          :error="hasError"
          :error-messages="errorMessages"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field label="date (使わない)" type="date" dense :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages" />
      </v-col>
      <v-col cols="3">
        <v-text-field label="time (使わない)" type="time" dense :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages" />
      </v-col>
      <v-col cols="3">
        <v-text-field label="datetime-local (使わない)" type="datetime-local" append-icon="mdi-eye" dense :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages" />
      </v-col>
      <v-col cols="3">
        <v-text-field label="number" type="number" clearable dense :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-dialog
          ref="dateDialog"
          :return-value.sync="date"
          width="252px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-on="on"
              v-model="date"
              dense
              :hide-details="hideDetails"
              label="date-picker"
              append-icon="ic-calendar-S"
              clearable
              readonly
              type="date"
              :error="hasError"
              :error-messages="errorMessages"
            />
          </template>
          <v-date-picker
            v-model="date"
            no-title
            locale="ja"
            :day-format="date => new Date(date).getDate()"
            @input="$refs.dateDialog.save(date)"
            width="auto"
          />
        </v-dialog>
      </v-col>
      <v-col cols="3">
        <datetime-picker
          v-model="datetime"
          dense
          :hide-details="hideDetails"
          label="datetime-picker"
          :error="hasError"
          :error-messages="errorMessages"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-textarea label="textarea label" outlined :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages"></v-textarea>
        <v-textarea label="disabled textarea" value="value" disabled outlined :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages"></v-textarea>
      </v-col>
      <v-col cols="3">
        <v-checkbox :hide-details="hideDetails" label="label" color="inputSelectionControl" :error="hasError" :error-messages="errorMessages" />
        <v-checkbox :hide-details="hideDetails" label="label2" color="inputSelectionControl" :error="hasError" :error-messages="errorMessages" />
        <v-checkbox :hide-details="hideDetails" label="label3" color="inputSelectionControl" :error="hasError" :error-messages="errorMessages" />
        <v-checkbox :hide-details="hideDetails" label="label4" color="inputSelectionControl" :error="hasError" :error-messages="errorMessages" />
        <v-checkbox :hide-details="hideDetails" label="label5" color="inputSelectionControl" :error="hasError" :error-messages="errorMessages" disabled />
        <v-checkbox :hide-details="hideDetails" label="label6" color="inputSelectionControl" :error="hasError" :error-messages="errorMessages" disabled v-model="check" />
      </v-col>
      <v-col cols="3">
        <v-radio-group :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages">
          <v-radio label="label1-1" color="inputSelectionControl" :error="hasError" />
          <v-radio label="label1-2" color="inputSelectionControl" :error="hasError" />
          <v-radio label="label1-3" color="inputSelectionControl" :error="hasError" />
          <v-radio label="label1-4 disabled" color="inputSelectionControl" :error="hasError" disabled />
        </v-radio-group>
        <v-radio-group :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages" value="active" disabled persistent-hint hint="v-radio-group with disabled attribute">
          <v-radio label="label2-1" color="inputSelectionControl" :error="hasError" value="active" />
          <v-radio label="label2-2" color="inputSelectionControl" :error="hasError" />
        </v-radio-group>
      </v-col>
      <v-col cols="3">
        <v-switch label="switch" :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages" />
        <v-switch dense label="dense switch" :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages" />
        <v-switch label="disabled switch" disabled :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages" />
        <v-switch label="disabled switch" disabled v-model="check" :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages" />
        <v-switch dense label="disables dense switch" disabled :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages" />
        <v-switch dense label="disables dense switch" disabled v-model="check" :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages" />
      </v-col>
    </v-row>
    <filter-form>
      <v-row>
        <v-col cols="3">
          <v-text-field label="label" clearable :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages" />
        </v-col>
        <v-col cols="3">
          <v-text-field label="password" type="password" append-icon="mdi-eye" :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages" ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field value="clearable" clearable :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages" ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-select label="label" :hide-details="hideDetails" :error="hasError" :error-messages="errorMessages" ></v-select>
        </v-col>
      </v-row>
    </filter-form>
  </v-container>
</template>

<script>
// component
import datetimePicker from '@/components/public/datetimePicker'
import filterForm from '@/components/common/filterForm'

export default {
  name: 'Inputs',
  components: {
    datetimePicker,
    filterForm,
  },
  data () {
    return {
      date: null,
      datetime: null,
      hasError: false,
      hideDetails: false,
      check: true,
    }
  },
  computed: {
    errorMessages () {
      return this.hasError ? 'errorMessages' : null
    },
  },
}
</script>
