export default {
  data () {
    return {
      DAY_OF_WEEK_LIST: {
        SUN: { text: '日', value: { sunday: 1 } },
        MON: { text: '月', value: { monday: 1 } },
        TUE: { text: '火', value: { tuesday: 1 } },
        WED: { text: '水', value: { wednesday: 1 } },
        THU: { text: '木', value: { thursday: 1 } },
        FRI: { text: '金', value: { friday: 1 } },
        SAT: { text: '土', value: { saturday: 1 } },
      },
    }
  },
}
