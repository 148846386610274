export default {
  data () {
    return {
      SEGMENT_EXPORT_SCRIPT_RECURRING_TYPES: {
        DAILY_SCHEDULED: { text: '日次指定時刻', value: 1 },
        AFTER_SEGMENT_MANAGER_EXEC: { text: 'セグメント作成毎', value: 2 },
      },
    }
  },
}
