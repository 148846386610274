<template>
  <div class="d-flex">
    <v-spacer v-if="putsValueRight"/>
    <span v-if="scvColumn.isUpdated">{{ $t(`text.userDetail.updatedScvColumn`) }}</span>
    <v-skeleton-loader
      v-else-if="scvColumn && scvColumn.scvColumnId === undefined"
      ref="skeleton"
      class="mt-1"
      type="text"
      boilerplate
      width="100"
      max-height="16"
    />
    <span v-else>{{ scvColumn.scvColumnValue }}</span>
    <v-spacer v-if="!putsValueRight"/>
    <v-btn v-if="!scvColumn.isUpdated && scvColumn.scvColumnValue != null && scvColumn.scvColumnValue != ''" small :loading="loading" @click="dialog = true" class="ml-1">詳細</v-btn>
    <v-dialog
      v-model="dialog"
      width="368"
      overlay-color="#07070820"
      overlay-opacity="1"
    >
      <v-card>
        <v-card-title>
          {{ scvColumn.scvColumnName }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="scvValue"
            class="pt-0"
            readonly
            :value="scvColumn.scvColumnValue"
            hide-details
          />
        </v-card-text>
        <v-list v-if="scvSources.length > 0" subheader dense disabled>
          <v-divider />
          <v-subheader>{{ $t('text.userDetail.scvColumnSource') }}</v-subheader>
          <v-list-item-group color="primary">
            <template v-for="source in scvSources">
              <v-list-item :key="'item-' + source.dataSetId + '-' + source.scvId" >
                <v-list-item-content>
                  <v-list-item-subtitle>{{ source.dataSetName }}</v-list-item-subtitle>
                  <v-list-item-title>{{ source.value }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
        <v-card-actions class="justify-center">
          <v-btn color="denial" @click="dialog = false">閉じる</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'scvColumn',
  mixins: [
  ],
  data: () => ({
    menu: false,
    dialog: false,
    editing: false,
    scvValue: '',
  }),
  props: {
    loading: {
      type: Boolean,
    },
    scvColumn: {
      type: Object,
    },
    scvSources: {
      type: Array,
    },
    putsValueRight: {
      type: Boolean,
    },
  },
  watch: {
    'scvColumn.scvColumnValue' () {
      this.scvValue = this.scvColumn.scvColumnValue
    },
    immediate: true,
  },
  created () {
    this.scvValue = this.scvColumn.scvColumnValue
  },
  methods: {
    edit () {
      this.dialog = true
      this.editing = true
    },
    save () {
      if (confirm(this.$t('text.userDetail.confirmValueOverwrite'))) {
        this.$emit('overwrite', this.scvColumn.scvColumnId, this.scvValue)
        this.dialog = false
        this.editing = false
      }
    },
    restore () {
      this.scvValue = this.scvColumn.scvColumnValue
      this.dialog = false
      this.editing = false
    },
  },
}
</script>

<style scoped lang="scss">
.v-dialog > .v-card {
  & > .v-card__text,
  & > .v-card__title {
    padding-left: 12px;
    padding-right: 12px;
  }

  & > .v-card__title {
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
}
}
</style>
