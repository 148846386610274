export default {
  data () {
    return {
      LOGICAL_OPERATOR_TYPE: {
        AND: { text: '全て含む', value: 1 },
        OR: { text: 'いずれかを含む', value: 2 },
      },
    }
  },
}
