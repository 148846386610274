export default {
  data () {
    return {
      getMonthlyScheduleStr (jobSettingMonthly) {
        const str = []
        for (const key in jobSettingMonthly) {
          if (jobSettingMonthly[key] === 1) {
            switch (key) {
              case 'lastday':
                str.push('末日')
                break
              default :
                str.push(key.replace('day', '') + '日')
                break
            }
          }
        }
        return str.toString()
      },
      getWeeklyScheduleStr (jobSettingWeekly) {
        const str = []
        for (const key in jobSettingWeekly) {
          if (jobSettingWeekly[key] === 1) {
            switch (key) {
              case 'sunday':
                str.push('日曜日')
                break
              case 'monday':
                str.push('月曜日')
                break
              case 'tuesday':
                str.push('火曜日')
                break
              case 'wednesday':
                str.push('水曜日')
                break
              case 'thursday':
                str.push('木曜日')
                break
              case 'friday':
                str.push('金曜日')
                break
              case 'saturday':
                str.push('土曜日')
                break
            }
          }
        }
        return str.toString()
      },
    }
  },
}
