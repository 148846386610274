const global = {
  namespaced: true,
  state: {
    // true: Mini化, false: 全体表示
    drawer: true,
  },
  mutations: {
    setDrawer (state, newVal) {
      state.drawer = newVal
    },
  },
  actions: {
    toggle ({ commit, state }) {
      commit('setDrawer', !state.drawer)
    },
    updateDrawer ({ commit }, newVal) {
      commit('setDrawer', newVal)
    },
  },
}

export default global
