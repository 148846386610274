export default {
  data () {
    return {
      JSON_INPUT_ITEM_NAMES: {
        S3: { text: 'S3', value: 'inputS3' },
        AURORA: { text: 'MySQL', value: 'inputAurora' },
        REDSHIFT: { text: 'Redshift', value: 'inputRedshift' },
        SEGMENT: { text: 'Segment', value: 'inputSegment' },
        GCS: { text: 'GCS', value: 'inputGcs' },
        SFTP: { text: 'SFTP', value: 'inputSFTP' },
      },
    }
  },
}
