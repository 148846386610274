<template>
  <v-card>
    <v-card-title class="py-5">
      集計データ対象の設定
    </v-card-title>
    <v-card-text>
      <v-card class="mb-5" tile outlined>
        <v-card-text class="px-0 pb-0">
          <v-row class="ma-0">
            <v-col class="filterColumn" cols="6">
              <v-select
                :value="targetTransaction.dataSourceId"
                @change="changeDataSource"
                :label="$t('form.transactionSummary.summaryDataSource')"
                dense
                :items="dataSourceList"
                item-text="dataSourceName"
                item-value="dataSourceId"
                return-object
                name="summaryDataSource"
                :data-vv-as="$t('form.transactionSummary.summaryDataSource')"
                v-validate="'required'"
                :error-messages="errors.collect('summaryDataSource')"
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                :value="targetTransaction.summaryColumn.summaryPhysicalName"
                @change="changeSummaryColumn"
                :label="$t('form.transactionSummary.summaryColumn')"
                dense
                :items="summaryColumnList"
                item-text="logicalName"
                item-value="physicalName"
                return-object
                name="summaryColumn"
                :data-vv-as="$t('form.transactionSummary.summaryColumn')"
                v-validate="'required'"
                :error-messages="errors.collect('summaryColumn')"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-subheader>集計条件</v-subheader>
      <v-card class="mb-5" tile outlined>
        <v-card-text>
          <div class="pb-1">{{ $t('text.segmentSetting.operatorsCannotMixed') }}</div>
          <v-chip v-if="targetTransaction.logicalOperatorType === LOGICAL_OPERATOR_TYPE.AND.value" color="and">AND</v-chip>
          <v-chip v-else-if="targetTransaction.logicalOperatorType === LOGICAL_OPERATOR_TYPE.OR.value" color="or">OR</v-chip>
          <!-- 3 - x で x (LOGICAL_OPERATOR_TYPE: 1 or 2) の 状態変換 -->
          <v-btn icon @click="targetTransaction.logicalOperatorType = 3 - targetTransaction.logicalOperatorType"><v-icon>ic-arrow-updown</v-icon></v-btn>
          <span>{{ $t('text.segmentSetting.batchToggle') }}</span>
          <div class="d-flex align-center">
            <!-- 削除ボタン -->
            <div class="label ml-8 px-3">
              <v-row>
                <v-col cols="5">{{ $t('form.transactionSummary.filterColumn') }}</v-col>
                <v-col cols="4">{{ $t('form.transactionSummary.filterValue') }}</v-col>
                <v-col cols="3">{{ $t('form.transactionSummary.filterOperator') }}</v-col>
              </v-row>
            </div>
          </div>
          <transaction-rule
            v-for="(filterColumn, index) in targetTransaction.filterColumns"
            ref="transactionRule"
            :key="index"
            :index="index"
            :filter-column="filterColumn"
            :target-transaction="targetTransaction"
            :data-source-list="dataSourceList"
            @removeRule="targetTransaction.filterColumns.splice(index, 1)"
            @changeFilterColumn="changeFilterColumn($event, index)"
          />
          <v-btn fab depressed @click="onClickAddRuleButton" :disabled="targetTransaction.filterColumns.length >= filterLimit">
            <v-icon>ic-add</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn color="denial" @click="onClickCancel">{{ $t('btn.cancel') }}</v-btn>
      <v-btn color="primary" @click="onClickOk">{{ $t('btn.ok') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// component
import transactionRule from '@/components/TransactionSummarySetting/transactionRule'

// enum
import TRANSACTION_SUMMARY_TYPE from '@/enum/transactionSummary/TRANSACTION_SUMMARY_TYPE'
import LOGICAL_OPERATOR_TYPE from '@/enum/LOGICAL_OPERATOR_TYPE'

export default {
  name: 'transactionGroupForm',
  components: {
    transactionRule,
  },
  mixins: [
    TRANSACTION_SUMMARY_TYPE,
    LOGICAL_OPERATOR_TYPE,
  ],
  data () {
    return {
      targetTransaction: {},
      initialFilterColumn: {
        filterPhysicalName: null,
        filterLogicalName: null,
        filterValue: null,
        logicalNot: false,
        operatorType: null,
        dataType: null,
      },
    }
  },
  props: {
    filterLimit: {
      type: Number,
    },
    transaction: {
      type: Object,
    },
    dataSourceList: {
      type: Array,
    },
    transactionSummaryType: {
      type: Number,
    },
    transactionSummaryOutputDataType: {
      type: Number,
    },
  },
  computed: {
    summaryColumnList () {
      let columnList = []
      if (this.targetTransaction.dataSourceId && this.dataSourceList.length > 0) {
        columnList = JSON.parse(JSON.stringify(this.dataSourceList.find(dataSource => dataSource.dataSourceId === this.targetTransaction.dataSourceId)?.headerMetaJson?.columns))
        columnList.forEach(column => {
          column.disabled = false
          if (this.transactionSummaryType !== this.TRANSACTION_SUMMARY_TYPE.COUNT.value &&
            this.transactionSummaryType !== this.TRANSACTION_SUMMARY_TYPE.UNIQUE_VALUES_COUNT.value &&
            this.transactionSummaryType !== this.TRANSACTION_SUMMARY_TYPE.FIRST_TIMESTAMP.value &&
            this.transactionSummaryType !== this.TRANSACTION_SUMMARY_TYPE.LAST_TIMESTAMP.value) {
            // カウント・初回ログ発生日時・最新ログ発生日時以外は出力データ型のみ対象カラムとして選択可能
            column.disabled = (Object.prototype.hasOwnProperty.call(column, 'dataType') && column.dataType !== this.transactionSummaryOutputDataType)
          }
        })
      }
      return columnList
    },
  },
  watch: {
    transaction: {
      handler () {
        this.targetTransaction = JSON.parse(JSON.stringify(this.transaction))
      },
      immediate: true,
    },
  },
  methods: {
    changeDataSource (dataSource) {
      this.targetTransaction.dataSourceId = dataSource.dataSourceId
      this.targetTransaction.summaryColumn = {
        summaryPhysicalName: null,
        summaryLogicalName: null,
        dataType: null,
      }
      this.targetTransaction.filterColumns = []
    },
    changeSummaryColumn (newVal) {
      let newSummaryColumn = {}
      if (newVal) {
        newSummaryColumn = {
          summaryPhysicalName: newVal.physicalName,
          summaryLogicalName: newVal.logicalName,
          dataType: newVal.dataType,
        }
      } else {
        newSummaryColumn = {
          summaryPhysicalName: null,
          summaryLogicalName: null,
          dataType: null,
        }
      }
      this.targetTransaction.summaryColumn = newSummaryColumn
    },
    changeFilterColumn (transactionRule, groupIndex) {
      for (const key in transactionRule) {
        this.targetTransaction.filterColumns[groupIndex][key] = transactionRule[key]
      }
    },
    onClickAddRuleButton () {
      this.targetTransaction.filterColumns.push(JSON.parse(JSON.stringify(this.initialFilterColumn)))
    },
    onClickCancel () {
      this.$emit('dialogClose')
      this.targetTransaction = JSON.parse(JSON.stringify(this.transaction))
      this.$validator.reset()
    },
    onClickOk () {
      let totalResult = true
      this.$validator.validateAll()
        .then(async (result) => {
          if (result) {
            for (let i = 0; i < this.targetTransaction.filterColumns.length; i++) {
              await this.$refs.transactionRule[i].$validator.validateAll()
                .then((filterColumnResult) => {
                  if (!filterColumnResult) {
                    totalResult = false
                  }
                })
            }
          } else {
            totalResult = false
          }
        })
        .finally(() => {
          if (totalResult) {
            this.$emit('dialogClose')
            this.$emit('applyTransaction', this.targetTransaction)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-subheader) using ($integral-core-theme) {
  color: map-deep-get($integral-core-theme, 'views', 'segmentSetting', 'segmentForms', 'ruleGroupForm', 'text');
}

.v-subheader {
  margin: 0 0 6px;
  padding: 0;
  font-size: 12px;
  line-height: 16px;
  height: auto;
}
.label {
  font-size: 12px;
  line-height: 16px;
  padding-top: 14px;
  margin-bottom: -6px;
  width: 100%;
}
</style>
