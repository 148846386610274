<template>
  <v-container fluid>
    <v-card flat>
      <div class="user">ユーザー</div>
      <v-data-table
        disable-filtering
        disable-pagination
        disable-sort
        hide-default-footer
        fixed-header
        :headers="tableHeader"
        :items="modSegmentUsers"
        :loading="loadingSegmentsUsers"
        @click:row="onClickTableRow"
      >
        <template #item.icon>
          <v-icon>ic-anonymous-M</v-icon>
        </template>
        <template #item.segmentLength="{ item }">
          {{ item.segmentList.length }}件
        </template>
        <template #item.segmentNames="{ item }">
          <div v-for="segment in item.segmentList.slice(0, 3)" :key="'name_' + segment.segmentId">
            {{ segment.segmentName }}
          </div>
        </template>
        <template #item.segmentList="{ item }">
          <v-tooltip top max-width="900">
            <template #activator="{ on, attrs }">
              <v-btn outlined tile v-bind="attrs" v-on="on">
                <v-icon class="mr-1">ic-info</v-icon>
                <!-- TODO i18n -->
                表示
              </v-btn>
            </template>
            <ul>
              <li v-for="userSegment in item.segmentList" :key="userSegment.segmentId">
                {{ userSegment.segmentName }}
              </li>
            </ul>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-pagination v-model="params.page" :length="pages" total-visible="7" :disabled="loadingSegmentsUsers" @input="getSegmentUsers()" class="pt-7 pb-2"/>

    <!-- TODO 親コンポーネントに移植して innerSCV と共通化 -->
    <!-- セグメントユーザー詳細 -->
    <v-navigation-drawer
      v-model="showUserDetail"
      width="80%"
      app
      fixed
      temporary
      right
    >
      <information-bar>
        <template #left>
          <v-btn @click="showUserDetail = false">
            {{ $t(`btn.close`) }}
          </v-btn>
        </template>
        <template #right>
          <v-btn :to="{ name: 'UserUnknownDetail', params: { unknownId: selectedUnknownId } }">{{ $t('btn.open') }}</v-btn>
          <v-btn class="mx-5" icon x-small :to="{ name: 'UserUnknownDetail', params: { unknownId: selectedUnknownId } }" target="_blank"><v-icon>mdi-open-in-new</v-icon></v-btn>
        </template>
      </information-bar>

      <user-unknown-detail
        v-if="showUserDetail && selectedUnknownId"
        :unknownId="selectedUnknownId" />
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import userUnknownDetail from '@/views/userUnknownDetail'
// utils
import momentUtil from '@/utils/momentUtil'
import notifyUtil from '@/utils/notifyUtil'

const DEFAULT_PAGE = 1
const DEFAULT_LIMIT = 100
const HALF_AN_HOUR = 30
const MINUTE_OF_AN_HOUR = 60
const MINUTE_OF_ONE_DAY = 1440

export default {
  components: {
    userUnknownDetail,
  },
  props: {
    segmentId: {
      type: Number,
      default: 0,
    },
  },
  mixins: [
    momentUtil,
    notifyUtil,
  ],
  data () {
    return {
      tableHeader: [
        {
          value: 'icon',
          width: '48',
          sortable: false,
        },
        {
          text: '所属数',
          value: 'segmentLength',
          width: '10%',
          sortable: false,
        },
        {
          text: 'セグメント名',
          value: 'segmentNames',
          sortable: false,
        },
        {
          text: '全セグメント',
          value: 'segmentList',
          width: '20%',
          sortable: false,
        },
        {
          text: '最終アクセス',
          value: 'lastAccessDiffTime',
          width: '20%',
          sortable: false,
        },
      ],
      params: {
        segmentId: '',
        page: DEFAULT_PAGE,
        limit: DEFAULT_LIMIT,
      },
      showUserDetail: false,
      selectedUnknownId: null,
      userUnknownList: [],
      userPaging: {},
    }
  },
  computed: {
    modSegmentUsers () {
      if (this.userUnknownList.length === 0) return []
      const tmpUsers = JSON.parse(JSON.stringify(this.userUnknownList))
      tmpUsers.forEach((user) => {
        // 最終アクセス時刻からの差分時間を算出、24時間以上経過している場合はyyyy-MM-dd HH:mm:SSを返す
        const today = new Date(this.momentUtil.getCurrentDateTime())
        const lastAccessDate = new Date(user.lastAccessDatetime)
        user.lastAccessDiffTime = Math.floor((today.getTime() - lastAccessDate.getTime()) / (1000 * 60))
        // 30分以内
        if (user.lastAccessDiffTime <= HALF_AN_HOUR) {
          user.lastAccessDiffTime = String(user.lastAccessDiffTime) + '分以内'
        // 31分以上24時間以内
        } else if (user.lastAccessDiffTime > HALF_AN_HOUR && user.lastAccessDiffTime <= MINUTE_OF_ONE_DAY) {
          if (user.lastAccessDiffTime % MINUTE_OF_AN_HOUR === 0) {
            user.lastAccessDiffTime = user.lastAccessDiffTime / MINUTE_OF_AN_HOUR
          } else {
            user.lastAccessDiffTime = Math.floor(user.lastAccessDiffTime / MINUTE_OF_AN_HOUR) + 1
          }
          user.lastAccessDiffTime = String(user.lastAccessDiffTime) + '時間以内'
        // 24時間以上
        } else if (user.lastAccessDiffTime > MINUTE_OF_ONE_DAY) {
          user.lastAccessDiffTime = user.lastAccessDatetime
        }
      })
      return tmpUsers
    },
    pages () {
      return Math.ceil(this.userPaging.filteredTotalCount / this.userPaging.limit) || 0
    },
    loadingSegmentsUsers () {
      return this.$store.state.segmentUsers.loadingSegmentsUsers
    },
  },
  watch: {
    $route: {
      handler () {
        // URLの変更を検知してセグメントユーザーを再取得
        this.getSegmentUsers()
      },
    },
  },
  created () {
    this.getSegmentUsers()
  },
  methods: {
    /**
     * セグメントユーザーリストの取得
     */
    getSegmentUsers () {
      this.params.segmentId = this.segmentId
      this.$store.dispatch('segmentUsers/getSegmentsUnknown', this.params)
        .then((res) => {
          this.userPaging = res.paging
          this.userUnknownList = res.userUnknownList
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    onClickTableRow (item) {
      this.selectedUnknownId = item.unknownId
      this.showUserDetail = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-data-table) using ($integral-core-theme) {
  &::v-deep tbody tr td {
    border-bottom: 1px solid map-deep-get($integral-core-theme, 'data-table', 'td-border') !important;
  }
}

@include theme(v-btn) using ($integral-core-theme) {
  background-color: map-deep-get($integral-core-theme, 'views', 'segmentDetail', 'innerUnknown', 'background') !important;
  border: 1px solid map-deep-get($integral-core-theme, 'views', 'segmentDetail', 'innerUnknown', 'border') !important;
  color: map-deep-get($integral-core-theme, 'views', 'segmentDetail', 'innerUnknown', 'color') !important;
  :hover {
    color: map-deep-get($integral-core-theme, 'views', 'segmentDetail', 'innerUnknown', 'color') !important;
  }
}

.user {
  font-size: 12px;
  line-height: 16px;
}
.v-data-table ::v-deep tr {
  cursor: pointer;
  .v-icon {
    padding-left: 12px;
  }
  .v-btn {
    .v-btn__content {
      letter-spacing: 0;
      line-height: 20px;
      .v-icon {
        font-size: 20px;
        padding-left: 0px;
      }
    }
  }
}
</style>
