<template>
  <v-card>
    <v-card-title class="py-5">
      {{ $t('text.segmentSetting.filterSetting') }}
    </v-card-title>
    <v-card-text>
      <v-subheader>{{ $t('text.segmentSetting.type') }}</v-subheader>
      <v-card class="filterRuleTypes mb-5" tile outlined>
        <v-card-text>
          <v-radio-group
            v-model="filterRuleType"
            :disabled="selectedGroup.filterRuleType > 0"
            row
            dense
            hide-details
            mandatory
          >
            <v-radio color="inputSelectionControl" :disabled="isUnknown || !canGetThis('scvs')" :label="$t('form.segmentSetting.ruleForm.attribute')" :value="FILTER_RULE_TYPE.ATTRIBUTE.value" />
            <v-radio color="inputSelectionControl" :label="$t('form.segmentSetting.ruleForm.webTransaction')" :value="FILTER_RULE_TYPE.WEB_TRANSACTION.value" />
            <v-radio color="inputSelectionControl" :disabled="isUnknown || !canGetThis('DataSets')" :label="$t('form.segmentSetting.ruleForm.transactionSummary')" :value="FILTER_RULE_TYPE.TRANSACTION_SUMMARY.value" />
          </v-radio-group>
        </v-card-text>
      </v-card>

      <template v-if="selectedGroup.filterRuleType > 0">
        <!-- トランザクションサマリ設定 -->
        <template v-if="selectedGroup.filterRuleType === FILTER_RULE_TYPE.TRANSACTION_SUMMARY.value">
          <v-subheader>{{ $t('text.segmentSetting.transactionSummarySetting') }}</v-subheader>
          <v-card tile outlined class="mb-5">
            <v-card-text class="px-3 pb-5">
              <v-row>
                <v-col class="pb-0">
                  <v-select
                    :value="selectedTransactionSummary.filterPhysicalName"
                    @change="changeTransactionSummary"
                    :items="transactionSummaryList"
                    item-text="filterLogicalName"
                    item-value="filterPhysicalName"
                    :label="$t('text.transactionSummaryName')"
                    :placeholder="$t(`form.segmentSetting.segmentFilter.column.selectPlaceholder`)"
                    persistent-placeholder
                    return-object
                    :loading="loadingTransactionSummaryList"
                    :readonly="loadingTransactionSummaryList"
                    name="transactionSummaryName"
                    :data-vv-as="$t('text.transactionSummaryName')"
                    v-validate="'required'"
                    :error-messages="errors.collect('transactionSummaryName')"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col>
                  <v-textarea
                    outlined
                    hide-details
                    readonly
                    rows="3"
                    :label="$t('form.note')"
                    :value="selectedTransactionSummary && selectedTransactionSummary.note"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>

        <!-- Web行動の内部絞り込み -->
        <template v-if="selectedGroup.filterRuleType === FILTER_RULE_TYPE.WEB_TRANSACTION.value">
          <v-subheader>{{ $t('text.segmentSetting.internalFilter') }}</v-subheader>
          <v-card tile outlined class="mb-5 pt-3">
            <v-card-text class="px-3 pb-3">
              <v-autocomplete
                v-model="internalFilterPage"
                :label="$t(`form.segmentSetting.segmentFilter.page`)"
                placeholder="未選択の場合は全てを参照します"
                :items="pages"
                :search-input.sync="searchPage"
                :loading="loadingPages"
                :readonly="loadingPages && !hasPagesBeenUpdated"
                item-text="url"
                item-value="pageId"
                clearable
                dense
                cache-items
                return-object>
                <template v-slot:append-item>
                  <div v-intersect="onIntersect" v-if="pagesPaging.filteredTotalCount > ((pagesPaging.index + 1) * pagesPaging.limit)" class="px-4 py-3 text-center">
                    <v-progress-circular indeterminate color="primary" />
                  </div>
                </template>
              </v-autocomplete>
            </v-card-text>
          </v-card>
        </template>

        <!-- 対象期間・曜日・日付 (Web行動の内部絞り込み) -->
        <template v-if="selectedGroup.filterRuleType === FILTER_RULE_TYPE.WEB_TRANSACTION.value">
          <v-subheader>{{ $t('text.segmentSetting.periodSetting') }}</v-subheader>
          <v-card tile outlined class="mb-5 pt-3">
            <v-card-text class="px-3 pb-3">
              <period-form
                :group="selectedGroup"
                @changePeriodType="selectedGroup.periodType = $event"
                @changeStartDate="selectedGroup.startDate = $event"
                @changeEndDate="selectedGroup.endDate = $event"
                @changeRelativeDayFrom="selectedGroup.relativeDayFrom = $event"
                @changeRelativeDayTo="selectedGroup.relativeDayTo = $event"
                @changeDayOfWeek="selectedGroup.dayOfWeek = $event"
                @changeDayOfMonth="selectedGroup.dayOfMonth = $event"
              />
            </v-card-text>
          </v-card>
        </template>

        <v-subheader>{{ $t('text.segmentSetting.filterRule') }}</v-subheader>
        <v-card tile outlined class="filterRule">
          <v-card-text class="pa-3">
            <div class="pb-1">{{ $t('text.segmentSetting.operatorsCannotMixed') }}</div>
            <v-chip v-if="selectedGroup.logicalOperatorType === LOGICAL_OPERATOR_TYPE.AND.value" color="and">AND</v-chip>
            <v-chip v-else-if="selectedGroup.logicalOperatorType === LOGICAL_OPERATOR_TYPE.OR.value" color="or">OR</v-chip>
            <!-- 3 - x で x (LOGICAL_OPERATOR_TYPE: 1 or 2) の 状態変換 -->
            <v-btn icon @click="selectedGroup.logicalOperatorType = 3 - selectedGroup.logicalOperatorType"><v-icon>ic-arrow-updown</v-icon></v-btn>
            <span>{{ $t('text.segmentSetting.batchToggle') }}</span>

            <div class="d-flex align-center">
              <!-- 削除ボタン -->
              <div class="label ml-8 px-3">
                <v-row>
                  <v-col cols="5" v-if="selectedGroup.filterRuleType !== FILTER_RULE_TYPE.TRANSACTION_SUMMARY.value">{{ $t('form.segmentSetting.segmentFilter.column.target') }}</v-col>
                  <v-col cols="4">{{ $t('form.segmentSetting.segmentFilter.column.value') }}</v-col>
                  <v-col cols="3">{{ $t('form.segmentSetting.segmentFilter.column.operator') }}</v-col>
                </v-row>
              </div>
            </div>

            <template v-for="(child, index) in selectedGroup.children">
              <div
                v-if="index !== 0"
                :key="index + '_chip'"
                class="my-3 d-flex justify-center align-content-center"
              >
                <v-chip v-if="selectedGroup.logicalOperatorType === LOGICAL_OPERATOR_TYPE.AND.value" color="and">AND</v-chip>
                <v-chip v-else-if="selectedGroup.logicalOperatorType === LOGICAL_OPERATOR_TYPE.OR.value" color="or">OR</v-chip>
              </div>
              <rule-form
                :index="index"
                :key="index"
                :rule="child"
                :loading="selectedGroup.filterRuleType === FILTER_RULE_TYPE.ATTRIBUTE.value && loadingScvColumn"
                :filterColumnList="filterColumnList"
                :ruleType="selectedGroup.filterRuleType"
                class="my-3"
                @changeFilterColumn="changeFilterColumn($event, index)"
                @changeFilterValue="child.filterColumn.filterValue = $event"
                @changeOperatorType="changeOperatorType($event, index)"
                @changeLogicalNot="child.filterColumn.logicalNot = $event"
                @remove="onClickRemoveRuleButton(index)"
              />
            </template>
            <!-- TODO FIXME confirm するまで更新されないので条件の個数制御が効かない -->
            <v-btn fab depressed @click="onClickAddRuleButton" :disabled="filterCount >= 10">
              <v-icon>ic-add</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </template>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-btn color="denial" @click="onClickCancel">{{ $t('btn.cancel') }}</v-btn>
      <v-btn color="primary" v-if="selectedGroup.filterRuleType === null" @click="selectedGroup.filterRuleType = filterRuleType">{{ $t('btn.next') }}</v-btn>
      <v-btn color="secondary" v-if="selectedGroup.filterRuleType > 0" @click="onClickBack">{{ $t('btn.back') }}</v-btn>
      <v-btn color="primary" v-if="selectedGroup.filterRuleType > 0" @click="onClickOk">{{ $t('btn.ok') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// components
import periodForm from './periodForm.vue'
import ruleForm from './ruleForm.vue'
// mixins
import segmentFilterRule from '@/mixins/segmentFilterRule'
// enum
import FILTER_RULE_TYPE from '@/enum/FILTER_RULE_TYPE'
import INTERNAL_FILTER_TYPES from '@/enum/INTERNAL_FILTER_TYPES'
import LOGICAL_OPERATOR_TYPE from '@/enum/LOGICAL_OPERATOR_TYPE'
import OPERATOR_TYPE from '@/enum/OPERATOR_TYPE'
import USER_TYPES from '@/enum/USER_TYPES'
import FILTER_TYPE from '@/enum/FILTER_TYPE'
// util
import notifyUtil from '@/utils/notifyUtil'
import segmentFilterUtil from '@/utils/segmentFilterUtil'
import moment from 'moment-timezone'

export default {
  name: 'ruleGroupForm',
  mixins: [
    FILTER_RULE_TYPE,
    INTERNAL_FILTER_TYPES,
    LOGICAL_OPERATOR_TYPE,
    OPERATOR_TYPE,
    USER_TYPES,
    FILTER_TYPE,
    segmentFilterRule,
    notifyUtil,
    segmentFilterUtil,
  ],
  components: {
    periodForm,
    ruleForm,
  },
  props: {
    userType: {
      type: Number,
    },
    group: {
      type: Object,
    },
    filterCount: {
      type: Number,
    },
    scvColumns: {
      type: Array,
    },
    loadingScvColumn: {
      type: Boolean,
    },
    transactionSummaryList: {
      type: Array,
    },
    loadingTransactionSummaryList: {
      type: Boolean,
    },
    initialPages: {
      type: Array,
    },
    initialPagesPaging: {
      type: Object,
    },
    loadingPages: {
      type: Boolean,
    },
  },
  data () {
    return {
      filterRuleType: null,
      selectedGroup: null,
      selectedTransactionSummary: {
        dataType: null,
        filterLogicalName: null,
        filterPhysicalName: null,
        filterType: null,
        note: null,
        transactionSummarySettingId: null,
      },
      pages: [],
      searchPage: null,
      pagesPaging: {
        filteredTotalCount: null,
        totalCount: null,
        index: null,
        limit: null,
      },
      searchTimerId: null,
      hasPagesBeenUpdated: false,
    }
  },
  watch: {
    initialPages: {
      handler () {
        if (!this.hasPagesBeenUpdated) {
          this.pages = this.initialPages
          this.pagesPaging = this.initialPagesPaging
        }
      },
      immediate: true,
    },
    /**
     * URL選択におけるAPI検索のための入力監視
     */
    searchPage (url) {
      clearTimeout(this.searchTimerId)

      // delay new call 500ms
      this.searchTimerId = setTimeout(() => {
        this.$store.dispatch('pages/getPages', url === null ? null : encodeURI(url))
          .then((res) => {
            this.pages = res.pages
            this.pagesPaging = res.paging
            this.hasPagesBeenUpdated = true
          })
          .catch((err) => {
            this.handleErrorResponse(err)
          })
      }, 500)
    },
  },
  computed: {
    ...mapGetters('auth', ['canGetThis']),
    isUnknown () {
      return this.userType === this.USER_TYPES.USER_UNKNOWN.value
    },
    filterColumnList () {
      if (this.selectedGroup.filterRuleType === this.FILTER_RULE_TYPE.ATTRIBUTE.value) {
        return this.scvColumns
      } else if (this.selectedGroup.filterRuleType === this.FILTER_RULE_TYPE.WEB_TRANSACTION.value) {
        return [this.FILTER_TYPE.SESSION_CNT, this.FILTER_TYPE.PV, this.FILTER_TYPE.SUMMARY_TIME_ON_SITE]
      } else if (this.selectedGroup.filterRuleType === this.FILTER_RULE_TYPE.TRANSACTION_SUMMARY.value) {
        return this.transactionSummaryList
      }
      return []
    },
    internalFilterPage: {
      get () {
        return this.selectedGroup.internalFilterColumn?.filterValue
      },
      set (newVal) {
        if (newVal === null) {
          // undefined では、search.vue#confirmFilterForm で Object.assign が無視されるので null を入れる
          this.selectedGroup.internalFilterColumn = null
        } else {
          const filterColumn = {
            dataType: this.DATA_TYPES.LONG.value,
            filterValue: newVal.pageId,
            operatorType: this.OPERATOR_TYPE.EQ.value,
            filterLogicalName: newVal.url,
            filterPhysicalName: this.INTERNAL_FILTER_TYPES.PAGE.filterPhysicalName,
            internalFilterType: this.INTERNAL_FILTER_TYPES.PAGE.internalFilterType,
          }
          this.selectedGroup.internalFilterColumn = filterColumn
        }
      },
    },
  },
  created () {
    this.resetRuleGroup()

    // 内部絞り込み対象が設定済みで、選択内容が初期表示に含まれてない場合があるので、設定値で検索をかけておく
    if (this.selectedGroup.internalFilterColumn?.filterLogicalName) {
      const isIncludedInPages = this.pages?.some((u) => u.url === this.selectedGroup.internalFilterColumn.filterLogicalName)
      if (!isIncludedInPages) {
        this.$store.dispatch('pages/getPages', this.selectedGroup.internalFilterColumn.filterLogicalName)
          .then((res) => {
            this.pages = res.pages
            this.pagesPaging = res.paging
            this.hasPagesBeenUpdated = true
          })
          .catch((err) => {
            this.handleErrorResponse(err)
          })
      }
    }
  },
  methods: {
    resetRuleGroup () {
      this.selectedGroup = JSON.parse(JSON.stringify(this.group))
      this.filterRuleType = this.selectedGroup.filterRuleType
      if (this.selectedGroup.filterRuleType === this.FILTER_RULE_TYPE.TRANSACTION_SUMMARY.value) {
        this.selectedTransactionSummary = this.transactionSummaryList.find((v) => v.transactionSummarySettingId === this.selectedGroup.children[0].filterColumn.transactionSummarySettingId)
      }
    },
    onClickAddRuleButton () {
      const newFilterRule = this.addFilterRule()
      // filterRuleType がトランザクションサマリで、既にトランザクションサマリが選択されている場合は新規ルールの型情報等が決定するので詰める
      if (this.selectedTransactionSummary.transactionSummarySettingId !== null) {
        newFilterRule.filterColumn.filterType = this.selectedTransactionSummary.filterType
        newFilterRule.filterColumn.dataType = this.selectedTransactionSummary.dataType
        newFilterRule.filterColumn.filterLogicalName = this.selectedTransactionSummary.filterLogicalName
        newFilterRule.filterColumn.filterPhysicalName = this.selectedTransactionSummary.filterPhysicalName
        newFilterRule.filterColumn.transactionSummarySettingId = this.selectedTransactionSummary.transactionSummarySettingId
      }
      this.selectedGroup.children.push(newFilterRule)
    },
    changeFilterColumn (newVal, index) {
      this.selectedGroup.children[index].filterColumn.filterType = newVal.filterType
      this.selectedGroup.children[index].filterColumn.dataType = newVal.dataType
      this.selectedGroup.children[index].filterColumn.filterLogicalName = newVal.filterLogicalName
      this.selectedGroup.children[index].filterColumn.filterPhysicalName = newVal.filterPhysicalName
      // フィルター項目変更時に一致条件と条件値を初期化
      this.selectedGroup.children[index].filterColumn.filterValue = null
      this.selectedGroup.children[index].filterColumn.operatorType = null
      this.selectedGroup.children[index].filterColumn.logicalNot = false
    },
    /**
     * 一致条件の変更時に設定値を検証・変更
     * @param  {Object} newVal 選択したカラム
     * @param index 編集対象条件のインデックス
     */
    changeOperatorType (newVal, index) {
      if (this.isRelativeDateOperator(newVal)) {
        // dataTypeが変更される場合, valueを初期化
        if (this.selectedGroup.children[index].filterColumn.dataType !== this.DATA_TYPES.LONG.value) {
          this.selectedGroup.children[index].filterColumn.filterValue = null
        }
        // 相対日付関連の演算子は数値型として扱う
        this.selectedGroup.children[index].filterColumn.dataType = this.DATA_TYPES.LONG.value
      } else {
        // 親(同物理名)のdataTypeと食い違っていた場合, 条件値を初期化 & dataTypeを親に合わせて整合性を保つ
        this.filterColumnList.forEach(e => {
          if (e.filterPhysicalName === this.selectedGroup.children[index].filterColumn.filterPhysicalName && e.dataType !== this.selectedGroup.children[index].filterColumn.dataType) {
            this.selectedGroup.children[index].filterColumn.filterValue = null
            this.selectedGroup.children[index].filterColumn.dataType = e.dataType
          }
        })
      }
      // 一致条件を NULL, NOT_NULL に変更時に条件値を初期化
      if (newVal === this.OPERATOR_TYPE.IS_NULL.value || newVal === this.OPERATOR_TYPE.IS_NOT_NULL.value) {
        this.selectedGroup.children[index].filterColumn.filterValue = ''
      }
      this.selectedGroup.children[index].filterColumn.operatorType = newVal
    },
    onClickRemoveRuleButton (index) {
      this.selectedGroup.children.splice(index, 1)

      if (this.selectedGroup.children.length === 0) {
        this.notifyErrorMessage('フィルタリング対象と条件は必須です')
        this.onClickAddRuleButton()
      }
    },
    onClickCancel () {
      this.$emit('cancel')
      // チラつき防止
      setTimeout(() => { this.resetRuleGroup() }, 500)
    },
    onClickBack () {
      const tempDepth = this.selectedGroup.depth
      // 条件の初期化
      this.selectedGroup = this.addFilterRuleGroup()
      this.selectedGroup.depth = tempDepth
      this.selectedTransactionSummary = {
        dataType: null,
        filterLogicalName: null,
        filterPhysicalName: null,
        filterType: null,
        note: null,
        transactionSummarySettingId: null,
      }
    },
    async onClickOk () {
      let validation = true

      await this.$validator.validateAll().then((result) => {
        if (!result) validation = false
      })
      if (!validation) return

      switch (this.selectedGroup.periodType) {
        case this.PERIOD_TYPES.CUSTOM.value:
          if (!this.selectedGroup.startDate && !this.selectedGroup.endDate) {
            validation = false
            this.notifyErrorMessage(this.$t('notify.error.segments.requireStartDateOrEndDate'))
          }
          // 開始・終了日付の整合性確認
          if (this.selectedGroup.startDate && this.selectedGroup.endDate) {
            const startDateMoment = moment(this.selectedGroup.startDate).tz('Asia/Tokyo')
            const endDateMoment = moment(this.selectedGroup.endDate).tz('Asia/Tokyo')
            // 開始日が終了日以降になっていないか確認
            if (endDateMoment.diff(startDateMoment, 'day') < 0) {
              validation = false
              this.notifyErrorMessage(this.$t('notify.error.segments.mustBeStartDateBeforeEndDate'))
            }
          }
          break
        case this.PERIOD_TYPES.CUSTOM_RELATIVE_PERIOD.value:
          if (!this.selectedGroup.relativeDayFrom && !this.selectedGroup.relativeDayTo) {
            validation = false
            this.notifyErrorMessage(this.$t('notify.error.segments.requireRelativeDayFromOrRelativeDayTo'))
          }
          if (this.selectedGroup.relativeDayFrom && this.selectedGroup.relativeDayTo && Number(this.selectedGroup.relativeDayFrom) < Number(this.selectedGroup.relativeDayTo)) {
            validation = false
            this.notifyErrorMessage(this.$t('notify.error.segments.mustBeRelativeDayFromBeforeRelativeDayTo'))
          }
          break
      }
      if (validation) this.$emit('applyRuleGroup', this.selectedGroup)
    },
    changeTransactionSummary (newVal) {
      this.selectedTransactionSummary = newVal
      this.selectedGroup.children.forEach(rule => {
        // return-object でオブジェクトそのものが filterType に入ってるので上書き
        rule.filterColumn.filterType = newVal.filterType
        rule.filterColumn.dataType = newVal.dataType
        rule.filterColumn.filterLogicalName = newVal.filterLogicalName
        rule.filterColumn.filterPhysicalName = newVal.filterPhysicalName
        rule.filterColumn.transactionSummarySettingId = newVal.transactionSummarySettingId
      })
    },
    onIntersect (entries, observer, isIntersecting) {
      if (isIntersecting && !this.loadingPages) {
        this.$store.dispatch('pages/getNextPages', { url: this.searchPage, page: this.pagesPaging.index })
          .then((res) => {
            this.pages = [...this.pages, ...res.pages]
            this.pagesPaging = res.paging
            this.hasPagesBeenUpdated = true
          })
          .catch((err) => {
            this.handleErrorResponse(err)
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-subheader) using ($integral-core-theme) {
  color: map-deep-get($integral-core-theme, 'views', 'segmentSetting', 'segmentForms', 'ruleGroupForm', 'text');
}

.v-subheader {
  margin: 0 0 6px;
  padding: 0;
  font-size: 12px;
  line-height: 16px;
  height: auto;
}

.filterRuleTypes {
  .v-card__text {
    padding: 10px;

    .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;

      .v-radio {
        margin-right: 118px;
        ::v-deep .v-label {
          font-size: 14px !important;
        }
      }
    }
  }
}
.filterRule {
  .v-card__text {
    line-height: 16px;
    font-size: 12px;
    .v-btn--icon {
      height: 24px;
      width: 24px;
    }
  }
}
.label {
  font-size: 12px;
  line-height: 16px;
  padding-top: 14px;
  margin-bottom: -6px;
  width: 100%;
}
</style>
