var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"items":_vm.enumUtil.convertForSelectList(_vm.AWS_S3_REGION),"clearable":"","hide-no-data":"","label":_vm.$t("form.region"),"name":"region","data-vv-as":_vm.$t("form.region"),"error-messages":_vm.errors.collect('region')},model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"label":_vm.$t("form.jobSetting.jobTask.bucket"),"name":"bucket","data-vv-as":_vm.$t("form.jobSetting.jobTask.bucket"),"error-messages":_vm.errors.collect('bucket')},model:{value:(_vm.bucket),callback:function ($$v) {_vm.bucket=$$v},expression:"bucket"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"label":_vm.$t("form.jobSetting.jobTask.file"),"name":"filepath","data-vv-as":_vm.$t("form.jobSetting.jobTask.file"),"error-messages":_vm.errors.collect('filepath')},model:{value:(_vm.filePath),callback:function ($$v) {_vm.filePath=$$v},expression:"filePath"}},[_c('v-tooltip',{attrs:{"slot":"append","bottom":"","content-class":"placeholderTooltip"},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("ic-help-S")])]}}])},[_c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-card-title',[_vm._v("利用可能なプレースホルダー")]),_c('v-card-text',{staticClass:"pa-3"},[_c('v-data-table',{attrs:{"headers":[{ text: 'プレースホルダ', value: 'placeholder' }, { text: '出力', value: 'output' } ],"items":[
                  { placeholder: '%%env.yyyy%%', output: '年(YYYY)' },
                  { placeholder: '%%env.mm%%', output: '月(MM)' },
                  { placeholder: '%%env.dd%%', output: '日(DD)' },
                  { placeholder: '%%env.hh%%', output: '時(HH)' },
                  { placeholder: '%%env.mi%%', output: '分(MI)' },
                  { placeholder: '%%env.ss%%', output: '秒(SS)' },
                  { placeholder: '%%job_setting_id%%', output: 'JOB設定ID' },
                  { placeholder: '%%job_task_setting_id%%', output: 'JOBタスク設定ID' } ],"hide-default-header":"","hide-default-footer":"","disable-pagination":"","disable-sort":""}})],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"autocomplete":"new-password","label":_vm.$t("form.accessKey"),"name":"accesskey","data-vv-as":_vm.$t("form.accessKey"),"error-messages":_vm.errors.collect('accesskey')},model:{value:(_vm.accessKey),callback:function ($$v) {_vm.accessKey=$$v},expression:"accessKey"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"autocomplete":"new-password","type":"password","label":_vm.$t("form.secretKey"),"name":"secretkey","data-vv-as":_vm.$t("form.secretKey"),"error-messages":_vm.errors.collect('secretkey')},model:{value:(_vm.secretKey),callback:function ($$v) {_vm.secretKey=$$v},expression:"secretKey"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }