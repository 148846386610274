export default {
  data () {
    return {
      // WebAPIのETL_TYPE参照
      INPUT_ETL_TYPES: {
        S3: { text: 'S3', value: 1 },
        AURORA: { text: 'MySQL', value: 2 }, // MySQLが正
        REDSHIFT: { text: 'Redshift', value: 3 },
        SEGMENT: { text: 'セグメント', value: 4 },
        GCS: { text: 'GCS', value: 5 },
        SFTP: { text: 'SFTP', value: 6 },
      },
    }
  },
}
