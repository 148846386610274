<template>
  <div>
    <v-card tile outlined>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <!-- セグメント名 -->
            <v-text-field
              v-model="segmentName"
              :label="$t(`form.segmentSetting.segment.name`)"
              :placeholder="$t(`form.segmentSetting.segment.namePlaceholder`)"
              persistent-placeholder
              name="segmentName"
              :data-vv-as="$t(`form.segmentSetting.segment.name`)"
              v-validate="'required'"
              :error-messages="errors.collect('segmentName')" />
            <!-- 説明 -->
            <v-textarea
              rows="4"
              auto-grow
              v-model="note"
              :label="$t(`form.note`)"
              name="note"
              :data-vv-as="$t(`form.note`)"
              v-validate="'max:100'"
              :error-messages="errors.collect('note')"
            ></v-textarea>
          </v-col>
          <v-col cols="4" class="mt-n1 userType">
            <!-- セグメントユーザー種別 -->
            <div>{{ $t(`form.segmentSetting.segment.userType`) }}</div>
            <v-radio-group v-model.number="userType" :disabled="isUpdateMode" row class="pt-0" @change="changeUserType">
              <v-radio :value="Number(3)" color="inputSelectionControl">
                <template v-slot:label>
                  <v-icon class="mr-2">ic-customer-M</v-icon>
                  {{ $t(`text.userScv`) }}
                </template>
              </v-radio>
              <v-radio :value="Number(1)" color="inputSelectionControl">
                <template v-slot:label>
                  <v-icon class="mr-2">ic-anonymous-M</v-icon>
                  {{ $t(`text.userUnknown`) }}
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- フィルタ条件 -->
    <div v-if="buildType === SEGMENT_BUILD_TYPE.STANDARD.value">
      <v-subheader class="mt-5 pl-0">
        {{ $t(`form.segmentSetting.segmentFilter.title`) }}({{ filterCount }}件)
      </v-subheader>
      <rule-group
        ref="filterRuleGroup"
        :userType="userType"
        :group="ruleGroup"
        :filterCount="filterCount"
        :scvColumns="scvColumns"
        :loadingScvColumn="loadingScvSettings"
        :initialPages="initialPages"
        :initialPagesPaging="initialPagesPaging"
        :loadingPages="loadingPages"
        :transactionSummaryList="transactionSummaryList"
        :loadingTransactionSummaryList="loadingTransactionSummaries"
        @switchLogicalOperatorType="switchLogicalOperatorType"
        @removeGroup="removeRuleGroup"
        @onClickAddRuleGroupButton="onClickAddRuleGroupButton"
      />
      <div class="caption">同一グループでANDとORを混在することはできません</div>
    </div>

    <!-- TODO i18n -->
    <div v-if="buildType === SEGMENT_BUILD_TYPE.CUSTOM.value" class="mt-5">
      スケジュール設定
      <v-card outlined tile class="mt-2" height="82px">
        <v-card-text>
          <v-row>
            <!-- スケジュール種別 -->
            <v-col cols="12" md="4">
              <v-select
                v-model="jobSetting.recurringType"
                class="pb-3 mb-n1"
                :items="enumUtil.convertForSelectList(JOB_RECURRING_TYPES)"
                name="recurringType"
                @change="resetJobSettingWithoutRecurringType"
                :data-vv-as="$t(`form.jobSetting.job.schedule`)"
                v-validate="'required'"
                :error-messages="errors.collect('recurringType')" />
            </v-col>
            <!-- 日時 -->
            <v-col cols="8" v-show="jobSetting.recurringType === JOB_RECURRING_TYPES.ONETIME.value">
              <!-- TODO  data-vv-asが効いていない-->
              <datetime-picker
                ref="datetimePicker"
                v-model="execDateTime"
                :label="$t('form.jobSetting.job.execDateTime')"
                name="execDateTime"
                :data-vv-as="$t('form.jobSetting.job.execDateTime')"
                v-validate="jobSetting.recurringType === JOB_RECURRING_TYPES.ONETIME.value ? 'required' : '' "
                :error-messages="errors.collect('execDateTime')"
              />
            </v-col>
            <!-- 時刻 -->
            <v-col cols="4"
                   v-show="jobSetting.recurringType === JOB_RECURRING_TYPES.DAILY.value ||
                      jobSetting.recurringType === JOB_RECURRING_TYPES.WEEKLY.value ||
                      jobSetting.recurringType === JOB_RECURRING_TYPES.MONTHLY.value">
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="execHours"
                    :items="enumUtil.convertForSelectList(HOUR_LIST)"
                    :suffix="'時'"
                    :error-messages="errors.collect('execTime')"
                  />
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="execMinutes"
                    :items="enumUtil.convertForSelectList(MINUTES_LIST)"
                    :suffix="'分'"
                    :error-messages="errors.collect('execTime')"
                  />
                </v-col>
              </v-row>
            </v-col>
            <!-- 曜日 -->
            <v-col cols="4" v-show="jobSetting.recurringType === JOB_RECURRING_TYPES.WEEKLY.value" >
              <v-select
                v-model="jobSetting.jobSettingWeekly"
                multiple
                :label="$t(`form.jobSetting.job.execWeekly`)"
                :items="enumUtil.convertForSelectList(DAY_OF_WEEK_LIST)"
                name="execWeekly"
                :data-vv-as="$t(`form.jobSetting.job.execWeekly`)"
                v-validate="jobSetting.recurringType === JOB_RECURRING_TYPES.WEEKLY.value ? 'required' : '' "
                :error-messages="errors.collect('execWeekly')"
                @change="jobSetting.jobSettingWeekly.sort((x, y) => x - y)">
                <template #prepend-item>
                  <v-list-item ripple @click="selectAllWeek">
                    <v-list-item-action>
                      <v-icon>{{ dayOfWeekIcon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t(`form.selectAll`) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                </template>
              </v-select>
            </v-col>
            <!-- 月次 -->
            <v-col cols="4" v-show="jobSetting.recurringType === JOB_RECURRING_TYPES.MONTHLY.value">
              <v-select
                v-model="jobSetting.jobSettingMonthly"
                multiple
                :label="$t(`form.jobSetting.job.execMonthly`)"
                :items="enumUtil.convertForSelectList(DAY_OF_MONTH_LIST)"
                name="execMonthly"
                :data-vv-as="$t(`form.jobSetting.job.execMonthly`)"
                v-validate="jobSetting.recurringType === JOB_RECURRING_TYPES.MONTHLY.value ? 'required' : '' "
                :error-messages="errors.collect('execMonthly')"
                @change="jobSetting.jobSettingMonthly.sort((x, y) => x - y)">
                <template #prepend-item>
                  <v-list-item ripple @click="selectAllDay">
                    <v-list-item-action>
                      <v-icon>{{ dayOfMonthIcon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t(`form.selectAll`) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                </template>
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">
                    {{ item.text }}
                  </span>
                  <span v-if="index !== 0 && index <= 5">
                    , {{ item.text }}
                  </span>
                  <span v-if="index === 6" class="grey--text caption">
                    (+{{ jobSetting.jobSettingMonthly.length - 5 }} others)
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <!-- SQL エディタ -->
    <div v-if="buildType === SEGMENT_BUILD_TYPE.CUSTOM.value" class="mt-5">
      <v-row no-gutters>
        <v-col>
          SQL
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn class="mr-8" color="denial" :to="segmentId ? '/segment/' + segmentId : '/segments'">
            {{ $t(`btn.cancel`) }}
          </v-btn>
          <v-btn class="mr-8" color="primary" v-if="this.isUpdateMode" @click="validateAndBuildingRequestParameter()" :disabled="!canPut">
            {{ $t(`btn.segment.update`) }}
          </v-btn>
          <v-btn class="mr-8" color="primary" v-else @click="validateAndBuildingRequestParameter()" :disabled="!canPost">
            {{ $t(`btn.segment.create`) }}
          </v-btn>
        </v-col>
      </v-row>
      <v-card flat outlined tile class="mt-2">
        <v-card-text class="pa-3">
          <v-row>
            <v-col>
              <sql-editor class="editor" v-model="sql" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <!-- 確認モーダル -->
    <v-dialog
      v-model="showModal"
      width="40%"
      overlay-color="#07070820"
      overlay-opacity="1"
    >
      <v-card>
        <v-card-title>
          {{ modalContent }}
        </v-card-title>
        <v-card-text>
          <div>{{ $t(`form.segmentSetting.segment.name`) }}：{{segmentName}}</div>
          <div>{{ $t(`form.segmentSetting.segment.userType`) }}：{{userType | userFilter }}</div>
          <div v-if="buildType === SEGMENT_BUILD_TYPE.STANDARD.value">
            {{ $t(`text.periodType`) }} / {{ $t('text.filter' ) }}：{{ filterTextObject.logicalOperatorType }}
            <ul class="modal_text">
              <li v-for="(filter, index) in filterTextObject.filterTextList" :key="'filterText_' + String(index)">
                {{ filter.period }} {{ filter.internalFilter === '' ? '' : '/ ' + filter.internalFilter }} / {{ filter.filter }}
              </li>
            </ul>
          </div>
          <div v-if="buildType === SEGMENT_BUILD_TYPE.CUSTOM.value">
            <div>{{ $t(`form.jobSetting.job.schedule`) }}：{{ enumUtil.getEnumFromValue(JOB_RECURRING_TYPES, jobSetting.recurringType).text }}</div>
            <v-textarea v-if="buildType === SEGMENT_BUILD_TYPE.CUSTOM.value" v-model="sql" filled readonly no-resize/>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="denial" @click="showModal = false">{{ $t(`btn.no`) }}</v-btn>
          <v-btn color="primary" @click="requestSettingSegment()">{{ $t(`btn.yes`) }}</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// components
import datetimePicker from '@/components/public/datetimePicker'
import sqlEditor from '@/components/common/sqlEditor'
import ruleGroup from './segmentForms/ruleGroup.vue'
// enum
import COLUMN_REFERENCE_TYPES from '@/enum/COLUMN_REFERENCE_TYPES'
import DATA_TYPES from '@/enum/DATA_TYPES'
import FILTER_TYPE from '@/enum/FILTER_TYPE'
import JOB_RECURRING_TYPES from '@/enum/JOB_RECURRING_TYPES'
import OPERATOR_TYPE from '@/enum/OPERATOR_TYPE'
import PERIOD_TYPES from '@/enum/PERIOD_TYPES'
import SEGMENT_BUILD_TYPE from '@/enum/SEGMENT_BUILD_TYPE'
import USER_TYPES from '@/enum/USER_TYPES'
import SEGMENT_SUMMARIES from '@/enum/SEGMENT_SUMMARIES'
import DAY_OF_MONTH_LIST from '@/enum/DAY_OF_MONTH_LIST'
import DAY_OF_WEEK_LIST from '@/enum/DAY_OF_WEEK_LIST'
import HOUR_LIST from '@/enum/HOUR_LIST'
import MINUTES_LIST from '@/enum/MINUTES_LIST'
// mixins
import segmentFilterRule from '@/mixins/segmentFilterRule'
// util
import convertUtil from '@/utils/convertUtil'
import enumUtil from '@/utils/enumUtil'
import momentUtil from '@/utils/momentUtil'
import segmentFilterUtil from '@/utils/segmentFilterUtil'
import segmentTexts from '@/utils/segmentTexts'
import notifyUtil from '@/utils/notifyUtil'

// const UPPER_LIMIT_PERIOD_MONTH = 3 // セグメント指定月間上限
const SEGMENT_EDIT_PAGE_NAME = 'SegmentSettingEdit'

export default {
  mixins: [
    COLUMN_REFERENCE_TYPES,
    DATA_TYPES,
    FILTER_TYPE,
    JOB_RECURRING_TYPES,
    OPERATOR_TYPE,
    PERIOD_TYPES,
    SEGMENT_BUILD_TYPE,
    SEGMENT_SUMMARIES,
    USER_TYPES,
    DAY_OF_MONTH_LIST,
    DAY_OF_WEEK_LIST,
    HOUR_LIST,
    MINUTES_LIST,
    segmentFilterRule,
    convertUtil,
    enumUtil,
    notifyUtil,
    momentUtil,
    segmentFilterUtil,
    segmentTexts,
  ],
  components: {
    ruleGroup,
    datetimePicker,
    sqlEditor,
  },
  props: {
    buildType: {
      type: Number,
      required: true,
    },
    isProcessing: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      scvColumns: [],
      showModal: false,
      modalContent: '',
      userType: 3, // 初期値はSCV
      segmentSetting: {},
      scvSql: 'SELECT scv_id FROM scv_data',
      unknownSql: 'SELECT unknown_id FROM user_unknown',
      sql: 'SELECT scv_id FROM scv_data',
      segmentName: '',
      note: '',
      initialPages: [],
      initialPagesPaging: {},
      jobSetting: {
        recurringType: 0,
        execDate: null,
        execTime: null,
        jobSettingWeekly: [],
        jobSettingMonthly: [],
      },
      requestParameter: {
        segmentName: '',
        userType: '',
        buildType: '',
        note: '',
        filterQuery: '',
        filterRuleJson: '',
      },
      unknownRuleGroup: [],
      scvRuleGroup: [],
      filterTextObject: {
        logicalOperatorType: '',
        filterTextList: [],
      },
      transactionSummaryList: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['canPost', 'canPut']),
    segmentId () {
      return this.$route.params.segmentId
    },
    loadingScvSettings () {
      return this.$store.state.scvSettings.loadingScvSettings
    },
    loadingPages () {
      return this.$store.state.pages.loadingPages
    },
    isUpdateMode () {
      return this.$route.name === SEGMENT_EDIT_PAGE_NAME
    },
    isUnknown () {
      return this.userType === this.USER_TYPES.USER_UNKNOWN.value
    },
    isScv () {
      return this.userType === this.USER_TYPES.SCV.value
    },
    ruleGroup () {
      if (this.isUnknown) {
        return this.unknownRuleGroup
      } else if (this.isScv) {
        return this.scvRuleGroup
      }
      return []
    },
    filterCount () {
      let count = 0
      if (!this.ruleGroup.children) return count
      this.ruleGroup.children.forEach(rule => {
        if (rule.filterColumn) count++
        if (rule.children) {
          count = count + rule.children.length
        }
      })
      return count
    },
    // 曜日全選択チェックフラグ
    checkedAllDayOfWeek () {
      return this.jobSetting.jobSettingWeekly?.length === Object.keys(this.DAY_OF_WEEK_LIST).length
    },
    // 曜日一つ以上選択チェックフラグ
    checkedSomeDayOfWeek () {
      return this.jobSetting.jobSettingWeekly?.length > 0 && !this.checkedAllDayOfWeek
    },
    // 曜日選択での全選択項目のアイコン
    dayOfWeekIcon () {
      // 全選択、一つ以上選択、未選択でアイコンを切り替え
      if (this.checkedAllDayOfWeek) return 'mdi-checkbox-marked-outline'
      if (this.checkedSomeDayOfWeek) return 'mdi-checkbox-intermediate'
      return 'mdi-checkbox-blank-outline'
    },
    // 日にち全選択チェックフラグ
    checkedAllDayOfMonth () {
      return this.jobSetting.jobSettingMonthly?.length === Object.keys(this.DAY_OF_MONTH_LIST).length
    },
    // 日にち一つ以上選択チェックフラグ
    checkedSomeDayOfMonth () {
      return this.jobSetting.jobSettingMonthly?.length > 0 && !this.checkedAllDayOfMonth
    },
    // 日にち選択での全選択項目のアイコン
    dayOfMonthIcon () {
      // 全選択、一つ以上選択、未選択でアイコンを切り替え
      if (this.checkedAllDayOfMonth) return 'mdi-checkbox-marked-outline'
      if (this.checkedSomeDayOfMonth) return 'mdi-checkbox-intermediate'
      return 'mdi-checkbox-blank-outline'
    },
    execDateTime: {
      get () {
        if (this.jobSetting.execDate === null || this.jobSetting.execTime === null) return null
        const dataTimeString = this.jobSetting.execDate + ' ' + this.jobSetting.execTime + ':00'
        return isNaN(Date.parse(dataTimeString)) ? null : this.convertIsoToDateTimeString(new Date(dataTimeString))
      },
      set (newVal) {
        if (isNaN(Date.parse(newVal))) {
          this.jobSetting.execDate = null
          this.jobSetting.execTime = '00:00'
          return
        }
        const datetime = new Date(newVal)
        this.jobSetting.execDate = datetime.getFullYear() + '-' +
          (('0' + (datetime.getMonth() + 1)).slice(-2)) + '-' +
          ('0' + datetime.getDate()).slice(-2)
        this.jobSetting.execTime = ('0' + datetime.getHours()).slice(-2) + ':' + ('0' + datetime.getMinutes()).slice(-2)
      },
    },
    execHours: {
      get () {
        const datetime = isNaN(Date.parse(this.jobSetting.execTime)) ? new Date() : new Date(this.jobSetting.execTime)
        return ('0' + datetime.getHours()).slice(-2)
      },
      set (newVal) {
        this.jobSetting.execTime = newVal + ':' + this.execMinutes
      },
    },
    execMinutes: {
      get () {
        const datetime = isNaN(Date.parse(this.jobSetting.execTime)) ? new Date() : new Date(this.jobSetting.execTime)
        return ('0' + datetime.getMinutes()).slice(-2)
      },
      set (newVal) {
        this.jobSetting.execTime = this.execHours + ':' + newVal
      },
    },
    loadingTransactionSummaries () {
      return this.$store.state.transactionSummary.loadingTransactionSummaries
    },
  },
  watch: {
    // ルートの変更の検知
    '$route' (to, from) {
      // data部リセット  // TODO: data部のリセットで何が起きているのかコメントを追記する
      Object.assign(this.$data, this.$options.data())
      // state部リセット // TODO: ここでstoreのstateを初期化しているが大丈夫なのか調査
      Object.assign(this.$store.state, this.$store.defaultState)
    },
  },
  filters: {
    buildFilter (val) {
      if (val === 1) return '標準'
      if (val === 2) return 'カスタム'
    },
    userFilter (val) {
      if (val === 1) return '匿名客'
      if (val === 2) return '顧客'
      if (val === 3) return 'カスタマー'
    },
  },
  created () {
    // 初期の空オブジェクトを入れる (編集時は詰替前に初期化する)
    this.unknownRuleGroup = this.addEmptyFilterRuleGroup()
    this.scvRuleGroup = this.addEmptyFilterRuleGroup()

    // フィルタ条件の各選択肢を取得
    if (this.buildType === this.SEGMENT_BUILD_TYPE.STANDARD.value) {
      this.$store.dispatch('transactionSummary/getAllTransactionSummaries')
        .then((res) => {
          for (const transactionSummary of res.data.data.transactionSummarySettingList) {
            this.transactionSummaryList.push({
              filterType: this.FILTER_TYPE.TRANSACTION_SUMMARY.filterType,
              dataType: transactionSummary.transactionSummaryOutputDataType,
              filterLogicalName: transactionSummary.transactionSummaryName,
              filterPhysicalName: transactionSummary.transactionSummaryName,
              transactionSummarySettingId: transactionSummary.transactionSummarySettingId,
              note: transactionSummary.note, // 選択時の備考用、リクエストには含めない
            })
          }
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
      this.$store.dispatch('scvSettings/getValidScvSettings')
        .then((res) => {
          const tmpScvColumnList = []
          for (const scvColumn of res.scvSettings) {
            if (scvColumn.scvColumnChoiceSettingList.length > 0) {
              tmpScvColumnList.push({
                filterType: this.FILTER_TYPE.SCV_COLUMN.filterType,
                dataType: scvColumn.dataType,
                filterLogicalName: scvColumn.scvLogicalColumnName,
                filterPhysicalName: scvColumn.scvPhysicalColumnName,
                scvColumnIndex: scvColumn.scvColumnIndex,
              })
            }
          }
          this.scvColumns = tmpScvColumnList
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
      this.$store.dispatch('pages/getPages', null)
        .then((res) => {
          this.initialPages = res.pages
          this.initialPagesPaging = res.paging
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    }

    this.fetchSettingSegments()
    this.$parent.isUpdateMode = this.isUpdateMode
  },
  methods: {
    /**
     * セグメント設定項目の格納
     */
    fetchSettingSegments () {
      if (this.segmentId) {
        this.$emit('updateLoadingStatus', true)
        this.$store.dispatch('segments/getSegment', { segmentId: this.segmentId, targetDate: this.momentUtil.getCurrentDateForSummary() })
          .then((res) => {
            this.segmentSetting = res.segment
            this.fillParams()
          })
          .catch((err) => {
            this.handleErrorResponse(err)
          }).finally(() => this.$emit('updateLoadingStatus', false))
      }
    },
    /**
     * セグメント取得後の格納処理
     */
    fillParams () {
      this.userType = this.segmentSetting.userType
      this.segmentName = this.segmentSetting.segmentName
      this.note = this.segmentSetting.note
      this.sql = this.segmentSetting.filterQuery
      // prop で親から受け取ってるので親で上書き
      this.$emit('buildTypeChanged', this.segmentSetting.buildType)

      // フィルター条件の初期化
      switch (this.segmentSetting.buildType) {
        case this.SEGMENT_BUILD_TYPE.STANDARD.value: {
          const tmpFilterRuleGroup = JSON.parse(this.segmentSetting.filterRuleJson)
          this.fillFilterRuleGroup(tmpFilterRuleGroup, 1)

          if (this.isUnknown) {
            this.unknownRuleGroup = tmpFilterRuleGroup
          } else if (this.isScv) {
            this.scvRuleGroup = tmpFilterRuleGroup
          }
          break
        }
        case this.SEGMENT_BUILD_TYPE.CUSTOM.value: {
          this.jobSetting = this.segmentSetting.customSegmentJobSetting
        }
      }
    },
    fillFilterRuleGroup (ruleGroup, depth) {
      if (Object.prototype.hasOwnProperty.call(ruleGroup, 'children')) {
        // 不足している項目を補完
        ruleGroup.isGroup = true
        ruleGroup.depth = depth
        if (!Object.prototype.hasOwnProperty.call(ruleGroup, 'startDate')) ruleGroup.startDate = ''
        if (!Object.prototype.hasOwnProperty.call(ruleGroup, 'endDate')) ruleGroup.endDate = ''
        if (!Object.prototype.hasOwnProperty.call(ruleGroup, 'relativeDayFrom')) ruleGroup.relativeDayFrom = ''
        if (!Object.prototype.hasOwnProperty.call(ruleGroup, 'relativeDayTo')) ruleGroup.relativeDayTo = ''
        if (!Object.prototype.hasOwnProperty.call(ruleGroup, 'dayOfWeek')) ruleGroup.dayOfWeek = []
        if (!Object.prototype.hasOwnProperty.call(ruleGroup, 'dayOfMonth')) ruleGroup.dayOfMonth = []

        depth += 1
        ruleGroup.children.forEach(child => {
          this.fillFilterRuleGroup(child, depth)
        })
      } else {
        if (ruleGroup.filterColumn.operatorType === this.OPERATOR_TYPE.IS_NULL.value || ruleGroup.filterColumn.operatorType === this.OPERATOR_TYPE.IS_NOT_NULL.value) return
        // 日付補完
        if (ruleGroup.filterColumn.dataType === this.DATA_TYPES.DATE.value) {
          ruleGroup.filterColumn.filterValue = this.convertIsoToDateString(ruleGroup.filterColumn.filterValue)
        }
        // 日時補完
        if (ruleGroup.filterColumn.dataType === this.DATA_TYPES.DATETIME.value) {
          ruleGroup.filterColumn.filterValue = this.convertIsoToDateTimeString(ruleGroup.filterColumn.filterValue)
        }
        // logicalNotの導入による補完
        if (!Object.prototype.hasOwnProperty.call(ruleGroup.filterColumn, 'logicalNot')) ruleGroup.filterColumn.logicalNot = false
        const typeOfReplacement = ['NOT_EQ', 'NOT_PARTIAL_MATCH', 'IS_NULL']
        for (const key of typeOfReplacement) {
          if (ruleGroup.filterColumn.operatorType === this.OPERATOR_TYPE[key].value) {
            ruleGroup.filterColumn.operatorType = this.OPERATOR_TYPE_WITH_LOGICAL_NOT[key].value
            ruleGroup.filterColumn.logicalNot = true
          }
        }
      }
    },
    /**
     * フォーム入力値の検証とリクエストパラメーターの生成
     */
    async validateAndBuildingRequestParameter () {
      if (this.isProcessing) return
      // 入力値チェック
      let formResult = true
      switch (this.buildType) {
        case this.SEGMENT_BUILD_TYPE.STANDARD.value:
          if (this.ruleGroup.children.length === 0) {
            formResult = false
            this.notifyErrorMessage(this.$t('notify.error.segments.noFilter'))
          }
          break
        case this.SEGMENT_BUILD_TYPE.CUSTOM.value:
          if (this.sql === '') {
            formResult = false
            this.notifyErrorMessage(this.$t('notify.error.segments.noFilter'))
          }
      }
      await this.$validator.validateAll().then((result) => {
        if (!result) formResult = false
      })
      for (const error of this.errors.items) {
        this.notifyErrorMessage(error.msg)
      }

      if (!formResult) return

      // リクエストパラメーター生成
      this.transferRequestParameter()

      if (this.buildType === this.SEGMENT_BUILD_TYPE.STANDARD.value) {
        this.filterTextObject = this.createSegmentFilterText(this.ruleGroup)
      }

      if (this.isUpdateMode) {
        this.modalContent = this.$t('notify.check.segments.update')
        this.requestParameter.putedSegmentId = this.segmentId
      } else {
        this.modalContent = this.$t('notify.check.segments.create')
      }
      this.showModal = true
    },
    /**
     * 入力内容をリクエストパラメーターに変換
     */
    transferRequestParameter () {
      this.requestParameter.segmentName = this.segmentName
      this.requestParameter.userType = this.userType
      this.requestParameter.buildType = this.buildType
      this.requestParameter.note = this.note
      this.requestParameter.filterRuleJson = null
      this.requestParameter.filterQuery = null

      if (this.buildType === this.SEGMENT_BUILD_TYPE.STANDARD.value) {
        this.requestParameter.filterRuleJson = JSON.parse(JSON.stringify(this.ruleGroup))
        this.transferFilterRuleJson(this.requestParameter.filterRuleJson)
      } else if (this.buildType === this.SEGMENT_BUILD_TYPE.CUSTOM.value) {
        this.requestParameter.jobSetting = JSON.parse(JSON.stringify(this.jobSetting))
        switch (this.requestParameter.jobSetting.recurringType) {
          case this.JOB_RECURRING_TYPES.WEEKLY.value: {
            const weeklySetting = {}
            for (const obj in this.jobSetting.jobSettingWeekly) {
              for (const key in this.jobSetting.jobSettingWeekly[obj]) {
                const value = this.jobSetting.jobSettingWeekly[obj][key]
                weeklySetting[key] = value
              }
            }
            this.requestParameter.jobSetting.jobSettingWeekly = weeklySetting
            break
          }
          case this.JOB_RECURRING_TYPES.MONTHLY.value: {
            const monthlySettingParam = {}
            for (const obj in this.jobSetting.jobSettingMonthly) {
              for (const key in this.jobSetting.jobSettingMonthly[obj]) {
                const value = this.jobSetting.jobSettingMonthly[obj][key]
                monthlySettingParam[key] = value
              }
            }
            this.requestParameter.jobSetting.jobSettingMonthly = monthlySettingParam
            break
          }
          default:
            this.requestParameter.jobSetting.jobSettingWeekly = {}
            this.requestParameter.jobSetting.jobSettingMonthly = {}
            break
        }

        if (this.requestParameter.jobSetting.execTime) {
          this.requestParameter.jobSetting.execTime += ':00'
        }

        // カスタムセグメント (SQL) の場合は sql だけ移す
        this.requestParameter.filterQuery = this.sql
      }
    },
    transferFilterRuleJson (ruleGroup) {
      if (ruleGroup.isGroup) {
        // 日付をISO規格に変換
        if (ruleGroup.periodType === this.PERIOD_TYPES.CUSTOM.value) {
          if (ruleGroup.startDate) ruleGroup.startDate = this.convetDateToIso(new Date(ruleGroup.startDate))
          if (ruleGroup.endDate) ruleGroup.endDate = this.convetDateToIso(new Date(ruleGroup.endDate))
        }
        // 表示用プロパティの削除
        delete ruleGroup.isGroup
        delete ruleGroup.depth
        if (ruleGroup.internalFilterColumn && !ruleGroup.internalFilterColumn.internalFilterType) delete ruleGroup.internalFilterColumn
        // フィルターJSONの容量節約のため値を持っていない項目を削除
        if (ruleGroup.dayOfWeek.length === 0) delete ruleGroup.dayOfWeek
        if (ruleGroup.dayOfMonth.length === 0) delete ruleGroup.dayOfMonth
        Object.keys(ruleGroup).forEach(column => {
          if (!ruleGroup[column]) delete ruleGroup[column]
        })

        ruleGroup.children.forEach(child => {
          this.transferFilterRuleJson(child)
        })
      } else {
        // 単体条件
        if (ruleGroup.internalFilterColumn && !ruleGroup.internalFilterColumn.internalFilterType) delete ruleGroup.internalFilterColumn
        // PUTリクエスト用にSCV項目の条件に最新の論理名を反映
        if (ruleGroup.filterColumn.filterType === this.FILTER_TYPE.SCV_COLUMN.filterType) {
          const latestScvColumn = this.scvColumns.find(scvColumn => scvColumn.filterPhysicalName === ruleGroup.filterColumn.filterPhysicalName)
          ruleGroup.filterColumn.filterLogicalName = latestScvColumn.filterLogicalName
        }
        // DateをISO規格に変換
        if (ruleGroup.filterColumn.dataType === this.DATA_TYPES.DATETIME.value) {
          // 一致条件が NULL, NOT_NULL の場合は条件値が NULL になるのでそのまま (それ以外は required のため何らかの値が入る)
          if (ruleGroup.filterColumn.operatorType !== this.OPERATOR_TYPE.IS_NULL.value &&
            ruleGroup.filterColumn.operatorType !== this.OPERATOR_TYPE.IS_NOT_NULL.value) {
            ruleGroup.filterColumn.filterValue = this.convetDateTimeToIso(new Date(ruleGroup.filterColumn.filterValue))
          }
        }
      }
    },
    /**
     * セグメント作成/更新処理の呼び出し
     */
    requestSettingSegment () {
      if (this.isUpdateMode) {
        this.$emit('updateSegment', this.requestParameter)
      } else {
        this.$emit('createSegment', this.requestParameter)
      }
      this.showModal = false
    },
    /**
     * セグメントユーザー検索
     */
    async searchSegmentUsers () {
      if (this.buildType === this.SEGMENT_BUILD_TYPE.STANDARD.value && this.ruleGroup.children.length === 0) {
        this.notifyErrorMessage(this.$t('notify.error.segments.noFilter'))
        return
      } else if (this.buildType === this.SEGMENT_BUILD_TYPE.CUSTOM.value && this.sql === '') {
        this.notifyErrorMessage(this.$t('notify.error.segments.noFilter'))
        return
      }
      this.transferRequestParameter()

      this.$emit('searchSegment', Object.assign({}, this.requestParameter, { baseDate: this.momentUtil.getCurrentDateIncludeHyphen() }))
    },
    async createSearchQuery () {
      // SQLセグメントはスキップ
      if (this.buildType === this.SEGMENT_BUILD_TYPE.CUSTOM.value) return

      if (this.buildType === this.SEGMENT_BUILD_TYPE.STANDARD.value && this.ruleGroup.children.length === 0) {
        this.notifyErrorMessage(this.$t('notify.error.segments.noFilter'))
        return
      }
      this.transferRequestParameter()
      this.$emit('createQuery', Object.assign({}, this.requestParameter, { baseDate: this.momentUtil.getCurrentDateIncludeHyphen() }))
    },
    /**
     * リクエストパラメーターの初期化
     */
    initializeRequestParameters () {
      this.requestParameter.filterRuleJson = []
    },
    /**
     * 日付をIso規格に変換
     * @param  {Date} date 変換対象の日付
     * @return {String} yyyy-MM-dd
     */
    // TODO: convertUtilに移行(引数はDateではなくStringになっているので注意)
    convetDateToIso (date) {
      return date.getFullYear() + '-' +
        (('0' + (date.getMonth() + 1)).slice(-2)) + '-' +
        ('0' + date.getDate()).slice(-2)
    },
    /**
     * 日時をIso規格に変換
     * @param  {Date} date 変換対象の日付
     * @return {String} yyyy-MM-dd HH:mm:SS
     */
    // TODO: convertUtilに移行(引数はDateではなくStringになっているので注意)
    convetDateTimeToIso (date) {
      return date.getFullYear() + '-' +
        (('0' + (date.getMonth() + 1)).slice(-2)) + '-' +
        ('0' + date.getDate()).slice(-2) + ' ' +
        ('0' + date.getHours()).slice(-2) + ':' +
        ('0' + date.getMinutes()).slice(-2) + ':' +
        ('0' + date.getSeconds()).slice(-2)
    },
    /**
     * Iso規格の文字列を日付文字列に変換
     * @param  {String} isoString
     * @return {String} yyyy-MM-dd
     */
    // TODO: convertUtilに移行
    convertIsoToDateString (isoString) {
      const date = new Date(isoString)
      return date.getFullYear() + '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
        ('0' + date.getDate()).slice(-2)
    },
    /**
     * Iso規格の文字列を日時文字列に変換
     * @param  {String} isoString
     * @return {String} yyyy-MM-ddTHH:mm:SS
     */
    // TODO: convertUtilに移行
    convertIsoToDateTimeString (isoString) {
      const date = new Date(isoString)
      return date.getFullYear() + '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
        ('0' + date.getDate()).slice(-2) + 'T' +
        ('0' + date.getHours()).slice(-2) + ':' +
        ('0' + date.getMinutes()).slice(-2) + ':' +
        ('0' + date.getSeconds()).slice(-2)
    },
    switchLogicalOperatorType () {
      // logicalOperatorType は 1 か 2 を取るので切り替えるために 3 から引いている
      if (this.isUnknown) {
        this.unknownRuleGroup.logicalOperatorType = 3 - this.unknownRuleGroup.logicalOperatorType
      } else if (this.isScv) {
        this.scvRuleGroup.logicalOperatorType = 3 - this.scvRuleGroup.logicalOperatorType
      }
    },
    removeRuleGroup (index) {
      this.ruleGroup.children.splice(index, 1)
    },
    onClickAddRuleGroupButton () {
      const newGroup = this.addFilterRuleGroup()
      if (this.isUnknown) {
        newGroup.depth = this.unknownRuleGroup.depth + 1
        this.unknownRuleGroup.children.push(newGroup)
      } else if (this.isScv) {
        newGroup.depth = this.scvRuleGroup.depth + 1
        this.scvRuleGroup.children.push(newGroup)
      }
    },
    /**
     * 実行曜日を格納
     */
    selectAllWeek () {
      this.$nextTick(() => {
        const weekCheckedValList = []
        if (this.jobSetting.jobSettingWeekly.length === 0) {
          for (const key of Object.keys(this.DAY_OF_WEEK_LIST)) {
            weekCheckedValList.push(this.DAY_OF_WEEK_LIST[key].value)
          }
        }
        this.jobSetting.jobSettingWeekly = weekCheckedValList
      })
    },
    /**
     * 実行日を格納
     */
    selectAllDay () {
      this.$nextTick(() => {
        const dayCheckedValList = []
        if (this.jobSetting.jobSettingMonthly.length === 0) {
          for (const key of Object.keys(this.DAY_OF_MONTH_LIST)) {
            dayCheckedValList.push(this.DAY_OF_MONTH_LIST[key].value)
          }
        }
        this.jobSetting.jobSettingMonthly = dayCheckedValList
      })
    },
    resetJobSettingWithoutRecurringType () {
      this.jobSetting.execDate = null
      this.jobSetting.execTime = '00:00'
      this.jobSetting.jobSettingWeekly = []
      this.jobSetting.jobSettingMonthly = []
    },
    changeUserType (userType) {
      this.initializeRequestParameters()
      if (this.buildType === this.SEGMENT_BUILD_TYPE.CUSTOM.value) {
        if (userType === this.USER_TYPES.SCV.value) {
          this.unknownSql = this.sql
          this.sql = this.scvSql
        } else if (userType === this.USER_TYPES.USER_UNKNOWN.value) {
          this.scvSql = this.sql
          this.sql = this.unknownSql
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/theme.scss';

.userType {
  font-size: 10.5px;
  line-height: 16px;
  .v-radio {
    ::v-deep .v-input--selection-controls__input .v-icon {
      font-size: 20px;
    }
    ::v-deep .v-label {
      font-size: 14px;
      .v-icon {
        font-size: 20px;
      }
    }
  }
}
.editor {
  max-height: 70vh !important;
  height: 70vh !important;

  border-collapse: collapse;
  border-color: currentColor;
  border-style: solid;
  border-width: 1px;
}
</style>
