<template>
  <v-card flat>
    <v-form>
      <div
        :class="[{ 'inputform_file-dragover': isDrag }, 'inputform_file', 'text-center']"
        @dragover.prevent="isDrag=true"
        @dragleave.prevent="isDrag=false"
        @drop.prevent="dropFile($event.dataTransfer.files)">
        <v-row class="inputform_text" align="center" dense>
          <v-col>
            {{ $t('form.masters.dropArea') }}
          </v-col>
          <v-col>
            {{ $t('form.masters.or') }}
          </v-col>
          <v-col>
            <input ref="input" id="inputFile" type="file" v-if="view" accept=".csv" @change="checkFile($event.target.files)">
          </v-col>
        </v-row>
      </div>
    </v-form>
    <v-card-actions>
      <v-spacer />
      <v-btn class="mr-3" color="denial" @click="resetForm()">{{ $t(`btn.cancel`) }}</v-btn>
      <v-btn
        class="mr-3"
        color="primary"
        :disabled="schemaNotDetermined || isDisabledUpload || selectedCsvImportType !== CSV_IMPORT_TYPE.REPLACEMENT.value"
        :loading="isLoading || infering"
        @click="replacementDataSourceUpload"
      >
        {{ $t(`btn.masters.replaceUpload`)}}
      </v-btn>
      <v-btn
        class="mr-3"
        color="primary"
        :disabled="schemaNotDetermined || isDisabledUpload || selectedCsvImportType !== CSV_IMPORT_TYPE.DIFFERENCE.value"
        :loading="isLoading || infering"
        @click="differenceDataSourceUpload"
      >
        {{ $t(`btn.masters.difUpload`)}}
      </v-btn>
      <v-btn class="mr-3" color="primary" :disabled="isDisabledUpload || disabledUpdateSchema" :loading="isLoading || infering" @click="inferSchema">
        {{ $t(`btn.masters.infer`)}}
      </v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
// enum
import CSV_IMPORT_TYPE from '@/enum/CSV_IMPORT_TYPE'
// util
import notifyUtil from '@/utils/notifyUtil'

const MAX_FILE_SIZE = 209715200
const MAX_COLUMNS_SIZE = 1500

export default {
  name: 'MasterUpload',
  mixins: [
    CSV_IMPORT_TYPE,
    notifyUtil,
  ],
  props: {
    dataSourceId: {
      type: Number,
    },
    selectedCsvImportType: {
      type: Number,
    },
    disabledUpdateSchema: {
      type: Boolean,
    },
    schemaNotDetermined: {
      type: Boolean,
    },
  },
  data () {
    return {
      importType: 1,
      isDrag: false,
      isDisabledUpload: true,
      isLoading: false,
      uploadFile: null,
      view: true,
    }
  },
  computed: {
    infering () {
      return this.$store.state.dataSource.infering
    },
  },
  methods: {
    dropFile (droppedFile) {
      this.$refs.input.files = droppedFile
      this.checkFile(droppedFile)
    },
    /**
     * ファイルの形式を確認してデータをバインドする
     * @param  {File} targetFiles 選択またはドロップされたファイル
     */
    async checkFile (targetFiles) {
      this.isDrag = false
      this.isDisabledUpload = true

      if (targetFiles.length < 1) {
        this.resetUploadFile()
        return
      }

      let hasError = false
      const type = targetFiles[0].name.split('.')
      if (targetFiles.length > 1) {
        this.notifyErrorMessage(this.$t('notify.error.file.notMultipleUpload'))
        hasError = true
      }
      if (type[type.length - 1].toLowerCase() !== 'csv') {
        this.notifyErrorMessage(this.$t('notify.error.file.notCsv'))
        hasError = true
      }
      if (targetFiles[0].size > MAX_FILE_SIZE) {
        this.notifyErrorMessage(this.$t('notify.error.file.fileSizeOver200M'))
        hasError = true
      }
      let columnLength = 0
      await this.countCsvColumns(targetFiles[0]).then(res => {
        columnLength = res
      })
      if (columnLength >= MAX_COLUMNS_SIZE) {
        this.notifyErrorMessage(this.$t('notify.error.file.columnSizeOver1500'))
        hasError = true
      }

      if (hasError) {
        this.resetUploadFile()
        return
      }
      // 正常なファイルであればバインド
      this.uploadFile = targetFiles[0]
      this.isDisabledUpload = false
    },
    countCsvColumns (targetFile) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        let columnLength = 0
        reader.readAsText(targetFile)
        reader.onload = () => {
          // 行末の改行を区切り文字として行を要素とした配列を生成
          const targetBody = reader.result.split('\n')
          // 各カラムの区切り文字をカウント
          const headerColumns = targetBody[0].match(/","/g)
          if (headerColumns) {
            // 区切り文字の数+1でカラム数算出
            columnLength = headerColumns.length + 1
          } else {
            // 各カラムがダブルクォートで囲われていないヘッダーの場合はカンマの数でカラム数をカウント
            columnLength = targetBody[0].split(',').length
          }
          resolve(columnLength)
        }
      })
    },
    /**
     * 差分アップロード
     */
    differenceDataSourceUpload () {
      this.isLoading = true
      this.uploadDataSource(this.CSV_IMPORT_TYPE.DIFFERENCE.value)
    },
    /**
     * 差し替えアップロード
     */
    replacementDataSourceUpload () {
      this.isLoading = true
      this.uploadDataSource(this.CSV_IMPORT_TYPE.REPLACEMENT.value)
    },
    /**
     * スキーマ推測
     */
    inferSchema () {
      const csvFile = new FormData()
      csvFile.append('csvFile', this.uploadFile)
      this.$store.dispatch('dataSource/infer', {
        file: csvFile,
      })
        .then((res) => {
          this.notifySuccessMessage(this.$t('notify.success.csvSchemaInfer'))
          this.$emit('infer', res.data.data.columns)
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    },
    /**
     * 取込種別に応じて顧客マスタアップロードを実行
     * @param  {Number} importType 取込種別
     */
    uploadDataSource (importType) {
      const csvFile = new FormData()
      csvFile.append('csvFile', this.uploadFile)
      const uploadParameter = {
        dataSourceId: this.dataSourceId,
        importType: importType,
        file: csvFile,
      }
      this.$store.dispatch('dataSource/uploadDataSource', uploadParameter)
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.file') + this.$t('notify.success.upload'))
          this.$store.dispatch('dataSource/getScheduledToBeImportedObjectList', this.dataSourceId)
            .catch((err) => {
              this.handleErrorResponse(err)
            })
        }).catch(err => {
          this.handleErrorResponse(err)
        }).finally(() => this.resetForm())
    },
    resetForm () {
      if (document.getElementById('inputFile').value) document.getElementById('inputFile').value = ''
      this.isLoading = false
      this.resetUploadFile()
    },
    /**
     * アップロードしたファイル名の表示を削除.
     */
    resetUploadFile () {
      this.view = false
      this.isDisabledUpload = true
      this.uploadFile = null
      this.$nextTick(function () {
        this.view = true
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-card) using ($integral-core-theme) {
  .inputform_file {
    #inputFile {
      height: 30px;
    }
    ::v-deep input[type="file"]::-webkit-file-upload-button {
      margin-right: 8px;
      background-color: map-deep-get($integral-core-theme, 'buttons', 'default', 'color', 'background');
      border: 1px solid map-deep-get($integral-core-theme, 'buttons', 'default', 'color', 'border');
      box-shadow: 3px 3px 0px map-deep-get($integral-core-theme, 'buttons', 'default', 'color', 'box-shadow');
      color: map-deep-get($integral-core-theme, 'buttons', 'default', 'color', 'text');
    }
  }
  .v-card__actions {
    border-top: 1px solid map-deep-get($integral-core-theme, 'tabs', 'border');
  }
}
.v-card {
  .v-form {
    margin: 0 auto;
    max-width: 546px;
    .inputform_file {
      margin-bottom: 25px;
    }
  }
  .v-card__actions {
    margin: 12px -12px 0;
  }
}
</style>
