<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn color="primary" :to="{ name: 'SegmentExportScriptSettingAdd' }" :disabled="!canPost">
          {{ $t(`btn.create`) }}
        </v-btn>
      </template>
      <template #right>
        <v-btn @click="fetchSegmentExportScripts" :loading="loadingSegmentExportScripts"><v-icon>ic-reload</v-icon></v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <filter-form
        @search="setFilterParameter"
        @reset="resetFilterParameter"
        @switchFilter="val => isFiltering = val"
        :isFiltering="isFiltering"
        :filteredTotal="paging.filteredTotalCount"
        :total="paging.totalCount"
      >
        <v-row>
          <v-col class="py-0" cols="6">
            <v-text-field
              :value="filterParameter.name"
              @input="preparedFilters.name = $event"
              :label="$t(`text.segmentExportScripts.name`)"
              clearable
              :disabled="!isFiltering" />
          </v-col>
          <v-col class="py-0" cols="3">
            <v-select
              :value="filterParameter.lastStatus"
              @change="preparedFilters.lastStatus = $event"
              :items="enumUtil.convertForSelectList(EXEC_STATUS)"
              :label="$t(`text.segmentExportScripts.lastExecStatus`)"
              clearable
              :disabled="!isFiltering" />
          </v-col>
          <v-col class="py-0" cols="3">
            <v-select
              :value="filterParameter.executable"
              @change="preparedFilters.executable = $event"
              :items="executableOptions"
              :label="$t(`text.segmentExportScripts.scriptStatus`)"
              clearable
              :disabled="!isFiltering" />
          </v-col>
        </v-row>
      </filter-form>
      <!-- sort/limit -->
      <v-row justify="space-between" class="my-0">
        <v-col cols="4">
          <v-select
            v-model="orderBy"
            class="sort pt-0"
            hide-details
            :items="sortableColumns"
            :disabled="loadingSegmentExportScripts"
          >
            <template #prepend>
              <span class="prepend-sort-text">
                {{ $t('text.sort') }}
              </span>
            </template>
            <template #append-outer>
              <v-btn icon @click="isDesc = !isDesc" :disabled="loadingSegmentExportScripts">
                <v-icon v-if="isDesc">ic-sort-desc</v-icon>
                <v-icon v-else>ic-sort-asc</v-icon>
              </v-btn>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-progress-linear v-if="loadingSegmentExportScripts" indeterminate color="primary" />
      <v-card v-if="!loadingSegmentExportScripts && segmentExportScripts.length === 0" outlined tile><v-card-text>{{$t('text.noData')}}</v-card-text></v-card>
      <large-list
        v-for="(script) in segmentExportScripts"
        :key="script.segmentExportScriptId"
        :name="script.segmentExportScriptName"
        :note="script.note"
        :to="'/segmentExportScript/setting/' + script.segmentExportScriptId"
      >
        <!-- ヘッダー -->
        <template #header>
          <v-row class="align-center">
            <v-col cols="1">
              <v-chip
                v-if="script.lastSegmentExportScriptExecHistory === null || script.lastSegmentExportScriptExecHistory.execStatus === undefined"
                color="pending"
                :to="{ name: 'SegmentExportScriptHistory', params: { segmentExportScriptId: script.segmentExportScriptId }}"
              >
                未実行
              </v-chip>
              <v-chip
                v-else-if="script.lastSegmentExportScriptExecHistory.execStatus === EXEC_STATUS.PROCESSING.value"
                color="ongoing"
                :to="{ name: 'SegmentExportScriptHistory', params: { segmentExportScriptId: script.segmentExportScriptId }}"
              >
                {{ enumUtil.getEnumFromValue(EXEC_STATUS, script.lastSegmentExportScriptExecHistory.execStatus).text }}
              </v-chip>
              <v-chip
                v-else-if="script.lastSegmentExportScriptExecHistory.execStatus === EXEC_STATUS.SUCCESS.value"
                color="success"
                :to="{ name: 'SegmentExportScriptHistory', params: { segmentExportScriptId: script.segmentExportScriptId }}"
              >
                {{ enumUtil.getEnumFromValue(EXEC_STATUS, script.lastSegmentExportScriptExecHistory.execStatus).text }}
              </v-chip>
              <v-chip
                v-else-if="script.lastSegmentExportScriptExecHistory.execStatus === EXEC_STATUS.FAILED.value"
                color="fault"
                :to="{ name: 'SegmentExportScriptHistory', params: { segmentExportScriptId: script.segmentExportScriptId }}"
              >
                {{ enumUtil.getEnumFromValue(EXEC_STATUS, script.lastSegmentExportScriptExecHistory.execStatus).text }}
              </v-chip>
            </v-col>
            <v-col cols="9">
              {{ $t('text.segmentExportScripts.segmentName') }}&#58;&nbsp;{{ script.segmentName }}
            </v-col>
            <v-col cols="2" class="text-right">
              <v-btn
                v-show="script.lastSegmentExportScriptExecHistory !== null && script.lastSegmentExportScriptExecHistory.execStatus !== undefined"
                small
                :to="{ name: 'SegmentExportScriptHistory', params: { segmentExportScriptId: script.segmentExportScriptId }}"
                class="mr-2"
              >
                {{ $t(`text.segmentExportScripts.execHistory`) }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <!-- col2 -->
        <template #col2>
          {{ $t('text.createdAt') }}&#58;&nbsp;{{ script.createdAt }}
          <br>
          {{ $t('text.updatedAt') }}&#58;&nbsp;{{ script.updatedAt }}
        </template>
        <!-- col3 -->
        <template #col3>
          {{ $t(`text.segmentExportScripts.scriptStatus`) }}&#58;&nbsp;{{ script.executable ? $t('text.segmentExportScripts.executable') : $t('text.segmentExportScripts.unexecutable') }}
          <br>
          {{ $t(`text.segmentExportScripts.recurringTypes`) }}&#58;&nbsp;{{ enumUtil.getEnumFromValue(SEGMENT_EXPORT_SCRIPT_RECURRING_TYPES, script.segmentExportScheduleType).text }}
        </template>
      </large-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// components
import FilterForm from '@/components/common/filterForm'
import LargeList from '@/components/common/largeList'
// enum
import EXEC_STATUS from '@/enum/EXEC_STATUS'
import SEGMENT_EXPORT_SCRIPT_RECURRING_TYPES from '@/enum/SEGMENT_EXPORT_SCRIPT_RECURRING_TYPES'
// util
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'SegmentExportScripts',
  components: {
    FilterForm,
    LargeList,
  },
  mixins: [
    enumUtil,
    notifyUtil,
    EXEC_STATUS,
    SEGMENT_EXPORT_SCRIPT_RECURRING_TYPES,
  ],
  data () {
    return {
      executableOptions: [
        { text: this.$t('text.segmentExportScripts.executable'), value: true },
        { text: this.$t('text.segmentExportScripts.unexecutable'), value: false },
      ],
      sortableColumns: [
        {
          text: this.$t('text.segmentExportScripts.sort.createdAt'),
          value: 'createdAt',
        },
        {
          text: this.$t('text.segmentExportScripts.sort.updatedAt'),
          value: 'updatedAt',
        },
        {
          text: this.$t('text.segmentExportScripts.sort.scriptStatus'),
          value: 'executable',
        },
      ],
      segmentExportScripts: [],
      preparedFilters: [],
      paging: {},
      isFiltering: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['canPost']),
    ...mapGetters({ queryParams: 'segmentExportScripts/getQueryParams' }),
    orderBy: {
      get () {
        return this.$store.state.segmentExportScripts.sortParameter.orderBy
      },
      set (newVal) {
        this.changeQuery('orderBy', newVal)
      },
    },
    isDesc: {
      get () {
        return this.$store.state.segmentExportScripts.sortParameter.isDesc
      },
      set (newVal) {
        this.changeQuery('isDesc', newVal)
      },
    },
    enabledFilter () {
      return this.$store.state.segmentExportScripts.enabledFilter
    },
    filterParameter () {
      return this.$store.state.segmentExportScripts.filterParameter
    },
    loadingSegmentExportScripts: {
      get () {
        return this.$store.state.segmentExportScripts.loadingSegmentExportScripts
      },
      set (newVal) {
        this.$store.dispatch('segmentExportScripts/updateLoadingSegmentExportScripts', newVal)
      },
    },
  },
  created () {
    this.loadingSegmentExportScripts = true

    const tmpQuery = {}
    for (const key in this.$route.query) {
      tmpQuery[key] = this.$route.query[key]
    }

    // クエリが不十分な場合補う
    let isQuerySufficient = true
    for (const key in this.queryParams) {
      if (tmpQuery[key] === null || tmpQuery[key] === undefined) {
        tmpQuery[key] = this.queryParams[key]
        isQuerySufficient = false
      }
    }

    // orderByに指定された文字列がソート基準の選択肢にあるなら問題ない
    const validateOrderBy = this.sortableColumns.some((column) => tmpQuery.orderBy === column.value)
    if (!validateOrderBy) {
      tmpQuery.orderBy = 'createdAt'
      isQuerySufficient = false
    }
    if (tmpQuery.isDesc !== true && tmpQuery.isDesc !== false && tmpQuery.isDesc !== 'true' && tmpQuery.isDesc !== 'false') {
      tmpQuery.isDesc = true
      isQuerySufficient = false
    }

    // クエリを変更した場合はURLを置き換える
    if (!isQuerySufficient) {
      this.$router.replace({ query: tmpQuery })
        .catch(err => err) // 画面表示時の NavigationDuplicated 対策
      // 置き換わるとbeforeRouteUpdateが呼び出されるのでこちらの処理は中断する
      return
    }

    this.reflectQueryParamsToState(tmpQuery)
    this.preparedFilters = this.filterParameter
    this.isFiltering = this.enabledFilter
    this.fetchSegmentExportScripts()
  },
  beforeRouteUpdate (to, from, next) {
    // ルート変更に反応する
    if (to.query !== from.query) {
      this.reflectQueryParamsToState(to.query)
      this.fetchSegmentExportScripts()
    }
    next()
  },
  methods: {
    changeQuery (type, value) {
      const queryParams = this.queryParams

      if (type === 'filter') {
        queryParams.name = value.name
        queryParams.lastStatus = value.lastStatus
        queryParams.executable = value.executable
        queryParams.enabledFilter = this.isFiltering
      } else {
        queryParams[type] = value
      }

      // クエリパラメーター更新
      // クエリパラメーターを更新するとbeforeRouteUpdateが呼び出される
      this.$router.push({ query: queryParams })
        .catch(err => err) // 画面表示時の NavigationDuplicated 対策
    },
    fetchSegmentExportScripts () {
      this.$store.dispatch('segmentExportScripts/getSegmentExportScripts')
        .then((res) => {
          this.segmentExportScripts = res.segmentExportScripts
          this.paging = res.paging
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    reflectQueryParamsToState (query) {
      this.$store.dispatch('segmentExportScripts/updateOrderBy', query.orderBy)
      this.$store.dispatch('segmentExportScripts/updateIsDesc', JSON.parse(query.isDesc))
      this.$store.dispatch('segmentExportScripts/updateEnabledFilter', JSON.parse(query.enabledFilter))
      const tmpFilterParameter = []
      if (Object.keys(query).length > 0) {
        // filterParameter として許可されているプロパティのみ有効
        for (const key in this.filterParameter) {
          if (query[key] === undefined && query[key] === null) {
            tmpFilterParameter[key] = null
          } else {
            tmpFilterParameter[key] = query[key]
          }
          // this.$route.query には文字列型で入ってるので、数値型にキャストできるなら変換
          if (tmpFilterParameter[key] !== null && tmpFilterParameter[key] !== '' && !Number.isNaN(Number(tmpFilterParameter[key]))) {
            tmpFilterParameter[key] = Number(tmpFilterParameter[key])
          }
          // this.$route.query には文字列型で入ってるので、boolean型にしたいものは変換
          if (tmpFilterParameter[key] === 'true') {
            tmpFilterParameter[key] = true
          }
          if (tmpFilterParameter[key] === 'false') {
            tmpFilterParameter[key] = false
          }
        }
      }
      this.$store.dispatch('segmentExportScripts/updateFilter', tmpFilterParameter)
    },
    /**
     * フィルタリング条件の適用
     */
    setFilterParameter () {
      this.changeQuery('filter', this.preparedFilters)
    },
    /**
     * フィルタリング条件の初期化
     */
    resetFilterParameter () {
      this.preparedFilters = []
      this.setFilterParameter()
    },
  },
}
</script>
