<template>
  <div>
    <!-- スキーマ (CSV, API) -->
    <template
      v-if="dataSource.connectorType === CONNECTOR_TYPES.CSV.value || dataSource.connectorType === CONNECTOR_TYPES.API.value"
    >
      <form @submit.prevent="onSaveSchemaClick()" data-vv-scope="schema">
        <v-data-table
          v-if="dataSource.dataSourceId"
          :headers="schemaTableHeaders"
          :items="headerMetaColumns"
          :loading="loading"
          hide-default-footer
          disable-pagination
          disable-sort
        >
          <!-- LINT が怒るのは無視 https://github.com/vuejs/eslint-plugin-vue/issues/1165 -->
          <template #item.delete="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  fab
                  depressed
                  x-small
                  color="denial"
                  @click="onClickRemoveSchemaColumnButton(item.index)"
                  :disabled="item.index === userIdentifyColumn || item.index === datetimeIdentifyColumn || transactionIdentifierColumn.includes(item.index)"
                >
                  <v-icon>ic-close-S</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('btn.delete') }}</span>
            </v-tooltip>
          </template>
          <template #item.key="{ item }">
            <v-radio-group
              v-model.number="userIdentifyColumn"
              :disabled="dataSource.userIdentifyColumn > 0"
              hide-details
            >
              <v-radio :value="Number(item.index)" color="inputSelectionControl" />
            </v-radio-group>
          </template>
          <template #item.datetimeIdentifyColumn="{ item }">
            <v-radio-group
              v-model.number="datetimeIdentifyColumn"
              :disabled="dataSource.datetimeIdentifyColumn > 0"
              hide-details
            >
              <v-radio :value="Number(item.index)" color="inputSelectionControl" />
            </v-radio-group>
          </template>
          <template #item.transactionIdentifierColumn="{ item }">
            <v-checkbox
              v-model="transactionIdentifierColumn"
              :disabled="(dataSource.transactionIdentifierColumn || {}).length > 0"
              hide-details
              class="pb-2"
              :value="item.index"
              color="inputSelectionControl"
            />
          </template>
          <template #item.logicalName="{ item }">
            <v-text-field
              v-model="item.logicalName"
              dense
              hide-details="auto"
              :name="'logicalName[' + item.index + ']'"
              :data-vv-as="$t(`form.dataSource.columnName`)"
              v-validate="'required'"
              :error-messages="errors.collect('schema.logicalName[' + item.index + ']')"
            />
          </template>
          <template #item.physicalName="{ item }">
            <v-text-field
              v-model="item.physicalName"
              dense
              hide-details="auto"
              :name="'physicalName[' + item.index + ']'"
              :data-vv-as="$t(`form.dataSource.columnName`)"
              v-validate="'required|dataSourceSchemaPhysicalNameSyntax'"
              :error-messages="errors.collect('schema.physicalName[' + item.index + ']')"
            />
          </template>
          <template #item.dataType="{ item }">
            <v-select
              v-model="item.dataType"
              dense
              hide-details="auto"
              :items="dataTypes"
              item-text="label"
              item-value="value"
              :name="'dataType[' + item.index + ']'"
              :data-vv-as="$t(`form.dataType`)"
              v-validate="'required'"
              :error-messages="errors.collect('schema.dataType[' + item.index + ']')"
            />
          </template>
          <template #body.append>
            <tr>
              <td :colspan="schemaTableHeaders.length">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      v-on="on"
                      fab
                      depressed
                      @click="onClickAddSchemaColumnButton"
                      :disabled="disabledUpdateSchema"
                    >
                      <v-icon>ic-add</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.add') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="denial" @click="onCancelClick">{{ $t('btn.cancel') }}</v-btn>
          <v-btn type="submit" color="primary" :disabled="disabledUpdateSchema">{{ $t('btn.update') }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </form>
    </template>

    <!-- スキーマ (WEB) -->
    <template
      v-else-if="dataSource.connectorType === CONNECTOR_TYPES.WEB.value ||
        dataSource.connectorType === CONNECTOR_TYPES.ANDROID.value ||
        dataSource.connectorType === CONNECTOR_TYPES.IOS.value"
    >
      <v-data-table
        :headers="schemaTableHeaders"
        :items="webMasterHeader"
        :loading="loading"
        hide-default-footer
        disable-pagination
        disable-sort
      >
        <template #item.key="{ item }">
          <v-radio-group :value="WEB_IDENTIFY_COLUMN_INDEX" disabled hide-details>
            <v-radio disabled :value="Number(item.index)" color="inputSelectionControl" />
          </v-radio-group>
        </template>
        <template #item.dataType="{ item }">
          <v-select
            v-model="item.dataType"
            readonly
            dense
            hide-details
            :items="dataTypes"
            item-text="label"
            item-value="value"
          />
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// enum
import DATA_TYPES from '@/enum/DATA_TYPES'
import DATA_SOURCE_TYPES from '@/enum/DATA_SOURCE_TYPES'
import CONNECTOR_TYPES from '@/enum/CONNECTOR_TYPES'
// utils
import displayConverter from '@/utils/displayConverter'
import notifyUtil from '@/utils/notifyUtil'
import momentUtil from '@/utils/momentUtil'

export default {
  name: 'DataSourceTabs',
  mixins: [
    DATA_TYPES,
    DATA_SOURCE_TYPES,
    CONNECTOR_TYPES,
    displayConverter,
    notifyUtil,
    momentUtil,
  ],
  props: {
    dataSet: {
      type: Object,
    },
    dataSource: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
  data () {
    return {
      requestParameter: {
        userIdentifyColumn: null,
        datetimeIdentifyColumn: null,
        transactionIdentifierColumn: [],
      },
      masterSchemaTableHeaders: [
        {
          text: '',
          value: 'delete',
          width: '56',
          align: 'center',
          sortable: false,
        },
        {
          text: '顧客識別子',
          value: 'key',
          width: '64',
          sortable: false,
        },
        {
          text: '列番号',
          value: 'index',
          width: '64',
          align: 'end',
          sortable: false,
        },
        {
          text: 'カラム識別名',
          value: 'logicalName',
          sortable: false,
        },
        {
          text: 'カラム物理名',
          value: 'physicalName',
          sortable: false,
        },
        {
          text: 'データ型',
          value: 'dataType',
          width: '174',
          sortable: false,
        },
      ],
      transactionSchemaTableHeaders: [
        {
          text: '',
          value: 'delete',
          width: '56',
          align: 'center',
          sortable: false,
        },
        {
          text: '顧客識別子',
          value: 'key',
          width: '64',
          sortable: false,
        },
        {
          text: '日時識別子',
          value: 'datetimeIdentifyColumn',
          width: '64',
          sortable: false,
        },
        {
          text: 'トランザクション識別子',
          value: 'transactionIdentifierColumn',
          width: '64',
          sortable: false,
        },
        {
          text: '列番号',
          value: 'index',
          width: '64',
          align: 'end',
          sortable: false,
        },
        {
          text: 'カラム識別名',
          value: 'logicalName',
          sortable: false,
        },
        {
          text: 'カラム物理名',
          value: 'physicalName',
          sortable: false,
        },
        {
          text: 'データ型',
          value: 'dataType',
          width: '174',
          sortable: false,
        },
      ],
      webMasterHeader: [
        { index: 1, logicalName: 'iuid', physicalName: 'iuid', dataType: 1 },
        { index: 2, logicalName: '顧客識別情報', physicalName: 'customerIdentify', dataType: 1 },
        { index: 3, logicalName: '初回デバイス種別', physicalName: 'firstDeviceType', dataType: 2 },
        { index: 4, logicalName: '初回OS種別', physicalName: 'firstOsType', dataType: 2 },
        { index: 5, logicalName: '初回ブラウザ種別', physicalName: 'firstBrowserType', dataType: 2 },
        { index: 6, logicalName: '初回IPアドレス', physicalName: 'firstIpAddress', dataType: 1 },
        { index: 7, logicalName: '初回アクセス日時', physicalName: 'firstAccessDatetime', dataType: 4 },
        { index: 8, logicalName: '最終アクセス日時', physicalName: 'lastAccessDatetime', dataType: 4 },
        { index: 9, logicalName: '訪問回数', physicalName: 'visitCnt', dataType: 1 },
      ],
      WEB_IDENTIFY_COLUMN_INDEX: 2,
      headerMetaColumns: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['canPut']),
    userIdentifyColumn: {
      get () {
        if (this.requestParameter.userIdentifyColumn && this.requestParameter.userIdentifyColumn > 0) {
          return this.requestParameter.userIdentifyColumn
        } else {
          return this.dataSource.userIdentifyColumn
        }
      },
      set (newVal) {
        this.requestParameter.userIdentifyColumn = newVal
      },
    },
    datetimeIdentifyColumn: {
      get () {
        if (this.requestParameter.datetimeIdentifyColumn && this.requestParameter.datetimeIdentifyColumn > 0) {
          return this.requestParameter.datetimeIdentifyColumn
        } else {
          return this.dataSource.datetimeIdentifyColumn
        }
      },
      set (newVal) {
        this.requestParameter.datetimeIdentifyColumn = newVal
      },
    },
    transactionIdentifierColumn: {
      get () {
        if (this.requestParameter.transactionIdentifierColumn && this.requestParameter.transactionIdentifierColumn.length > 0) {
          return this.requestParameter.transactionIdentifierColumn
        } else {
          return this.dataSource.transactionIdentifierColumn || []
        }
      },
      set (newVal) {
        this.requestParameter.transactionIdentifierColumn = newVal
      },
    },
    schemaTableHeaders () {
      if (!this.dataSource.dataSourceId) {
        return []
      }
      if (this.dataSource.dataSourceType === this.DATA_SOURCE_TYPES.MASTER.value) {
        if (this.dataSource.connectorType === this.CONNECTOR_TYPES.WEB.value ||
          this.dataSource.connectorType === this.CONNECTOR_TYPES.ANDROID.value ||
          this.dataSource.connectorType === this.CONNECTOR_TYPES.IOS.value) {
          return this.masterSchemaTableHeaders.slice(1)
        }
        return this.masterSchemaTableHeaders
      } else if (this.dataSource.dataSourceType === this.DATA_SOURCE_TYPES.TRANSACTION.value) {
        return this.transactionSchemaTableHeaders
      }
      return []
    },
    hashValue () {
      if (this.dataSource.dataSourceType === this.DATA_SOURCE_TYPES.MASTER.value) {
        return this.dataSource.masterHash
      } else if (this.dataSource.dataSourceType === this.DATA_SOURCE_TYPES.TRANSACTION.value) {
        return this.dataSource.transactionHash
      }
      return ''
    },
    apiTag () {
      // 「/」をエスケープするとでesLintエラーが起きてしまうのでesLintを一時的に無効化
      /* eslint-disable no-useless-escape */
      return `<script>
window.integralCore = window.integralCore || function(){
  (integralCore.params = integralCore.params||[]).push(arguments)
};
integralCore('', 'datasourceimport', '', '//{移管ドメイン}/');
var commonEl = document.createElement('script');
commonEl.src = '//cdn.{移管ドメイン}/js/integralCoreCommon.js';
commonEl.async = 1;
document.body.appendChild(commonEl);
function dataSourceImport(dataMetaJson) {
  let param = ['', 'datasourceimport', {
    "dataSourceHash": "${this.hashValue}",
    "dataMetaJson": dataMetaJson,
  }];
  integralCoreCommon.postDataSource(param);
}

// メソッド呼び出しサンプル
data = {
${
  this.headerMetaColumns.map(col => {
    return `  ${col.physicalName}: {{${this.dataTypes.find(type => type.value === col.dataType).text}}}`
  })
    .join(',\n')
}
};
dataSourceImport(data);
<\/script>`
      /* eslint-enable no-useless-escape */
    },
    siteTag () {
      // 「/」をエスケープするとでesLintエラーが起きてしまうのでesLintを一時的に無効化
      /* eslint-disable no-useless-escape */
      return `<script>
window.integralCore = window.integralCore || function(){
(integralCore.params=integralCore.params||[]).push(arguments)
};
integralCore('` + this.dataSource.siteHash + `', 'pageview');
<\/script>
<script async src='https://cdn.ic.${this.dataSource.measureDomain}/js/${this.dataSource.contractCompanyId}/${this.dataSource.siteMeasureScriptName}' type="text/javascript" charset="UTF-8"><\/script>`
      /* eslint-enable no-useless-escape */
    },
    sdkJson () {
      return `{
  "measure-url": "https://{移管対応済みのドメイン}/measure",
  "site-hash": "` + this.dataSource.siteHash + `"
}`
    },
    replacementS3path () {
      return process.env.VUE_APP_S3_BUCKET_NAME_PREFIX + this.$store.state.auth.loginUserDetail.loginUserWebWorkspaceName + process.env.VUE_APP_S3_BUCKET_NAME_SUFFIX + '/data-source-import/' + this.hashValue + '/replacement/'
    },
    differenceS3path () {
      return process.env.VUE_APP_S3_BUCKET_NAME_PREFIX + this.$store.state.auth.loginUserDetail.loginUserWebWorkspaceName + process.env.VUE_APP_S3_BUCKET_NAME_SUFFIX + '/data-source-import/' + this.hashValue + '/difference/'
    },
    dataTypes () {
      const tmpDataTypes = Object.values(this.DATA_TYPES)
      return tmpDataTypes
    },
    disabledUpdateSchema () {
      const transactionLink = this.dataSet.createRuleList?.find(rule =>
        this.dataSource.dataSourceId === rule.targetDataSourceId,
      )
      return this.dataSet.hasMasterLink || transactionLink || !this.canPut
    },
  },
  watch: {
    dataSource: {
      handler () {
        if (this.dataSource.headerMetaJson) {
          this.headerMetaColumns = JSON.parse(JSON.stringify(JSON.parse(this.dataSource.headerMetaJson).columns))
        } else {
          this.headerMetaColumns = []
        }
      },
      immediate: true,
    },
  },
  methods: {
    onClickAddSchemaColumnButton () {
      this.headerMetaColumns.push({
        index: this.headerMetaColumns.length + 1,
        dataType: null,
        logicalName: null,
        physicalName: null,
      })
    },
    onClickRemoveSchemaColumnButton (index) {
      this.headerMetaColumns.splice(index - 1, 1)
      // index 振りなおし
      let i = 1
      this.headerMetaColumns.forEach(column => {
        column.index = i++
      })
      // userIdentifyColumn 調整
      if (this.userIdentifyColumn !== null && this.userIdentifyColumn >= 0 && this.userIdentifyColumn > index) {
        // 消したカラムの index が userIdentifyColumn の index より若かったらずれるので調整する
        this.userIdentifyColumn = this.userIdentifyColumn - 1
      }
      // datetimeIdentifyColumn 調整
      if (this.datetimeIdentifyColumn !== null && this.datetimeIdentifyColumn >= 0 && this.datetimeIdentifyColumn > index) {
        // 消したカラムの index が datetimeIdentifyColumn の index より若かったらずれるので調整する
        this.datetimeIdentifyColumn = this.datetimeIdentifyColumn - 1
      }
      // transactionIdentifierColumn 調整
      if (this.transactionIdentifierColumn !== null && this.transactionIdentifierColumn.length >= 0) {
        const tmpTransactionIdentifierColumn = []
        this.transactionIdentifierColumn.forEach((val) => {
          if (val > index) {
            // 消したカラムの index が transactionIdentifierColumn の index より若かったらずれるので調整する
            tmpTransactionIdentifierColumn.push(val - 1)
          } else {
            tmpTransactionIdentifierColumn.push(val)
          }
        })
        this.transactionIdentifierColumn = tmpTransactionIdentifierColumn
      }
    },
    onSaveSchemaClick () {
      this.$validator.validateAll('schema').then((result) => {
        if (!result) return
        // userIdentifyColumn は必須
        if (this.userIdentifyColumn === null || this.userIdentifyColumn < 0) {
          this.notifyErrorMessage(this.$t('notify.error.dataSource.requireUserIdentifyColumn'))
          return
        }
        // userIdentifyColumn は文字列型のみ
        if (this.headerMetaColumns[this.userIdentifyColumn - 1].dataType !== this.DATA_TYPES.STRING.value) {
          this.notifyErrorMessage(this.$t('notify.error.dataSource.userIdentifyColumnShouldBeStringType'))
          return
        }
        // カラム物理名に " の使用は不可
        const hasDoubleQuotation = this.headerMetaColumns.find(col => col.physicalName.indexOf('"') >= 0)
        if (hasDoubleQuotation) {
          this.notifyErrorMessage(this.$t('notify.error.dataSource.prohibitedUseDoubleQuotation'))
          return
        }
        // カラム物理名の重複不可(大文字小文字問わず)
        for (const checkCol of this.headerMetaColumns) {
          if (this.headerMetaColumns.find(col => col.index !== checkCol.index && col.physicalName.toLowerCase() === checkCol.physicalName.toLowerCase())) {
            this.notifyErrorMessage(this.$t('notify.error.dataSource.duplicatePhysicalName'))
            return
          }
        }
        // トランザクションの場合のみ有効なバリデーション
        if (this.dataSource.dataSourceType === this.DATA_SOURCE_TYPES.TRANSACTION.value) {
          // datetimeIdentifyColumn, transactionIdentifierColumn はトランザクションの場合必須
          if (this.datetimeIdentifyColumn === null || this.datetimeIdentifyColumn < 0) {
            this.notifyErrorMessage(this.$t('notify.error.dataSource.requireDatetimeIdentifyColumn'))
            return
          }
          if (this.transactionIdentifierColumn.length === 0) {
            this.notifyErrorMessage(this.$t('notify.error.dataSource.requireTransactionIdentifierColumn'))
            return
          }
          // datetimeIdentifyColumn は日時型のみ
          if (this.datetimeIdentifyColumn &&
              this.headerMetaColumns[this.datetimeIdentifyColumn - 1] && // datetimeIdentifyColumn は 1 始まり
              this.headerMetaColumns[this.datetimeIdentifyColumn - 1].dataType !== this.DATA_TYPES.DATETIME.value) {
            this.notifyErrorMessage(this.$t('notify.error.dataSource.datetimeIdentifyColumnShuldBeDateType'))
            return
          }
        }
        this.$emit('saveSchema', this.dataSource.dataSourceId, { columns: this.headerMetaColumns }, this.userIdentifyColumn, this.datetimeIdentifyColumn, this.transactionIdentifierColumn)
      })
    },
    onCancelClick () {
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-input--radio-group {
  margin: 16px 0;
}
</style>
