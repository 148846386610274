<template>
  <v-data-table
    disable-filtering
    disable-pagination
    disable-sort
    hide-default-footer
    :headers="tableHeaders"
    :items="histories"
    :loading="loading">
    <template #item.execStatus="{ item }">
      <v-chip
        v-if="item.execStatus === EXEC_STATUS.PROCESSING.value"
        color="ongoing"
      >
        {{ enumUtil.getEnumFromValue(EXEC_STATUS, item.execStatus).text }}
      </v-chip>
      <v-chip
        v-else-if="item.execStatus === EXEC_STATUS.SUCCESS.value"
        color="success"
      >
        {{ enumUtil.getEnumFromValue(EXEC_STATUS, item.execStatus).text }}
      </v-chip>
      <v-chip
        v-else-if="item.execStatus === EXEC_STATUS.FAILED.value"
        color="fault"
      >
        {{ enumUtil.getEnumFromValue(EXEC_STATUS, item.execStatus).text }}
      </v-chip>
      <v-chip
        v-else-if="item.execStatus === undefined"
        color="pending"
      >
        未実行
      </v-chip>
    </template>
    <template #item.log="{ item }">
      <v-btn
        small
        target="_blank"
        :href="getDownloadUrl(item.segmentExportScriptExecHistoryId)"
        :disabled="item.execStatus !== EXEC_STATUS.SUCCESS.value && item.execStatus !== EXEC_STATUS.FAILED.value"
      >{{ $t(`btn.segmentExportScriptHistory.download`) }}</v-btn>
    </template>
  </v-data-table>
</template>

<script>
// enum
import EXEC_STATUS from '@/enum/EXEC_STATUS'
// util
import enumUtil from '@/utils/enumUtil'

export default {
  name: 'SegmentExportScriptHistoryTable',
  mixins: [
    enumUtil,
    EXEC_STATUS,
  ],
  props: {
    histories: {
      type: Array,
      default () {
        return []
      },
    },
    loading: {
      type: Boolean,
      default () {
        return false
      },
    },
  },
  data () {
    return {
      tableHeaders: [
        {
          text: this.$t('table.segmentExportScriptHistory.startDatetime'),
          value: 'startDatetime',
          width: '12%',
        },
        {
          text: this.$t('table.segmentExportScriptHistory.endDatetime'),
          value: 'endDatetime',
          width: '12%',
        },
        {
          text: this.$t('table.segmentExportScriptHistory.execStatus'),
          value: 'execStatus',
          width: '10%',
        },
        {
          text: this.$t('table.segmentExportScriptHistory.message'),
          value: 'errorMessage',
          width: '20%',
          sortable: false,
        },
        {
          text: this.$t('table.segmentExportScriptHistory.log'),
          value: 'log',
          width: '8%',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    getDownloadUrl (segmentExportScriptExecHistoryId) {
      return '//' + document.domain + '/api/segmentExportScript/history/log/' + segmentExportScriptExecHistoryId + '/'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
</style>
