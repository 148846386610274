export default {
  data () {
    return {
      PERIOD_TYPES: {
        ALL: { text: '全期間', value: 1 },
        TODAY: { text: '当日', value: 2 },
        PREVIOUS_DAY: { text: '前日', value: 3 },
        THIS_MONTH: { text: '今月', value: 4 },
        LAST_MONTH: { text: '先月', value: 5 },
        FOR_1_WEEK: { text: '直近1週間', value: 6 },
        FOR_30_DAYS: { text: '直近30日', value: 7 },
        FOR_90_DAYS: { text: '直近90日', value: 8 },
        CUSTOM: { text: '日付指定', value: 9 },
        CUSTOM_RELATIVE_PERIOD: { text: '期間指定', value: 10 },
      },
    }
  },
}
