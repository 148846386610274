<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn :to="{ name:'AccountRoles' }">{{ $t(`btn.backToList`) }}</v-btn>
      </template>
    </information-bar>
    <account-role-setting-form
      :accountRoleSetting="formParameter"
      :buttonType="buttonType"
      :isLoading="creatingAccountRole || updatingAccountRole"
      :roleHeaders="tableHeaders"
      @changeRoleName="val => formParameter.roleName = val"
      @changeNote="val => formParameter.note = val"
      @request="requestAccountRoleSetting()"
    />
  </v-card>
</template>

<script>
// component
import accountRoleSettingForm from '@/components/AccountRoleSetting/AccountRoleSettingForm'
// enum
import CONFIRM_MESSAGES from '@/enum/notify/CONFIRM_MESSAGES'
// util
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'AccountRoleSetting',
  components: {
    'account-role-setting-form': accountRoleSettingForm,
  },
  mixins: [
    CONFIRM_MESSAGES,
    enumUtil,
    notifyUtil,
  ],
  data () {
    return {
      formParameter: {
        roleId: '',
        roleName: '',
        note: '',
        authorityList: [
          { functionName: 'customer', pageName: 'カスタマー', getAuthorized: false, postAuthorized: false, putAuthorized: false, deleteAuthorized: false },
          { functionName: 'scvSetting', pageName: 'SCV設定', getAuthorized: false, postAuthorized: false, putAuthorized: false, deleteAuthorized: false },
          { functionName: 'segment', pageName: 'セグメント', getAuthorized: false, postAuthorized: false, putAuthorized: false, deleteAuthorized: false },
          { functionName: 'dataSet', pageName: 'データセット', getAuthorized: false, postAuthorized: false, putAuthorized: false, deleteAuthorized: false },
          { functionName: 'segmentExportScript', pageName: 'セグメントエクスポートスクリプト', getAuthorized: false, postAuthorized: false, putAuthorized: false, deleteAuthorized: false },
          { functionName: 'jobs', pageName: 'ジョブ', getAuthorized: false, postAuthorized: false, putAuthorized: false, deleteAuthorized: false },
          { functionName: 'accounts', pageName: 'アカウント管理', getAuthorized: false, postAuthorized: false, putAuthorized: false, deleteAuthorized: false },
          { functionName: 'role', pageName: '権限管理', getAuthorized: false, postAuthorized: false, putAuthorized: false, deleteAuthorized: false },
        ],
      },
      tableHeaders: [
        {
          text: '全選択',
          value: 'action',
          width: '10%',
          sortable: false,
        },
        {
          text: '機能名',
          value: 'pageName',
          sortable: false,
        },
        {
          text: '表示',
          value: 'read',
          width: '10%',
          sortable: false,
        },
        {
          text: '作成',
          value: 'create',
          width: '10%',
          sortable: false,
        },
        {
          text: '編集',
          value: 'update',
          width: '10%',
          sortable: false,
        },
        {
          text: '削除',
          value: 'delete',
          width: '10%',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    buttonType () {
      if (this.$router.currentRoute.name === 'AccountRoleSetting') {
        return 'POST'
      } else if (this.$router.currentRoute.name === 'AccountRoleEdit') {
        return 'PUT'
      } else {
        return null
      }
    },
    creatingAccountRole () {
      return this.$store.state.accountRole.creatingAccountRole
    },
    updatingAccountRole () {
      return this.$store.state.accountRole.updatingAccountRole
    },
  },
  created () {
    if (this.$router.currentRoute.name === 'AccountRoleEdit') {
      this.$store.dispatch('accountRole/getAccountRole', this.$route.params.roleId)
        .then(res => {
          for (const key in res.data.data.authority) {
            const index = this.formParameter.authorityList.findIndex((authority) => authority.functionName === key)
            this.formParameter.authorityList[index].getAuthorized = res.data.data.authority[key].getAuthorized
            this.formParameter.authorityList[index].postAuthorized = res.data.data.authority[key].postAuthorized
            this.formParameter.authorityList[index].putAuthorized = res.data.data.authority[key].putAuthorized
            this.formParameter.authorityList[index].deleteAuthorized = res.data.data.authority[key].deleteAuthorized
          }
          this.formParameter.roleId = res.data.data.roleId
          this.formParameter.roleName = res.data.data.roleName
          this.formParameter.note = res.data.data.note
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    }
  },
  methods: {
    requestAccountRoleSetting () {
      const authority = {}
      this.formParameter.authorityList.forEach(auth => {
        authority[auth.functionName] = JSON.parse(JSON.stringify(auth))
        delete authority[auth.functionName].functionName
        delete authority[auth.functionName].pageName
      })
      const requestParameter = {
        roleId: this.formParameter.roleId,
        roleName: this.formParameter.roleName,
        note: this.formParameter.note,
        authority: authority,
      }
      if (this.buttonType === 'POST') {
        this.$store.dispatch('accountRole/createRole', requestParameter)
          .then(() => {
            this.notifySuccessMessage(this.$t('notify.accountRole') + this.$t('notify.success.create'))
            this.$router.push({ name: 'AccountRoles' })
          }).catch(err => {
            this.handleErrorResponse(err)
          })
      } else if (this.buttonType === 'PUT') {
        this.$store.dispatch('accountRole/updateRole', requestParameter)
          .then(() => {
            this.notifySuccessMessage(this.$t('notify.accountRole') + this.$t('notify.success.update'))
            this.$router.push({ name: 'AccountRoles' })
          }).catch(err => {
            this.handleErrorResponse(err)
          })
      }
    },
  },
}
</script>
