<template>
  <login-form />
</template>

<script>
import loginForm from '@/components/Login/loginForm'

export default {
  name: 'Login',
  components: {
    'login-form': loginForm,
  },
  created () {
    let loginFailed = false
    this.$store.dispatch('auth/getLoginUser')
      .catch(() => (loginFailed = true))
      .finally(() => {
        this.$store.dispatch('auth/checkAuth')
          .catch(() => (loginFailed = true))
          .finally(() => {
            if (this.$store.state.auth.loginUserDetail.mfaRequired && !this.$store.state.auth.loginUserDetail.mfaDeviceRegistered) {
              // MFA 必須でデバイス未登録なのでデバイス登録画面 (QRコード表示) へ
              this.$router.push({ name: 'TotpRegister' })
            } else if (
              this.$store.state.auth.loginUserDetail.mfaRequired &&
              this.$store.state.auth.loginUserDetail.mfaDeviceRegistered &&
              !this.$store.state.auth.loginUserDetail.mfaAuthenticated
            ) {
              // MFA 必須でデバイス登録済みで MFA 未認証なのでワンタイムパスワード入力画面へ
              this.$router.push({ name: 'TotpLogin' })
            } else if (!this.$store.state.auth.loginUserDetail.passwordChangedFlg) {
              // パスワード変更が必要なのでパスワード変更画面へ
              this.$router.push({ name: 'PasswordChange' })
            } else if (!loginFailed && this.$store.state.auth.loginUserDetail.loginUserId > 0) {
              // 既にログイン済みだったらログイン済み画面へ遷移させる
              this.forward()
            }
          })
      })
  },
  methods: {
    forward () {
      if (this.$store.state.route.query.redirect && this.$router.resolve(this.$store.state.route.query.redirect).resolved.name !== 'PageNotFound') {
        this.$router.push({ name: this.$store.state.route.query.redirect })
      } else if (this.$store.state.auth.loginUserDetail.authority.dataSet.getAuthorized) {
        this.$router.push({ name: 'DataSets' })
      } else {
        this.$router.push({ name: 'Release' })
      }
    },
  },
}
</script>
