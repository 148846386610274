export default {
  data () {
    return {
      OPERATOR_TYPE_WITH_LOGICAL_NOT: {
        NOT_GT: { text: '超過ではない', value: 3, symbol: 'NOT_GT' },
        NOT_GT_EQ: { text: '以上ではない', value: 4, symbol: 'NOT_GT_EQ' },
        NOT_LT: { text: '未満ではない', value: 5, symbol: 'NOT_LT' },
        NOT_LT_EQ: { text: '以下ではない', value: 6, symbol: 'NOT_LT_EQ' },
        NOT_EQ: { text: '等しくない', value: 7, symbol: 'NOT_EQ' },
        NOT_PREFIX_MATCH: { text: 'で始まらない', value: 9, symbol: 'NOT_F_MATCH' },
        NOT_PARTIAL_MATCH: { text: '含まない', value: 10, symbol: 'NOT_LIKE' },
        NOT_BACKWARD_MATCH: { text: 'で終わらない', value: 11, symbol: 'NOT_P_MATCH' },
        IS_NULL: { text: '持っていない', value: 14, symbol: 'IS NULL' },
        EXCEPT_DAY_AGO: { text: '日前以外', value: 29, symbol: 'EXCEPT_DAY_AGO' },
        EXCEPT_DAY_NEXT: { text: '日後以外', value: 30, symbol: 'EXCEPT_DAY_NEXT' },
        NOT_WITHIN_DAY_AGO: { text: '日以内ではない', value: 31, symbol: 'NOT_WITHIN_DAY_AGO', prepend: '過去' },
        NOT_WITHIN_DAY_NEXT: { text: '日以内ではない', value: 32, symbol: 'NOT_WITHIN_DAY_NEXT', prepend: '翌' },
        NOT_DAY_AGO_EXCEPT_YEAR: { text: '日前ではない (月日のみ対象)', value: 33, symbol: 'NOT_DAY_AGO_EXCEPT_YEAR' },
        NOT_DAY_NEXT_EXCEPT_YEAR: { text: '日後ではない (月日のみ対象)', value: 34, symbol: 'NOT_DAY_NEXT_EXCEPT_YEAR' },
        NOT_WITHIN_DAY_AGO_EXCEPT_YEAR: { text: '日以内ではない (月日のみ対象)', value: 35, symbol: 'NOT_WITHIN_DAY_AGO_EXCEPT_YEAR', prepend: '過去' },
        NOT_WITHIN_DAY_NEXT_EXCEPT_YEAR: { text: '日以内ではない (月日のみ対象)', value: 36, symbol: 'NOT_WITHIN_DAY_NEXT_EXCEPT_YEAR', prepend: '翌' },
      },
    }
  },
}
