export default {
  http: {
    error: {
      400: 'リクエスト内容が不正です (400: Bad Request)',
      401: 'ログイン状態を確認してください (401: Unauthorized)',
      403: '権限がありません (403: Forbidden)',
      404: '対象が見つかりません (404: Not Found)',
      408: 'リクエストがタイムアウトしました (408: Request Timeout)',
      500: '処理中にサーバーでエラーが発生しました (500: Internal Server Error)',
      502: 'サーバーでエラーが発生しました (502: Bad Gateway)',
      503: '現在サービスを利用できません (503: Service Unavailable)',
      504: 'リクエストがタイムアウトしました (504: Gateway Timeout)',
    },
  },
}
