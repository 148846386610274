import axios from '@/axios'

const scvSettings = {
  namespaced: true,
  state: {
    scvSettings: [],
    loadingScvSettings: true,
  },
  mutations: {
    setScvSettings (state, resData) {
      resData.sort((a, b) => a.scvColumnIndex - b.scvColumnIndex)
      state.scvSettings = resData
    },
    setLoadingScvSettings (state, newval) {
      state.loadingScvSettings = newval
    },
    resetScvSettings (state) {
      state.scvSettings = []
    },
  },
  actions: {
    /**
     * SCV設定に必要な項目を取得
     * stateに保存しない
     * @return {Promise}
     */
    async getScvSettings ({ state, commit }) {
      commit('setLoadingScvSettings', true)
      return await axios.get('/scv/setting/')
        .then(res => {
          return Promise.resolve({
            scvSettings: res.data.data.scvColumnList.sort((a, b) => a.scvColumnIndex - b.scvColumnIndex),
          })
        }).catch((err) => {
          return Promise.reject(err)
        }).finally(() => {
          commit('setLoadingScvSettings', false)
        })
    },
    /**
     * SCV作成時に利用された最新のSCV設定を取得
     * columnDisplaySetting用にstateに保存する
     * @return {Promise}
     */
    async getValidScvSettings ({ state, commit }) {
      commit('setLoadingScvSettings', true)
      return await axios.get('/scv/setting/valid/')
        .then(res => {
          commit('setScvSettings', JSON.parse(JSON.stringify(res.data.data.scvColumnList)))
          return Promise.resolve({
            scvSettings: res.data.data.scvColumnList.sort((a, b) => a.scvColumnIndex - b.scvColumnIndex),
          })
        }).catch((err) => {
          commit('resetScvSettings')
          return Promise.reject(err)
        }).finally(() => {
          commit('setLoadingScvSettings', false)
        })
    },
    /**
     * prefix1件追加
     * @param  {Object} requestParameter リクエストパラメーター
     * @return {Promise}
     */
    async insertScvSetting ({ commit }, requestParameter) {
      await axios.post('/scv/setting/', {
        scvColumnLogicalName: requestParameter.scvLogicalColumnName,
        scvColumnIndex: requestParameter.scvColumnIndex,
        dataType: requestParameter.dataType,
        isScvIdentify: requestParameter.isScvIdentify,
        scvColumnChoiceSettingList: requestParameter.scvColumnChoiceSettingList,
      }).catch((err) => {
        return Promise.reject(err)
      })
    },
    /**
     * prefix1件更新
     * @param  {Object} requestParameter リクエストパラメーター
     * @return {Promise}
     */
    async updateScvSetting ({ commit }, requestParameter) {
      await axios.put('/scv/setting/' + requestParameter.scvColumnId + '/', {
        scvColumnLogicalName: requestParameter.scvLogicalColumnName,
        scvColumnIndex: requestParameter.scvColumnIndex,
        dataType: requestParameter.dataType,
        isScvIdentify: requestParameter.isScvIdentify,
        scvColumnChoiceSettingList: requestParameter.scvColumnChoiceSettingList,
      }).catch((err) => {
        return Promise.reject(err)
      })
    },
    async deleteScvSetting ({ commit }, scvColumnId) {
      await axios({ method: 'delete', url: '/scv/setting/' + scvColumnId + '/' })
    },
  },
}
export default scvSettings
