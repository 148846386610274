<template>
  <div>
    <!-- v5.3.0 -->
    <h4 id="v5.3.0">
      v5.3.0 (2019/09/09~)
    </h4>
    <h5>サイト一覧</h5>
    <ul>
      <li>サイトを削除する際に表示されるダイアログのレイアウトを変更しました。</li>
    </ul>
    <h5>コンバージョン一覧</h5>
    <ul>
      <li>コンバージョンを削除する際に表示されるダイアログのレイアウトを変更しました。</li>
    </ul>
    <h5>ジョブ一覧</h5>
    <ul>
      <li>ジョブを削除する際に表示されるダイアログのレイアウトを変更しました。</li>
    </ul>
    <h5>ジョブ設定</h5>
    <ul>
      <li>ジョブを削除する際に表示されるダイアログのレイアウトを変更しました。</li>
      <li>タスク種別「入出力」においてリージョンの入力形式を変更しました。</li>
    </ul>
    <h5>全般</h5>
    <ul>
      <li>日時を入力するダイアログを表示中に、ダイアログ外をクリックしてもダイアログが閉じないよう変更しました。</li>
    </ul>

    <!-- v5.2.0 -->
    <h4 id="v5.2.0">
      v5.2.0 (2019/08/21~)
    </h4>
    <h5>ヘッダー&サイドバー</h5>
    <ul>
      <li>レイアウトを調整しました。</li>
    </ul>
    <h5>ログイン</h5>
    <ul>
      <li>名称を修正しました。</li>
      <span class="sub-text">
        「CompanyAccountId」→「企業アカウントID」<br>
        「EmailAddress」→「メールアドレス」<br>
        「Password」→「パスワード」
      </span>
      <li>ブラウザの自動補完機能によって補完されたテキストが、入力フォームのプレースホルダーと重なってしまう問題を修正しました。</li>
    </ul>
    <h5>セグメント詳細</h5>
    <ul>
      <li>セグメントを削除後にセグメント一覧画面へ遷移せず、そのまま他のセグメントを選択して確認できるよう挙動を変更しました。</li>
    </ul>
    <h5>セグメント設定</h5>
    <ul>
      <li>フィルタ条件を一括でクリアする、フィルタ条件クリアボタンが実装されました。</li>
      <span class="sub-text">選択中の検索属性のフィルタ条件のみがクリアされます。</span>
      <li>項目別実績のコンバージョン別実績の絞り込み条件から「PV」と「セッション数」を削除しました。</li>
    </ul>
    <h5>サイト一覧</h5>
    <ul>
      <li>ソート項目の項目名を一部修正しました。</li>
    </ul>
    <h5>ジョブ設定</h5>
    <ul>
      <li>ブラウザの自動補完機能によって意図していない入力フォームにテキストが補完されてしまう問題を修正しました。</li>
      <li>タスク種別「SQL」を選択時の入力値チェックの挙動を修正しました。</li>
    </ul>
    <h5>全般</h5>
    <ul>
      <li>サマリの集計値に単位を追記しました。</li>
    </ul>

    <!-- v5.1.0 -->
    <h4 id="v5.1.0">
      v5.1.0 (2019/08/07~)
    </h4>
    <h5>ヘッダー</h5>
    <ul>
      <li>契約企業名とログインユーザー名をユーザーメニューに移行しました。</li>
      <li>レイアウトを調整しました。</li>
    </ul>
    <h5>サイドバー</h5>
    <ul>
      <li>レイアウトを調整しました。</li>
    </ul>
    <h5>ログイン</h5>
    <ul>
      <li>ログイン失敗時に通知が表示されるよう修正しました。</li>
    </ul>
    <h5>セグメント一覧</h5>
    <ul>
      <li>セグメントの種別をアイコン化しました。</li>
      <span class="sub-text">S:標準セグメント / C:カスタムセグメント</span>
      <li>各セグメントごとのタイルのレイアウトを調整しました。</li>
    </ul>
    <h5>セグメント詳細</h5>
    <ul>
      <li>セグメント削除時に課金プランが変更される場合、アラートで確認事項が表示されるようになりました。</li>
    </ul>
    <h5>セグメント設定</h5>
    <ul>
      <li>セグメントの期間指定の「カスタム」を「日付指定」に名称を変更しました。</li>
      <span class="sub-text">※機能に変更はありません。</span>
      <li>セグメントの期間指定の「カスタム」選択時の入力規則を変更しました。</li>
      <span class="sub-text">開始日付または終了日付どちらか必須項目となります。</span>
      <li>「内部絞り込み」を「項目別実績」に名称を変更しました。</li>
      <span class="sub-text">※機能に変更はありません。</span>
      <li>セグメント作成/更新ボタン押下時の挙動を変更しました。</li>
      <span class="sub-text">設定されるフィルタ条件が確認ダイアログで表示され、ダイアログ上の作成/更新ボタン押下するとセグメントが作成/更新されます。</span>
      <li>画面のレイアウトを調整しました。</li>
    </ul>
    <h5>サイト一覧</h5>
    <ul>
      <li>ソート機能とフィルタリング機能を実装しました。</li>
      <li>一覧リストに「計測ドメイン」列を追加しました。</li>
      <li>Webサイトのタグ設定ダイアログに計測タグをクリップボードにコピーするボタンを実装しました。</li>
      <li>iOS/AndroidアプリのSDK設定ダイアログにSDK用JSONをクリップボードにコピーするボタンを実装しました。</li>
      <li>一覧リストとWebサイト/iOS/Androidのダイアログのレイアウトを調整しました。</li>
    </ul>
    <h5>コンバージョン一覧画面</h5>
    <ul>
      <li>ソート機能とフィルタリング機能を実装しました。</li>
      <li>表示中のコンバージョンに紐付くサイトの名称を見出しに追加しました。</li>
      <li>サイト一覧への戻るボタンを実装しました。</li>
      <li>一覧リストのレイアウトを調整しました。</li>
    </ul>
    <h5>顧客情報設定</h5>
    <ul>
      <li>一覧リストの「登録方法」列を削除しました。</li>
    </ul>
    <h5>ジョブ一覧</h5>
    <ul>
      <li>「スタンバイ」を「定期実行」に名称を変更しました。</li>
      <span class="sub-text">※機能に変更はありません。</span>
      <li>スケジュールの「毎週」と「毎月」の表示方法を変更しました。</li>
      <span class="sub-text">「毎週」の設定曜日と「毎月」の設定日付はインフォメーションアイコンにカーソルを置くことで確認できます。</span>
      <li>「定期実行」ボタンクリック時の挙動を調整しました。</li>
      <li>一覧リストのレイアウトを調整しました。</li>
    </ul>
    <h5>ジョブ設定</h5>
    <ul>
      <li>「スタンバイ」を「定期実行」に名称を変更しました。</li>
      <span class="sub-text">※機能に変更はありません。</span>
      <li>「定期実行」ボタンクリック時の挙動を調整しました。</li>
      <li>タスク設定のSQL入力フォームの入力規則を変更しました。</li>
      <span class="sub-text">タスク1つにつき入力できるSQLクエリは1件となります。複数件SQLクエリが入力されている場合、ジョブを作成/更新することはできません。</span>
      <li>作成/更新ボタン押下時に、不正な入力が含まれるタスクが全てハイライトされるようになりました。</li>
      <li>「戻る」ボタン押下時に確認ダイアログが表示されるよう変更しました。</li>
      <li>画面のレイアウトを調整しました。</li>
    </ul>
    <h5>ジョブ実行履歴一覧</h5>
    <ul>
      <li>一覧リストのレイアウトを調整しました。</li>
    </ul>
    <h5>その他全般</h5>
    <ul>
      <li>桁数省略されている数値にカーソルを合わせた際に省略前の数値が表示されるようになりました。</li>
      <li>一部文言とレイアウトを調整しました。</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'version5',
  minorVersions: [
    'v5.3.0',
    'v5.2.0',
    'v5.1.0',
  ],
}
</script>
