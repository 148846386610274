<template>
  <v-card outlined tile>
    <v-card-text>
      <v-list v-if="buildType === SEGMENT_BUILD_TYPE.CUSTOM.value" class="pa-0">
        <v-list-item-content class="segmentFilterRule pa-0">
          <v-list-item-subtitle>
            {{ $t(`form.jobSetting.job.schedule`) }}：{{ enumUtil.getEnumFromValue(JOB_RECURRING_TYPES, recurringType).text }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ $t('text.filter') }}<span v-html="$t('text.escapeDelimiter')" />{{ NO_RULE_CAUSED_BY_CUSTOM }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list>
      <v-list v-else class="pa-0">
        <v-list-item-content class="segmentFilterRule pa-0">
          <!-- フィルタ条件 -->
          <v-list-item-subtitle v-if="buildType === SEGMENT_BUILD_TYPE.CUSTOM.value">
            {{ NO_RULE_CAUSED_BY_CUSTOM }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="filterTextObject.filterTextList.length === 0">
            {{ NO_FILTER_RULES }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else>
            <div>{{ filterTextObject.logicalOperatorType }}</div>
            <v-tooltip bottom max-width="400">
              <template #activator="{ on }">
                <ul v-on="on">
                  <li v-for="(filter, index) in filterTextObject.filterTextList" :key="'filterText_' + String(index)">
                    {{ filter.period }} {{ filter.internalFilter === '' ? '' : '/ ' + filter.internalFilter }} / {{ filter.filter }}
                  </li>
                </ul>
              </template>
              <ul>
                <li v-for="(filter, index) in filterTextObject.filterTextList" :key="'filterText_' + String(index)">
                  {{ filter.period }} {{ filter.internalFilter === '' ? '' : '/ ' + filter.internalFilter }} / {{ filter.filter }}
                </li>
              </ul>
            </v-tooltip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
// enum
import JOB_RECURRING_TYPES from '@/enum/JOB_RECURRING_TYPES'
import SEGMENT_BUILD_TYPE from '@/enum/SEGMENT_BUILD_TYPE'
// utils
import enumUtil from '@/utils/enumUtil'
import segmentTexts from '@/utils/segmentTexts'

export default {
  name: 'SegmentConfigList',
  mixins: [
    JOB_RECURRING_TYPES,
    SEGMENT_BUILD_TYPE,
    enumUtil,
    segmentTexts,
  ],
  props: {
    buildType: {
      type: Number,
      default: null,
    },
    filterTextObject: {
      type: Object,
      default () {
        return {
          logicalOperatorType: '',
          filterTextList: [],
        }
      },
    },
    jobSetting: {
      type: Object,
      default: null,
    },
  },
  computed: {
    recurringType () {
      if (this.jobSetting && Object.prototype.hasOwnProperty.call(this.jobSetting, 'recurringType')) return this.jobSetting.recurringType
      return null
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-list) using ($integral-core-theme) {
  .segmentFilterRule {
    .v-list-item__subtitle {
      color: map-deep-get($integral-core-theme, 'views', 'segmentDetail', 'segmentConfigList', 'text');
    }
  }
}

.segmentFilterRule {
  .v-list-item__subtitle {
    font-size: 12px;
    letter-spacing: 0.36px;
    line-height: 18px;
    white-space: normal;
  }
}
</style>
