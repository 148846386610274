import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      light: {
        primary: '#127BB0', // blue-700
        secondary: '#4FB2E3', // blue-400
        accent: '#054263', // blue-900
        error: '#E94F5E', // red-400
        background: '#F0F8FC', // blue-50
        // ラジオ、チェックボックス用
        inputSelectionControl: '#127BB0', // blue-700
      },
      dark: {
        primary: '#054263', // blue-900
        secondary: '#0A5A82', // blue-800
        accent: '#1F8BC1', // blue-600
        error: '#EF3B48', // red-500
        background: '#070708', // gray-900
        // ラジオ、チェックボックス用
        inputSelectionControl: '#2A2A2A', // gray-800
      },
    },
  },
})
