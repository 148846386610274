export default {
  data () {
    return {
      EXEC_STATUS: {
        PROCESSING: { text: '実行中', value: 1 },
        SUCCESS: { text: '成功', value: 2 },
        FAILED: { text: '失敗', value: 9 },
      },
    }
  },
}
