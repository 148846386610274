import axios from '@/axios'
import router from '@/router'
import checkValueUtil from '@/utils/checkValueUtil'

const jobs = {
  namespaced: true,
  state: {
    sortParameter: {
      orderBy: 'updatedAt',
      isDesc: true,
    },
    enabledFilter: true,
    filterParameter: {
      enabled: null,
      page: 1,
      lastStatus: null,
      limit: 50,
      name: null,
      recurringType: null,
      createdBy: null,
      updatedBy: null,
    },
    loadingJobs: false,
    postingJob: false,
    puttingJob: false,
    bulkDeletingJob: false,
  },
  mutations: {
    setOrderBy (state, newVal) {
      state.sortParameter.orderBy = newVal
    },
    setIsDesc (state, newVal) {
      state.sortParameter.isDesc = newVal
    },
    setFilter (state, newVal) {
      state.filterParameter = newVal
    },
    setEnabledFilter (state, newVal) {
      state.enabledFilter = newVal
    },
    setLoadingJobs (state, newVal) {
      state.loadingJobs = newVal
    },
    setPostingJob (state, newVal) {
      state.postingJob = newVal
    },
    setPuttingJob (state, newVal) {
      state.puttingJob = newVal
    },
    setBulkDeletingJob (state, newVal) {
      state.bulkDeletingJob = newVal
    },
  },
  getters: {
    getQueryParams (state) {
      const params = Object.assign({}, state.sortParameter, state.filterParameter, { enabledFilter: state.enabledFilter })
      for (const key in params) {
        if (checkValueUtil.isNullOrEmpty(params[key])) delete params[key]
      }
      return params
    },
  },
  actions: {
    /**
     * ジョブ一覧リストの取得
     */
    async getJobs ({ commit, state }) {
      commit('setLoadingJobs', true)
      let queryOrderBy = 'updated_at'
      if (state.sortParameter.orderBy) {
        // キャメルからスネークに変換
        queryOrderBy = state.sortParameter.orderBy.replace(/([A-Z])/g, (str) => { return '_' + str.charAt(0).toLowerCase() })
      }

      const queryParameter = {
        isDesc: state.sortParameter.isDesc,
        orderBy: queryOrderBy,
        offset: (state.filterParameter.page - 1) * state.filterParameter.limit,
        limit: state.filterParameter.limit,
        enabled: null,
        lastStatus: null,
        name: null,
        rType: null,
        createdBy: null,
        updatedBy: null,
      }
      if (state.enabledFilter) {
        queryParameter.enabled = state.filterParameter.enabled
        queryParameter.lastStatus = state.filterParameter.lastStatus
        queryParameter.name = state.filterParameter.name
        queryParameter.rType = state.filterParameter.recurringType
        queryParameter.createdBy = state.filterParameter.createdBy
        queryParameter.updatedBy = state.filterParameter.updatedBy
      }

      return await axios.get('/jobs/', {
        params: queryParameter,
      }).then((res) => {
        return Promise.resolve({
          jobs: res.data.data.jobs,
          paging: res.data.paging,
        })
      }).catch((err) => {
        return Promise.reject(err)
      }).finally(() => {
        commit('setLoadingJobs', false)
      })
    },
    /**
     * ID指定したジョブを1件取得
     * @param  {Number}  jobId  取得するジョブのID
     */
    async getJob ({ commit }, jobId) {
      return await axios.get('/jobs/' + jobId + '/')
        .then((res) => {
          return Promise.resolve({
            job: res.data.data,
          })
        }).catch((error) => {
          if (error.response.status === 404) router.push({ name: 'PageNotFound' }, () => {})
        })
    },
    /**
     * ジョブ1件作成
     * @param  {Object}  postParameter ジョブ作成用リクエストパラメーター
     */
    async postJob ({ commit }, postParameter) {
      commit('setPostingJob', true)
      return await axios.post('/jobs/', {
        jobName: postParameter.jobName,
        executionDate: postParameter.executionDate,
        executionTime: postParameter.executionTime,
        recurringType: postParameter.recurringType,
        enabled: postParameter.enabled ? 1 : 0,
        jobTasks: postParameter.jobTasks,
        note: postParameter.note,
        weeklyJobSetting: postParameter.weeklyJobSetting,
        monthlyJobSetting: postParameter.monthlyJobSetting,
      }).finally(() => {
        commit('setPostingJob', false)
      })
    },
    /**
     * ジョブ1件更新
     * @param  {Object}  putParameter ジョブ更新用リクエストパラメーター
     */
    async putJob ({ commit }, putParameter) {
      commit('setPuttingJob', true)
      return await axios.put('/jobs/' + putParameter.id + '/', {
        jobName: putParameter.jobName,
        executionDate: putParameter.executionDate,
        executionTime: putParameter.executionTime,
        recurringType: putParameter.recurringType,
        enabled: putParameter.enabled ? 1 : 0,
        jobTasks: putParameter.jobTasks,
        note: putParameter.note,
        weeklyJobSetting: putParameter.weeklyJobSetting,
        monthlyJobSetting: putParameter.monthlyJobSetting,
      }).finally(() => {
        commit('setPuttingJob', false)
      })
    },
    /**
     * ジョブ1件削除
     * @param  {Number}  jobId  削除対象のジョブID
     */
    async deleteJob ({ commit }, jobId) {
      await axios.delete('/jobs/' + jobId + '/')
    },
    /**
     * ジョブ一括削除
     * @param {Array} jobIdList
     */
    async bulkDeleteJob ({ commit }, jobIdList) {
      commit('setBulkDeletingJob', true)
      const requestList = []
      for (const jobId of jobIdList) {
        requestList.push(
          axios.delete('/jobs/' + jobId + '/')
            .finally(() => {
              commit('setBulkDeletingJob', false)
            }),
        )
      }
      return Promise.all(requestList)
    },
    /**
     * IDで指定したジョブの即時実行
     * @param  {Number}  jobId  即時実行を行うジョブのID
     */
    async execJob ({ commit }, jobId) {
      await axios.post('/jobs/quickJobExecHistory/', {
        jobSettingId: jobId,
      })
    },
    updateOrderBy ({ commit }, newVal) {
      commit('setOrderBy', newVal)
    },
    updateIsDesc ({ commit }, newVal) {
      commit('setIsDesc', newVal)
    },
    updateFilter ({ commit }, newVal) {
      commit('setFilter', newVal)
    },
    updateEnabledFilter ({ commit }, newVal) {
      commit('setEnabledFilter', newVal)
    },
  },
}
export default jobs
