import axios from '@/axios'

const userInfo = {
  namespaced: true,
  state: {
    changingPassword: false,
  },
  mutations: {
    setChangingPassword (state, newVal) {
      state.changingPassword = newVal
    },
  },
  actions: {
    /**
     * パスワードを変更する
     * @param {any} commit
     * @param {any} params
     * @returns {any} promiseオブジェクト
     */
    changePassword ({ commit }, params) {
      commit('setChangingPassword', true)
      return axios.put('/account/user/password/', {
        currentPassword: params.currentPassword,
        newPassword: params.newPassword,
        newPasswordConfirm: params.newPasswordConfirm,
      }).finally(() => {
        commit('setChangingPassword', false)
      })
    },
  },
}

export default userInfo
