<script>
import { Line, mixins } from 'vue-chartjs'
// data, option
// @see https://github.com/apertureless/vue-chartjs
// @see https://www.chartjs.org/
export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default () {
        return {
          labels: [],
          datasets: [
            {
              label: 'Loading',
              data: [],
            },
          ],
        }
      },
    },
    customizeOptions: {
      type: Object,
      default () {
        return {
        }
      },
    },
  },
  data () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              suggestedMin: 0,
            },
          }],
        },
        legend: {
          onClick: function (event, item) {
            // クリックした時にグラフが消えるのを無効化
          },
        },
      },
    }
  },
  mounted () {
    this.renderChart(this.chartData, Object.assign(this.options, this.customizeOptions))
  },
}
</script>
