<template>
  <div>
    <h3 class="lead-line">ジョブ名</h3>
    <v-card class="my-3" outlined tile>
      <v-row>
        <v-col cols="8">
          <v-card-text class="pb-0">
            <v-text-field
              v-model="jobForm.jobName"
              class="x-large"
              name="jobName"
              placeholder="ジョブ名を入力してください"
              :data-vv-as="$t(`form.jobSetting.job.name`)"
              v-validate="'required|max:200'"
              :error-messages="errors.collect('jobName')" />
            <v-textarea
              v-model="jobForm.note"
              :label="$t(`form.note`)"
              outlined
              rows="3"
              name="note"
              v-validate="'max:1000'"
              :data-vv-as="$t(`form.note`)"
              :error-messages="errors.collect('note')" />
          </v-card-text>
        </v-col>
        <v-col cols="4" v-if="buttonType === 'PUT'">
          <p class="my-5">
            <span class="d-inline-block job_info_column">作成者</span>&#58;&nbsp;{{ formInputJob.createdUserName }}<br>
            <span class="d-inline-block job_info_column">作成日時</span>&#58;&nbsp;{{ formInputJob.createdAt }}
          </p>
          <p>
            <span class="d-inline-block job_info_column">更新者</span>&#58;&nbsp;{{ formInputJob.updatedUserName }}<br>
            <span class="d-inline-block job_info_column">更新日時</span>&#58;&nbsp;{{ formInputJob.updatedAt }}<br>
          </p>
        </v-col>
      </v-row>
    </v-card>

    <h3 class="mt-7 lead-line">スケジュール設定</h3>
    <v-card class="my-3" outlined tile height="312px">
      <v-card-text>
        <v-row>
          <!-- 実行種別 -->
          <v-col cols="12" md="4">
            <v-radio-group
              class="mt-0"
              v-model.number="jobForm.recurringType"
              mandatory
              name="recurringType"
              :data-vv-as="$t(`form.jobSetting.job.schedule`)"
              v-validate="'required'"
              :error-messages="errors.collect('recurringType')"
            >
              <v-row>
                <v-col class="py-0">
                  <v-radio
                    class="mt-3"
                    color="inputSelectionControl"
                    :label="JOB_RECURRING_TYPES.UNSCHEDULED.text"
                    :value="JOB_RECURRING_TYPES.UNSCHEDULED.value"
                  />
                </v-col>
                <v-col class="py-0">
                  <v-radio
                    class="mt-3"
                    color="inputSelectionControl"
                    :label="JOB_RECURRING_TYPES.DAILY.text"
                    :value="JOB_RECURRING_TYPES.DAILY.value"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-radio
                    class="mt-3"
                    color="inputSelectionControl"
                    :label="JOB_RECURRING_TYPES.ONETIME.text"
                    :value="JOB_RECURRING_TYPES.ONETIME.value"
                  />
                </v-col>
                <v-col class="py-0">
                  <v-radio
                    class="mt-3"
                    color="inputSelectionControl"
                    :label="JOB_RECURRING_TYPES.WEEKLY.text"
                    :value="JOB_RECURRING_TYPES.WEEKLY.value"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-radio
                    class="mt-3"
                    color="inputSelectionControl"
                    :label="JOB_RECURRING_TYPES.HOURLY.text"
                    :value="JOB_RECURRING_TYPES.HOURLY.value"
                  />
                </v-col>
                <v-col class="py-0">
                  <v-radio
                    class="mt-3"
                    color="inputSelectionControl"
                    :label="JOB_RECURRING_TYPES.MONTHLY.text"
                    :value="JOB_RECURRING_TYPES.MONTHLY.value"
                  />
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="8" class="pt-4">
            <!-- 実行スケジュール 追加入力：カレンダー -->
            <div
              v-show="jobForm.recurringType === JOB_RECURRING_TYPES.ONETIME.value"
            >
              <b>日付を選択してください</b>
              <v-row>
                <v-col cols="4" class="pt-2">
                  <v-dialog
                    ref="menu"
                    v-model="showDatePicker"
                    :return-value.sync="formDate"
                    width="252px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="formDate"
                        placeholder="YYYY-MM-DD"
                        persistent-placeholder
                        append-icon="ic-calendar-S"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        name="date"
                        :data-vv-as="$t(`form.date`)"
                        v-validate="{ 'required': jobForm.recurringType === JOB_RECURRING_TYPES.ONETIME.value}"
                        :error-messages="errors.collect('date')"
                      />
                    </template>
                    <v-date-picker
                      v-model="formDate"
                      locale="ja"
                      :day-format="date => new Date(date).getDate()"
                      no-title
                      scrollable
                      width="auto"
                      :allowed-dates="afterToday"
                      @input="$refs.menu.save(formDate)"
                    />
                  </v-dialog>
                </v-col>
              </v-row>
            </div>
            <!-- 実行スケジュール 追加入力：曜日 -->
            <div
              v-show="jobForm.recurringType === JOB_RECURRING_TYPES.WEEKLY.value"
            >
              <b>曜日を選択してください</b>
              <v-row>
                <v-col class="pb-0">
                  <v-checkbox hide-details="false" label="全選択" :value="checkedAllDayOfWeek" @click="toggleWeek()" />
                </v-col>
              </v-row>
              <v-row class="mb-3">
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfWeekList"
                    hide-details
                    :label="DAY_OF_WEEK_LIST.SUN.text"
                    :value="DAY_OF_WEEK_LIST.SUN.value"
                    name="dayOfWeek"
                    :data-vv-as="$t(`form.dayOfWeek`)"
                    v-validate="{ 'required': jobForm.recurringType === JOB_RECURRING_TYPES.WEEKLY.value }"
                    :error-messages="errors.collect('dayOfWeek')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfWeekList"
                    hide-details
                    name="dayOfWeek"
                    :error-messages="errors.collect('dayOfWeek')"
                    :label="DAY_OF_WEEK_LIST.MON.text"
                    :value="DAY_OF_WEEK_LIST.MON.value"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfWeekList"
                    hide-details
                    name="dayOfWeek"
                    :error-messages="errors.collect('dayOfWeek')"
                    :label="DAY_OF_WEEK_LIST.TUE.text"
                    :value="DAY_OF_WEEK_LIST.TUE.value"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfWeekList"
                    hide-details
                    name="dayOfWeek"
                    :error-messages="errors.collect('dayOfWeek')"
                    :label="DAY_OF_WEEK_LIST.WED.text"
                    :value="DAY_OF_WEEK_LIST.WED.value"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfWeekList"
                    hide-details
                    name="dayOfWeek"
                    :error-messages="errors.collect('dayOfWeek')"
                    :label="DAY_OF_WEEK_LIST.THU.text"
                    :value="DAY_OF_WEEK_LIST.THU.value"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfWeekList"
                    hide-details
                    name="dayOfWeek"
                    :error-messages="errors.collect('dayOfWeek')"
                    :label="DAY_OF_WEEK_LIST.FRI.text"
                    :value="DAY_OF_WEEK_LIST.FRI.value"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfWeekList"
                    hide-details
                    name="dayOfWeek"
                    :error-messages="errors.collect('dayOfWeek')"
                    :label="DAY_OF_WEEK_LIST.SAT.text"
                    :value="DAY_OF_WEEK_LIST.SAT.value"
                  />
                </v-col>
              </v-row>
            </div>
            <!-- 実行スケジュール 追加入力：日にち -->
            <div
              v-show="jobForm.recurringType === JOB_RECURRING_TYPES.MONTHLY.value"
            >
              <b>日付を選択してください</b>
              <v-row>
                <v-col class="pb-0">
                  <v-checkbox hide-details="false" label="全選択" :value="checkedAllDayOfMonth" @click="toggleMonth()" />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_1.text"
                    :value="DAY_OF_MONTH_LIST.DAY_1.value"
                    name="dayOfMonth"
                    :data-vv-as="$t(`form.date`)"
                    v-validate="{ 'required': jobForm.recurringType === JOB_RECURRING_TYPES.MONTHLY.value }"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_2.text"
                    :value="DAY_OF_MONTH_LIST.DAY_2.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_3.text"
                    :value="DAY_OF_MONTH_LIST.DAY_3.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_4.text"
                    :value="DAY_OF_MONTH_LIST.DAY_4.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_5.text"
                    :value="DAY_OF_MONTH_LIST.DAY_5.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_6.text"
                    :value="DAY_OF_MONTH_LIST.DAY_6.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_7.text"
                    :value="DAY_OF_MONTH_LIST.DAY_7.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_8.text"
                    :value="DAY_OF_MONTH_LIST.DAY_8.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_9.text"
                    :value="DAY_OF_MONTH_LIST.DAY_9.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_10.text"
                    :value="DAY_OF_MONTH_LIST.DAY_10.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_11.text"
                    :value="DAY_OF_MONTH_LIST.DAY_11.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_12.text"
                    :value="DAY_OF_MONTH_LIST.DAY_12.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_13.text"
                    :value="DAY_OF_MONTH_LIST.DAY_13.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_14.text"
                    :value="DAY_OF_MONTH_LIST.DAY_14.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_15.text"
                    :value="DAY_OF_MONTH_LIST.DAY_15.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_16.text"
                    :value="DAY_OF_MONTH_LIST.DAY_16.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_17.text"
                    :value="DAY_OF_MONTH_LIST.DAY_17.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_18.text"
                    :value="DAY_OF_MONTH_LIST.DAY_18.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_19.text"
                    :value="DAY_OF_MONTH_LIST.DAY_19.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_20.text"
                    :value="DAY_OF_MONTH_LIST.DAY_20.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_21.text"
                    :value="DAY_OF_MONTH_LIST.DAY_21.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_22.text"
                    :value="DAY_OF_MONTH_LIST.DAY_22.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_23.text"
                    :value="DAY_OF_MONTH_LIST.DAY_23.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_24.text"
                    :value="DAY_OF_MONTH_LIST.DAY_24.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_25.text"
                    :value="DAY_OF_MONTH_LIST.DAY_25.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_26.text"
                    :value="DAY_OF_MONTH_LIST.DAY_26.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_27.text"
                    :value="DAY_OF_MONTH_LIST.DAY_27.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_28.text"
                    :value="DAY_OF_MONTH_LIST.DAY_28.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_29.text"
                    :value="DAY_OF_MONTH_LIST.DAY_29.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_30.text"
                    :value="DAY_OF_MONTH_LIST.DAY_30.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
              </v-row>
              <v-row class="mb-3">
                <v-col class="py-0" cols="1">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.DAY_31.text"
                    :value="DAY_OF_MONTH_LIST.DAY_31.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
                <v-col class="py-0" cols="2">
                  <v-checkbox
                    v-model="sortedDayOfMonthList"
                    hide-details="false"
                    :label="DAY_OF_MONTH_LIST.LAST_DAY.text"
                    :value="DAY_OF_MONTH_LIST.LAST_DAY.value"
                    name="dayOfMonth"
                    :error-messages="errors.collect('dayOfMonth')"
                  />
                </v-col>
              </v-row>
            </div>
            <!-- 実行スケジュール 追加入力：時刻 -->
            <div
              v-show="jobForm.recurringType === JOB_RECURRING_TYPES.ONETIME.value ||
              jobForm.recurringType === JOB_RECURRING_TYPES.DAILY.value ||
              jobForm.recurringType === JOB_RECURRING_TYPES.WEEKLY.value ||
              jobForm.recurringType === JOB_RECURRING_TYPES.MONTHLY.value"
            >
              <b>時刻を選択してください</b>
              <v-row class="pt-2">
                <v-col cols="2">
                  <v-select v-model="formHour" dense :items="enumUtil.convertForSelectList(HOUR_LIST)" :suffix="'時'" />
                </v-col>
                <v-col cols="2">
                  <!-- 00分を指定しないと起動しないため、暫定的な対応として00分しか指定できないようにしている -->
                  <v-select v-model="formMinutes" dense :items="'00'" :suffix="'分'" />
                </v-col>
                <v-col cols="2">
                  に実施
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// enum
import DAY_OF_MONTH_LIST from '@/enum/DAY_OF_MONTH_LIST'
import DAY_OF_WEEK_LIST from '@/enum/DAY_OF_WEEK_LIST'
import HOUR_LIST from '@/enum/HOUR_LIST'
import MINUTES_LIST from '@/enum/MINUTES_LIST'
import JOB_RECURRING_TYPES from '@/enum/JOB_RECURRING_TYPES'
// util
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'JobForms',
  mixins: [
    DAY_OF_MONTH_LIST,
    DAY_OF_WEEK_LIST,
    HOUR_LIST,
    MINUTES_LIST,
    JOB_RECURRING_TYPES,
    notifyUtil,
    enumUtil,
  ],
  props: {
    buttonType: {
      type: String,
    },
    formInputJob: {
      type: Object,
    },
    formDatetimeParameter: {
      type: Object,
    },
    dayOfMonthListCheckedVal: {
      typr: Array,
    },
    dayOfWeekListCheckedVal: {
      type: Array,
    },
  },
  data () {
    return {
      showDatePicker: false,

    }
  },
  computed: {
    jobForm () {
      return this.formInputJob
    },
    formDate: {
      get () {
        return this.formDatetimeParameter.date
      },
      set (newVal) {
        this.$emit('updateDate', newVal)
      },
    },
    sortedDayOfWeekList: {
      get () {
        return this.dayOfWeekListCheckedVal
      },
      set (valList) {
        // 日曜始まりの曜日順にソート
        const tmpList = []
        for (const key of Object.keys(this.DAY_OF_WEEK_LIST)) {
          for (const dayOfWeekCheckVal of valList) {
            if (JSON.stringify(this.DAY_OF_WEEK_LIST[key].value) === JSON.stringify(dayOfWeekCheckVal)) {
              tmpList.push(dayOfWeekCheckVal)
              break
            }
          }
          if (tmpList.length === valList.length) break
        }
        this.$emit('updateDayOfWeek', tmpList)
      },
    },
    sortedDayOfMonthList: {
      get () {
        return this.dayOfMonthListCheckedVal
      },
      set (valList) {
        // 日付順にソート
        const tmpList = []
        for (const key of Object.keys(this.DAY_OF_MONTH_LIST)) {
          for (const dayOfMonthCheckVal of valList) {
            if (JSON.stringify(this.DAY_OF_MONTH_LIST[key].value) === JSON.stringify(dayOfMonthCheckVal)) {
              tmpList.push(dayOfMonthCheckVal)
              break
            }
          }
          if (tmpList.length === valList.length) break
        }
        this.$emit('updateDayOfMonth', tmpList)
      },
    },
    // 曜日全選択チェックフラグ
    checkedAllDayOfWeek () {
      return this.sortedDayOfWeekList.length === Object.keys(this.DAY_OF_WEEK_LIST).length
    },
    // 日にち全選択チェックフラグ
    checkedAllDayOfMonth () {
      return this.sortedDayOfMonthList.length === Object.keys(this.DAY_OF_MONTH_LIST).length
    },
    formHour: {
      get () {
        return this.formDatetimeParameter.time.substring(0, 2)
      },
      set (newVal) {
        this.$emit('updateHour', newVal)
      },
    },
    formMinutes: {
      get () {
        return this.formDatetimeParameter.time.substring(3)
      },
      set (newVal) {
        this.$emit('updateMinutes', newVal)
      },
    },
  },
  methods: {
    /**
     * 実行曜日を全選択or全解除
     */
    toggleWeek () {
      this.$nextTick(() => {
        const weekCheckedValList = []
        if (!this.checkedAllDayOfWeek) {
          for (const key of Object.keys(this.DAY_OF_WEEK_LIST)) {
            weekCheckedValList.push(this.DAY_OF_WEEK_LIST[key].value)
          }
        }
        this.$emit('updateDayOfWeek', weekCheckedValList)
      })
    },
    /**
     * 実行日を全選択or全解除
     */
    toggleMonth () {
      this.$nextTick(() => {
        const monthCheckedValList = []
        if (!this.checkedAllDayOfMonth) {
          for (const key of Object.keys(this.DAY_OF_MONTH_LIST)) {
            monthCheckedValList.push(this.DAY_OF_MONTH_LIST[key].value)
          }
        }
        this.$emit('updateDayOfMonth', monthCheckedValList)
      })
    },
    /**
     * ジョブ設定の入力値チェック
     * @return {Promise}
     */
    async validateJobForms () {
      if (!await this.$validator.validateAll()) {
        for (const error of this.errors.items) {
          this.notifyErrorMessage(error.msg)
        }
        return false
      }
      return true
    },
    /**
     * 今日以降
     */
    afterToday (val) {
      let today = new Date()
      today = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
      )
      return today <= new Date(val)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/theme.scss';

.job_info_column {
  width: 5em;
}

@include theme(v-card) using ($integral-core-theme) {
  p {
    color: map-deep-get($integral-core-theme, 'views', 'jobSetting', 'jobName', 'paragraph');
  }
}

#app {
  @include theme(v-card) using ($integral-core-theme) {
    b {
      color: map-deep-get($integral-core-theme, 'views', 'jobSetting', 'header', 'bold');
    }
  }
}
</style>
