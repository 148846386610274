var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-5",attrs:{"outlined":"","tile":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.$router.options.routes,"hide-default-footer":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.requiredAuthority",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.meta || {}).requiredAuthority)+" ")]}},{key:"item.get",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.canGetThis(item.name))+" ")]}},{key:"item.post",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.canPostThis(item.name))+" ")]}},{key:"item.put",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.canPutThis(item.name))+" ")]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.canDeleteThis(item.name))+" ")]}},{key:"item.all",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.isAllAuthorizedThis(item.name))+" ")]}}])})],1)],1),_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-card-text',[_c('v-textarea',{attrs:{"label":"$store.state.auth.loginUserDetail.authority","value":_vm.formatJSON(_vm.$store.state.auth.loginUserDetail.authority),"rows":"8","readonly":""}}),_vm._v(" isAllAuthorizedThis datasetAdd: "+_vm._s(_vm.isAllAuthorizedThis('DataSetAdd'))+" "),_c('br'),_c('br'),_vm._v(" isAllAuthorizedThis segmentSettingEdit: "+_vm._s(_vm.isAllAuthorizedThis('SegmentSettingEdit'))+" "),_c('br'),_c('br'),_vm._v(" canGet datasetAdd: "+_vm._s(_vm.canGetThis('DataSetAdd'))+" "),_c('br'),_c('br'),_vm._v(" canGet segmentSettingEdit: "+_vm._s(_vm.canGetThis('SegmentSettingEdit'))+" "),_c('br'),_c('br'),_vm._v(" canPost datasetAdd: "+_vm._s(_vm.canPostThis('DataSetAdd'))+" "),_c('br'),_c('br'),_vm._v(" canPost segmentSettingEdit: "+_vm._s(_vm.canPostThis('SegmentSettingEdit'))+" ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }