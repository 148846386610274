import axios from '@/axios'

const segmentExportScript = {
  namespaced: true,
  state: {
    loadingSegmentExportScript: true,
  },
  mutations: {
    setLoadingSegmentExportScript (state, newval) {
      state.loadingSegmentExportScript = newval
    },
  },
  actions: {
    getSegmentExportScript ({ commit }, segmentExportScriptId) {
      commit('setLoadingSegmentExportScript', true)
      return axios.get('/segmentExportScript/' + segmentExportScriptId + '/')
        .finally(() => {
          commit('setLoadingSegmentExportScript', false)
        })
    },
    postSegmentExportScript ({ commit }, postParameter) {
      return axios.post('/segmentExportScript/', {
        segmentExportScriptName: postParameter.segmentExportScriptName,
        segmentId: postParameter.segmentId,
        segmentExportScheduleType: postParameter.segmentExportScheduleType,
        scheduleTime: postParameter.scheduleTime,
        script: postParameter.script,
        executable: postParameter.executable,
        note: postParameter.note,
      })
    },
    putSegmentExportScript ({ commit }, putParameter) {
      return axios.put('/segmentExportScript/' + putParameter.segmentExportScriptId + '/', {
        segmentExportScriptName: putParameter.segmentExportScriptName,
        segmentExportScheduleType: putParameter.segmentExportScheduleType,
        scheduleTime: putParameter.scheduleTime,
        script: putParameter.script,
        executable: putParameter.executable,
        note: putParameter.note,
      })
    },
    deleteSegmentExportScript ({ commit }, segmentExportScriptId) {
      return axios({ method: 'delete', url: '/segmentExportScript/' + segmentExportScriptId + '/' })
    },
    updateLoadingSegmentExportScript ({ commit }, newval) {
      commit('setLoadingSegmentExportScript', newval)
    },
  },
}
export default segmentExportScript
