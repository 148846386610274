<template>
  <v-row>
    <v-col class="mt-15 pb-0" cols="12">
      <!-- セグメント -->
      <v-autocomplete
        v-model="segment"
        :items="segments"
        :label="$t(`form.segment`)"
        name="inputSegment"
        :data-vv-as="$t(`form.segment`)"
        v-validate="'required'"
        :error-messages="errors.collect('inputSegment')"
        :loading="loadingSegment"
        return-object
        clearable />
      <segment-config-list style="height: 240px; overflow: auto;"
        :filterTextObject="filterTextObject"
        :buildType="segment.hasOwnProperty('buildType') ? segment.buildType : null" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
// components
import segmentConfigList from '@/components/public/Segment/segmentConfigList'
// enum
import INPUT_ETL_TYPES from '@/enum/jobTask/INPUT_ETL_TYPES'
import JSON_INPUT_ITEM_NAMES from '@/enum/jobTask/JSON_INPUT_ITEM_NAMES'
import SEGMENT_BUILD_TYPE from '@/enum/SEGMENT_BUILD_TYPE'
// utils
import enumUtil from '@/utils/enumUtil'
import momentUtil from '@/utils/momentUtil'
import notifyUtil from '@/utils/notifyUtil'
import segmentFilterUtil from '@/utils/segmentFilterUtil'
import segmentTexts from '@/utils/segmentTexts'

export default {
  components: {
    'segment-config-list': segmentConfigList,
  },
  props: ['etlType', 'inputForm'],
  mixins: [
    INPUT_ETL_TYPES,
    JSON_INPUT_ITEM_NAMES,
    SEGMENT_BUILD_TYPE,
    enumUtil,
    momentUtil,
    notifyUtil,
    segmentFilterUtil,
    segmentTexts,
  ],
  data () {
    return {
      segments: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['canGetThis']),
    segment: {
      get () {
        if (this.checkSettingProp()) {
          // セレクトボックス表示用テキスト構築
          const inputForm = this.inputForm.inputSegment
          inputForm.text = JSON.stringify(this.inputForm.inputSegment.segmentId) + ' ' + JSON.stringify(this.inputForm.inputSegment.segmentName).replace(/"/g, '')
          return inputForm
        }
        return {}
      },
      set (value) {
        this.updateValue('inputSegment', value)
      },
    },
    filterTextObject () {
      if (this.segment && this.segment.buildType === this.SEGMENT_BUILD_TYPE.STANDARD.value) {
        return this.createSegmentFilterText(JSON.parse(this.segment.filterRuleJson))
      }
      return {
        logicalOperatorType: '',
        filterTextList: [],
      }
    },
    loadingSegment () {
      return this.$store.state.segments.loadingSegment
    },
  },
  watch: {
    inputForm: {
      handler () {
        this.$emit('checkValidate')
      },
      deep: true,
    },
  },
  created () {
    if (!this.canGetThis('Segments')) return
    this.segments = []
    this.$store.dispatch('segments/getAllSegments', this.momentUtil.getCurrentDate())
      .then((res) => {
        for (const segment of res.data.data.segments) {
          this.segments.push({
            text: segment.segment.segmentId + ' ' + segment.segment.segmentName,
            segmentName: segment.segment.segmentName,
            segmentId: segment.segment.segmentId,
            userType: segment.segment.userType,
            buildType: segment.segment.buildType,
            filterRuleJson: segment.segment.filterRuleJson,
          })
        }
        return this.segments
      })
      .catch((err) => {
        this.handleErrorResponse(err)
      })
  },
  methods: {
    /**
     * セグメントのタスクか判定
     * @return {Boolean}
     */
    checkSettingProp () {
      return this.inputForm && this.inputForm.inputSegment
    },
    /**
     * セグメントインプットタスクの値をJSONに変換して更新
     * @param  {String} propertyName  更新対象のキー
     * @param  {String} propertyValue 更新対象の値
     */
    updateValue (propertyName, propertyValue) {
      if (!this.inputForm) return

      const json = Object.assign({}, this.inputForm)
      if (!json[this.JSON_INPUT_ITEM_NAMES.SEGMENT.value]) {
        this.$set(json, this.JSON_INPUT_ITEM_NAMES.SEGMENT.value, {})
      }
      Object.assign(json, { [propertyName]: propertyValue })
      this.$emit('update', json)
    },
  },
}
</script>
