export default {
  data () {
    return {
      notifySuccessMessage (successMessage) {
        this.$notify({
          type: 'info',
          text: successMessage,
        })
      },
      notifyWarnMessage (warnMessage) {
        this.$notify({
          type: 'warn',
          text: warnMessage,
        })
      },
      notifyErrorMessage (errorMessage) {
        this.$notify({
          type: 'error',
          text: errorMessage,
        })
      },
      handleErrorResponse (err) {
        const response = err.response
        if (!response) {
          if (process.env.VUE_APP_DEBUG) {
            // レスポンス起因のエラーじゃない可能性があるので出力
            throw (err)
          }
          return
        }

        // システムエラーコードが存在する場合にメッセージの後ろに付与する
        const concatSystemErrorCode = function (message, systemErrorCode) {
          if (!systemErrorCode) return message
          return `${message} (${systemErrorCode})`
        }

        const errors = response.data.errors
        const systemErrorCode = response.data.systemErrorCode
        if (errors && errors.length > 0) {
          // APIのエラーメッセージが存在する場合
          for (const error of errors) {
            this.notifyErrorMessage(concatSystemErrorCode(error.message, systemErrorCode))
          }
        } else {
          // APIのエラーメッセージが存在しない場合
          this.notifyErrorMessage(concatSystemErrorCode(this.$t(`http.error.${response.status}`), systemErrorCode))
        }
      },
    }
  },
}
