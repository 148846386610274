<template>
  <v-container fluid>
    <div class="pb-3 caution">セグメントを削除するには、以下のタスクを削除する必要があります</div>
    <div v-if="!loading || modSegmentJobs.length !== 0">
      <div v-if="modSegmentJobs.length === 0" class="text-center">関連するジョブはありません。</div>
      <div v-else>
        <v-progress-linear v-if="loading" indeterminate color="primary"/>
        <v-card
          v-for="job in modSegmentJobs"
          class="mb-2"
          outlined
          tile
          :key="job.id"
          :disabled="loading">
          <v-card-text class="header d-flex align-content-center flex-wrap">
            <v-row class="align-center">
              <v-col cols="1">
                <v-chip v-if="job.lastExecStatus === JOB_EXEC_STATUS.WAIT.value" color="pending" :to="{ name: 'JobHistory', query: { id: job.id }}">
                  {{ enumUtil.getEnumFromValue(JOB_EXEC_STATUS, job.lastExecStatus).text }}
                </v-chip>
                <v-chip v-else-if="job.lastExecStatus === JOB_EXEC_STATUS.EXECUTING.value" color="ongoing" :to="{ name: 'JobHistory', query: { id: job.id }}">
                  {{ enumUtil.getEnumFromValue(JOB_EXEC_STATUS, job.lastExecStatus).text }}
                </v-chip>
                <v-chip v-else-if="job.lastExecStatus === JOB_EXEC_STATUS.SUCCESS.value" color="success" :to="{ name: 'JobHistory', query: { id: job.id }}">
                  {{ enumUtil.getEnumFromValue(JOB_EXEC_STATUS, job.lastExecStatus).text }}
                </v-chip>
                <v-chip v-else-if="job.lastExecStatus === JOB_EXEC_STATUS.FAIL.value" color="fault" :to="{ name: 'JobHistory', query: { id: job.id }}">
                  {{ enumUtil.getEnumFromValue(JOB_EXEC_STATUS, job.lastExecStatus).text }}
                </v-chip>
                <v-chip v-else-if="!job.lastExecStatus" color="pending">
                  未実行
                </v-chip>
              </v-col>
              <v-col>
                <span class="mr-1">スケジュール&#58;&nbsp;</span>
                <span v-if="job.recurringType === JOB_RECURRING_TYPES.ONETIME.value">
                  {{ enumUtil.getEnumFromValue(JOB_RECURRING_TYPES, job.recurringType).text }}&nbsp;{{ job.executionTime | hideTimeSecond }}
                </span>
                <span v-else-if="job.recurringType === JOB_RECURRING_TYPES.DAILY.value">
                  {{ enumUtil.getEnumFromValue(JOB_RECURRING_TYPES, job.recurringType).text }}&nbsp;{{ job.executionTime | showTimeOnly | hideSeconds }}
                </span>
                <v-tooltip v-else-if="job.recurringType === JOB_RECURRING_TYPES.WEEKLY.value" bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      {{ enumUtil.getEnumFromValue(JOB_RECURRING_TYPES, job.recurringType).text }}&nbsp;{{ job.executionTime | showTimeOnly | hideSeconds }}
                      <v-icon>ic-info</v-icon>
                    </span>
                  </template>
                  <span class="body-1">
                    {{ getWeeklyScheduleStr(job.jobSettingWeekly) }}
                  </span>
                </v-tooltip>
                <v-tooltip v-else-if="job.recurringType === JOB_RECURRING_TYPES.MONTHLY.value" bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      {{ enumUtil.getEnumFromValue(JOB_RECURRING_TYPES, job.recurringType).text }}&nbsp;{{ job.executionTime | showTimeOnly | hideSeconds }}
                      <v-icon>ic-info</v-icon>
                    </span>
                  </template>
                  <span class="body-1">
                    {{ getMonthlyScheduleStr(job.jobSettingMonthly) }}
                  </span>
                </v-tooltip>
                <span v-else>
                  {{ enumUtil.getEnumFromValue(JOB_RECURRING_TYPES, job.recurringType).text }}
                </span>
              </v-col>
              <v-spacer/>
              <v-col cols="6" class="d-flex align-center">
                最終ジョブ終了日時&#58;&nbsp;{{ job.lastExecDatetime }}
                <v-btn
                  v-show="job.lastExecStatus"
                  small
                  class="ml-5"
                  :to="{ name: 'JobHistory', query: { id: job.id }}">
                  実行履歴
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="main pa-3">
            <router-link :to="{ name:'JobSettingEdit', params:{ jobId: job.id } }" style="text-decoration: none; color: inherit;">
              <h3 class="name">{{ job.jobName }}</h3>
              <v-data-table
                disable-filtering
                disable-pagination
                disable-sort
                hide-default-footer
                :headers="jobTaskHeader"
                :items="job.jobTasks"
              >
                <template #item.jobTaskName="{ item }">
                  <span :class="{ 'undelete-task': item.inputEtlType !== INPUT_ETL_TYPES.SEGMENT.text}">
                    ・{{ item.jobTaskName }}
                  </span>
                </template>
                <template #item.jobTaskType="{ item }">
                  <span :class="{ 'undelete-task': item.inputEtlType !== INPUT_ETL_TYPES.SEGMENT.text}">
                    {{ enumUtil.getEnumFromValue(JOB_TASK_TYPES, item.jobTaskType).text }}
                  </span>
                </template>
                <template #item.inputType="{ item }">
                  <span v-if="item.jobTaskType === JOB_TASK_TYPES.IO.value" :class="{ 'undelete-task': item.inputEtlType !== INPUT_ETL_TYPES.SEGMENT.text}">
                    {{ item.inputEtlType }}
                  </span>
                  <span v-else :class="{ 'undelete-task': item.inputEtlType !== INPUT_ETL_TYPES.SEGMENT.text}">
                    &#045;
                  </span>
                </template>
                <template #item.outputType="{ item }">
                  <span v-if="item.jobTaskType === JOB_TASK_TYPES.IO.value" :class="{ 'undelete-task': item.inputEtlType !== INPUT_ETL_TYPES.SEGMENT.text}">
                    {{ item.outputEtlType }}
                  </span>
                  <span v-else>
                    &#045;
                  </span>
                </template>
              </v-data-table>
            </router-link>
          </v-card-text>
        </v-card>
        <v-pagination :value="page" @input="changePage($event)" :length="pages" total-visible="7" :disabled="loading" />
      </div>
    </div>
    <v-row v-else :justify="'center'" class="ma-3">
      <v-progress-circular indeterminate color="primary" />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
// enum
import JOB_EXEC_STATUS from '@/enum/JOB_EXEC_STATUS'
import JOB_RECURRING_TYPES from '@/enum/JOB_RECURRING_TYPES'
import JOB_TASK_TYPES from '@/enum/jobTask/JOB_TASK_TYPES'
import INPUT_ETL_TYPES from '@/enum/jobTask/INPUT_ETL_TYPES'
// util
import notifyUtil from '@/utils/notifyUtil'
import enumUtil from '@/utils/enumUtil'
import scheduleStrUtil from '@/utils/scheduleStrUtil'

const DEFAULT_PAGE = 1
const DEFAULT_LIMIT = 10

export default {
  props: {
    segmentId: {
      type: Number,
      default: 0,
    },
  },
  mixins: [
    JOB_EXEC_STATUS,
    JOB_RECURRING_TYPES,
    JOB_TASK_TYPES,
    INPUT_ETL_TYPES,
    notifyUtil,
    enumUtil,
    scheduleStrUtil,
  ],
  data () {
    return {
      jobTaskHeader: [
        {
          text: 'タスク名',
          value: 'jobTaskName',
          width: '50%',
          sotrable: false,
        },
        {
          text: 'タスク種別',
          value: 'jobTaskType',
          width: '10%',
          sotrable: false,
        },
        {
          text: 'インプット種別',
          value: 'inputType',
          width: '20%',
          sotrable: false,
        },
        {
          text: 'アウトプット種別',
          value: 'outputType',
          width: '20%',
          sotrable: false,
        },
      ],
      page: DEFAULT_PAGE,
      loading: false,
      segmentJobs: [],
      jobPaging: {},
    }
  },
  computed: {
    ...mapGetters('auth', ['canDelete', 'canGetThis']),
    modSegmentJobs () {
      const tmpJobs = this.segmentJobs

      for (const job of tmpJobs) {
        if (job.recurringType === this.JOB_RECURRING_TYPES.WEEKLY.value) {
          job.jobScheduleStr = this.getWeeklyScheduleStr(job.jobSettingWeekly)
        } else if (job.recurringType === this.JOB_RECURRING_TYPES.MONTHLY.value) {
          job.jobScheduleStr = this.getMonthlyScheduleStr(job.jobSettingMonthly)
        }
        if (job.recurringType >= 1 && job.recurringType <= 5) {
          job.recurringTypeStr = this.enumUtil.getEnumFromValue(this.JOB_RECURRING_TYPES, job.recurringType).text
        }
        if (job.lastExecStatus >= 1 && job.lastExecStatus <= 4) {
          job.lastExecStatusStr = this.enumUtil.getEnumFromValue(this.JOB_EXEC_STATUS, job.lastExecStatus).text
        } else {
          job.lastExecStatusStr = ''
        }
        for (const task of job.jobTasks) {
          if (task.jobTaskType === this.JOB_TASK_TYPES.SQL.value) {
            continue
          }
          const json = JSON.parse(task.jobTaskReference)
          task.inputEtlType = json.input.etlType
          if (task.inputEtlType === this.INPUT_ETL_TYPES.SEGMENT.value) {
            task.segmentId = String(json.input.inputSegment.segmentId)
          }
          task.outputEtlType = json.output.etlType
        }
      }
      return tmpJobs
    },
    pages () {
      return Math.ceil(this.jobPaging.filteredTotalCount / this.jobPaging.limit) || 0
    },
  },
  watch: {
    $route: {
      handler () {
        this.getSegmentJobs()
      },
    },
  },
  created () {
    this.getSegmentJobs()
  },
  methods: {
    /**
     * セグメントに紐付くジョブ一覧リスト取得
     */
    getSegmentJobs () {
      if (this.canGetThis('Jobs')) {
        this.loading = true
        this.$store.dispatch('segmentJobs/getSegmentsJobs', {
          segmentId: String(this.segmentId),
          page: this.page,
          limit: DEFAULT_LIMIT,
        })
          .then((res) => {
            this.segmentJobs = res.jobs
            this.jobPaging = res.paging
          })
          .catch((err) => {
            this.handleErrorResponse(err)
          }).finally(() => {
            this.loading = false
          })
      }
    },
    changePage (newVal) {
      this.page = newVal
      this.getSegmentJobs()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-card) using ($integral-core-theme) {
  .header {
    background-color: map-deep-get($integral-core-theme, 'common', 'large-list', 'header-background');
    color: map-deep-get($integral-core-theme, 'common', 'large-list', 'header-text');
  }
  .main {
    color: map-deep-get($integral-core-theme, 'common', 'large-list', 'main');
    .name {
      color: map-deep-get($integral-core-theme, 'common', 'large-list', 'name');
    }
    &:hover {
      background-color: map-deep-get($integral-core-theme, 'common', 'large-list', 'background-hover');
    }
  }
}

.caution {
  line-height: 16px;
}
.header {
  font-size: 14px;
  line-height: 20px;
  min-height: 36px;
  padding: 0 8px;
}
.main {
  padding: 8px 8px;
  .name {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
  }
  .v-data-table {
    margin-top: 2px;
    background-color: transparent;
    &::v-deep tr {
      pointer-events: none;
      th, td {
        padding-left: 0 !important;
        font-size: 12px;
        height: 22px;
      }
      th {
        height: 28px;
      }
      td {
        border-bottom: none !important;
      }
    }
  }
}
.ci-title {
  font-size: 12px;
}
.undelete-task {
  color: #a2a1a1;
}
/* 枠からはみ出るので横幅1400px以下のときはフォント縮小 */
@media only screen and (max-width: 1400px) {
  .ci_title {
    font-size: 10px
  }
}
</style>
