<template>
  <totp-login-form />
</template>

<script>
import totpLoginForm from '@/components/Login/totpLoginForm'

export default {
  name: 'TotpLogin',
  components: {
    totpLoginForm,
  },
  created () {
    this.$store.dispatch('auth/getLoginUser')
      .then(() => {
        if (this.$store.state.auth.loginUserDetail.mfaRequired && !this.$store.state.auth.loginUserDetail.mfaDeviceRegistered) {
          // MFA 必須でデバイス未登録なのでデバイス登録画面 (QRコード表示) へ
          this.$router.push({ name: 'TotpRegister' })
        } else if (this.$store.state.auth.loginUserDetail.mfaAuthenticated && !this.$store.state.auth.loginUserDetail.passwordChangedFlg) {
          // MFA 認証済みでパスワード変更が必要なのでパスワード変更画面へ
          this.$router.push({ name: 'PasswordChange' })
        } else if (this.$store.state.auth.loginUserDetail.mfaAuthenticated && this.$store.state.auth.loginUserDetail.loginUserId > 0) {
          // 既にログイン済みだったらログイン済み画面へ遷移させる
          this.$router.push({ name: 'Release' })
        }
      })
      .catch(() => {
        // ID/PASS 認証が通ってなかったらログイン画面へ遷移させる
        this.$router.push({ name: 'Login' })
      })
  },
}
</script>
