import axios from '@/axios'

const measureSettings = {
  namespaced: true,
  actions: {
    async getMeasureSetting () {
      return axios.get('/measures/setting/')
        .then((res) => {
          return Promise.resolve({
            measureSettings: res.data.data.measureSettingDtoList,
          })
        })
    },
  },
}
export default measureSettings
