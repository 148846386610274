import axios from '@/axios'
import checkValueUtil from '@/utils/checkValueUtil'

const dataSets = {
  namespaced: true,
  state: {
    loadingDataSets: false,
    sortParameter: {
      orderBy: 'createdAt',
      isDesc: true,
    },
  },
  mutations: {
    setIsDesc (state, newVal) {
      state.sortParameter.isDesc = newVal
    },
    setOrderBy (state, newVal) {
      state.sortParameter.orderBy = newVal
    },
    setLoadingDataSets (state, newVal) {
      state.loadingDataSets = newVal
    },
  },
  getters: {
    getQueryParams (state) {
      const params = Object.assign({}, state.sortParameter)
      for (const key in params) {
        if (checkValueUtil.isNullOrEmpty(params[key])) delete params[key]
      }
      return params
    },
  },
  actions: {
    async getDataSets ({ commit, state }) {
      commit('setLoadingDataSets', true)
      let queryOrderBy = ''
      if (state.sortParameter.orderBy) {
        // キャメルからスネークに変換
        queryOrderBy = state.sortParameter.orderBy.replace(/([A-Z])/g, (str) => { return '_' + str.charAt(0).toLowerCase() })
      }

      const res = await axios.get('/dataSet/', {
        params: {
          orderBy: queryOrderBy,
          isDesc: state.sortParameter.isDesc,
          offset: 0,
          limit: 5000,
        },
      }).finally(() => {
        commit('setLoadingDataSets', false)
      })
      return res.data.data.dataSets
    },
    updateOrderBy ({ commit }, newVal) {
      commit('setOrderBy', newVal)
    },
    updateIsDesc ({ commit }, newVal) {
      commit('setIsDesc', newVal)
    },
  },
}
export default dataSets
