<template>
  <v-card tile elevation="0">
    <information-bar showTotalUser />
    <v-card-text>
      <filter-form
        @search="changeQuery('enabledFilter', isFiltering)"
        @reset="changeQuery('filters', '[]')"
        @switchFilter="val => isFiltering = val"
        :isFiltering="isFiltering"
        :total="paging.totalCount"
        :filteredTotal="paging.filteredTotalCount"
      >
        <v-btn class="mr-6" @click="filterDialog = true" :disabled="!isFiltering">{{$t('text.scvs.filtering.set')}}</v-btn>
        <v-chip class="mr-3" v-for="(filter, index) in filterParameter.filters" :key="index" :disabled="!isFiltering">
          {{ humarizeFilter(filter) }}
        </v-chip>
      </filter-form>
      <!-- paging/sort/limit -->
      <v-row justify="space-between" class="my-0">
        <v-col cols="4">
          <v-select
            v-model="orderBy"
            class="sort pt-0"
            :disabled="loadingScvs"
            hide-details
            :items="scvColumns"
            item-text="text"
            item-value="scvPhysicalColumnName"
          >
            <template #prepend>
              <span class="prepend-sort-text">
                {{ $t('text.sort') }}
              </span>
            </template>
            <template #append-outer>
              <v-btn icon @click="isDesc = !isDesc" :disabled="loadingScvs">
                <v-icon v-if="isDesc">ic-sort-desc</v-icon>
                <v-icon v-else>ic-sort-asc</v-icon>
              </v-btn>
            </template>
          </v-select>
        </v-col>
        <v-col cols="4">
          <v-pagination :value="filterParameter.page" @input="changeQuery('page', $event)" :length="pages" total-visible="7" :disabled="loadingScvs" />
        </v-col>
        <v-col class="text-right columnDisplaySetting" cols="2">
          <!-- カラム順番・ON/OFF -->
          <v-btn small @click="openDrawer = true">
            {{ $t('text.scvs.columnDisplaySetting') }}
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="limit"
            class="limits pt-0"
            :disabled="loadingScvs"
            :items="limits"
            hide-details
          >
            <template #prepend>
              <span class="prepend-sort-text">
                {{ $t('text.limits') }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-card v-if="activeColumnIds && isSearched" tile outlined>
        <v-card-text class="pa-2">
          <scvs-table
            :scvs="scvs"
            :scvColumns="filterdScvColumns"
            :unionCandidates.sync="unionCandidates"
            :loading="loadingScvs"
          />
        </v-card-text>
      </v-card>
      <div v-else>
        <span v-show="!loadingScvs">{{ $t(`text.scvs.default`) }}</span>
        <v-row v-show="loadingScvs" :justify="'center'">
          <v-progress-circular indeterminate color="primary" />
        </v-row>
      </div>
      <v-row align="center" justify="space-between" class="mt-0">
        <v-col offset="4" cols="4">
          <v-pagination :value="filterParameter.page" @input="changeQuery('page', $event)" :length="pages" total-visible="7" :disabled="loadingScvs" />
        </v-col>
      </v-row>
    </v-card-text>

    <!-- 統合画面への遷移ボタン -->
    <v-footer fixed v-if="unionCandidates.length !== 0">
      <v-spacer />
      <v-btn
        color="denial"
        @click="unionCandidates = []">
        {{ $t('btn.reset') }}
      </v-btn>
      <v-btn
        :to="'scvUnion?candidates=' + unionCandidates"
        :disabled="unionCandidates.length < 2 || !canPost"
        class="ma-5"
        color="primary"
        large>
        {{ $t('text.scvs.union.move') }} ({{unionCandidates.length}})
      </v-btn>
      <v-spacer />
    </v-footer>

    <!-- フィルタリング条件ダイアログ -->
    <v-dialog v-model="filterDialog" width="650">
      <!-- フィルタリング設定 -->
      <v-card>
        <v-card-title>{{$t('text.scvs.filtering.setting')}}</v-card-title>
        <v-card-text>
          <v-row class="filterLabel mx-0">
            <v-col cols="auto" class="pr-0">
              <div class="deleteDummy"/>
            </v-col>
            <v-col cols="5">{{$t('text.scvs.filtering.target')}}</v-col>
            <v-col cols="6">{{$t('text.scvs.filtering.filterValue')}}</v-col>
          </v-row>
          <v-card v-for="(form, index) in preparedFilters" :key="index" class="my-2" outlined tile>
            <v-card-text>
              <v-row align-self="center">
                <v-col class="py-0 pl-3 pr-0 text-right" cols="auto" align-self="start">
                  <!-- 削除ボタン -->
                  <v-btn
                    fab
                    depressed
                    x-small
                    color="denial"
                    @click.stop="onClickDeleteButton(index)">
                    <v-icon>ic-close-S</v-icon>
                  </v-btn>
                </v-col>
                <v-col class="py-0 pl-3 pr-0" cols="5" align-self="center">
                  <!-- 検索対象SCV項目 -->
                  <v-select
                    v-model="form.filterId"
                    class="mt-3"
                    dense
                    :items="scvColumns"
                    item-text="text"
                    item-value="value"
                    :placeholder="$t(`text.scvs.filtering.select`)"
                    :name="'scvId' + index"
                    :data-vv-as="$t(`text.scvs.filtering.target`)"
                    v-validate="'required'"
                    :error-messages="errors.collect('scvId' + index)" />
                </v-col>
                <v-col class="py-0 pl-3 pr-0" cols="6" align-self="center">
                  <!-- 検索条件値 : 空の場合は無視 -->
                  <!-- clear 時に値が null になるため @input で空文字に変換 https://github.com/vuetifyjs/vuetify/issues/4144 -->
                  <v-text-field
                    v-model="form.filterValue"
                    class="mt-3"
                    dense
                    :placeholder="$t(`text.scvs.filtering.filterValuePlaceholder`)"
                    clearable
                    @input="(val) => form.filterValue = val ? val : ''"
                    vaule=""
                    :name="'scvValue' + index"
                    :data-vv-as="$t(`text.scvs.filtering.filterValue`)"
                    :error-messages="errors.collect('scvValue' + index)" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-row class="addFilter">
            <v-col class="pb-0 text-right" cols="1">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn v-on="on" fab depressed @click="onClickAddFilterPrameterButton">
                    <v-icon>ic-add</v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t(`btn.scv.addFilter`) }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row align="center" justify="end">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon class="mr-3" v-on="on">ic-help-S</v-icon>
              </template>
              <span>
                {{ $t('text.scvs.info.search') }}
              </span>
            </v-tooltip>
            <v-btn class="mr-3" color="denial" @click="filterDialog = false">
              {{ $t(`btn.cancel`) }}
            </v-btn>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn class="mr-3" v-on="on" @click="resetPreparedFilters">
                  {{ $t(`btn.reset`) }}
                </v-btn>
              </template>
              <span>
                {{ $t(`tooltip.resetFilter`) }}
              </span>
            </v-tooltip>
            <v-btn class="mr-3" color="secondary" @click="onClickSetFilterButton">
              {{ $t(`btn.setting`) }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- カラム順番・ON/OFF ドロワー -->
    <column-display-setting
      :openDrawer="openDrawer"
      @switchDrawer="openDrawer = $event"
      :scvColumns="scvColumns"
      @sortScvColumns="scvColumns = $event"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// component
import filterForm from '@/components/common/filterForm'
import scvsTable from '@/components/Scvs/scvsTable'
import columnDisplaySetting from '@/components/common/columnDisplaySetting'
// util
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'

const DEFAULT_PAGE = 1
const DEFAULT_LIMIT = 50

export default {
  name: 'scvs',
  components: {
    filterForm,
    'scvs-table': scvsTable,
    'column-display-setting': columnDisplaySetting,
  },
  mixins: [
    enumUtil,
    notifyUtil,
  ],
  data () {
    return {
      paging: {},
      scvColumns: [],
      scvs: [],
      unionCandidates: [],
      openDrawer: false,
      preparedFilters: [],
      filterDialog: false,
      limits: [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
      ],
      isSearched: false,
      isFiltering: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['canPost']),
    ...mapGetters({ queryParams: 'scvs/getQueryParams' }),
    loadingScvs: {
      get () {
        return this.$store.state.scvs.loadingScvs
      },
      set (newVal) {
        this.$store.dispatch('scvs/updateLoadingScvs', newVal)
      },
    },
    activeColumnIds: {
      get () {
        return this.$store.state.scvs.activeColumnIds
      },
      set (newVal) {
        this.$store.dispatch('scvs/updateActiveColumnIds', newVal)
      },
    },
    filterdScvColumns () {
      return this.scvColumns.filter((column) => this.activeColumnIds.includes(column.value))
    },
    filterParameter () {
      return this.$store.state.scvs.filterParameter
    },
    orderBy: {
      get () {
        return this.$store.state.scvs.sortParameter.orderBy
      },
      set (newVal) {
        this.changeQuery('orderBy', newVal)
      },
    },
    isDesc: {
      get () {
        return this.$store.state.scvs.sortParameter.isDesc
      },
      set (newVal) {
        this.changeQuery('isDesc', newVal)
      },
    },
    enabledFilter () {
      return this.$store.state.scvs.enabledFilter
    },
    pages () {
      return Math.ceil(this.paging.filteredTotalCount / this.paging.limit) || 1
    },
    limit: {
      get () {
        return this.$store.state.scvs.filterParameter.limit
      },
      set (newVal) {
        this.changeQuery('limit', newVal)
      },
    },
  },
  watch: {
    scvColumns () {
      // draggable で scvColumns だけを変更すると v-list-item-group が変更を検知できずハイライトがずれてしまうので、
      // v-model に指定している activeColumnIds を無理やり変更して検知させる
      this.activeColumnIds = JSON.parse(JSON.stringify(this.activeColumnIds))
    },
  },
  created () {
    this.loadingScvs = true
    this.$store.dispatch('scvSettings/getValidScvSettings')
      .then((res) => {
        this.scvColumns = JSON.parse(JSON.stringify(res.scvSettings))
          .map((scvSetting) => {
            return { text: scvSetting.scvLogicalColumnName, value: scvSetting.scvColumnId, custom: scvSetting.isCustom, scvPhysicalColumnName: scvSetting.scvPhysicalColumnName }
          })
          .sort((a, b) => this.$store.state.scvs.sortedColumnIds?.indexOf(a.value) - this.$store.state.scvs.sortedColumnIds?.indexOf(b.value))

        if (this.activeColumnIds === null) {
          // 初期表示カラムの選択
          this.$store.dispatch('scvs/resetActiveColumnIds')
        }

        const tmpQuery = {}
        for (const key in this.$route.query) {
          tmpQuery[key] = this.$route.query[key]
        }

        // クエリが不十分な場合補う
        let isQuerySufficient = true
        for (const key in this.queryParams) {
          if (tmpQuery[key] === null || tmpQuery[key] === undefined) {
            tmpQuery[key] = this.queryParams[key]
            isQuerySufficient = false
          }
        }

        // orderByに指定された文字列がソート基準の選択肢にあるか、もしくはscv_idなら問題ない
        const validateOrderBy = this.scvColumns.some((scvColumn) => scvColumn.scvPhysicalColumnName === tmpQuery.orderBy)
        if (!validateOrderBy && tmpQuery.orderBy !== 'scv_id') {
          tmpQuery.orderBy = undefined
          isQuerySufficient = false
        }
        if (tmpQuery.isDesc !== true && tmpQuery.isDesc !== false && tmpQuery.isDesc !== 'true' && tmpQuery.isDesc !== 'false') {
          tmpQuery.isDesc = true
          isQuerySufficient = false
        }
        tmpQuery.page = Number(tmpQuery.page)
        if (!Number.isInteger(tmpQuery.page) || tmpQuery.page < 1) {
          tmpQuery.page = DEFAULT_PAGE
          isQuerySufficient = false
        }
        tmpQuery.limit = Number(tmpQuery.limit)
        if (!Number.isInteger(tmpQuery.limit) || tmpQuery.limit < 1 || tmpQuery.limit > 5000) {
          tmpQuery.limit = DEFAULT_LIMIT
          isQuerySufficient = false
        }

        // クエリを変更した場合はURLを置き換える
        if (!isQuerySufficient) {
          this.$router.replace({ query: tmpQuery })
            .catch(err => err) // 画面表示時の NavigationDuplicated 対策
          // 置き換わるとbeforeRouteUpdateが呼び出されるのでこちらの処理は中断する
          return
        }

        // クエリパラメーターに指定されている条件をStateに反映
        this.reflectQueryParamsToState(tmpQuery)
        this.isFiltering = this.enabledFilter
        this.preparedFilters = this.filterParameter.filters

        // SCV設定が設定済みの場合のみ初期表示用の検索を行う
        if (this.scvColumns[0]) {
          this.getScvs()
        } else {
          this.loadingScvs = false
        }
      })
  },
  beforeRouteUpdate (to, from, next) {
    // ルート変更に反応する
    if (to.query !== from.query) {
      this.reflectQueryParamsToState(to.query)
      this.preparedFilters = this.filterParameter.filters
      this.getScvs()
    }
    next()
  },
  methods: {
    onClickAddFilterPrameterButton () {
      // 有効なカラムの先頭をデフォルト選択
      const firstColumnId = this.scvColumns.length > 0 ? this.scvColumns[0].scvColumnId : null
      this.preparedFilters.push({ filterId: firstColumnId, filterValue: '' })
    },
    onClickDeleteButton (index) {
      this.preparedFilters.splice(index, 1)
    },
    changeQuery (type, value) {
      const queryParams = this.queryParams

      queryParams[type] = value
      queryParams.enabledFilter = this.isFiltering
      if (type !== 'page') {
        queryParams.page = DEFAULT_PAGE
      }

      // クエリパラメーター更新
      // クエリパラメーターを更新するとbeforeRouteUpdateが呼び出される
      this.$router.push({ query: queryParams })
        .catch(err => err) // 画面表示時の NavigationDuplicated 対策
    },
    /**
     * SCV一覧の取得
     */
    getScvs () {
      this.$store.dispatch('scvs/getScvs')
        .then((res) => {
          this.scvs = res.scvs
          this.paging = res.paging
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        }).finally(() => {
          this.isSearched = true
        })
    },
    reflectQueryParamsToState (query) {
      this.$store.dispatch('scvs/updateOrderBy', query.orderBy)
      this.$store.dispatch('scvs/updateIsDesc', JSON.parse(query.isDesc))
      this.$store.dispatch('scvs/updateFilter', JSON.parse(query.filters))
      this.$store.dispatch('scvs/updateEnabledFilter', JSON.parse(query.enabledFilter))
      this.$store.dispatch('scvs/updatePage', Number(query.page))
      this.$store.dispatch('scvs/updateLimit', Number(query.limit))
    },
    /**
     * ダイアログのフィルタリング実行
     */
    onClickSetFilterButton () {
      this.$validator.validateAll().then((result) => {
        // バリデーションエラーがあればリクエストは送信しない
        if (!result) return

        // 選択中のフィルタリング条件を反映
        this.changeQuery('filters', JSON.stringify(this.preparedFilters))

        this.filterDialog = false
      })
    },
    resetPreparedFilters () {
      this.preparedFilters = JSON.parse(JSON.stringify(this.filterParameter.filters))
    },
    humarizeFilter (filter) {
      const target = this.scvColumns.find(col => col.value === filter.filterId)
      if (!target) return ''
      return `${target.text}: ${filter.filterValue}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

#app .columnDisplaySetting {
  margin-bottom: 6px;
  margin-top: auto;
  .v-btn {
    text-indent: 0em;
    letter-spacing: 0em;
    padding: 0 7px !important;
  }
}
.v-card__text {
  .filterLabel {
    font-size: 12px;
    .col {
      padding-bottom: 10px;
      .deleteDummy {
        width: 20px;
      }
    }
    .col {
      &:last-child {
        margin-left: 5px;
      }
    }
  }
  .v-card {
    .v-card__text {
      .row {
        margin: -16px;
        height: 56px;
        .col {
          .v-input, .v-select {
            line-height: 19px;
            &::v-deep .v-input__control {
              .v-input__slot {
                margin-bottom: 9px;
                .v-select__slot {
                  .v-select__selections {
                    height: 32px;
                    .v-select__selection {
                      margin-bottom: 6px;
                      margin-top: auto;
                      line-height: 19px;
                    }
                  }
                  .v-input__append-inner {
                    padding-top: 4px;
                  }
                }
                .v-text-field__slot {
                  height: 32px;
                  input {
                    padding: 0;
                    margin-bottom: 6px;
                    margin-top: auto;
                    line-height: 19px;
                  }
                }
              }
              .v-text-field__details {
                height: 16px;
              }
            }
          }
          .v-btn {
            margin-top: 17px !important;
          }
        }
        .col {
          &:last-child {
            margin-left: 5px;
          }
        }
      }
    }
  }
  .row.addFilter {
    margin: -12px -3px;
  }
}
</style>
