import axios from '@/axios'
import router from '@/router'

const userScvDetail = {
  namespaced: true,
  state: {
    loadingUserScvDetail: false,
    loadingScvColumnSources: false,
  },
  mutations: {
    setLoadingUserScvDetail (state, newVal) {
      state.loadingUserScvDetail = newVal
    },
    setLoadingScvColumnSources (state, newVal) {
      state.loadingScvColumnSources = newVal
    },
  },
  actions: {
    async getUserScvDetail ({ commit, state }, scvId) {
      commit('setLoadingUserScvDetail', true)
      return await axios.get('/userDetail/scv/' + scvId + '/')
        .then((res) => {
          if (res.data.data.parentScvId && res.data.data.parentScvId !== scvId) {
            router.push({ name: 'UserScvDetail', params: { scvId: res.data.data.parentScvId } })
          }
          return Promise.resolve({
            userScvDetail: res.data.data,
          })
        })
        .catch((error) => {
          if (error.response?.status === 404) router.push({ name: 'PageNotFound' }, () => {})
        })
        .finally(() => {
          commit('setLoadingUserScvDetail', false)
        })
    },
    /**
     * stateに保存せず構成データセット一覧を取得
     */
    getCustomerFragments ({ commit, state }, scvId) {
      return axios.get('/userDetail/scv/' + scvId + '/fragments/')
    },
    /**
     * stateに保存せずSCV項目ごとの採択候補を取得
     */
    getScvColumnSources ({ commit, state }, scvId) {
      commit('setLoadingScvColumnSources', true)
      return axios.get('/userDetail/scv/' + scvId + '/scvColumnSource/')
        .finally(() => {
          commit('setLoadingScvColumnSources', false)
        })
    },
    /**
     * stateに保存せず子カスタマーの一覧を取得
     */
    getScvChildren ({ commit }, scvId) {
      return axios.get('/userDetail/scv/' + scvId + '/children/')
    },
    /**
     * stateに保存せずSCV統合設定の有無を取得
     */
    checkScvUnionSetting ({ commit }, scvId) {
      return axios.get('/customerUnion/customer/' + scvId + '/')
    },
    /**
     * SCV項目を更新
     */
    async overwriteScvColumn ({ commit }, params) {
      await axios.post('/userDetail/scv/' + params.scvId + '/scvColumn/' + params.scvColumnId, {
        scvColumnValue: params.newValue,
      }).catch((err) => {
        return Promise.reject(err)
      })
    },
    /**
     * SCV統合を解除
     */
    async unintegrate ({ commit }, scvId) {
      await axios.delete('/customerUnion/customer/' + scvId + '/')
    },
    updateLoadingUserScvDetail ({ commit }, newVal) {
      commit('setLoadingUserScvDetail', newVal)
    },
    updateLoadingScvColumnSources ({ commit }, newVal) {
      commit('setLoadingScvColumnSources', newVal)
    },
  },
}
export default userScvDetail
