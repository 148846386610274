<template>
  <v-container>
    <v-card outlined tile>
      <v-card-text class="pa-3">
        <v-data-table
          :headers="tableHeaders"
          :items="roles"
          hide-default-footer
          disable-pagination
          disable-sort
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Tables',
  data () {
    return {
      tableHeaders: [
        {
          text: 'ユーザー名',
          value: 'name',
          width: '20%',
          sortable: false,
        },
        {
          text: 'メールアドレス',
          value: 'mailAddress',
          sortable: false,
        },
        {
          text: '権限',
          value: 'role',
          sortable: false,
        },
        {
          text: '備考',
          value: 'note',
          sortable: false,
        },
      ],
      roles: [
        { name: '管理アカウント', mailAddress: 'admin@example.com', role: '管理者', note: '削除禁止' },
        { name: '一般アカウント', mailAddress: 'account@example.com', role: '一般', note: '' },
        { name: 'マーケター', mailAddress: 'marketing@example.com', role: '閲覧のみ', note: '' },
      ],
    }
  },
}
</script>
