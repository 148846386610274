<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Heading 1 (h1) - sidebar logo</h1>
        <h2>Heading 2 (h2) - App.vue page title</h2>
        <h3>Heading 3 (h3)</h3>
        <h3 class="lead-line">Heading 3 (h3.lead-line)</h3>
        <h4>Heading 4 (h4)</h4>
        <h5>Heading 5 (h5)</h5>
        <h6>Heading 6 (h6)</h6>
        <p>body (p)</p>
        <div>body (div)</div>
      </v-col>
      <v-col>
        <div class="text-subtitle-1">subtitle 1 (.text-subtitle-1)</div>
        <div class="text-subtitle-2">subtitle 2 (.text-subtitle-2)</div>
        <div class="text-body-1">body 1 (.text-body-1)</div>
        <div class="text-body-2">body 2 (.text-body-2)</div>
        <div class="text-button">button (.text-button)</div>
        <div class="text-caption">caption (.text-caption)</div>
        <div class="text-overline">overline (.text-overline)</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Typography',
}
</script>
