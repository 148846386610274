export default {
  btn: {
    add: '追加',
    create: '新規作成',
    update: '更新',
    delete: '削除',
    bulkDelete: 'まとめて削除する',
    settingUpdate: '設定編集',
    save: '保存',
    ok: 'ok',
    cancel: 'キャンセル',
    cancel_eng: 'Cancel',
    yes: 'はい',
    no: 'いいえ',
    back: '戻る',
    backToList: '一覧へ戻る',
    backToHistory: '履歴へ戻る',
    back_eng: 'Back',
    next: '次へ',
    next_eng: 'next',
    setting: '適用',
    reset: 'リセット',
    asc: '昇順',
    desc: '降順',
    filtering: 'フィルタリング設定',
    edit: '編集',
    copy: '複製',
    change: '変更',
    clipboardCopy: 'クリップボードにコピー',
    search: '検索',
    open: '開く',
    close: '閉じる',
    upload: 'アップロード',
    fileUpload: 'ファイルアップロード',
    select: '選択',
    finish: '完了',
    verify: '認証する',
    login: {
      login: 'ログイン',
    },
    passwordChange: {
      change: 'パスワードを変更',
    },
    jobs: {
      execRegistration: '今すぐ実行',
    },
    jobHistory: {
      backToList: 'ジョブ一覧に戻る',
    },
    site: {
      download: 'SDKダウンロード',
    },
    scvUnion: {
      save: '統合する',
    },
    segment: {
      query: '検索クエリ表示',
      search: 'ユーザー検索',
      create: 'セグメント作成',
      update: 'セグメント更新',
      clear: 'フィルタ条件クリア',
      download: 'Cookie&広告ID一覧DL',
    },
    segmentDetail: {
      back: 'セグメント一覧へ戻る',
    },
    segmentExportScriptHistory: {
      back: 'セグメントエクスポートスクリプト一覧に戻る',
      download: 'ダウンロード',
    },
    masters: {
      detail: '顧客マスタ詳細情報',
      linkSetting: '顧客マスタ連結設定',
      masterUpload: '顧客マスタアップロード',
      difUpload: '差分アップロード',
      replaceUpload: '差し替えアップロード',
      infer: 'スキーマ読み取り',
    },
    masterLinkSetting: {
      save: '設定を保存',
      execute: '保存&連結実行',
      regist: '連結ルール新規作成',
    },
    scv: {
      addFilter: '検索条件を追加',
      backToList: 'カスタマー一覧へ戻る',
    },
    scvSetting: {
      save: '編集中',
      create: 'SCV作成',
      identify: 'SCV識別子設定',
    },
    userDetail: {
      unintegrate: '解除',
    },
    dataSet: {
      create: 'データセット作成',
      delete: 'データセットを削除',
      linkSetting: '顧客マスタと連結',
      add: 'データセットのみを作成',
      next: '続いてデータソースを追加',
    },
    dataSource: {
      add: '追加する',
      delete: 'データソースを削除',
      finishSetting: '作成したデータソース詳細を開く',
      back: 'データセットに戻る',
      executeImporter: '取込処理を起動する',
    },
  },
}
