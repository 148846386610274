<template>
  <div>
    <v-progress-linear v-if="loading" indeterminate color="primary" />
    <v-card v-if="!loading && segmentsList.length === 0" outlined tile><v-card-text>{{$t('text.noData')}}</v-card-text></v-card>
    <large-list
      v-for="segment in segmentsList"
      :key="segment.segment.segmentId"
      :id="segment.segment.segmentId"
      :to="{ name: 'SegmentDetail', params: { segmentId: segment.segment.segmentId } }"
      :name="segment.segment.segmentName"
      :note="segment.segment.note"
      :showSelects="showSelects"
      :selectedList="selectedSegments"
      @checked="selected"
    >
      <template #header>
        <v-row class="header">
          <v-col cols="6">
            <v-tooltip right max-width="900">
              <template #activator="{ on, attrs }">
                <v-chip color="large-list" small
                  v-clipboard:copy="segment.segment.segmentId"
                  v-clipboard:success="copySuccess"
                  v-clipboard:error="copyError"
                  v-bind="attrs"
                  v-on="on"
                  @click.self.stop=""
                >
                  ID: {{segment.segment.segmentId}}
                </v-chip>
              </template>
              <span>ID をクリップボードにコピー</span>
            </v-tooltip>
          </v-col>
          <v-col cols="3">{{segment.segment.createdBy}}</v-col>
          <v-col cols="3">作成日時&#58;&nbsp;{{segment.segment.createdAt}}</v-col>
        </v-row>
      </template>
      <!-- 2列目のSlot -->
      <template #col2>
        <v-row>
          <v-col>
            <segment-user-circle-chart
              :size="60"
              :count="segment.totalUsers"
              :total="segment.segment.userType === USER_TYPES.SCV.value? customerTotalUser : unknownTotalUser" />
          </v-col>
          <v-col>
            <div class="total pb-3">{{segment.totalUsers}}<span>人</span></div>
            <div v-if="segment.lastWeekTotal !== undefined" class="change">
              <v-icon v-if="segment.totalUsers - segment.lastWeekTotal > 0" x-small class="blue" color="white">mdi-arrow-up-bold</v-icon>
              <v-icon v-else-if="segment.totalUsers - segment.lastWeekTotal < 0" x-small class="red" color="white">mdi-arrow-down-bold</v-icon>
              <v-icon v-else x-small class="grey" color="white">ic-add</v-icon>
              <span>{{ addComma(segment.totalUsers - segment.lastWeekTotal) }}</span>
              <v-icon v-if="segment.segment.userType === USER_TYPES.SCV.value">ic-customer-M</v-icon>
              <v-icon v-else>ic-anonymous-M</v-icon>
            </div>
            <div v-else>
              <v-skeleton-loader ref="skeleton" type="text" width="40"></v-skeleton-loader>
            </div>
          </v-col>
        </v-row>
      </template>
      <!-- 3列目のSlot -->
      <template #col3>
        <div v-if="segment.segment.buildType === SEGMENT_BUILD_TYPE.CUSTOM.value" class="pb-2">
          {{ $t(`form.jobSetting.job.schedule`) }}&#58;&nbsp;{{ enumUtil.getEnumFromValue(JOB_RECURRING_TYPES, segment.segment.customSegmentJobSetting.recurringType).text }}
        </div>
        <v-tooltip bottom max-width="900">
          <template #activator="{ on, attrs }">
            <v-btn outlined tile plain v-bind="attrs" v-on="on">
              <v-icon class="mr-1">ic-info</v-icon>
              <!-- TODO i18n -->
              条件を表示
            </v-btn>
          </template>
          <ul v-if="segment.segment.buildType === SEGMENT_BUILD_TYPE.CUSTOM.value">
            <li>{{ NO_RULE_CAUSED_BY_CUSTOM }}</li>
            <li v-if="segment.segment.customSegmentJobSetting.recurringType === JOB_RECURRING_TYPES.ONETIME.value">
              実行日時&#58;&nbsp;{{ segment.segment.customSegmentJobSetting.execDate }}&nbsp;{{ segment.segment.customSegmentJobSetting.execTime }}
            </li>
          </ul>
          <ul v-else-if="segment.filterTextObject.filterTextList.length === 0">
            <li>{{ NO_FILTER_RULES }}</li>
          </ul>
          <ul v-else>
            <li v-for="(filter, index) in segment.filterTextObject.filterTextList" :key="'filterText_' + String(index)">
              {{ filter.period }} {{ filter.internalFilter === '' ? '' : '/ ' + filter.internalFilter }} / {{ filter.filter }}
            </li>
          </ul>
        </v-tooltip>
      </template>
    </large-list>
  </div>
</template>

<script>
// component
import LargeList from '@/components/common/largeList'
import SegmentUserCircleChart from '@/components/public/Segment/segmentUserCircleChart'
// enum
import JOB_RECURRING_TYPES from '@/enum/JOB_RECURRING_TYPES'
import SEGMENT_BUILD_TYPE from '@/enum/SEGMENT_BUILD_TYPE'
import USER_TYPES from '@/enum/USER_TYPES'
// util
import displayConverter from '@/utils/displayConverter'
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'
import segmentTexts from '@/utils/segmentTexts'

export default {
  name: 'SegmentsList',
  components: {
    LargeList,
    SegmentUserCircleChart,
  },
  props: {
    segmentsList: {
      type: Array,
    },
    selectedSegments: {
      type: Array,
    },
    showSelects: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  mixins: [
    JOB_RECURRING_TYPES,
    SEGMENT_BUILD_TYPE,
    USER_TYPES,
    displayConverter,
    enumUtil,
    notifyUtil,
    segmentTexts,
  ],
  computed: {
    unknownTotalUser () {
      return this.$store.state.totalUser.totalUser.userUnknown.total
    },
    customerTotalUser () {
      return this.$store.state.totalUser.totalUser.userCustomer.total
    },
  },
  methods: {
    selected (value) {
      this.$emit('checked', value)
    },
    copySuccess () {
      this.notifySuccessMessage(this.$t('notify.success.copyToClipboard'))
    },
    copyError (e) {
      this.notifyErrorMessage(e)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/theme.scss';

@include theme(v-card) using ($integral-core-theme) {
  .main {
    .v-progress-circular {
      &::v-deep .v-progress-circular__info {
        color: map-deep-get($integral-core-theme, 'views', 'segments', 'segmentsList', 'progress-circular', 'text-info');
      }
    }
  }
}

.segmentName {
  flex-grow: 3;
}
.segmentFilterRule,
.segmentTotal,
.segmentDate {
  flex-grow: 1
}
.v-progress-circular {
  margin-top: 3px;
}
.header {
  font-size: 12px;
  .v-chip {
    font-size: 14px;
  }
}
.total {
  font-size: 16px;
  span {
    font-size: 12px;
  }
}
.change {
  width: 90px;
  span {
    padding-left: 4.22px;
    padding-right: 30px;
  }
}
</style>
