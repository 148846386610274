<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="9" align-self="start" class="text-left">
        <div class="caption pt-3 pb-1">
          <!-- TODO i18n -->
          {{ $t(`text.segmentSetting.result.user.title`) }}&nbsp;({{ $t(`text.segmentSetting.result.user.subHead`) }}サンプリング)
        </div>
      </v-col>
      <v-col cols="3" align-self="start" class="text-right pt-4">
        <!-- カラム順番・ON/OFF -->
        <v-btn
          small
          @click="openDrawer = !openDrawer"
        >
          {{ $t('text.scvs.columnDisplaySetting') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-show="!loadingScvs" class="mt-0">
      <v-col cols="12" class="pt-0">
        <v-card outlined>
          <v-data-table
            :headers="modTableHeaders"
            hide-default-footer
            :items="scvs"
            :loading="loadingScvs"
            class="px-3"
          >
            <template #body="{ items }">
              <tbody>
                <tr v-if="items.length === 0">
                  <td :colspan="modTableHeaders.length">{{$t('text.scvs.noResult')}}</td>
                </tr>
                <tr v-for="item in items" :key="item.scvId" @click="onClickTableRow(item.scvId)">
                  <td v-for="(scvColumn) in modTableHeaders" :key="scvColumn.scvColumnId">
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <span v-on="on">
                          {{ item.scvColumnValueList | getColumn(scvColumn.value) | ellipsis(20,'...') }}
                        </span>
                      </template>
                      <span class="break-all">
                        {{ item.scvColumnValueList | getColumn(scvColumn.value) }}
                      </span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-show="loadingScvs" :justify="'center'">
      <v-progress-circular indeterminate color="primary" />
    </v-row>
    <!-- セグメントユーザー詳細 -->
    <v-navigation-drawer
      v-model="showScvDetail"
      width="80%"
      app
      fixed
      hide-overlay
      temporary
      right
    >
      <information-bar>
        <template #left>
          <v-btn @click="showScvDetail = false">
            {{ $t(`btn.close`) }}
          </v-btn>
        </template>
        <template #right>
          <v-btn :to="{ name: 'UserScvDetail', params: { scvId: scvId } }">{{ $t('btn.open') }}</v-btn>
          <v-btn class="mx-5" fab x-small :to="{ name: 'UserScvDetail', params: { scvId: scvId } }" target="_blank"><v-icon>mdi-open-in-new</v-icon></v-btn>
        </template>
      </information-bar>

      <user-scv-detail
        v-if="scvId"
        :scvId="scvId" />
    </v-navigation-drawer>

    <!-- カラム順番・ON/OFF ドロワー -->
    <column-display-setting
      :openDrawer="openDrawer"
      @switchDrawer="openDrawer = $event"
      :scvColumns="scvColumns"
      @sortScvColumns="scvColumns = $event"
    />
  </v-container>
</template>

<script>
import userScvDetail from '@/views/userScvDetail'
// component
import columnDisplaySetting from '@/components/common/columnDisplaySetting'
// utils
import momentUtil from '@/utils/momentUtil'
import notifyUtil from '@/utils/notifyUtil'

const DEFAULT_PAGE = 1
const DEFAULT_LIMIT = 100

export default {
  components: {
    'user-scv-detail': userScvDetail,
    'column-display-setting': columnDisplaySetting,
  },
  props: {
    userList: {
      type: Array,
      default () {
        return []
      },
    },
  },
  mixins: [
    momentUtil,
    notifyUtil,
  ],
  data () {
    return {
      scvs: [],
      scvColumns: [],
      openDrawer: false,
      showScvDetail: false,
      params: {
        isDesc: true,
        orderBy: this.$store.state.scvs.sortParameter.orderBy,
        page: DEFAULT_PAGE,
        limit: DEFAULT_LIMIT,
        filters: JSON.stringify({ filterId: 1, filterValue: '' }),
        scvIds: [],
      },
      scvId: null,
    }
  },
  computed: {
    loadingScvs: {
      get () {
        return this.$store.state.scvs.loadingScvs
      },
      set (newVal) {
        this.$store.dispatch('scvs/updateLoadingScvs', newVal)
      },
    },
    activeColumnIds: {
      get () {
        return this.$store.state.scvs.activeColumnIds
      },
      set (newVal) {
        this.$store.dispatch('scvs/updateActiveColumnIds', newVal)
      },
    },
    filterdScvColumns () {
      // 所属セグメント数の表示領域 (0) またはアクティブなカラム
      return this.scvColumns.filter((column) => this.activeColumnIds === 0 || this.activeColumnIds.includes(column.value))
    },
    modTableHeaders () {
      const tmpTableHeaders = JSON.parse(JSON.stringify(this.filterdScvColumns))
      tmpTableHeaders.forEach(tableHeader => {
        tableHeader.width = '10%'
        tableHeader.sortable = false
      })
      return tmpTableHeaders
    },
  },
  watch: {
    scvColumns () {
      // draggable で scvColumns だけを変更すると v-list-item-group が変更を検知できずハイライトがずれてしまうので、
      // v-model に指定している activeColumnIds を無理やり変更して検知させる
      this.activeColumnIds = JSON.parse(JSON.stringify(this.activeColumnIds))
    },
  },
  created () {
    this.$store.dispatch('scvSettings/getValidScvSettings')
      .then((res) => {
        this.scvColumns = JSON.parse(JSON.stringify(res.scvSettings))
          .filter(scvSetting => scvSetting.scvColumnChoiceSettingList.length > 0)
          .map((scvSetting) => {
            return { text: scvSetting.scvLogicalColumnName, value: scvSetting.scvColumnId, custom: scvSetting.isCustom }
          })
          .sort((a, b) => this.$store.state.scvs.sortedColumnIds?.indexOf(a.value) - this.$store.state.scvs.sortedColumnIds?.indexOf(b.value))
        if (this.activeColumnIds === null) {
          // 初期表示カラムの選択
          this.$store.dispatch('scvs/resetActiveColumnIds')
        }

        this.getScvs()
      })
  },
  methods: {
    /**
     * SCV ID から SCV Valueリストの取得
     */
    getScvs () {
      this.params.scvIds = this.userList.map(user => user.userId).toString()
      this.params.filters = []
      this.$store.dispatch('scvs/getScvsByIds', this.params)
        .then((res) => {
          this.scvs = res.scvs
        })
    },
    onClickTableRow (scvId) {
      this.scvId = scvId
      this.showScvDetail = true
    },
  },
  filters: {
    getColumn (scvColumnValueList, scvColumnId) {
      const column = scvColumnValueList.find(scvColumn => scvColumn.scvColumnId === Number(scvColumnId))
      if (!column) return null
      return column.scvColumnValue
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.v-data-table {
  white-space : nowrap;
}
tr {
  cursor: pointer;
}
div.v-dialog__content {
  justify-content: flex-end !important;
}
</style>
