export default {
  data () {
    return {
      OS_TYPES: {
        IOS: { text: 'iOS', value: 1 },
        ANDROID: { text: 'Android', value: 2 },
        WINDOWS: { text: 'Windows', value: 3 },
        MAC: { text: 'Mac', value: 4 },
        LINUX: { text: 'Linux', value: 5 },
      },
    }
  },
}
