import Vue from 'vue'
import ja from 'vee-validate/dist/locale/ja'
import VeeValidate, { Validator } from 'vee-validate'
const MAX_KEYWORDS_BYTES = 255

/* 自動で発火されないようイベント発火のバリデーションを無効化 */
Vue.use(VeeValidate, {
  events: '',
})

/* 日本語にローカライズ */
Validator.localize('ja', ja)

/* ローカライズ後にデフォルトのエラーメッセージを上書き */
const dictionary = {
  ja: {
    messages: {
      min: (field, [length]) => `${length}文字以上入力してください`,
      required_if: field => field + 'は必須項目です',
    },
  },
}
Validator.localize(dictionary)

/* カスタムルール */
Validator.extend('domainSyntax', {
  getMessage: () => 'xxx.xxxの形式で入力してください',
  validate (value) {
    // TODO:ドメイン用の正規表現とバンドルID用の正規表現に切り分ける
    const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z0-9-.]*[a-zA-Z]{2,}$|^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]?\.[^/:*?"<>|]*$/
    return domainRegex.test(value)
  },
})
Validator.extend('emailSyntax', {
  getMessage: () => 'メールアドレスの形式が不正です',
  validate (value) {
    const emailRegexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
    return emailRegexp.test(value)
  },
})
Validator.extend('passwordSyntax', {
  getMessage: () => '英数字(a~z,A~Z,0~9)と記号を全て使用してください',
  validate (value) {
    const upperCaseRegex = /[A-Z]/
    const lowerCaseRegex = /[a-z]/
    const numberRegex = /[0-9]/
    const escapeSequencesRegex = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/
    return upperCaseRegex.test(value) && lowerCaseRegex.test(value) && numberRegex.test(value) && escapeSequencesRegex.test(value)
  },
})
Validator.extend('passwordASCIIOnlySyntax', {
  getMessage: () => '英数字(a~z,A~Z,0~9)と記号以外は使用しないでください',
  validate (value) {
    const ASCIIRegex = /^[\x21-\x7F]*$/
    return ASCIIRegex.test(value)
  },
})
Validator.extend('dataSourceSchemaPhysicalNameSyntax', {
  getMessage: () => '英数字（0 ~ 9、A ~ Z、a ~ z）、漢字、ひらがな、カタカナが使用可能です。記号は「_」のみ使用可能です。',
  validate (value) {
    const dataSourceSchemaPhysicalNameRegex = /^[a-zA-Z0-9_\p{Script_Extensions=Hiragana}\p{Script_Extensions=Katakana}\p{Script_Extensions=Han}]*$/u
    return dataSourceSchemaPhysicalNameRegex.test(value)
  },
})
Validator.extend('keywordSyntax', {
  getMessage: () => '入力内容が長すぎます',
  validate (value) {
    return encodeURIComponent(value).replace(/%../g, 'x').length <= MAX_KEYWORDS_BYTES
  },
})
Validator.extend('emptyFile', {
  getMessage: () => 'ファイルが空です',
  validate (name, value) {
    return value[0] !== '' && value[0] !== null && value[0] !== undefined
  },
}, { hasTarget: true })

export default new Validator()
