<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #right>
        <v-btn id="logoutButton" @click="logout()"><v-icon>ic-logout</v-icon></v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <password-form />
    </v-card-text>
  </v-card>
</template>

<script>
import passwordForm from '@/components/PasswordChange/passwordForm'

export default {
  components: {
    passwordForm,
  },
  methods: {
    /**
     * ログアウト処理
     */
    logout () {
      this.$store.dispatch('auth/logout')
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
#logoutButton {
  ::before {
    color: inherit;
  }
}
</style>
