<template>
  <div>
    <!-- v9.0.0 -->
    <h4 id="v9.0.0">v9.0.0 (2020/06/30~)</h4>
    <h5>全般</h5>
    <ul>
      <li>軽微な不具合を修正しました</li>
    </ul>
    <h5>SCV設定</h5>
    <ul>
      <li>いくつかの不具合を修正しました。</li>
    </ul>
    <h5>セグメント設定</h5>
    <ul>
      <li>いくつかの不具合を修正しました。</li>
    </ul>
    <h5>マスタ設定</h5>
    <ul>
      <li>いくつかの不具合を修正しました。</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'version9',
  minorVersions: [
    'v9.0.0',
  ],
}
</script>
