<template>
  <v-container>
    <v-img :src="require('@/assets/logo-white.svg')" width="330" class="mx-auto" contain />
    <h2 color="white" class="mt-5 text-center">認証コード入力</h2>
    <form @submit.prevent="verify()">
      <p>
        認証アプリに表示されている6桁のコードを入力してください。
      </p>
      <!-- TODO v-otp-input が使えるようになったら変更 -->
      <v-text-field
        v-model="requestParameter.oneTimePassword"
        :label="$t(`form.totp.oneTimePassword`)"
        :placeholder="$t(`form.totp.oneTimePasswordPlaceholder`)"
        class="mt-5"
        dark
        color="white"
        persistent-placeholder
        name="oneTimePassword"
        type="number"
        :data-vv-as="$t(`form.totp.oneTimePassword`)"
        v-validate="'required'"
        :error-messages="errors.collect('oneTimePassword')" />
      <v-btn type="submit" color="primary" class="mt-8" block :loading="totpVerifying">
        {{ $t(`btn.verify`) }}
      </v-btn>
      <p class="mt-5 text-center">
        <v-btn text small color="secondary" @click="logout">別のアカウントでログインする</v-btn>
      </p>
      <p v-if="loginUserDetail.mfaDeviceRegistered">
        <small>
          認証できない方は、管理者にデバイス登録の解除を依頼してください。
        </small>
      </p>
    </form>
  </v-container>
</template>

<script>

// util
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'TotpLoginForm',
  mixins: [notifyUtil],
  data () {
    return {
      requestParameter: {
        oneTimePassword: '',
      },
    }
  },
  computed: {
    loginUserDetail () {
      return this.$store.state.auth.loginUserDetail
    },
    totpVerifying () {
      return this.$store.state.auth.totpVerifying
    },
  },
  methods: {
    verify () {
      this.$validator.validateAll()
        .then((result) => {
          // バリデーションエラーがあればリクエストは送信しない
          if (!result) return
          this.$store.dispatch('auth/totpVerify', this.requestParameter)
            .catch((err) => {
              // ログイン失敗時にパスワードをクリア
              this.handleErrorResponse(err)
              this.requestParameter.oneTimePassword = ''
            })
        })
    },
    logout () {
      this.$store.dispatch('auth/logout')
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 350px;
  min-width: 350px;
  padding: 0;
  h2 {
    color: map-get($shades, 'white') !important;
  }
  form {
    color: map-get($gray, '_300') !important;
  }
}
</style>
