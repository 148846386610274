export default {
  data () {
    return {
      JOB_RECURRING_TYPES: {
        UNSCHEDULED: { text: '指定なし', value: 0 },
        ONETIME: { text: '指定日時', value: 1 },
        DAILY: { text: '毎日', value: 2 },
        HOURLY: { text: '毎時', value: 3 },
        WEEKLY: { text: '毎週', value: 4 },
        MONTHLY: { text: '毎月', value: 5 },
      },
    }
  },
}
