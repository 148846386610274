/** 画面でのみ使用するenum */
export default {
  data () {
    return {
      CHART_DISPLAY_TYPES: {
        ALL: { text: '総数', value: 1 },
        DIFF: { text: '増減', value: 2 },
      },
    }
  },
}
