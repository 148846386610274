export default {
  title: {
    menu: {
      scv: 'カスタマー',
      segment: 'セグメント',
      dataSet: 'データセット',
      integrations: '連携ツール',
      job: 'ジョブ',
      account: 'アカウント',
      setting: '設定',
    },
    page: {
      scvs: 'カスタマー一覧',
      scvSettings: 'SCV設定',
      segments: 'セグメント一覧',
      segmentSetting: 'セグメント作成',
      dataSets: 'データセット一覧',
      transactionSummary: 'トランザクションサマリ一覧',
      transactionSummarySetting: 'トランザクションサマリ設定',
      segmentExportScripts: 'エクスポートスクリプト一覧',
      integrations: '連携ツール一覧',
      jobs: 'ジョブ一覧',
      jobSetting: 'ジョブ設定',
      jobHistories: 'ジョブ履歴',
      accounts: 'アカウント一覧',
      roles: '権限一覧',
      manuals: '各種手順書',
      passwordChange: 'パスワード変更',
      alerts: 'アラート設定',
      releaseNote: 'リリース情報',
    },
  },
}
