<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn color="primary" :to="{ name:'AccountRoleSetting' }" :disabled="!canPost">{{ $t(`btn.create`) }}</v-btn>
      </template>
      <template #right>
        <v-btn @click="reloadAccountRoles()"><v-icon>ic-reload</v-icon></v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <v-card tile outlined>
        <v-card-text class="pa-3">
          <account-roles-table
            :accountRoles="accountRoles"
            :pageIndex="index"
            :loading="loadingAccountRoles"
            @changePage="val => index = val"
            @edit="editAccountRole($event)"
            @delete="deleteAccountRole($event)"
            @showAccounts="showRoleAccounts($event)"
          />
        </v-card-text>
      </v-card>
      <v-dialog v-model="showDeleteModal" width="400">
        <v-card>
          <v-card-title/>
          <v-card-text>
            {{ $t('notify.check.delete', [deleteAccountRoleName]) }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="denial" @click="showDeleteModal = false">
              {{ $t(`btn.no`) }}
            </v-btn>
            <v-btn color="primary" @click="onClickDeleteRole()" :loading="loadingAccountRoles || deletingAccountRole">
              {{ $t('btn.yes') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showAccounts"
        width="368"
        overlay-color="#07070820"
        overlay-opacity="1"
      >
        <v-card>
          <v-card-title>
            アカウント一覧 - {{showAccountsAccountRoleName}}
          </v-card-title>
          <v-card-text>
            <v-list outlined>
              <template v-for="(account, index) in accountList">
                <v-list-item :key="account.accountId">{{ account.accountName }}</v-list-item>
                <v-divider
                  v-if="index < accountList.length - 1"
                  :key="index"
                />
              </template>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="denial" @click="showAccounts = false">閉じる</v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// component
import accountRolesTable from '@/components/AccountRoles/AccountRolesTable'
// util
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'

const DEFAULT_INDEX = 1
// const DEFAULT_LIMIT = 10

export default {
  name: 'AccountRoles',
  components: {
    'account-roles-table': accountRolesTable,
  },
  mixins: [
    enumUtil,
    notifyUtil,
  ],
  data () {
    return {
      accountRoles: [],
      index: DEFAULT_INDEX,
      showAccountEdit: false,
      showAccounts: false,
      showDeleteModal: false,
      deleteId: null,
      deleteAccountRoleName: null,
      showAccountsAccountRoleName: null,
      accountList: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['canPost', 'canPut', 'canDelete']),
    loadingAccountRoles () {
      return this.$store.state.accountRole.loadingAccountRoles
    },
    deletingAccountRole () {
      return this.$store.state.accountRole.deletingAccountRole
    },
  },
  created () {
    this.reloadAccountRoles()
  },
  methods: {
    reloadAccountRoles () {
      this.$store.dispatch('accountRole/getAccountRoles')
        .then(response => {
          this.accountRoles = response.data.data.roleList
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        }).finally(() => {
          this.index = DEFAULT_INDEX
        })
    },
    editAccountRole (roleId) {
      this.$router.push({ name: 'AccountRoleEdit', params: { roleId: roleId } })
    },
    deleteAccountRole (role) {
      this.deleteId = role.roleId
      this.deleteAccountRoleName = role.roleName
      this.showDeleteModal = true
    },
    onClickDeleteRole () {
      this.$store.dispatch('accountRole/deleteRole', this.deleteId)
        .then(res => {
          this.notifySuccessMessage(this.$t('notify.accountRole') + this.$t('notify.success.delete'))
          this.deleteId = null
          this.deleteAccountRoleName = null
          this.showDeleteModal = false
          this.reloadAccountRoles()
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    },
    showRoleAccounts (role) {
      this.showAccountsAccountRoleName = role.roleName
      this.$store.dispatch('accountRole/getRoleAccounts', role.roleId)
        .then(res => {
          this.accountList = res.data.data.accountList
          this.showAccounts = true
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    },
  },
}
</script>
