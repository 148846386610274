<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn :to="'/dataSet/setting/' + dataSetId + '/linkSetting'">
          {{ $t(`btn.back`) }}
        </v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <h3 class="lead-line">トランザクションと顧客マスタを連結する</h3>
      <h4>できること</h4>
      <div>SCVとして生成されるユーザに対してトランザクションのサマリを紐付けることができます。</div>

      <div class="sheetHeading">
        <v-spacer class="flex-grow-0" />
        <div>顧客マスタ&nbsp;&#040;<v-icon>ic-file-table-M</v-icon>&nbsp;or&nbsp;<v-icon>ic-API-M</v-icon>&#041;</div>
        <v-spacer class="flex-grow-0" />
        <div>トランザクション&nbsp;&#040;<v-icon>ic-file-table-M</v-icon>&nbsp;or&nbsp;<v-icon>ic-API-M</v-icon>&#041;</div>
        <v-spacer class="flex-grow-0" />
      </div>

      <v-sheet class="ruleForm" v-for="(selected, index) in selectedDataSourceList" :key="'selectedDataSources_' + index">
        <!-- 削除ボタン -->
        <v-btn
          fab
          depressed
          x-small
          color="denial"
          @click.stop="selectedDataSourceList.splice(index, 1)"
        >
          <v-icon>ic-close-S</v-icon>
        </v-btn>
        <!-- 顧客マスタ -->
        <v-card outlined tile>
          <v-card-text>
            <v-select
              v-model="selected.master"
              :label="$t('form.dataSource.name')"
              :items="JSON.parse(JSON.stringify(masterDataSourceList))"
              item-text="dataSourceName"
              item-value="dataSourceId"
              return-object
              :prepend-icon="getIcon(selected.master.connectorType)"
              name="master"
              :data-vv-as="$t('form.dataSource.name')"
              v-validate="'required'"
              :error-messages="errors.collect('master')"
            />
            <v-select
              :value="selected.master.userIdentifyColumn"
              :label="$t('form.dataSource.columnName')"
              :items="JSON.parse((selected.master || {}).headerMetaJson || '{}').columns"
              :item-text="item => item.logicalName + ' (' + getDataType(item.dataType).label + ')'"
              item-value="index"
              :item-disabled="isDateTypeNotString"
              readonly
              prepend-icon="ic-none"
              append-icon=""
              name="masterColumn"
              :data-vv-as="$t('form.dataSource.columnName')"
              v-validate="'required'"
              :error-messages="errors.collect('masterColumn')"
            >
            </v-select>
          </v-card-text>
        </v-card>

        <v-icon x-large>ic-link-LL</v-icon>

        <!-- トランザクション -->
        <v-card outlined tile>
          <v-card-text>
            <v-select
              v-model="selected.transaction"
              :label="$t('form.dataSource.name')"
              :items="JSON.parse(JSON.stringify(transactionDataSourceList))"
              item-text="dataSourceName"
              item-value="dataSourceId"
              return-object
              :prepend-icon="getIcon(selected.transaction.connectorType)"
              name="transaction"
              :data-vv-as="$t('form.dataSource.name')"
              v-validate="'required'"
              :error-messages="errors.collect('transaction')"
            />
            <v-select
              :value="selected.transaction.userIdentifyColumn"
              :label="$t('form.dataSource.columnName')"
              :items="JSON.parse((selected.transaction || {}).headerMetaJson || '{}').columns"
              :item-text="item => item.logicalName + ' (' + getDataType(item.dataType).label + ')'"
              item-value="index"
              :item-disabled="isDateTypeNotString"
              readonly
              prepend-icon="ic-none"
              append-icon=""
              name="transactionColumn"
              :data-vv-as="$t('form.dataSource.columnName')"
              v-validate="'required'"
              :error-messages="errors.collect('transactionColumn')"
            >
            </v-select>
          </v-card-text>
        </v-card>
      </v-sheet>
      <v-btn fab depressed @click="selectedDataSourceList.push({master: {}, transaction: {}})">
        <v-icon>ic-add</v-icon>
      </v-btn>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="denial" :to="'/dataSet/setting/' + dataSetId + '/linkSetting'">{{ $t('btn.cancel') }}</v-btn>
      <v-btn @click="onSaveClick" color="primary" :disabled="disableLinkSaveButton">{{ $t('btn.save') }}</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from '@/axios'
// enum
import DATA_TYPES from '@/enum/DATA_TYPES'
import DATA_SOURCE_TYPES from '@/enum/DATA_SOURCE_TYPES'
import CONNECTOR_TYPES from '@/enum/CONNECTOR_TYPES'
// util
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'dataSourceTransactionLinkSetting',
  mixins: [
    DATA_TYPES,
    DATA_SOURCE_TYPES,
    CONNECTOR_TYPES,
    notifyUtil,
  ],
  data () {
    return {
      dataSetId: Number(this.$route.params.dataSetId),
      dataSources: {},
      transactionLinkRuleList: [],
      selectedDataSourceList: [{ master: {}, transaction: {} }],
    }
  },
  computed: {
    masterDataSourceList () {
      if (this.dataSources.masterList === undefined) return []
      return this.dataSources.masterList
        .filter(dataSource =>
          dataSource.connectorType === this.CONNECTOR_TYPES.CSV.value ||
          dataSource.connectorType === this.CONNECTOR_TYPES.API.value,
        )
    },
    transactionDataSourceList () {
      if (this.dataSources.transactionList === undefined) return []
      return this.dataSources.transactionList
        .filter(dataSource =>
          dataSource.connectorType === this.CONNECTOR_TYPES.CSV.value ||
          dataSource.connectorType === this.CONNECTOR_TYPES.API.value,
        )
    },
    dataTypes () {
      const tmpDataTypes = Object.values(this.DATA_TYPES)
      return tmpDataTypes
    },
    disableLinkSaveButton () {
      // 0件の場合は削除処理になるので保存できる
      if (this.selectedDataSourceList.length === 0) {
        return false
      }
      // 入力されてないフォームがある場合は保存できない
      this.selectedDataSourceList.forEach(selected => {
        if (selected.master?.dataSourceId === undefined || selected.target?.dataSourceId === undefined) {
          return true
        }
      })
      return false
    },
  },
  created () {
    this.fetchDataSources()
  },
  methods: {
    fetchDataSources () {
      this.$store.dispatch('dataSet/getDataSources', this.dataSetId)
        .then((res) => {
          this.dataSources = res.data.data
          this.fetchDataSetLinkRule()
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
        .finally(() => {})
    },
    fetchDataSetLinkRule () {
      this.$store
        .dispatch('dataSet/getDataSetLinkRule', this.dataSetId)
        .then(res => {
          this.transactionLinkRuleList = res.data.data.transactionLinkRuleList
          this.setUpRuleList()
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    setUpRuleList () {
      this.selectedDataSourceList = []
      this.transactionLinkRuleList.forEach(rule => {
        // Update link rule.
        // 構築ルールのデータソースID からセレクトボックスが返す形式のデータソースオブジェクトをコピー
        // origin = masterDataSource, target = transactionDataSource
        const master = JSON.parse(JSON.stringify(this.dataSources.masterList.find(dataSource => dataSource.dataSourceId === rule.originDataSourceId)))
        const transaction = JSON.parse(JSON.stringify(this.dataSources.transactionList.find(dataSource => dataSource.dataSourceId === rule.targetDataSourceId)))

        this.selectedDataSourceList.push({ master: master, transaction: transaction })
      })
      if (!this.selectedDataSourceList.length === 0) {
        this.selectedDataSourceList.push({ master: {}, transaction: {} })
      }
    },
    onSaveClick () {
      // ルールなしの場合は削除処理
      if (this.selectedDataSourceList.length === 0) {
        axios({ method: 'delete', url: '/dataSet/' + this.dataSetId + '/transactionSummaryAdoptRule/' })
          .then(() => {
            this.notifySuccessMessage(this.$t('notify.dataSourceLink') + this.$t('notify.success.delete'))
            this.fetchDataSetLinkRule()
          }).catch(err => {
            this.handleErrorResponse(err)
          })
        return
      }

      // validate
      const errors = []
      this.$validator.validateAll()
        .then(result => {
          if (!result) return
          this.selectedDataSourceList.forEach((selected) => {
            // same targetDataSource
            if (this.selectedDataSourceList.filter(selected2 => selected2.transaction.dataSourceId === selected.transaction.dataSourceId).length !== 1) {
              errors.push(this.$t('notify.error.dataSet.transactionLinkRule.sameTransaction'))
            }
          })
        })
      if (errors.length > 0) {
        errors.forEach((e, index) => setTimeout(() => { this.notifyErrorMessage(e) }, index * 300))
        return
      }

      // リクエストパラメータの形式に変換
      const ruleList = this.selectedDataSourceList.map(selected => {
        return {
          masterDataSourceId: selected.master.dataSourceId,
          transactionDataSourceId: selected.transaction.dataSourceId,
        }
      })
      axios.put('/dataSet/' + this.dataSetId + '/transactionSummaryAdoptRule/', {
        transactionSummaryAdoptRuleList: ruleList,
      }).then(() => {
        this.notifySuccessMessage(this.$t('notify.linkRule') + this.$t('notify.success.update'))
        this.fetchDataSetLinkRule()
      }).catch((err) => {
        this.handleErrorResponse(err)
      })
    },
    getDataType (value) {
      return this.dataTypes.find(dataType => dataType.value === value)
    },
    getIcon (type) {
      switch (type) {
        case this.CONNECTOR_TYPES.CSV.value:
          return 'ic-file-table-M'
        case this.CONNECTOR_TYPES.API.value:
          return 'ic-API-M'
        default:
          return 'ic-none'
      }
    },
    isDateTypeNotString (item) {
      return item.dataType !== this.DATA_TYPES.STRING.value
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

#app {
  @include theme(v-sheet) using ($integral-core-theme) {
    &.ruleForm {
      background: map-deep-get($integral-core-theme, 'views', 'transactionLinkSetting', 'sheets', 'background') 0% 0% no-repeat padding-box;
    }
  }
}
.sheetHeading {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  padding: 12px 12px 0;
  font-weight: bold;
  font-size: 16px;
}
.ruleForm {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  padding: 12px;

  > .v-card {
    flex: 1;
    margin: 0 10px;
  }

  .master:after {
    content: '連結対象には、文字列型のカラムのみ指定できます';
  }
}
</style>
