export default {
  data () {
    return {
      CONNECTOR_TYPES: {
        CSV: { text: 'CSV', value: 1, icon: 'ic-file-table', availableDataSourceType: [1, 2] },
        API: { text: 'API', value: 2, icon: 'ic-API', availableDataSourceType: [1, 2] },
        WEB: { text: 'Web', value: 3, icon: 'ic-web', availableDataSourceType: [1] },
        ANDROID: { text: 'Android', value: 4, icon: 'ic-android', availableDataSourceType: [1] },
        IOS: { text: 'iOS', value: 5, icon: 'ic-iOS', availableDataSourceType: [1] },
      },
    }
  },
}
