<template>
  <v-container class="py-0" fluid>
    <v-row>
      <v-col class="py-0" cols="4">
        <v-select
          v-model="periodType"
          class="mt-0 pb-6"
          hide-details
          :items="enumUtil.convertForSelectList(actionPeriodType)"
          :placeholder="$t(`text.userDetail.achievementPeriod`)" />
      </v-col>
      <v-col class="py-0" cols="2" v-show="periodType === PERIOD_TYPES.CUSTOM.value">
        <v-dialog ref="dialog" v-model="startDatePicker" :return-value.sync="startDate" width="252px">
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              :label="startDate ? $t(`text.startDate`) : ''"
              :placeholder="$t(`text.startDate`)"
              class="mt-0"
              append-icon="ic-calendar-S"
              readonly
              v-on="on"
              v-bind="attrs"
              name="startDate"
              :data-vv-as="$t(`form.segmentSetting.segment.customPeriodDate`)"
              v-validate="periodType === PERIOD_TYPES.CUSTOM.value && !startDate && !endDate ? 'required' : '' "
              :error-messages="errors.collect('startDate')"
            />
          </template>
          <v-date-picker
            v-model="startDate"
            locale="ja"
            :day-format="date => new Date(date).getDate()"
            no-title
            width="auto"
            :allowed-dates="allowedDates"
            @input="$refs.dialog.save(startDate)"
          />
        </v-dialog>
      </v-col>
      <p class="pt-5 mb-0" v-show="periodType === PERIOD_TYPES.CUSTOM.value">〜</p>
      <v-col class="py-0" cols="2" v-show="periodType === PERIOD_TYPES.CUSTOM.value">
        <v-dialog ref="dialog2" v-model="endDatePicker" :return-value.sync="endDate" width="252px">
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              :label="startDate ? $t(`text.endDate`) : ''"
              :placeholder="$t(`text.endDate`)"
              class="mt-0"
              append-icon="ic-calendar-S"
              readonly
              v-on="on"
              v-bind="attrs"
              name="endDate"
              :data-vv-as="$t(`form.segmentSetting.segment.customPeriodDate`)"
              v-validate="periodType === PERIOD_TYPES.CUSTOM.value && !startDate && !endDate ? 'required' : '' "
              :error-messages="errors.collect('endDate')"
            />
          </template>
          <v-date-picker
            v-model="endDate"
            locale="ja"
            :day-format="date => new Date(date).getDate()"
            no-title
            width="auto"
            :allowed-dates="allowedDates"
            @input="$refs.dialog2.save(endDate)"
          />
        </v-dialog>
      </v-col>
      <v-col class="py-0">
        <v-btn class="mt-3" color="secondary" @click="onClickSearch()" :loading="isSearching">{{ $t(`text.search`) }}</v-btn>
      </v-col>
      <v-col class="py-0" cols="2">
        <v-switch v-model="switchSummary" color="secondary" @click="switchSummary = !switchSummary" hide-details>
          <template #label>
            {{$t(`text.userDetail.showSummary`)}}
          </template>
        </v-switch>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PERIOD_TYPES from '@/enum/PERIOD_TYPES'
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'PeriodFilterSelect',
  mixins: [
    PERIOD_TYPES,
    enumUtil,
    notifyUtil,
  ],
  props: {
    periodFilter: {
      type: Object,
    },
    isSearching: {
      type: Boolean,
      default: false,
    },
    showSummary: {
      type: Boolean,
    },
  },
  computed: {
    actionPeriodType () {
      const tmpPeriodType = JSON.parse(JSON.stringify(this.PERIOD_TYPES))
      delete tmpPeriodType.CUSTOM_RELATIVE_PERIOD
      return tmpPeriodType
    },
    switchSummary: {
      get () {
        return this.showSummary
      },
      set (newVal) {
        this.$emit('switchSummary', newVal)
      },
    },
  },
  watch: {
    periodFilter: {
      handler () {
        this.startDate = this.periodFilter.startDate
        this.endDate = this.periodFilter.endDate
      },
      deep: true,
    },
  },
  data () {
    return {
      periodType: null,
      startDate: '',
      endDate: '',
      startDatePicker: false,
      endDatePicker: false,
    }
  },
  methods: {
    onClickSearch () {
      this.$validator.validateAll().then((result) => {
        // バリデーションエラーがあればリクエストは送信しない
        if (!result) return
        this.$emit('search', {
          periodType: this.periodType,
          startDate: this.startDate,
          endDate: this.endDate,
        })
      })
    },
    // 180日前〜当日までを選択できるように制限
    allowedDates (val) {
      const today = new Date()
      const minAllowedDay = new Date()
      minAllowedDay.setDate(
        today.getDate() - 181,
      )
      return new Date(val) >= minAllowedDay && new Date(val) <= today
    },
  },
}
</script>

<style scoped lang="scss">
.adjust {
  padding: 24px 0 0 24px;
}
.v-input--switch {
  &::v-deep .v-input__control {
    .v-input__slot {
      flex-direction: row-reverse;
      margin-top: 8px;
      .v-input--selection-controls__input {
        width: 40px;
        margin: 0;
        .v-input--switch__thumb.secondary--text {
          transform: translate(16px, 0);
        }
        .v-input--switch__track {
          width: 40px;
        }
      }
      .v-label {
        margin-right: 30px;
        font-size: 14px;
        color: #474747 !important;
        white-space: nowrap;
      }
    }
  }
}
.v-select {
  &::v-deep .v-input__control {
    .v-input__slot {
      .v-select__slot {
        .v-select__selections {
          font-size: 14px;
          color: #474747;
        }
      }
    }
  }
}
</style>
