export default {
  data () {
    return {
      DEVICE_TYPES: {
        SMART_PHONE: { text: 'スマートフォン', value: 1 },
        PC: { text: 'PC', value: 2 },
      },
    }
  },
}
