<template>
  <v-navigation-drawer
    id="sidebar"
    app
    clipped
    fixed
    :mini-variant.sync="drawer"
    permanent
    width="284">
    <v-list expand subheader>
      <v-list-item>
        <v-list-item-icon v-if="drawer">
          <v-icon @click.stop="drawer = false">ic-arrow-right</v-icon>
        </v-list-item-icon>
        <v-list-item-content v-else>
          <h1>
            <v-img src="@/assets/logo-white.svg" alt="INTEGRAL CORE" width="198" height="24" contain />
          </h1>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon @click="drawer = true">ic-arrow-left</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-divider />
      <v-list-item class="loginUser">
        <v-list-item-icon v-if="drawer">
          <v-icon>ic-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-action v-else></v-list-item-action>
        <v-list-item-content>
          <p>{{ loginUserDetail.loginUserName }}</p>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon @click="logout()">ic-logout</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <template v-for="(page, index) in filteredPageList">
        <!-- メインメニュー -->
        <v-list-item
          :key="index"
          :class="['mainMenu', isActiveHead(page) ? 'head__menu' : '']"
          no-action>
          <v-list-item-action><v-icon>{{page.icon}}</v-icon></v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <b>{{ page.title }}</b>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- サブメニュー -->
        <template v-for="(subPage, subPageIndex) in page.subPage">
          <v-list-item
            v-if="!drawer"
            :key="index + '_' + subPageIndex"
            :to="{ name: subPage.name, query: subPage.params }"
            exact-path
            ripple>
            <v-list-item-action />
            <v-list-item-content>
              <v-list-item-subtitle>{{ subPage.title }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

// util
import enumUtil from '@/utils/enumUtil'

export default {
  name: 'Sidebar',
  mixins: [
    enumUtil,
  ],
  data () {
    return {
      pageList: [
        {
          title: this.$t('title.menu.scv'),
          functionName: 'customer',
          icon: 'ic-customer',
          subPage: [
            { title: this.$t('title.page.scvs'), name: 'scvs', functionName: 'scvs', params: null },
            { title: this.$t('title.page.scvSettings'), name: 'scvSetting', functionName: 'scvSetting', params: null },
          ],
        },
        {
          title: this.$t('title.menu.segment'),
          functionName: 'segment',
          icon: 'ic-segment',
          subPage: [
            { title: this.$t('title.page.segments'), name: 'Segments', params: null },
            { title: this.$t('title.page.segmentSetting'), name: 'SegmentSetting', params: null },
            // TODO i18n
            { title: 'セグメント作成 (SQL)', name: 'SegmentSQLSetting', params: null },
          ],
        },
        {
          title: this.$t('title.menu.dataSet'),
          functionName: 'dataSets',
          icon: 'ic-dataset',
          subPage: [
            { title: this.$t('title.page.dataSets'), name: 'DataSets', params: null },
            { title: this.$t('title.page.transactionSummary'), name: 'TransactionSummary', params: null },
          ],
        },
        {
          title: this.$t('title.menu.integrations'),
          functionName: 'integrations',
          icon: 'ic-linking',
          subPage: [
            { title: this.$t('title.page.segmentExportScripts'), name: 'SegmentExportScripts', params: null },
            // { title: this.$t(`title.page.integrations`), name: 'Integrations', params: null }
          ],
        },
        {
          title: this.$t('title.menu.job'),
          functionName: 'jobs',
          icon: 'ic-job',
          subPage: [
            { title: this.$t('title.page.jobs'), name: 'Jobs', params: null },
            { title: this.$t('title.page.jobHistories'), name: 'JobHistory', params: null },
            { title: this.$t('title.page.jobSetting'), name: 'JobSetting', params: null },
          ],
        },
        {
          title: this.$t('title.menu.setting'),
          functionName: 'setting',
          icon: 'ic-settings',
          subPage: [
            { title: this.$t('title.page.accounts'), name: 'Accounts', params: null },
            { title: this.$t('title.page.roles'), name: 'AccountRoles', params: null },
            { title: this.$t('title.page.manuals'), name: 'Manuals', params: null },
            { title: this.$t('title.page.passwordChange'), name: 'PasswordChange', params: null },
            // { title: this.$t(`title.page.alerts`), name: 'Alerts', params: null },
            { title: this.$t('title.page.releaseNote'), name: 'Release', params: null },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['isAllAuthorizedThis']),
    ...mapGetters(
      {
        scvsParams: 'scvs/getQueryParams',
        segmentsParams: 'segments/getQueryParams',
        dataSetsParams: 'dataSets/getQueryParams',
        transactionSummaryParams: 'transactionSummary/getQueryParams',
        segmentExportScriptsParams: 'segmentExportScripts/getQueryParams',
        jobsParams: 'jobs/getQueryParams',
        jobHistoryParams: 'jobExecHistory/getQueryParams',
        accountsParams: 'accounts/getQueryParams',
      },
    ),
    drawer: {
      get () {
        return this.$store.state.global.drawer
      },
      set (newVal) {
        // Mini 状態のリストアイコンをクリックすると Mini 状態が解除されて false が入ってくる
        this.$store.dispatch('global/updateDrawer', newVal)
      },
    },
    loginUserDetail () {
      return this.$store.state.auth.loginUserDetail
    },
    loginUserAuthority () {
      return this.$store.state.auth.loginUserDetail.authority
    },
    filteredPageList () {
      const tmpPageList = this.pageList.filter(page => this.isDisplayPage(page))
      tmpPageList.forEach(tmpPage => {
        tmpPage.subPage = tmpPage.subPage.filter(sub => this.isAllAuthorizedThis(sub.name))
      })
      return tmpPageList.map(page => {
        page.subPage.map(subPage => {
          return this.setPageParams(subPage)
        })
        return page
      })
    },
  },
  created () {
    // ログインユーザー情報を保持していない場合は再取得
    if (!this.loginUserDetail.loginUserId) this.$store.dispatch('auth/getLoginUser')
  },
  methods: {
    isDisplayPage (page) {
      switch (page.functionName) {
        case 'customer':
          return !!this.loginUserAuthority.scvSetting?.getAuthorized
        case 'segment':
          return !!this.loginUserAuthority.segment?.getAuthorized
        case 'integrations':
          // 現状セグメントエクスポートスクリプトしかないので、連携ツール配下をそれの権限で扱う
          return !!this.loginUserAuthority.segmentExportScript?.getAuthorized
        case 'dataSets':
          return !!this.loginUserAuthority.dataSet?.getAuthorized
        case 'jobs':
          return !!this.loginUserAuthority.jobs?.getAuthorized
        case 'setting':
          return true
        default:
          return false
      }
    },
    setPageParams (subPage) {
      switch (subPage.name) {
        case 'scvs':
          subPage.params = this.scvsParams
          break
        case 'Segments':
          subPage.params = this.segmentsParams
          break
        case 'DataSets':
          subPage.params = this.dataSetsParams
          break
        case 'TransactionSummary':
          subPage.params = this.transactionSummaryParams
          break
        case 'SegmentExportScripts':
          subPage.params = this.segmentExportScriptsParams
          break
        case 'Jobs':
          subPage.params = this.jobsParams
          break
        case 'JobHistory':
          subPage.params = this.jobHistoryParams
          break
        case 'Accounts':
          subPage.params = this.accountsParams
          break
        default:
          // 何もしない
      }
      return subPage
    },
    isActiveHead (page) {
      const currentPageName = this.$route.name
      switch (page.functionName) {
        case 'customer': {
          const customerPageList = ['scvSetting', 'scvs', 'scvUnion', 'UserScvDetail', 'UserUnknownDetail']
          return customerPageList.includes(currentPageName)
        }
        case 'segment': {
          const segmentPageList = ['Segments', 'SegmentSetting', 'SegmentSQLSetting', 'SegmentSettingCopy', 'SegmentSettingEdit', 'SegmentDetail']
          return segmentPageList.includes(currentPageName)
        }
        case 'integrations': {
          const segmentExportScriptPageList = ['SegmentExportScriptHistory', 'SegmentExportScripts', 'SegmentExportScriptSettingAdd', 'SegmentExportScriptSettingEdit', 'SegmentExportScriptSettingCopy']
          return segmentExportScriptPageList.includes(currentPageName)
        }
        case 'dataSets': {
          const dataSetPageList = ['DataSets', 'DataSetAdd', 'DataSetSettingDetail', 'DataSourceAdd', 'DataSourceConnectorSetting', 'DataSourceSettingDetail', 'DataSourceLinkSetting', 'DataSourceMasterLinkSetting', 'DataSourceTransactionLinkSetting', 'TransactionSummary', 'TransactionSummarySetting', 'TransactionSummaryEdit']
          return dataSetPageList.includes(currentPageName)
        }
        case 'jobs': {
          const jobPageList = ['JobSetting', 'JobSettingEdit', 'Jobs', 'JobHistory']
          return jobPageList.includes(currentPageName)
        }
        case 'setting': {
          const settingPageList = ['Manuals', 'Accounts', 'AccountSetting', 'AccountEdit', 'AccountRoles', 'AccountRoleSetting', 'AccountRoleEdit', 'PasswordChange', 'Release']
          return settingPageList.includes(currentPageName)
        }
        default:
          return false
      }
    },
    /**
     * ログアウト処理
     */
    logout () {
      this.menu = false
      this.$store.dispatch('auth/logout')
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.loginUser {
  min-height: 40px;
  .v-list-item__icon {
    min-width: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    .v-icon {
      font-size: 20px;
      padding-left: 2px;
    }
  }
  .v-list-item__content {
    height: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0px;
    p {
      font-size: 12px;
      margin-bottom: 0px;
      line-height: 1;
    }
  }
}
.v-list-item__title {
  b {
    font-size: 14px;
  }
}
.v-list-item--active::before {
  opacity: 0;
}
.v-navigation-drawer--mini-variant {
  .mainMenu:hover {
    background-color: #0A5A8266;
  }
}
.v-list-item.v-list-item--link:hover {
  background-color: #0A5A8266;
}
</style>
