<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn v-if="getRouteToReturn().name === 'SegmentExportScripts'" :to="'/segmentExportScripts/'">{{ $t(`btn.backToList`) }}</v-btn>
        <v-btn v-else :to="getRouteToReturn()">{{ $t(`btn.back`) }}</v-btn>
      </template>
      <template #right>
        <v-btn @click="getSegmentExportScriptHistories" :loading="loadingSegmentExportScriptHistories"><v-icon>ic-reload</v-icon></v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <filter-form
        @search="setFilterParameter"
        @reset="resetFilterParameter"
        @switchFilter="val => isFiltering = val"
        :isFiltering="isFiltering"
        :filteredTotal="paging.filteredTotalCount"
        :total="paging.totalCount"
      >
        <v-row>
          <v-col class="py-0" cols="3">
            <v-select
              :value="filterParameter.execStatus"
              @change="preparedFilters.execStatus = $event"
              :items="enumUtil.convertForSelectList(EXEC_STATUS)"
              :label="$t(`text.segmentExportScriptHistory.execStatus`)"
              clearable
              :disabled="!isFiltering" />
          </v-col>
        </v-row>
      </filter-form>
      <!-- paging/sort/limit -->
      <v-row justify="space-between" class="my-0">
        <v-col cols="4">
          <v-select
            v-model="orderBy"
            class="sort pt-0"
            hide-details
            :items="sortableColumns"
            :disabled="loadingSegmentExportScriptHistories"
          >
            <template #prepend>
              <span class="prepend-sort-text">
                {{ $t('text.sort') }}
              </span>
            </template>
            <template #append-outer>
              <v-btn icon @click="isDesc = !isDesc" :disabled="loadingSegmentExportScriptHistories">
                <v-icon v-if="isDesc">ic-sort-desc</v-icon>
                <v-icon v-else>ic-sort-asc</v-icon>
              </v-btn>
            </template>
          </v-select>
        </v-col>
        <v-col cols="5">
          <v-pagination :value="filterParameter.page" @input="changeQuery('page', $event)" :length="pages" total-visible="7" :disabled="loadingSegmentExportScriptHistories" />
        </v-col>
        <v-spacer />
        <v-col cols="2">
          <v-select
            v-model="limit"
            class="limits pt-0"
            hide-details
            :items="limits"
            :disabled="loadingSegmentExportScriptHistories"
          >
            <template #prepend>
              <span class="prepend-sort-text">
                {{ $t('text.limits') }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <segment-export-script-history-table
        :histories="segmentExportScriptHistories"
        :loading="loadingSegmentExportScriptHistories"
      />
      <!-- paging -->
      <v-row>
        <v-col offset="4" cols="5">
          <v-pagination :value="filterParameter.page" @input="changeQuery('page', $event)" :length="pages" total-visible="7" :disabled="loadingSegmentExportScriptHistories" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// component
import FilterForm from '@/components/common/filterForm'
import segmentExportScriptHistoryTable from '@/components/SegmentExportScriptHistory/segmentExportScriptHistoryTable'
// enum
import EXEC_STATUS from '@/enum/EXEC_STATUS'
// util
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'

const DEFAULT_PAGE = 1
const DEFAULT_LIMIT = 50

export default {
  name: 'SegmentExportScriptHistory',
  mixins: [
    EXEC_STATUS,
    enumUtil,
    notifyUtil,
  ],
  components: {
    FilterForm,
    segmentExportScriptHistoryTable,
  },
  data () {
    return {
      paging: {},
      sortableColumns: [
        {
          text: this.$t('text.segmentExportScriptHistory.sort.startDatetime'),
          value: 'startDatetime',
        },
        {
          text: this.$t('text.segmentExportScriptHistory.sort.endDatetime'),
          value: 'endDatetime',
        },
        {
          text: this.$t('text.segmentExportScriptHistory.sort.execStatus'),
          value: 'execStatus',
        },
      ],
      limits: [
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
      prevRoute: null,
      segmentExportScriptId: Number(this.$route.params.segmentExportScriptId),
      segmentExportScriptHistories: [],
      preparedFilters: [],
      isFiltering: true,
    }
  },
  computed: {
    ...mapGetters({ queryParams: 'segmentExportScriptHistory/getQueryParams' }),
    pages () {
      return Math.ceil(this.paging.filteredTotalCount / this.paging.limit) || 1
    },
    limit: {
      get () {
        return this.$store.state.segmentExportScriptHistory.filterParameter.limit
      },
      set (newVal) {
        this.changeQuery('limit', newVal)
      },
    },
    orderBy: {
      get () {
        return this.$store.state.segmentExportScriptHistory.sortParameter.orderBy
      },
      set (newVal) {
        this.changeQuery('orderBy', newVal)
      },
    },
    isDesc: {
      get () {
        return this.$store.state.segmentExportScriptHistory.sortParameter.isDesc
      },
      set (newVal) {
        this.changeQuery('isDesc', newVal)
      },
    },
    enabledFilter () {
      return this.$store.state.segmentExportScriptHistory.enabledFilter
    },
    filterParameter () {
      return this.$store.state.segmentExportScriptHistory.filterParameter
    },
    loadingSegmentExportScriptHistories: {
      get () {
        return this.$store.state.segmentExportScriptHistory.loadingSegmentExportScriptHistories
      },
      set (newVal) {
        this.$store.dispatch('segmentExportScriptHistory/updateLoadingSegmentExportScriptHistories', newVal)
      },
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  created () {
    this.loadingSegmentExportScriptHistories = true

    const tmpQuery = {}
    for (const key in this.$route.query) {
      tmpQuery[key] = this.$route.query[key]
    }

    // クエリが不十分な場合補う
    let isQuerySufficient = true
    for (const key in this.queryParams) {
      if (tmpQuery[key] === null || tmpQuery[key] === undefined) {
        tmpQuery[key] = this.queryParams[key]
        isQuerySufficient = false
      }
    }

    // orderByに指定された文字列がソート基準の選択肢にあるなら問題ない
    const validateOrderBy = this.sortableColumns.some((column) => tmpQuery.orderBy === column.value)
    if (!validateOrderBy) {
      tmpQuery.orderBy = 'endDatetime'
      isQuerySufficient = false
    }
    if (tmpQuery.isDesc !== true && tmpQuery.isDesc !== false && tmpQuery.isDesc !== 'true' && tmpQuery.isDesc !== 'false') {
      tmpQuery.isDesc = true
      isQuerySufficient = false
    }
    tmpQuery.page = Number(tmpQuery.page)
    if (!Number.isInteger(tmpQuery.page) || tmpQuery.page < 1) {
      tmpQuery.page = DEFAULT_PAGE
      isQuerySufficient = false
    }
    tmpQuery.limit = Number(tmpQuery.limit)
    if (!Number.isInteger(tmpQuery.limit) || tmpQuery.limit < 1 || tmpQuery.limit > 5000) {
      tmpQuery.limit = DEFAULT_LIMIT
      isQuerySufficient = false
    }

    // クエリを変更した場合はURLを置き換える
    if (!isQuerySufficient) {
      this.$router.replace({ query: tmpQuery })
        .catch(err => err) // 画面表示時の NavigationDuplicated 対策
      // 置き換わるとbeforeRouteUpdateが呼び出されるのでこちらの処理は中断する
      return
    }

    this.reflectQueryParamsToState(tmpQuery)
    this.preparedFilters = this.filterParameter
    this.isFiltering = this.enabledFilter
    this.getSegmentExportScriptHistories()
  },
  beforeRouteUpdate (to, from, next) {
    // ルート変更に反応する
    if (to.query !== from.query) {
      this.reflectQueryParamsToState(to.query)
      this.getSegmentExportScriptHistories()
    }
    next()
  },
  methods: {
    /**
     * 戻るボタンの戻り先を取得 (セグメント詳細からも遷移してくるため)
     * @returns {Route} 戻るボタンの遷移先.前画面の Route.name を見て振り分ける
     *     `prevRoute.name === 'SegmentDetail'`: セグメント詳細へ遷移 (パラメータを保持)
     *     `prevRoute.name === 'SegmentExportScripts'`: ジョブ一覧へ遷移 (パラメータを保持)
     *     その他: スクリプト一覧へ遷移 (パラメータなし)
     */
    getRouteToReturn () {
      if (this.prevRoute === null || this.prevRoute.name === null) {
        return { name: 'SegmentExportScripts' }
      } else if (this.prevRoute.name === 'SegmentDetail') {
        return this.prevRoute
      } else if (this.prevRoute.name === 'SegmentExportScripts') {
        return this.prevRoute
      }
      return { name: 'SegmentExportScripts' }
    },
    changeQuery (type, value) {
      const queryParams = this.queryParams

      if (type === 'filter') {
        queryParams.execStatus = value.execStatus
        queryParams.enabledFilter = this.isFiltering
      } else {
        queryParams[type] = value
      }

      if (type !== 'page') {
        queryParams.page = DEFAULT_PAGE
      }

      // クエリパラメーター更新
      // クエリパラメーターを更新するとbeforeRouteUpdateが呼び出される
      this.$router.push({ query: queryParams })
        .catch(err => err) // 画面表示時の NavigationDuplicated 対策
    },
    /**
     * セグメントエクスポートスクリプト実行履歴一覧リストの取得
     */
    getSegmentExportScriptHistories () {
      this.$store.dispatch('segmentExportScriptHistory/getSegmentExportScriptHistories', this.segmentExportScriptId)
        .then(res => {
          this.segmentExportScriptHistories = res.segmentExportScriptHistories
          this.paging = res.paging
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    },
    reflectQueryParamsToState (query) {
      this.$store.dispatch('segmentExportScriptHistory/updateOrderBy', query.orderBy)
      this.$store.dispatch('segmentExportScriptHistory/updateIsDesc', JSON.parse(query.isDesc))
      this.$store.dispatch('segmentExportScriptHistory/updateEnabledFilter', JSON.parse(query.enabledFilter))
      const tmpFilterParameter = []
      if (Object.keys(query).length > 0) {
        // filterParameter として許可されているプロパティのみ有効
        for (const key in this.filterParameter) {
          if (query[key] === undefined && query[key] === null) {
            tmpFilterParameter[key] = null
          } else {
            tmpFilterParameter[key] = query[key]
          }
          // this.$route.query には文字列型で入ってるので、数値型にキャストできるなら変換
          if (tmpFilterParameter[key] !== null && tmpFilterParameter[key] !== '' && !Number.isNaN(Number(tmpFilterParameter[key]))) {
            tmpFilterParameter[key] = Number(tmpFilterParameter[key])
          }
          // this.$route.query には文字列型で入ってるので、boolean型にしたいものは変換
          if (tmpFilterParameter[key] === 'true') {
            tmpFilterParameter[key] = true
          }
          if (tmpFilterParameter[key] === 'false') {
            tmpFilterParameter[key] = false
          }
        }
      }
      this.$store.dispatch('segmentExportScriptHistory/updateFilter', tmpFilterParameter)
    },
    /**
     * フィルタリング条件の適用
     */
    setFilterParameter () {
      this.changeQuery('filter', this.preparedFilters)
    },
    /**
     * フィルタリング条件の初期化
     */
    resetFilterParameter () {
      this.preparedFilters = []
      this.setFilterParameter()
    },
  },
}
</script>
