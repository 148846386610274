import Vue from 'vue'
import App from './App.vue'
import axios from '@/axios/'
import router from '@/router'
import store from '@/store/'
import validate from '@/validate/'
import filters from '@/filters'

/* ナビゲーション */
import Sidebar from '@/components/Sidebar/index'
import InformationBar from '@/components/common/informationBar'
/* 汎用parts */
import LineChart from '@/components/public/lineChart'

/* style */
import vuetify from '@/plugins/vuetify'
import './styles/main.scss'

/* notification */
import Notifications from 'vue-notification'
/* クリップボードにコピー */
import VueClipboard from 'vue-clipboard2'
/* VuexのStore(store.state.route)でroute情報を取得可能にする */
import { sync } from 'vuex-router-sync'
/* 多言語対応 */
import VueI18n from 'vue-i18n'
import messages from '@/i18n/messages'

/* ナビゲーション */
Vue.component('information-bar', InformationBar)
Vue.component('global-sidebar', Sidebar)
/* 汎用パーツ */
Vue.component('line-chart', LineChart)

// VuexとVue-Routerの紐付けに必要な処理
sync(store, router)

// 同名のルーティングがある場合に権限チェックが破綻するので検査
router.options.routes.reduce((names, route) => {
  if (route.name in names) {
    names[route.name]++
    throw Error('router.js に同名のルーティング情報が定義されています. name = ' + route.name)
  } else {
    names[route.name] = 1
  }
  return names
}, {})

Vue.use(filters)
Vue.use(Notifications)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'ja',
  messages: messages,
})

Vue.config.productionTip = false

new Vue({
  axios,
  router,
  store,
  validate,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
