export default {
  data () {
    return {
      TRANSACTION_PERIOD_TYPE: {
        ALL_DAYS: { text: '全期間', value: 1 },
        LAST_DAYS_INCLUDE_CURRENT_DAY: { text: '直近n日間(当日を含む)', value: 2 },
        LAST_DAYS_EXCLUDE_CURRENT_DAY: { text: '直近n日間(当日を除く)', value: 3 },
      },
    }
  },
}
