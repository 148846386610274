export default {
  data () {
    return {
      INTERNAL_FILTER_TYPES: {
        SITE: { internalFilterType: 1, filterLogicalName: 'WEBサイト', filterPhysicalName: 'site_id' },
        PAGE: { internalFilterType: 2, filterLogicalName: 'ページ', filterPhysicalName: 'page_id' },
        DEVICE_TYPE: { internalFilterType: 3, filterLogicalName: 'デバイス', filterPhysicalName: 'device_type' },
        OS_TYPE: { internalFilterType: 4, filterLogicalName: 'OS', filterPhysicalName: 'os_type' },
      },
    }
  },
}
