const RATIO = 100 // 百分率計算用
const DIGIT = 10 // 切り捨て計算の小数点桁指定用

export default {
  data () {
    return {
      addKIfOverKilo (num) {
        if (Number.isNaN(num) || !Number.isFinite(num)) {
          return 0
        }
        const Big = require('big.js')
        // 4桁以上の数値を10の位以下を切り捨てて末尾に"K"を追加する(例、12345は12.3K)
        const intNum = new Big(num).round()
        if (intNum >= 1000) {
          const bigNum = new Big(num)
          const divideNum = new Big('1000')
          const culculatedNum = bigNum.div(divideNum).round(1, 0)
          return this.addComma(culculatedNum) + 'K'
        }
        return num
      },
      addComma (num) {
        return Number(num).toLocaleString()
      },
      calculateRatioUser (segmentTotal, userTotal) {
        // 0除算回避
        if (segmentTotal <= 0 || userTotal <= 0) return 0
        // 百分率で算出→100倍して小数点未満を切り捨て→1/100倍することで小数点第2位未満を切り捨てる
        return (Math.floor(segmentTotal / userTotal * RATIO * 100) / 100) || '0.00'
      },
      calWeeklyCompare (thisWeeklyTotal, lastWeeklyTotal) {
        // 前週比を算出する、前週の値が0の場合は計算できないので0を返却する
        if (lastWeeklyTotal === 0) {
          return 0
        }
        // 引数がNaNの場合は計算できないので0を返却する
        if (Number.isNaN(thisWeeklyTotal) || !Number.isFinite(thisWeeklyTotal) || Number.isNaN(lastWeeklyTotal) || !Number.isFinite(lastWeeklyTotal)) {
          return 0
        }
        return Math.floor(((thisWeeklyTotal / lastWeeklyTotal - 1) * RATIO) * DIGIT) / DIGIT
      },
      timeSecFilter (sec) {
        if (sec < 60) {
          return String(Math.floor(sec)) + '秒'
        }
        if (sec % 60 === 0) {
          return String(Math.floor(sec / 60)) + '分'
        } else {
          return String(Math.floor(sec / 60)) + '分' + String(Math.floor(sec % 60)) + '秒'
        }
      },
    }
  },
}
