export default {
  table: {
    accounts: {
      loginUserName: 'ユーザー名',
      emailAddress: 'メールアドレス',
      role: '権限',
      note: '備考',
      lastLoginDateTime: '最終ログイン日時',
      createdAt: '作成日時',
    },
    sites: {
      column1: '計測対象名',
      column2: 'ドメイン/バンドルID',
      column3: '計測ドメイン',
      column4: '計測対象種別',
      column5: 'サイトハッシュ',
      column6: '作成日時',
      column7: '更新日時',
    },
    dataSourceImportHistories: {
      column1: '種別',
      column3: 'ファイル名',
      column4: 'アップロード結果',
      column5: 'アップロード開始日時',
      column6: 'アップロード終了日時',
      column7: 'エラーメッセージ',
    },
    jobs: {
      column1: 'ジョブ名',
      column2: 'スケジュール',
      column3: '更新日時・ユーザー',
      column4: 'ステータス',
      column5: '備考',
      column6: '最終結果',
      column7: '終了日時',
    },
    jobHistories: {
      jobName: 'ジョブ名',
      detail: '詳細',
      createDatetime: '起動日時',
      startDatetime: '処理開始日時',
      endDatetime: '終了日時',
      jobResult: '結果',
      jobMessage: 'メッセージ',
    },
    masterImportHistories: {
      column1: '顧客マスタ名',
      column2: 'アップロード種別',
      column3: '登録者',
      column4: 'ファイル名',
      column5: 'アップロード結果',
      column6: 'アップロード開始日時',
      column7: 'アップロード終了日時',
      column8: 'エラーメッセージ',
    },
    masterLinkHistories: {
      column1: '実行日時',
      column2: '終了日時',
      column3: '実行種別',
      column4: 'ステータス',
      column5: 'エラーメッセージ',
    },
    masters: {
      column1: '',
      column2: '顧客マスタ名',
      column3: '顧客マスタハッシュ',
      column4: '顧客マスタ内主キー',
      column5: '作成日時',
      column6: '更新日時',
      column7: '最終アップロード結果',
      column8: '最終アップロード日時',
    },
    segmentExportScriptHistory: {
      startDatetime: '処理開始日時',
      endDatetime: '終了日時',
      execStatus: '結果',
      message: 'メッセージ',
      log: 'スクリプトログ',
    },
  },
}
