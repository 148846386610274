import axios from '@/axios'
import router from '@/router'

const userUnknownDetail = {
  namespaced: true,
  state: {
    loadingUserUnknownDetail: false,
  },
  mutations: {
    setLoadingUserUnknownDetail (state, newVal) {
      state.loadingUserUnknownDetail = newVal
    },
  },
  actions: {
    async getUserUnknownDetail ({ commit }, unknownId) {
      commit('setLoadingUserUnknownDetail', true)
      return await axios.get('/userDetail/unknown/' + unknownId + '/')
        .then((res) => {
          return Promise.resolve({
            userUnknownDetail: res.data.data,
          })
        })
        .catch((error) => {
          if (error.response.status === 404) router.push({ name: 'PageNotFound' }, () => {})
        })
        .finally(() => {
          commit('setLoadingUserUnknownDetail', false)
        })
    },
  },
}
export default userUnknownDetail
