<template>
  <div>
    <v-row v-if="!customScvColumns" class="py-3" :justify="'center'" dense>
      <v-progress-circular indeterminate color="primary" />
    </v-row>
    <v-row v-else class="mx-0">
      <v-col v-for="num in pageColumnCount" :key="num" :cols="12 / pageColumnCount" class="pt-1">
        <v-list class="pt-0">
          <template v-for="(column, index) in displayColumns.slice(((num - 1) * pageItemLimit / pageColumnCount), (num * pageItemLimit / pageColumnCount))">
            <v-list-item :key="index" two-line class="px-0">
              <v-list-item-content>
                <v-list-item-subtitle class="mb-1">{{ column.scvColumnName }}</v-list-item-subtitle>
                <scv-column :scvColumn="column" :scvSources="findScvColumnSources(column.scvColumnId)" :loading="loading" @overwrite="overwrite" />
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="index + '_divider'" />
          </template>
        </v-list>
      </v-col>
    </v-row>
    <v-row :align="'center'" :justify="'space-between'">
      <v-col class="text-center" cols="12">
        <v-pagination v-model="page" :length="pageLength" total-visible="7" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// component
import scvColumn from '@/components/UserDetail/scvColumn'

const PAGE_ITEM_LIMIT = 20
const PAGE_COLUMN_COUNT = 2

export default {
  name: 'customScvColumns',
  components: {
    'scv-column': scvColumn,
  },
  props: {
    customScvColumns: {
      type: Array,
    },
    scvCustomColumnSources: {
      type: Array,
    },
    total: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
  },
  data () {
    return {
      displayColumns: [],
      page: 1,
      pageItemLimit: PAGE_ITEM_LIMIT,
      pageColumnCount: PAGE_COLUMN_COUNT,
    }
  },
  computed: {
    // 総ページ数
    pageLength () {
      return Math.ceil(this.total / PAGE_ITEM_LIMIT) | 0
    },
  },
  watch: {
    customScvColumns: {
      handler () {
        this.fetchIdentify()
      },
      deep: true,
    },
    page () {
      this.fetchIdentify()
    },
  },
  methods: {
    /**
     * 識別情報を表示用に加工
     */
    fetchIdentify () {
      this.displayColumns = []
      if (this.customScvColumns) {
        // 識別情報の開始位置
        let index = (this.page - 1) * PAGE_ITEM_LIMIT
        if (this.page === this.pageLength) {
          for (index; index < this.total; index++) {
            this.displayColumns.push(this.customScvColumns[index])
          }
        } else {
          for (let n = 0; n < PAGE_ITEM_LIMIT; n++) {
            this.displayColumns.push(this.customScvColumns[index + n])
          }
        }
      }
      // 18項目に満たない場合は空のObjectを詰めて保管
      if (this.displayColumns.length < PAGE_ITEM_LIMIT) {
        const emptyItem = {
          scvColumnId: '',
          scvColumnName: '',
          scvColumnValue: '',
          isUpdated: false,
        }
        const insertNum = PAGE_ITEM_LIMIT - this.displayColumns.length
        for (let n = 0; n < insertNum; n++) {
          this.displayColumns.push(emptyItem)
        }
      }
    },
    findScvColumnSources (scvColumnId) {
      if (!this.scvCustomColumnSources || !scvColumnId) {
        return []
      }
      const columnSources = this.scvCustomColumnSources.find((col) => col.scvColumnId === scvColumnId)
      if (columnSources !== undefined) {
        return columnSources.scvColumnDataSetSources
      }
      return []
    },
    /**
     * SCV項目を上書き
     */
    overwrite (scvColumnId, newValue) {
      this.$emit('overwrite', scvColumnId, newValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-list-item {
  min-height: 56px;
  .v-list-item__content {
    overflow: visible;
    padding-bottom: 6px;
    .v-list-item__subtitle {
      font-size: 12px;
    }
    .d-flex {
      align-items: flex-end;
    }
  }
}
</style>
