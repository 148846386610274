export default {
  data () {
    return {
      SCV_DATA_TYPES: {
        STRING: { text: '文字列', value: 1 },
        LONG: { text: '数値', value: 2 },
        DATE: { text: '日付', value: 3 },
        DATETIME: { text: '日時', value: 4 },
      },
    }
  },
}
