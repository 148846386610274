<template>
  <v-card tile elevation="0">
    <information-bar showTotalUser>
      <template #left>
        <v-btn :to="{ name: 'scvs' }">
          {{ $t(`btn.scv.backToList`) }}
        </v-btn>
      </template>
    </information-bar>
    <v-card-text>
      選択したユーザを1人に統合します。統合時に各項目から優先したい値を設定し「統合する」ボタンを押してください
      <scv-union-table
        v-if="isSearched"
        :tableHeaders="scvColumns"
        :scvs="scvs"
        :defaultScv.sync="defaultScv"
        :targetScv.sync="targetScv"
        :selectedColumns.sync="selectedColumns"
        :loading="loadingScvs" />
      <div v-else>
        <div v-show="!loadingScvs" class="my-5">{{ $t(`text.scvUnion.noCustomer`) }}</div>
        <v-row v-show="loadingScvs" :justify="'center'">
          <v-progress-circular indeterminate color="primary" />
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="denial" large :to="{ name: 'scvs' }">{{ $t('btn.cancel') }}</v-btn>
      <v-btn color="primary" large :disabled="!isValidSetting" @click="saveUnionSetting">{{ $t('btn.scvUnion.save') }}</v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
// component
import scvUnionTable from '@/components/ScvUnion/scvUnionTable'
// util
import notifyUtil from '@/utils/notifyUtil'

const DEFAULT_PAGE = 1

export default {
  name: 'scvUnion',
  components: {
    scvUnionTable,
  },
  mixins: [
    notifyUtil,
  ],
  data () {
    return {
      scvColumns: [],
      scvs: [],
      candidates: '',
      defaultScv: undefined,
      targetScv: [],
      selectedColumns: [],
      isSearched: false,
    }
  },
  computed: {
    loadingScvs: {
      get () {
        return this.$store.state.scvs.loadingScvs
      },
      set (newVal) {
        this.$store.dispatch('scvs/updateLoadingScvs', newVal)
      },
    },
    filterParameter () {
      return this.$store.state.scvs.filterParameter
    },
    errorMessages () {
      const errorMessages = []
      if (this.targetScv.length === 0 ||
          this.targetScv.includes(null) ||
          this.targetScv.includes(undefined)) {
        errorMessages.push(this.$t('notify.error.scvUnion.noTarget'))
      }
      if (!this.defaultScv) {
        errorMessages.push(this.$t('notify.error.scvUnion.noDefaultScv'))
      }
      if (!this.targetScv.find(scv => scv && scv.scvId === this.defaultScv)) {
        errorMessages.push(this.$t('notify.error.scvUnion.defaultScvNotTargeted'))
      }
      if (this.selectedColumns.length !== this.scvColumns.length ||
          this.selectedColumns.includes(null) ||
          this.selectedColumns.includes(undefined)) {
        errorMessages.push(this.$t('notify.error.scvUnion.missingColumns'))
      }
      return errorMessages
    },
    isValidSetting () {
      return this.errorMessages.length === 0
    },
  },
  created () {
    this.$store.dispatch('scvSettings/getValidScvSettings')
      .then((res) => {
        this.filterParameter.page = DEFAULT_PAGE

        this.scvColumns = JSON.parse(JSON.stringify(res.scvSettings))
          .filter(scvSetting => scvSetting.scvColumnChoiceSettingList.length > 0)
          // .filter(scvSetting => scvSetting.scvColumnChoiceSettingList.length > 0 && !scvSetting.custom)
          .map((scvSetting) => {
            return { text: scvSetting.scvLogicalColumnName, value: scvSetting.scvColumnId, custom: scvSetting.custom }
          })

        // 有効なカラムの先頭をデフォルト選択
        const firstColumnId = this.scvColumns.length > 0 ? this.scvColumns[0].value : null
        this.$store.dispatch('scvs/updateFilter', [{ filterId: firstColumnId, filterValue: '' }])

        // クエリパラメーターに指定されている条件をStateに反映
        Object.keys(this.$route.query).forEach(queryParam => {
          switch (queryParam) {
            case 'candidates':
              this.candidates = this.$route.query.candidates
              break
            default:
          }
        })

        // SCV設定が設定済みで、統合対象の SCV が指定されている場合のみ初期表示用の検索を行う
        if (this.scvColumns[0] && this.candidates) {
          this.getScvs()
        }
      })
  },
  methods: {
    /**
     * SCV一覧の取得
     */
    getScvs () {
      const params = {}
      params.isDesc = this.$store.state.scvs.sortParameter.isDesc
      params.orderBy = this.$store.state.scvs.sortParameter.orderBy
      params.page = this.filterParameter.page
      params.limit = this.filterParameter.limit
      params.filters = this.filterParameter.filters
      params.scvIds = this.candidates
      this.$store.dispatch('scvs/getScvsByIds', params)
        .then((res) => {
          this.scvs = res.scvs
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        }).finally(() => {
          this.isSearched = true
        })
    },
    /**
     * 統合設定の保存
     */
    saveUnionSetting () {
      if (this.errorMessages.length > 0) {
        this.notifyErrorMessage(this.errorMessages.join('<br>'))
        return
      }
      const formParameter = {}
      formParameter.defaultScvId = this.defaultScv
      formParameter.targetScvIds = this.targetScv.filter(scv => scv).map(scv => scv.scvId)
      formParameter.selectedScvColumnList = []
      this.scvColumns.forEach((column, index) => {
        formParameter.selectedScvColumnList.push({ scvColumnId: column.value, selectedCustomerCode: this.selectedColumns[index] })
      })
      this.$store.dispatch('scvUnion/saveUnionSetting', formParameter)
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.scvUnion') + this.$t('notify.success.create'))
          this.$router.push({ name: 'scvs' }, () => {})
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.filled_condition {
  background: #ff980012;
}
</style>
