<template>
  <div>
    <v-tabs v-model="selectedTab">
      <v-tab href="#default">{{ $t(`text.scvSetting.scvSettingList.defaultHeading`)}}</v-tab>
      <v-tab href="#custom">{{ $t(`text.scvSetting.scvSettingList.customHeading`)}}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab" class="pr-0 pb-5">
      <v-tab-item value="default">
        <v-list nav class="item-inPageScroll_y pl-0 pt-0">
          <v-list-item-group v-model="computedSelectedScvSetting">
            <template v-for="(scvSetting) in defaultScvSettings">
              <v-list-item
                :value="scvSetting"
                :key="scvSetting.scvColumnId"
                ripple
              >
                <v-list-item-icon class="align-self-center mr-1">
                  <v-icon v-if="scvSetting.isScvIdentify">ic-key-S</v-icon>
                  <v-icon v-else-if="scvSetting.scvColumnChoiceSettingList.length >= 1" small>ic-turned-in-S</v-icon>
                </v-list-item-icon>
                <!-- TODO: Phase2の隠し機能 -->
                <!-- <v-list-item-icon class="align-self-center mr-3">
                  <v-icon v-if="scvSetting.isSync">mdi-antenna</v-icon>
                </v-list-item-icon> -->
                <v-list-item-content class="flex-grow-1">
                  <v-list-item-title class="body-1">
                    {{ scvSetting.scvLogicalColumnName }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="grey--text">
                    {{ scvSetting.scvPhysicalColumnName }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-show="scvSetting.isOrigin === false">
                  <v-btn
                    x-small
                    outlined
                    tile
                    @click.stop
                  >
                    {{ $t('btn.scvSetting.save')}}
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-tab-item>
      <v-tab-item value="custom">
        <v-btn small class="mr-3 mb-2" color="secondary" @click="addCustomScvSetting" :disabled="!canPost || newCustomScvSetting">
          項目追加
        </v-btn>
        <v-btn small @click="onClickOpenShowReorderModal()" :disabled="!canPut" class="mb-2">
          {{ $t(`text.scvSetting.scvSettingList.reorder`) }}
        </v-btn>
        <v-list v-if="customScvSettings.length > 0" nav class="item-inPageScroll_y pl-0 pt-0">
          <v-list-item-group v-model="computedSelectedScvSetting">
            <template v-for="(scvSetting, index) in customScvSettings">
              <v-list-item
                :value="scvSetting"
                :key="scvSetting.scvColumnId"
                ripple
              >
                <v-list-item-icon class="align-self-center mr-3">
                  <v-icon v-if="scvSetting.isScvIdentify">ic-key-S</v-icon>
                  <v-icon v-else-if="scvSetting.scvColumnChoiceSettingList.length >= 1" small>ic-turned-in-S</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="flex-grow-1">
                  <v-list-item-title class="body-1">
                    {{ scvSetting.scvLogicalColumnName }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="grey--text">
                    {{ scvSetting.scvPhysicalColumnName }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-show="scvSetting.isOrigin === false">
                  <v-btn
                    x-small
                    outlined
                    tile
                    @click.stop
                  >
                    {{ $t('btn.scvSetting.save')}}
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action>
                  <v-icon @click.stop="onClickDeleteButton(index)" :disabled="!canDelete">ic-close-M</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
            <v-list-item
              v-if="newCustomScvSetting"
              :value="newCustomScvSetting"
              :key="newCustomScvSetting.scvColumnId"
              ripple
            >
              <v-list-item-icon class="align-self-center mr-3" />
              <v-list-item-content class="flex-grow-1">
                <v-list-item-title class="body-1">
                  {{ newCustomScvSetting.scvLogicalColumnName }}
                </v-list-item-title>
                <v-list-item-subtitle class="grey--text">
                  {{ newCustomScvSetting.scvPhysicalColumnName }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  x-small
                  outlined
                  tile
                  @click.stop
                >
                  {{ $t('btn.scvSetting.save')}}
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-icon @click.stop="newCustomScvSetting = null">ic-close-M</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div v-else>
          <p>{{ $t(`text.scvSetting.scvSettingList.notExsitCustomHeading`)}}</p>
        </div>
      </v-tab-item>
    </v-tabs-items>

    <!-- 追加SCV項目削除ダイアログ -->
    <v-dialog
      v-model="showScvSettingDelete"
      width="400"
      overlay-color="#07070820"
      overlay-opacity="1"
    >
      <v-card class="reorder">
        <v-card-title></v-card-title>
        <v-card-text>
          {{ $t(`notify.check.delete`, [this.customScvSetting.scvLogicalColumnName]) }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="denial" @click="showScvSettingDelete = false">{{ $t(`btn.no`) }}</v-btn>
          <v-btn color="primary" @click="deleteCustomScvSetting" :disabled="!canDelete">
            {{ $t(`btn.yes`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 追加SCV項目並べ替えダイアログ -->
    <v-dialog
      v-model="showReorderModal"
      width="368"
      overlay-color="#07070820"
      overlay-opacity="1"
      persistent
    >
      <v-card class="reorder">
        <v-card-title class="pt-7 pb-5">{{ $t(`text.scvSetting.scvSettingList.reorder`) }}</v-card-title>
        <v-card-text>
          <v-list outlined nav class="dialog-scroll pa-3">
            <draggable v-model="customReorderScvSettings">
              <template v-for="(scvSetting) in customReorderScvSettings">
                <v-list-item
                  class="px-3"
                  :value="scvSetting"
                  :key="'reorder_' + scvSetting.scvColumnId"
                  color="primary"
                  ripple
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ scvSetting.scvLogicalColumnName }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon size="20px">ic-handle-S</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </draggable>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="denial" @click="onClickCloseShowReorderModal">{{ $t(`btn.cancel`) }}</v-btn>
          <v-btn color="primary" @click="onClickUpdateOrder">{{ $t(`btn.update`) }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// enum
import DATA_TYPES from '@/enum/DATA_TYPES'
// util
import notifyUtil from '@/utils/notifyUtil'
// plugin
import draggable from 'vuedraggable'

export default {
  name: 'scvSettingList',
  mixins: [
    DATA_TYPES,
    notifyUtil,
  ],
  components: {
    draggable,
  },
  props: {
    scvSettings: {
      type: Array,
    },
    selectedScvSetting: {
      type: Object,
    },
  },
  data () {
    return {
      newCustomScvSetting: null,
      selectedTab: 'default',
      defaultScvSettings: [],
      customScvSettings: [],
      customReorderScvSettings: [],
      customScvSetting: [],
      showReorderModal: false,
      showScvSettingDelete: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['canPost', 'canPut', 'canDelete']),
    computedSelectedScvSetting: {
      get () {
        return this.selectedScvSetting
      },
      set (newVal) {
        this.$emit('selectScvSetting', newVal)
      },
    },
  },
  watch: {
    scvSettings: {
      handler (newVal) {
        this.defaultScvSettings = []
        this.customScvSettings = []
        newVal.forEach(scvSetting => {
          if (scvSetting.isCustom) {
            this.customScvSettings.push(scvSetting)
          } else {
            this.defaultScvSettings.push(scvSetting)
          }
        })
      },
      deep: true,
    },
  },
  methods: {
    canSave (scvSetting) {
      if (scvSetting.scvColumnId) {
        return this.canPut
      } else {
        return this.canPost
      }
    },
    addCustomScvSetting () {
      this.newCustomScvSetting = {
        scvColumnId: null,
        scvColumnName: this.$t('text.scvSetting.scvSettingList.newScvColumnName'),
        scvColumnIndex: this.customScvSettings.length,
        dataType: this.DATA_TYPES.STRING.value,
        isCustom: true,
        scvColumnChoiceSettingList: [],
      }
      this.$emit('selectScvSetting', this.newCustomScvSetting)
    },
    deleteCustomScvSetting () {
      this.$emit('delete', this.customScvSetting.scvColumnId)
      this.showScvSettingDelete = false
    },
    onClickDeleteButton (index) {
      this.customScvSetting = this.customScvSettings[index]
      if (this.customScvSetting.scvColumnId) {
        this.showScvSettingDelete = true
      } else {
        this.$emit('reload')
      }
    },
    onClickUpdateOrder () {
      const scvIds = []
      this.customReorderScvSettings.forEach(scvSetting => scvIds.push(scvSetting.scvColumnId))
      this.$emit('updateOrder', scvIds)
      this.showReorderModal = false
    },
    onClickOpenShowReorderModal () {
      // 初期化
      this.customReorderScvSettings = []
      // 固定SCV項目のみピックアップ
      this.scvSettings.forEach((scvSetting) => {
        if (scvSetting.isCustom) this.customReorderScvSettings.push(scvSetting)
      })
      this.showReorderModal = true
    },
    onClickCloseShowReorderModal () {
      this.showReorderModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

#app .v-tabs-items {
  #default, #custom {
    @include theme(v-list) using ($integral-core-theme) {
      .v-list-item.v-list-item--active .v-icon::before {
        color: inherit;
      }
    }
  }
}

#app .reorder {
  @include theme(v-list) using ($integral-core-theme) {
    border-color: map-deep-get($integral-core-theme, 'dialog', 'border');
    .v-list-item {
      border-color: map-deep-get($integral-core-theme, 'dialog', 'border');
      color: map-deep-get($integral-core-theme, 'dialog', 'list-text') !important;
    }
  }
  .v-list-item {
    cursor: grab;
    min-height: 40px;
    .v-list-item__content {
      padding-top: 6px;
      padding-bottom: 6px;
      .v-list-item__title {
        line-height: 2;
        font-size: 14px;
      }
    }
    .v-list-item__icon {
      min-width: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
.item-inPageScroll_y {
  height: 70vh;
}
.dialog-scroll {
  overflow-y: scroll;
  height: 35vh;
}
</style>
