export default {
  text: {
    uploadResult: 'アップロード結果',
    update: '更新',
    createdAt: '作成日時',
    updatedAt: '更新日時',
    createdBy: '作成者',
    updatedBy: '更新者',
    sort: 'ソート基準',
    limits: '表示件数',
    noData: 'データが登録されていません。',
    noResults: '検索結果が見つかりません',
    delimiter: ': ',
    escapeDelimiter: '&#58;&nbsp;',
    unknownTotalUser: '総匿名客数',
    scvTotalUser: '総カスタマー数',
    asOfTime: '時点',
    unitHuman: '人',
    summary: 'サマリ',
    periodType: '期間指定',
    startDate: '開始日付',
    endDate: '終了日付',
    search: '検索',
    timeOnSite: '滞在時間',
    buy: '購入',
    pv: 'PV',
    id: 'ID',
    note: '説明',
    period: '期間',
    filter: '条件',
    userUnknown: '匿名客',
    userCustomer: '顧客',
    userScv: 'カスタマー',
    scv: 'SCV',
    displaySetting: '表示指標',
    beforeWeeklyRatio: '前週比',
    standard: '標準',
    custom: 'カスタム',
    baseDate: '基準日',
    dateInterval: '日付間隔',
    displayType: '表示切替',
    schedule: 'スケジュール',
    columnNum: '列目',
    master: '顧客マスタ',
    transaction: 'トランザクション',
    transactionSummary: 'トランザクションサマリ',
    transactionSummaryName: 'トランザクションサマリ名',
    bulkDeleteConfirm: '選択した項目をまとめて削除してよろしいですか？',
    headerMenu: {
      account: 'アカウント一覧',
      accountInfo: 'アカウント情報',
      manual: '各種手順書',
      changePassword: 'パスワード変更',
      releaseNote: 'リリース情報',
      logout: 'ログアウト',
    },
    itemCnt: {
      filtered: '件該当',
      all: '全',
      count: '件',
    },
    buildManual: {
      js: '計測JS',
      android: 'Android',
      ios: 'iOS',
    },
    accounts: {
      editColumnMenu: 'パスワード再発行',
      resultModal: {
        title_create: '以下の内容でユーザーを作成しました',
        title_recreate: '以下の内容でパスワードを再発行しました',
        user_name: 'ユーザー名',
        authority: '権限',
        mail_address: 'メールアドレス',
        password: 'パスワード',
        memo: '備考',
        annotation: 'パスワードはこのダイアログに１度しか表示されません',
      },
      filtering: {
        keyword: 'キーワード',
        role: '権限',
      },
      sort: {
        loginUserName: 'ユーザー名',
        emailAddress: 'メールアドレス',
        roleName: '権限',
        lastLoginDatetime: '最終ログイン日時',
        createdAt: '作成日時',
      },
      loginInfo: 'ログイン情報',
      loginUserName: 'ユーザー名',
      emailAddress: 'メールアドレス',
      roleName: '権限',
      password: 'パスワード',
      note: '備考',
    },
    scvUnion: {
      noCustomer: '統合対象のカスタマーが見つかりません',
      default: '主',
      detail: '詳細',
      alreadySet: 'すでに統合設定済みです',
    },
    dataSets: {
      dataSourceCount: '登録データソース数',
      master: '顧客マスタ',
      transaction: 'トランザクション',
    },
    dataSet: {
      title: 'データセット',
      dataSetName: 'データセット名',
      master: '顧客マスタ',
      transaction: 'トランザクション',
      schema: 'スキーマ',
      delete: '配下のデータソースを全て削除してからデータセットを削除してください',
    },
    dataSources: {
      domain: 'ドメイン',
      hash: 'ハッシュ値',
      bundle: 'バンドルID',
    },
    dataSource: {
      title: 'データソース',
      dataSetName: 'データセット名',
      dataSourceName: 'データソース名',
      record: 'レコード',
      schema: 'スキーマ',
      connector: 'コネクタ',
      history: 'アップロード履歴',
      masterLinkRule: '顧客マスタ連結設定',
      transactionLinkRule: 'トランザクション連結設定',
      deleteLinkRule: '連結設定削除',
      importHistory: 'アップロード履歴',
      uploadResult: 'アップロード結果',
      domain: 'ドメイン',
      bundle: 'バンドルID',
      measureDomain: '計測ドメイン',
      noRecord: 'データが取り込まれていません',
      apiConnectorNeedCreateRule: '先に、顧客マスタ連結設定で Web/SDK 計測連結カラムの設定をする必要があります',
      recordDataImportDelay: '取込設定登録後、実際にDBに反映されるまでには、ファイルサイズ次第ですが数時間要する場合がございます',
    },
    dataSourceImportHistories: {
      backToDataSet: 'データセット一覧へ戻る',
      filtering: {
        status: 'アップロード結果',
      },
      sort: {
        key1: 'アップロード開始時間',
        key2: 'アップロード終了時間',
        key3: 'データソース名',
        key4: 'アップロード種別',
        key5: 'アップロード結果',
      },
    },
    dataSourceLinkSetting: {
      linkSettingHint: 'お客様が登録するマスタデータ(CSVファイル or API経由で取り込むマスタデータ)と、INTEGRAL-COREが独自に計測しているマスタデータ(Web計測、iOS、Android)を紐付ける機能になります。<br>Web計測ではユーザを識別するための情報を計測タグから受け取りますが、識別情報は事前にINTEGRAL-COREに登録しておく必要があります。その際に、本機能を利用します。',
      masterLinkSettingHint: '複数のマスタを結合することができます',
      transactionLinkSettingHint: 'SCVとして生成されるユーザに対して<br> トランザクションのサマリを<br> 紐付けることができます',
      webLinkSettingHint: '「特定のユーザ(顧客フラグメント)であるか？」と<br> 判断するための顧客フラグメント識別情報を<br> ユーザが追加できます',
    },
    masterLinkHistories: {
      filtering: {
        result: 'ステータス',
        execType: '実行種別',
      },
      sort: {
        key1: '実行日時',
        key2: '終了日時',
        key3: '実行種別',
        key4: 'ステータス',
      },
    },
    jobHistories: {
      filtering: {
        name: 'ジョブ名',
        status: '結果',
      },
      sort: {
        jobName: 'ジョブ名',
        createDatetime: '起動日時',
        startDatetime: '処理開始日時',
        endDatetime: '終了日時',
        jobResult: '結果',
      },
      detail: '詳細',
    },
    jobs: {
      filtering: {
        name: 'ジョブ名',
        types: 'スケジュール種別',
        enabled: 'ステータス',
        lastStatus: '最終結果',
        enabledJob: '有効',
        disabledJob: '無効',
        createdUserName: '作成者',
        updatedUserName: '更新者',
      },
      sort: {
        jobName: 'ジョブ名',
        recurringType: 'スケジュール',
        enabled: 'ステータス',
        updatedAt: '更新日時',
        lastExecStatus: '最終結果',
        lastExecTime: '最終ジョブ終了日時',
      },
    },
    jobSetting: {
      remind: '現在の入力内容を全て破棄してジョブ一覧画面へ遷移します。<br>よろしいですか？',
      taskListHelp: 'タスクは上から順に実行されます',
      taskStart: 'START',
      taskEnd: 'END',
      newTask: '新規タスク',
      ioType: '入出力',
      sqlType: 'SQL',
    },
    sites: {
      filtering: {
        name: '計測対象名',
        domain: 'ドメイン/バンドルID',
        types: '計測対象種別',
        mDomain: '計測ドメイン',
      },
      sort: {
        key1: '作成日時',
        key2: '更新日時',
        key3: '計測対象名',
        key4: '計測対象種別',
      },
      dialog: {
        jsTitle: 'タグ設定',
        sdkTitle: 'SDK用JSON',
      },
      menu: {
        cvSetting: 'CV設定',
        tagSetting: 'タグ設定',
      },
    },
    summaries: {
      session: 'セッション',
      sessionTimeOnSite: '平均滞在時間',
      pvToSession: 'PV/セッション',
    },
    userDetail: {
      userSegment: '所属セグメント',
      noUserSegment: 'セグメントに所属していません',
      unknownIdentifyInfo: '匿名客識別情報',
      customerIdentifyInfo: '顧客識別情報',
      unknownIdentify: '匿名客識別子',
      customerIdentify: '顧客識別子',
      unionedScv: '統合カスタマー',
      scvIdentify: 'SCV識別子',
      unintegrateScv: '統合カスタマーの解除',
      integrateScvNextTerm: 'カスタマー統合設定が存在します。次回のSCV構築処理時に統合されます。',
      unintegrateScvNextTerm: '統合済みカスタマーの解除を受け付けました。次回のSCV構築処理時に統合が解除されます。',
      firstAccessDate: '初回アクセス日',
      lastAccessDate: '最終アクセス日',
      customerPromotionDate: '顧客成立日',
      customerInfo: '会員情報',
      noCustomerInfo: '会員情報が登録されていません',
      noFragments: '顧客マスタに登録されていません',
      noTransactionSummary: '集計済みトランザクションサマリが存在しません',
      noDatasetRows: 'データが有りません',
      noSelectedDatasetRow: '表示するデータを選択してください',
      actionHistoryTitle: '行動履歴',
      noActionHistories: 'このユーザーの行動履歴が登録されていません',
      pvCnt: 'PV数',
      actionHistory: '行動履歴',
      actionHistoryBase: '行動履歴基本情報',
      actionHistoryDetail: '行動履歴詳細',
      historyItems: {
        key1: 'セッション開始日時',
        key2: '滞在時間',
        key3: 'PV',
      },
      achievementPeriod: '表示期間を選択してください',
      customScvColumn: 'カスタムSCV項目',
      confirmValueOverwrite: '入力内容で項目を上書きしますか？',
      scvColumnSource: 'データソース',
      updatedScvColumn: '項目設定が変更されたため、次回SCV構築後に表示されます',
      showSummary: 'サマリを表示',
    },
    scvs: {
      filtering: {
        target: '検索対象項目',
        filterValue: '検索条件',
        filterValuePlaceholder: 'テキストを入力してください',
        set: '条件を設定',
        setting: '検索条件設定',
        select: '選択してください',
      },
      info: {
        search: 'SCV設定が未設定の場合は検索できません',
      },
      union: {
        header: '統合',
        move: '統合画面へ',
      },
      columnDisplaySetting: '表示項目設定',
      noResult: '表示する検索結果が存在しません',
      default: '検索対象項目と検索条件を入力して検索してください',
    },
    scvSetting: {
      scvSetting: 'SCV項目',
      scvIdentify: 'SCV識別子',
      scvSync: 'リアルタイム利用',
      setScvIdentify: 'この項目をSCV識別子に設定する',
      scvIdentifyConfirm: 'SCV識別子を変更した場合、既存のカスタマーとの連続性を保てない可能性があります。本当に更新しますか？',
      unsaved: '未保存のSCV項目があります。<br>全て保存してからSCV作成を実行してください。',
      scvSettingList: {
        title: '項目一覧',
        defaultHeading: 'デフォルト',
        customHeading: 'カスタム',
        notExsitCustomHeading: 'カスタムSCV項目が登録されていません',
        reorder: '並べ替え',
        newScvColumnName: '新規SCV項目',
      },
      scvSettingDetail: {
        title: '項目設定',
        hint: '&#x203B;SCVの値は上から順に優先されます',
        unselected: 'SCV項目一覧より設定するカラムを選択してください',
        deletedColumn: 'マスタ項目削除済み',
      },
      scvSettingMasterList: {
        title: 'データセット一覧',
        heading: 'マスタ項目',
        notExistMasterHeader: 'データセットにデータが登録されていません',
        notExistMaster: 'データセットを選択してください',
      },
    },
    segments: {
      lastUpdated: '最終更新時刻',
      filtering: {
        name: 'セグメント名',
        sType: 'セグメント種別',
        userType: 'ユーザー種別',
      },
      sort: {
        key1: '作成日時(ID)',
        key2: '更新日時',
        key3: 'セグメント名',
      },
      tab: {
        user: 'ユーザー',
        summary: '実績',
        job: '関連ジョブ',
        exportScript: 'エクスポートスクリプト',
        userAndFilter: 'ユーザー数&nbsp;&#047;&nbsp;フィルタ条件',
        csvDownload: 'CSVダウンロード',
      },
    },
    segmentDetail: {
      selectSegment: 'セグメント選択',
      unselected: 'セグメントを選択してください',
      changePlan: {
        former: 'このセグメントを削除するとセグメント数が',
        latter: '件となるため、料金プランが自動的に変更されます',
      },
      downLoadNotice: 'ただし、Webトラッキングによるユーザー情報が紐付く場合に限る',
      noUser: 'セグメントにユーザーが紐付けられていません',
      lastAccess: '最終アクセス',
      noJob: 'セグメントに紐付けられたジョブは存在しません',
      lastExecStatus: '最終結果',
      io: 'INPUT/OUTPUT',
      sql: 'SQL',
    },
    segmentExportScriptHistory: {
      execStatus: '結果',
      sort: {
        startDatetime: '処理開始日時',
        endDatetime: '終了日時',
        execStatus: '結果',
      },
    },
    segmentExportScripts: {
      name: 'スクリプト名',
      segmentName: 'セグメント名',
      recurringTypes: 'スケジュール種別',
      scriptStatus: 'ステータス',
      lastExecStatus: '最終結果',
      executable: '有効',
      unexecutable: '無効',
      createdUserName: '作成者',
      updatedUserName: '更新者',
      execHistory: '実行履歴',
      sort: {
        scriptStatus: 'ステータス',
        createdAt: '作成日時',
        updatedAt: '更新日時',
      },
    },
    segmentExportScriptSetting: {
      name: 'スクリプト名',
      executable: '有効',
      unexecutable: '無効',
      createdUserName: '作成者',
      updatedUserName: '更新者',
      recurringSetting: 'スケジュール設定',
      recurringTypes: 'スケジュール種別',
      targetSegment: 'エクスポート対象セグメント',
      segmentName: 'セグメント名',
      script: 'スクリプト',
      showTemplate: 'テンプレート参照',
    },
    segmentSetting: {
      search: 'ユーザー検索',
      searchQuery: '検索クエリ',
      customSegmentFilter: '構築種別が「カスタム」の場合は<br>この画面からフィルタ条件を設定できません',
      filterSetting: 'フィルタリング対象の設定',
      type: 'タイプ',
      filterRule: 'フィルタリング対象と条件',
      operatorsCannotMixed: 'この条件にANDとORを混在することはできません',
      batchToggle: '一括変換',
      internalFilter: '詳細指定',
      periodSetting: '対象期間・曜日・日付',
      execWeekly: '曜日指定',
      transactionSummarySetting: 'トランザクションサマリ設定',
      announce: '検索には時間がかかる場合があります',
      result: {
        title: '検索結果',
        noUser: '表示する検索結果が存在しません',
        summary: {
          title: '実績',
          unselected: '表示する指標が選択されていません',
        },
        user: {
          title: 'ユーザー',
          subHead: '最大10件',
          noUser: '該当するユーザーが存在しません',
        },
        userDetail: {
          title: 'ユーザー詳細',
          unselected: 'ユーザーが選択されていません',
          userInfo: {
            column1: '最終アクセス日時',
            column2: '初回アクセス日時',
            column3: '顧客成立日',
          },
        },
      },
    },
    masterImportHistories: {
      filtering: {
        name: '顧客マスタ名',
        importType: 'アップロード種別',
        importResult: 'アップロード結果',
      },
      sort: {
        key1: 'アップロード開始時間',
        key2: 'アップロード終了時間',
        key3: 'マスタ名',
        key4: 'アップロード種別',
        key5: 'アップロード結果',
      },
    },
    masters: {
      filtering: {
        name: '顧客マスタ名',
      },
      sort: {
        key1: '作成日',
        key2: '更新日',
        key3: '顧客マスタ名',
      },
      s3path: {
        replacement: '差し替え取り込み用S3バケット',
        difference: '差分取り込み用S3バケット',
      },
      remind: '※この顧客マスタが設定されている連結ルールも削除されます',
      noData: 'アップロード<br>データなし',
    },
    masterLinkSetting: {
      remind: {
        execute: '設定を保存し、連携処理を実行します。',
        cancel: '現在の入力内容を全て破棄して顧客マスタ一覧画面へ遷移します。<br>よろしいですか？',
      },
      masterName: '顧客マスタ名',
      columnName: 'カラム名',
      ruleSave: '変更した連結ルールを保存するためには、「設定を保存」or「保存&連結実行」ボタンをクリックしてください。',
    },
    result: {
      processing: '実行中',
      success: '成功',
      fail: '失敗',
    },
    transactionSummaries: {
      filtering: {
        keyword: 'キーワード',
        periodType: '期間',
        transactionSummaryType: 'サマリ種別',
      },
      sort: {
        transactionSummaryName: 'サマリ名',
        transactionSummaryType: 'サマリ種別',
        updatedBy: '更新者',
        updatedAt: '更新日時',
      },
      list: {
        col2: {
          type: '種別',
          transactionLength: '対応データソース数',
        },
        col3: {
          periodType: '集計期間',
          beforeDayPrefix: '直近',
          beforeDaySuffix: '日間',
          includeToday: '(当日含む)',
          excludeToday: '(当日含まず)',
          filterTooltip: '条件を表示',
          filterSummaryColumn: '対象カラム',
        },
      },
    },
  },
}
