export default {
  form: {
    companyAccountId: '企業アカウントID',
    note: '説明',
    mailAddress: 'メールアドレス',
    password: 'パスワード',
    datetime: '日時',
    date: '日付',
    time: '時刻',
    dayOfWeek: '曜日',
    dayOfMonth: '実行日',
    dataType: 'データ型',
    selectAll: '全選択',
    endpoint: 'エンドポイント',
    region: 'リージョン',
    accessKey: 'アクセスキー',
    secretKey: 'シークレットキー',
    passphrase: 'パスフレーズ',
    segment: 'セグメント',
    hint: {
      datetime: '日時を選択してください',
      time: '時刻を選択してください',
      dayOfWeek: '実行曜日を選択してください',
      dayOfMonth: '実行日を選択してください',
      enabledJob: '有効にするとスケジュール設定で指定された日時にジョブが実行されます。',
      sqlEditor: '1つのタスクにつき、入力できるクエリは1件です',
      displaySummarySetting: '表示するサマリ指標を選択してください',
    },
    accounts: {
      title: 'アカウント設定',
      loginUserName: '名前',
      placeholder: '入力してください',
      note: '備考',
    },
    accountRole: {
      note: '備考',
    },
    changePassword: {
      currentpassword: '現在のパスワード',
      newPassword: '新規パスワード',
      passwordFormat: '英数字（a~z,A~Z,0~9）と記号を含めた10文字以上で設定してください',
      confirmNewPassword: '新規パスワード(確認用)',
      remind: '初期パスワードを変更してください。',
    },
    dataSet: {
      add: 'データセット新規作成',
      edit: 'データセット編集',
      name: 'データセット名',
      namePlaceholder: 'データセット名を入力してください',
      note: '説明',
    },
    dataSource: {
      add: 'データソース追加',
      edit: 'データソース編集',
      name: 'データソース名',
      namePlaceholder: 'データソース名を入力してください',
      connector: 'コネクタ設定',
      columnName: 'カラム名',
      physicalName: 'カラム物理名',
      uploadTitleMaster: '顧客マスタアップロード',
      uploadTitleTransaction: 'トランザクションアップロード',
      note: '説明',
    },
    jobSetting: {
      job: {
        title: 'ジョブ設定',
        name: 'ジョブ名',
        schedule: 'スケジュール',
        enabled: '有効',
        execTime: '実行時刻',
        execDateTime: '実行日時',
        execWeekly: '曜日指定',
        execMonthly: '日付指定',
        status: 'ステータス',
      },
      jobTask: {
        title: 'タスク設定',
        name: 'タスク名',
        namePlaceholder: 'タスク名を入力してください',
        types: 'タスク種別',
        inputTypes: 'インプット種別',
        outputTypes: 'アウトプット種別',
        sqlEditor: 'SQLエディタ',
        query: 'クエリ',
        host: 'ホスト名',
        port: 'ポート番号',
        user: 'ユーザー名',
        schema: 'スキーマ名(任意)',
        database: 'データベース名',
        table: 'テーブル名',
        bucket: 'バケット名',
        pathPrefix: 'ディレクトリパス',
        file: 'ファイル名',
        authJsonKey: '登録済み認証キーJSON名',
        uploadedJsonkey: '選択済み認証キーJSON名',
        jsonKey: '認証キーJSON',
        dropArea: 'ここにJSONファイルをドロップ<br>または<br>',
        outputMode: '出力モード',
        mergeKey: 'マージキー',
        fileNameSuffix: 'ファイル名末尾指定',
        header: 'ヘッダ行なし',
      },
    },
    segmentExportScript: {
      segmentExportScriptName: 'スクリプト名',
      recurringSetting: 'スケジュール設定',
      recurringTypes: 'スケジュール種別',
      targetSegment: 'エクスポート対象セグメント',
      script: 'スクリプト',
    },
    segmentSetting: {
      info: 'セグメント情報',
      buildType: '構築種別',
      segment: {
        title: 'セグメント条件',
        userType: '検索対象',
        customPeriodDate: '開始日付または終了日付',
        name: 'セグメント名',
        namePlaceholder: 'セグメント名を入力してください',
      },
      segmentFilter: {
        title: 'フィルタ条件',
        page: 'URLから指定',
        column: {
          target: 'フィルタリング対象',
          type: '種別',
          value: '条件値',
          operator: '一致条件',
          internalColumn: '絞り込み条件',
          selectPlaceholder: '選択してください',
          inputPlaceholder: '入力してください',
        },
      },
      ruleForm: {
        attribute: '属性',
        webTransaction: 'Web行動',
        transactionSummary: 'トランザクションサマリ',
      },
    },
    sites: {
      title: '計測対象設定',
      siteType: '計測対象種別',
      siteName: 'サイト名',
      domain: 'ドメイン',
      mDomain: '計測ドメイン',
      appName: 'アプリ名',
      bundle: 'バンドルID',
    },
    masters: {
      title: '顧客マスタ設定',
      detailTitle: '顧客マスタ詳細',
      uploadTitle: '顧客マスタアップロード',
      masterName: '顧客マスタ名',
      masterIdentify: {
        prefix: 'マスタ内主キー',
        suffix: '列目',
      },
      dropArea: 'ここにCSVファイルをドロップ',
      or: 'または',
      fileName: 'アップロードファイル名',
      logicalChecker: '論理名含む',
      identifyTable: '顧客識別用テーブル',
      importType: {
        replacement: '差し替え',
        difference: '差分取り込み',
      },
      hint: {
        select: '顧客マスタを選択してください',
      },
    },
    masterLinkSetting: {
      title: '顧客マスタ連結設定',
      enable: '顧客マスタ連結自動実行',
      masterLinkRuleSetting: {
        title: '連結ルール設定',
        ruleName: '連結ルール名',
      },
      masterLinkRuleList: {
        title: '連結ルール一覧',
        enable: '連結ルール適応',
      },
    },
    scvSetting: {
      scvColumnName: 'カラム名称',
      dataType: 'データ型',
      selectableMaster: '設定可能データセット',
    },
    userDetail: {
      unintegrateScv: 'このカスタマーの統合設定を解除します。解除後は、統合されていたカスタマーが個別のカスタマーとして独立します。',
    },
    totp: {
      oneTimePassword: '認証コード',
      oneTimePasswordPlaceholder: '6桁の認証コード',
    },
    transactionSummary: {
      transactionName: 'トランザクションサマリ名',
      transactionNamePlaceholder: 'トランザクションサマリ名を入力してください',
      transactionSummaryType: 'トランザクションサマリ種別',
      transactionSummaryDataType: 'データ型',
      periodType: '集計期間',
      beforeDay: '開始期間',
      summaryTarget: '集計対象',
      summaryDataSource: '対象データソース',
      summaryColumn: '対象カラム',
      summaryFilters: '集計条件',
      filterColumn: '対象カラム',
      filterOperator: '一致条件',
      filterValue: '条件値',
      save: 'トランザクションサマリ設定を保存します',
    },
  },
}
