<template>
  <div>
    <v-card-text>
      <h3 class="lead-line">権限名</h3>
      <v-card outlined tile>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="8">
                <!-- 権限名 -->
                <v-text-field
                  :value="accountRoleSetting.roleName"
                  @input="$emit('changeRoleName', $event)"
                  placeholder="権限名を入力してください"
                  name="roleName"
                  data-vv-as="権限名"
                  v-validate="'required|max:255'"
                  :error-messages="errors.collect('roleName')" />
                <!-- 備考 -->
                <v-textarea
                  :value="accountRoleSetting.note"
                  @input="$emit('changeNote', $event)"
                  :label="$t(`form.accountRole.note`)"
                  outlined
                  name="note"
                  :data-vv-as="$t(`form.accountRole.note`)"
                  v-validate="'max:1024'"
                  :error-messages="errors.collect('note')" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <h4 class="mt-6">権限</h4>
      <v-card outlined tile>
        <v-card-text>
          <v-data-table
            :headers="roleHeaders"
            hide-default-footer
            :items="accountRoleSetting.authorityList"
          >
            <template #item.action="{ item }">
              <v-row class="align-center">
                  <v-col>
                    <v-checkbox
                      v-model="item.all"
                      class="mt-0"
                      :value="item.getAuthorized && item.postAuthorized && item.putAuthorized && item.deleteAuthorized"
                      color="default"
                      hide-details
                      @change="onClickSelectAll(item)"
                    />
                  </v-col>
              </v-row>
            </template>
            <template #item.read="{ item }">
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="item.getAuthorized"
                    class="mt-0"
                    color="default"
                    hide-details
                  />
                </v-col>
              </v-row>
            </template>
            <template #item.create="{ item }">
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="item.postAuthorized"
                    class="mt-0"
                    color="default"
                    hide-details
                    :disabled="!item.getAuthorized"
                  />
                </v-col>
              </v-row>
            </template>
            <template #item.update="{ item }">
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="item.putAuthorized"
                    class="mt-0"
                    color="default"
                    hide-details
                    :disabled="!item.getAuthorized"
                  />
                </v-col>
              </v-row>
            </template>
            <template #item.delete="{ item }">
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="item.deleteAuthorized"
                    class="mt-0"
                    color="default"
                    hide-details
                    :disabled="!item.getAuthorized"
                  />
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <!-- キャンセル -->
      <v-btn large color="denial" :to="{ name:'AccountRoles' }">
        {{ $t(`btn.cancel`) }}
      </v-btn>
      <!-- 作成/更新 -->
      <v-btn large color="primary" @click="formValidate()" :disabled="!canClickRequestButton()" :loading="isLoading">
        {{ $t('btn.save') }}
      </v-btn>
      <v-spacer />
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'AccountRoleSettingForm',
  mixins: [
    notifyUtil,
  ],
  props: {
    accountRoleSetting: {
      type: Object,
    },
    buttonType: {
      type: String,
    },
    isLoading: {
      type: Boolean,
    },
    roleHeaders: {
      type: Array,
    },
    roleList: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters('auth', ['canPost', 'canPut']),
  },
  methods: {
    canClickRequestButton () {
      if ((this.buttonType === 'POST' && this.canPost) || (this.buttonType === 'PUT' && this.canPut)) return true
      return false
    },
    switchLabel (buttonType) {
      if (buttonType === 'POST') return this.$t('btn.create')
      if (buttonType === 'PUT') return this.$t('btn.update')
      return ''
    },
    onClickSelectAll (item) {
      if (item.getAuthorized && item.postAuthorized && item.putAuthorized && item.deleteAuthorized) {
        item.getAuthorized = false
        item.postAuthorized = false
        item.putAuthorized = false
        item.deleteAuthorized = false
      } else {
        item.getAuthorized = true
        item.postAuthorized = true
        item.putAuthorized = true
        item.deleteAuthorized = true
      }
    },
    formValidate () {
      this.$validator.validateAll().then(result => {
        // バリデーションエラーがあればリクエストは送信しない
        if (!result) {
          for (const error of this.errors.items) {
            this.notifyErrorMessage(error.msg)
          }
          return
        }
        this.accountRoleSetting.authorityList.forEach(authority => {
          if (!authority.getAuthorized) {
            authority.postAuthorized = false
            authority.putAuthorized = false
            authority.deleteAuthorized = false
          }
        })
        this.$emit('request')
      })
    },
  },
}
</script>
