export default {
  data () {
    return {
      SEGMENT_SUMMARIES: {
        NEW_USER: { text: '新規ユーザー', key: 'newUser', value: 1 },
        SESSION: { text: 'セッション', key: 'session', value: 2 },
        SESSION_AVG: { text: '平均セッション', key: 'sessionAvg', value: 3 },
        PV_TO_SESSION: { text: 'PV/セッション', key: 'pvToSession', value: 4 },
        SALES: { text: '総売上', key: 'sales', value: 8 },
        ARPU: { text: 'ARPU', key: 'arpu', value: 10 },
        TIME_ON_SITE: { text: '平均滞在時間', key: 'timeOnSite', value: 11 },
      },
    }
  },
}
