<template>
  <v-card tile elevation="0">
    <information-bar v-if="isNotCalledByOtherPages">
      <template #left>
        <v-btn :to="previousRoute">
          {{ $t(`btn.backToList`) }}
        </v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <v-row>
        <v-col cols="3" class="pr-1">
          <user-base-info
            userType="scv"
            :loading="loadingUserScvDetail || loadingScvColumnSources"
            :userDetail="scvDetail"
            :scvSettings="scvSettings"
            :scvChildren="scvChildren"
            :hasScvUnionSetting="hasScvUnionSetting"
            :scvDefaultColumnSources="scvDefaultColumnSources"
            @overwrite="overwriteScvColumn"
            @unintegrate="unintegrate"
          />
        </v-col>
        <v-col cols="9">
          <v-progress-linear v-if="loadingUserScvDetail" indeterminate color="primary" />
          <default-scv-columns
            class="mb-4"
            userType="scv"
            :loading="loadingUserScvDetail || loadingScvColumnSources"
            :userDetail="scvDetail"
            :scvSettings="scvSettings"
            :scvDefaultColumnSources="scvDefaultColumnSources"
            @overwrite="overwriteScvColumn"
          />
          <v-tabs v-model="selectedTab">
            <v-tab href="#custom">{{ $t('text.userDetail.customScvColumn')}}</v-tab>
            <v-tab href="#action">{{ $t('text.userDetail.actionHistory')}}</v-tab>
            <v-tab href="#master">{{ $t('text.master') }}</v-tab>
            <v-tab href="#transactionSummary">{{ $t('text.transactionSummary') }}</v-tab>
            <v-tab href="#segment">所属セグメント({{(scvDetail.scvDetailSegments || {}).length}})</v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTab" class="px-0">
            <v-tab-item value="custom">
              <custom-scv-columns
                :customScvColumns="customScvColumns"
                :scvCustomColumnSources="scvCustomColumnSources"
                :total="this.customScvColumns.length || 0"
                :loading="loadingUserScvDetail || loadingScvColumnSources"
                @overwrite="overwriteScvColumn"
              />
            </v-tab-item>
            <v-tab-item value="action">
              <period-filter-select
                :periodFilter="{ periodType: periodType, startDate: startDate, endDate: endDate }"
                :isSearching="isSearching"
                :showSummary="showSummary"
                @search="filterdSearch"
                @switchSummary="val => showSummary = val"
              />
              <action-history
                :action-histories="actionHistories"
                :is-action-history-searching="loadingActionHistory"
                :paging="actionHistoryPaging"
                :page="page"
                @changePage="handleChangeHistoryPage"
              />
              <user-summary
                :loading="loadingUserSummary"
                :summaries="summaries"
                :showSummary="showSummary"
                @closeSummary="showSummary = false"
              />
            </v-tab-item>
            <v-tab-item value="master">
              <fragments :dataSetRows="customerFragments" />
            </v-tab-item>
            <v-tab-item value="transactionSummary">
              <user-transaction-summary :scvTransactionSettingList="scvDetail.scvTransactionSettingDtoList" />
            </v-tab-item>
            <v-tab-item value="segment">
              <user-segments :segments="scvDetail.scvDetailSegments" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// components
import actionHistory from '@/components/UserDetail/actionHistory'
import customScvColumns from '@/components/UserDetail/customScvColumns'
import defaultScvColumns from '@/components/UserDetail/defaultScvColumns'
import fragments from '@/components/UserDetail/fragments'
import periodFilterSelect from '@/components/UserDetail/periodFilterSelect'
import userBaseInfo from '@/components/UserDetail/userBaseInfo'
import userSegments from '@/components/UserDetail/userSegments'
import userSummary from '@/components/UserDetail/userSummary'
import userTransactionSummary from '@/components/UserDetail/userTransactionSummary'
// enums
import PERIOD_TYPES from '@/enum/PERIOD_TYPES'
import USER_TYPES from '@/enum/USER_TYPES'
// utils
import displayConverter from '@/utils/displayConverter'
import momentUtil from '@/utils/momentUtil'
import notifyUtil from '@/utils/notifyUtil'

const ACTION_HISTORY_PAGE = 1
const ACTION_HISTORY_INDEX = 1
const ACTION_HISTORY_LIMIT = 5

export default {
  name: 'UserScvDetail',
  props: {
    scvId: {
      type: String,
    },
  },
  mixins: [
    PERIOD_TYPES,
    USER_TYPES,
    displayConverter,
    momentUtil,
    notifyUtil,
  ],
  components: {
    actionHistory,
    customScvColumns,
    defaultScvColumns,
    fragments,
    periodFilterSelect,
    userBaseInfo,
    userSegments,
    userSummary,
    userTransactionSummary,
  },
  data () {
    return {
      scvDetail: {},
      selectedTab: 'custom',
      summaries: {
        session: {
          label: this.$t('text.summaries.session'),
          value: 0,
          chipValue: null,
        },
        pvToSession: {
          label: this.$t('text.summaries.pvToSession'),
          value: 0,
          chipValue: null,
        },
        sessionTimeOnSite: {
          label: this.$t('text.summaries.sessionTimeOnSite'),
          value: '0秒',
          chipValue: null,
        },
      },
      periodType: 1,
      startDate: '',
      endDate: '',
      // 行動履歴
      actionHistories: [],
      // 行動履歴ページング
      actionHistoryPaging: {
        index: ACTION_HISTORY_INDEX,
        limit: ACTION_HISTORY_LIMIT,
        totalCount: 0,
        filteredTotalCount: 0,
      },
      page: ACTION_HISTORY_PAGE,
      customerFragments: null,
      scvChildren: [],
      hasScvUnionSetting: false,
      scvSettings: [],
      scvDefaultColumnSources: [],
      scvCustomColumnSources: null,
      showSummary: false,
    }
  },
  computed: {
    isNotCalledByOtherPages () {
      return this.$route.name === 'UserScvDetail'
    },
    loadingUserScvDetail: {
      get () {
        return this.$store.state.userScvDetail.loadingUserScvDetail
      },
      set (newVal) {
        this.$store.dispatch('userScvDetail/updateLoadingUserScvDetail', newVal)
      },
    },
    customScvColumns () {
      const scvDetailCopy = JSON.parse(JSON.stringify(this.scvDetail))
      if (!scvDetailCopy.scvCustomColumns) return []
      // API が返却するカスタマー詳細 (userScvDetail) に columnIndex が入ってない
      // だが、columnIndex順に並べたい
      // よって、別で API から取得する SCV項目一覧 scvSettings から columnIndex を入れないといけない
      const scvCustomColumnsWithIndex = this.addScvCustomColumnIndex(scvDetailCopy.scvCustomColumns)
      scvCustomColumnsWithIndex.sort(function (a, b) {
        return (a.scvColumnIndex - b.scvColumnIndex)
      })
      return scvCustomColumnsWithIndex.map(scvColumn => {
        return { scvColumnId: scvColumn.scvColumnId, scvColumnName: scvColumn.scvColumnLogicalName, scvColumnValue: scvColumn.scvColumnValue, isUpdated: scvColumn.updated, scvColumnIndex: scvColumn.scvColumnIndex }
      })
    },
    isSearching () {
      return this.loadingUserSummary || this.loadingActionHistory
    },
    userId () {
      if (this.isNotCalledByOtherPages) {
        return this.$route.params.scvId
      } else {
        return this.scvId
      }
    },
    previousRoute () {
      const from = this.$store.state.route.from
      // 前の画面がない (直接流入・リロードなど) 場合はユーザ一覧へ戻す
      if (from === undefined || from.name === null) {
        return { name: 'scvs' }
      } else if (from.name === 'UserScvDetail') {
        // 親SCVへのリダイレクトがあった場合は2つ前を特定できないのでユーザ一覧へ戻す
        return { name: 'scvs' }
      }
      // 前の画面があればその画面に返す
      return from
    },
    loadingScvColumnSources: {
      get () {
        return this.$store.state.userScvDetail.loadingUserScvDetail
      },
      set (newVal) {
        this.$store.dispatch('userScvDetail/updateLoadingScvColumnSources', newVal)
      },
    },
    loadingUserSummary: {
      get () {
        return this.$store.state.segmentSearch.loadingUserSummary
      },
      set (newVal) {
        this.$store.dispatch('segmentSearch/updateLoadingUserSummary', newVal)
      },
    },
    loadingActionHistory: {
      get () {
        return this.$store.state.userActionHistory.loadingActionHistory
      },
      set (newVal) {
        this.$store.dispatch('userActionHistory/updateLoadingActionHistory', newVal)
      },
    },
  },
  watch: {
    scvId () {
      this.getUserScvDetail()
    },
    '$route.params.scvId' () {
      this.getUserScvDetail()
    },
  },
  created () {
    this.loadingScvColumnSources = true
    this.loadingUserSummary = true
    this.loadingActionHistory = true
    this.loadingUserScvDetail = true
    // SCV項目
    this.$store.dispatch('scvSettings/getScvSettings')
      .then((res) => {
        this.scvSettings = JSON.parse(JSON.stringify(res.scvSettings))
      })
      .catch((err) => {
        this.handleErrorResponse(err)
      })
    this.getUserScvDetail()
  },
  methods: {
    getUserScvDetail () {
      // SCV詳細
      this.$store.dispatch('userScvDetail/getUserScvDetail', this.userId)
        .then((res) => {
          this.scvDetail = res.userScvDetail
          // 親SCVがあって、自身が親ではない場合はリダイレクトするので他のAPIは叩かない
          if (this.scvDetail && this.scvDetail.scvId && (!this.scvDetail.parentScvId || this.scvDetail.parentScvId === this.scvDetail.scvId)) {
            this.search()
          }
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    /**
     * 全リソース取得
     */
    search () {
      // バリデーション（期間指定：日付指定・開始日、終了日が入力されている場合）
      if (this.periodType === this.PERIOD_TYPES.CUSTOM.value && (this.startDate && this.endDate) && (this.startDate > this.endDate)) {
        this.notifyErrorMessage(this.$t('notify.error.form.invalidPeriod'))
        return
      }

      // 構成データセット一覧を取得
      this.fetchCustomerFragments()
      // SCV項目ごとの採択候補を取得
      this.fetchScvColumnSources()
      // 子カスタマーの一覧を取得
      this.fetchScvChildren()
      // SCV統合設定の有無を確認
      this.fetchScvUnionSetting()
      // サマリと行動履歴取得
      this.actionSearch()
    },
    /**
     * サマリと行動履歴取得
     */
    actionSearch () {
      // 実績取得
      this.fetchSummaries()
      // 行動履歴取得
      this.fetchHistories()
    },
    /**
     * 期間指定の設定
     * @param  {Object} filterParams 設定期間
     */
    filterdSearch (filterParams) {
      this.periodType = filterParams.periodType
      this.startDate = filterParams.startDate
      this.endDate = filterParams.endDate
      this.actionSearch()
    },
    /**
     * サマリの値を初期化
     */
    initializeSummaries () {
      for (const key of Object.keys(this.summaries)) {
        this.summaries[key].value = 0
      }
    },
    /**
     * サマリの値を格納
     */
    fetchSummaries () {
      // 初期化
      this.initializeSummaries()

      // 実績取得
      this.$store.dispatch('segmentSearch/searchUserSummary', {
        userId: this.userId,
        userType: this.USER_TYPES.SCV.value,
        periodType: this.periodType,
        startDate: this.startDate,
        endDate: this.endDate,
        baseDate: this.momentUtil.getCurrentDateIncludeHyphen(),
      }).then((res) => {
        const data = res.data.data
        this.summaries.session.value = this.addKIfOverKilo(data.session)
        this.summaries.sessionTimeOnSite.value = this.timeSecFilter(data.sessionTimeAvg)
        this.summaries.pvToSession.value = this.addKIfOverKilo(data.session ? Math.floor(data.pv / data.session * 10) / 10 : 0)
        // ツールチップ用実データ
        this.summaries.session.chipValue = this.addComma(data.session)
        this.summaries.sessionTimeOnSite.chipValue = this.timeSecFilter(data.sessionTimeAvg)
        this.summaries.pvToSession.chipValue = this.addComma(data.session ? Math.floor(data.pv / data.session * 10) / 10 : 0)
      }).catch((err) => {
        this.handleErrorResponse(err)
      })
    },
    /**
     * 行動履歴を取得
     */
    fetchHistories () {
      // 初期化
      this.actionHistories = []

      // 行動履歴取得
      this.$store.dispatch('userActionHistory/getCustomerActionHistory', {
        customerId: this.userId,
        periodType: this.periodType,
        startDate: this.startDate,
        endDate: this.endDate,
        baseDate: this.momentUtil.getCurrentDateIncludeHyphen(),
        page: this.page,
        limit: ACTION_HISTORY_LIMIT,
      }).then((res) => {
        this.actionHistories = res.data.data.sessions
        this.actionHistoryPaging = res.data.paging
      }).catch((err) => {
        this.handleErrorResponse(err)
      })
    },
    /**
     * 構成データセット一覧を取得
     */
    fetchCustomerFragments () {
      this.$store.dispatch('userScvDetail/getCustomerFragments', this.userId)
        .then((res) => {
          this.customerFragments = res.data.data.customerFragmentList
        }).catch((err) => {
          this.handleErrorResponse(err)
          this.customerFragments = []
        })
    },
    /**
     * SCV項目ごとの採択候補を取得
     */
    fetchScvColumnSources () {
      // 初期化
      this.scvDefaultColumnSources = []

      this.$store.dispatch('userScvDetail/getScvColumnSources', this.userId)
        .then((res) => {
          this.scvDefaultColumnSources = res.data.data.scvDefaultColumns
          this.scvCustomColumnSources = res.data.data.scvCustomColumns
        }).catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    /**
     * 子カスタマーの一覧を取得
     */
    fetchScvChildren () {
      // 初期化
      this.scvChildren = []

      this.$store.dispatch('userScvDetail/getScvChildren', this.userId)
        .then((res) => {
          this.scvChildren = res.data.data.scvWithIdentifyColumnList
        }).catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    /**
     * SCV統合設定の有無を取得
     */
    fetchScvUnionSetting () {
      this.$store.dispatch('userScvDetail/checkScvUnionSetting', this.userId)
        .then((res) => {
          this.hasScvUnionSetting = res.data.data.exist
        }).catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    /**
     * SCV項目の値を手動で上書き
     */
    overwriteScvColumn (scvColumnId, newValue) {
      this.$store.dispatch('userScvDetail/overwriteScvColumn', {
        scvId: this.userId,
        scvColumnId: scvColumnId,
        newValue: newValue,
      }).then((res) => {
        this.getUserScvDetail()
      }).catch((err) => {
        this.handleErrorResponse(err)
      })
    },
    /**
     * 統合解除
     */
    unintegrate () {
      this.$store.dispatch('userScvDetail/unintegrate', this.userId)
        .then((res) => {
          this.notifySuccessMessage(this.$t('notify.success.setScvUnintegration'))
          this.getUserScvDetail()
        }).catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    handleChangeHistoryPage (page) {
      this.page = page
      this.fetchHistories()
    },
    addScvCustomColumnIndex (scvCustomColumns) {
      const scvCustomColumnsWithIndex = this.scvSettings.filter(value => value.isCustom)
      for (const scvCustomColumn of scvCustomColumns) {
        const targetScvColumnIndex = scvCustomColumnsWithIndex.find((v) => v.scvColumnId === scvCustomColumn.scvColumnId).scvColumnIndex
        scvCustomColumn.scvColumnIndex = targetScvColumnIndex
      }
      return scvCustomColumns
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/theme.scss';

@include theme(v-tabs-items) using ($integral-core-theme) {
  ::v-deep p {
    color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'actionHistory', 'text');
  }
}
</style>
