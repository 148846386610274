<template>
  <div>
    <!-- ホスト名 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="host"
          :label="$t(`form.jobSetting.jobTask.host`)"
          name="host"
          :data-vv-as="$t(`form.jobSetting.jobTask.host`)"
          v-validate="'required'"
          :error-messages="errors.collect('host')" />
      </v-col>
    </v-row>
    <!-- ポート番号 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="port"
          :label="$t(`form.jobSetting.jobTask.port`)"
          type="number"
          name="port"
          :data-vv-as="$t(`form.jobSetting.jobTask.port`)"
          v-validate="'required'"
          :error-messages="errors.collect('port')" />
      </v-col>
    </v-row>
    <!-- ディレクトリパス -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="pathPrefix"
          :label="$t(`form.jobSetting.jobTask.pathPrefix`)"
          name="pathPrefix"
          :data-vv-as="$t(`form.jobSetting.jobTask.pathPrefix`)"
          v-validate="'required'"
          :error-messages="errors.collect('pathPrefix')" />
      </v-col>
    </v-row>
    <!-- ファイル名 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="filename"
          :label="$t(`form.jobSetting.jobTask.file`)"
          name="filename"
          :data-vv-as="$t(`form.jobSetting.jobTask.file`)"
          v-validate="'required'"
          :error-messages="errors.collect('filename')" />
      </v-col>
    </v-row>
    <!-- ヘッダ行有無 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-checkbox
          v-model="skipHeaderLines"
          :label="$t(`form.jobSetting.jobTask.header`)"
          false-value="1"
          true-value="0">
          <v-tooltip slot="append" bottom :max-width="420">
            <template #activator="{ on }">
              <v-icon v-on="on">ic-help-S</v-icon>
            </template>
            <span v-html="$t(`tooltip.jobSetting.hasNotHeaderFile`)" />
          </v-tooltip>
        </v-checkbox>
      </v-col>
    </v-row>
    <!-- ユーザー名 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="username"
          :label="$t(`form.jobSetting.jobTask.user`)"
          name="username"
          :data-vv-as="$t(`form.jobSetting.jobTask.user`)"
          v-validate="'required'"
          :error-messages="errors.collect('username')" />
      </v-col>
    </v-row>
    <!-- パスワード -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="password"
          autocomplete="new-password"
          type="password"
          :label="$t(`form.password`)"
          name="password"
          :data-vv-as="$t(`form.password`) + 'または' + $t(`form.secretKey`)"
          v-validate="{ required: secretKey === null || secretKey === undefined || secretKey === '' }"
          :error-messages="errors.collect('password')" />
      </v-col>
    </v-row>
    <!-- シークレットキー -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-textarea
          v-model="secretKey"
          autocomplete="new-password"
          type="password"
          :label="$t(`form.secretKey`)"
          name="secretKey"
          :data-vv-as="$t(`form.secretKey`) + 'または' + $t(`form.password`)"
          v-validate="{ required: password === null || password === undefined || password === '' }"
          :error-messages="errors.collect('secretKey')" />
      </v-col>
    </v-row>
    <!-- シークレットキー・パスフレーズ -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="passphrase"
          type="password"
          :label="$t(`form.passphrase`)"
          name="passphrase" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// enum
import JSON_INPUT_ITEM_NAMES from '@/enum/jobTask/JSON_INPUT_ITEM_NAMES'
// util
import enumUtil from '@/utils/enumUtil'

export default {
  mixins: [
    JSON_INPUT_ITEM_NAMES,
    enumUtil,
  ],
  props: ['inputForm'],
  computed: {
    host: {
      get () {
        if (this.checkSettingProp()) {
          return this.inputForm[this.JSON_INPUT_ITEM_NAMES.SFTP.value].host
        }
        return null
      },
      set (value) {
        this.updateValue('host', value)
      },
    },
    port: {
      get () {
        if (this.checkSettingProp()) {
          return this.inputForm[this.JSON_INPUT_ITEM_NAMES.SFTP.value].port
        }
        return null
      },
      set (value) {
        this.updateValue('port', value)
      },
    },
    pathPrefix: {
      get () {
        if (this.checkSettingProp()) {
          return this.inputForm[this.JSON_INPUT_ITEM_NAMES.SFTP.value].pathPrefix
        }
        return null
      },
      set (value) {
        this.updateValue('pathPrefix', value)
      },
    },
    filename: {
      get () {
        if (this.checkSettingProp()) {
          return this.inputForm[this.JSON_INPUT_ITEM_NAMES.SFTP.value].filename
        }
        return null
      },
      set (value) {
        this.updateValue('filename', value)
      },
    },
    skipHeaderLines: {
      get () {
        if (this.checkSettingProp()) {
          return this.inputForm[this.JSON_INPUT_ITEM_NAMES.SFTP.value].skipHeaderLines
        }
        return null
      },
      set (value) {
        this.updateValue('skipHeaderLines', value)
      },
    },
    username: {
      get () {
        if (this.checkSettingProp()) {
          return this.inputForm[this.JSON_INPUT_ITEM_NAMES.SFTP.value].username
        }
        return null
      },
      set (value) {
        this.updateValue('username', value)
      },
    },
    password: {
      get () {
        if (this.checkSettingProp()) {
          return this.inputForm[this.JSON_INPUT_ITEM_NAMES.SFTP.value].password
        }
        return null
      },
      set (value) {
        this.updateValue('password', value)
      },
    },
    secretKey: {
      get () {
        if (this.checkSettingProp()) {
          return this.inputForm[this.JSON_INPUT_ITEM_NAMES.SFTP.value].secretKey
        }
        return null
      },
      set (value) {
        this.updateValue('secretKey', value)
      },
    },
    passphrase: {
      get () {
        if (this.checkSettingProp()) {
          return this.inputForm[this.JSON_INPUT_ITEM_NAMES.SFTP.value].passphrase
        }
        return null
      },
      set (value) {
        this.updateValue('passphrase', value)
      },
    },
  },
  methods: {
    /**
     * SFTPのタスクかどうかを判定
     * @return {Boolean}
     */
    checkSettingProp () {
      return this.inputForm && this.inputForm[this.JSON_INPUT_ITEM_NAMES.SFTP.value]
    },
    /**
     * SFTPインプットタスクの値をJSONに変換して更新
     * @param  {String} propertyName  更新対象のキー
     * @param  {String} propertyValue 更新対象の値
     */
    updateValue (propertyName, propertyValue) {
      if (!this.inputForm) return

      const json = Object.assign({}, this.inputForm)
      if (!json[this.JSON_INPUT_ITEM_NAMES.SFTP.value]) {
        this.$set(json, this.JSON_INPUT_ITEM_NAMES.SFTP.value, {})
      }
      Object.assign(json[this.JSON_INPUT_ITEM_NAMES.SFTP.value], { [propertyName]: propertyValue })
      this.$emit('update', json)
    },
  },
}
</script>
