import axios from '@/axios'
import checkValueUtil from '@/utils/checkValueUtil'

const dataSourceImportHistory = {
  namespaced: true,
  state: {
    sortParameter: {
      orderBy: 'startDatetime',
      isDesc: true,
    },
    filterParameter: {
      page: 1,
      limit: 50,
      dataSourceId: null,
      status: null,
    },
    initFilterParameter: {
      page: 1,
      limit: 50,
      dataSourceId: null,
      status: null,
    },
    loadingDataSourceImportHistories: false,
  },
  mutations: {
    setOrderBy (state, newVal) {
      state.sortParameter.orderBy = newVal
    },
    setIsDesc (state, newVal) {
      state.sortParameter.isDesc = newVal
    },
    filterReset (state) {
      Object.assign(state.filterParameter, JSON.parse(JSON.stringify(state.initFilterParameter)))
    },
    setFilter (state, newVal) {
      state.filterParameter = newVal
    },
    setLoadingDataSourceImportHistories (state, newVal) {
      state.loadingDataSourceImportHistories = newVal
    },
  },
  getters: {
    getQueryParams (state) {
      const params = Object.assign({}, state.sortParameter, state.filterParameter)
      for (const key in params) {
        if (checkValueUtil.isNullOrEmpty(params[key])) delete params[key]
      }
      return params
    },
  },
  actions: {
    /**
     * マスタアップロード履歴リストを取得
     */
    async getDataSourceImportHistories ({ commit, state }, dataSourceId) {
      commit('setLoadingDataSourceImportHistories', true)
      let queryOrderBy = 'start_datetime'
      if (state.sortParameter.orderBy) {
        // キャメルからスネークに変換
        queryOrderBy = state.sortParameter.orderBy.replace(/([A-Z])/g, (str) => { return '_' + str.charAt(0).toLowerCase() })
      }

      return await axios.get('/dataSource/importPeriodicHistory/', {
        params: {
          dataSourceId: dataSourceId,
          dataSourceImportStatus: state.filterParameter.status,
          offset: (state.filterParameter.page - 1) * state.filterParameter.limit,
          limit: state.filterParameter.limit,
          isDesc: state.sortParameter.isDesc,
          orderBy: queryOrderBy,
        },
      }).then((res) => {
        return Promise.resolve({
          dataSourceImportHistories: res.data.data.dataSourcePeriodicImportHistoryDtoList,
          paging: res.data.paging,
        })
      }).catch((err) => {
        // ここでキャッチすることで呼び出し元にエラーが伝播されなくなるのでエラーをreturnする
        commit('resetDataSourceImportHistories')
        return Promise.reject(err)
      }).finally(() => {
        commit('setLoadingDataSourceImportHistories', false)
      })
    },
    updateOrderBy ({ commit }, newVal) {
      commit('setOrderBy', newVal)
    },
    updateIsDesc ({ commit }, newVal) {
      commit('setIsDesc', newVal)
    },
    updateFilter ({ commit }, newVal) {
      commit('setFilter', newVal)
    },
    filterReset ({ commit }) {
      commit('filterReset')
    },
  },
}
export default dataSourceImportHistory
