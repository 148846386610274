<template>
  <v-card tile elevation="0">
    <information-bar>
    </information-bar>
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-card
            class="manualLink"
            outlined
            tile
            href="https://auth.dev.cdp-integral.com/js/js.html"
            target="blank"
          >
            <v-icon size="84">mdi-desktop-mac</v-icon>
            {{ $t(`text.buildManual.js`) }}
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card
            class="manualLink"
            outlined
            tile
            href="https://auth.dev.cdp-integral.com/sdk/androidsdk/manual/android.html"
            target="blank"
          >
            <v-icon size="84">mdi-android</v-icon>
            {{ $t(`text.buildManual.android`) }}
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card
            class="manualLink"
            outlined
            tile
            href="https://auth.dev.cdp-integral.com/sdk/iossdk/manual/ios.html"
            target="blank"
          >
            <v-icon size="84">mdi-apple</v-icon>
            {{ $t(`text.buildManual.ios`) }}
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Manuals',
}
</script>

<style lang="scss" scoped>
.manualLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 260px;
  .v-icon {
    margin-bottom: 32px;
  }
}
</style>
