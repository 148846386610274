<template>
  <div class="d-flex align-center">
    <totp-qr-code
      :loginUserDetail="loginUserDetail"
      :totpSecretKey="totpSecretKey"
    />
    <totp-login-form />
  </div>
</template>

<script>
import totpLoginForm from '@/components/Login/totpLoginForm'
import totpQrCode from '@/components/Login/totpQrCode'

export default {
  name: 'TotpRegister',
  components: {
    totpLoginForm,
    totpQrCode,
  },
  data () {
    return {
      totpSecretKey: '',
    }
  },
  computed: {
    loginUserDetail () {
      return this.$store.state.auth.loginUserDetail
    },
  },
  created () {
    this.$store.dispatch('auth/getLoginUser')
      .then(() => {
        // デバイス登録画面を表示していいか確認
        if (
          this.$store.state.auth.loginUserDetail.mfaRequired &&
          this.$store.state.auth.loginUserDetail.mfaDeviceRegistered &&
          !this.$store.state.auth.loginUserDetail.mfaAuthenticated
        ) {
          // MFA 必須でデバイス登録済みで MFA 未認証なのでワンタイムパスワード入力画面へ
          this.$router.push({ name: 'TotpLogin' })
        } else if (this.$store.state.auth.loginUserDetail.mfaAuthenticated && !this.$store.state.auth.loginUserDetail.passwordChangedFlg) {
          // MFA 認証済みでパスワード変更が必要なのでパスワード変更画面へ
          this.$router.push({ name: 'PasswordChange' })
        } else if (this.$store.state.auth.loginUserDetail.mfaAuthenticated && this.$store.state.auth.loginUserDetail.loginUserId > 0) {
          // 既にログイン済みだったらログイン済み画面へ遷移させる
          this.$router.push({ name: 'Release' })
        }

        // 秘密鍵取得
        this.$store.dispatch('auth/getTotpSeacretKey')
          .then((res) => (this.totpSecretKey = res.data.data.secretKey))
          .catch(() => {
            // デバイス登録済みなどで秘密鍵が取れなかったらログイン画面へ遷移させる
            this.$router.push({ name: 'Login' })
          })
      })
      .catch(() => {
        // ID/PASS 認証が通ってなかったらログイン画面へ遷移させる
        this.$router.push({ name: 'Login' })
      })
  },
}
</script>
