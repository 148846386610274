<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn :to="{ name: 'TransactionSummary' }">
          {{ $t(`btn.backToList`) }}
        </v-btn>
      </template>
      <template #right v-if="isPutMode">
        <v-btn @click="onClickDeleteModalButton()" :disabled="!canDelete || loadingTransactionSummaries">{{ $t('btn.delete') }}</v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <h3 class="lead-line">トランザクションサマリ名</h3>
      <v-card class="mb-6" outlined tile>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="formParameter.transactionName"
                class="pt-1"
                :placeholder="$t('form.transactionSummary.transactionNamePlaceholder')"
                name="transactionName"
                :data-vv-as="$t('form.transactionSummary.transactionName')"
                v-validate="'required|max:200'"
                :error-messages="errors.collect('transactionName')"
              />
              <v-textarea
                v-model="formParameter.note"
                :label="$t('form.note')"
                outlined
                rows="3"
                name="note"
                :data-vv-as="$t('form.note')"
                v-validate="'max:1000'"
                :error-messages="errors.collect('note')"
              />
            </v-col>
            <v-col cols="4" v-if="isPutMode">
              <p>
                <span class="d-inline-block info-column">{{ $t('text.createdBy') }}</span>&#58;&nbsp;{{ transactionSummary.createdUserName }}<br>
                <span class="d-inline-block info-column">{{ $t('text.createdAt') }}</span>&#58;&nbsp;{{ transactionSummary.createdAt }}
              </p>
              <p>
                <span class="d-inline-block info-column">{{ $t('text.updatedBy') }}</span>&#58;&nbsp;{{ transactionSummary.updatedUserName }}<br>
                <span class="d-inline-block info-column">{{ $t('text.updatedAt') }}</span>&#58;&nbsp;{{ transactionSummary.updatedAt }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <h4>集計期間</h4>
      <v-card class="mb-6" outlined tile>
        <v-card-text>
          <v-radio-group v-model.number="formParameter.periodType" dense hide-details mandantory row>
            <v-row>
              <v-col>
                <v-radio :label="TRANSACTION_PERIOD_TYPE.ALL_DAYS.text"  :value="TRANSACTION_PERIOD_TYPE.ALL_DAYS.value" color="inputSelectionControl" />
              </v-col>
              <v-col>
                <v-radio :label="'期間指定'" :value="conditionalPeriodTypeValue" color="inputSelectionControl" />
                <v-row class="ml-5">
                  <v-col>
                    <v-text-field
                      v-model="formParameter.beforeDay"
                      :disabled="formParameter.periodType === TRANSACTION_PERIOD_TYPE.ALL_DAYS.value"
                      :prefix="'Last'"
                      :suffix="'days'"
                      type="number"
                      min="1"
                      name="beforeDay"
                      data-vv-as="期間指定"
                      v-validate="formParameter.periodType === conditionalPeriodTypeValue ? 'required|min_value:1' : ''"
                      :error-messages="errors.collect('beforeDay')"
                    />
                  </v-col>
                  <v-col class="d-flex">
                    <v-radio
                      :label="'当日含む'"
                      :disabled="formParameter.periodType === TRANSACTION_PERIOD_TYPE.ALL_DAYS.value"
                      :value="TRANSACTION_PERIOD_TYPE.LAST_DAYS_INCLUDE_CURRENT_DAY.value"
                      color="inputSelectionControl" />
                    <v-radio
                      :label="'当日含まない'"
                      :disabled="formParameter.periodType === TRANSACTION_PERIOD_TYPE.ALL_DAYS.value"
                      :value="TRANSACTION_PERIOD_TYPE.LAST_DAYS_EXCLUDE_CURRENT_DAY.value"
                      color="inputSelectionControl" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-card-text>
      </v-card>
      <h4>種別</h4>
      <v-card class="mb-6" outlined tile>
        <v-card-text>
          <v-row>
            <v-col>
              <span class="text-subtitle-2">計算</span><br>
              <v-radio-group
                v-model.number="formParameter.transactionSummaryType"
                class="mt-0 ml-4"
                dense
                hide-details
                row
                name="transactionSummaryType"
                :data-vv-as="$t(`form.transactionSummary.transactionSummaryType`)"
                v-validate="'required'"
                :error-messages="errors.collect('transactionSummaryType')"
              >
                &#040;&nbsp;
                <v-radio :label="TRANSACTION_SUMMARY_TYPE.SUM.text" :value="TRANSACTION_SUMMARY_TYPE.SUM.value" color="inputSelectionControl" />
                <v-radio :label="TRANSACTION_SUMMARY_TYPE.MAX.text" :value="TRANSACTION_SUMMARY_TYPE.MAX.value" color="inputSelectionControl" />
                <v-radio :label="TRANSACTION_SUMMARY_TYPE.AVERAGE.text" :value="TRANSACTION_SUMMARY_TYPE.AVERAGE.value" color="inputSelectionControl" />
                &#041;&nbsp;
              </v-radio-group>
            </v-col>
            <v-col>
              <span>カウント</span><br>
              <v-radio-group
                v-model.number="formParameter.transactionSummaryType"
                class="mt-0 ml-4"
                dense
                hide-details
                row
                :error-messages="errors.collect('transactionSummaryType')"
              >
                &#040;&nbsp;
                <v-radio :label="TRANSACTION_SUMMARY_TYPE.COUNT.text" :value="TRANSACTION_SUMMARY_TYPE.COUNT.value" color="inputSelectionControl" />
                <v-radio :label="TRANSACTION_SUMMARY_TYPE.UNIQUE_VALUES_COUNT.text" :value="TRANSACTION_SUMMARY_TYPE.UNIQUE_VALUES_COUNT.value" color="inputSelectionControl" />
                &#041;&nbsp;
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span>日時</span><br>
              <v-radio-group
                v-model.number="formParameter.transactionSummaryType"
                class="mt-0 ml-4"
                dense
                hide-details
                row
                :error-messages="errors.collect('transactionSummaryType')"
              >
                &#040;&nbsp;
                <v-radio :label="TRANSACTION_SUMMARY_TYPE.FIRST_TIMESTAMP.text" :value="TRANSACTION_SUMMARY_TYPE.FIRST_TIMESTAMP.value" color="inputSelectionControl" />
                <v-radio :label="TRANSACTION_SUMMARY_TYPE.LAST_TIMESTAMP.text" :value="TRANSACTION_SUMMARY_TYPE.LAST_TIMESTAMP.value" color="inputSelectionControl" />
                &#041;&nbsp;
              </v-radio-group>
            </v-col>
            <v-col>
              <span>値</span><br>
              <v-radio-group
                v-model.number="formParameter.transactionSummaryType"
                class="mt-0 ml-4"
                dense
                hide-details
                row
                :error-messages="errors.collect('transactionSummaryType')"
              >
                &#040;&nbsp;
                <v-radio :label="TRANSACTION_SUMMARY_TYPE.MOST_FREQUENT.text" :value="TRANSACTION_SUMMARY_TYPE.MOST_FREQUENT.value" color="inputSelectionControl" />
                <v-radio :label="TRANSACTION_SUMMARY_TYPE.FIRST_VALUE.text" :value="TRANSACTION_SUMMARY_TYPE.FIRST_VALUE.value" color="inputSelectionControl" />
                <v-radio :label="TRANSACTION_SUMMARY_TYPE.LAST_VALUE.text" :value="TRANSACTION_SUMMARY_TYPE.LAST_VALUE.value" color="inputSelectionControl" />
                &#041;&nbsp;
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <h4>出力データ</h4>
      <v-card class="mb-6" outlined tile>
        <v-card-text>
          <v-radio-group v-model.number="formParameter.transactionSummaryOutputDataType" mandatory row>
            &#040;&nbsp;
                <v-radio
                  :label="DATA_TYPES.STRING.label"
                  :value="DATA_TYPES.STRING.value"
                  :disabled="!selectableSummaryOutputDataType.includes(DATA_TYPES.STRING.value)"
                  color="inputSelectionControl" />
                <v-radio
                  :label="DATA_TYPES.LONG.label"
                  :value="DATA_TYPES.LONG.value"
                  :disabled="!selectableSummaryOutputDataType.includes(DATA_TYPES.LONG.value)"
                  color="inputSelectionControl" />
                <v-radio
                  :label="DATA_TYPES.DATE.label"
                  :value="DATA_TYPES.DATE.value"
                  :disabled="!selectableSummaryOutputDataType.includes(DATA_TYPES.DATE.value)"
                  color="inputSelectionControl" />
                <v-radio
                  :label="DATA_TYPES.DATETIME.label"
                  :value="DATA_TYPES.DATETIME.value"
                  :disabled="!selectableSummaryOutputDataType.includes(DATA_TYPES.DATETIME.value)"
                  color="inputSelectionControl" />
                &#041;&nbsp;
          </v-radio-group>
        </v-card-text>
      </v-card>
      <h4>集計データ</h4>
      <v-card flat tile outlined class="mb-4 rule-form">
        <v-card-text class="px-9 py-3">
          <v-row v-if="loadingDataSourceList" :justify="'center'" class="ma-3">
            <v-progress-circular indeterminate color="primary"/>
          </v-row>
          <div v-else>
            <transaction-group
              v-for="(transaction, index) in formParameter.transactions"
              ref="ruleGroup"
              :key="index + '_group'"
              :transaction="transaction"
              :totalTransactionCount="formParameter.transactions.length"
              :data-source-list="dataSourceList"
              :is-transaction-summary-type-mismatched="mismatchedTransactionSummaryTypes.some((type) => type === index)"
              @editGroup="onClickEditTransactionButton(index)"
              @removeGroup="onClickRemoveTransactionButton(index)"
            />
            <v-btn
              class="ml-2"
              fab
              depressed
              outlined
              @click="onClickAddTransactionButton()"
              :disabled="formParameter.transactions.length >= transactionLimit">
              <v-icon>ic-add</v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-dialog v-model="showTransactionGroupForm" width="770" overlay-color="#07070820" overlay-opacity="1" persistent>
      <transaction-group-form
        :transaction="targetTransactionIndex < formParameter.transactions.length ? formParameter.transactions[targetTransactionIndex] : initialTransaction"
        :data-source-list="dataSourceList"
        :transaction-summary-type="formParameter.transactionSummaryType"
        :transaction-summary-output-data-type="formParameter.transactionSummaryOutputDataType"
        :filter-limit="filterLimit - filterCount"
        @dialogClose="showTransactionGroupForm = false"
        @applyTransaction="formParameter.transactions[targetTransactionIndex] = $event"
      />
    </v-dialog>
    <v-card-actions>
      <v-spacer />
      <v-btn color="denial" large :to="{ name: 'TransactionSummary' }">{{ $t('btn.cancel') }}</v-btn>
      <v-btn color="primary" large @click="onClickSaveModalButton()" :disabled="disableSaveButton">{{ $t('btn.save') }}</v-btn>
      <v-spacer />
    </v-card-actions>
    <v-dialog v-model="showSaveModal" width="40%" @input="showSaveModal = false">
      <v-card>
        <v-card-title>{{ $t('form.transactionSummary.save') }}</v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="showSaveModal = false">{{ $t('btn.cancel') }}</v-btn>
          <v-btn color="primary" @click="onClickSaveButton()">{{ $t('btn.save') }}</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDeleteModal" width="400" @input="showDeleteModal = false" :loading="loadingTransactionSummaries">
      <v-card>
        <v-card-title/>
        <v-card-text>
          {{ $t('notify.check.delete', [formParameter.transactionName]) }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="denial" @click="showDeleteModal = false">{{ $t('btn.no') }}</v-btn>
          <v-btn color="primary" @click="onClickDeleteButton()">{{ $t('btn.yes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// component
import TransactionGroup from '@/components/TransactionSummarySetting/transactionGroup.vue'
import TransactionGroupForm from '@/components/TransactionSummarySetting/transactionGroupForm.vue'
// enum
import DATA_TYPES from '@/enum/DATA_TYPES'
import TRANSACTION_PERIOD_TYPE from '@/enum/transactionSummary/TRANSACTION_PERIOD_TYPE'
import TRANSACTION_SUMMARY_TYPE from '@/enum/transactionSummary/TRANSACTION_SUMMARY_TYPE'
import OPERATOR_TYPE from '@/enum/OPERATOR_TYPE'
import OPERATOR_TYPE_WITH_LOGICAL_NOT from '@/enum/OPERATOR_TYPE_WITH_LOGICAL_NOT'
// util
import convertUtil from '@/utils/convertUtil'
import momentUtil from '@/utils/momentUtil'
import notifyUtil from '@/utils/notifyUtil'

const TRANSACTION_LIMIT = 10
const FILTER_LIMIT = 10

export default {
  name: 'TransactionSummarySetting',
  components: {
    TransactionGroup,
    TransactionGroupForm,
  },
  mixins: [
    DATA_TYPES,
    TRANSACTION_PERIOD_TYPE,
    TRANSACTION_SUMMARY_TYPE,
    OPERATOR_TYPE,
    OPERATOR_TYPE_WITH_LOGICAL_NOT,
    convertUtil,
    momentUtil,
    notifyUtil,
  ],
  data () {
    return {
      showTransactionGroupForm: false,
      targetTransactionIndex: null,
      showSaveModal: false,
      showDeleteModal: false,
      transactionSummary: {
        createdUserName: null,
        createdAt: null,
        updatedUserName: null,
        updatedAt: null,
      },
      transactionLimit: TRANSACTION_LIMIT,
      filterLimit: FILTER_LIMIT,
      filterCount: 0,
      dataSourceList: [],
      formParameter: {
        transactionSummarySettingId: null,
        transactionName: null,
        note: null,
        transactionSummaryType: null,
        transactionSummaryOutputDataType: null,
        periodType: 1,
        beforeDay: null,
        transactions: [],
      },
      initialTransaction: {
        dataSourceId: null,
        logicalOperatorType: 1,
        summaryColumn: {
          summaryPhysicalName: null,
          summaryLogicalName: null,
          dataType: null,
        },
        filterColumns: [],
      },
      mismatchedTransactionSummaryTypes: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['canPost', 'canPut', 'canDelete']),
    isPutMode () {
      return this.$route.name === 'TransactionSummaryEdit'
    },
    selectableSummaryOutputDataType () {
      switch (this.formParameter.transactionSummaryType) {
        case this.TRANSACTION_SUMMARY_TYPE.SUM.value:
        case this.TRANSACTION_SUMMARY_TYPE.MAX.value:
        case this.TRANSACTION_SUMMARY_TYPE.AVERAGE.value:
        case this.TRANSACTION_SUMMARY_TYPE.COUNT.value:
        case this.TRANSACTION_SUMMARY_TYPE.UNIQUE_VALUES_COUNT.value:
          return [this.DATA_TYPES.LONG.value]
        case this.TRANSACTION_SUMMARY_TYPE.FIRST_TIMESTAMP.value:
        case this.TRANSACTION_SUMMARY_TYPE.LAST_TIMESTAMP.value:
          return [this.DATA_TYPES.DATETIME.value]
        default:
          return [this.DATA_TYPES.STRING.value, this.DATA_TYPES.LONG.value, this.DATA_TYPES.DATE.value, this.DATA_TYPES.DATETIME.value]
      }
    },
    disableSaveButton () {
      if (this.$route.name === 'TransactionSummarySetting') {
        return !this.canPost
      } else if (this.$route.name === 'TransactionSummaryEdit') {
        return !this.canPut
      }
      return false
    },
    conditionalPeriodTypeValue () {
      if (this.formParameter.periodType === this.TRANSACTION_PERIOD_TYPE.LAST_DAYS_INCLUDE_CURRENT_DAY.value) {
        return this.TRANSACTION_PERIOD_TYPE.LAST_DAYS_INCLUDE_CURRENT_DAY.value
      } else if (this.formParameter.periodType === this.TRANSACTION_PERIOD_TYPE.LAST_DAYS_EXCLUDE_CURRENT_DAY.value) {
        return this.TRANSACTION_PERIOD_TYPE.LAST_DAYS_EXCLUDE_CURRENT_DAY.value
      }
      return this.TRANSACTION_PERIOD_TYPE.LAST_DAYS_INCLUDE_CURRENT_DAY.value
    },
    loadingTransactionSummaries () {
      return this.$store.state.transactionSummary.loadingTransactionSummaries
    },
    loadingDataSourceList () {
      return this.$store.state.dataSource.loadingDataSourceList
    },
  },
  watch: {
    'formParameter.transactionSummaryType': {
      handler () {
        switch (this.formParameter.transactionSummaryType) {
          case this.TRANSACTION_SUMMARY_TYPE.COUNT.value:
          case this.TRANSACTION_SUMMARY_TYPE.UNIQUE_VALUES_COUNT.value:
          case this.TRANSACTION_SUMMARY_TYPE.SUM.value:
          case this.TRANSACTION_SUMMARY_TYPE.MAX.value:
          case this.TRANSACTION_SUMMARY_TYPE.AVERAGE.value:
            this.formParameter.transactionSummaryOutputDataType = this.DATA_TYPES.LONG.value
            break
          case this.TRANSACTION_SUMMARY_TYPE.FIRST_TIMESTAMP.value:
          case this.TRANSACTION_SUMMARY_TYPE.LAST_TIMESTAMP.value:
            this.formParameter.transactionSummaryOutputDataType = this.DATA_TYPES.DATETIME.value
            break
        }
      },
    },
  },
  created () {
    if (this.isPutMode) {
      this.$store.dispatch('transactionSummary/getTransactionSummary', this.$route.params.transactionSummaryId)
        .then(res => {
          this.transactionSummary = res.data.data
          this.formParameter.transactionSummarySettingId = res.data.data.transactionSummarySettingId
          this.formParameter.transactionName = res.data.data.transactionSummaryName
          this.formParameter.note = res.data.data.note
          this.formParameter.transactionSummaryType = res.data.data.transactionSummaryType
          this.formParameter.transactionSummaryOutputDataType = res.data.data.transactionSummaryOutputDataType
          this.formParameter.periodType = res.data.data.periodType
          this.formParameter.beforeDay = res.data.data.beforeDay
          this.formParameter.transactions = res.data.data.transactions

          // convertIsoToDateTime for <input type="datetime-local">
          this.formParameter.transactions.forEach(transaction => {
            transaction.filterColumns.forEach(transactionFilter => {
              if (transactionFilter.dataType === this.DATA_TYPES.DATETIME.value) {
                transactionFilter.filterValue = this.convertIsoToDateTimeString(transactionFilter.filterValue)
              }
            })
          })

          this.formParameter.transactions.forEach((transaction) => {
            transaction.filterColumns.forEach((filterColumn) => {
              if (filterColumn.logicalNot === undefined) {
                if (filterColumn.operatorType === this.OPERATOR_TYPE.NOT_PARTIAL_MATCH.value) {
                  filterColumn.logicalNot = true
                  filterColumn.operatorType = this.OPERATOR_TYPE_WITH_LOGICAL_NOT.NOT_PARTIAL_MATCH.value
                } else if (filterColumn.operatorType === this.OPERATOR_TYPE.NOT_EQ.value) {
                  filterColumn.logicalNot = true
                  filterColumn.operatorType = this.OPERATOR_TYPE_WITH_LOGICAL_NOT.NOT_EQ.value
                } else {
                  filterColumn.logicalNot = false
                }
              }
            })
          })
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    }
    this.$store.dispatch('dataSource/getDataSourceList')
      .then(res => {
        this.dataSourceList = res.data.data.dataSourceList
      }).catch((err) => {
        this.handleErrorResponse(err)
      })
  },
  methods: {
    onClickSaveModalButton () {
      this.validateForms()
        .then(res => {
          this.showSaveModal = true
        }).catch(() => {}) // Uncaught (in promise) 対策。握りつぶしても vee-validate の表示が出る
    },
    onClickDeleteModalButton () {
      this.showDeleteModal = true
    },
    onClickAddTransactionButton () {
      this.filterCount = 0
      this.formParameter.transactions.forEach(transaction => {
        this.filterCount += transaction.filterColumns.length
      })
      this.showTransactionGroupForm = true
      this.targetTransactionIndex = this.formParameter.transactions.length
    },
    onClickEditTransactionButton (index) {
      this.targetTransactionIndex = index
      this.showTransactionGroupForm = true
    },
    onClickRemoveTransactionButton (index) {
      this.formParameter.transactions.splice(index, 1)
    },
    onClickSaveButton () {
      if (this.isPutMode) {
        this.formParameter.summaryRuleChangedDatetime = this.momentUtil.getCurrentDateTime()
        this.$store.dispatch('transactionSummary/updateTransactionSummary', this.buildRequest())
          .then(() => {
            this.notifySuccessMessage(this.$t('notify.transactionSummary') + this.$t('notify.success.update'))
            this.showSaveModal = false
            this.$router.push({ name: 'TransactionSummary' })
          }).catch(err => {
            this.handleErrorResponse(err)
          })
      } else {
        this.$store.dispatch('transactionSummary/createTransactionSummary', this.buildRequest())
          .then(() => {
            this.notifySuccessMessage(this.$t('notify.transactionSummary') + this.$t('notify.success.create'))
            this.showSaveModal = false
            this.$router.push({ name: 'TransactionSummary' })
          }).catch(err => {
            this.handleErrorResponse(err)
          })
      }
    },
    onClickDeleteButton () {
      this.$store.dispatch('transactionSummary/deleteTransactionSummary', this.formParameter.transactionSummarySettingId)
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.transactionSummary') + this.$t('notify.success.delete'))
          this.showDeleteModal = false
          this.$router.push({ name: 'TransactionSummary' })
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    },
    async validateForms () {
      const resultList = []
      this.mismatchedTransactionSummaryTypes = []
      // サマリ名、サマリ種別、集計期間のフォームバリデーション実行
      await this.$validator.validateAll()
        .then(result => {
          if (!result) resultList.push(false)
        })
      for (const error of this.errors.items) {
        this.notifyErrorMessage(error.msg)
      }

      if (!this.formParameter.transactions.length) {
        resultList.push(false)
        this.notifyErrorMessage(this.$t('notify.error.transactionSummary.noTransaction'))
      }

      // サマリ種別と集計カラムの制約チェック
      switch (this.formParameter.transactionSummaryType) {
        case this.TRANSACTION_SUMMARY_TYPE.SUM.value:
        case this.TRANSACTION_SUMMARY_TYPE.MAX.value:
        case this.TRANSACTION_SUMMARY_TYPE.AVERAGE.value:
          // SUM, MAX, AVERAGEはLONGのみ
          this.formParameter.transactions.forEach((transaction, index) => {
            if (transaction.summaryColumn.dataType !== this.DATA_TYPES.LONG.value) {
              this.mismatchedTransactionSummaryTypes.push(index)
              resultList.push(false)
            }
          })
          break
        case this.TRANSACTION_SUMMARY_TYPE.MOST_FREQUENT.value:
        case this.TRANSACTION_SUMMARY_TYPE.FIRST_VALUE.value:
        case this.TRANSACTION_SUMMARY_TYPE.LAST_VALUE.value:
          // MOST_FREQUENT, FIRST_VALUE, LAST_VALUEは選択されたデータ型のみ
          this.formParameter.transactions.forEach((transaction, index) => {
            if (transaction.summaryColumn.dataType !== this.formParameter.transactionSummaryOutputDataType) {
              this.mismatchedTransactionSummaryTypes.push(index)
              resultList.push(false)
            }
          })
          break
        case this.TRANSACTION_SUMMARY_TYPE.COUNT.value:
        case this.TRANSACTION_SUMMARY_TYPE.UNIQUE_VALUES_COUNT.value:
        case this.TRANSACTION_SUMMARY_TYPE.FIRST_TIMESTAMP.value:
        case this.TRANSACTION_SUMMARY_TYPE.LAST_TIMESTAMP.value:
        default:
          // その他は制約なし
          break
      }
      if (this.mismatchedTransactionSummaryTypes.length !== 0) {
        this.notifyErrorMessage(this.$t('notify.error.transactionSummary.mismatchTransactionSummaryType'))
      }
      if (resultList.includes(false)) {
        return Promise.reject(resultList)
      } else {
        return Promise.resolve()
      }
    },
    buildRequest () {
      const requestParameter = JSON.parse(JSON.stringify(this.formParameter))
      requestParameter.transactions.forEach(transaction => {
        transaction.filterColumns.forEach(transactionFilter => {
          if (transactionFilter.dataType === this.DATA_TYPES.DATETIME.value) {
            transactionFilter.filterValue = this.convertDateTimeToIso(transactionFilter.filterValue)
          }
          if (transactionFilter.operatorType === this.OPERATOR_TYPE.IS_NULL.value || transactionFilter.operatorType === this.OPERATOR_TYPE.IS_NOT_NULL.value) {
            transactionFilter.filterValue = null
          }
        })
      })
      return requestParameter
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-card) using ($integral-core-theme) {
  .rule-form {
    background-color: map-deep-get($integral-core-theme, 'views', 'transactionSummarySetting', 'rule', 'background');
    border: 1px solid map-deep-get($integral-core-theme, 'views', 'segmentSetting', 'segmentGroup', 'outerMost', 'border') !important;
  }
}

.info-column {
  min-width: 5em;
}

h4 {
  margin-bottom: 14px;
}
</style>
