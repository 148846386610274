export default {
  data () {
    return {
      JOB_TASK_IO_CATEGORY: {
        INPUT: { text: 'インプット', value: 1 },
        OUTPUT: { text: 'アウトプット', value: 2 },
      },
    }
  },
}
