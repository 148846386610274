<template>
  <line-chart :chart-data="cumputedChartData" :customize-options="customizeOptions" />
</template>

<script>
import CHART_DISPLAY_TYPES from '@/enum/CHART_DISPLAY_TYPES'

const BACKGROUND_COLOR = [
  '#4FB2E34D',
  '#A2A1A14D',
]

const BORDER_COLOR = [
  '#4FB2E3',
  '#A2A1A1',
]

export default {
  name: 'SummaryGraph',
  mixins: [CHART_DISPLAY_TYPES],
  props: {
    // lebelsとdata配列の1つ目の要素は増減計算用なのでグラフに表示されない
    chartData: {
      type: Object,
      default () {
        return {
          labels: [],
          datasets: [
            {
              label: 'Loading',
              data: [],
            },
          ],
        }
      },
    },
    customizeOptions: {
      type: Object,
      default () {
        return {}
      },
    },
    chartDisplayType: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    cumputedChartData () {
      // 参照を切る
      const chartData = JSON.parse(JSON.stringify(this.chartData))
      // チャート線を直線にする
      for (const index in chartData.datasets) {
        chartData.datasets[index].lineTension = 0
        chartData.datasets[index].backgroundColor = BACKGROUND_COLOR[index]
        chartData.datasets[index].borderColor = BORDER_COLOR[index]
      }
      // 一番目の要素は増減表示用なので除外する
      chartData.labels = chartData.labels.length > 0 ? chartData.labels.slice(1, chartData.labels.length) : chartData.labels

      if (this.chartDisplayType === this.CHART_DISPLAY_TYPES.ALL.value) {
        // 総数の場合、最初の要素だけ除外して表示
        for (const dataset of chartData.datasets) {
          dataset.data = dataset.data.length > 0 ? dataset.data.slice(1, dataset.data.length) : dataset.data
        }
        return chartData
      }

      // 増減の場合、差分を計算する
      for (const dataset of chartData.datasets) {
        const data = []
        for (let i = 0; i <= dataset.data.length; i++) {
          const num1 = dataset.data[i + 1]
          const num2 = dataset.data[i]
          let diff = null
          // 比較対象のいずれかがNumber型ではない場合、計算しない
          if ((num1 !== null && num1 !== undefined) && (num2 !== null && num2 !== undefined)) {
            // 小数第3位を四捨五入して丸め誤差を取り除く
            diff = Math.round((num1 - num2) * 100) / 100
          }
          data.push(diff)
        }
        dataset.data = data
      }
      return chartData
    },
  },
}
</script>
