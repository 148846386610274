<template>
  <div>
  <!-- v8.2.0 -->
  <h4 id="v8.2.0">v8.2.0 (2020/06/18~)</h4>
  <h5>SCV一覧</h5>
  <ul>
    <li>デフォルトで全てのSCVを一覧表示するようになりました。</li>
    <li>カラムの表示/非表示の切り替え、並べ替えができるようになりました。</li>
  </ul>
  <h5>SCV設定</h5>
  <ul>
    <li>項目一覧のカラム設定済み項目にアイコンが付きました。</li>
    <li>いくつかの不具合を修正しました。</li>
  </ul>
  <h5>セグメント一覧</h5>
  <ul>
    <li>各セグメントにユーザー数の前週比が追加されました。</li>
  </ul>
  <h5>セグメント詳細</h5>
  <ul>
    <li>レイアウトを一部変更しました。</li>
  </ul>
  <h5>セグメント設定</h5>
  <ul>
    <li>編集時に既存の設定が読み込まれるまで画面がローディング中を明示するようになりました。</li>
  </ul>
  <h5>ジョブ設定</h5>
  <ul>
    <li>入出力設定の疎通確認ボタンを追加しました。</li>
    <li>接続先のテンプレート保存ができるようになりました。</li>
  </ul>

  <!-- v8.1.2 -->
  <h4 id="v8.1.2">v8.1.2 (2020/06/09~)</h4>
  <h5>ジョブ設定</h5>
  <ul>
    <li>スケジュール設定に「指定なし」を追加しました。</li>
  </ul>

  <!-- v8.1.1 -->
  <h4 id="v8.1.1">v8.1.1 (2020/06/08~)</h4>
  <h5>ジョブ一覧</h5>
  <ul>
    <li>レイアウトを一部変更しました。</li>
  </ul>
  <h5>ジョブ設定</h5>
  <ul>
    <li>レイアウトを一部変更しました。</li>
  </ul>
  <!-- v8.1.0 -->
  <h4 id="v8.1.0">v8.1.0 (2020/04/28~)</h4>
    <h5>SCV設定</h5>
    <ul>
      <li>項目設定の保存ボタンを項目一覧に移動しました。設定が変更されると保存ボタンが表示されます。</li>
      <li>項目設定で選択されている顧客マスタをハイライトするようにしました。</li>
    </ul>
    <h5>セグメント一覧</h5>
    <ul>
      <li>レイアウトを変更しました。</li>
      <li>セグメントの作成日時・作成者を表示しました。</li>
    </ul>
    <h5>セグメント詳細</h5>
    <ul>
      <li>レイアウトを変更しました。</li>
    </ul>
    <h5>セグメント設定</h5>
    <ul>
      <li>セグメントに備考を登録できるようになりました。</li>
      <li>レイアウトを変更しました。</li>
    </ul>
    <h5>顧客詳細・匿名客詳細</h5>
    <ul>
      <li>レイアウトとカラーデザインを変更しました。</li>
    </ul>
    <h5>ジョブ結果一覧</h5>
    <ul>
      <li>ジョブ一覧からジョブごとの結果一覧画面に遷移できるように変更しました。</li>
    </ul>
    <h5>ジョブ設定</h5>
    <ul>
      <li>レイアウトを変更しました。</li>
    </ul>

  <!-- v8.0.0 -->
  <h4 id="v8.0.0">v8.0.0 (2020/04/20~)</h4>
    <h5>全般</h5>
    <ul>
      <li>
        軽微な不具合を修正しました
      </li>
    </ul>
    <h5>SingleCustomerView</h5>
    <ul>
      <li>
        SCV機能が追加されました
      </li>
    </ul>
    <h5>顧客マスタ一覧</h5>
    <ul>
      <li>
        顧客マスタの作成上限を撤廃しました
      </li>
    </ul>
    <h5>セグメント詳細</h5>
    <ul>
      <li>
        機能拡張に伴いサマリ情報を削除しました
      </li>
    </ul>
    <h5>セグメント設定</h5>
    <ul>
      <li>
        ユーザー検索結果のレイアウトを調整しました
      </li>
    </ul>
    <h5>匿名客詳細&nbsp;&#047;&nbsp;顧客詳細</h5>
    <ul>
      <li>
        レイアウトを一部調整しました
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'version8',
  minorVersions: [
    'v8.2.0',
    'v8.1.2',
    'v8.1.1',
    'v8.1.0',
    'v8.0.0',
  ],
}
</script>
