import * as buttonTypeFilter from './buttonTypeFilter'
import * as dateFilters from './dateFilters'
import * as datetimeFilters from './datetimeFilters'
import * as ellipsis from './ellipsis'
import * as filteringFilter from './filteringFilter'

export default {
  install (Vue) {
    Object.keys(buttonTypeFilter).forEach(key => {
      Vue.filter(key, buttonTypeFilter[key])
    })

    Object.keys(dateFilters).forEach(key => {
      Vue.filter(key, dateFilters[key])
    })

    Object.keys(datetimeFilters).forEach(key => {
      Vue.filter(key, datetimeFilters[key])
    })

    Object.keys(ellipsis).forEach(key => {
      Vue.filter(key, ellipsis[key])
    })

    Object.keys(filteringFilter).forEach(key => {
      Vue.filter(key, filteringFilter[key])
    })
  },
}
