var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',{attrs:{"href":"#record"}},[_vm._v(" "+_vm._s(_vm.$t("text.dataSource.record"))+" ")]),_c('v-tab',{attrs:{"href":"#schema"}},[_vm._v(" "+_vm._s(_vm.$t("text.dataSource.schema"))+" ")]),_c('v-tab',{attrs:{"href":"#connector"}},[_vm._v(" "+_vm._s(_vm.$t("text.dataSource.connector"))+" ")]),(
        _vm.dataSource.connectorType === _vm.CONNECTOR_TYPES.CSV.value ||
        _vm.dataSource.connectorType === _vm.CONNECTOR_TYPES.API.value
      )?_c('v-tab',{attrs:{"href":"#history"}},[_vm._v(" "+_vm._s(_vm.$t("text.dataSource.history"))+" ")]):_vm._e()],1),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',{attrs:{"value":"record"}},[_c('div',[_c('span',{staticClass:"ml-4 mr-16 recordCount"},[_c('span',{staticClass:"label"},[_vm._v("全")]),_vm._v(" "+_vm._s(_vm.addComma(_vm.dataSource.total))+" "),_c('span',{staticClass:"label ml-3"},[_vm._v("件")])]),_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('text.dataSource.recordDataImportDelay'))+" ")])]),(_vm.dataSource.dataSourceId)?_c('v-data-table',{attrs:{"headers":_vm.headerMetaHeader,"items":_vm.records,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","disable-sort":"","no-data-text":_vm.$t('text.dataSource.noRecord')},scopedSlots:_vm._u([{key:"item.firstDeviceType",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.enumUtil.getEnumFromValue(_vm.DEVICE_TYPES, item.firstDeviceType).text)+" ")]}},{key:"item.firstOsType",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.enumUtil.getEnumFromValue(_vm.OS_TYPES, item.firstOsType).text)+" ")]}},{key:"item.firstBrowserType",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.enumUtil.getEnumFromValue(_vm.BROWSER_TYPES, item.firstBrowserType).text)+" ")]}}],null,false,857343788)}):_vm._e()],1),_c('v-tab-item',{attrs:{"value":"schema"}},[_c('data-source-schema',_vm._g({ref:"dataSourceSchema",attrs:{"dataSet":_vm.dataSet,"dataSource":_vm.dataSource,"loading":_vm.loading}},_vm.$listeners))],1),_c('v-tab-item',{attrs:{"value":"connector"}},[_c('data-source-connector',_vm._g({attrs:{"dataSet":_vm.dataSet,"dataSource":_vm.dataSource,"measureSettings":_vm.measureSettings,"scheduledToBeImportedObjectNameList":_vm.scheduledToBeImportedObjectNameList,"loading":_vm.loading},on:{"onClickInferSchemaFromCsvButton":_vm.onClickInferSchemaFromCsvButton}},_vm.$listeners))],1),(
        _vm.dataSource.connectorType === _vm.CONNECTOR_TYPES.CSV.value ||
        _vm.dataSource.connectorType === _vm.CONNECTOR_TYPES.API.value
      )?_c('v-tab-item',{attrs:{"value":"history"}},[_c('data-source-import-history',{attrs:{"dataSourceImportHistories":_vm.dataSourceImportHistories,"paging":_vm.dataSourceImportHistoryPaging,"loading":_vm.loadingDataSourceImportHistories},on:{"getDataSourceImportHistories":function($event){return _vm.$emit('getDataSourceImportHistories')}}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }