export default {
  data () {
    return {
      CSV_IMPORT_TYPE: {
        REPLACEMENT: { text: '差し替え', value: 1 },
        DIFFERENCE: { text: '差分', value: 2 },
      },
    }
  },
}
