import axios from '@/axios'
import checkValueUtil from '@/utils/checkValueUtil'

const segmentExportScripts = {
  namespaced: true,
  state: {
    enabledFilter: true,
    filterParameter: {
      name: null,
      lastStatus: null,
      executable: null,
    },
    initFilterParameter: {
      name: null,
      lastStatus: null,
      executable: null,
    },
    sortParameter: {
      orderBy: 'createdAt',
      isDesc: true,
    },
    loadingSegmentExportScripts: true,
  },
  mutations: {
    setFilter (state, newVal) {
      state.filterParameter = newVal
    },
    setIsDesc (state, newVal) {
      state.sortParameter.isDesc = newVal
    },
    setOrderBy (state, newVal) {
      state.sortParameter.orderBy = newVal
    },
    filterReset (state) {
      Object.assign(state.filterParameter, JSON.parse(JSON.stringify(state.initFilterParameter)))
    },
    setEnabledFilter (state, newVal) {
      state.enabledFilter = newVal
    },
    setLoadingSegmentExportScripts (state, newval) {
      state.loadingSegmentExportScripts = newval
    },
  },
  getters: {
    getQueryParams (state) {
      const params = Object.assign({}, state.sortParameter, state.filterParameter, { enabledFilter: state.enabledFilter })
      for (const key in params) {
        if (checkValueUtil.isNullOrEmpty(params[key])) delete params[key]
      }
      return params
    },
  },
  actions: {
    async getSegmentExportScripts ({ commit, state }) {
      commit('setLoadingSegmentExportScripts', true)
      const queryParameter = {
        offset: 0,
        limit: 5000,
        isDesc: state.sortParameter.isDesc,
        orderBy: state.sortParameter.orderBy,
      }
      if (state.enabledFilter) {
        queryParameter.segmentExportScriptName = state.filterParameter.name
        queryParameter.execStatus = state.filterParameter.lastStatus
        queryParameter.executable = state.filterParameter.executable
      }
      return axios.get('/segmentExportScript/', { params: queryParameter })
        .then(res => {
          return Promise.resolve({
            segmentExportScripts: res.data.data.segmentExportScripts,
            paging: res.data.paging,
          })
        }).catch((err) => {
          return Promise.reject(err)
        }).finally(() => {
          commit('setLoadingSegmentExportScripts', false)
        })
    },
    async getExportScriptsBySegmentId ({ commit, state }, segmentId) {
      commit('setLoadingSegmentExportScripts', true)
      return await axios.get('/segmentExportScript/', {
        params: {
          segmentId: segmentId,
          offset: 0,
          limit: 5000,
          isDesc: state.sortParameter.isDesc,
          orderBy: state.sortParameter.orderBy,
        },
      })
        .then(res => {
          return Promise.resolve({
            segmentExportScripts: res.data.data.segmentExportScripts,
          })
        }).catch((err) => {
          return Promise.reject(err)
        }).finally(() => {
          commit('setLoadingSegmentExportScripts', false)
        })
    },
    filterReset ({ commit }) {
      commit('filterReset')
    },
    updateFilter ({ commit }, newVal) {
      commit('setFilter', newVal)
    },
    updateOrderBy ({ commit }, newVal) {
      commit('setOrderBy', newVal)
    },
    updateIsDesc ({ commit }, newVal) {
      commit('setIsDesc', newVal)
    },
    updateEnabledFilter ({ commit }, newVal) {
      commit('setEnabledFilter', newVal)
    },
    updateLoadingSegmentExportScripts ({ commit }, newval) {
      commit('setLoadingSegmentExportScripts', newval)
    },
  },
}
export default segmentExportScripts
