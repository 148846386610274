<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8" class="d-flex">
        <div>
          <div class="cardTitle customer" v-show="isScv">カスタマー</div>
          <div class="cardTitle" v-show="isUnknown">匿名客</div>
          <v-card outlined tile class="flex-grow-0 mr-3" width="132">
            <v-card-text class="text-center">
              <v-icon v-if="isScv" color="primary">ic-customer-M</v-icon>
              <v-icon v-else-if="isUnknown">ic-anonymous-M</v-icon>
              {{ segment.total }}{{ $t(`text.unitHuman`) }}
              <segment-user-circle-chart
                class="mt-6"
                :isScv="isScv"
                :isUnknown="isUnknown"
                :count="segment.total"
                :total="totalUser" />
            </v-card-text>
          </v-card>
        </div>
        <!-- 折れ線グラフ -->
        <div class="transition">
          <div class="cardTitle">ユーザー数の推移</div>
          <segment-user-line-chart :segment="segment" />
        </div>
      </v-col>
      <!-- 期間指定・フィルタ条件・構築種別 -->
      <v-col cols="4" class="pl-0">
        <div class="cardTitle">{{ $t(`text.filter`) }}</div>
        <segment-config-list
          :buildType="segment.buildType"
          :filterTextObject="filterTextObject"
          :jobSetting="segment.customSegmentJobSetting"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import segmentConfigList from '@/components/public/Segment/segmentConfigList'
import segmentUserCircleChart from '@/components/public/Segment/segmentUserCircleChart'
import segmentUserLineChart from '@/components/SegmentDetail/segmentUserLineChart'
// enum
import JOB_RECURRING_TYPES from '@/enum/JOB_RECURRING_TYPES'
import SEGMENT_BUILD_TYPE from '@/enum/SEGMENT_BUILD_TYPE'
import USER_TYPES from '@/enum/USER_TYPES'
// util
import enumUtil from '@/utils/enumUtil'
import segmentFilterUtil from '@/utils/segmentFilterUtil'

export default {
  name: 'innerInfo',
  mixins: [
    JOB_RECURRING_TYPES,
    SEGMENT_BUILD_TYPE,
    USER_TYPES,
    enumUtil,
    segmentFilterUtil,
  ],
  props: {
    segment: {
      type: Object,
    },
  },
  components: {
    'segment-config-list': segmentConfigList,
    'segment-user-circle-chart': segmentUserCircleChart,
    'segment-user-line-chart': segmentUserLineChart,
  },
  computed: {
    isUnknown () {
      return this.segment.userType === this.USER_TYPES.USER_UNKNOWN.value | false
    },
    isScv () {
      return this.segment.userType === this.USER_TYPES.SCV.value | false
    },
    totalUser () {
      if (this.isUnknown) {
        return this.$store.state.totalUser.totalUser.userUnknown.total || 0
      } else if (this.isScv) {
        return this.$store.state.totalUser.totalUser.userCustomer.total || 0
      }
      return 0
    },
    filterTextObject () {
      if (this.segment.buildType === this.SEGMENT_BUILD_TYPE.STANDARD.value && Object.prototype.hasOwnProperty.call(this.segment, 'filterRuleJson')) {
        return this.createSegmentFilterText(JSON.parse(this.segment.filterRuleJson))
      }
      return {
        logicalOperatorType: '',
        filterTextList: [],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.transition {
  width: 100%;
}
.cardTitle {
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 6px;
}
.v-card {
  height: calc(100% - 22px);
  .v-progress-circular {
    &::v-deep .v-progress-circular__info {
      font-size: 17px;
    }
  }
}
</style>
