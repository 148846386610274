<template>
  <v-container>
    <v-pagination
      v-model="page"
      :length="3"
    />
  </v-container>
</template>

<script>
export default {
  name: 'Pagination',
  data () {
    return {
      page: 1,
    }
  },
}
</script>
