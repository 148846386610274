import axios from '@/axios'

const segmentJobs = {
  namespaced: true,
  actions: {
    /**
     * セグメントに紐付くジョブ一覧リスト取得
     * @param  {Object}  params リクエストパラメーター
     * @return {Promise}
     */
    async getSegmentsJobs ({ commit }, params) {
      return await axios.get('/segments/' + params.segmentId + '/jobs/', {
        params: {
          offset: (params.page - 1) * params.limit,
          limit: params.limit,
        },
      })
        .then(res => {
          return Promise.resolve({
            jobs: res.data.data.jobs,
            paging: res.data.paging,
          })
        })
    },
  },
}
export default segmentJobs
