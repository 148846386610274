<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn :to="'/dataSet/setting/' + dataSetId">
          {{ $t(`btn.back`) }}
        </v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <v-row>
        <v-col>
          {{ $t('text.dataSet.dataSetName') }}&#58;&nbsp;{{ dataSet.dataSetName }}
        </v-col>
      </v-row>
      <v-row class="navigationWrapper">
        <div class="contentContainer">
          <v-card outlined :to="'/dataSet/setting/' + dataSetId + '/masterLinkSetting'" width="286" height="268">
            <v-card-text>
              <div>
                <!-- TODO 通常の v-icon に置き換え -->
                <!-- <v-icon>ic-checked-circle</v-icon> が使えないので暫定措置 -->
                <span v-show="hasMasterLinkRule" class="v-icon notranslate ic ic-checked-circle" style="font-size: 20px;"><span class="path1"></span><span class="path2"></span></span>
                顧客マスタ同士を連結する
              </div>
              <v-icon x-large>ic-customer-master-S</v-icon>
              <v-icon>ic-arrow</v-icon>
              <v-icon x-large>ic-customer-master-S</v-icon>
            </v-card-text>
          </v-card>
          <p v-html="$t('text.dataSourceLinkSetting.masterLinkSettingHint')"></p>
        </div>
        <div class="contentContainer">
          <v-card outlined :to="'/dataSet/setting/' + dataSetId + '/webLinkSetting'" width="286" height="268" :disabled="!canWebLink">
            <v-card-text>
              <div>
                <!-- TODO 通常の v-icon に置き換え -->
                <!-- TODO fix typo -->
                <!-- <v-icon>ic-checked-circle</v-icon> が使えないので暫定措置 -->
                <span v-show="hasWebLinkRule" class="v-icon notranslate ic ic-checked-circle" style="font-size: 20px;"><span class="path1"></span><span class="path2"></span></span>
                Web/SDK計測関連カラムと<br>顧客マスタを連結する
              </div>
              <img class="mx-auto" src="@/assets/logo-tate.png" width="124" />
              <v-icon>ic-arrow</v-icon>
              <v-icon x-large>ic-customer-master-S</v-icon>
            </v-card-text>
          </v-card>
          <p v-html="$t('text.dataSourceLinkSetting.webLinkSettingHint')"></p>
        </div>
        <div class="contentContainer">
          <v-card outlined :to="'/dataSet/setting/' + dataSetId + '/transactionLinkSetting'" width="286" height="268">
            <v-card-text>
              <div>
                <!-- TODO 通常の v-icon に置き換え -->
                <!-- TODO fix typo -->
                <!-- <v-icon>ic-checked-circle</v-icon> が使えないので暫定措置 -->
                <span v-show="hasTransactionLinkRule" class="v-icon notranslate ic ic-checked-circle" style="font-size: 20px;"><span class="path1"></span><span class="path2"></span></span>
                トランザクションと<br>顧客マスタを連結する
              </div>
              <v-icon x-large>ic-customer-master-S</v-icon>
              <v-icon>ic-arrow</v-icon>
              <v-icon x-large>ic-transaction-S</v-icon>
            </v-card-text>
          </v-card>
          <p v-html="$t('text.dataSourceLinkSetting.transactionLinkSettingHint')"></p>
        </div>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card elevation="0" outlined>
            <v-card-title>
              <v-icon>ic-info</v-icon>
              顧客マスタと連結とは？
            </v-card-title>
            <v-card-text v-html="$t('text.dataSourceLinkSetting.linkSettingHint')" />
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import CONNECTOR_TYPES from '@/enum/CONNECTOR_TYPES'
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'dataSourceLinkSetting',
  mixins: [
    CONNECTOR_TYPES,
    notifyUtil,
  ],
  data () {
    return {
      dataSetId: Number(this.$route.params.dataSetId),
      dataSet: {},
      hasMasterLinkRule: false,
      hasTransactionLinkRule: false,
      hasWebLinkRule: false,
      canWebLink: false,
    }
  },
  created () {
    this.fetchDataSet()
    this.fetchDataSetLinkRule()
  },
  methods: {
    fetchDataSet () {
      this.$store.dispatch('dataSet/getDataSet', this.dataSetId)
        .then((res) => {
          this.dataSet = res
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    fetchDataSetLinkRule () {
      // masterLinkRuleList から dataSourceId でmasterLinkRule と webLinkRule を分けるためデータソース一覧が必要
      this.$store.dispatch('dataSet/getDataSources', this.dataSetId)
        .then(res => {
          const masterList = res.data.data.masterList
          this.canWebLink = masterList.some((master) => master.connectorType === 3 || master.connectorType === 4 || master.connectorType === 5)
          this.$store
            .dispatch('dataSet/getDataSetLinkRule', this.dataSetId)
            .then(res => {
              this.hasMasterLinkRule = this.findMasterLinkRule(res.data.data.masterLinkRuleList, masterList) !== undefined
              this.hasWebLinkRule = this.findWebLinkRule(res.data.data.masterLinkRuleList, masterList) !== undefined
              this.hasTransactionLinkRule = res.data.data.transactionLinkRuleList.length > 0
            })
            .catch((err) => {
              this.handleErrorResponse(err)
            })
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    findMasterLinkRule (ruleList, masterList) {
      return ruleList.find(rule => {
        const origin = masterList.find(master => master.dataSourceId === rule.originDataSourceId)
        const target = masterList.find(master => master.dataSourceId === rule.targetDataSourceId)
        return origin.connectorType !== this.CONNECTOR_TYPES.WEB.value &&
          origin.connectorType !== this.CONNECTOR_TYPES.IOS.value &&
          origin.connectorType !== this.CONNECTOR_TYPES.ANDROID.value &&
          target.connectorType !== this.CONNECTOR_TYPES.WEB.value &&
          target.connectorType !== this.CONNECTOR_TYPES.IOS.value &&
          target.connectorType !== this.CONNECTOR_TYPES.ANDROID.value
      })
    },
    findWebLinkRule (ruleList, masterList) {
      return ruleList.find(rule => {
        const origin = masterList.find(master => master.dataSourceId === rule.originDataSourceId)
        const target = masterList.find(master => master.dataSourceId === rule.targetDataSourceId)
        return origin.connectorType === this.CONNECTOR_TYPES.WEB.value ||
          origin.connectorType === this.CONNECTOR_TYPES.IOS.value ||
          origin.connectorType === this.CONNECTOR_TYPES.ANDROID.value ||
          target.connectorType === this.CONNECTOR_TYPES.WEB.value ||
          target.connectorType === this.CONNECTOR_TYPES.IOS.value ||
          target.connectorType === this.CONNECTOR_TYPES.ANDROID.value
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.navigationWrapper {
  display: flex;
  justify-content: center;

  .contentContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 326px;
    text-align: center;

    &:first-child {
      margin-right: 24px;
    }

    .v-card {
      margin-bottom: 16px;
      .v-card__text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        font-weight: bold;

        .v-image {
          align-self: center;
        }

        .ic-checked-circle {
          position: absolute;
          top: 16px;
          left: 12px;
        }
      }
    }
  }
}
</style>
