<template>
  <v-row class="ma-0">
    <v-col cols="8">
      <v-list v-if="segments.length > 0" class="py-0" outlined subheader three-line>
        <v-list-item-group v-model="selectedSegment">
          <template v-for="(segment, index) in segments">
            <v-divider v-if="index > 0" :key="segment.segmentId + '_divider'" />
            <v-list-item :key="segment.segmentId" :value="segment">
              <v-list-item-content>
                <v-list-item-title>{{ segment.segmentName }}</v-list-item-title>
                <v-list-item-subtitle>{{ segment.segmentNote }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
      <div v-else>
        {{ $t(`text.userDetail.noUserSegment`) }}
      </div>
    </v-col>
    <v-col cols="4">
      <v-card class="selectedSegment" v-if="selectedSegment" outlined tile>
        <v-card-text>
          <div>
            <v-subheader>{{ $t(`text.id`) }}</v-subheader>
            <span class="ml-4">{{ selectedSegment.segmentId }}</span>
          </div>
          <v-divider class="mt-1 mb-2" />
          <div>
            <v-subheader>{{ $t(`text.note`) }}</v-subheader>
            <span class="ml-4">{{ selectedSegment.segmentNote }}</span>
          </div>
          <v-divider class="mt-1 mb-2" />
          <div>
            <v-subheader>{{ $t(`text.filter`) }}: {{ filterTextObject.logicalOperatorType }}</v-subheader>
            <v-list subheader dense>
              <!-- TODO 現状 buildType は API に含まれていないので常にカスタムセグメントではない通常のセグメントに判定される -->
              <v-list-item v-if="selectedSegment.buildType === SEGMENT_BUILD_TYPE.CUSTOM.value">
                <v-list-item-content>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-list-item-title v-on="on">{{ NO_RULE_CAUSED_BY_CUSTOM }}</v-list-item-title>
                    </template>
                    <span class="break-all">{{ NO_RULE_CAUSED_BY_CUSTOM }}</span>
                  </v-tooltip>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else-if="filterTextObject.filterTextList.length === 0">
                <v-list-item-content>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-list-item-title v-on="on">{{ NO_FILTER_RULES }}</v-list-item-title>
                    </template>
                    <span class="break-all">{{ NO_FILTER_RULES }}</span>
                  </v-tooltip>
                </v-list-item-content>
              </v-list-item>
              <template v-else v-for="(filterRule, index) in filterTextObject.filterTextList">
                <v-list-item :key="'rule_' + index">
                  <v-list-item-content>
                    <v-list-item-title>{{ filterRule.period }}</v-list-item-title>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-list-item-subtitle v-on="on">{{ filterRule.filter }}</v-list-item-subtitle>
                      </template>
                      <span class="break-all">{{ filterRule.filter }}</span>
                    </v-tooltip>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-4">
          <v-spacer />
          <v-btn small :to="{ name: 'SegmentDetail', params: { segmentId: selectedSegment.segmentId } }">このセグメントを確認する</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// enum
import SEGMENT_BUILD_TYPE from '@/enum/SEGMENT_BUILD_TYPE'
// util
import segmentTexts from '@/utils/segmentTexts'
import segmentFilterUtil from '@/utils/segmentFilterUtil'

export default {
  name: 'UserSegment',
  mixins: [
    SEGMENT_BUILD_TYPE,
    segmentTexts,
    segmentFilterUtil,
  ],
  props: {
    segments: {
      type: Array,
      default () {
        return []
      },
    },
  },
  data () {
    return {
      selectedSegment: null,
    }
  },
  computed: {
    filterTextObject () {
      if (Object.prototype.hasOwnProperty.call(this.selectedSegment, 'filterRuleJson')) {
        return this.createSegmentFilterText(JSON.parse(this.selectedSegment.filterRuleJson))
      }
      return {
        logicalOperatorType: '',
        filterTextList: [],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-list) using ($integral-core-theme) {
  .v-subheader {
    color: map-deep-get($integral-core-theme, 'lists', 'text-subheader');
  }
}
.selectedSegment {
  .v-subheader {
    padding: 0;
    font-size: 12px;
    line-height: 14px;
  }
}
</style>
