<template>
  <v-data-table
    class="mb-8"
    disable-filtering
    disable-pagination
    disable-sort
    hide-default-footer
    :headers="tableHeaders"
    :items="jobHistories"
    :loading="loading"
    fixed-header>
    <template #item.jobName="{ item }">
      {{ item.jobName }}
    </template>
    <template #item.jobSettingId="{ item }">
      <v-btn small :to="{ name: 'JobSettingEdit', params: { jobId: item.jobSettingId }}">{{ $t('text.jobHistories.detail') }}</v-btn>
    </template>
    <template #item.jobResult="{ item }">
      <v-chip readonly v-if="item.jobResult === JOB_EXEC_STATUS.WAIT.value" color="pending">
        {{ enumUtil.getEnumFromValue(JOB_EXEC_STATUS, item.jobResult).text }}
      </v-chip>
      <v-chip readonly v-else-if="item.jobResult === JOB_EXEC_STATUS.EXECUTING.value" color="ongoing">
        {{ enumUtil.getEnumFromValue(JOB_EXEC_STATUS, item.jobResult).text }}
      </v-chip>
      <v-chip readonly v-else-if="item.jobResult === JOB_EXEC_STATUS.SUCCESS.value" color="success">
        {{ enumUtil.getEnumFromValue(JOB_EXEC_STATUS, item.jobResult).text }}
      </v-chip>
      <v-chip readonly v-else-if="item.jobResult === JOB_EXEC_STATUS.FAIL.value" color="fault">
        {{ enumUtil.getEnumFromValue(JOB_EXEC_STATUS, item.jobResult).text }}
      </v-chip>
    </template>
    <template #item.jobMessage="{ item }">
      <v-tooltip v-if="item.taskMessage" bottom>
        <template #activator="{ on }">
          <div v-on="on">
            {{ item.jobTaskName }}
            <br>
            {{ item.taskMessage | ellipsis(20,'...') }}
          </div>
        </template>
        <div class="break-all">
          {{ item.jobTaskName }}
          <br>
          {{ item.taskMessage }}
        </div>
      </v-tooltip>
      <v-tooltip v-else bottom>
        <template #activator="{ on }">
          <span v-on="on">
            {{ item.jobMessage | ellipsis(20,'...') }}
          </span>
        </template>
        <span class="break-all">
          {{ item.jobMessage }}
        </span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
// enum
import JOB_EXEC_STATUS from '@/enum/JOB_EXEC_STATUS'
// util
import enumUtil from '@/utils/enumUtil'

export default {
  name: 'JobHistoryTable',
  mixins: [
    JOB_EXEC_STATUS,
    enumUtil,
  ],
  props: {
    jobHistories: {
      type: Array,
      default () {
        return []
      },
    },
    loading: {
      type: Boolean,
      default () {
        return false
      },
    },
  },
  data () {
    return {
      tableHeaders: [
        {
          text: this.$t('table.jobHistories.jobName'),
          value: 'jobName',
          width: '20%',
        },
        {
          text: this.$t('table.jobHistories.detail'),
          value: 'jobSettingId',
          width: '6%',
        },
        {
          text: this.$t('table.jobHistories.createDatetime'),
          value: 'createDatetime',
          width: '12%',
        },
        {
          text: this.$t('table.jobHistories.startDatetime'),
          value: 'startDatetime',
          width: '12%',
        },
        {
          text: this.$t('table.jobHistories.endDatetime'),
          value: 'endDatetime',
          width: '12%',
        },
        {
          text: this.$t('table.jobHistories.jobResult'),
          value: 'jobResult',
          width: '8%',
        },
        {
          text: this.$t('table.jobHistories.jobMessage'),
          value: 'jobMessage',
          width: '20%',
          sortable: false,
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.v-data-table ::v-deep .v-data-table__wrapper {
  max-height: 500px;
  tr {
    th {
      &.column {
        top: 48px !important;
      }
      font-size: 14px !important;
      padding-left: 12px !important;
      font-weight: 700 !important;
    }
    td {
      padding-left: 12px !important;
    }
  }
}
</style>
