var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"mt-6 lead-line"},[_vm._v("統合前データ")]),_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-card-text',[_c('v-data-table',{staticClass:"item-inPageScroll_x",attrs:{"id":"scvUnionTable","headers":_vm.modTableHeaders,"items":_vm.scvs,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","disable-sort":"","show-select":"","item-key":"scvId"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[(items.length === 0)?_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('text.scvUnion.noCustomer')))])]):_vm._e(),_vm._l((items),function(item){return _c('tr',{key:item.scvId},[_c('td',{staticClass:"text-center checkBox"},[(!item.uniond)?_c('v-checkbox',{staticClass:"mt-0",attrs:{"value":item,"color":"default","hide-details":""},model:{value:(_vm.computedTargetScv),callback:function ($$v) {_vm.computedTargetScv=$$v},expression:"computedTargetScv"}}):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({attrs:{"readonly":"","off-icon":"mdi-square-off-outline","color":"default"}},on))]}}],null,true)},[_c('span',{staticClass:"break-all"},[_vm._v(" "+_vm._s(_vm.$t('text.scvUnion.alreadySet'))+" ")])])],1),_c('td',{staticClass:"radio"},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"name":"defaultScv","hide-details":""},on:{"change":_vm.defaultChanged},model:{value:(_vm.computedDefaultScv),callback:function ($$v) {_vm.computedDefaultScv=$$v},expression:"computedDefaultScv"}},[_c('v-radio',{attrs:{"value":item.scvId,"color":"inputSelectionControl"}})],1)],1),_c('td',{staticClass:"detail"},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.openDetail(item.scvId)}}},[_vm._v(_vm._s(_vm.$t('text.scvUnion.detail')))])],1),_vm._l((_vm.tableHeaders),function(scvColumn,index){return _c('td',{key:scvColumn.scvColumnId},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn-toggle',{staticStyle:{"width":"100%"},attrs:{"tile":"","group":"","dense":""},model:{value:(_vm.computedSelectedColumns[index]),callback:function ($$v) {_vm.$set(_vm.computedSelectedColumns, index, $$v)},expression:"computedSelectedColumns[index]"}},[_c('v-btn',_vm._g({staticClass:"text-none justify-start",staticStyle:{"width":"100%"},attrs:{"text":"","value":item.scvId}},on),[_vm._v(" "+_vm._s(_vm._f("ellipsis")(_vm._f("getColumn")(item.scvColumnValueList,scvColumn.value),30,'...'))+" ")])],1)]}}],null,true)},[_c('span',{staticClass:"break-all"},[_vm._v(" "+_vm._s(_vm._f("getColumn")(item.scvColumnValueList,scvColumn.value))+" ")])])],1)})],2)})],2)]}}]),model:{value:(_vm.computedTargetScv),callback:function ($$v) {_vm.computedTargetScv=$$v},expression:"computedTargetScv"}})],1)],1),_c('h3',{staticClass:"mt-9 lead-line"},[_vm._v("統合後データ")]),_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-card-text',[_c('v-data-table',{staticClass:"item-inPageScroll_x",attrs:{"headers":_vm.afterUnionTableHeaders,"hide-default-footer":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"checkBox"}),_c('td',{staticClass:"radio"}),_c('td',{staticClass:"detail"}),_vm._l((_vm.tableHeaders),function(scvColumn){return _c('td',{key:scvColumn.scvColumnId},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"px-1"},[_c('span',_vm._g({staticClass:"unionedScvValue"},on),[_vm._v(" "+_vm._s(_vm._f("ellipsis")(_vm._f("getColumn")(_vm.unionedScv.scvColumnValueList,scvColumn.value),30,'...'))+" ")])])]}}],null,true)},[_c('span',{staticClass:"break-all"},[_vm._v(" "+_vm._s(_vm._f("getColumn")(_vm.unionedScv.scvColumnValueList,scvColumn.value))+" ")])])],1)})],2)])]},proxy:true}])})],1)],1),_c('v-navigation-drawer',{attrs:{"width":"80%","app":"","fixed":"","temporary":"","right":""},model:{value:(_vm.showDetailUserId),callback:function ($$v) {_vm.showDetailUserId=$$v},expression:"showDetailUserId"}},[_c('information-bar',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('v-btn',{on:{"click":function($event){_vm.showDetailUserId = false}}},[_vm._v(" "+_vm._s(_vm.$t("btn.close"))+" ")])]},proxy:true},{key:"right",fn:function(){return [_c('v-btn',{staticClass:"mx-5",attrs:{"icon":"","x-small":"","to":{ name: 'UserScvDetail', params: { scvId: _vm.showDetailUserId } },"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]},proxy:true}])}),(_vm.showDetailUserId)?[_c('user-scv-detail',{attrs:{"scvId":_vm.showDetailUserId}})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }