<template>
  <div>
    <!-- コネクタ CSV -->
    <template v-if="dataSource.connectorType === CONNECTOR_TYPES.CSV.value">
      <div class="sectionTitle">
        取込種別
      </div>
      <v-radio-group v-model="requestParameter.selectedCsvImportType" row mandatory>
        <v-radio :value="CSV_IMPORT_TYPE.REPLACEMENT.value" :label="CSV_IMPORT_TYPE.REPLACEMENT.text" />
        <v-radio :value="CSV_IMPORT_TYPE.DIFFERENCE.value" :label="CSV_IMPORT_TYPE.DIFFERENCE.text" />
        <v-btn small color="denial" class="mx-2" @click="requestParameter.selectedCsvImportType = dataSource.csvImportType">{{ $t('btn.reset') }}</v-btn>
        <v-btn small color="primary" class="mx-2" @click="onSaveCsvImportTypeClick" :disabled="requestParameter.selectedCsvImportType == dataSource.csvImportType">
          {{ $t('btn.update') }}
        </v-btn>
      </v-radio-group>
      <v-text-field
        ref="path"
        :label="$t(`text.masters.s3path.replacement`)"
        :disabled="requestParameter.selectedCsvImportType !== CSV_IMPORT_TYPE.REPLACEMENT.value"
        readonly
        @focus="$event.srcElement.select()"
        :value="replacementS3path"
      >
        <v-tooltip slot="append-outer" bottom>
          <template #activator="{ on }">
            <v-btn v-on="on" icon color="primary"
              class="ml-4"
              v-clipboard:copy="replacementS3path"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError">
              <v-icon>ic-copy-clipboard-S</v-icon>
            </v-btn>
          </template>
          <span>
            {{ $t(`btn.clipboardCopy`)}}
          </span>
        </v-tooltip>
      </v-text-field>
      <v-text-field
        ref="path"
        class="mt-2"
        :label="$t(`text.masters.s3path.difference`)"
        :disabled="requestParameter.selectedCsvImportType !== CSV_IMPORT_TYPE.DIFFERENCE.value"
        readonly
        @focus="$event.srcElement.select()"
        :value="differenceS3path"
      >
        <v-tooltip slot="append-outer" bottom>
          <template #activator="{ on }">
            <v-btn v-on="on" icon color="primary"
              class="ml-4"
              v-clipboard:copy="differenceS3path"
              v-clipboard:success="copySuccess"
              v-clipboard:error="copyError">
              <v-icon>ic-copy-clipboard-S</v-icon>
            </v-btn>
          </template>
          <span>
            {{ $t(`btn.clipboardCopy`)}}
          </span>
        </v-tooltip>
      </v-text-field>
      <div class="sectionTitle" v-if="objectNameItems && objectNameItems.length > 0">
        次回取込予定
      </div>
      <v-card outlined tile v-if="objectNameItems && objectNameItems.length > 0" class="mb-4">
        <v-card-text class="pa-3">
          <v-data-table
            :headers="[{ value: 'name' }]"
            :items="objectNameItems"
            dense
            hide-default-header
            hide-default-footer
            disable-pagination
            disable-sort
          />
          <v-divider />
          <div class="mt-3 text-center">
            <v-btn
              color="primary"
              @click="executeImporterDialog = true"
            >
              {{ $t(`btn.dataSource.executeImporter`)}}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <v-spacer />
      <div class="uploadLabel">
        {{ dataSource.dataSourceType === DATA_SOURCE_TYPES.MASTER.value ? $t('form.dataSource.uploadTitleMaster') : $t('form.dataSource.uploadTitleTransaction') }}
      </div>
      <master-upload
        :dataSourceId="dataSource.dataSourceId"
        :selectedCsvImportType="requestParameter.selectedCsvImportType"
        :disabledUpdateSchema="disabledUpdateSchema"
        :schemaNotDetermined="dataSource.userIdentifyColumn === null"
        @close="onCancelClick"
        @infer="onClickInferSchemaFromCsvButton"
      />

      <v-dialog
        v-model="executeImporterDialog"
        width="368"
        overlay-color="#07070820"
        overlay-opacity="1"
      >
        <v-card>
          <v-card-title>
            <!-- dialog 内の v-card には上部余白の為必要 -->
          </v-card-title>
          <v-card-text>{{ currentImportTypeText }}取込処理を起動してよろしいですか？</v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="denial" @click="executeImporterDialog = false">{{ $t('btn.no') }}</v-btn>
            <v-btn color="primary" @click="onExecuteImporterClick">{{ $t('btn.yes') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <!-- コネクタ API -->
    <template v-if="dataSource.connectorType === CONNECTOR_TYPES.API.value">
      <v-textarea filled readonly no-resize rows="10" :value="apiTag" />
    </template>

    <!-- コネクタ Web -->
    <template v-else-if="dataSource.connectorType === CONNECTOR_TYPES.WEB.value">
      <form @submit.prevent="onSaveWebDomainClick()" data-vv-scope="webDomain">
        <v-row>
          <v-col cols="6" class="pb-0">
            <v-text-field
              v-model="requestParameter.domain"
              :label="$t('text.dataSource.domain')"
              :disabled="dataSource.domain !== ''"
              name="web"
              :data-vv-as="$t(`form.sites.domain`)"
              v-validate="'required|domainSyntax|max:200'"
              :error-messages="errors.collect('webDomain.web')"
            />
          </v-col>
          <v-col cols="6" class="pb-0">
            <!-- 計測ドメイン -->
            <v-text-field
              v-if="dataSource.measureDomain"
              :value="dataSource.measureDomain"
              :label="$t('text.dataSource.measureDomain')"
              disabled
            />
            <v-autocomplete
              v-else
              v-model="requestParameter.measureSettingId"
              :items="measureSettings"
              item-value="measureSettingId"
              item-text="measureDomain"
              :label="$t(`text.dataSource.measureDomain`)"
              name="measureDomain"
              :data-vv-as="$t(`text.dataSource.measureDomain`)"
              v-validate="'required'"
              :error-messages="errors.collect('webDomain.measureDomain')" />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col>
            <v-textarea filled readonly hide-details no-resize rows="10" :value="siteTag" />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="denial" @click="onCancelClick">{{ $t('btn.cancel') }}</v-btn>
          <v-btn type="submit" color="primary">{{ $t('btn.update') }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </form>
    </template>

    <!-- コネクタ Android/iOS -->
    <template
      v-else-if="dataSource.connectorType === CONNECTOR_TYPES.ANDROID.value ||
        dataSource.connectorType === CONNECTOR_TYPES.IOS.value"
    >
      <form @submit.prevent="onSaveMobileDomainClick()" data-vv-scope="mobileDomain">
        <v-row>
          <v-col cols="4" class="pb-0">
            <v-text-field
              v-model="requestParameter.domain"
              :label="$t('text.dataSource.bundle')"
              :disabled="dataSource.domain !== ''"
              name="mobile"
              :data-vv-as="$t(`form.sites.domain`)"
              v-validate="'required|domainSyntax|max:200'"
              :error-messages="errors.collect('mobileDomain.mobile')"
            />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col>
            <v-textarea
              class="mobileSdkTextarea"
              :value="sdkJson"
              filled
              readonly
              hint="記載方法の詳細に関しては、管理メニューより手順書をご確認ください"
              persistent-hint
              no-resize
              rows="10"
            />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="denial" @click="onCancelClick">{{ $t('btn.cancel') }}</v-btn>
          <v-btn type="submit" color="primary">{{ $t('btn.update') }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </form>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// components
import masterUpload from './masterUpload'
// enum
import DATA_TYPES from '@/enum/DATA_TYPES'
import DATA_SOURCE_TYPES from '@/enum/DATA_SOURCE_TYPES'
import CONNECTOR_TYPES from '@/enum/CONNECTOR_TYPES'
import CSV_IMPORT_TYPE from '@/enum/CSV_IMPORT_TYPE'
// utils
import displayConverter from '@/utils/displayConverter'
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'
import momentUtil from '@/utils/momentUtil'

export default {
  name: 'DataSourceTabs',
  mixins: [
    DATA_TYPES,
    DATA_SOURCE_TYPES,
    CONNECTOR_TYPES,
    CSV_IMPORT_TYPE,
    displayConverter,
    enumUtil,
    notifyUtil,
    momentUtil,
  ],
  components: {
    masterUpload,
  },
  props: {
    dataSet: {
      type: Object,
    },
    dataSource: {
      type: Object,
    },
    measureSettings: {
      type: Array,
    },
    scheduledToBeImportedObjectNameList: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  data () {
    return {
      requestParameter: {
        dataSourceId: null,
        datetimeIdentifyColumn: null,
        transactionIdentifierColumn: [],
        domain: '',
        measureDomain: '',
        measureSettingId: null,
        selectedCsvImportType: null,
      },
      uploadFile: null,
      isDrag: false,
      headerMetaColumns: [],
      executeImporterDialog: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['canPut']),
    hashValue () {
      if (this.dataSource.dataSourceType === this.DATA_SOURCE_TYPES.MASTER.value) {
        return this.dataSource.masterHash
      } else if (this.dataSource.dataSourceType === this.DATA_SOURCE_TYPES.TRANSACTION.value) {
        return this.dataSource.transactionHash
      }
      return ''
    },
    apiTag () {
      // 「/」をエスケープするとでesLintエラーが起きてしまうのでesLintを一時的に無効化
      /* eslint-disable no-useless-escape */
      return `<script>
window.integralCore = window.integralCore || function(){
  (integralCore.params = integralCore.params||[]).push(arguments)
};
integralCore('', 'datasourceimport', '', '//{移管ドメイン}/');
var commonEl = document.createElement('script');
commonEl.src = '//cdn.{移管ドメイン}/js/integralCoreCommon.js';
commonEl.async = 1;
document.body.appendChild(commonEl);
function dataSourceImport(dataMetaJson) {
  let param = ['', 'datasourceimport', {
    "dataSourceHash": "${this.hashValue}",
    "dataMetaJson": dataMetaJson,
  }];
  integralCoreCommon.postDataSource(param);
}

// メソッド呼び出しサンプル
data = {
${
  this.headerMetaColumns.map(col => {
    return `  ${col.physicalName}: {{${this.dataTypes.find(type => type.value === col.dataType).text}}}`
  })
    .join(',\n')
}
};
dataSourceImport(data);
<\/script>`
      /* eslint-enable no-useless-escape */
    },
    siteTag () {
      // 「/」をエスケープするとでesLintエラーが起きてしまうのでesLintを一時的に無効化
      /* eslint-disable no-useless-escape */
      return `<script>
window.integralCore = window.integralCore || function(){
(integralCore.params=integralCore.params||[]).push(arguments)
};
integralCore('` + this.dataSource.siteHash + `', 'pageview');
<\/script>
<script async src='https://cdn.ic.${this.dataSource.measureDomain}/js/${this.dataSource.contractCompanyId}/${this.dataSource.siteMeasureScriptName}' type="text/javascript" charset="UTF-8"><\/script>`
      /* eslint-enable no-useless-escape */
    },
    sdkJson () {
      return `{
  "measure-url": "https://{移管対応済みのドメイン}/measure",
  "site-hash": "` + this.dataSource.siteHash + `"
}`
    },
    replacementS3path () {
      return process.env.VUE_APP_S3_BUCKET_NAME_PREFIX + this.$store.state.auth.loginUserDetail.loginUserWebWorkspaceName + process.env.VUE_APP_S3_BUCKET_NAME_SUFFIX + '/data-source-import/' + this.hashValue + '/replacement/'
    },
    differenceS3path () {
      return process.env.VUE_APP_S3_BUCKET_NAME_PREFIX + this.$store.state.auth.loginUserDetail.loginUserWebWorkspaceName + process.env.VUE_APP_S3_BUCKET_NAME_SUFFIX + '/data-source-import/' + this.hashValue + '/difference/'
    },
    dataTypes () {
      const tmpDataTypes = Object.values(this.DATA_TYPES)
      return tmpDataTypes
    },
    disabledUpdateSchema () {
      const transactionLink = this.dataSet.createRuleList?.find(rule =>
        this.dataSource.dataSourceId === rule.targetDataSourceId,
      )
      return this.dataSet.hasMasterLink || transactionLink || !this.canPut
    },
    objectNameItems: {
      get () {
        return this.scheduledToBeImportedObjectNameList?.map(objectName => ({ name: objectName }))
      },
    },
    currentImportTypeText: {
      get () {
        return this.enumUtil.getEnumFromValue(this.CSV_IMPORT_TYPE, this.dataSource.csvImportType)?.text
      },
    },
  },
  watch: {
    dataSource: {
      handler () {
        this.requestParameter.dataSourceId = this.dataSource.dataSourceId
        this.requestParameter.domain = this.dataSource.domain
        this.requestParameter.selectedCsvImportType = this.dataSource.csvImportType
        if (this.dataSource.headerMetaJson) {
          this.headerMetaColumns = JSON.parse(JSON.stringify(JSON.parse(this.dataSource.headerMetaJson).columns))
        } else {
          this.headerMetaColumns = []
        }
      },
      immediate: true,
    },
  },
  methods: {
    onClickInferSchemaFromCsvButton (columns) {
      this.$emit('onClickInferSchemaFromCsvButton', columns)
    },
    onSaveCsvImportTypeClick () {
      this.$emit('saveCsvImportType', this.requestParameter.selectedCsvImportType)
    },
    onSaveWebDomainClick () {
      this.$validator.validateAll('webDomain').then(result => {
        if (!result) return
        this.$emit('saveDomain', this.dataSource.dataSourceId, this.requestParameter.domain, this.requestParameter.measureSettingId)
      })
    },
    onSaveMobileDomainClick () {
      this.$validator.validateAll('mobileDomain').then(result => {
        if (!result) return
        this.$emit('saveDomain', this.dataSource.dataSourceId, this.requestParameter.domain)
      })
    },
    onExecuteImporterClick () {
      this.$emit('executeImporter')
      this.executeImporterDialog = false
    },
    onCancelClick () {
      this.$emit('cancel')
    },
    copySuccess () {
      this.notifySuccessMessage(this.$t('notify.success.copyToClipboard'))
    },
    copyError (e) {
      this.notifyErrorMessage(e)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-text-field) using ($integral-core-theme) {
  &.modified {
    background-color: map-deep-get($integral-core-theme, 'inputs', 'filled');
  }
}
@include theme(v-textarea) using ($integral-core-theme) {
  &.modified {
    background-color: map-deep-get($integral-core-theme, 'inputs', 'filled');
  }
  &.mobileSdkTextarea {
    ::v-deep .v-text-field__details .v-messages__message {
      color: map-deep-get($integral-core-theme, 'views', 'dataSourceDetail', 'connector', 'message');
    }
  }
}
@include theme(v-card) using ($integral-core-theme) {
  .v-card__actions {
    border-top: 1px solid map-deep-get($integral-core-theme, 'tabs', 'border');
  }
  .sectionTitle, .uploadLabel {
    color: map-deep-get($integral-core-theme, 'views', 'dataSourceDetail', 'connector', 'uploadLabel');
  }
}
.v-card {
  .v-card__actions {
    margin: 12px -12px 0;
  }
}
.v-dialog {
  .v-card .v-card__actions {
    margin: 0;
    border-top: none;
  }
}

.mobileSdkTextarea {
  ::v-deep .v-text-field__details {
    padding: 2px 0 0;
  }
}
.uploadLabel {
  position: relative;
  margin: 8px 0 -20px;
  z-index: 2;
}
</style>
