<template>
  <v-app :id="$route.meta.beforeLogin ? 'loginApp' : 'app' ">
    <global-sidebar v-if="showNavigation" />
    <!-- 通知コンポーネント -->
    <notifications :width="400" classes="notifications" closeOnClick />
    <v-main>
      <v-container fluid>
        <h2 v-if="showPageTitle">
          {{$route.meta.pageName}}
        </h2>
        <router-view :key="$route.path" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      showNavigation: this.$route.meta.hasNavigation,
      showPageTitle: !this.$route.meta.hidePageTitle,
    }
  },
  watch: {
    '$route' () {
      this.showNavigation = this.$route.meta.hasNavigation
      this.showPageTitle = !this.$route.meta.hidePageTitle
      document.title = this.$route.meta.pageName + ' | INTEGRAL-CORE'
    },
    '$vuetify.theme.dark': {
      handler () {
        this.changeIconMode()
      },
      immediate: true,
    },
  },
  mounted () {
    this.initDarkMode()
  },
  methods: {
    initDarkMode () {
      const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
      this.$vuetify.theme.dark = !!darkMediaQuery.matches

      darkMediaQuery.addEventListener('change', (e) => {
        this.$vuetify.theme.dark = !!e.matches
      })
    },
    changeIconMode () {
      // index.html の link タグを置き換え
      const themeElement = document.getElementById('iconLink')
      if (this.$vuetify.theme.dark) {
        themeElement.setAttribute('href', themeElement.getAttribute('href').replace('light', 'dark'))
      } else {
        themeElement.setAttribute('href', themeElement.getAttribute('href').replace('dark', 'light'))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-main {
  height: 100vh;
  width: 100%;
  overflow-x: auto;
}
.container {
  padding: 28px 24px 96px;
  min-width: 940px;
}

#loginApp {
  background: transparent linear-gradient(180deg, #054263 0%, #021E2E 100%) 0% 0% no-repeat padding-box;

  .v-main {
    display: flex;
    align-items: center;
  }
}
</style>
