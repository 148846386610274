import axios from '@/axios'
import checkValueUtil from '@/utils/checkValueUtil'

const accounts = {
  namespaced: true,
  state: {
    sortParameter: {
      orderBy: 'lastLoginDatetime',
      isDesc: true,
    },
    enabledFilter: true,
    filterParameter: {
      page: 1,
      limit: 50,
      keyword: null,
      roleId: null,
    },
    loadingAccounts: false,
    loadingAccount: false,
    postingAccount: false,
    putingAccount: false,
    deletingAccount: false,
    recreatingPassword: false,
  },
  mutations: {
    setOrderBy (state, newVal) {
      state.sortParameter.orderBy = newVal
    },
    setIsDesc (state, newVal) {
      state.sortParameter.isDesc = newVal
    },
    setFilter (state, newVal) {
      state.filterParameter = newVal
    },
    setEnabledFilter (state, newVal) {
      state.enabledFilter = newVal
    },
    setLoadingAccounts (state, newVal) {
      state.loadingAccounts = newVal
    },
    setLoadingAccount (state, newVal) {
      state.loadingAccount = newVal
    },
    setPostingAccount (state, newVal) {
      state.postingAccount = newVal
    },
    setPutingAccount (state, newVal) {
      state.putingAccount = newVal
    },
    setDeletingAccount (state, newVal) {
      state.deletingAccount = newVal
    },
    setRecreatingPassword (state, newVal) {
      state.recreatingPassword = newVal
    },
  },
  getters: {
    getQueryParams (state) {
      const params = Object.assign({}, state.sortParameter, state.filterParameter, { enabledFilter: state.enabledFilter })
      for (const key in params) {
        if (checkValueUtil.isNullOrEmpty(params[key])) delete params[key]
      }
      return params
    },
  },
  actions: {
    /**
     * アカウント一覧取得
     * @return {Promise}
     */
    async getAccounts ({ commit, state }) {
      commit('setLoadingAccounts', true)
      let queryOrderBy = 'last_login_datetime'
      if (state.sortParameter.orderBy) {
        queryOrderBy = state.sortParameter.orderBy.replace(/([A-Z])/g, (str) => { return '_' + str.charAt(0).toLowerCase() })
      }

      const queryParameter = {
        isDesc: state.sortParameter.isDesc,
        orderBy: queryOrderBy,
        offset: (state.filterParameter.page - 1) * state.filterParameter.limit,
        limit: state.filterParameter.limit,
        keyword: null,
        roleId: null,
      }
      if (state.enabledFilter) {
        queryParameter.keyword = state.filterParameter.keyword
        queryParameter.roleId = state.filterParameter.roleId
      }
      return axios.get('/account/user/', { params: queryParameter })
        .then(res => {
          return Promise.resolve({
            accountList: res.data.data.mloginUserDtoList,
            paging: res.data.paging,
          })
        }).catch((err) => {
          return Promise.reject(err)
        }).finally(() => {
          commit('setLoadingAccounts', false)
        })
    },
    async getAccount ({ commit }, accountId) {
      commit('setLoadingAccount', true)
      return axios.get('/account/user/' + accountId + '/')
        .finally(() => {
          commit('setLoadingAccount', false)
        })
    },
    /**
     * アカウント名とアカウントIDの一覧取得
     * @return {Promise}
     */
    async getAccountNameAndIdList () {
      return axios.get('/account/user/name/')
    },
    /**
     * アカウント1件作成
     * @param  {Object} postParameter アカウント作成用パラメーター
     * @return {Promise}
     */
    postAccount ({ commit }, postParameter) {
      commit('setPostingAccount', true)
      return axios.post('/account/user/', {
        loginUserName: postParameter.loginUserName,
        roleId: postParameter.roleId,
        emailAddress: postParameter.emailAddress,
        note: postParameter.note,
      }).finally(() => {
        commit('setPostingAccount', false)
      })
    },
    /**
     * アカウント1件更新
     * @param  {Object} putParameter アカウント更新用パラメーター
     * @return {Promise}
     */
    putAccount ({ commit }, putParameter) {
      commit('setPutingAccount', true)
      return axios.put('/account/user/' + putParameter.loginUserId + '/', {
        loginUserName: putParameter.loginUserName,
        roleId: putParameter.roleId,
        note: putParameter.note,
      }).finally(() => {
        commit('setPutingAccount', false)
      })
    },
    /**
     * ID指定アカウント1件削除
     * @param  {Number} loginUserId 削除対象アカウントID
     * @return {Promise}
     */
    deleteAccount ({ commit }, loginUserId) {
      commit('setDeletingAccount', true)
      return axios({ method: 'delete', url: '/account/user/' + loginUserId + '/' })
        .finally(() => {
          commit('setDeletingAccount', false)
        })
    },
    /**
     * ID指定アカウントパスワード再発行
     * @param  {Number} loginUserId 再発行アカウントID
     * @return {Promise}
     */
    recreatePassword ({ commit }, loginUserId) {
      commit('setRecreatingPassword', true)
      return axios({ method: 'put', url: '/account/user/recreatePassword/' + loginUserId + '/' })
        .finally(() => {
          commit('setRecreatingPassword', false)
        })
    },
    updateOrderBy ({ commit }, newVal) {
      commit('setOrderBy', newVal)
    },
    updateIsDesc ({ commit }, newVal) {
      commit('setIsDesc', newVal)
    },
    updateFilter ({ commit }, newVal) {
      commit('setFilter', newVal)
    },
    updateEnabledFilter ({ commit }, newVal) {
      commit('setEnabledFilter', newVal)
    },
  },
}
export default accounts
