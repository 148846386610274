<template>
  <v-container fluid>
    <v-row v-if="!scvTransactionSettingList" class="py-3" :justify="'center'">
      <v-progress-circular indeterminate color="primary" />
    </v-row>
    <v-row v-else-if="scvTransactionSettingList.length === 0">
      <v-col>
        {{ $t(`text.userDetail.noTransactionSummary`) }}
      </v-col>
    </v-row>
    <div v-else>
      <v-row class="mb-0">
        <v-col v-for="num in pageColumnCount" :key="num" :cols="12 / pageColumnCount">
          <v-list>
            <template v-for="(transaction, index) in scvTransactionSettingListLimitedPageItem.slice((num - 1) * pageItemLimit / pageColumnCount, num * (pageItemLimit / pageColumnCount))">
              <v-list-item :key="'transaction_' + index" two-line>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ transaction.transactionName }} ({{ transaction.updatedAt }}{{ $t('text.update')}})</v-list-item-subtitle>
                  <div v-for="(transactionValue, valueIndex) in transaction.values" :key="'transactionValue_' + index + '_' + valueIndex">
                    {{ transactionValue.value }} <span class="grey--text">({{ transactionValue.scvId }})</span>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="index + '_divider'" />
            </template>
          </v-list>
        </v-col>
      </v-row>
      <v-pagination v-model="page" :length="pages"></v-pagination>
    </div>
  </v-container>
</template>

<script>
const PAGE_ITEM_LIMIT = 20
const PAGE_COLUMN_COUNT = 2

export default {
  name: 'userTransactionSummary',
  props: {
    scvTransactionSettingList: {
      type: Array,
      default () {
        return null
      },
    },
  },
  data () {
    return {
      pageItemLimit: PAGE_ITEM_LIMIT,
      pageColumnCount: PAGE_COLUMN_COUNT,
      page: 1,
    }
  },
  computed: {
    pages () {
      if (!this.scvTransactionSettingList) return 1
      return Math.ceil(this.scvTransactionSettingList.length / PAGE_ITEM_LIMIT)
    },
    scvTransactionSettingListLimitedPageItem () {
      return this.scvTransactionSettingList.slice((this.page - 1) * PAGE_ITEM_LIMIT, this.page * PAGE_ITEM_LIMIT)
    },
  },
}
</script>
