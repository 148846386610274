<template>
  <v-card class="editorWrapper" tile outlined>
    <div ref="editor" class="monaco-editor"></div>
  </v-card>
</template>

<script>
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api'

export default {
  name: 'sqlEditor',
  props: {
    value: {
      type: String,
      default () {
        return ''
      },
    },
  },
  data () {
    return {
      monaco: null,
      editor: null,
      model: null,
      subscription: null,
    }
  },
  watch: {
    '$vuetify.theme.dark': {
      handler () {
        if (this.editor) {
          this.monaco.editor.setTheme(this.$vuetify.theme.dark ? 'vs-dark' : 'vs')
        }
      },
      immediate: true,
    },
    value () {
      if (this.value !== this.editor.getValue()) {
        this.editor.getModel().setValue(this.value === null ? '' : this.value)
      }
    },
  },
  async mounted () {
    this.initEditorSetting()

    const el = this.$refs.editor
    this.monaco = monaco

    this.editor = this.monaco.editor.create(el, {
      value: this.value,
      language: 'sql',
      theme: this.$vuetify.theme.dark ? 'vs-dark' : 'vs',
      wordWrap: true,
      automaticLayout: true,
      minimap: {
        enabled: false,
      },
    })

    window.addEventListener('resize', (e) => {
      this.editor.layout()
    })

    this.setOnDidChangeContent()
  },
  destroyed () {
    this.editor?.dispose()
    this.model?.dispose()
    this.subscription?.dispose()
  },
  methods: {
    initEditorSetting () {
      // SQL エディタの linter/codeCompletionSuggestions 設定は特になし
    },
    setOnDidChangeContent () {
      this.subscription = this.editor.getModel().onDidChangeContent(() => {
        const value = this.editor.getValue()
        this.$emit('input', value)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.editorWrapper {
  height: 100%;
}
.monaco-editor {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
</style>
