export default {
  data () {
    return {
      DAY_OF_MONTH_LIST: {
        DAY_1: { text: '1', value: { day1: 1 } },
        DAY_2: { text: '2', value: { day2: 1 } },
        DAY_3: { text: '3', value: { day3: 1 } },
        DAY_4: { text: '4', value: { day4: 1 } },
        DAY_5: { text: '5', value: { day5: 1 } },
        DAY_6: { text: '6', value: { day6: 1 } },
        DAY_7: { text: '7', value: { day7: 1 } },
        DAY_8: { text: '8', value: { day8: 1 } },
        DAY_9: { text: '9', value: { day9: 1 } },
        DAY_10: { text: '10', value: { day10: 1 } },
        DAY_11: { text: '11', value: { day11: 1 } },
        DAY_12: { text: '12', value: { day12: 1 } },
        DAY_13: { text: '13', value: { day13: 1 } },
        DAY_14: { text: '14', value: { day14: 1 } },
        DAY_15: { text: '15', value: { day15: 1 } },
        DAY_16: { text: '16', value: { day16: 1 } },
        DAY_17: { text: '17', value: { day17: 1 } },
        DAY_18: { text: '18', value: { day18: 1 } },
        DAY_19: { text: '19', value: { day19: 1 } },
        DAY_20: { text: '20', value: { day20: 1 } },
        DAY_21: { text: '21', value: { day21: 1 } },
        DAY_22: { text: '22', value: { day22: 1 } },
        DAY_23: { text: '23', value: { day23: 1 } },
        DAY_24: { text: '24', value: { day24: 1 } },
        DAY_25: { text: '25', value: { day25: 1 } },
        DAY_26: { text: '26', value: { day26: 1 } },
        DAY_27: { text: '27', value: { day27: 1 } },
        DAY_28: { text: '28', value: { day28: 1 } },
        DAY_29: { text: '29', value: { day29: 1 } },
        DAY_30: { text: '30', value: { day30: 1 } },
        DAY_31: { text: '31', value: { day31: 1 } },
        LAST_DAY: { text: '末日', value: { lastday: 1 } },
      },
    }
  },
}
