<template>
  <v-card outlined tile>
    <v-card-text>
      <v-autocomplete
        v-model="selectedDataSet"
        :label="$t(`form.scvSetting.selectableMaster`)"
        :placeholder="$t(`text.scvSetting.scvSettingMasterList.notExistMaster`)"
        :items="dataSetRowInfoList"
        item-text="dataSetName"
        return-object>
        <template #append>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon v-on="on">ic-help-S</v-icon>
            </template>
            <span v-html="$t(`tooltip.scvSetting.selectableMaster`)" />
          </v-tooltip>
        </template>
      </v-autocomplete>
      <div v-if="selectedDataSet && selectedDataSetHeader">
        <v-tabs
          v-model="computedSelectedCandidateIndex"
          show-arrows
          @change="updateCandidateIndex"
        >
          <v-tab
            v-for="(_, i) in selectedScvColumnChoiceSetting.headerMeta"
            :key="i"
          >
            {{ i + 1 }}
          </v-tab>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn v-on="on" icon class="align-self-end" @click="addCandidate"><v-icon>ic-add</v-icon></v-btn>
            </template>
            <span v-html="$t(`tooltip.scvSetting.candidate`)" />
          </v-tooltip>
        </v-tabs>

        <p>{{ $t(`text.scvSetting.scvSettingMasterList.heading`)}}</p>
        <v-list class="item-inPageScroll_y" dense subheader no-action>
          <v-list-item
            v-for="(header, index) in selectedDataSetHeader"
            :key="header.physicalName"
            :input-value="header.isScvColumn"
            :disabled="scvSetting.dataType !== header.dataType && !header.isScvColumn"
            ripple
            @click.stop="onClickDataSetColumn(index)">
            <v-list-item-icon class="align-self-center mr-0">
              {{ header.idx === null ? '' : header.idx + 1 }}
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ header.logicalName }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <div v-else-if="selectedDataSet && !selectedDataSetHeader">
        <p>{{ $t(`text.scvSetting.scvSettingMasterList.notExistMasterHeader`)}}</p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'scvSettingMasterList',
  props: {
    dataSetRowInfoList: {
      type: Array,
      default () {
        return []
      },
    },
    scvSetting: {
      type: Object,
      default () {
        return null
      },
    },
    selectedDataSetId: {
      type: Number,
      default: 0,
    },
    selectedCandidateIndex: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      selectedDataSet: null,
      selectedDataSetHeader: null,
      selectedScvColumnChoiceSetting: { headerMeta: [[]] },
    }
  },
  watch: {
    scvSetting: {
      handler () {
        this.fetchSelectedDataSet()
      },
      deep: true,
    },
    selectedDataSet: {
      handler () {
        this.fetchSelectedDataSet()
      },
      deep: true,
    },
    selectedCandidateIndex: {
      handler () {
        this.fetchSelectedDataSet()
      },
    },
    selectedDataSetId (newVal) {
      if (newVal > 0) {
        // 項目設定で選択されたマスタを顧客マスタ一覧でも選択状態にする
        this.selectedDataSet = this.dataSetRowInfoList.find(dataSetRowInfo => dataSetRowInfo.dataSetId === newVal)
      }
    },
  },
  computed: {
    computedSelectedCandidateIndex: {
      get () {
        return this.selectedCandidateIndex
      },
      set (newVal) {
        this.updateCandidateIndex(newVal)
      },
    },
  },
  methods: {
    fetchSelectedDataSet () {
      this.selectedScvColumnChoiceSetting = { headerMeta: [[]] }
      if (this.selectedDataSet) {
        if (this.scvSetting) {
          this.selectedScvColumnChoiceSetting =
            this.scvSetting.scvColumnChoiceSettingList.find(choiceSetting => {
              return choiceSetting.dataSetId === this.selectedDataSet.dataSetId
            })
          if (this.selectedScvColumnChoiceSetting === undefined) {
            this.selectedScvColumnChoiceSetting = { headerMeta: [[]] }
          }
        }
        this.$emit('selectDataSetId', this.selectedDataSet.dataSetId)
        this.selectedDataSetHeader = null
        if (this.selectedDataSet.headerMeta) {
          this.selectedDataSetHeader = this.selectedDataSet.headerMeta
          this.selectedDataSetHeader.forEach((headerColumn) => {
            const scvProperty = this.isSelectedColumn(headerColumn)
            headerColumn.idx = scvProperty.idx
            headerColumn.isScvColumn = scvProperty.isScvColumn
          })
        }
      }
    },
    onClickDataSetColumn (index) {
      // キーの並び順を統一するため詰め替える
      const tmpSelectHeaderMeta = {
        idx: this.selectedDataSetHeader[index].idx,
        logicalName: this.selectedDataSetHeader[index].logicalName,
        physicalName: this.selectedDataSetHeader[index].physicalName,
        dataType: this.selectedDataSetHeader[index].dataType,
        disabled: this.selectedDataSetHeader[index].disabled,
      }
      const newScvColumnChoiceSetting = {
        dataSetId: this.selectedDataSet.dataSetId,
        dataSetName: this.selectedDataSet.dataSetName,
        priority: null, // 値は保存ボタン押下時にいれるが、並び順確保のため宣言しておく
        columnJoinDelimiter: '-',
        headerMeta: tmpSelectHeaderMeta,
      }
      this.$emit('addScvColumnChoiceSetting', newScvColumnChoiceSetting, this.computedSelectedCandidateIndex)
    },
    isSelectedColumn (column) {
      if (!this.scvSetting ||
        this.selectedScvColumnChoiceSetting.headerMeta.length === 0 ||
        this.selectedScvColumnChoiceSetting.headerMeta[this.computedSelectedCandidateIndex] === undefined) {
        return { scvColumnIndex: null, isScvColumn: false }
      }
      for (const header of this.selectedScvColumnChoiceSetting.headerMeta[this.computedSelectedCandidateIndex]) {
        if (column.physicalName === header.physicalName) {
          return { idx: header.idx, isScvColumn: true }
        }
      }
      return { idx: null, isScvColumn: false }
    },
    updateCandidateIndex (index) {
      this.$emit('updateCandidateIndex', index)
    },
    addCandidate () {
      // 未設定の候補を増産しない
      if (this.selectedScvColumnChoiceSetting.headerMeta[this.computedSelectedCandidateIndex].length === 0) {
        return
      }
      this.selectedScvColumnChoiceSetting.headerMeta.push([])
      this.updateCandidateIndex(this.selectedScvColumnChoiceSetting.headerMeta.length - 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.item-inPageScroll_y {
  height: 50vh;
}
.v-list-item {
  border-bottom: solid thin rgba(0, 0, 0, 0.12);
}

.v-tab {
  min-width: 60px;
}
</style>
