<template>
  <v-container>
    <large-list
      v-for="(list, index) in lists"
      :key="index"
      :name="list.name"
      :note="list.note"
    >
      <!-- ヘッダーがある場合のみ使用するSlot. v-row が必要 -->
      <template #header>
        <v-row>
          <v-col cols="6">権限: {{list.role}}</v-col>
          <v-col cols="6">作成日時: {{list.createdAt}}</v-col>
        </v-row>
      </template>
      <!-- 2列目ののSlot -->
      <template #col2>
        #col2
      </template>
      <!-- 3列目ののSlot -->
      <template #col3>
        #col3: {{list.mailAddress}}
      </template>
    </large-list>
  </v-container>
</template>

<script>
import LargeList from '@/components/common/largeList'

export default {
  name: 'LargeLists',
  components: {
    LargeList,
  },
  data () {
    return {
      lists: [
        { name: '管理アカウント', mailAddress: 'admin@example.com', role: '管理者', note: '削除禁止', createdAt: '2021/04/01 12:34' },
        { name: '一般アカウント', mailAddress: 'account@example.com', role: '一般', note: '', createdAt: '2021/04/01 12:34' },
        { name: 'マーケター', mailAddress: 'marketing@example.com', role: '閲覧のみ', note: '', createdAt: '2021/04/01 12:34' },
      ],
    }
  },
}
</script>
