<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn color="primary" :to="{ name: 'AccountSetting'}" :disabled="!canPost">{{ $t(`btn.create`) }}</v-btn>
      </template>
      <template #right>
        <v-btn @click="getAccounts()"><v-icon>ic-reload</v-icon></v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <filter-form
        @search="setFilterParameter"
        @reset="resetFilterParameter"
        @switchFilter="val => isFiltering = val"
        :isFiltering="isFiltering"
        :filteredTotal="paging.filteredTotalCount"
        :total="paging.totalCount"
      >
        <v-row>
          <v-col class="py-0" cols="6">
            <v-text-field
              :value="filterParameter.keyword"
              @input="preparedFilters.keyword = $event"
              :label="$t(`text.accounts.filtering.keyword`)"
              clearable
              :disabled="!isFiltering" />
          </v-col>
          <v-col class="py-0" cols="3">
            <v-select
              :value="filterParameter.roleId"
              @change="preparedFilters.roleId = $event"
              :items="roleList"
              :label="$t(`text.accounts.filtering.role`)"
              clearable
              :disabled="!isFiltering || !canGetThis('AccountRoles')" />
          </v-col>
        </v-row>
      </filter-form>
      <!-- paging/sort/limit -->
      <v-row justify="space-between" class="my-0">
        <v-col cols="4">
          <v-select
            v-model="orderBy"
            class="sort pt-0"
            hide-details
            :items="sortableColumns"
            :disabled="loadingAccounts"
          >
            <template #prepend>
              <span class="prepend-sort-text">
                {{ $t('text.sort') }}
              </span>
            </template>
            <template #append-outer>
              <v-btn icon @click="isDesc = !isDesc" :disabled="loadingAccounts">
                <v-icon v-if="isDesc">ic-sort-desc</v-icon>
                <v-icon v-else>ic-sort-asc</v-icon>
              </v-btn>
            </template>
          </v-select>
        </v-col>
        <v-col cols="5">
          <v-pagination :value="filterParameter.page" @input="changeQuery('page', $event)" :length="pages" total-visible="7" :disabled="loadingAccounts" />
        </v-col>
        <v-spacer />
        <v-col cols="2">
          <v-select
            v-model="limit"
            class="limits pt-0"
            hide-details
            :items="limits"
            :disabled="loadingAccounts"
          >
            <template #prepend>
              <span class="prepend-sort-text">
                {{ $t('text.limits') }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <!-- table -->
      <v-card tile outlined>
        <v-card-text class="pa-3">
          <accounts-table
            :accounts="accountList"
            :loading="loadingAccounts"
            @clickRow="onClickTableRow($event)"
          />
        </v-card-text>
      </v-card>
      <v-row align="center" justify="space-between" class="mt-0">
        <v-col offset="4" cols="4">
          <v-pagination :value="filterParameter.page" @input="changeQuery('page', $event)" :length="pages" total-visible="7" :disabled="loadingAccounts" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// component
import AccountsTable from '@/components/Accounts/AccountsTable'
import FilterForm from '@/components/common/filterForm'
// util
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'

const DEFAULT_PAGE = 1
const DEFAULT_LIMIT = 50

export default {
  name: 'Accounts',
  components: {
    AccountsTable,
    FilterForm,
  },
  mixins: [
    enumUtil,
    notifyUtil,
  ],
  data () {
    return {
      sortableColumns: [
        {
          text: this.$t('text.accounts.sort.loginUserName'),
          value: 'loginUserName',
        },
        {
          text: this.$t('text.accounts.sort.emailAddress'),
          value: 'emailAddress',
        },
        {
          text: this.$t('text.accounts.sort.roleName'),
          value: 'roleName',
        },
        {
          text: this.$t('text.accounts.sort.lastLoginDatetime'),
          value: 'lastLoginDatetime',
        },
        {
          text: this.$t('text.accounts.sort.createdAt'),
          value: 'createdAt',
        },
      ],
      limits: [
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
      accountList: [],
      paging: {},
      roleList: [],
      preparedFilters: [],
      isFiltering: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['canPost', 'canGetThis']),
    ...mapGetters({ queryParams: 'accounts/getQueryParams' }),
    pages () {
      return Math.ceil(this.paging.filteredTotalCount / this.paging.limit) || 1
    },
    limit: {
      get () {
        return this.$store.state.accounts.filterParameter.limit
      },
      set (newVal) {
        this.changeQuery('limit', newVal)
      },
    },
    orderBy: {
      get () {
        return this.$store.state.accounts.sortParameter.orderBy
      },
      set (newVal) {
        this.changeQuery('orderBy', newVal)
      },
    },
    isDesc: {
      get () {
        return this.$store.state.accounts.sortParameter.isDesc
      },
      set (newVal) {
        this.changeQuery('isDesc', newVal)
      },
    },
    enabledFilter () {
      return this.$store.state.accounts.enabledFilter
    },
    filterParameter () {
      return this.$store.state.accounts.filterParameter
    },
    loadingAccounts () {
      return this.$store.state.accounts.loadingAccounts
    },
  },
  created () {
    if (this.canGetThis('AccountRoles')) {
      this.$store.dispatch('accountRole/getAccountRoles')
        .then(response => {
          response.data.data.roleList.forEach(role => {
            this.roleList.push({ text: role.roleName, value: role.roleId })
          })
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    }

    const tmpQuery = {}
    for (const key in this.$route.query) {
      tmpQuery[key] = this.$route.query[key]
    }

    // クエリが不十分な場合補う
    let isQuerySufficient = true
    for (const key in this.queryParams) {
      if (tmpQuery[key] === null || tmpQuery[key] === undefined) {
        tmpQuery[key] = this.queryParams[key]
        isQuerySufficient = false
      }
    }

    // orderByに指定された文字列がソート基準の選択肢にあるなら問題ない
    const validateOrderBy = this.sortableColumns.some((column) => tmpQuery.orderBy === column.value)
    if (!validateOrderBy) {
      tmpQuery.orderBy = 'lastLoginDatetime'
      isQuerySufficient = false
    }
    if (tmpQuery.isDesc !== true && tmpQuery.isDesc !== false && tmpQuery.isDesc !== 'true' && tmpQuery.isDesc !== 'false') {
      tmpQuery.isDesc = true
      isQuerySufficient = false
    }
    tmpQuery.page = Number(tmpQuery.page)
    if (!Number.isInteger(tmpQuery.page) || tmpQuery.page < 1) {
      tmpQuery.page = DEFAULT_PAGE
      isQuerySufficient = false
    }
    tmpQuery.limit = Number(tmpQuery.limit)
    if (!Number.isInteger(tmpQuery.limit) || tmpQuery.limit < 1 || tmpQuery.limit > 5000) {
      tmpQuery.limit = DEFAULT_LIMIT
      isQuerySufficient = false
    }

    // クエリを変更した場合はURLを置き換える
    if (!isQuerySufficient) {
      this.$router.replace({ query: tmpQuery })
        .catch(err => err) // 画面表示時の NavigationDuplicated 対策
      // 置き換わるとbeforeRouteUpdateが呼び出されるのでこちらの処理は中断する
      return
    }

    this.reflectQueryParamsToState(tmpQuery)
    this.preparedFilters = this.filterParameter
    this.isFiltering = this.enabledFilter
    this.getAccounts()
  },
  beforeRouteUpdate (to, from, next) {
    // ルート変更に反応する
    if (to.query !== from.query) {
      this.reflectQueryParamsToState(to.query)
      this.getAccounts()
    }
    next()
  },
  methods: {
    changeQuery (type, value) {
      const queryParams = this.queryParams

      if (type === 'filter') {
        queryParams.keyword = value.keyword
        queryParams.roleId = value.roleId
        queryParams.enabledFilter = this.isFiltering
      } else {
        queryParams[type] = value
      }

      if (type !== 'page') {
        queryParams.page = DEFAULT_PAGE
      }

      // クエリパラメーター更新
      // クエリパラメーターを更新するとbeforeRouteUpdateが呼び出される
      this.$router.push({ query: queryParams })
        .catch(err => err) // 画面表示時の NavigationDuplicated 対策
    },
    /**
     * アカウント一覧リストを再取得
     */
    getAccounts () {
      // アカウント一覧再取得
      this.$store.dispatch('accounts/getAccounts')
        .then(res => {
          this.accountList = res.accountList
          this.paging = res.paging
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    },
    onClickTableRow (accountId) {
      this.$router.push({ name: 'AccountEdit', params: { accountId: accountId } })
    },
    reflectQueryParamsToState (query) {
      this.$store.dispatch('accounts/updateOrderBy', query.orderBy)
      this.$store.dispatch('accounts/updateIsDesc', JSON.parse(query.isDesc))
      this.$store.dispatch('accounts/updateEnabledFilter', JSON.parse(query.enabledFilter))
      const tmpFilterParameter = []
      if (Object.keys(query).length > 0) {
        // filterParameter として許可されているプロパティのみ有効
        for (const key in this.filterParameter) {
          if (query[key] === undefined && query[key] === null) {
            tmpFilterParameter[key] = null
          } else {
            tmpFilterParameter[key] = query[key]
          }
          // this.$route.query には文字列型で入ってるので、数値型にキャストできるなら変換
          if (tmpFilterParameter[key] !== null && tmpFilterParameter[key] !== '' && !Number.isNaN(Number(tmpFilterParameter[key]))) {
            tmpFilterParameter[key] = Number(tmpFilterParameter[key])
          }
          // this.$route.query には文字列型で入ってるので、boolean型にしたいものは変換
          if (tmpFilterParameter[key] === 'true') {
            tmpFilterParameter[key] = true
          }
          if (tmpFilterParameter[key] === 'false') {
            tmpFilterParameter[key] = false
          }
        }
      }
      this.$store.dispatch('accounts/updateFilter', tmpFilterParameter)
    },
    /**
     * フィルタリング条件の適用
     */
    setFilterParameter () {
      this.changeQuery('filter', this.preparedFilters)
    },
    /**
     * フィルタリング条件の初期化
     */
    resetFilterParameter () {
      this.preparedFilters = []
      this.setFilterParameter()
    },
  },
}
</script>
