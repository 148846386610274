<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>デフォルトサイズ</h2>
        <v-btn>デフォルト</v-btn>
        <v-btn color="primary">primary</v-btn>
        <v-btn color="secondary">secondary</v-btn>
        <v-btn color="denial">denial</v-btn>
        <v-btn disabled>disabled</v-btn>
        <v-btn loading>loading</v-btn>
      </v-col>
      <v-col>
        <h2>small</h2>
        <v-btn small>デフォルト</v-btn>
        <v-btn small color="primary">primary</v-btn>
        <v-btn small color="secondary">secondary</v-btn>
        <v-btn small color="denial">denial</v-btn>
        <v-btn small disabled>disabled</v-btn>
        <v-btn small loading>loading</v-btn>
      </v-col>
      <v-col>
        <h2>large</h2>
        <v-btn large>デフォルト</v-btn>
        <v-btn large color="primary">primary</v-btn>
        <v-btn large color="secondary">secondary</v-btn>
        <v-btn large color="denial">denial</v-btn>
        <v-btn large disabled>disabled</v-btn>
        <v-btn large loading>loading</v-btn>
      </v-col>
      <v-col>
        <h2>depressed outlined tile</h2>
        <v-btn depressed outlined tile>デフォルト</v-btn>
        <v-btn depressed outlined tile color="primary">primary</v-btn>
        <v-btn depressed outlined tile color="secondary">secondary</v-btn>
        <v-btn depressed outlined tile color="denial">denial</v-btn>
        <v-btn depressed outlined tile disabled>disabled</v-btn>
        <v-btn depressed outlined tile loading>loading</v-btn>
      </v-col>
      <v-col>
        <h2>text</h2>
        <v-btn text>デフォルト</v-btn>
        <v-btn text color="primary">primary</v-btn>
        <v-btn text color="secondary">secondary</v-btn>
        <v-btn text color="denial">denial</v-btn>
        <v-btn text disabled>disabled</v-btn>
        <v-btn text loading>loading</v-btn>
      </v-col>
      <v-col>
        <h2>icon</h2>
        <v-btn icon><v-icon>mdi-account</v-icon></v-btn>
        <v-btn icon color="primary"><v-icon>mdi-account</v-icon></v-btn>
        <v-btn icon color="secondary"><v-icon>mdi-account</v-icon></v-btn>
        <v-btn icon color="denial"><v-icon>mdi-account</v-icon></v-btn>
        <v-btn icon disabled><v-icon>mdi-account</v-icon></v-btn>
        <v-btn icon loading><v-icon>mdi-account</v-icon></v-btn>
      </v-col>
      <v-col>
        <h2>fab depressed</h2>
        <v-btn fab depressed><v-icon>mdi-account</v-icon></v-btn>
        <v-btn fab depressed color="primary"><v-icon>mdi-account</v-icon></v-btn>
        <v-btn fab depressed color="secondary"><v-icon>mdi-account</v-icon></v-btn>
        <v-btn fab depressed color="denial"><v-icon>mdi-account</v-icon></v-btn>
        <v-btn fab depressed disabled><v-icon>mdi-account</v-icon></v-btn>
        <v-btn fab depressed loading><v-icon>mdi-account</v-icon></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Buttons',
}
</script>

<style lang="scss" scoped>
.col {
  display:flex;
  flex-flow: column;
  align-items: center;
  justify-content:space-between;
  min-height: 500px;
}
</style>
