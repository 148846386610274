<template>
  <div>
    <!-- バケット名 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="bucket"
          :label="$t(`form.jobSetting.jobTask.bucket`)"
          name="bucket"
          :data-vv-as="$t(`form.jobSetting.jobTask.bucket`)"
          v-validate="'required'"
          :error-messages="errors.collect('bucket')" />
      </v-col>
    </v-row>
    <!-- ファイル名 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="filePath"
          :label="$t(`form.jobSetting.jobTask.file`)"
          name="filepath"
          :data-vv-as="$t(`form.jobSetting.jobTask.file`)"
          v-validate="'required'"
          :error-messages="errors.collect('filepath')">
          <v-tooltip slot="append" bottom content-class="placeholderTooltip">
            <template #activator="{ on }">
              <v-icon v-on="on">ic-help-S</v-icon>
            </template>
            <v-card tile flat>
              <v-card-title>利用可能なプレースホルダー</v-card-title>
              <v-card-text class="pa-3">
                <v-data-table
                  :headers="[{ text: 'プレースホルダ', value: 'placeholder' }, { text: '出力', value: 'output' },]"
                  :items="[
                    { placeholder: '%%env.yyyy%%', output: '年(YYYY)' },
                    { placeholder: '%%env.mm%%', output: '月(MM)' },
                    { placeholder: '%%env.dd%%', output: '日(DD)' },
                    { placeholder: '%%env.hh%%', output: '時(HH)' },
                    { placeholder: '%%env.mi%%', output: '分(MI)' },
                    { placeholder: '%%env.ss%%', output: '秒(SS)' },
                    { placeholder: '%%job_setting_id%%', output: 'JOB設定ID' },
                    { placeholder: '%%job_task_setting_id%%', output: 'JOBタスク設定ID' },
                  ]"
                  hide-default-header
                  hide-default-footer
                  disable-pagination
                  disable-sort
                />
              </v-card-text>
            </v-card>
          </v-tooltip>
        </v-text-field>
      </v-col>
    </v-row>
    <!-- 登録済みJSON -->
    <v-row v-show="registeredAuthenticationkeyFileName != null">
      <v-col class="pb-0" cols="12">
        <v-text-field class="gcs-key"
          v-model="registeredAuthenticationkeyFileName"
          :label="$t(`form.jobSetting.jobTask.authJsonKey`)"
          readonly>
          <v-tooltip slot="append" bottom :max-width="420">
            <template #activator="{ on }">
              <v-icon v-on="on">ic-help-S</v-icon>
            </template>
            <span v-html="$t(`tooltip.jobSetting.hasGcsAuthFile`)" />
          </v-tooltip>
        </v-text-field>
      </v-col>
    </v-row>
    <!-- 選択済みJSON -->
    <v-row v-show="isUploaded">
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="uploadedAuthenticationkeyFileName"
          :label="$t(`form.jobSetting.jobTask.uploadedJsonkey`)"
          name="uploadedAuthenticationkeyFileName"
          :data-vv-as="$t(`form.jobSetting.jobTask.uploadedJsonkey`)"
          v-validate="{ emptyFile: uploadedAuthenticationkeyFileValue }"
          readonly
          clearable
          @click:clear="resetUploadJsonFile"
          :error-messages="errors.collect('uploadedAuthenticationkeyFileName')" />
      </v-col>
    </v-row>
    <!-- ドロップエリア -->
    <v-row v-show="!isUploaded">
      <v-col class="pb-0 dropArea" cols="12">
        <div class="caption mb-2 mt-n1" :class="{ 'error--text': !existsAuthenticationkeyFile }">{{ $t(`form.jobSetting.jobTask.jsonKey`) }}</div>
        <v-card
          tile
          class="mb-2"
          :class="[{ 'inputform_file-dragover': isDrag }, { 'fileError': !existsAuthenticationkeyFile }, 'inputform_file', 'text-center']"
          @dragover.prevent="isDrag=true"
          @dragleave.prevent="isDrag=false"
          @drop.prevent="checkFile($event.dataTransfer.files)">
          <span class="inputform_text">
            <span v-html="$t(`form.jobSetting.jobTask.dropArea`)" />
            <!-- TODO: vuetifyのfile inputフォームに置き換える -->
            <input id="outputAuthenticationkeyFile" type="file" accept=".json" @change="checkFile($event.target.files)">
          </span>
        </v-card>
        <div v-if="!existsAuthenticationkeyFile" class="error--text errorMessage">{{ $t('notify.error.jobs.noAuthFile') }}</div>
        <div v-else class="errorMessage"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// enum
import JSON_OUTPUT_ITEM_NAMES from '@/enum/jobTask/JSON_OUTPUT_ITEM_NAMES'
// util
import notifyUtil from '@/utils/notifyUtil'
import enumUtil from '@/utils/enumUtil'

export default {
  mixins: [
    JSON_OUTPUT_ITEM_NAMES,
    notifyUtil,
    enumUtil,
  ],
  props: ['outputForm'],
  data () {
    return {
      isDrag: false,
      isUploaded: false,
      existsAuthenticationkeyFile: true,
    }
  },
  computed: {
    bucket: {
      get () {
        if (this.checkSettingProp()) {
          return this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.GCS.value].bucket
        }
        return null
      },
      set (value) {
        this.updateValue('bucket', value)
      },
    },
    filePath: {
      get () {
        if (this.checkSettingProp()) {
          return this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.GCS.value].filePath
        }
        return null
      },
      set (value) {
        this.updateValue('filePath', value)
      },
    },
    registeredAuthenticationkeyFileName: {
      get () {
        if (this.checkSettingProp()) {
          return this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.GCS.value].authenticationkeyFileName
        }
        return null
      },
    },
    registeredAuthenticationkeyFileValue: {
      get () {
        if (this.checkSettingProp()) {
          return this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.GCS.value].authenticationkeyFileValue
        }
        return null
      },
    },
    uploadedAuthenticationkeyFileName: {
      get () {
        if (this.checkSettingProp()) {
          return this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.GCS.value].uploadedAuthenticationkeyFileName
        }
        return null
      },
      set (value) {
        this.updateValue('uploadedAuthenticationkeyFileName', value)
      },
    },
    uploadedAuthenticationkeyFileValue: {
      get () {
        if (this.checkSettingProp()) {
          return this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.GCS.value].uploadedAuthenticationkeyFileValue
        }
        return null
      },
    },
  },
  watch: {
    outputForm: {
      handler () {
        this.$emit('checkValidate')
        this.setIsUploaded()
      },
      deep: true,
    },
  },
  methods: {
    /**
     * GCSのタスクがどうかを判定
     */
    checkSettingProp () {
      return this.outputForm && this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.GCS.value]
    },
    /**
     * ファイルの形式を確認する
     * @param  {File} targetFiles 選択またはドロップされたファイル
     */
    checkFile (targetFiles) {
      this.isDrag = false
      if (targetFiles.length > 1) this.notifyErrorMessage(this.$t('notify.error.file.notMultipleUpload'))

      const type = targetFiles[0].name.split('.')
      if (type[type.length - 1].toLowerCase() !== 'json') this.notifyErrorMessage(this.$t('notify.error.file.notJson'))

      if (targetFiles.length > 1 || type[type.length - 1].toLowerCase() !== 'json') return
      this.loadJsonFile(targetFiles[0])
    },
    /**
     * JSONファイルの読み込み
     * @param  {File} file 読み込み対象のJSONファイル
     */
    loadJsonFile (file) {
      this.updateValue('uploadedAuthenticationkeyFileName', file.name)

      const reader = new window.FileReader()
      // JSONファイル読み込み完了時のイベントを指定
      reader.onloadend = event => {
        this.updateValue('uploadedAuthenticationkeyFileValue', event.target.result)
      }
      // JSONファイル読み込み開始
      reader.readAsText(file)

      // 他のタスクの入力フォームに選択したファイル名が表示されてしまうため、入力フォームを初期化
      if (document.getElementById('outputAuthenticationkeyFile').value) document.getElementById('outputAuthenticationkeyFile').value = ''
      this.isUploaded = true
    },
    /**
     * 選択済みのJSONファイルを初期化
     */
    resetUploadJsonFile () {
      this.updateValue('uploadedAuthenticationkeyFileValue', null)
      this.updateValue('uploadedAuthenticationkeyFileName', null)
      this.isUploaded = false
    },
    /**
     * ファイル選択済みの判定
     */
    setIsUploaded () {
      this.isUploaded = this.outputForm != null &&
        this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.GCS.value] != null &&
        this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.GCS.value].uploadedAuthenticationkeyFileName != null
    },
    /**
     * GCS用タスクの値を更新
     * @param  {String} propertyName  更新対象のキー
     * @param  {String} propertyValue 更新対象の値
     */
    updateValue (propertyName, propertyValue) {
      if (!this.outputForm) return
      const json = Object.assign({}, this.outputForm)
      if (!json[this.JSON_OUTPUT_ITEM_NAMES.GCS.value]) {
        this.$set(json, this.JSON_OUTPUT_ITEM_NAMES.GCS.value, {})
      }
      Object.assign(json[this.JSON_OUTPUT_ITEM_NAMES.GCS.value], { [propertyName]: propertyValue })
      this.$emit('update', json)
    },
    /**
     * VeeValidateと認証キーJSONのバリデート
     * taskFormから呼び出し
     * @returns {Promise<boolean>}
     */
    async validateOutputGcs () {
      this.existsAuthenticationkeyFile = false

      if ((!this.registeredAuthenticationkeyFileName || !this.registeredAuthenticationkeyFileValue) &&
        !this.uploadedAuthenticationkeyFileName) {
        await this.$validator.validateAll()
        return false
      }
      this.existsAuthenticationkeyFile = true

      return await this.$validator.validateAll()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-card) using ($integral-core-theme) {
  &.fileError {
    border-color: map-deep-get($integral-core-theme, 'views', 'jobSetting', 'error', 'border');
  }
}
@include theme(v-input) using ($integral-core-theme) {
  &.gcs-key {
    ::v-deep.v-input__slot {
      background-color: map-deep-get($integral-core-theme, 'views', 'jobSetting', 'gcsKey', 'background');
    }
  }
}

.caption {
  line-height: 16px !important;
}
#outputAuthenticationkeyFile {
  width: 100%;
  height: 30px;
}
.inputform_file {
  box-shadow: none !important;
  height: 25vh;
}
.errorMessage {
  font-size: 12px;
  height: 12px;
}

.placeholderTooltip.menuable__content__active {
  opacity: 1!important;
}
</style>
