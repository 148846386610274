<template>
  <div>
    <v-row v-if="!dataSetRows" :justify="'center'">
      <v-progress-circular indeterminate color="primary" />
    </v-row>
    <v-row v-else-if="dataSetRows.length === 0">
      <v-col>
        {{ $t(`text.userDetail.noFragments`) }}
      </v-col>
    </v-row>
    <v-row v-else class="ma-0">
      <v-col cols="3" class="py-0">
        <v-card outlined tile>
          <v-list class="dataSourceList" subheader>
            <v-list-item-group v-model="selectedDatasetRow" mandatory>
              <template v-for="(dataSetRow) in dataSetRows">
                <v-subheader :key="'dataSetName' + dataSetRow.dataSetId">
                  {{ dataSetRow.dataSetName }}
                </v-subheader>
                <template v-for="dataSource in dataSetRow.dataSourceList">
                  <v-list-item :key="dataSource.dataSourceId" :value="dataSource" :disabled="dataSource.dataMetaList.length === 0" inset>
                    <v-list-item-content>
                      <v-list-item-title>{{ dataSource.dataSourceName }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="dataSource.dataSourceId + '_divider'" />
                </template>
              </template>
              <v-list-item v-if="dataSetRows.length === 0">
                <v-list-item-content>
                  <v-list-item-title>{{ $t('text.userDetail.noDatasetRows') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="9" two-line class="py-0">
        <template v-if="selectedDatasetRow">
          <v-card outlined tile v-for="(dataMeta, index) in selectedDatasetRow.dataMetaList" :key="index">
            <v-list  v-for="(value, key) in dataMeta" :key="index + '_' + key" subheader>
              <v-divider :key="index + '_' + key + '_divider'" />
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ key }}</v-list-item-subtitle>
                  <v-list-item-title>{{ value }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </template>
        <v-card v-else outlined tile >
          <v-card-text>
            {{ $t('text.userDetail.noSelectedDatasetRow') }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'fragmens',
  props: {
    dataSetRows: {
      type: Array,
    },
  },
  data () {
    return {
      selectedDatasetRow: undefined,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-list) using ($integral-core-theme) {
  &.dataSourceList {
    .v-subheader {
      color: map-deep-get($integral-core-theme, 'lists', 'text-subheader');
    }
  }
}
.dataSourceList {
  overflow-y: scroll;
  height: 60vh;
  .v-subheader {
    height: 30px;
    font-size: 12px;
    line-height: 14px;
  }
  .v-list-item {
    padding: 0 12px 0 24px;
    min-height: 40px;
    line-height: 16px;
  }
}

</style>
