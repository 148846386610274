export default {
  data () {
    return {
      FILTER_TYPE: {
        FIRST_DEVICE_TYPE: { filterType: 1, dataType: 5, filterLogicalName: '初回デバイス種別', filterPhysicalName: 'first_device_type' },
        FIRST_OS_TYPE: { filterType: 2, dataType: 5, filterLogicalName: '初回OS種別', filterPhysicalName: 'first_os_type' },
        FIRST_BROWSER_TYPE: { filterType: 3, dataType: 5, filterLogicalName: '初回ブラウザ種別', filterPhysicalName: 'first_browser_type' },
        FIRST_IP_ADDRESS: { filterType: 4, dataType: 1, filterLogicalName: '初回IPアドレス', filterPhysicalName: 'first_ip_address' },
        FIRST_ACCESS_DATETIME: { filterType: 5, dataType: 4, filterLogicalName: '初回アクセス日時', filterPhysicalName: 'first_access_datetime' },
        LAST_ACCESS_DATETIME: { filterType: 6, dataType: 4, filterLogicalName: '最終アクセス日時', filterPhysicalName: 'last_access_datetime' },
        SCV_COLUMN: { filterType: 7, dataType: null, filterLogicalName: 'SCV項目', filterPhysicalName: 'scv_column' },
        SESSION_CNT: { filterType: 8, dataType: 2, filterLogicalName: 'セッション数', filterPhysicalName: 'session_cnt' },
        PV: { filterType: 9, dataType: 2, filterLogicalName: 'PV数', filterPhysicalName: 'pv' },
        SUMMARY_TIME_ON_SITE: { filterType: 10, dataType: 2, filterLogicalName: '総滞在時間(秒)', filterPhysicalName: 'summary_time_on_site' },
        TRANSACTION_SUMMARY: { filterType: 14, dataType: null, filterLogicalName: 'トランザクションサマリ', filterPhysicalName: 'transaction_summary' },
      },
    }
  },
}
