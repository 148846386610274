<template>
  <v-card outlined tile>
    <v-card-text>
      <v-text-field
        v-model="jobTaskName"
        class="large"
        :label="$t(`form.jobSetting.jobTask.name`)"
        :placeholder="$t(`form.jobSetting.jobTask.namePlaceholder`)"
        persistent-placeholder
        :prefix="String(formJobTask.jobTaskOrder)"
        name="jobTaskName"
        :data-vv-as="$t(`form.jobSetting.jobTask.name`)"
        v-validate="'required|max:200'"
        :error-messages="errors.collect('jobTaskName')" />
      <v-radio-group v-model.number="jobTaskType" class="jobTaskType" row label="タスク種別">
        <v-radio color="inputSelectionControl" :label="JOB_TASK_TYPES.IO.text" :value="JOB_TASK_TYPES.IO.value" />
        <v-radio color="inputSelectionControl" :label="JOB_TASK_TYPES.SQL.text" :value="JOB_TASK_TYPES.SQL.value" />
      </v-radio-group>
      <v-row>
        <v-container v-show="formJobTask.jobTaskType === JOB_TASK_TYPES.IO.value" fluid>
          <v-row class="py-3 justify-center">
            <!-- inputフォーム -->
            <v-col cols="6">
              <div class="mb-3"><b>インプット</b></div>
              <v-select
                v-model="inputEtlType"
                hide-details
                :items="selectableTaskList"
                :label="$t(`form.jobSetting.jobTask.inputTypes`)"
                name="inputType"
                :data-vv-as="$t(`form.jobSetting.jobTask.inputTypes`)"
                v-validate="'required'"
                :error-messages="errors.collect('inputType')">
              </v-select>
              <v-row
                v-show="inputEtlType !== INPUT_ETL_TYPES.SEGMENT.value"
                class="mt-3 mb-7"
                justify="center">
                <v-btn
                  small
                  @click.stop="onClickGetConnectionSettingsButton(JOB_TASK_IO_CATEGORY.INPUT.value)">
                  テンプレート選択
                </v-btn>
              </v-row>

              <input-s3
                ref="inputS3"
                v-show="inputEtlType === INPUT_ETL_TYPES.S3.value"
                :input-form="inputValue"
                @checkValidate="checkValidate()"
                @update="val => inputValue = val" />
              <input-db
                ref="inputDb"
                v-show="inputEtlType === INPUT_ETL_TYPES.AURORA.value || inputEtlType === INPUT_ETL_TYPES.REDSHIFT.value"
                :etlType="inputEtlType"
                :input-form="inputValue"
                @checkValidate="checkValidate()"
                @update="val => inputValue = val" />
              <input-segment
                ref="inputSegment"
                v-show="inputEtlType === INPUT_ETL_TYPES.SEGMENT.value"
                :input-form="inputValue"
                @checkValidate="checkValidate()"
                @update="val => inputValue = val" />
              <input-gcs
                ref="inputGcs"
                v-show="inputEtlType === INPUT_ETL_TYPES.GCS.value"
                :etlType="inputEtlType"
                :input-form="inputValue"
                @checkValidate="checkValidate()"
                @update="val => inputValue = val" />
              <input-sftp
                ref="inputSftp"
                v-show="inputEtlType === INPUT_ETL_TYPES.SFTP.value"
                :input-form="inputValue"
                @checkValidate="checkValidate()"
                @update="val => inputValue = val" />
              <v-row
                v-show="inputEtlType !== INPUT_ETL_TYPES.SEGMENT.value"
                class="mt-3 pt-3">
                <v-col>
                  <v-btn
                    block
                    color="secondary"
                    @click.stop="onClickCheckConnection(JOB_TASK_IO_CATEGORY.INPUT.value)">
                    疎通確認
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-show="inputEtlType !== INPUT_ETL_TYPES.SEGMENT.value"
                class="pb-3"
                :justify="'center'">
                <v-btn small @click.stop="saveInputTaskSetting()">
                  テンプレートとして保存
                </v-btn>
              </v-row>
            </v-col>
            <!-- outputフォーム -->
            <v-col cols="6">
              <div class="mb-3"><b>アウトプット</b></div>
              <v-select
                v-model="outputEtlType"
                hide-details
                :items="enumUtil.convertForSelectList(OUTPUT_ETL_TYPES)"
                :label="$t(`form.jobSetting.jobTask.outputTypes`)"
                name="outputType"
                :data-vv-as="$t(`form.jobSetting.jobTask.outputTypes`)"
                v-validate="'required'"
                :error-messages="errors.collect('outputType')" />
              <v-row class="mt-3 mb-7" justify="center">
                <v-btn
                  small
                  @click.stop="onClickGetConnectionSettingsButton(JOB_TASK_IO_CATEGORY.OUTPUT.value)">
                  テンプレート選択
                </v-btn>
              </v-row>

              <output-s3
                ref="outputS3"
                v-show="outputEtlType === OUTPUT_ETL_TYPES.S3.value"
                :output-form="outputValue"
                @checkValidate="checkValidate()"
                @update="val => outputValue = val" />
              <output-db
                ref="outputDb"
                v-show="outputEtlType === OUTPUT_ETL_TYPES.AURORA.value || outputEtlType === OUTPUT_ETL_TYPES.REDSHIFT.value"
                :etlType="outputEtlType"
                :output-form="outputValue"
                @checkValidate="checkValidate()"
                @update="val => outputValue = val" />
              <output-gcs
                ref="outputGcs"
                v-show="outputEtlType === OUTPUT_ETL_TYPES.GCS.value"
                :etlType="outputEtlType"
                :output-form="outputValue"
                @checkValidate="checkValidate()"
                @update="val => outputValue = val" />
              <v-row class="mt-3 pt-3">
                <v-col>
                  <v-btn
                    block
                    color="secondary"
                    @click.stop="onClickCheckConnection(JOB_TASK_IO_CATEGORY.OUTPUT.value)">
                    疎通確認
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                class="pb-3"
                :justify="'center'">
                <v-btn small @click.stop="saveOutputTaskSetting()">
                  テンプレートとして保存
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-show="formJobTask.jobTaskType === JOB_TASK_TYPES.SQL.value" fluid>
          <v-icon small>ic-info</v-icon>{{ hint }}
          <v-row>
            <v-col>
              <sql-editor class="editor mb-1" :class="{ 'sqlError' : queryCount === 0 || queryCount > 1 }" v-model="jobTaskCommand" />
              <div v-if="queryCount === 0" class="error--text errorMessage">{{ $t('notify.error.jobs.noQuery') }}</div>
              <div v-else-if="queryCount > 1" class="error--text errorMessage">{{ $t('notify.error.jobs.overQuery') }}</div>
              <div v-else class="errorMessage"></div>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-card-text>

    <v-dialog
      v-model="templateDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title>テンプレート一覧</v-card-title>
        <v-list class="item-inPageScroll_y" dense>
          <v-list-item
            v-for="connectSetting in connectSettings"
            :key="connectSetting.jobTaskConnectSettingName + connectSetting.jobTaskConnectSettingId"
            @click="selectConnectSetting(connectSetting)">
            <v-list-item-icon class="text_center">
              <v-icon v-if="connectSetting.jobTaskReference.etlType === INPUT_ETL_TYPES.S3.value" large color="warning">
                mdi-alpha-s-box
              </v-icon>
              <v-icon v-else-if="connectSetting.jobTaskReference.etlType === INPUT_ETL_TYPES.AURORA.value" large color="info">
                mdi-alpha-a-box
              </v-icon>
              <v-icon v-else-if="connectSetting.jobTaskReference.etlType === INPUT_ETL_TYPES.REDSHIFT.value" large color="error">
                mdi-alpha-r-box
              </v-icon>
              <v-icon v-else-if="connectSetting.jobTaskReference.etlType === INPUT_ETL_TYPES.GCS.value" large color="success">
                mdi-alpha-g-box
              </v-icon>
              <v-icon v-else-if="connectSetting.jobTaskReference.etlType === INPUT_ETL_TYPES.SFTP.value" large color="accent">
                mdi-alpha-f-box
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              {{ connectSetting.jobTaskConnectSettingName }}
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon small @click.stop="onClickDeleteConnectSettingBtn(connectSetting)">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      width="400"
    >
      <v-card>
        <v-card-title/>
        <v-card-text>
          {{ $t('notify.check.delete', [deleteDialogTitle]) }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="denial" @click="deleteDialog = false">
            {{ $t('btn.no') }}
          </v-btn>
          <v-btn color="primary" @click="deletConnectSetting">
            {{ $t('btn.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="saveConnectDialog"
      max-width="475"
    >
      <v-card>
        <v-card-title>登録するテンプレート名を入力してください</v-card-title>
        <v-card-actions>
          <v-text-field v-model="formConnectSetting.name" :label="'テンプレート名'" />
        </v-card-actions>
        <v-card-actions>
          <v-row :justify="'end'">
            <v-btn class="mr-3" @click="saveConnectDialog = false">
              {{ $t('btn.cancel') }}
            </v-btn>
            <v-btn class="mr-3" color="primary" :disabled="!formConnectSetting.name" @click="onClickSaveTaskButton">
              {{ $t('btn.save') }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import axios from '@/axios'
import { mapGetters } from 'vuex'
// components
import InputDb from '@/components/JobSetting/jobTasks/jobTaskForms/inputDb'
import InputS3 from '@/components/JobSetting/jobTasks/jobTaskForms/inputS3'
import InputSegment from '@/components/JobSetting/jobTasks/jobTaskForms/inputSegment'
import InputGcs from '@/components/JobSetting/jobTasks/jobTaskForms/inputGcs'
import InputSftp from '@/components/JobSetting/jobTasks/jobTaskForms/inputSftp'
import OutputDb from '@/components/JobSetting/jobTasks/jobTaskForms/outputDb'
import OutputS3 from '@/components/JobSetting/jobTasks/jobTaskForms/outputS3'
import OutputGcs from '@/components/JobSetting/jobTasks/jobTaskForms/outputGcs'
import sqlEditor from '@/components/common/sqlEditor'
// enum
import INPUT_ETL_TYPES from '@/enum/jobTask/INPUT_ETL_TYPES'
import OUTPUT_ETL_TYPES from '@/enum/jobTask/OUTPUT_ETL_TYPES'
import JSON_INPUT_ITEM_NAMES from '@/enum/jobTask/JSON_INPUT_ITEM_NAMES'
import JSON_OUTPUT_ITEM_NAMES from '@/enum/jobTask/JSON_OUTPUT_ITEM_NAMES'
import JOB_TASK_IO_CATEGORY from '@/enum/jobTask/JOB_TASK_IO_CATEGORY'
import JOB_TASK_TYPES from '@/enum/jobTask/JOB_TASK_TYPES'
// util
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'

const TOOLTIP_WIDTH = 400

export default {
  name: 'TaskForm',
  components: {
    'input-db': InputDb,
    'input-s3': InputS3,
    'input-segment': InputSegment,
    'input-gcs': InputGcs,
    'input-sftp': InputSftp,
    'output-db': OutputDb,
    'output-s3': OutputS3,
    'output-gcs': OutputGcs,
    sqlEditor,
  },
  mixins: [
    INPUT_ETL_TYPES,
    OUTPUT_ETL_TYPES,
    JSON_INPUT_ITEM_NAMES,
    JSON_OUTPUT_ITEM_NAMES,
    JOB_TASK_IO_CATEGORY,
    JOB_TASK_TYPES,
    enumUtil,
    notifyUtil,
  ],
  props: {
    formJobTask: {
      type: Object,
    },
  },
  data () {
    return {
      connectSettings: [],
      selectedCategory: null,
      formConnectSetting: {
        name: null,
        ioCategory: null,
        ioType: null,
        jobTaskReference: null,
      },
      saveConnectDialog: false,
      hint: this.$t('form.hint.sqlEditor'),
      tooltipWidth: TOOLTIP_WIDTH,
      templateDialog: false,
      deleteDialog: false,
      deleteEntity: null,
      deleteDialogTitle: null,
      queryCount: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['canGetThis']),
    jobTaskName: {
      get () {
        return this.formJobTask.jobTaskName
      },
      set (newVal) {
        this.$emit('changeJobTaskName', newVal)
      },
    },
    jobTaskType: {
      get () {
        return this.formJobTask.jobTaskType
      },
      set (newVal) {
        this.$emit('changeJobTaskType', newVal)
      },
    },
    inputEtlType: {
      get () {
        return this.formJobTask.jobTaskReference ? this.formJobTask.jobTaskReference.input.etlType : this.INPUT_ETL_TYPES.S3.value
      },
      set (newVal) {
        this.$emit('changeInputEtlType', newVal)
      },
    },
    selectableTaskList () {
      const array = this.enumUtil.convertForSelectList(this.INPUT_ETL_TYPES)
      array.forEach(item => {
        if (item.value === this.INPUT_ETL_TYPES.SEGMENT.value) {
          item.disabled = !this.canGetThis('Segments')
        }
      })
      return array
    },
    inputValue: {
      get () {
        return this.formJobTask.jobTaskReference?.input
      },
      set (newVal) {
        this.$emit('changeInputValue', newVal)
      },
    },
    outputEtlType: {
      get () {
        return this.formJobTask.jobTaskReference ? this.formJobTask.jobTaskReference.output.etlType : this.OUTPUT_ETL_TYPES.S3.value
      },
      set (newVal) {
        this.$emit('changeOutputEtlType', newVal)
      },
    },
    outputValue: {
      get () {
        return this.formJobTask.jobTaskReference?.output
      },
      set (newVal) {
        this.$emit('changeOutputValue', newVal)
      },
    },
    jobTaskCommand: {
      get () {
        return this.formJobTask.jobTaskCommand
      },
      set (newVal) {
        this.$emit('changeJobTaskCommand', newVal)
      },
    },
    hasError: {
      get () {
        return this.formJobTask.hasError
      },
      set (newVal) {
        this.$emit('changeHasError', newVal)
      },
    },
  },
  watch: {
    'formJobTask.hasError' () {
      this.checkValidate()
    },
    'formJobTask.jobTaskReference.input.etlType' () {
      // インプット種別切替時にバリデーションエラーをリセット
      if (!this.formJobTask.hasError) {
        if (this.inputEtlType === this.INPUT_ETL_TYPES.S3.value && this.$refs.inputS3) this.$refs.inputS3.$validator.reset()

        if (this.inputEtlType === this.INPUT_ETL_TYPES.AURORA.value || this.inputEtlType === this.INPUT_ETL_TYPES.REDSHIFT.value) this.$refs.inputDb.$validator.reset()

        if (this.inputEtlType === this.INPUT_ETL_TYPES.GCS.value && this.$refs.inputGcs) {
          this.$refs.inputGcs.$validator.reset()
          this.$refs.inputGcs.setIsUploaded()
        }

        if (this.inputEtlType === this.INPUT_ETL_TYPES.SFTP.value && this.$refs.inputSftp) this.$refs.inputSftp.$validator.reset()
      }
    },
    'formJobTask.jobTaskReference.output.etlType' () {
      // アウトプット種別切替時にバリデーションエラーをリセット
      if (!this.formJobTask.hasError) {
        if (this.outputEtlType === this.OUTPUT_ETL_TYPES.S3.value && this.$refs.outputS3) this.$refs.outputS3.$validator.reset()

        if (this.outputEtlType === this.OUTPUT_ETL_TYPES.AURORA.value ||
            this.outputEtlType === this.OUTPUT_ETL_TYPES.REDSHIFT.value) this.$refs.outputDb.$validator.reset()

        if (this.outputEtlType === this.OUTPUT_ETL_TYPES.GCS.value && this.$refs.outputGcs) {
          this.$refs.outputGcs.$validator.reset()
          this.$refs.outputGcs.setIsUploaded()
        }
      }
    },
  },
  methods: {
    /**
     * タスクの入力値チェック呼び出し
     */
    checkValidate () {
      if (!this.formJobTask.hasError) return
      // setTimeout()で非同期処理を直列化し、データがフォームにバインドされるのを待つ
      setTimeout(this.validateJobTaskForm, 0)
      this.hasError = false
    },
    /**
     * タスクのバリデーションステータスの初期化
     */
    resetValidateJobTaskForm () {
      this.$validator.reset()
      if (this.$refs.inputS3) this.$refs.inputS3.$validator.reset()
      if (this.$refs.inputDb) this.$refs.inputDb.$validator.reset()
      if (this.$refs.inputGcs) this.$refs.inputGcs.$validator.reset()
      if (this.$refs.inputSftp) this.$refs.inputSftp.$validator.reset()
      if (this.$refs.outputS3) this.$refs.outputS3.$validator.reset()
      if (this.$refs.outputDb) this.$refs.outputDb.$validator.reset()
      if (this.$refs.outputGcs) this.$refs.outputGcs.$validator.reset()
    },
    /**
     * タスクのフォーム入力値チェック
     */
    validateJobTaskForm () {
      this.$validator.validateAll()

      switch (this.formJobTask.jobTaskType) {
        case this.JOB_TASK_TYPES.IO.value:
          this.validateJobTaskIOForm()
          break
        case this.JOB_TASK_TYPES.SQL.value:
          this.validateJobTaskSQLForm()
          break
      }
    },
    validateJobTaskSQLForm () {
      this.queryCount = null

      if (!this.formJobTask.jobTaskCommand || !this.formJobTask.jobTaskCommand.trim()) {
        this.queryCount = 0
        return
      }

      const queryRegex = /[;]$/
      // セミコロンの数でクエリをカウント
      this.queryCount = this.formJobTask.jobTaskCommand.match(queryRegex) ? this.formJobTask.jobTaskCommand.match(queryRegex).length : 0
      if (!queryRegex.test(this.formJobTask.jobTaskCommand.trim())) {
        this.queryCount = 0
      }
    },
    /**
     * IOタスクのフォーム入力値チェック呼び出し
     */
    validateJobTaskIOForm () {
      if (!this.formJobTask.jobTaskReference) return

      if (this.formJobTask.jobTaskReference.input) {
        this.validateInputForm()
      }
      if (this.formJobTask.jobTaskReference.output) {
        this.validateOutputForm()
      }
    },
    async validateInputForm () {
      switch (this.formJobTask.jobTaskReference.input.etlType) {
        case this.INPUT_ETL_TYPES.S3.value:
          if (this.$refs.inputS3) {
            return this.$refs.inputS3.$validator.validateAll()
          }
          break
        case this.INPUT_ETL_TYPES.AURORA.value:
        case this.INPUT_ETL_TYPES.REDSHIFT.value:
          if (this.$refs.inputDb) {
            return this.$refs.inputDb.$validator.validateAll()
          }
          break
        case this.INPUT_ETL_TYPES.SEGMENT.value:
          if (this.$refs.inputSegment) {
            return this.$refs.inputSegment.$validator.validateAll()
          }
          break
        case this.INPUT_ETL_TYPES.GCS.value:
          if (this.$refs.inputGcs) {
            // 認証キーJSONがVeeValidateでチェックできないため別で用意
            return await this.$refs.inputGcs.validateInputGcs()
          }
          break
        case this.INPUT_ETL_TYPES.SFTP.value:
          if (this.$refs.inputSftp) {
            return this.$refs.inputSftp.$validator.validateAll()
          }
          break
      }
      return false
    },
    async validateOutputForm () {
      switch (this.formJobTask.jobTaskReference.output.etlType) {
        case this.OUTPUT_ETL_TYPES.S3.value:
          if (this.$refs.outputS3) {
            return this.$refs.outputS3.$validator.validateAll()
          }
          break
        case this.OUTPUT_ETL_TYPES.AURORA.value:
        case this.OUTPUT_ETL_TYPES.REDSHIFT.value:
          if (this.$refs.outputDb) {
            return this.$refs.outputDb.$validator.validateAll()
          }
          break
        case this.OUTPUT_ETL_TYPES.GCS.value:
          if (this.$refs.outputGcs) {
            // 認証キーJSONがVeeValidateでチェックできないため別で用意
            return await this.$refs.outputGcs.validateOutputGcs()
          }
          break
      }
      return false
    },
    selectConnectSetting (connectSetting) {
      if (connectSetting.ioCategory === this.JOB_TASK_IO_CATEGORY.INPUT.value) {
        this.inputValue = connectSetting.jobTaskReference
      } else if (connectSetting.ioCategory === this.JOB_TASK_IO_CATEGORY.OUTPUT.value) {
        this.outputValue = connectSetting.jobTaskReference
      }
      this.templateDialog = false
    },
    saveInputTaskSetting () {
      const tmpInputTask = JSON.parse(JSON.stringify(this.formJobTask.jobTaskReference.input))
      switch (this.formJobTask.jobTaskReference.input.etlType) {
        // 不要なkey-valueを削除
        case this.INPUT_ETL_TYPES.S3.value:
          if (!Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputS3')) {
            this.notifyErrorMessage('接続情報が入力されていません')
            return
          }
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputAurora')) delete tmpInputTask.inputAurora
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputRedshift')) delete tmpInputTask.inputRedshift
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputSegment')) delete tmpInputTask.inputSegment
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputGcs')) delete tmpInputTask.inputGcs
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputSFTP')) delete tmpInputTask.inputSFTP
          this.formConnectSetting.ioType = this.formJobTask.jobTaskReference.input.etlType
          this.formConnectSetting.jobTaskReference = JSON.stringify(tmpInputTask)
          break
        case this.INPUT_ETL_TYPES.AURORA.value:
          if (!Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputAurora')) {
            this.notifyErrorMessage('接続情報が入力されていません')
            return
          }
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputS3')) delete tmpInputTask.inputS3
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputRedshift')) delete tmpInputTask.inputRedshift
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputSegment')) delete tmpInputTask.inputSegment
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputGcs')) delete tmpInputTask.inputGcs
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputSFTP')) delete tmpInputTask.inputSFTP
          this.formConnectSetting.ioType = this.formJobTask.jobTaskReference.input.etlType
          this.formConnectSetting.jobTaskReference = JSON.stringify(tmpInputTask)
          break
        case this.INPUT_ETL_TYPES.REDSHIFT.value:
          if (!Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputRedshift')) {
            this.notifyErrorMessage('接続情報が入力されていません')
            return
          }
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputS3')) delete tmpInputTask.inputS3
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputAurora')) delete tmpInputTask.inputAurora
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputSegment')) delete tmpInputTask.inputSegment
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputGcs')) delete tmpInputTask.inputGcs
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputSFTP')) delete tmpInputTask.inputSFTP
          this.formConnectSetting.ioType = this.formJobTask.jobTaskReference.input.etlType
          this.formConnectSetting.jobTaskReference = JSON.stringify(tmpInputTask)
          break
        case this.INPUT_ETL_TYPES.GCS.value:
          if (!Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputGcs')) {
            this.notifyErrorMessage('接続情報が入力されていません')
            return
          }
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputS3')) delete tmpInputTask.inputS3
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputAurora')) delete tmpInputTask.inputAurora
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputRedshift')) delete tmpInputTask.inputRedshift
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputSegment')) delete tmpInputTask.inputSegment
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputSFTP')) delete tmpInputTask.inputSFTP
          this.formConnectSetting.ioType = this.formJobTask.jobTaskReference.input.etlType
          this.formConnectSetting.jobTaskReference = JSON.stringify(tmpInputTask)
          break
        case this.INPUT_ETL_TYPES.SFTP.value:
          if (!Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputSFTP')) {
            this.notifyErrorMessage('接続情報が入力されていません')
            return
          }
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputS3')) delete tmpInputTask.inputS3
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputAurora')) delete tmpInputTask.inputAurora
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputRedshift')) delete tmpInputTask.inputRedshift
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputSegment')) delete tmpInputTask.inputSegment
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'inputGcs')) delete tmpInputTask.inputGcs
          this.formConnectSetting.ioType = this.formJobTask.jobTaskReference.input.etlType
          this.formConnectSetting.jobTaskReference = JSON.stringify(tmpInputTask)
          break
        case this.INPUT_ETL_TYPES.SEGMENT.value:
          this.notifyErrorMessage('セグメントはテンプレートとして保存できません')
          return
        default:
          this.notifyErrorMessage('不正なインプット種別です')
          return
      }
      this.formConnectSetting.ioCategory = this.JOB_TASK_IO_CATEGORY.INPUT.value
      this.formConnectSetting.ioType = this.formJobTask.jobTaskReference.input.etlType
      this.saveConnectDialog = true
    },
    saveOutputTaskSetting () {
      const tmpInputTask = JSON.parse(JSON.stringify(this.formJobTask.jobTaskReference.output))
      switch (this.formJobTask.jobTaskReference.output.etlType) {
        // 不要なkey-valueを削除
        case this.OUTPUT_ETL_TYPES.S3.value:
          if (!Object.prototype.hasOwnProperty.call(tmpInputTask, 'outputS3')) {
            this.notifyErrorMessage('接続情報が入力されていません')
            return
          }
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'outputAurora')) delete tmpInputTask.outputAurora
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'outputRedshift')) delete tmpInputTask.outputRedshift
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'outputGcs')) delete tmpInputTask.outputGcs
          this.formConnectSetting.ioType = this.formJobTask.jobTaskReference.output.etlType
          this.formConnectSetting.jobTaskReference = JSON.stringify(tmpInputTask)
          break
        case this.OUTPUT_ETL_TYPES.AURORA.value:
          if (!Object.prototype.hasOwnProperty.call(tmpInputTask, 'outputAurora')) {
            this.notifyErrorMessage('接続情報が入力されていません')
            return
          }
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'outputS3')) delete tmpInputTask.outputS3
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'outputRedshift')) delete tmpInputTask.outputRedshift
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'outputGcs')) delete tmpInputTask.outputGcs
          this.formConnectSetting.ioType = this.formJobTask.jobTaskReference.output.etlType
          this.formConnectSetting.jobTaskReference = JSON.stringify(tmpInputTask)
          break
        case this.OUTPUT_ETL_TYPES.REDSHIFT.value:
          if (!Object.prototype.hasOwnProperty.call(tmpInputTask, 'outputRedshift')) {
            this.notifyErrorMessage('接続情報が入力されていません')
            return
          }
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'outputS3')) delete tmpInputTask.outputS3
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'outputAurora')) delete tmpInputTask.outputAurora
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'outputGcs')) delete tmpInputTask.outputGcs
          this.formConnectSetting.ioType = this.formJobTask.jobTaskReference.output.etlType
          this.formConnectSetting.jobTaskReference = JSON.stringify(tmpInputTask)
          break
        case this.OUTPUT_ETL_TYPES.GCS.value:
          if (!Object.prototype.hasOwnProperty.call(tmpInputTask, 'outputGcs')) {
            this.notifyErrorMessage('接続情報が入力されていません')
            return
          }
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'outputS3')) delete tmpInputTask.outputS3
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'outputAurora')) delete tmpInputTask.outputAurora
          if (Object.prototype.hasOwnProperty.call(tmpInputTask, 'outputRedshift')) delete tmpInputTask.outputRedshift
          this.formConnectSetting.ioType = this.formJobTask.jobTaskReference.output.etlType
          this.formConnectSetting.jobTaskReference = JSON.stringify(tmpInputTask)
          break
        default:
          this.notifyErrorMessage('不正なアウトプット種別です')
          return
      }
      this.formConnectSetting.ioCategory = this.JOB_TASK_IO_CATEGORY.OUTPUT.value
      this.formConnectSetting.ioType = this.formJobTask.jobTaskReference.output.etlType
      this.saveConnectDialog = true
    },
    onClickSaveTaskButton () {
      axios.post('/jobs/connectSettings/', {
        jobTaskConnectSettingName: this.formConnectSetting.name,
        ioCategory: this.formConnectSetting.ioCategory,
        ioType: this.formConnectSetting.ioType,
        jobTaskReference: this.formConnectSetting.jobTaskReference,
      }).then(res => {
        this.notifySuccessMessage(this.formConnectSetting.name + 'の登録が完了しました')
        this.formConnectSetting = {
          name: null,
          ioCategory: null,
          ioType: null,
          jobTaskReference: null,
        }
        this.saveConnectDialog = false
      }).catch(err => {
        this.handleErrorResponse(err)
      })
    },
    onClickGetConnectionSettingsButton (ioCategory) {
      this.selectedCategory = ioCategory
      axios.get('/jobs/connectSettings/' + ioCategory + '/')
        .then(res => {
          this.connectSettings = res.data.data.jobTaskConnectSettingDtoList
          this.connectSettings.forEach(jobTaskConnectSetting => {
            jobTaskConnectSetting.jobTaskReference = JSON.parse(jobTaskConnectSetting.jobTaskReference)
          })
          this.templateDialog = true
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    },
    async onClickCheckConnection (ioCategory) {
      let jobTaskReferenceStr
      let jobTaskType
      switch (ioCategory) {
        case this.JOB_TASK_IO_CATEGORY.INPUT.value: {
          // 必須項目に記入漏れがあれば中断
          if (!(await this.validateInputForm())) {
            return
          }
          // 入力内容をJSON化
          const tmpJobTaskReferenceInput = { etlType: JSON.parse(JSON.stringify(this.formJobTask.jobTaskReference.input.etlType)) }
          for (const key in this.INPUT_ETL_TYPES) {
            if (this.formJobTask.jobTaskReference.input.etlType === this.INPUT_ETL_TYPES[key].value) {
              tmpJobTaskReferenceInput[this.JSON_INPUT_ITEM_NAMES[key].value] =
                JSON.parse(JSON.stringify(this.formJobTask.jobTaskReference.input[this.JSON_INPUT_ITEM_NAMES[key].value]))
            }
          }
          jobTaskReferenceStr = JSON.stringify(tmpJobTaskReferenceInput)
          // IO種別の判定
          jobTaskType = this.formJobTask.jobTaskReference.input.etlType
          break
        }
        case this.JOB_TASK_IO_CATEGORY.OUTPUT.value: {
          // 必須項目に記入漏れがあれば中断
          if (!(await this.validateOutputForm())) {
            return
          }
          // 入力内容をJSON化
          const tmpJobTaskReferenceOutput = { etlType: JSON.parse(JSON.stringify(this.formJobTask.jobTaskReference.output.etlType)) }
          for (const key in this.OUTPUT_ETL_TYPES) {
            if (this.formJobTask.jobTaskReference.output.etlType === this.OUTPUT_ETL_TYPES[key].value) {
              tmpJobTaskReferenceOutput[this.JSON_OUTPUT_ITEM_NAMES[key].value] =
                JSON.parse(JSON.stringify(this.formJobTask.jobTaskReference.output[this.JSON_OUTPUT_ITEM_NAMES[key].value]))
            }
          }
          jobTaskReferenceStr = JSON.stringify(tmpJobTaskReferenceOutput)
          // IO種別の判定
          jobTaskType = this.formJobTask.jobTaskReference.output.etlType
          break
        }
        default:
          throw new Error('unknown argument')
      }
      axios.post('/jobs/connectSettings/check/', {
        ioCategory: ioCategory,
        jobTaskType: jobTaskType,
        jobTaskReference: jobTaskReferenceStr,
      }).then(res => {
        if (res.data.data.connect) {
          this.notifySuccessMessage('疎通確認が成功しました')
        } else {
          this.notifyErrorMessage('疎通確認が失敗しました')
        }
      }).catch(err => {
        this.handleErrorResponse(err)
      })
    },
    onClickDeleteConnectSettingBtn (connectSetting) {
      this.deleteEntity = connectSetting
      this.deleteDialogTitle = connectSetting.jobTaskConnectSettingName
      this.deleteDialog = true
    },
    deletConnectSetting () {
      axios.delete('/jobs/connectSettings/' + this.deleteEntity.jobTaskConnectSettingId + '/')
        .then(() => {
          this.deleteEntity = null
          this.deleteDialogTitle = null
          this.deleteDialog = false
          this.notifySuccessMessage('テンプレートを削除しました')
          this.onClickGetConnectionSettingsButton(this.selectedCategory)
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/theme.scss';

.item-inPageScroll_y {
  height: 35vh;
}
.editor {
  max-height: 50vh !important;
  height: 50vh !important;

  border-collapse: collapse;
  border-color: currentColor;
  border-style: solid;
  border-width: 1px;
}
.errorMessage {
  font-size: 12px;
  height: 12px;
}

.jobTaskType ::v-deep legend {
  margin-right: 34px;
}

#app {
  @include theme(v-card) using ($integral-core-theme) {
    ::v-deep .dropArea{
      color: map-deep-get($integral-core-theme, 'views', 'jobSetting', 'dropArea', 'text');
    }
    b {
      color: map-deep-get($integral-core-theme, 'views', 'jobSetting', 'header', 'bold');
    }
    &.editor.sqlError {
      border-color: map-deep-get($integral-core-theme, 'views', 'jobSetting', 'error', 'border');
    }
  }
}

</style>
