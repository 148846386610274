<template>
  <v-data-table
    class="item-inPageScroll_x"
    :headers="modTableHeaders"
    :items="scvs"
    :loading="loading"
    disable-filtering
    disable-pagination
    disable-sort
    hide-default-footer
    fixed-header
  >
    <template #body="{ items }">
      <tbody>
        <tr v-if="items.length === 0">
          <td class="text-center" :colspan="modTableHeaders.length">{{$t('text.scvs.noResult')}}</td>
        </tr>
        <!-- TODO 行クリックのリンク化 https://github.com/vuetifyjs/vuetify/issues/13054 -->
        <tr v-for="item in items" :key="item.scvId" @click="openDetail(item)">
          <td @click.stop>
            <v-checkbox
              v-model="computedUnionCandidates"
              :ripple="false"
              class="mt-0 pt-0"
              color="default"
              hide-details
              :value="item.scvId"
            />
          </td>
          <td v-for="(scvColumn) in scvColumns" :key="scvColumn.value">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <span v-on="on">
                  {{ item.scvColumnValueList | getColumn(scvColumn.value) | ellipsis(50,'...') }}
                </span>
              </template>
              <span class="break-all">
                {{ item.scvColumnValueList | getColumn(scvColumn.value) }}
              </span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
// util
import notifyUtil from '@/utils/notifyUtil'
import enumUtil from '@/utils/enumUtil'
import scheduleStrUtil from '@/utils/scheduleStrUtil'

export default {
  name: 'scvsTable',
  mixins: [
    notifyUtil,
    enumUtil,
    scheduleStrUtil,
  ],
  props: {
    scvs: {
      type: Array,
      default () {
        return []
      },
    },
    scvColumns: {
      type: Array,
      default () {
        return []
      },
    },
    unionCandidates: {
      type: Array,
      default () {
        return []
      },
    },
    loading: {
      type: Boolean,
      default () {
        return false
      },
    },
  },
  computed: {
    modTableHeaders () {
      const tmpTableHeaders = this.scvColumns
        .map((scvColumn) => {
          return { text: scvColumn.text, value: scvColumn.value }
        })
      tmpTableHeaders.forEach(tableHeader => {
        tableHeader.width = '200'
      })
      tmpTableHeaders.unshift({ text: this.$t('text.scvs.union.header'), value: 'union', width: '1%', sortable: false })
      return tmpTableHeaders
    },
    computedUnionCandidates: {
      get () {
        return this.unionCandidates
      },
      set (newVal) {
        this.$emit('update:unionCandidates', newVal)
      },
    },
  },
  methods: {
    openDetail (value) {
      this.$router.push({ name: 'UserScvDetail', params: { scvId: value.scvId } }, () => {})
    },
  },
  filters: {
    hideSeconds (str) {
      if (!str || str.length !== 8) return ''
      return str.slice(0, 5)
    },
    getColumn (scvColumnValueList, scvColumnId) {
      const column = scvColumnValueList.find(scvColumn => scvColumn.scvColumnId === Number(scvColumnId))
      if (!column) return null
      return column.scvColumnValue
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-data-table__wrapper {
  max-height: 500px;
  th.column {
    top: 48px !important;
  }
  tr {
    cursor: pointer;
  }
}
</style>
