<template>
  <v-container>
    <v-btn @click="dialog = true">Open</v-btn>

    <!-- overlay の色を CSS で指定できないので入力が必要 -->
    <v-dialog
      v-model="dialog"
      width="368"
      overlay-color="#07070820"
      overlay-opacity="1"
    >
      <v-card>
        <v-card-title>
          <!-- dialog 内の v-card には上部余白の為必要 -->
        </v-card-title>
        <v-card-text>選択した項目をまとめて削除してよろしいですか？</v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="denial">{{ $t('btn.no') }}</v-btn>
          <v-btn color="primary">{{ $t('btn.yes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'Dialogs',
  data () {
    return {
      dialog: false,
    }
  },
}
</script>
