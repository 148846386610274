export default {
  data () {
    return {
      DB_OUTPUT_MODE: {
        INSERT: { text: 'insert', value: 1 },
        TRUNCATE_INSERT: { text: 'truncate_insert', value: 2 },
        MERGE: { text: 'merge', value: 3 },
      },
    }
  },
}
