<template>
  <div>
    <!-- v7.5.0 -->
    <h4 id="v7.5.0">
      v7.5.0 (2020/03/27~)
    </h4>
    <h5>全般</h5>
    <ul>
      <li>
        各種レイアウトの調整を行いました
      </li>
    </ul>
    <h5>パスワード設定</h5>
    <ul>
      <li>
        入力されたパスワードに不備がある場合に、不足している項目がまとめて表示されるようになりました
      </li>
    </ul>
    <h5>顧客マスタ</h5>
    <ul>
      <li>
        一覧リストの各行をクリックすると、クリックした行の顧客マスタの設定ウインドウが表示されるようになりました
      </li>
      <li>
        一覧リストに作成者、更新者、最終アップロード日時を追加しました
      </li>
    </ul>
    <h5>ジョブ設定</h5>
    <ul>
      <li>
        S3タスクにおいて、エンドポイントの入力フォームを削除しました
      </li>
      <li>
        インプットS3タスクにおいて、ファイルにヘッダ行の有無を設定する項目を追加しました
      </li>
    </ul>

    <!-- v7.3.0 -->
    <h4 id="v7.3.0">
      v7.3.0 (2020/03/06~)
    </h4>
    <h5>ジョブ設定</h5>
    <ul>
      <li>
        タスク種別「Redshift」を選択時にスキーマ名を入力できるようになりました。
      </li>
    </ul>

    <!-- v7.1.0 -->
    <h4 id="v7.1.0">
      v7.1.0 (2020/02/18~)
    </h4>
    <h5>ログイン</h5>
    <ul>
      <li>
        ログイン後に計測対象一覧画面へ遷移するようになりました
      </li>
    </ul>
    <h5>ヘッダー</h5>
    <ul>
      <li>
        管理者権限以上のユーザーのユーザーメニューに「アカウント一覧」が追加されました、従来のアカウント管理機能と同等の機能となります
      </li>
    </ul>
    <h5>サイドバー</h5>
    <ul>
      <li>
        メニューの順番を変更しました
      </li>
      <li>
        「アカウント管理」を削除しました
      </li>
    </ul>
    <h5>計測対象一覧</h5>
    <ul>
      <li>
        「サイト管理」を「計測対象管理」に名称を変更しました
      </li>
      <li>
        一覧リストの各行をクリックすると、クリックした行の計測対象の設定ウインドウが表示されるようになりました
      </li>
      <li>
        一覧リストのヘッダーからソート設定を行えるよう変更しました
      </li>
      <li>
        フィルタリング設定のレイアウトを変更しました
      </li>
      <li>
        1ページあたりの表示項目数を25件、50件、100件に変更しました。初期設定では50件表示されます
      </li>
      <li>
        その他レイアウトを調整しました
      </li>
    </ul>
    <h5>コンバージョン設定</h5>
    <ul>
      <li>
        設定ウインドウのフォームを一部修正しました
      </li>
      <li>
        一覧リストの各行をクリックすると、クリックした行のコンバージョン設定の設定ウインドウが表示されるようになりました
      </li>
      <li>
        一覧リストのヘッダーからソート設定を行えるよう変更しました
      </li>
      <li>
        フィルタリング設定のレイアウトを変更しました
      </li>
      <li>
        1ページあたりの表示項目数を25件、50件、100件に変更しました。初期設定では50件表示されます
      </li>
      <li>
        その他レイアウトを調整しました
      </li>
    </ul>
    <h5>顧客マスタ一覧</h5>
    <ul>
      <li>
        一覧リストのヘッダーからソート設定を行えるよう変更しました
      </li>
      <li>
        フィルタリング設定のレイアウトを変更しました
      </li>
    </ul>
    <h5>顧客マスタアップロード履歴, 顧客マスタ連結履歴, ジョブ一覧</h5>
    <ul>
      <li>
        一覧リストのヘッダーからソート設定を行えるよう変更しました
      </li>
      <li>
        フィルタリング設定のレイアウトを変更しました
      </li>
      <li>
        1ページあたりの表示項目数を25件、50件、100件に変更しました。初期設定では50件表示されます
      </li>
    </ul>
    <h5>セグメント詳細, セグメント設定, ユーザー詳細, ジョブ設定</h5>
    <ul>
      <li>
        レイアウトを調整しました
      </li>
    </ul>

    <!-- v7.0.0 -->
    <h4 id="v7.0.0">
      v7.0.0 (2020/02/03~)
    </h4>
    <h5>サイドバー</h5>
    <ul>
      <li>
        「顧客情報設定」が「顧客マスタ管理」に統合されました。
      </li>
    </ul>
    <h5>ログイン, パスワード変更</h5>
    <ul>
      <li>
        パスワードの表示/非表示を切り替えられるようになりました。
      </li>
    </ul>
    <h5>セグメント設定</h5>
    <ul>
      <li>
        ページ別実績フィルタでページのURLを検索できるようになりました。
      </li>
    </ul>
    <h5>マスタ一覧</h5>
    <ul>
      <li>
        複数の顧客マスタを管理できるようになりました。
      </li>
      <li>
        マスタ情報の更新方法として、「差分更新」が選択可能になりました
      </li>
      <li>
        取り込んだ顧客マスタを一覧表示できるようになりました。
      </li>
    </ul>
    <h5>マスタ取込履歴</h5>
    <ul>
      <li>
        顧客マスタ取込の実行履歴を一覧表示できるようになりました。
      </li>
    </ul>
    <h5>マスタ連結設定</h5>
    <ul>
      <li>
        各顧客マスタを連結する機能が追加されました。
      </li>
      <li>
        各顧客マスタを連結する際の連結ルールを設定できるようになりました。
      </li>
    </ul>
    <h5>マスタ連結履歴</h5>
    <ul>
      <li>
        顧客マスタの連結実行履歴を一覧表示できるようになりました。
      </li>
    </ul>
    <h5>アカウント管理</h5>
    <ul>
      <li>
        アカウント作成後、パスワード変更後に表示されるアカウント情報をコピーできるように変更しました。
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'version7',
  minorVersions: [
    'v7.5.0',
    'v7.3.0',
    'v7.1.0',
    'v7.0.0',
  ],
}
</script>
