export default {
  tooltip: {
    setFilter: 'フィルタリング条件に応じて一覧リストのフィルタリングを行います',
    resetFilter: 'フィルタリング条件をリセットします',
    taskDrag: 'リスト上のタスクを上下にドラッグすることで実行順序を入れ替えることができます',
    autoCreateTable: '指定したテーブルが存在しない場合は自動作成されますが<br>意図しないテーブル定義になる可能性があります',
    hasJob: '紐付くジョブが存在するため削除できません',
    needDelete: 'セグメントを削除するには、このタスクを削除する必要があります',
    isMaxSizeMaster: '顧客マスタは最大5件まで作成できます',
    processingMaster: '顧客マスタ取り込み処理実行中または顧客マスタ連結実行中は操作できません<br>時間を置いてからリロードしてください',
    identifyMaster: '顧客識別用テーブルに設定されている顧客マスタです',
    dataSet: {
      measureLinkRuleLimitation: '連結対象には、文字列型のカラムのみ指定できます',
      transactionLinkRuleLimitation: '連結するカラムは識別子列のみです',
    },
    jobSetting: {
      jobTaskSchema: '明示的にスキーマを指定したい場合に入力してください',
      hasNotHeaderFile: 'ヘッダ行を持たないファイルの場合はチェックしてください<br>チェックしない場合は1行目の読み込みがスキップされます',
      hasGcsAuthFile: '登録されているGCS認証ファイル名です<br>GCS認証ファイルを上書きする場合は下部の「認証キーJSON」よりGCS認証ファイルをアップロードしてください',
    },
    userDetail: {
      edit: '編集します',
      save: '保存します',
      restore: '編集を破棄します',
    },
    scvSetting: {
      selectableMaster: 'SCV設定中にデータソース取込処理が実行されると、<br>SCV設定を正常に登録できない場合がありますのでご注意ください',
      candidate: '同一データセットから複数の候補を設定できます。<br>SCV項目に採用されるデータは1番の設定から順に優先されます。',
      dataTypeMismatch: 'データタイプ型が異なります',
      disabledDataSetColumn: 'データセットの対象カラムが削除済みです',
    },
  },
}
