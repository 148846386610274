<template>
  <div>
    <v-card flat tile class="segmentGroup mb-4">
      <v-card-title v-if="group.depth !== 1" class="py-3 pl-2 pr-3">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn v-on="on" fab depressed x-small color="denial" @click="onClickRemoveRuleGroupButton(groupIndex)">
              <v-icon>ic-close-S</v-icon>
            </v-btn>
          </template>
          <span>グループを削除</span>
        </v-tooltip>
        <v-chip v-if="group.filterRuleType === FILTER_RULE_TYPE.ATTRIBUTE.value" color="attribute" class="ml-2">{{ FILTER_RULE_TYPE.ATTRIBUTE.label }}</v-chip>
        <v-chip v-else-if="group.filterRuleType === FILTER_RULE_TYPE.WEB_TRANSACTION.value" color="webTransaction" class="ml-2">{{ FILTER_RULE_TYPE.WEB_TRANSACTION.label }}</v-chip>
        <v-chip v-else-if="group.filterRuleType === FILTER_RULE_TYPE.TRANSACTION_SUMMARY.value" color="transactionSummary" class="ml-2">{{ FILTER_RULE_TYPE.TRANSACTION_SUMMARY.label }}</v-chip>
        <v-spacer />
        <v-btn small @click="editing = true">{{ $t('btn.change') }}</v-btn>
      </v-card-title>
      <v-divider v-if="group.depth !== 1" class="ml-9"></v-divider>
      <v-card-text>
        <v-simple-table v-if="group.depth !== 1 && group.filterRuleType === FILTER_RULE_TYPE.WEB_TRANSACTION.value" class="mb-3">
          <!-- 期間指定 -->
          <tr>
            <td>期間指定</td>
            <td>
              {{ getPeriodSentence(group.periodType, group.startDate, group.endDate, group.relativeDayFrom, group.relativeDayTo, group.dayOfMonth, group.dayOfWeek) }}
            </td>
          </tr>
          <tr v-if="group.internalFilterColumn !== undefined && group.internalFilterColumn !== null && group.internalFilterColumn.internalFilterType !== null">
            <td>{{enumUtil.getEnumFromSomeProperty(INTERNAL_FILTER_TYPES, 'internalFilterType', group.internalFilterColumn.internalFilterType).filterLogicalName}}</td>
            <td>
              {{ createInternalFilterRuleSentence(group) }}
            </td>
          </tr>
        </v-simple-table>

        <template v-for="(child, index) in group.children">
          <div
            v-if="index !== 0"
            :key="index + '_chip'"
            class="my-2 d-flex justify-center align-content-center"
          >
            <v-chip v-if="group.logicalOperatorType === LOGICAL_OPERATOR_TYPE.AND.value" color="and">AND</v-chip>
            <v-chip v-else-if="group.logicalOperatorType === LOGICAL_OPERATOR_TYPE.OR.value" color="or">OR</v-chip>
            <v-btn x-small icon v-if="group.depth === 1" @click="$emit('switchLogicalOperatorType')"><v-icon>ic-arrow-updown</v-icon></v-btn>
          </div>
          <rule-group
            v-if="child.isGroup"
            v-bind="$attrs"
            v-on="$listeners"
            ref="filterRuleGroup"
            :key="index + '_group'"
            :user-type="userType"
            :group="child"
            :groupIndex="index"
            :scvColumns="scvColumns"
            :loadingScvColumn="loadingScvColumn"
            :initialPages="initialPages"
            :initialPagesPaging="initialPagesPaging"
            :loadingPages="loadingPages"
            :transactionSummaryList="transactionSummaryList"
            :loadingTransactionSummaryList="loadingTransactionSummaryList"
          />
          <v-sheet
            v-if="!child.isGroup"
            :key="index + '_rule'"
            color="segmentRule"
            class="mb-1 px-3 py-2"
          >
            {{ getFilterRuleSentence(child) }}
          </v-sheet>
        </template>
        <!-- 条件追加ボタン -->
        <v-row dense class="mb-0" v-if="group.depth < 3 && group.filterRuleType === FILTER_RULE_TYPE.GROUP.value">
          <v-col class="pb-0 firstColumn" cols="2">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn v-on="on" fab depressed @click="$emit('onClickAddRuleGroupButton')" :disabled="filterCount >= 10">
                  <v-icon>ic-add</v-icon>
                </v-btn>
              </template>
              <span>グループを追加</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- フィルタ条件入力フォーム -->
    <v-dialog v-model="filterFormDialog" width="770" overlay-color="#07070820" overlay-opacity="1" persistent>
      <rule-group-form
        :userType="userType"
        :group="group"
        :filterCount="filterCount"
        :scvColumns="scvColumns"
        :loadingScvColumn="loadingScvColumn"
        :initialPages="initialPages"
        :initialPagesPaging="initialPagesPaging"
        :loadingPages="loadingPages"
        :transactionSummaryList="transactionSummaryList"
        :loadingTransactionSummaryList="loadingTransactionSummaryList"
        @cancel="cancelFilterFormDialog"
        @applyRuleGroup="applyRuleGroup"
      />
    </v-dialog>
  </div>
</template>

<script>
// components
import ruleGroupForm from './ruleGroupForm.vue'
// enum
import FILTER_RULE_TYPE from '@/enum/FILTER_RULE_TYPE'
import INTERNAL_FILTER_TYPES from '@/enum/INTERNAL_FILTER_TYPES'
import LOGICAL_OPERATOR_TYPE from '@/enum/LOGICAL_OPERATOR_TYPE'
import OPERATOR_TYPE from '@/enum/OPERATOR_TYPE'
import PERIOD_TYPES from '@/enum/PERIOD_TYPES'
// mixins
import segmentFilterRule from '@/mixins/segmentFilterRule'
// util
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'
import segmentFilterUtil from '@/utils/segmentFilterUtil'

export default {
  name: 'ruleGroup',
  mixins: [
    FILTER_RULE_TYPE,
    INTERNAL_FILTER_TYPES,
    LOGICAL_OPERATOR_TYPE,
    OPERATOR_TYPE,
    PERIOD_TYPES,
    segmentFilterRule,
    segmentFilterUtil,
    enumUtil,
    notifyUtil,
  ],
  components: {
    ruleGroupForm,
  },
  props: {
    userType: {
      type: Number,
    },
    depth: {
      type: Number,
    },
    group: {
      type: Object,
    },
    groupIndex: {
      type: Number,
      default () {
        return 0
      },
    },
    filterCount: {
      type: Number,
    },
    scvColumns: {
      type: Array,
    },
    loadingScvColumn: {
      type: Boolean,
    },
    transactionSummaryList: {
      type: Array,
    },
    loadingTransactionSummaryList: {
      type: Boolean,
    },
    initialPages: {
      type: Array,
    },
    initialPagesPaging: {
      type: Object,
    },
    loadingPages: {
      type: Boolean,
    },
  },
  data () {
    return {
      startDatePicker: false,
      endDatePicker: false,
      weekList: [
        { text: '日', value: 0 },
        { text: '月', value: 1 },
        { text: '火', value: 2 },
        { text: '水', value: 3 },
        { text: '木', value: 4 },
        { text: '金', value: 5 },
        { text: '土', value: 6 },
      ],
      dayList: [
        { text: '1日', value: 1 },
        { text: '2日', value: 2 },
        { text: '3日', value: 3 },
        { text: '4日', value: 4 },
        { text: '5日', value: 5 },
        { text: '6日', value: 6 },
        { text: '7日', value: 7 },
        { text: '8日', value: 8 },
        { text: '9日', value: 9 },
        { text: '10日', value: 10 },
        { text: '11日', value: 11 },
        { text: '12日', value: 12 },
        { text: '13日', value: 13 },
        { text: '14日', value: 14 },
        { text: '15日', value: 15 },
        { text: '16日', value: 16 },
        { text: '17日', value: 17 },
        { text: '18日', value: 18 },
        { text: '19日', value: 19 },
        { text: '20日', value: 20 },
        { text: '21日', value: 21 },
        { text: '22日', value: 22 },
        { text: '23日', value: 23 },
        { text: '24日', value: 24 },
        { text: '25日', value: 25 },
        { text: '26日', value: 26 },
        { text: '27日', value: 27 },
        { text: '28日', value: 28 },
        { text: '29日', value: 29 },
        { text: '30日', value: 30 },
        { text: '31日', value: 31 },
      ],
      editing: false,
    }
  },
  computed: {
    // 曜日全選択チェックフラグ
    checkedAllDayOfWeek () {
      return this.group.dayOfWeek.length === Object.keys(this.weekList).length
    },
    // 曜日一つ以上選択チェックフラグ
    checkedSomeDayOfWeek () {
      return this.group.dayOfWeek.length > 0 && !this.checkedAllDayOfWeek
    },
    // 曜日選択での全選択項目のアイコン
    dayOfWeekIcon () {
      // 全選択、一つ以上選択、未選択でアイコンを切り替え
      if (this.checkedAllDayOfWeek) return 'mdi-checkbox-marked-outline'
      if (this.checkedSomeDayOfWeek) return 'mdi-checkbox-intermediate'
      return 'mdi-checkbox-blank-outline'
    },
    // 日にち全選択チェックフラグ
    checkedAllDayOfMonth () {
      return this.group.dayOfMonth.length === Object.keys(this.dayList).length
    },
    // 日にち一つ以上選択チェックフラグ
    checkedSomeDayOfMonth () {
      return this.group.dayOfMonth.length > 0 && !this.checkedAllDayOfMonth
    },
    // 日にち選択での全選択項目のアイコン
    dayOfMonthIcon () {
      // 全選択、一つ以上選択、未選択でアイコンを切り替え
      if (this.checkedAllDayOfMonth) return 'mdi-checkbox-marked-outline'
      if (this.checkedSomeDayOfMonth) return 'mdi-checkbox-intermediate'
      return 'mdi-checkbox-blank-outline'
    },
    filterFormDialog () {
      return this.editing || this.group.filterRuleType === null
    },
  },
  methods: {
    onClickRemoveRuleGroupButton (index) {
      this.$emit('removeGroup', index)
    },
    getPeriodSentence (periodType, startDate, endDate, relativeDayFrom, relativeDayTo, dayOfMonth, dayOfWeek) {
      return this.createPeriodSentence(periodType, startDate, endDate, relativeDayFrom, relativeDayTo, dayOfMonth, dayOfWeek)
    },
    getFilterRuleSentence (rule) {
      if (rule.filterColumn.filterType === null) { return }
      // 一致条件が NULL, NOT_NULL の場合は条件値が NULL になり、それ以外は required のため何らかの値が入る
      if (rule.filterColumn.operatorType !== this.OPERATOR_TYPE.IS_NULL.value &&
          rule.filterColumn.operatorType !== this.OPERATOR_TYPE.IS_NOT_NULL.value &&
          rule.filterColumn.filterValue === null) {
        return
      }
      return this.createFilterRuleSentence(rule)
    },
    cancelFilterFormDialog () {
      if (this.editing) {
        this.editing = false
      } else {
        this.$emit('removeGroup', this.groupIndex)
      }
    },
    applyRuleGroup (group) {
      // TODO: エラーは出ないが親からもらった変数を子コンポーネントで変更してしまっているので回避したい
      Object.assign(this.group, JSON.parse(JSON.stringify(group)))
      this.editing = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

// v-sheet
@include theme(v-card) using ($integral-core-theme) {
  &.segmentGroup {
    background-color: map-deep-get($integral-core-theme, 'views', 'segmentSetting', 'segmentGroup', 'outerMost', 'background');
    border: 1px solid map-deep-get($integral-core-theme, 'views', 'segmentSetting', 'segmentGroup', 'outerMost', 'border');
    .v-data-table {
      color: map-deep-get($integral-core-theme, 'views', 'segmentSetting', 'segmentGroup', 'table', 'text');
    }
    .v-sheet {
      background-color: map-deep-get($integral-core-theme, 'views', 'segmentSetting', 'sheets', 'base', 'background');;
      border: 1px solid map-deep-get($integral-core-theme, 'views', 'segmentSetting', 'sheets', 'base', 'border');
      .segmentRule {
        background-color: map-deep-get($integral-core-theme, 'views', 'segmentSetting', 'sheets', 'segment-rule', 'background');
        color: map-deep-get($integral-core-theme, 'views', 'segmentSetting', 'sheets', 'segment-rule', 'text');
      }
    }
  }
}
.segmentGroup {
  .v-card__title {
    .v-chip:hover::before {
      opacity: 0;
    }
  }
  .v-card__text {
    padding: 12px 36px;
    .segmentGroup {
      border-radius: 4px !important;
      .segmentRule {
        font-weight: bold;
        line-height: 16px;
        border: none;
      }
    }
  }
}
</style>
