<template>
  <div>
    <v-icon v-if="isScv" size="52px">ic-customer-LLL</v-icon>
    <v-icon v-else size="52px">ic-anonymous-LLL</v-icon>
    <div class="mt-4 mb-5">
      <v-row dense v-if="isScv">
        <v-col cols="3">SCV ID</v-col>
        <v-col cols="9" class="text-right" v-if="userDetail && userDetail.scvId">{{ userDetail.scvId }}</v-col>
        <v-col cols="9" class="text-right" v-else>
          <v-skeleton-loader ref="skeleton" type="text" boilerplate width="150" class="mt-1" max-height="16"/>
        </v-col>
      </v-row>
      <v-row dense v-else>
        <v-col cols="3">ID</v-col>
        <v-col cols="9" class="text-right" v-if="userDetail && userDetail.iuid">{{ userDetail.iuid }}</v-col>
        <v-col cols="9" class="text-right" v-else>
          <v-skeleton-loader ref="skeleton" type="text" boilerplate width="150" class="mt-1" max-height="16"/>
        </v-col>
      </v-row>
      <v-row dense>
        <!-- スコア -->
        <v-col cols="3">スコア</v-col>
        <v-col cols="9" class="text-left">
          <scv-column
            v-if="isScv"
            :loading="loading"
            :scvColumn="scvScore"
            :scvSources="scvScoreColumnSources"
            :putsValueRight="true"
            @overwrite="overwrite"/>
          <v-skeleton-loader v-else ref="skeleton" type="text" boilerplate width="100"/>
        </v-col>
      </v-row>
      <v-row dense>
        <!-- ランク -->
        <v-col cols="3">ランク</v-col>
        <v-col cols="9" class="text-left">
          <scv-column
            v-if="isScv"
            :loading="loading"
            :scvColumn="scvRank"
            :scvSources="scvRankColumnSources"
            :putsValueRight="true"
            @overwrite="overwrite"/>
          <v-skeleton-loader v-else ref="skeleton" type="text" boilerplate width="100"/>
        </v-col>
      </v-row>
      <v-row dense class="ltv">
        <!-- LTV -->
        <v-col cols="3">LTV</v-col>
        <v-col cols="9" class="text-left">
          <scv-column
            v-if="isScv"
            :loading="loading"
            :scvColumn="scvLTV"
            :scvSources="scvLTVColumnSources"
            :putsValueRight="true"
            @overwrite="overwrite"/>
          <v-skeleton-loader v-else ref="skeleton" type="text" boilerplate width="100"/>
        </v-col>
      </v-row>
    </div>
    <div v-if="isScv">
      <v-row v-if="hasScvUnionSetting" no-gutters class="mb-1">
        <v-col cols="6">
          {{ $t('text.userDetail.unionedScv') }}
        </v-col>
        <v-spacer />
        <v-col class="text-right">
          <v-btn small color="denial" @click="unintegrateDialog = true" :disabled="!canDelete">
            {{ $t('btn.userDetail.unintegrate') }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- 顧客識別情報 (SCV) -->
      <div class="identify" v-if="scvChildren.length > 0">
        <div class="mb-1">{{ $t('text.userDetail.scvIdentify') }}</div>
        <div v-if="!hasScvUnionSetting && isParent" class="caption">
          {{ $t('text.userDetail.unintegrateScvNextTerm') }}
        </div>
        <div v-else-if="hasScvUnionSetting && !isParent" class="caption">
          {{ $t('text.userDetail.integrateScvNextTerm') }}
        </div>
        <template v-for="scv in scvChildren">
          <v-list :key="scv.id">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>SCV ID</v-list-item-title>
                <v-list-item-subtitle>{{ scv.scvId }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="(column, index) in scv.scvIdentifyColumns" :key="index">
              <v-list-item-content>
                <v-list-item-title>{{column.scvColumnLogicalName}}</v-list-item-title>
                <v-list-item-subtitle>{{column.scvColumnValue}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </div>
    </div>
    <!-- SCV統合解除モーダル -->
    <v-dialog v-model="unintegrateDialog" width="400">
      <v-card>
        <v-card-title>{{ $t('text.userDetail.unintegrateScv') }}</v-card-title>
        <v-card-text>
          {{ $t('form.userDetail.unintegrateScv') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="unintegrateDialog = false">{{ $t('btn.cancel') }}</v-btn>
          <v-btn color="error" @click="unintegrate">{{ $t('btn.userDetail.unintegrate') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// component
import scvColumn from '@/components/UserDetail/scvColumn'
// utils
import momentUtil from '@/utils/momentUtil'

export default {
  name: 'UserBaseInformation',
  mixins: [
    momentUtil,
  ],
  components: {
    scvColumn,
  },
  data () {
    return {
      unintegrateDialog: false,
    }
  },
  props: {
    loading: {
      type: Boolean,
    },
    scvSettings: {
      type: Array,
    },
    userDetail: {
      type: Object,
    },
    scvChildren: {
      type: Array,
    },
    hasScvUnionSetting: {
      type: Boolean,
    },
    scvDefaultColumnSources: {
      type: Array,
    },
    userType: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('auth', ['canDelete']),
    // TODO ENUM化
    isUnknown () {
      return this.userType === 'unknown'
    },
    isScv () {
      return this.userType === 'scv'
    },
    scvScore () {
      return this.findScvColumn(7, '-')
    },
    scvRank () {
      return this.findScvColumn(8, '-')
    },
    scvLTV () {
      return this.findScvColumn(9, '-')
    },
    scvScoreColumnSources () {
      return this.findScvColumnSources(7)
    },
    scvRankColumnSources () {
      return this.findScvColumnSources(8)
    },
    scvLTVColumnSources () {
      return this.findScvColumnSources(9)
    },
    isParent () {
      return this.scvChildren.length > 1
    },
  },
  methods: {
    // TODO Util切り出し
    findScvColumn (scvColumnId, defaultValue = '') {
      if (!this.scvSettings || !this.userDetail || !this.userDetail.scvDefaultColumns || !scvColumnId) {
        return {}
      }
      const scvColumnSetting = this.scvSettings.find((col) => col.scvColumnId === scvColumnId)
      const userScvColumn = this.userDetail.scvDefaultColumns.find((col) => col.scvColumnId === scvColumnId)
      return {
        scvColumnId: scvColumnSetting ? scvColumnSetting.scvColumnId : undefined,
        scvColumnName: scvColumnSetting ? scvColumnSetting.scvLogicalColumnName : '',
        scvColumnValue: userScvColumn ? userScvColumn.scvColumnValue : defaultValue,
        isUpdated: userScvColumn ? userScvColumn.updated : false,
      }
    },
    findScvColumnSources (scvColumnId) {
      if (!this.scvDefaultColumnSources || !scvColumnId) {
        return []
      }
      const columnSources = this.scvDefaultColumnSources.find((col) => col.scvColumnId === scvColumnId)
      if (columnSources !== undefined) {
        return columnSources.scvColumnDataSetSources
      }
      return []
    },
    /**
     * SCV項目を上書き
     */
    overwrite (scvColumnId, newValue) {
      this.$emit('overwrite', scvColumnId, newValue)
    },
    /**
     * 統合解除
     */
    unintegrate () {
      this.$emit('unintegrate')
      this.unintegrateDialog = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-card) using ($integral-core-theme) {
  .row {
    color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'userbaseInfo', 'row', 'text');
    &.ltv {
      color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'userbaseInfo', 'ltv', 'text');
    }
  }
  .identify {
    .v-list {
      .v-list-item {
        .v-list-item__content {
          .v-list-item__title {
            color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'userbaseInfo', 'identifyTitle', 'text');
          }
          .v-list-item__subtitle {
            color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'userbaseInfo', 'identifySubtitle', 'text');
          }
        }
      }
    }
  }
}

.row {
  font-size: 12px;
  line-height: 20px;
  .v-skeleton-loader {
    margin-right: 0;
    margin-left: auto;
  }
  &.ltv {
    font-size: 14px;
  }
}
.identify {
  .div {
    line-height: 19px;
    padding: 4px 0;
  }
  .v-list {
    padding: 0 0;
    margin-bottom: 28px;
    .v-list-item {
      padding: 0 0;
      min-height: unset;
      .v-list-item__content {
        padding: 4px 0;
        .v-list-item__title {
          font-weight: bold;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
