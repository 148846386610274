import axios from '@/axios'

const scvUnion = {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    /**
     * 設定追加
     * @param  {Object} formParameter リクエストパラメーター
     * @return {Promise}
     */
    async saveUnionSetting ({ commit }, formParameter) {
      await axios.post('/customerUnion/customer/', {
        baseCustomerCode: formParameter.defaultScvId,
        targetCustomerCodeList: formParameter.targetScvIds,
        selectedCustomerColumnList: formParameter.selectedScvColumnList,
      }).catch((err) => {
        return Promise.reject(err)
      })
    },
  },
}
export default scvUnion
