<template>
  <v-row v-if="isSearching" :justify="'center'" class="fill-height ma-3">
    <v-progress-circular indeterminate color="primary"/>
  </v-row>
  <v-row v-else-if="!isSearching && userList.length === 0">
    <v-col>
      <p>{{ $t(`text.segmentSetting.result.user.noUser`) }}</p>
    </v-col>
  </v-row>
  <v-row v-else>
    <!-- <v-col class="py-1" shrink v-for="(user, index) in userList" :key="user.userId">
      <v-card v-if="isUnknown" class="user_card" :class="{'user_card-active': user.userId === activeUserId}"
        @click.native="showUserDetail(user.userId)" hover>
        <v-card-text>
          <v-row class="body-1" :justify="'center'">
            <v-btn color="black" class="white--text" depressed rounded :ripple="false">
              <v-icon>person</v-icon>{{ $t(`text.userUnknown`) }}&nbsp;{{ index + 1 }}
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-else-if="isCustomer" :class="{'user_card-active': user.userId === activeUserId}"
        @click.native="showUserDetail(user.userId)" hover>
        <v-card-text>
          <v-row class="body-1" :justify="'center'">
            <v-btn color="primary" class="white--text" depressed rounded :ripple="false">
              <v-icon>how_to_reg</v-icon>{{ $t(`text.userCustomer`) }}&nbsp;{{ index + 1 }}
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col> -->
    <v-col cols="12">
      <!-- 匿名客リスト -->
      <!-- TODO InnerUnknown に寄せる -->
      <div v-if="isUnknown" class="caption pt-3">
        <!-- TODO i18n -->
        {{ $t(`text.segmentSetting.result.user.title`) }}&nbsp;({{ $t(`text.segmentSetting.result.user.subHead`) }}サンプリング)
        <v-card
          class="item-inPageScroll_y user-list mt-1"
          outlined
        >
          <v-list dense>
            <v-list-item
              v-for="(user) in userList"
              :key="user.userId"
              @click="showUserDetail(user.userId)"
            >
              <v-list-item-icon>
                <v-icon>ic-anonymous-M</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t(`text.userUnknown`) }} {{user.userId}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
      <!-- 顧客テーブル -->
      <inner-scv v-else :userList="userList" />
    </v-col>
  </v-row>
</template>

<script>
// component
import innerScv from './innerScv.vue'

export default {
  name: 'ResultUserList',
  components: {
    'inner-scv': innerScv,
  },
  props: {
    isSearching: {
      default: false,
    },
    isUnknown: {
      default: false,
    },
    isCustomer: {
      default: false,
    },
    userList: {
      type: Array,
      default () {
        return []
      },
    },
  },
  data () {
    return {
      activeUserId: null,
    }
  },
  watch: {
    userList: {
      handler () {
        if (!this.userList || this.userList.length === 0) return
        // リスト更新時に先頭のユーザーIDを代入
        this.activeUserId = this.userList[0].userId
      },
      deep: true,
    },
  },
  methods: {
    showUserDetail (userId) {
      this.activeUserId = userId
      this.$emit('clickList', userId)
    },
  },
}
</script>

<style lang="scss" scoped>
.user_card {
  border-left: 3px white solid;
}
.user_card-active {
  border-left-color: orange;
}
</style>
