<template>
  <v-data-table
    class="item-inPageScroll_x"
    :headers="tableHeaders"
    :items="accounts"
    :loading="loading"
    disable-filtering
    disable-pagination
    disable-sort
    hide-default-footer
    fixed-header
    @click:row="onClickRow" />
</template>

<script>
// util
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'accountsTable',
  mixins: [
    enumUtil,
    notifyUtil,
  ],
  props: {
    accounts: {
      type: Array,
      default () {
        return []
      },
    },
    loading: {
      type: Boolean,
      default () {
        return false
      },
    },
  },
  data () {
    return {
      tableHeaders: [
        {
          text: this.$t('table.accounts.loginUserName'),
          value: 'loginUserName',
          width: '15%',
        },
        {
          text: this.$t('table.accounts.emailAddress'),
          value: 'emailAddress',
          width: '20%',
        },
        {
          text: this.$t('table.accounts.role'),
          value: 'roleName',
          width: '10%',
        },
        {
          text: this.$t('table.accounts.note'),
          value: 'note',
          width: '20%',
        },
        {
          text: this.$t('table.accounts.lastLoginDateTime'),
          value: 'lastLoginDateTime',
          width: '10em',
        },
        {
          text: this.$t('table.accounts.createdAt'),
          value: 'createdAt',
          width: '10em',
        },
      ],
    }
  },
  methods: {
    onClickRow (account) {
      this.$emit('clickRow', account.loginUserId)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-data-table {
  ::v-deep .v-data-table__wrapper {
    max-height: 500px;
    th.column {
      top: 48px !important;
    }
    tbody tr {
      cursor: pointer;
    }
  }
}
</style>
