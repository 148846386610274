<template>
  <div>
    <v-tabs v-model="selectedTab">
      <v-tab href="#record">
        {{ $t("text.dataSource.record") }}
      </v-tab>
      <v-tab href="#schema">
        {{ $t("text.dataSource.schema") }}
      </v-tab>
      <v-tab href="#connector">
        {{ $t("text.dataSource.connector") }}
      </v-tab>
      <v-tab
        href="#history"
        v-if="
          dataSource.connectorType === CONNECTOR_TYPES.CSV.value ||
          dataSource.connectorType === CONNECTOR_TYPES.API.value
        "
      >
        {{ $t("text.dataSource.history") }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">

      <!-- レコード -->
      <v-tab-item value="record">
        <div>
          <span class="ml-4 mr-16 recordCount">
            <span class="label">全</span>
            {{ addComma(dataSource.total) }}
            <span class="label ml-3">件</span>
          </span>
          <span class="caption">
            {{ $t('text.dataSource.recordDataImportDelay') }}
          </span>
        </div>
        <v-data-table
          v-if="dataSource.dataSourceId"
          :headers="headerMetaHeader"
          :items="records"
          :loading="loading"
          hide-default-footer
          disable-pagination
          disable-sort
          :no-data-text="$t('text.dataSource.noRecord')"
        >
          <template #item.firstDeviceType="{ item }">
            {{ enumUtil.getEnumFromValue(DEVICE_TYPES, item.firstDeviceType).text }}
          </template>
          <template #item.firstOsType="{ item }">
            {{ enumUtil.getEnumFromValue(OS_TYPES, item.firstOsType).text }}
          </template>
          <template #item.firstBrowserType="{ item }">
            {{ enumUtil.getEnumFromValue(BROWSER_TYPES, item.firstBrowserType).text }}
          </template>
        </v-data-table>
      </v-tab-item>

      <!-- スキーマ -->
      <v-tab-item value="schema">
        <data-source-schema
          ref="dataSourceSchema"
          :dataSet="dataSet"
          :dataSource="dataSource"
          :loading="loading"
          v-on="$listeners"
        />
      </v-tab-item>

      <!-- コネクタ -->
      <v-tab-item value="connector">
        <data-source-connector
          :dataSet="dataSet"
          :dataSource="dataSource"
          :measureSettings="measureSettings"
          :scheduledToBeImportedObjectNameList="scheduledToBeImportedObjectNameList"
          :loading="loading"
          v-on="$listeners"
          @onClickInferSchemaFromCsvButton="onClickInferSchemaFromCsvButton"
        />
      </v-tab-item>

      <v-tab-item
        value="history"
        v-if="
          dataSource.connectorType === CONNECTOR_TYPES.CSV.value ||
          dataSource.connectorType === CONNECTOR_TYPES.API.value
        ">
        <data-source-import-history
          :dataSourceImportHistories="dataSourceImportHistories"
          :paging="dataSourceImportHistoryPaging"
          :loading="loadingDataSourceImportHistories"
          @getDataSourceImportHistories="$emit('getDataSourceImportHistories')"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// components
import dataSourceConnector from '@/components/DataSource/dataSourceConnector'
import dataSourceImportHistory from '@/components/DataSourceImportHistory/dataSourceImportHistory'
import dataSourceSchema from '@/components/DataSource/dataSourceSchema'
// enum
import BROWSER_TYPES from '@/enum/BROWSER_TYPES'
import CONNECTOR_TYPES from '@/enum/CONNECTOR_TYPES'
import DATA_TYPES from '@/enum/DATA_TYPES'
import DATA_SOURCE_TYPES from '@/enum/DATA_SOURCE_TYPES'
import DEVICE_TYPES from '@/enum/DEVICE_TYPES'
import OS_TYPES from '@/enum/OS_TYPES'
// utils
import displayConverter from '@/utils/displayConverter'
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'
import momentUtil from '@/utils/momentUtil'

export default {
  name: 'DataSourceTabs',
  mixins: [
    BROWSER_TYPES,
    CONNECTOR_TYPES,
    DATA_TYPES,
    DATA_SOURCE_TYPES,
    DEVICE_TYPES,
    OS_TYPES,
    displayConverter,
    enumUtil,
    notifyUtil,
    momentUtil,
  ],
  components: {
    dataSourceConnector,
    dataSourceImportHistory,
    dataSourceSchema,
  },
  props: {
    dataSet: {
      type: Object,
    },
    dataSource: {
      type: Object,
    },
    dataSourceImportHistories: {
      type: Array,
    },
    dataSourceImportHistoryPaging: {
      type: Object,
    },
    measureSettings: {
      type: Array,
    },
    scheduledToBeImportedObjectNameList: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    loadingDataSourceImportHistories: {
      type: Boolean,
    },
  },
  data () {
    return {
      requestParameter: {
        dataSourceId: null,
        domain: '',
      },
      selectedTab: 'record',
      uploadFile: null,
      isDrag: false,
      webMasterHeader: [
        { index: 1, logicalName: 'iuid', physicalName: 'iuid', dataType: 1 },
        { index: 2, logicalName: '顧客識別情報', physicalName: 'customerIdentify', dataType: 1 },
        { index: 3, logicalName: '初回デバイス種別', physicalName: 'firstDeviceType', dataType: 2 },
        { index: 4, logicalName: '初回OS種別', physicalName: 'firstOsType', dataType: 2 },
        { index: 5, logicalName: '初回ブラウザ種別', physicalName: 'firstBrowserType', dataType: 2 },
        { index: 6, logicalName: '初回IPアドレス', physicalName: 'firstIpAddress', dataType: 1 },
        { index: 7, logicalName: '初回アクセス日時', physicalName: 'firstAccessDatetime', dataType: 4 },
        { index: 8, logicalName: '最終アクセス日時', physicalName: 'lastAccessDatetime', dataType: 4 },
        { index: 9, logicalName: '訪問回数', physicalName: 'visitCnt', dataType: 1 },
      ],
    }
  },
  computed: {
    headerMetaColumns () {
      if (this.dataSource.headerMetaJson) {
        return JSON.parse(JSON.stringify(JSON.parse(this.dataSource.headerMetaJson).columns))
      } else {
        return []
      }
    },
    headerMetaHeader () {
      let header
      if (!this.dataSource.dataSourceId ||
        this.dataSource.connectorType === this.CONNECTOR_TYPES.WEB.value ||
        this.dataSource.connectorType === this.CONNECTOR_TYPES.ANDROID.value ||
        this.dataSource.connectorType === this.CONNECTOR_TYPES.IOS.value) {
        header = this.webMasterHeader.map(column => {
          return {
            text: column.logicalName,
            value: column.physicalName,
            sortable: false,
          }
        })
      } else {
        // データの共通化の流れが悪いが仕方がない
        header = this.headerMetaColumns.map(column => {
          return {
            text: column.logicalName,
            // ユーザー定義マスタの実データはBI-Redshiftから取得するのでRedshiftの仕様上キーが全て小文字になっている
            // なのでAuroraから取得したユーザー定義マスタのヘッダーのキーをlowerCaseに変換
            value: column.physicalName.toLowerCase(),
            sortable: false,
          }
        })
      }
      return header
    },
    records () {
      if (this.dataSource.connectorType === this.CONNECTOR_TYPES.WEB.value) {
        return this.dataSource.dataSourceUserUnknownDtoList
      } else {
        return this.dataSource.dataMetaList
      }
    },
    hashValue () {
      if (this.dataSource.dataSourceType === this.DATA_SOURCE_TYPES.MASTER.value) {
        return this.dataSource.masterHash
      } else if (this.dataSource.dataSourceType === this.DATA_SOURCE_TYPES.TRANSACTION.value) {
        return this.dataSource.transactionHash
      }
      return ''
    },
    apiTag () {
      // 「/」をエスケープするとでesLintエラーが起きてしまうのでesLintを一時的に無効化
      /* eslint-disable no-useless-escape */
      return `<script>
window.integralCore = window.integralCore || function(){
  (integralCore.params = integralCore.params||[]).push(arguments)
};
integralCore('', 'datasourceimport', '', '//{移管ドメイン}/');
var commonEl = document.createElement('script');
commonEl.src = '//cdn.{移管ドメイン}/js/integralCoreCommon.js';
commonEl.async = 1;
document.body.appendChild(commonEl);
function dataSourceImport(dataMetaJson) {
  let param = ['', 'datasourceimport', {
    "dataSourceHash": "${this.hashValue}",
    "dataMetaJson": dataMetaJson,
  }];
  integralCoreCommon.postDataSource(param);
}

// メソッド呼び出しサンプル
data = {
${
  this.headerMetaColumns.map(col => {
    return `  ${col.physicalName}: {{${this.dataTypes.find(type => type.value === col.dataType).text}}}`
  })
    .join(',\n')
}
};
dataSourceImport(data);
<\/script>`
      /* eslint-enable no-useless-escape */
    },
    siteTag () {
      // 「/」をエスケープするとでesLintエラーが起きてしまうのでesLintを一時的に無効化
      /* eslint-disable no-useless-escape */
      return `<script>
window.integralCore = window.integralCore || function(){
(integralCore.params=integralCore.params||[]).push(arguments)
};
integralCore('` + this.dataSource.siteHash + `', 'pageview');
<\/script>
<script async src='https://cdn.ic.${this.dataSource.measureDomain}/js/${this.dataSource.contractCompanyId}/${this.dataSource.siteMeasureScriptName}' type="text/javascript" charset="UTF-8"><\/script>`
      /* eslint-enable no-useless-escape */
    },
    sdkJson () {
      return `{
  "measure-url": "https://{移管対応済みのドメイン}/measure",
  "site-hash": "` + this.dataSource.siteHash + `"
}`
    },
    replacementS3path () {
      return process.env.VUE_APP_S3_BUCKET_NAME_PREFIX + this.$store.state.auth.loginUserDetail.loginUserWebWorkspaceName + process.env.VUE_APP_S3_BUCKET_NAME_SUFFIX + '/data-source-import/' + this.hashValue + '/replacement/'
    },
    differenceS3path () {
      return process.env.VUE_APP_S3_BUCKET_NAME_PREFIX + this.$store.state.auth.loginUserDetail.loginUserWebWorkspaceName + process.env.VUE_APP_S3_BUCKET_NAME_SUFFIX + '/data-source-import/' + this.hashValue + '/difference/'
    },
    dataTypes () {
      const tmpDataTypes = Object.values(this.DATA_TYPES)
      return tmpDataTypes
    },
    disabledUpdateSchema () {
      const transactionLink = this.dataSet.createRuleList?.find(rule =>
        this.dataSource.dataSourceId === rule.targetDataSourceId,
      )
      return this.dataSet.hasMasterLink || transactionLink
    },
  },
  watch: {
    dataSource: {
      handler () {
        this.requestParameter.dataSourceId = this.dataSource.dataSourceId
        this.requestParameter.domain = this.dataSource.domain
      },
      immediate: true,
    },
  },
  methods: {
    onClickInferSchemaFromCsvButton (columns) {
      this.selectedTab = 'schema'
      // dataSourceSchema が未レンダリングの場合は refs から辿れないのでキューに飛ばして待つ
      setTimeout(() => {
        this.$refs.dataSourceSchema.headerMetaColumns = JSON.parse(JSON.stringify(columns))
      }, 0)
    },
    onCancelClick () {
      this.$emit('cancel')
    },
    copyError (e) {
      this.notifyErrorMessage(e)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-text-field) using ($integral-core-theme) {
  &.modified {
    background-color: map-deep-get($integral-core-theme, 'inputs', 'filled');
  }
}
@include theme(v-textarea) using ($integral-core-theme) {
  &.modified {
    background-color: map-deep-get($integral-core-theme, 'inputs', 'filled');
  }
}
@include theme(v-tabs-items) using ($integral-core-theme) {
  #record {
    .recordCount {
      color: map-deep-get($integral-core-theme, 'views', 'dataSourceDetail', 'record', 'text');
    }
  }
}
#record {
  .recordCount {
    font-size: 11px;
    font-weight: bold;
    .label {
      font-size: 10px;
    }
  }
}
</style>
