<template>
  <v-progress-circular
    :color="color"
    :rotate="-90"
    :size="size"
    :value="totalRatio"
    :width="8">
    <span>
      {{ totalRatio }}&#37;
    </span>
  </v-progress-circular>
</template>

<script>
// util
import displayConverter from '@/utils/displayConverter'

export default {
  name: 'SegmentUserCircleChart',
  mixins: [
    displayConverter,
  ],
  props: {
    size: {
      type: Number,
      default () {
        return 80
      },
    },
    count: {
      type: Number,
      default () {
        return 0
      },
    },
    total: {
      type: Number,
      default () {
        return 0
      },
    },
  },
  computed: {
    /**
     * 匿名客/顧客の総数に対するセグメントに紐付く匿名客/顧客数の割合
     * @type {Number}
     */
    totalRatio () {
      return this.total ? this.calculateRatioUser(this.count, this.total) : 0
    },
    // TODO: overrides.scssが適用されないので暫定対応
    color () {
      return this.$vuetify.theme.dark ? '#C8E6F6' : '#054263'
    },
  },
}
</script>
