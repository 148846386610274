import axios from '@/axios'

const segmentSetting = {
  namespaced: true,
  actions: {
    /**
     * セグメント作成
     * @param  {Object}  requestParameter リクエストパラメーター
     * @return {Promise}
     */
    async postSettingSegment ({ commit }, requestParameter) {
      await axios.post('/segments/', {
        segmentName: requestParameter.segmentName,
        userType: requestParameter.userType,
        buildType: requestParameter.buildType,
        note: requestParameter.note,
        filterRuleJson: JSON.stringify(requestParameter.filterRuleJson),
        filterQuery: requestParameter.filterQuery,
        jobSetting: requestParameter.jobSetting,
      })
    },
    /**
     * セグメント1件更新
     * @param  {Object}  requestParameter リクエストパラメーター
     * @return {Promise}
     */
    async putSettingSegment ({ commit }, requestParameter) {
      await axios.put('/segments/' + requestParameter.putedSegmentId + '/', {
        segmentName: requestParameter.segmentName,
        userType: requestParameter.userType,
        buildType: requestParameter.buildType,
        note: requestParameter.note,
        filterRuleJson: JSON.stringify(requestParameter.filterRuleJson),
        filterQuery: requestParameter.filterQuery,
        jobSetting: requestParameter.jobSetting,
      })
    },
    /**
     * セグメント1件削除
     * @param  {Number}  segmentId 削除対象のセグメントID
     * @return {Promise}
     */
    async deleteSegment ({ commit }, segmentId) {
      await axios({ method: 'delete', url: '/segments/' + segmentId + '/' })
    },
  },
}
export default segmentSetting
