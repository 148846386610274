export default {
  data () {
    return {
      FILTER_RULE_TYPE: {
        ATTRIBUTE: { label: '属性', value: 1 },
        WEB_TRANSACTION: { label: 'Web行動', value: 2 },
        TRANSACTION_SUMMARY: { label: 'トランザクションサマリ', value: 3 },
        GROUP: { label: 'グループ', value: 9 },
      },
    }
  },
}
