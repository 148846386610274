<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn :to="{ name: 'Accounts'}">{{ $t(`btn.backToList`) }}</v-btn>
      </template>
      <template #right>
        <v-btn v-if="$route.name === 'AccountEdit'" @click="showDeleteDialog = true" :disabled="!canDelete || loading">{{ $t(`btn.delete`) }}</v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <h3 class="lead-line">アカウント</h3>
      <v-card outlined tile>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="formParameter.loginUserName"
                :label="$t(`form.accounts.loginUserName`)"
                persistent-placeholder
                :placeholder="$t(`form.accounts.placeholder`)"
                name="loginUserName"
                :data-vv-as="$t(`form.accounts.loginUserName`)"
                v-validate="'required|max:255'"
                :error-messages="errors.collect('loginUserName')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="formParameter.emailAddress"
                :label="$t(`form.mailAddress`)"
                persistent-placeholder
                :placeholder="$t(`form.accounts.placeholder`)"
                name="emailAddress"
                :data-vv-as="$t(`form.mailAddress`)"
                v-validate="'required|emailSyntax|max:255'"
                :error-messages="errors.collect('emailAddress')"
                :disabled="$route.name === 'AccountEdit'"
              />
            </v-col>
            <v-col v-if="$route.name === 'AccountEdit'" cols="4">
              <v-btn @click="showPasswordRecreateCheckDialog = true" :disabled="disableSaveButton">パスワード再発行</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="$route.name === 'AccountEdit' && mfaRequired">
            <v-col cols="8" class="align-self-center">
              MFAデバイス登録
            </v-col>
            <v-col cols="4">
              <v-btn v-if="mfaDeviceRegistered" @click="showMfaDeviceUnregisterCheckDialog = true" :disabled="!canDelete && !editMyself">デバイス登録解除</v-btn>
              <v-btn v-else disabled>デバイス未登録</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8">
              <v-textarea
                v-model="formParameter.note"
                :label="$t(`form.accounts.note`)"
                name="note"
                :data-vv-as="$t(`form.accounts.note`)"
                v-validate="'max:1000'"
                :error-messages="errors.collect('note')"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <h4 class="mt-7">権限</h4>
      <v-card outlined tile>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-select
                v-model.number="formParameter.roleId"
                :items="roleList"
                item-text="roleName"
                item-value="roleId"
                :label="$t(`text.accounts.filtering.role`)"
                return-object
                name="roleId"
                :disabled="!canGetThis('AccountRoles')"
                :data-vv-as="$t(`text.accounts.filtering.role`)"
                v-validate="'required'"
                :error-messages="errors.collect('roleId')"
                @change="selectRole"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="denial" large :to="{ name: 'Accounts' }">{{ $t('btn.cancel') }}</v-btn>
      <v-btn color="primary" large @click="onClickSaveButton()" :disabled="disableSaveButton">{{ $t('btn.save') }}</v-btn>
      <v-spacer />
    </v-card-actions>
    <!-- アカウント設定確認ダイアログ -->
    <v-dialog v-model="showRequestCheckDialog" :width="'35%'">
      <v-card>
        <v-card-title>
          入力内容の確認
        </v-card-title>
        <v-card-text>
          <span class="text-request-dialog">{{ $t('text.accounts.loginUserName') }}</span>&#58;&nbsp;{{ formParameter.loginUserName }}<br>
          <span class="text-request-dialog">{{ $t('text.accounts.emailAddress') }}</span>&#58;&nbsp;{{ formParameter.emailAddress }}<br>
          <span class="text-request-dialog">{{ $t('text.accounts.roleName') }}</span>&#58;&nbsp;{{ selectedRoleName }}<br>
          <span class="text-request-dialog">{{ $t('text.accounts.note') }}</span>&#58;&nbsp;{{ formParameter.note }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="denial" @click="showRequestCheckDialog = false">{{ $t('btn.cancel') }}</v-btn>
          <v-btn color="primary" @click="onClickRequestButton()" :loading="loading">{{ $t('btn.save') }}</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- アカウント設定結果ダイアログ -->
    <v-dialog v-model="showRequestResultDialog" persistent :width="'35%'">
      <v-card>
        <v-card-title>
          <v-card-title>{{ $t('text.accounts.loginInfo') }}</v-card-title>
        </v-card-title>
        <v-card-text>
          <span class="text-request-dialog">{{ $t('text.accounts.emailAddress') }}</span>&#58;&nbsp;{{ resultContent.emailAddress }}<br>
          <span class="text-request-dialog">{{ $t('text.accounts.password') }}</span>&#58;&nbsp;{{ resultContent.password }}<br>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="closeRequestResultDialog()">{{ $t('btn.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- アカウント削除確認ダイアログ -->
    <v-dialog v-model="showDeleteDialog" persistent width="400" :loading="loading">
      <v-card>
        <v-card-title/>
        <v-card-text>
          {{ $t('notify.check.delete', [formParameter.loginUserName]) }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="denial" @click="showDeleteDialog = false">{{ $t('btn.no') }}</v-btn>
          <v-btn color="primary" @click="deleteAccount()">{{ $t('btn.yes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- パスワード再発行確認用ダイアログ -->
    <v-dialog v-model="showPasswordRecreateCheckDialog" :width="'35%'">
      <v-card>
        <v-card-title />
        <v-card-text>{{ $t('notify.check.accounts.recreate') }}</v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="denial" @click="showPasswordRecreateCheckDialog = false">{{ $t('btn.no') }}</v-btn>
          <v-btn color="primary" @click="recreatePassword()" :loading="loading">{{ $t('btn.yes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- パスワード再発行結果ダイアログ -->
    <v-dialog v-model="showPasswordRecreateResultDialog" persistent :width="'35%'">
      <v-card>
        <v-card-title>{{ $t('text.accounts.loginInfo') }}</v-card-title>
        <v-card-text>
          <span class="text-account-dialog">{{ $t('text.accounts.emailAddress') }}</span>&#58;&nbsp;{{ resultContent.emailAddress }}<br>
          <span class="text-account-dialog">{{ $t('text.accounts.password') }}</span>&#58;&nbsp;{{ resultContent.password }}
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="showPasswordRecreateResultDialog = false" :loading="loading">{{ $t('btn.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- デバイス登録解除確認用ダイアログ -->
    <v-dialog v-model="showMfaDeviceUnregisterCheckDialog" :width="'35%'">
      <v-card>
        <v-card-title />
        <v-card-text>{{ $t('notify.check.accounts.unregisterDevice') }}</v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="denial" @click="showMfaDeviceUnregisterCheckDialog = false">{{ $t('btn.no') }}</v-btn>
          <v-btn color="primary" @click="unregisterDevice()" :loading="loading">{{ $t('btn.yes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// util
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'accountSetting',
  mixins: [
    notifyUtil,
  ],
  data () {
    return {
      showRequestCheckDialog: false,
      showRequestResultDialog: false,
      showPasswordRecreateCheckDialog: false,
      showPasswordRecreateResultDialog: false,
      showMfaDeviceUnregisterCheckDialog: false,
      showDeleteDialog: false,
      roleList: [],
      mfaDeviceRegistered: false,
      formParameter: {
        loginUserId: null,
        loginUserName: null,
        emailAddress: null,
        roleId: null,
        note: null,
      },
      resultContent: {
        loginUserName: null,
        emailAddress: null,
        password: null,
        note: null,
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['canGetThis', 'canPost', 'canPut', 'canDelete']),
    disableSaveButton () {
      if (this.$route.name === 'AccountSetting') {
        return !this.canPost
      } else if (this.$route.name === 'AccountEdit') {
        return !this.canPut
      }
      return false
    },
    selectedRoleName () {
      if (this.formParameter.roleId) return this.roleList?.find(role => role.roleId === this.formParameter.roleId)?.roleName
      return null
    },
    editMyself () {
      return this.$store.state.auth.loginUserDetail.loginUserId === this.formParameter.loginUserId
    },
    mfaRequired () {
      return this.$store.state.auth.loginUserDetail.mfaRequired
    },
    loadingAccountRoles () {
      return this.$store.state.accountRole.loadingAccountRoles
    },
    loadingAccount () {
      return this.$store.state.accounts.loadingAccount
    },
    postingAccount () {
      return this.$store.state.accounts.postingAccount
    },
    putingAccount () {
      return this.$store.state.accounts.putingAccount
    },
    deletingAccount () {
      return this.$store.state.accounts.deletingAccount
    },
    recreatingPassword () {
      return this.$store.state.accounts.recreatingPassword
    },
    loadingLoginUser () {
      return this.$store.state.auth.loadingLoginUser
    },
    loading () {
      return this.recreatingPassword || this.deletingAccount || this.putingAccount || this.postingAccount || this.loadingAccount || this.loadingAccountRoles || this.loadingLoginUser
    },
  },
  created () {
    if (this.$route.name === 'AccountEdit') {
      this.fetchAccount(this.$route.params.accountId)
      this.$store.dispatch('auth/getLoginUser')
    }

    this.$store.dispatch('accountRole/getAccountRoles')
      .then(response => {
        this.roleList = response.data.data.roleList
      }).catch((err) => {
        this.handleErrorResponse(err)
      })
  },
  methods: {
    fetchAccount (accountId) {
      this.$store.dispatch('accounts/getAccount', accountId)
        .then(res => {
          this.formParameter.loginUserId = res.data.data.loginUserId
          this.formParameter.loginUserName = res.data.data.loginUserName
          this.formParameter.emailAddress = res.data.data.emailAddress
          this.formParameter.roleId = res.data.data.roleId
          this.formParameter.note = res.data.data.note
          this.mfaDeviceRegistered = res.data.data.mfaDeviceRegistered
        }).catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    selectRole (newVal) {
      this.formParameter.roleId = newVal.roleId
    },
    onClickSaveButton () {
      this.$validator.validateAll()
        .then((result) => {
          if (!result) {
            for (const error of this.errors.items) {
              this.notifyErrorMessage(error.msg)
            }
            return
          }
          this.showRequestCheckDialog = true
        })
    },
    onClickRequestButton () {
      if (this.$route.name === 'AccountSetting') {
        this.$store.dispatch('accounts/postAccount', this.formParameter)
          .then(res => {
            this.notifySuccessMessage(this.$t('notify.account') + this.$t('notify.success.create'))
            this.resultContent.emailAddress = res.data.data.emailAddress
            this.resultContent.password = res.data.data.password
            this.showRequestCheckDialog = false
            this.showRequestResultDialog = true
          }).catch((err) => {
            this.handleErrorResponse(err)
          })
      } else if (this.$route.name === 'AccountEdit') {
        this.$store.dispatch('accounts/putAccount', this.formParameter)
          .then(() => {
            this.notifySuccessMessage(this.$t('notify.account') + this.$t('notify.success.update'))
            this.showRequestCheckDialog = false
            this.$router.push({ name: 'Accounts' })
          }).catch((err) => {
            this.handleErrorResponse(err)
          })
      }
    },
    deleteAccount () {
      this.$store.dispatch('accounts/deleteAccount', this.formParameter.loginUserId)
        .then(res => {
          this.notifySuccessMessage(this.$t('notify.account') + this.$t('notify.success.delete'))
          this.$router.push({ name: 'Accounts' })
        }).catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    /**
     * パスワードを再発行
     */
    recreatePassword () {
      this.$store.dispatch('accounts/recreatePassword', this.formParameter.loginUserId)
        .then(res => {
          this.resultContent.emailAddress = res.data.data.emailAddress
          this.resultContent.password = res.data.data.password
          this.showPasswordRecreateCheckDialog = false
          this.showPasswordRecreateResultDialog = true
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    },
    closeRequestResultDialog () {
      this.showRequestResultDialog = false
      this.$router.push({ name: 'Accounts' })
    },
    /**
     * MFAデバイス登録解除
     */
    unregisterDevice () {
      if (this.editMyself) {
        this.$store.dispatch('auth/unregisterMyDevice')
          .then(() => {
            // 自分のMFAデバイス登録解除時は再設定が必要なのでMFAデバイス登録画面へ遷移させる
            this.$router.push({ name: 'TotpRegister' })
            this.notifySuccessMessage(this.$t('notify.mfaDeviceRegistered') + this.$t('notify.success.delete'))
          })
          .catch((err) => this.handleErrorResponse(err))
      } else {
        this.$store.dispatch('auth/unregisterDevice', this.formParameter.loginUserId)
          .then(() => {
            this.fetchAccount(this.formParameter.loginUserId)
            this.showMfaDeviceUnregisterCheckDialog = false
            this.notifySuccessMessage(this.$t('notify.mfaDeviceRegistered') + this.$t('notify.success.delete'))
          })
          .catch((err) => this.handleErrorResponse(err))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.text-account-dialog {
  width: 8em;
}
</style>
