import axios from '@/axios'

const segmentSearch = {
  namespaced: true,
  state: {
    loadingSearch: false,
    loadingUserSummary: true,
  },
  mutations: {
    setLoadingSearch (state, newval) {
      state.loadingSearch = newval
    },
    setLoadingUserSummary (state, newval) {
      state.loadingUserSummary = newval
    },
  },
  actions: {
    /**
     * セグメントユーザー検索
     * @param  {Object} params リクエストパラメーター
     * @return {Promise}
     */
    searchSegmentUser ({ commit }, params) {
      commit('setLoadingSearch', true)
      return axios.put('/segments/search/', {
        buildType: params.buildType,
        userType: params.userType,
        baseDate: params.baseDate,
        filterRuleJson: JSON.stringify(params.filterRuleJson),
        filterQuery: params.filterQuery,
      }).finally(() => {
        commit('setLoadingSearch', false)
      })
    },
    /**
     * セグメントユーザーサマリ検索
     * @param  {Object} params リクエストパラメーター
     * @return {Promise}
     */
    searchUserSummary ({ commit }, params) {
      commit('setLoadingUserSummary', true)
      return axios.get('/userDetail/' + params.userId + '/summary/', {
        params: {
          userType: params.userType,
          periodType: params.periodType,
          startDate: params.startDate,
          endDate: params.endDate,
          baseDate: params.baseDate,
        },
      }).finally(() => {
        commit('setLoadingUserSummary', false)
      })
    },
    updateLoadingUserSummary ({ commit }, newval) {
      commit('setLoadingUserSummary', newval)
    },
  },
}
export default segmentSearch
