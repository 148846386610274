<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 class="mb-4">デフォルト</h2>
        <v-list>
          <v-list-item-group v-model="selected">
            <v-list-item v-for="(list, index) in lists" :key="index" :value="list.name">
              <v-list-item-content>
                {{ list.name }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col>
        <h2 class="mb-4">nav</h2>
        <v-list outlined nav>
          <v-list-item-group v-model="selected">
            <v-list-item v-for="(list, index) in lists" :key="index" :value="list.name">
              <v-list-item-icon><v-icon v-if="list.icon">{{ list.icon }}</v-icon></v-list-item-icon>
              <v-list-item-content>
                {{ list.name }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col>
        <h2 class="mb-4">outlined</h2>
        <v-list outlined>
          <v-list-item-group v-model="selected">
            <v-list-item v-for="(list, index) in lists" :key="index" :value="list.name">
              <v-list-item-content>
                {{ list.name }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Lists',
  data () {
    return {
      selected: '管理アカウント',
      lists: [
        { name: '管理アカウント', icon: 'mdi-account-hard-hat' },
        { name: '一般アカウント', icon: 'mdi-account' },
        { name: 'マーケター', icon: null },
      ],
    }
  },
}
</script>
