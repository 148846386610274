<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn :to="'/dataSet/setting/' + dataSetId">
          {{ $t(`btn.back`) }}
        </v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <stepper :item="steps" class="mb-6" />
      <div>{{ $t('text.dataSet.dataSetName') }}&#58;&nbsp;{{ dataSet.dataSetName }}</div>
      <div class="mb-2">{{ $t('text.dataSource.dataSourceName') }}&#58;&nbsp;{{ fetchedDataSource.dataSourceName }}</div>

      <h3 class="lead-line">スキーマの設定</h3>
      <v-progress-linear v-if="loadingDataSet" indeterminate color="primary" />
      <v-card outlined tile>
        <v-card-text>
          <data-source-schema
            ref="dataSourceSchema"
            :dataSet="dataSet"
            :dataSource="fetchedDataSource"
            :loading="loadingDataSource"
            v-on="$listeners"
            @saveSchema="saveDataSourceSchema"
            @cancel="onClickCancelSchemaButton"
          />
        </v-card-text>
      </v-card>

      <h3 class="mt-8 lead-line">コネクタの設定</h3>
      <v-progress-linear v-if="loadingDataSet" indeterminate color="primary" />
      <v-card outlined tile>
        <v-card-text>
          <data-source-connector
            :dataSet="dataSet"
            :dataSource="fetchedDataSource"
            :measureSettings="measureSettings"
            :scheduledToBeImportedObjectNameList="scheduledToBeImportedObjectNameList"
            :loading="loadingDataSource"
            v-on="$listeners"
            @onClickInferSchemaFromCsvButton="onClickInferSchemaFromCsvButton"
            @saveDomain="saveDataSourceDomain"
            @executeImporter="executeImporter"
            @cancel="onClickCancelButton"
          />
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn large color="secondary" :to="{ name: 'DataSourceSettingDetail', params: { dataSetId: dataSetId, dataSourceId: dataSourceId }}">{{ $t('btn.dataSource.finishSetting') }}</v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
// components
import dataSourceConnector from '@/components/DataSource/dataSourceConnector'
import dataSourceSchema from '@/components/DataSource/dataSourceSchema'
import stepper from '@/components/common/stepper'
// enums
import CONNECTOR_TYPES from '@/enum/CONNECTOR_TYPES'
// util
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'dataSourceConnectorSetting',
  mixins: [
    CONNECTOR_TYPES,
    notifyUtil,
  ],
  components: {
    dataSourceConnector,
    dataSourceSchema,
    stepper,
  },
  data () {
    return {
      dataSetId: Number(this.$route.params.dataSetId),
      dataSet: {},
      dataSourceId: Number(this.$route.params.dataSourceId),
      fetchedDataSource: {},
      steps: [
        { text: this.$t('form.dataSet.add') },
        { text: this.$t('form.dataSource.add') },
        { text: this.$t('form.dataSource.connector'), active: true },
      ],
      measureSettings: [],
      requestParameter: {
        dataSetId: Number(this.$route.params.dataSetId),
        dataSourceId: Number(this.$route.params.dataSourceId),
        measureSettingId: '',
        domain: '',
        bundle: '',
      },
    }
  },
  computed: {
    loadingDataSet () {
      return this.$store.state.dataSet.loadingDataSet
    },
    userAuth () {
      return this.$store.state.auth.loginUserDetail.authority.dataSet
    },
    scheduledToBeImportedObjectNameList () {
      return this.$store.state.dataSource.scheduledToBeImportedObjectNameList
    },
    loadingDataSource () {
      return this.$store.state.dataSource.loadingDataSource
    },
  },
  created () {
    this.$store.dispatch('measureSettings/getMeasureSetting')
      .then((res) => {
        this.measureSettings = res.measureSettings
      })
      .catch((err) => {
        this.handleErrorResponse(err)
      })
    this.fetchDataSet()
    this.fetchDataSource()
    this.fetchDataSourceScheduledToBeImportedObjectList()
  },
  methods: {
    fetchDataSet () {
      this.$store.dispatch('dataSet/getDataSet', this.dataSetId)
        .then((res) => {
          this.dataSet = res
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    fetchDataSource () {
      this.$store
        .dispatch('dataSource/getDataSource', this.dataSourceId)
        .then(res => {
          this.fetchedDataSource = res.data.data
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    fetchDataSourceScheduledToBeImportedObjectList () {
      this.$store
        .dispatch('dataSource/getScheduledToBeImportedObjectList', this.dataSourceId)
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    onClickInferSchemaFromCsvButton (columns) {
      this.selectedTab = 'schema'
      this.$refs.dataSourceSchema.headerMetaColumns = JSON.parse(JSON.stringify(columns))
    },
    saveDataSourceSchema (dataSourceId, headerMetaColumns, userIdentifyColumn, datetimeIdentifyColumn, transactionIdentifierColumn) {
      this.$store.dispatch('dataSource/putDataSourceConnector', {
        dataSourceId: dataSourceId,
        headerMetaJson: JSON.stringify(headerMetaColumns),
        userIdentifyColumn: userIdentifyColumn,
        datetimeIdentifyColumn: datetimeIdentifyColumn,
        transactionIdentifierColumn: transactionIdentifierColumn,
      })
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.schema') + this.$t('notify.success.update'))
          this.fetchDataSource()
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    saveDataSourceDomain (dataSourceId, domain, measureSettingId) {
      this.$store.dispatch('dataSource/putDataSourceConnectorDomain', {
        dataSourceId: dataSourceId,
        domain: domain,
        measureSettingId: measureSettingId,
      })
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.dataSourceDomain') + this.$t('notify.success.update'))
          this.fetchDataSource()
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    executeImporter () {
      this.$store.dispatch('dataSource/postImport', {
        dataSourceId: this.dataSourceId,
      })
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.success.executeImporter'))
          this.fetchDataSource()
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    onClickCancelSchemaButton () {
      this.$refs.dataSourceSchema.headerMetaColumns = []
    },
    onClickCancelButton () {
      this.$router.push({ name: 'DataSourceSettingDetail', params: { dataSetId: this.dataSetId, dataSourceId: this.dataSourceId } })
    },
  },
}
</script>
