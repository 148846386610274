export default {
  data () {
    return {
      COLUMN_REFERENCE_TYPES: {
        UNKNOWN_COLUMN: { text: 'UNKNOWN_COLUMN', value: 1 },
        CUSTOMER_COLUMN: { text: 'CUSTOMER_COLUMN', value: 2 },
        UNKNOWN_SUMMARY_COLUMN: { text: 'UNKNOWN_SUMMARY_COLUMN', value: 3 },
        CUSTOMER_SUMMARY_COLUMN: { text: 'CUSTOMER_SUMMARY_COLUMN', value: 4 },
        UNKNOWN_INTERNAL_SUMMARY_COLUMN: { text: 'UNKNOWN_INTERNAL_SUMMARY_COLUMN', value: 5 },
        CUSTOMER_INTERNAL_SUMMARY_COLUMN: { text: 'CUSTOMER_INTERNAL_SUMMARY_COLUMN', value: 6 },
      },
    }
  },
}
