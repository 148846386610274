<template>
  <v-toolbar height="48">
    <slot name="left" />
    <v-spacer />
      <!-- カスタマー数 -->
      <v-tooltip v-if="showTotalUser" bottom>
        <template #activator="{ on }">
          <v-card v-on="on" outlined class="mr-4">
            <v-card-text>
              <v-icon>ic-customer-M</v-icon>
              <span class="total-user">{{ customerTotalUser }}</span>
              <span class="unit-human">{{ $t(`text.unitHuman`) }}</span>
              <v-icon class="ratio-arrow" :class="[customerWeeklyRatio >= 0 ? 'up' : 'down']">{{ ratioArrowIcon(customerWeeklyRatio) }}</v-icon>
              <span class="ratio-text">{{ $t(`text.beforeWeeklyRatio`) }}&nbsp;{{ ratioSign(customerWeeklyRatio) }}{{ customerWeeklyRatio }}&#37;&nbsp;</span>
              <span class="ratio-text d-none d-lg-inline d-print-inline">&#40;{{ customerExecuteDate }}{{ $t(`text.asOfTime`) }}&#41;</span>
            </v-card-text>
          </v-card>
        </template>
        <span>
          {{ $t(`text.scvTotalUser`) }}{{ $t(`text.delimiter`) }}{{ customerWholeTotalUser }}{{ $t(`text.unitHuman`) }}
        </span>
        <span class="d-lg-none">
          &#40;{{ customerExecuteDate }}{{ $t(`text.asOfTime`) }}&#41;
        </span>
      </v-tooltip>
      <!-- 匿名客数 -->
      <v-tooltip v-if="showTotalUser" bottom>
        <template #activator="{ on }">
          <v-card v-on="on" outlined>
            <v-card-text>
              <v-icon>ic-anonymous-M</v-icon>
              <span class="total-user">{{ unknownTotalUser }}</span>
              <span class="unit-human">{{ $t(`text.unitHuman`) }}</span>
              <v-icon class="ratio-arrow" :class="[unknownWeeklyRatio >= 0 ? 'up' : 'down']">{{ ratioArrowIcon(unknownWeeklyRatio) }}</v-icon>
              <span class="ratio-text">{{ $t(`text.beforeWeeklyRatio`) }}&nbsp;{{ ratioSign(unknownWeeklyRatio) }}{{ unknownWeeklyRatio }}&#37;&nbsp;</span>
              <span class="ratio-text d-none d-lg-inline d-print-inline">&#40;{{ unknownExecuteDate }}{{ $t(`text.asOfTime`) }}&#41;</span>
            </v-card-text>
          </v-card>
        </template>
        <span>
          {{ $t(`text.unknownTotalUser`) }}{{ $t(`text.delimiter`) }}{{ unknownWholeTotalUser }}{{ $t(`text.unitHuman`) }}
        </span>
        <span class="d-lg-none">
          &#40;{{ unknownExecuteDate }}{{ $t(`text.asOfTime`) }}&#41;
        </span>
      </v-tooltip>
    <v-spacer />
    <slot name="right" />
  </v-toolbar>
</template>

<script>
// utils
import displayConverter from '@/utils/displayConverter'
import momentUtil from '@/utils/momentUtil'

export default {
  name: 'InformationBar',
  mixins: [
    displayConverter,
    momentUtil,
  ],
  props: {
    showTotalUser: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    unknownTotalUser () {
      return this.addKIfOverKilo(this.$store.state.totalUser.totalUser.userUnknown.total)
    },
    unknownWholeTotalUser () {
      return this.addComma(this.$store.state.totalUser.totalUser.userUnknown.total)
    },
    customerTotalUser () {
      return this.addKIfOverKilo(this.$store.state.totalUser.totalUser.userCustomer.total)
    },
    customerWholeTotalUser () {
      return this.addComma(this.$store.state.totalUser.totalUser.userCustomer.total)
    },
    unknownExecuteDate () {
      return this.$store.state.totalUser.totalUser.userUnknown.updatedAt
    },
    customerExecuteDate () {
      return this.$store.state.totalUser.totalUser.userCustomer.updatedAt
    },
    unknownWeeklyRatio () {
      return this.calWeeklyCompare(
        this.$store.state.totalUser.totalUser.userUnknown.total,
        this.$store.state.totalUser.totalUser.userUnknown.lastWeeklyTotal)
    },
    customerWeeklyRatio () {
      return this.calWeeklyCompare(
        this.$store.state.totalUser.totalUser.userCustomer.total,
        this.$store.state.totalUser.totalUser.userCustomer.lastWeeklyTotal)
    },
  },
  created () {
    if (this.showTotalUser) {
      this.$store.dispatch('totalUser/getCustomerTotalUser')
      this.$store.dispatch('totalUser/getUnknownTotalUser')
      this.pollingTotalUser()
    }
  },
  beforeDestroy () {
    clearInterval(this.totalUserObj)
  },
  methods: {
    /**
     * 30分ごとに総ユーザー数を取得
     */
    pollingTotalUser () {
      this.totalUserObj = setInterval(() => {
        this.$store.dispatch('totalUser/getCustomerTotalUser')
        this.$store.dispatch('totalUser/getUnknownTotalUser')
      }, 1800000)
    },
    /**
     * 表示させる矢印のアイコンを決定する
     */
    ratioArrowIcon (ratio) {
      if (ratio > 0) {
        /** 先週比がプラスの場合 */
        return 'ic-arrow-up-thick-SS'
      } else if (ratio < 0) {
        /** 先週比がマイナスの場合 */
        return 'ic-arrow-down-thick-SS'
      } else {
        /** 先週比が0の場合はアイコンなし */
        return ''
      }
    },
    /**
     * 先週比が0以上の場合は`+`を表示させる（defaultでは符号がついていないため）
     */
    ratioSign (ratio) {
      if (ratio >= 0) {
        /** 先週比が0以上の場合 */
        return '+'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-icon) using ($integral-core-theme) {
  &.ratio-arrow {
    &.up {
      background-color: map-deep-get($integral-core-theme, 'common', 'information-bar', 'background-up');
    }
    &.down {
      background-color: map-deep-get($integral-core-theme, 'common', 'information-bar', 'background-down');
    }
  }
}

.v-toolbar {
  position: sticky;
  top: 0;
  z-index: 3;
  .v-card .v-card__text {
    .v-icon {
      font-size: 20px;
      margin-right: 8px;
      vertical-align: text-bottom;
      &.ratio-arrow {
        font-size: 16px;
        margin-right: 4px;
      }
    }
    .total-user {
      font-size: 16px;
    }
    .unit-human {
      font-size: 12px;
      margin-right: 24px;
    }
    .ratio-text {
      font-size: 12px;
    }
  }
}
</style>
