export default {
  data () {
    return {
      BROWSER_TYPES: {
        CHROME: { text: 'Chrome', value: 1 },
        FIREFOX: { text: 'Firefox', value: 2 },
        SAFARI: { text: 'Safari', value: 3 },
        IE: { text: 'IE(Edge含まない)', value: 4 },
        EDGE: { text: 'Edge', value: 5 },
        OTHER: { text: 'その他', value: 6 },
      },
    }
  },
}
