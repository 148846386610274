<template>
  <div>
    <!-- ホスト名 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="host"
          :label="$t(`form.jobSetting.jobTask.host`)"
          name="host"
          :data-vv-as="$t(`form.jobSetting.jobTask.host`)"
          v-validate="'required'"
          :error-messages="errors.collect('host')" />
      </v-col>
    </v-row>
    <!-- ポート番号 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="port"
          :label="$t(`form.jobSetting.jobTask.port`)"
          type="number"
          name="port"
          :data-vv-as="$t(`form.jobSetting.jobTask.port`)"
          v-validate="'required'"
          :error-messages="errors.collect('port')" />
      </v-col>
    </v-row>
    <!-- ユーザー名 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="user"
          :label="$t(`form.jobSetting.jobTask.user`)"
          name="user"
          :data-vv-as="$t(`form.jobSetting.jobTask.user`)"
          v-validate="'required'"
          :error-messages="errors.collect('user')" />
      </v-col>
    </v-row>
    <!-- パスワード -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="password"
          autocomplete="new-password"
          type="password"
          :label="$t(`form.password`)"
          name="password"
          :data-vv-as="$t(`form.password`)"
          v-validate="'required'"
          :error-messages="errors.collect('password')" />
      </v-col>
    </v-row>
    <!-- データベース名 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="database"
          :label="$t(`form.jobSetting.jobTask.database`)"
          name="database"
          :data-vv-as="$t(`form.jobSetting.jobTask.database`)"
          v-validate="'required'"
          :error-messages="errors.collect('database')" />
      </v-col>
    </v-row>
    <!-- スキーマ名 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-show="etlType === OUTPUT_ETL_TYPES.REDSHIFT.value"
          v-model="schema"
          :label="$t(`form.jobSetting.jobTask.schema`)"
          name="schema">
          <v-tooltip slot="append" bottom :max-width="tooltipWidth">
            <template #activator="{ on }">
              <v-icon v-on="on">ic-help-S</v-icon>
            </template>
            <span v-html="$t(`tooltip.jobSetting.jobTaskSchema`)" />
          </v-tooltip>
        </v-text-field>
      </v-col>
    </v-row>
    <!-- テーブル名 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="table"
          :label="$t(`form.jobSetting.jobTask.table`)"
          name="table"
          :data-vv-as="$t(`form.jobSetting.jobTask.table`)"
          v-validate="'required'"
          :error-messages="errors.collect('table')">
            <template #append>
              <v-tooltip bottom :max-width="tooltipWidth">
                <template #activator="{ on }">
                  <v-icon v-on="on">ic-help-S</v-icon>
                </template>
                <span v-html="$t(`tooltip.autoCreateTable`)" />
              </v-tooltip>
            </template>
        </v-text-field>
      </v-col>
    </v-row>
    <!-- 出力モード -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-select
          v-model="mode"
          :items="enumUtil.convertForSelectList(DB_OUTPUT_MODE)"
          :label="$t(`form.jobSetting.jobTask.outputMode`)"
          name="mode"
          :data-vv-as="$t(`form.jobSetting.jobTask.outputMode`)"
          v-validate="'required'"
          :error-messages="errors.collect('mode')" />
      </v-col>
    </v-row>
    <!-- マージキー -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-if="etlType === OUTPUT_ETL_TYPES.REDSHIFT.value && mode === DB_OUTPUT_MODE.MERGE.value"
          v-model="mergeKeys"
          autocomplete="new-password"
          :label="$t(`form.jobSetting.jobTask.mergeKey`)"
          name="mergeKeys"
          :data-vv-as="$t(`form.jobSetting.jobTask.mergeKey`)"
          v-validate="'required'"
          :error-messages="errors.collect('mergeKeys')" />
      </v-col>
    </v-row>
    <!-- バケット名 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-if="etlType === OUTPUT_ETL_TYPES.REDSHIFT.value"
          v-model="s3Bucket"
          :label="$t(`form.jobSetting.jobTask.bucket`)"
          name="bucket"
          :data-vv-as="$t(`form.jobSetting.jobTask.bucket`)"
          v-validate="'required'"
          :error-messages="errors.collect('bucket')" />
      </v-col>
    </v-row>
    <!-- アクセスキー -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-if="etlType === OUTPUT_ETL_TYPES.REDSHIFT.value"
          v-model="awsAccessKeyId"
          autocomplete="new-password"
          :label="$t(`form.accessKey`)"
          name="accessKey"
          :data-vv-as="$t(`form.accessKey`)"
          v-validate="'required'"
          :error-messages="errors.collect('accessKey')" />
      </v-col>
    </v-row>
    <!-- シークレットキー -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-if="etlType === OUTPUT_ETL_TYPES.REDSHIFT.value"
          v-model="awsSecretAccessKey"
          autocomplete="new-password"
          type="password"
          :label="$t(`form.secretKey`)"
          name="secretKey"
          :data-vv-as="$t(`form.secretKey`)"
          v-validate="'required'"
          :error-messages="errors.collect('secretKey')" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DB_OUTPUT_MODE from '@/enum/jobTask/DB_OUTPUT_MODE'
import JSON_OUTPUT_ITEM_NAMES from '@/enum/jobTask/JSON_OUTPUT_ITEM_NAMES'
import OUTPUT_ETL_TYPES from '@/enum/jobTask/OUTPUT_ETL_TYPES'
import enumUtil from '@/utils/enumUtil'

const TOOLTIP_WIDTH = 400

export default {
  mixins: [DB_OUTPUT_MODE, JSON_OUTPUT_ITEM_NAMES, OUTPUT_ETL_TYPES, enumUtil],
  props: ['etlType', 'outputForm'],
  data () {
    return {
      tooltipWidth: TOOLTIP_WIDTH,
    }
  },
  computed: {
    host: {
      get () {
        if (this.checkSettingProp()) {
          return this.getInputJson().host
        }
        return null
      },
      set (value) {
        this.updateValue('host', value)
      },
    },
    port: {
      get () {
        if (this.checkSettingProp()) {
          return this.getInputJson().port
        }
        return null
      },
      set (value) {
        this.updateValue('port', value)
      },
    },
    user: {
      get () {
        if (this.checkSettingProp()) {
          return this.getInputJson().user
        }
        return null
      },
      set (value) {
        this.updateValue('user', value)
      },
    },
    password: {
      get () {
        if (this.checkSettingProp()) {
          return this.getInputJson().password
        }
        return null
      },
      set (value) {
        this.updateValue('password', value)
      },
    },
    schema: {
      get () {
        if (this.checkSettingProp()) {
          return this.getInputJson().schema
        }
        return null
      },
      set (value) {
        this.updateValue('schema', value)
      },
    },
    database: {
      get () {
        if (this.checkSettingProp()) {
          return this.getInputJson().database
        }
        return null
      },
      set (value) {
        this.updateValue('database', value)
      },
    },
    table: {
      get () {
        if (this.checkSettingProp()) {
          return this.getInputJson().table
        }
        return null
      },
      set (value) {
        this.updateValue('table', value)
      },
    },
    mode: {
      get () {
        if (this.checkSettingProp()) {
          return this.getInputJson().mode
        }
        return null
      },
      set (value) {
        this.updateValue('mode', value)
      },
    },
    mergeKeys: {
      get () {
        if (this.checkSettingProp()) {
          return this.getInputJson().mergeKeys
        }
        return null
      },
      set (value) {
        this.updateValue('mergeKeys', value)
      },
    },
    s3Bucket: {
      get () {
        if (this.checkSettingProp()) {
          return this.getInputJson().s3Bucket
        }
        return null
      },
      set (value) {
        this.updateValue('s3Bucket', value)
      },
    },
    awsAccessKeyId: {
      get () {
        if (this.checkSettingProp()) {
          return this.getInputJson().awsAccessKeyId
        }
        return null
      },
      set (value) {
        this.updateValue('awsAccessKeyId', value)
      },
    },
    awsSecretAccessKey: {
      get () {
        if (this.checkSettingProp()) {
          return this.getInputJson().awsSecretAccessKey
        }
        return null
      },
      set (value) {
        this.updateValue('awsSecretAccessKey', value)
      },
    },
  },
  methods: {
    /**
     * タスクのアウトプット種別がAuroraまたはRedshiftの判定
     * @return {Boolean}
     */
    checkSettingProp () {
      if (this.etlType === this.OUTPUT_ETL_TYPES.AURORA.value) {
        return this.outputForm && this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.AURORA.value]
      } else if (this.etlType === this.OUTPUT_ETL_TYPES.REDSHIFT.value) {
        return this.outputForm && this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.REDSHIFT.value]
      }
      return false
    },
    /**
     * JSONからタスクの値を取得
     * @return {Object}
     */
    getInputJson () {
      if (this.etlType === this.OUTPUT_ETL_TYPES.AURORA.value) {
        return this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.AURORA.value]
      } else if (this.etlType === this.OUTPUT_ETL_TYPES.REDSHIFT.value) {
        return this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.REDSHIFT.value]
      }
      return null
    },
    /**
     * タスクの値更新処理呼び出し
     * @param  {String} propertyName  更新対象の項目名
     * @param  {String} propertyValue 更新対象の値
     */
    updateValue (propertyName, propertyValue) {
      if (!this.outputForm || !this.etlType) return

      if (this.etlType === this.OUTPUT_ETL_TYPES.AURORA.value) {
        this.updateValueByJsonItemName(propertyName, propertyValue, this.JSON_OUTPUT_ITEM_NAMES.AURORA.value)
      } else if (this.etlType === this.OUTPUT_ETL_TYPES.REDSHIFT.value) {
        this.updateValueByJsonItemName(propertyName, propertyValue, this.JSON_OUTPUT_ITEM_NAMES.REDSHIFT.value)
      }
    },
    /**
     * JSONに変換してタスクの値を更新
     * @param  {String} propertyName  更新対象の項目名
     * @param  {String} propertyValue 更新対象の値
     * @param  {String} jsonItemName  更新対象のインプット種別
     */
    updateValueByJsonItemName (propertyName, propertyValue, jsonItemName) {
      const json = Object.assign({}, this.outputForm)
      if (!json[jsonItemName]) {
        this.$set(json, jsonItemName, {})
      }
      Object.assign(json[jsonItemName], { [propertyName]: propertyValue })
      this.$emit('update', json)
    },
  },
}
</script>
