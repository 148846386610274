<template>
  <v-card tile elevation="0">
    <information-bar />
    <v-progress-linear v-if="loadingScvSettings" indeterminate color="primary" />
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <h3 class="lead-line">{{ $t('text.scvSetting.scvSettingList.title')}}</h3>
          <scv-setting-list
            ref="scvSettingList"
            :scvSettings="scvSettings"
            :selectedScvSetting="scvSetting"
            @selectScvSetting="setScvSetting"
            @updateOrder="updateOrder"
            @delete="deleteScvSetting"
            @reload="reloadScvSetting"
          />
        </v-col>
        <v-col cols="8">
          <h3 class="lead-line">{{ $t(`text.scvSetting.scvSettingDetail.title`) }}</h3>
          <scv-setting-detail
            v-show="scvSetting"
            :scvSetting="scvSetting"
            :selectedDataSetId="selectedDataSetId"
            :selectedCandidateIndex="selectedCandidateIndex"
            :dataSetRowInfoList="dataSetRowInfoList"
            @switchScvIdentify="scvSetting.isScvIdentify = !scvSetting.isScvIdentify"
            @addPriority="addPriority"
            @selectDataSetId="updateDataSetId"
            @addScvColumnChoiceSetting="updateScvColumns"
            @removeSetting="val => scvSetting.scvColumnChoiceSettingList.splice(val, 1)"
            @updateScvSetting="val => scvSetting = val"
            @updateColumnName="val => scvSetting.scvColumnName = val"
            @updateColumnDataType="val => scvSetting.dataType = val"
            @updateCandidateIndex="updateCandidateIndex"
            @cancel="restoreScvSetting"
            @save="saveScvSetting"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from '@/axios'
// components
import scvSettingDetail from '@/components/ScvSetting/scvSettingDetail'
import scvSettingList from '@/components/ScvSetting/scvSettingList'
// util
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'scvSettings',
  components: {
    scvSettingDetail,
    scvSettingList,
  },
  mixins: [
    notifyUtil,
  ],
  data () {
    return {
      dataSetRowInfoList: [],
      originScvSettings: [],
      scvSettings: [],
      scvSetting: null,
      selectedDataSetId: null,
      selectedCandidateIndex: 0,
    }
  },
  computed: {
    loadingScvSettings () {
      return this.$store.state.scvSettings.loadingScvSettings
    },
  },
  watch: {
    scvSetting: {
      handler: function (newScvSetting, oldScvSetting) {
        if (!newScvSetting?.scvColumnId) return
        if (oldScvSetting?.scvColumnId !== newScvSetting.scvColumnId) return

        const originScvSetting = this.originScvSettings.find(originScvSetting => originScvSetting.scvColumnId === newScvSetting.scvColumnId)
        for (const key in newScvSetting) {
          if (key !== 'isOrigin' && JSON.stringify(newScvSetting[key]) !== JSON.stringify(originScvSetting[key])) {
            this.scvSetting.isOrigin = false
            return
          }
        }
        this.scvSetting.isOrigin = true
      },
      deep: true,
    },
  },
  created () {
    this.getScvSetting()
    axios.get('/dataSources/headerMeta/')
      .then((headerMetaRes) => {
        this.dataSetRowInfoList = []
        headerMetaRes.data.dataSetList.forEach((dataSet, index) => {
          this.dataSetRowInfoList.push({
            dataSetId: dataSet.dataSetId,
            dataSetName: dataSet.dataSetName,
            headerMeta: [],
          })
          dataSet.dataSourceList.forEach(dataSource => {
            dataSource.headerMeta.forEach(column => {
              this.dataSetRowInfoList[index].headerMeta.push({
                dataType: column.dataType,
                disabled: false,
                index: column.index,
                logicalName: dataSource.webConnector ? column.logicalName : dataSource.dataSourceName + '_' + column.logicalName,
                physicalName: dataSource.webConnector ? column.physicalName : dataSource.dataSourceId + '_' + column.physicalName,
              })
            })
          })
        })
      }).catch((err) => {
        this.handleErrorResponse(err)
      })
  },
  methods: {
    getScvSetting () {
      this.$store.dispatch('scvSettings/getScvSettings')
        .then((res) => {
          this.originScvSettings = JSON.parse(JSON.stringify(res.scvSettings))
          this.scvSettings = JSON.parse(JSON.stringify(res.scvSettings))
          this.scvSettings.forEach(scvSetting => {
            scvSetting.isOrigin = true
          })
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    reloadScvSetting () {
      this.$store.dispatch('scvSettings/getScvSettings')
        .then(res => {
          this.originScvSettings = JSON.parse(JSON.stringify(res.scvSettings))
          const tmpScvSettings = JSON.parse(JSON.stringify(res.scvSettings))
          this.scvSettings.forEach((scvSetting) => {
            // 編集中の項目を差し替え
            if (scvSetting.isOrigin === false) {
              const tmpScvSetting = tmpScvSettings.find(tmpScvSetting => tmpScvSetting.scvColumnId === scvSetting.scvColumnId)
              if (tmpScvSetting) {
                tmpScvSetting.isOrigin = false
                for (const key in tmpScvSetting) {
                  // 編集中の項目も並び替えできるようにscvColumnIndexは差し替えない
                  if (key !== 'scvColumnIndex') tmpScvSetting[key] = scvSetting[key]
                }
              }
            }
          })
          this.scvSettings = tmpScvSettings
          if (this.scvSetting) {
            // 画面に表示しているSCV項目をリフレッシュ
            this.scvSetting = this.scvSettings.find(scvSetting => scvSetting.scvColumnId === this.scvSetting.scvColumnId)
          }
        })
        .catch(() => {
          this.originScvSettings = []
          this.scvSettings = []
        })
    },
    setScvSetting (scvSetting) {
      this.scvSetting = scvSetting
    },
    /**
     * 編集破棄
     */
    restoreScvSetting () {
      // 新規作成は初期化
      if (this.scvSetting.scvColumnId === undefined) {
        this.scvSetting.scvColumnName = this.$t('text.scvSetting.scvSettingList.newScvColumnName')
        this.scvSetting.scvColumnChoiceSettingList = []
        return
      }

      // オリジナルのSCV項目を取得
      const originScvSetting = JSON.parse(JSON.stringify(this.originScvSettings.find(scvSetting => scvSetting.scvColumnId === this.scvSetting.scvColumnId)))

      // 一覧を上書きする
      this.scvSettings.forEach(scvSetting => {
        if (scvSetting.scvColumnId === originScvSetting.scvColumnId) {
          Object.assign(scvSetting, JSON.parse(JSON.stringify(originScvSetting)))
        }
      })
      // 選択中SCV項目を一覧からセット
      this.scvSetting = this.scvSettings.find(scvSetting => scvSetting.scvColumnId === this.scvSetting.scvColumnId)
    },
    saveScvSetting (formParameter) {
      if (formParameter.scvColumnId) {
        this.$store.dispatch('scvSettings/updateScvSetting', formParameter)
          .then(() => {
            this.notifySuccessMessage(this.$t('notify.scvSetting') + this.$t('notify.success.update'))
            this.scvSetting.isOrigin = true
            this.reloadScvSetting()
          }).catch(err => {
            this.handleErrorResponse(err)
          })
      } else {
        this.$store.dispatch('scvSettings/insertScvSetting', formParameter)
          .then(() => {
            this.notifySuccessMessage(this.$t('notify.scvSetting') + this.$t('notify.success.create'))
            this.scvSetting.isOrigin = true
            this.$refs.scvSettingList.newCustomScvSetting = null
            this.reloadScvSetting()
          }).catch(err => {
            this.handleErrorResponse(err)
          })
      }
    },
    deleteScvSetting (scvSettingId) {
      this.$store.dispatch('scvSettings/deleteScvSetting', scvSettingId)
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.scvSetting') + this.$t('notify.success.delete'))
          this.scvSetting = null
        }).catch(err => {
          this.handleErrorResponse(err)
        }).finally(() => {
          // SCV項目をリロード
          this.reloadScvSetting()
        })
    },
    /**
     * 選択したマスタカラムに応じてSCVカラムを更新
     * @param  {Object} newScvColumnChoiceSetting 更新対象のデータセット (クリックしたカラムだけを headerMeta にもつ)
     */
    updateScvColumns (newScvColumnChoiceSetting, candidateIndex) {
      if (!this.scvSetting) return
      // データセットのカラムが削除済みの場合は、更新のタイミングで削除する
      this.scvSetting.scvColumnChoiceSettingList.forEach((headerColumn) => {
        headerColumn.headerMeta.forEach(candidates => {
          candidates.forEach(column => {
            if (column.disabled === true) {
              headerColumn.headerMeta.splice(column.idx, 1)
            }
          })
        })
      })
      // SCV項目採択設定の有無を確認
      if (this.scvSetting.scvColumnChoiceSettingList) {
        const scvIndex = this.scvSetting.scvColumnChoiceSettingList.findIndex(scvSetting => scvSetting.dataSetId === newScvColumnChoiceSetting.dataSetId)
        // 登録済みのマスタの有無を確認
        if (this.scvSetting.scvColumnChoiceSettingList[scvIndex]) {
          const tmpHeaderMetaCandidates = this.scvSetting.scvColumnChoiceSettingList[scvIndex].headerMeta
          const tmpHeaderMeta = tmpHeaderMetaCandidates[this.selectedCandidateIndex]
          const headerIndex = tmpHeaderMeta.findIndex(headerMeta => headerMeta.physicalName === newScvColumnChoiceSetting.headerMeta.physicalName)
          // 選択したカラムが登録済みのカラムと物理名が合致するかを確認
          if (tmpHeaderMeta && tmpHeaderMeta[headerIndex]) {
            // 選択済みのカラムを解除
            tmpHeaderMeta.splice(headerIndex, 1)
            if (tmpHeaderMeta.length === 0) {
              // 登録済みのマスタのカラムが0件なので候補を削除
              tmpHeaderMetaCandidates.splice(this.selectedCandidateIndex, 1)
              this.selectedCandidateIndex = this.selectedCandidateIndex - 1
              if (tmpHeaderMetaCandidates.length === 0) {
                // 選択されたデータセットにおけるカラム候補が0件なのでマスタを解除
                this.scvSetting.scvColumnChoiceSettingList.splice(scvIndex, 1)
              }
              return
            }
          } else {
            // 登録済みのマスタにカラム追加
            tmpHeaderMeta.push(JSON.parse(JSON.stringify(newScvColumnChoiceSetting.headerMeta)))
          }
          this.scvSetting.scvColumnChoiceSettingList[scvIndex].headerMeta[this.selectedCandidateIndex] = this.castHeaderMeta(tmpHeaderMeta)
          return
        }
      }
      // SCVカラムを更新
      const tmpScvColumnChoiceSettingList = JSON.parse(JSON.stringify(newScvColumnChoiceSetting))
      tmpScvColumnChoiceSettingList.headerMeta = [this.castHeaderMeta([tmpScvColumnChoiceSettingList.headerMeta])]
      this.scvSetting.scvColumnChoiceSettingList.push(tmpScvColumnChoiceSettingList)
    },
    /**
     * 追加SCV項目並べ替え
     * @param {Array} customScvColumnIds 並べ替え済み追加SCV項目のID一覧
     */
    updateOrder (customScvColumnIds) {
      axios.put('/scv/setting/sort/', { scvColumnIdList: customScvColumnIds })
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.success.orderCustomScvSetting'))
          this.reloadScvSetting()
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    },
    /**
     * マスタヘッダーカラムをSCV設定用Objectに変換する
     * ex:"[{ idx: 1, physicalName: item1, logicalName: アイテム1 },{ idx: 2, physicalName: item2, logicalName: アイテム2 }]"
     * @param  {Array} columnList 変換するヘッダーカラムリスト
     * @return {String} SCV設定用JSON文字列
     */
    castHeaderMeta (columnList) {
      // 不要なプロパティを削除
      columnList.forEach((column) => {
        delete column.index
      })
      // インデックスを再設定
      columnList.forEach((column, index) => { column.idx = index })
      return columnList
    },
    addPriority () {
      this.scvSetting.scvColumnChoiceSettingList.forEach((scvColumnChoiceSetting, index) => {
        // リクエスト用にインデックス追加
        scvColumnChoiceSetting.priority = index
      })
    },
    updateDataSetId (id) {
      this.selectedDataSetId = id
    },
    updateCandidateIndex (index) {
      this.selectedCandidateIndex = index
    },
  },
}
</script>
