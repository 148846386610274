<template>
  <v-container>
    <v-img :src="require('@/assets/logo-white.svg')" width="330" class="mx-auto" contain />
    <form @submit.prevent="login()">
      <v-text-field
        v-model="requestParameter.companyHash"
        :label="$t(`form.companyAccountId`)"
        class="mt-10"
        dark
        color="white"
        persistent-placeholder
        name="companyHash"
        :data-vv-as="$t(`form.companyAccountId`)"
        v-validate="'required'"
        :error-messages="errors.collect('companyHash')" />
      <v-text-field
        v-model="requestParameter.emailAddress"
        :label="$t(`form.mailAddress`)"
        class="mt-8"
        dark
        color="white"
        persistent-placeholder
        name="email"
        :data-vv-as="$t(`form.mailAddress`)"
        v-validate.continues="'required|emailSyntax'"
        :error-count="2"
        :error-messages="errors.collect('email')" />
      <v-text-field
        :append-icon="passwordForm ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="passwordForm = !passwordForm"
        :type="passwordForm ? 'text' : 'password'"
        v-model="requestParameter.password"
        :label="$t(`form.password`)"
        class="mt-8"
        dark
        color="white"
        persistent-placeholder
        name="password"
        :data-vv-as="$t(`form.password`)"
        v-validate="'required'"
        :error-messages="errors.collect('password')" />
      <v-btn type="submit" color="primary" class="mt-8" block :loading="loggingIn">
        {{ $t(`btn.login.login`) }}
      </v-btn>
    </form>
  </v-container>
</template>

<script>

// util
import notifyUtil from '@/utils/notifyUtil'
// ログインフォームの幅固定
const cardWidth = 350

export default {
  name: 'LoginForm',
  mixins: [notifyUtil],
  data () {
    return {
      cardWidth,
      requestParameter: {
        companyHash: '',
        emailAddress: '',
        password: '',
      },
      inputType: 'password',
      passwordForm: false,
    }
  },
  computed: {
    loggingIn () {
      return this.$store.state.auth.loggingIn
    },
  },
  methods: {
    login () {
      this.$validator.validateAll()
        .then((result) => {
          // バリデーションエラーがあればリクエストは送信しない
          if (!result) return
          this.$store.dispatch('auth/login', this.requestParameter)
            .catch((err) => {
              // ログイン失敗時にパスワードをクリア
              this.handleErrorResponse(err)
              this.requestParameter.password = ''
            })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 350px;
  min-width: 350px;
  padding: 0;
}

.v-input::v-deep {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px #0A5A82 inset;
    -webkit-text-fill-color: #DCDCDC;
  }
}
</style>
