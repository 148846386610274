<template>
  <div>
    <v-card outlined tile class="mb-5">
      <v-card-text>
        <v-data-table
          :headers="tableHeaders"
          :items="$router.options.routes"
          hide-default-footer
          disable-pagination
          disable-sort
        >
          <template #item.requiredAuthority="{ item }">
            {{ (item.meta || {}).requiredAuthority }}
          </template>
          <template #item.get="{ item }">
            {{ canGetThis(item.name) }}
          </template>
          <template #item.post="{ item }">
            {{ canPostThis(item.name) }}
          </template>
          <template #item.put="{ item }">
            {{ canPutThis(item.name) }}
          </template>
          <template #item.delete="{ item }">
            {{ canDeleteThis(item.name) }}
          </template>
          <template #item.all="{ item }">
            {{ isAllAuthorizedThis(item.name) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card outlined tile>
      <v-card-text>
        <v-textarea
          label="$store.state.auth.loginUserDetail.authority"
          :value="formatJSON($store.state.auth.loginUserDetail.authority)"
          rows="8"
          readonly
        />
        isAllAuthorizedThis datasetAdd: {{isAllAuthorizedThis('DataSetAdd')}}
        <br><br>
        isAllAuthorizedThis segmentSettingEdit: {{isAllAuthorizedThis('SegmentSettingEdit')}}
        <br><br>
        canGet datasetAdd: {{canGetThis('DataSetAdd')}}
        <br><br>
        canGet segmentSettingEdit: {{canGetThis('SegmentSettingEdit')}}
        <br><br>
        canPost datasetAdd: {{canPostThis('DataSetAdd')}}
        <br><br>
        canPost segmentSettingEdit: {{canPostThis('SegmentSettingEdit')}}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Auth',
  data () {
    return {
      tableHeaders: [
        {
          text: 'name',
          value: 'name',
        },
        {
          text: 'path',
          value: 'path',
        },
        {
          text: 'meta.requiredAuthority',
          value: 'requiredAuthority',
        },
        {
          text: 'isAllAuthorizedThis',
          value: 'all',
        },
        {
          text: 'canGet',
          value: 'get',
        },
        {
          text: 'canPost',
          value: 'post',
        },
        {
          text: 'canPut',
          value: 'put',
        },
        {
          text: 'canDelete',
          value: 'delete',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['isAllAuthorizedThis', 'canGetThis', 'canPostThis', 'canPutThis', 'canDeleteThis']),
  },
  methods: {
    formatJSON (json) {
      return JSON.stringify(json, null, 2)
    },
  },
}
</script>
