<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn v-if="getRouteToReturn().name === 'Jobs'" :to="'/jobs/'">{{ $t(`btn.backToList`) }}</v-btn>
        <v-btn v-else-if="getRouteToReturn().name === 'JobHistory'" :to="getRouteToReturn()">{{ $t(`btn.backToHistory`) }}</v-btn>
        <v-btn v-else :to="getRouteToReturn()">{{ $t(`btn.back`) }}</v-btn>
      </template>
      <template #right>
        <v-btn v-if="buttonType==='PUT'" color="other" class="mx-3" @click="showJobDelete = true" :disabled="!canDelete || updating" :loading="loadingJobs">{{ $t(`btn.delete`) }}</v-btn>
        <v-switch v-model="formInputJob.enabled" class="header-switch" :label="switchStatus(formInputJob.enabled)" hide-details>
          <template #label>
            <span class="header-switch_label">
              {{ switchStatus(formInputJob.enabled) }}
            </span>
          </template>
        </v-switch>
      </template>
    </information-bar>
    <v-card-text>
      <job-forms
        ref="jobForms"
        :buttonType="buttonType"
        :formInputJob="formInputJob"
        :formDatetimeParameter="formDatetimeParameter"
        :dayOfMonthListCheckedVal="dayOfMonthListCheckedVal"
        :dayOfWeekListCheckedVal="dayOfWeekListCheckedVal"
        @updateDate="val => formDatetimeParameter.date = val"
        @updateHour="val => formDatetimeParameter.time = val + formDatetimeParameter.time.substring(2)"
        @updateMinutes="val => formDatetimeParameter.time = formDatetimeParameter.time.substring(0, 3) + val"
        @updateDayOfWeek="val => dayOfWeekListCheckedVal = val"
        @updateDayOfMonth="val => dayOfMonthListCheckedVal = val" />
      <h3 class="mt-7 lead-line">タスク設定</h3>
      <v-row>
        <v-col cols="4">
          <task-list
            ref="taskList"
            :activateJobTaskOrder="formJobTask.jobTaskOrder"
            :jobTaskList="formInputJob.jobTasks"
            @reset="resetValidateJobTasks()"
            @selectTask="fetchJobTask"
            @updateTaskList="val => formInputJob.jobTasks = val" />
        </v-col>
        <v-col cols="8">
          <task-form
            ref="taskForm"
            :formJobTask="formJobTask"
            @changeJobTaskName="val => formJobTask.jobTaskName = val"
            @changeJobTaskType="changeJobTaskType"
            @changeInputEtlType="val => formJobTask.jobTaskReference.input.etlType = val"
            @changeInputValue="val => formJobTask.jobTaskReference.input = val"
            @changeOutputEtlType="val => formJobTask.jobTaskReference.output.etlType = val"
            @changeOutputValue="val => formJobTask.jobTaskReference.output = val"
            @changeJobTaskCommand="val => formJobTask.jobTaskCommand = val"
            @changeHasError="val => formJobTask.hasError = val"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-footer fixed>
      <v-col cols="11" offset="1" class="d-flex justify-center">
        <v-btn class="mx-3" color="denial" large :to="{ name:'Jobs' }">{{ $t('btn.cancel') }}</v-btn>
        <v-btn class="mx-3" color="secondary" large @click="validateJobAndJobTasksAndQuickExecJob()" :loading="updating" :disabled="disableSaveButton">保存して今すぐ実行</v-btn>
        <v-btn class="mx-3" color="primary" large @click="validateJobAndJobTasks()" :loading="updating" :disabled="disableSaveButton">{{ buttonType | convertButtonType }}</v-btn>
      </v-col>
    </v-footer>
    <!-- 設定未保存モーダル -->
    <v-dialog v-model="showModal" @input="showModal = false" :width="'35%'">
      <v-card>
        <v-card-title>
          <span v-html="$t(`text.jobSetting.remind`)" />
        </v-card-title>
        <v-card-actions>
          <v-row :justify="'end'">
            <v-btn class="mr-3" color="info" @click="showModal = false" :to="'/jobs'">{{ $t(`btn.ok`) }}</v-btn>
            <v-btn class="mr-3" @click="showModal = false">{{ $t(`btn.cancel`) }}</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 削除確認用モーダル -->
    <v-dialog v-model="showJobDelete" @input="showJobDelete = false" width="400" :loading="loadingJobs">
      <v-card>
        <v-card-title/>
        <v-card-text>
          {{ $t(`notify.check.delete`, [formInputJob.jobName]) }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="denial" @click="showJobDelete = false">{{ $t(`btn.no`) }}</v-btn>
          <v-btn color="primary" @click="deleteJob()">{{ $t(`btn.yes`) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 新規登録・更新用モーダル -->
    <v-dialog v-model="showJobRequest" @input="showJobRequest = false" :width="'35%'">
      <v-card>
        <v-card-title>
          <p class="title" v-if="buttonType==='POST'" >{{ formInputJob.jobName }}{{ $t(`notify.check.create`) }}</p>
          <p class="title" v-if="buttonType==='PUT'" >{{ formInputJob.jobName }}{{ $t(`notify.check.update`) }}</p>
        </v-card-title>
        <v-card-text>
          {{ $t(`form.jobSetting.job.schedule`) }}：{{ enumUtil.getEnumFromValue(JOB_RECURRING_TYPES, formInputJob.recurringType).text }}
          <v-list class="modal_text">
            <v-list-item v-for="(task, index) in formInputJob.jobTasks" :key="index">
              <v-list-item-content>
                {{ task.jobTaskName }}
              </v-list-item-content>
              <!-- SQLタスク -->
              <v-list-item-icon v-if="task.jobTaskType === JOB_TASK_TYPES.SQL.value" class="text_center">
                <v-icon large text-color="white">
                  mdi-file-code
                </v-icon>
              </v-list-item-icon>
              <!-- IOタスク -->
              <!-- input種別 -->
              <v-list-item-icon v-if="task.jobTaskType === JOB_TASK_TYPES.IO.value" class="text_center">
                <v-icon v-if="task.jobTaskReference.input.etlType === INPUT_ETL_TYPES.S3.value" large color="warning">
                  mdi-alpha-s-box
                </v-icon>
                <v-icon v-else-if="task.jobTaskReference.input.etlType === INPUT_ETL_TYPES.AURORA.value" large color="info">
                  mdi-alpha-a-box
                </v-icon>
                <v-icon v-else-if="task.jobTaskReference.input.etlType === INPUT_ETL_TYPES.REDSHIFT.value" large color="error">
                  mdi-alpha-r-box
                </v-icon>
                <v-icon v-else-if="task.jobTaskReference.input.etlType === INPUT_ETL_TYPES.GCS.value" large color="success">
                  mdi-alpha-g-box
                </v-icon>
                <v-icon v-else-if="task.jobTaskReference.input.etlType === INPUT_ETL_TYPES.SFTP.value" large color="accent">
                  mdi-alpha-f-box
                </v-icon>
                <v-icon v-else-if="task.jobTaskReference.input.etlType === INPUT_ETL_TYPES.SEGMENT.value" large>
                  mdi-desktop-mac
                </v-icon>
                <!-- output種別 -->
                <v-icon v-if="task.jobTaskReference.output.etlType === INPUT_ETL_TYPES.S3.value" large color="warning">
                  mdi-alpha-s-box
                </v-icon>
                <v-icon v-else-if="task.jobTaskReference.output.etlType === INPUT_ETL_TYPES.AURORA.value" large color="info">
                  mdi-alpha-a-box
                </v-icon>
                <v-icon v-else-if="task.jobTaskReference.output.etlType === INPUT_ETL_TYPES.REDSHIFT.value" large color="error">
                  mdi-alpha-r-box
                </v-icon>
                <v-icon v-else-if="task.jobTaskReference.output.etlType === INPUT_ETL_TYPES.GCS.value" large color="success">
                  mdi-alpha-g-box
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="denial" @click="showJobRequest = false">{{ $t(`btn.no`) }}</v-btn>
          <v-btn color="primary" @click="clickRequestButton()" :disabled="disableSaveButton">{{ $t('btn.yes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// components
import jobForms from '@/components/JobSetting/jobForms'
import jobTaskList from '@/components/JobSetting/jobTasks/jobTaskList'
import jobTaskForm from '@/components/JobSetting/jobTasks/taskForm'
// enum
import CONFIRM_MESSAGES from '@/enum/notify/CONFIRM_MESSAGES'
import DB_OUTPUT_MODE from '@/enum/jobTask/DB_OUTPUT_MODE'
import INPUT_ETL_TYPES from '@/enum/jobTask/INPUT_ETL_TYPES'
import JOB_RECURRING_TYPES from '@/enum/JOB_RECURRING_TYPES'
import JOB_TASK_TYPES from '@/enum/jobTask/JOB_TASK_TYPES'
import JSON_INPUT_ITEM_NAMES from '@/enum/jobTask/JSON_INPUT_ITEM_NAMES'
import JSON_OUTPUT_ITEM_NAMES from '@/enum/jobTask/JSON_OUTPUT_ITEM_NAMES'
import OUTPUT_ETL_TYPES from '@/enum/jobTask/OUTPUT_ETL_TYPES'
import USER_TYPES from '@/enum/USER_TYPES'
// util
import checkValueUtil from '@/utils/checkValueUtil'
import enumUtil from '@/utils/enumUtil'
import momentUtil from '@/utils/momentUtil'
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'JobSetting',
  mixins: [
    CONFIRM_MESSAGES,
    DB_OUTPUT_MODE,
    INPUT_ETL_TYPES,
    JOB_RECURRING_TYPES,
    JOB_TASK_TYPES,
    JSON_INPUT_ITEM_NAMES,
    JSON_OUTPUT_ITEM_NAMES,
    OUTPUT_ETL_TYPES,
    USER_TYPES,
    checkValueUtil,
    enumUtil,
    momentUtil,
    notifyUtil,
  ],
  components: {
    'job-forms': jobForms,
    'task-list': jobTaskList,
    'task-form': jobTaskForm,
  },
  data () {
    return {
      job: {},
      prevRoute: null,
      buttonType: null,
      dayOfWeekListCheckedVal: [],
      dayOfMonthListCheckedVal: [],
      formDatetimeParameter: {
        dayOfWeek: null,
        day: null,
        date: null,
        time: '00:00',
      },
      formInputJob: {
        id: null,
        jobName: null,
        executionDate: null,
        executionTime: '00:00:00',
        recurringType: null,
        enabled: false,
        jobTasks: [],
        note: null,
        weeklyJobSetting: null,
        monthlyJobSetting: null,
        createdBy: null,
        createdAt: null,
        updatedBy: null,
        updatedAt: null,
      },
      formJobTask: {
        jobTaskName: null,
        jobTaskType: 1,
        jobTaskOrder: 1,
        jobTaskReference: {
          input: {
            etlType: 1,
            inputAurora: {},
            inputRedshift: {},
            inputS3: {},
            inputGcs: {},
            inputSFTP: {},
          },
          output: {
            etlType: 1,
            outputAurora: {},
            outputRedshift: {},
            outputS3: {},
            outputGcs: {},
          },
        },
        jobTaskCommand: null,
        hasError: false,
      },
      showModal: false,
      showJobDelete: false,
      showJobRequest: false,
      isQuickExec: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['canPost', 'canPut', 'canDelete']),
    datetimeNowStr () {
      return this.momentUtil.getCurrentDateIncludeHyphen()
    },
    disableSaveButton () {
      if (this.$route.name === 'JobSetting') {
        return !this.canPost
      } else if (this.$route.name === 'JobSettingEdit') {
        return !this.canPut
      }
      return false
    },
    updating () {
      return this.$store.state.jobs.postingJob || this.$store.state.jobs.puttingJob
    },
    loadingJobs () {
      return this.$store.state.jobs.loadingJobs
    },
  },
  watch: {
    job: {
      handler (job) {
        if (job && job.jobTasks) {
          this.formInputJob.id = job.id
          this.formInputJob.jobName = job.jobName
          this.formInputJob.executionTime = job.executionTime
          if (this.job.executionDate) {
            this.formDatetimeParameter.date = this.job.executionDate
          }
          if (this.job.executionTime) {
            this.formDatetimeParameter.time = this.job.executionTime.substr(0, 5)
          }
          this.formInputJob.recurringType = job.recurringType
          this.formInputJob.enabled = job.enabled
          this.formInputJob.jobTasks = job.jobTasks
          this.formInputJob.jobTasks.sort(function (a, b) {
            return a.jobTaskOrder - b.jobTaskOrder
          })
          this.formInputJob.note = job.note
          this.formInputJob.createdUserName = job.createdUserName
          this.formInputJob.createdAt = job.createdAt
          this.formInputJob.updatedUserName = job.updatedUserName
          this.formInputJob.updatedAt = job.updatedAt

          // 週次、月次設定をセット
          if (job.jobSettingWeekly) {
            for (const key in job.jobSettingWeekly) {
              const value = job.jobSettingWeekly[key]
              if (value === 1) {
                this.dayOfWeekListCheckedVal.push({ [key]: value })
              }
            }
          }
          if (job.jobSettingMonthly) {
            for (const key in job.jobSettingMonthly) {
              const value = job.jobSettingMonthly[key]
              if (value === 1) {
                this.dayOfMonthListCheckedVal.push({ [key]: value })
              }
            }
          }

          // 編集時に最初のタスクを選択
          if (job.jobTasks && job.jobTasks.length > 0) {
            this.formJobTask = job.jobTasks[0]
          }

          // ReferenceのJSON文字列をObjectに変換する
          this.formInputJob.jobTasks.forEach(task => {
            if (task.jobTaskReference) {
              task.jobTaskReference = JSON.parse(task.jobTaskReference)
              // 表示用にetlTypeを数値に変換
              task.jobTaskReference.input.etlType = this.enumUtil.getEnumFromText(this.INPUT_ETL_TYPES, task.jobTaskReference.input.etlType).value
              task.jobTaskReference.output.etlType = this.enumUtil.getEnumFromText(this.OUTPUT_ETL_TYPES, task.jobTaskReference.output.etlType).value
              // S3の場合はパスを入力用に変換する
              const inputS3 = task.jobTaskReference.input[this.JSON_INPUT_ITEM_NAMES.S3.value]
              if (inputS3) {
                inputS3.filePath = (inputS3.pathPrefix ? inputS3.pathPrefix : '') + (inputS3.fileName ? inputS3.fileName : '')
              }
              const outputS3 = task.jobTaskReference.output[this.JSON_OUTPUT_ITEM_NAMES.S3.value]
              if (outputS3) {
                outputS3.filePath = (outputS3.pathPrefix ? outputS3.pathPrefix : '') + (outputS3.fileName ? outputS3.fileName : '')
              }
              // DBの場合はアウトプットモードを変換する
              const outputAurora = task.jobTaskReference.output[this.JSON_OUTPUT_ITEM_NAMES.AURORA.value]
              if (outputAurora) {
                outputAurora.mode = this.enumUtil.getEnumFromText(this.DB_OUTPUT_MODE, outputAurora.mode).value
              }
              const outputRedshift = task.jobTaskReference.output[this.JSON_OUTPUT_ITEM_NAMES.REDSHIFT.value]
              if (outputRedshift) {
                outputRedshift.mode = this.enumUtil.getEnumFromText(this.DB_OUTPUT_MODE, outputRedshift.mode).value
                if (outputRedshift.mergeKeys) {
                  // 保存時にJSON配列に変換したので文字列に戻して[]と"を外す。Eslintの都合上、正規表現が特殊になっている。
                  outputRedshift.mergeKeys = JSON.stringify(outputRedshift.mergeKeys).replace(/[[\]"]/g, '')
                }
              }
              const inputGcs = task.jobTaskReference.input[this.JSON_INPUT_ITEM_NAMES.GCS.value]
              if (inputGcs) {
                inputGcs.filePath = (inputGcs.pathPrefix ? inputGcs.pathPrefix : '') + (inputGcs.fileName ? inputGcs.fileName : '')
              }
              const outputGcs = task.jobTaskReference.output[this.JSON_OUTPUT_ITEM_NAMES.GCS.value]
              if (outputGcs) {
                outputGcs.filePath = (outputGcs.pathPrefix ? outputGcs.pathPrefix : '') + (outputGcs.fileName ? outputGcs.fileName : '')
              }
            }
          })
        }
      },
    },
    deep: true,
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  created () {
    this.executionDate = this.momentUtil.getCurrentDateIncludeHyphen()
    const jobId = this.$route.params.jobId
    if (jobId) {
      // 更新
      this.initPutMode(jobId)
    } else {
      // 登録
      this.initPostMode()
    }
  },
  methods: {
    /**
     * タスクの初期値生成
     * @return {Object} タスクの初期値
     */
    createDefaultJobTaskReference () {
      return {
        input: {
          etlType: this.INPUT_ETL_TYPES.S3.value,
          inputS3: {
            region: 'ap-northeast-1',
          },
        },
        output: {
          etlType: this.OUTPUT_ETL_TYPES.S3.value,
          outputS3: {
            region: 'ap-northeast-1',
          },
        },
      }
    },
    /**
     * 戻るボタンの戻り先を取得 (セグメント詳細とジョブ履歴からも遷移してくるため)
     * @returns {Route} 戻るボタンの遷移先.前画面の Route.name を見て振り分ける
     *     `prevRoute.name === 'SegmentDetail'`: セグメント詳細へ遷移 (パラメータを保持)
     *     `prevRoute.name === 'Jobs'`: ジョブ一覧へ遷移 (パラメータを保持)
     *     `prevRoute.name === 'JobHistory'`: ジョブ履歴へ遷移 (パラメータを保持)
     *     その他: ジョブ一覧へ遷移 (パラメータなし)
     */
    getRouteToReturn () {
      if (this.prevRoute === null || this.prevRoute.name === null) {
        return { name: 'Jobs' }
      } else if (this.prevRoute.name === 'SegmentDetail') {
        return this.prevRoute
      } else if (this.prevRoute.name === 'Jobs') {
        return this.prevRoute
      } else if (this.prevRoute.name === 'JobHistory') {
        return this.prevRoute
      }
      return { name: 'Jobs' }
    },
    switchStatus (jobStatus) {
      if (jobStatus) return '有効'
      return '無効'
    },
    /**
     * ジョブ作成用にリクエストパラメーターを初期化
     */
    initPostMode () {
      this.buttonType = 'POST'
      this.formInputJob.id = null
      this.formInputJob.jobName = null
      this.formInputJob.executionDate = this.momentUtil.getCurrentDateIncludeHyphen()
      this.formInputJob.executionTime = '00:00:00'
      this.formDatetimeParameter.date = null
      this.formDatetimeParameter.time = '00:00'
      this.formInputJob.recurringType = null
      this.formInputJob.enabled = false
      this.formInputJob.jobTasks = []
      this.formInputJob.note = null
      this.formInputJob.weeklyJobSetting = null
      this.formInputJob.monthlyJobSetting = null
    },
    /**
     * ジョブ更新用にリクエストパラメーターを初期化
     * @param  {Number} jobId 更新対象のジョブID
     */
    initPutMode (jobId) {
      this.buttonType = 'PUT'
      this.$store.dispatch('jobs/getJob', jobId)
        .then((res) => {
          this.job = res.job
        })
        .catch((err) => {
          this.job = null
          this.handleErrorResponse(err)
          this.$router.push({ name: 'Jobs' }, () => {})
        })
    },
    /**
     * 選択されたタスクをフォームに格納
     * @param  {Number} index 選択されたタスクのインデックス
     */
    fetchJobTask (index) {
      // TODO 初期表示時に taskForm がまだ作られてなくて Cannot read property 'resetValidateJobTaskForm' of undefined"
      // this.resetValidateJobTasks()
      const task = this.formInputJob.jobTasks[index]
      // 必須項目確認
      if (task) {
        this.defineIfItemUndefined(task, 'jobTaskName', null)
        this.defineIfItemUndefined(task, 'jobTaskType', this.JOB_TASK_TYPES.IO.value)
        this.defineIfItemUndefined(task, 'jobTaskOrder', null)
        this.defineIfItemUndefined(task, 'jobTaskReference', {})
        this.defineIfItemUndefined(task.jobTaskReference, 'input', {})
        this.defineIfItemUndefined(task.jobTaskReference.input, 'etlType', this.INPUT_ETL_TYPES.S3.value)
        this.defineIfItemUndefined(task.jobTaskReference.input, 'inputS3', { region: 'ap-northeast-1' })
        this.defineIfItemUndefined(task.jobTaskReference, 'output', {})
        this.defineIfItemUndefined(task.jobTaskReference.output, 'etlType', this.OUTPUT_ETL_TYPES.S3.value)
        this.defineIfItemUndefined(task.jobTaskReference.output, 'outputS3', { region: 'ap-northeast-1' })
        this.defineIfItemUndefined(task, 'jobTaskCommand', null)
        this.formJobTask = task
      }
    },
    changeJobTaskType (type) {
      this.formJobTask.jobTaskType = type
      // タスク種別切替時にIOフォームがない場合は作成する
      if (!this.formJobTask.jobTaskReference) {
        this.formJobTask.jobTaskReference = this.createDefaultJobTaskReference()
      }
    },
    /**
    * 表示エラー回避
    */
    defineIfItemUndefined (obj, itemName, initValue) {
      if (obj[itemName] === undefined || obj[itemName] === null) {
        this.$set(obj, itemName, initValue)
      }
    },
    /**
     * バリデーションステータスのリセット
     */
    resetValidateJobTasks () {
      this.$refs.taskForm.resetValidateJobTaskForm()
    },
    /**
     * タスクの入力値チェックの呼び出し
     */
    validateJobTasks () {
      this.$refs.taskForm.validateJobTaskForm()
    },
    /**
     * ジョブとジョブタスクの入力チェック
     * @return {Promise}
     */
    async validateJobAndJobTasks () {
      this.resetValidateJobTasks()
      const hasErrorJob = !(await this.$refs.jobForms.validateJobForms())
      const hasErrorTask = !this.validateTaskFormValue()

      // バリデーションエラーがあれば中断
      if (hasErrorJob || hasErrorTask) return

      this.isQuickExec = false
      this.showJobRequest = true
    },
    async validateJobAndJobTasksAndQuickExecJob () {
      this.resetValidateJobTasks()
      const hasErrorJob = !(await this.$refs.jobForms.validateJobForms())
      const hasErrorTask = !this.validateTaskFormValue()

      // バリデーションエラーがあれば中断
      if (hasErrorJob || hasErrorTask) return

      this.formInputJob.enabled = true
      this.isQuickExec = true
      this.showJobRequest = true
    },
    /**
     * ジョブ作成/更新前処理
     * @return {[type]}
     */
    clickRequestButton () {
      // 項目整形
      const requestParam = this.convertFormValueToRequestParam()

      if (this.buttonType === 'POST') {
        this.sendPostRequest(requestParam)
      } else if (this.buttonType === 'PUT') {
        this.sendPutRequest(requestParam)
      }
    },
    /**
     * タスクの入力値チェック
     * @return {Boolean}
     */
    validateTaskFormValue () {
      let hasTasksError = false
      if (this.formInputJob.jobTasks.length === 0) {
        this.notifyErrorMessage(this.$t('notify.error.jobs.noTask'))
        return false
      }

      for (const index in this.formInputJob.jobTasks) {
        const task = this.formInputJob.jobTasks[index]
        task.hasError = false
        if (!checkValueUtil.isNumber(task.jobTaskOrder) || !checkValueUtil.isNumber(task.jobTaskType)) {
          this.notifyErrorMessage(String(parseInt(index) + 1) + '番目のタスクに' + this.$t('notify.error.form.invalidValue'))
          task.hasError = true
        }
        if (!task.jobTaskName) {
          this.notifyErrorMessage(String(parseInt(index) + 1) + '番目のタスクの' + this.$t('notify.error.jobs.noName'))
          task.hasError = true
        } else if (task.jobTaskName.length > 200) {
          this.notifyErrorMessage(String(parseInt(index) + 1) + '番目のタスクのタスク名を200文字以内で入力してください')
          task.hasError = true
        }

        if (checkValueUtil.isNumber(task.jobTaskType)) {
          switch (task.jobTaskType) {
            case this.JOB_TASK_TYPES.IO.value:
              if (!this.validateIOPattern(task)) {
                task.hasError = true
              }
              break
            case this.JOB_TASK_TYPES.SQL.value:
              if (!this.validateSQLPattern(task)) {
                task.hasError = true
              }
              break
          }
        }
        if (task.hasError) {
          if (task.jobTaskName && task.jobTaskName.length <= 200) {
            this.notifyErrorMessage(task.jobTaskName + 'に' + this.$t('notify.error.form.invalidValue'))
          } else {
            this.notifyErrorMessage(String(parseInt(index) + 1) + '番目のタスクに' + this.$t('notify.error.form.invalidValue'))
          }
          hasTasksError = true
        }
      }
      // 表示のため
      this.$refs.taskForm.validateJobTaskForm()
      return !hasTasksError
    },
    /**
     * SQLタスクの入力値チェック
     * @param  {Object} task チェック対象のタスク
     * @return {Boolean}
     */
    validateSQLPattern (task) {
      if (!task.jobTaskCommand || !task.jobTaskCommand.trim()) return false
      const queryRegex = /[;]$/
      // セミコロンの数でクエリをカウント
      const queryCount = task.jobTaskCommand.match(queryRegex) ? task.jobTaskCommand.match(queryRegex).length : 0
      if (queryCount !== 1 || !queryRegex.test(task.jobTaskCommand.trim())) return false
      return true
    },
    /**
     * IOタスクの入力値チェック
     * @param  {Object} task チェック対象のタスク
     * @return {Boolean} 入力値チェックの成否
     */
    validateIOPattern (task) {
      // input
      switch (task.jobTaskReference.input.etlType) {
        case this.INPUT_ETL_TYPES.S3.value:
          if (!this.validateInputS3Form(task)) return false
          break
        case this.INPUT_ETL_TYPES.AURORA.value:
          if (!this.validateInputAuroraForm(task)) return false
          break
        case this.INPUT_ETL_TYPES.REDSHIFT.value:
          if (!this.validateInputRedshiftForm(task)) return false
          break
        case this.INPUT_ETL_TYPES.SEGMENT.value:
          if (!this.validateInputSegmentForm(task)) return false
          break
        case this.INPUT_ETL_TYPES.GCS.value:
          if (!this.validateInputGcsForm(task)) return false
          break
        case this.INPUT_ETL_TYPES.SFTP.value:
          if (!this.validateInputSftpForm(task)) return false
          break
        default:
          return false
      }
      // output
      switch (task.jobTaskReference.output.etlType) {
        case this.OUTPUT_ETL_TYPES.S3.value:
          if (!this.validateOutputS3Form(task)) return false
          break
        case this.OUTPUT_ETL_TYPES.AURORA.value:
          if (!this.validateOutputAuroraForm(task)) return false
          break
        case this.OUTPUT_ETL_TYPES.REDSHIFT.value:
          if (!this.validateOutputRedshiftForm(task)) return false
          break
        case this.OUTPUT_ETL_TYPES.GCS.value:
          if (!this.validateOutputGcsForm(task)) return false
          break
        default:
          return false
      }
      return true
    },
    /**
     * S3インプットタスクの入力値チェック
     * @param  {Object} task チェック対象のタスク
     * @return {Boolean}     入力値チェックの成否
     */
    validateInputS3Form (task) {
      const inputJson = task.jobTaskReference.input[this.JSON_INPUT_ITEM_NAMES.S3.value]
      if (!inputJson) return false

      if (!inputJson.bucket || !inputJson.region || !inputJson.accessKey || !inputJson.secretKey) return false

      if (!inputJson.filePath) {
        return false
      } else {
        // パス解析
        const pathObj = this.splitFilePath(inputJson.filePath)
        if (!pathObj.fileName) return false
      }

      return true
    },
    /**
     * Auroraインプットタスクの入力値チェック
     * @param  {Object} task チェック対象のタスク
     * @return {Boolean}     入力値チェックの成否
     */
    validateInputAuroraForm (task) {
      const inputJson = task.jobTaskReference.input[this.JSON_INPUT_ITEM_NAMES.AURORA.value]
      if (!inputJson) return false

      if (!inputJson.host || !inputJson.port || !inputJson.user || !inputJson.database || !inputJson.table || !inputJson.password) return false

      return true
    },
    /**
     * Redshiftインプットタスクの入力値チェック
     * @param  {Object} task チェック対象のタスク
     * @return {Boolean}     入力値チェックの成否
     */
    validateInputRedshiftForm (task) {
      const inputJson = task.jobTaskReference.input[this.JSON_INPUT_ITEM_NAMES.REDSHIFT.value]
      if (!inputJson) return false

      if (!inputJson.host || !inputJson.port || !inputJson.user || !inputJson.password || !inputJson.database || !inputJson.table) return false

      return true
    },
    /**
     * セグメントインプットタスクの入力値チェック
     * IDのみ使うのでIDの確認のみ行う
     * @param  {Object} task チェック対象のタスク
     * @return {Boolean}     入力値チェックの成否
     */
    validateInputSegmentForm (task) {
      const inputJson = task.jobTaskReference.input.inputSegment
      if (!inputJson || !checkValueUtil.isNumber(inputJson.segmentId)) return false
      return true
    },
    /**
     * GCSインプットタスクの入力値チェック
     * @param  {Object} task チェック対象のタスク
     * @return {Boolean}     入力値チェックの成否
     */
    validateInputGcsForm (task) {
      const inputJson = task.jobTaskReference.input[this.JSON_INPUT_ITEM_NAMES.GCS.value]
      if (!inputJson) return false
      if (!inputJson.bucket) return false
      if ((!inputJson.uploadedAuthenticationkeyFileName || !inputJson.uploadedAuthenticationkeyFileValue) &&
        (!inputJson.authenticationkeyFileName || !inputJson.authenticationkeyFileValue)) return false

      if (!inputJson.filePath) {
        return false
      } else {
        // パス解析
        const pathObj = this.splitFilePath(inputJson.filePath)
        if (!pathObj.fileName) return false
      }
      return true
    },
    /**
     * SFTPインプットタスクの入力値チェック
     * @param  {Object} task チェック対象のタスク
     * @return {Boolean}     入力値チェックの成否
     */
    validateInputSftpForm (task) {
      const inputJson = task.jobTaskReference.input[this.JSON_INPUT_ITEM_NAMES.SFTP.value]
      if (!inputJson) return false
      if (!inputJson.host || !inputJson.port || !inputJson.pathPrefix || !inputJson.filename || !inputJson.username) return false
      if (!inputJson.password && !inputJson.secretKey) return false

      return true
    },
    /**
     * S3アウトプットタスクの入力値チェック
     * @param  {Object} task チェック対象のタスク
     * @return {Boolean}     入力値チェックの成否
     */
    validateOutputS3Form (task) {
      const outputJson = task.jobTaskReference.output[this.JSON_OUTPUT_ITEM_NAMES.S3.value]
      if (!outputJson) return false

      if (!outputJson.bucket || !outputJson.region || !outputJson.accessKey || !outputJson.secretKey) return false

      if (!outputJson.filePath) {
        return false
      } else {
        // パスの解析
        const pathObj = this.splitFilePath(outputJson.filePath)
        if (!pathObj.fileName) return false
      }

      return true
    },
    /**
     * Auroraアウトプットタスクの入力値チェック
     * @param  {Object} task チェック対象のタスク
     * @return {Boolean}     入力値チェックの成否
     */
    validateOutputAuroraForm (task) {
      const outputJson = task.jobTaskReference.output[this.JSON_OUTPUT_ITEM_NAMES.AURORA.value]
      if (!outputJson) return false

      if (!outputJson.host || !outputJson.port || !outputJson.user || !outputJson.database || !outputJson.table || !outputJson.mode || !outputJson.password) return false

      return true
    },
    /**
     * Redshiftアウトプットタスクの入力値チェック
     * @param  {Object} task チェック対象のタスク
     * @return {Boolean}     入力値チェックの成否
     */
    validateOutputRedshiftForm (task) {
      const outputJson = task.jobTaskReference.output[this.JSON_OUTPUT_ITEM_NAMES.REDSHIFT.value]
      if (!outputJson) return false

      if (!outputJson.host || !outputJson.port || !outputJson.user || !outputJson.password || !outputJson.database || !outputJson.table ||
          !outputJson.mode || !outputJson.awsAccessKeyId || !outputJson.awsSecretAccessKey || !outputJson.s3Bucket) return false
      if (outputJson.mode === this.DB_OUTPUT_MODE.MERGE.value && !outputJson.mergeKeys) return false

      return true
    },
    /**
     * GCSアウトプットタスクの入力値チェック
     * @param  {Object} task チェック対象のタスク
     * @return {Boolean}     入力値チェックの成否
     */
    validateOutputGcsForm (task) {
      const outputJson = task.jobTaskReference.output[this.JSON_OUTPUT_ITEM_NAMES.GCS.value]
      if (!outputJson) return false
      if (!outputJson.bucket) return false
      if ((!outputJson.uploadedAuthenticationkeyFileName || !outputJson.uploadedAuthenticationkeyFileValue) &&
        (!outputJson.authenticationkeyFileName || !outputJson.authenticationkeyFileValue)) return false

      if (!outputJson.filePath) {
        return false
      } else {
        // パスの解析
        const pathObj = this.splitFilePath(outputJson.filePath)
        if (!pathObj.fileName) return false
      }
      return true
    },
    /**
     * ファイルのパスを分割する
     * @param  {String} path ファイルのパス
     * @return {Object}      パス、ファイル名、拡張子に分割したObject
     */
    splitFilePath (path) {
      if (!path) return { pathPrefix: null, fileName: null, extension: null }
      // パス分割
      const pathArray = path.split('/')
      // 空文字を除去
      for (let i = pathArray.length - 1; i >= 0; i--) {
        if (!pathArray[i]) {
          pathArray.splice(i, 1)
        }
      }
      // ファイル名までのパス
      let pathPrefix = ''
      pathArray.forEach((arr, index) => {
        if (index !== pathArray.length - 1) {
          // 最後以外を結合
          pathPrefix = pathPrefix + arr + '/'
        }
      })

      // ファイル名を分割
      const fileArray = pathArray[pathArray.length - 1].split('.')
      // ※空文字は除去しない
      let fileName = ''
      let extension = ''
      if (fileArray.length > 1) {
        // 拡張子あり
        fileArray.forEach((arr, index) => {
          if (index !== fileArray.length - 1) {
            // 最後以外を結合して、結合最後だけ.を付けない
            fileName = fileName + arr + (index !== fileArray.length - 2 ? '.' : '')
          }
        })
        // 拡張子
        extension = fileArray[fileArray.length - 1]
      } else {
        fileName = fileArray[0]
      }
      return { pathPrefix: pathPrefix, fileName: fileName, extension: extension }
    },
    /**
     * フォームの入力値をリクエストパラメーターに変換
     * @return {Object} リクエストパラメーター
     */
    convertFormValueToRequestParam () {
      // clone
      const requestParam = JSON.parse(JSON.stringify(this.formInputJob))

      // 実行日時のフォーマット変換
      switch (requestParam.recurringType) {
        case this.JOB_RECURRING_TYPES.ONETIME.value:
          requestParam.executionDate = this.formDatetimeParameter.date
          requestParam.executionTime = this.formDatetimeParameter.time + ':00'
          break
        case this.JOB_RECURRING_TYPES.DAILY.value:
        case this.JOB_RECURRING_TYPES.WEEKLY.value:
        case this.JOB_RECURRING_TYPES.MONTHLY.value:
          requestParam.executionDate = '1970-01-01'
          requestParam.executionTime = this.formDatetimeParameter.time + ':00'
          break
        case this.JOB_RECURRING_TYPES.UNSCHEDULED.value:
        case this.JOB_RECURRING_TYPES.HOURLY.value:
          requestParam.executionDate = '1970-01-01'
          requestParam.executionTime = '00:00:00'
          break
        default:
          throw new Error('unknown recurringType')
      }
      // 週次、月次設定がある場合はrequestparamにセット
      switch (requestParam.recurringType) {
        case this.JOB_RECURRING_TYPES.WEEKLY.value: {
          const weeklySettingParam = {}
          for (const obj in this.dayOfWeekListCheckedVal) {
            for (const key in this.dayOfWeekListCheckedVal[obj]) {
              const value = this.dayOfWeekListCheckedVal[obj][key]
              weeklySettingParam[key] = value
            }
          }
          requestParam.weeklyJobSetting = weeklySettingParam
          break
        }
        case this.JOB_RECURRING_TYPES.MONTHLY.value: {
          const monthlySettingParam = {}
          for (const obj in this.dayOfMonthListCheckedVal) {
            for (const key in this.dayOfMonthListCheckedVal[obj]) {
              const value = this.dayOfMonthListCheckedVal[obj][key]
              monthlySettingParam[key] = value
            }
          }
          requestParam.monthlyJobSetting = monthlySettingParam
          break
        }
      }

      requestParam.jobTasks.forEach(task => {
        if (task.jobTaskReference) {
          // S3の場合はファイルパスを分割する
          const inputS3 = task.jobTaskReference.input[this.JSON_INPUT_ITEM_NAMES.S3.value]
          if (inputS3) {
            const pathObj = this.splitFilePath(inputS3.filePath)
            inputS3.pathPrefix = pathObj.pathPrefix
            inputS3.fileName = pathObj.fileName + (pathObj.extension ? '.' + pathObj.extension : '')
            inputS3.formatter = 'csv'
            // スキップ行数の保管
            if (!Object.prototype.hasOwnProperty.call(inputS3, 'skipHeaderLines')) inputS3.skipHeaderLines = 1
          }
          const outputS3 = task.jobTaskReference.output[this.JSON_OUTPUT_ITEM_NAMES.S3.value]
          if (outputS3) {
            const pathObj = this.splitFilePath(outputS3.filePath)
            outputS3.pathPrefix = pathObj.pathPrefix
            outputS3.fileName = pathObj.fileName + (pathObj.extension ? '.' + pathObj.extension : '')
            outputS3.extension = pathObj.extension
          }

          const inputAurora = task.jobTaskReference.input[this.JSON_INPUT_ITEM_NAMES.AURORA.value]
          if (inputAurora) {
            // Auroraでパスワードが未定義の場合、空文字を代入する
            if (!inputAurora.password) {
              inputAurora.password = ''
            }
          }
          const outputAurora = task.jobTaskReference.output[this.JSON_OUTPUT_ITEM_NAMES.AURORA.value]
          if (outputAurora) {
            // Auroraでパスワードが未定義の場合、空文字を代入する
            if (!outputAurora.password) {
              outputAurora.password = ''
            }
            // modeを文字列に変換
            outputAurora.mode = this.enumUtil.getEnumFromValue(this.DB_OUTPUT_MODE, outputAurora.mode).text
          }
          const inputRedshift = task.jobTaskReference.input[this.JSON_INPUT_ITEM_NAMES.REDSHIFT.value]
          if (inputRedshift) {
            // スキーマ名未入力の場合はkeyを削除
            if (Object.prototype.hasOwnProperty.call(inputRedshift, 'schema') && !inputRedshift.schema) delete inputRedshift.schema
          }
          const outputRedshift = task.jobTaskReference.output[this.JSON_OUTPUT_ITEM_NAMES.REDSHIFT.value]
          if (outputRedshift) {
            // スキーマ名未入力の場合はkeyを削除
            if (Object.prototype.hasOwnProperty.call(outputRedshift, 'schema') && !outputRedshift.schema) delete outputRedshift.schema
            // modeがmergeのときは変換、それ以外は削除
            if (outputRedshift.mode === this.DB_OUTPUT_MODE.MERGE.value) {
              // カンマで分割して文字列配列に変換し、それぞれの文字列をtrimする
              const keyList = outputRedshift.mergeKeys.split(',')
              for (let index = 0; index < keyList.length; index++) {
                keyList[index] = keyList[index].trim()
              }
              outputRedshift.mergeKeys = keyList
            } else {
              delete outputRedshift.mergeKeys
            }
            // modeを文字列に変換
            outputRedshift.mode = this.enumUtil.getEnumFromValue(this.DB_OUTPUT_MODE, outputRedshift.mode).text
          }

          // GCSの場合はファイルパスを分割する
          const inputGcs = task.jobTaskReference.input[this.JSON_INPUT_ITEM_NAMES.GCS.value]
          if (inputGcs) {
            const pathObj = this.splitFilePath(inputGcs.filePath)
            inputGcs.pathPrefix = pathObj.pathPrefix
            inputGcs.fileName = pathObj.fileName + (pathObj.extension ? '.' + pathObj.extension : '')
            inputGcs.formatter = 'csv'
            if (!Object.prototype.hasOwnProperty.call(inputGcs, 'skipHeaderLines')) inputGcs.skipHeaderLines = 1
            // 認証キーを更新
            if (inputGcs.uploadedAuthenticationkeyFileName && inputGcs.uploadedAuthenticationkeyFileValue) {
              inputGcs.authenticationkeyFileName = inputGcs.uploadedAuthenticationkeyFileName
              inputGcs.authenticationkeyFileValue = inputGcs.uploadedAuthenticationkeyFileValue
            }
          }
          const outputGcs = task.jobTaskReference.output[this.JSON_OUTPUT_ITEM_NAMES.GCS.value]
          if (outputGcs) {
            const pathObj = this.splitFilePath(outputGcs.filePath)
            outputGcs.pathPrefix = pathObj.pathPrefix
            outputGcs.fileName = pathObj.fileName + (pathObj.extension ? '.' + pathObj.extension : '')
            outputGcs.extension = pathObj.extension
            // 認証キーを更新
            if (outputGcs.uploadedAuthenticationkeyFileName && outputGcs.uploadedAuthenticationkeyFileValue) {
              outputGcs.authenticationkeyFileName = outputGcs.uploadedAuthenticationkeyFileName
              outputGcs.authenticationkeyFileValue = outputGcs.uploadedAuthenticationkeyFileValue
            }
          }

          // SFTPの場合
          const inputSftp = task.jobTaskReference.input[this.JSON_INPUT_ITEM_NAMES.SFTP.value]
          if (inputSftp) {
            // パスワードが未定義か空文字の場合、nullを代入する
            if (inputSftp.password === undefined || inputSftp.password === '') {
              inputSftp.password = null
            }
            // 秘密鍵が未定義の場合、nullを代入する
            if (inputSftp.secretKey === undefined || inputSftp.secretKey === '') {
              inputSftp.secretKey = null
            }
            // 秘密鍵パスフレーズが未定義の場合、nullを代入する
            if (inputSftp.passphrase === undefined || inputSftp.passphrase === '') {
              inputSftp.passphrase = null
            }
          }

          const inputSegment = task.jobTaskReference.input.inputSegment
          if (inputSegment) {
            delete inputSegment.text
          }
        }
      })

      // 必要項目変換
      requestParam.jobTasks.forEach(task => {
        switch (task.jobTaskType) {
          case this.JOB_TASK_TYPES.IO.value:
            // 不要項目削除
            this.deleteUnnecessaryRequestParamItems(task)
            // リクエスト用にenum数値から文字列へ変換
            task.jobTaskReference.input.etlType = this.enumUtil.getEnumFromValue(this.INPUT_ETL_TYPES, task.jobTaskReference.input.etlType).text
            task.jobTaskReference.output.etlType = this.enumUtil.getEnumFromValue(this.OUTPUT_ETL_TYPES, task.jobTaskReference.output.etlType).text
            task.jobTaskReference = JSON.stringify(task.jobTaskReference)
            task.jobTaskCommand = null
            break
          case this.JOB_TASK_TYPES.SQL.value:
            task.jobTaskReference = null
            break
          default:
            throw new Error('unknown jobTaskTypes')
        }
      })
      return requestParam
    },
    /**
     * リクエストパラメーター変換時などで定義された不要な項目の削除
     * @param  {Object} task 対象のタスク
     */
    deleteUnnecessaryRequestParamItems (task) {
      // inputの精査
      const input = task.jobTaskReference.input
      let requiredInputKey = null
      switch (input.etlType) {
        case this.INPUT_ETL_TYPES.S3.value:
          requiredInputKey = this.JSON_INPUT_ITEM_NAMES.S3.value
          // S3の場合はfilePathを削除
          delete input[requiredInputKey].filePath
          break
        case this.INPUT_ETL_TYPES.AURORA.value:
          requiredInputKey = this.JSON_INPUT_ITEM_NAMES.AURORA.value
          break
        case this.INPUT_ETL_TYPES.REDSHIFT.value:
          requiredInputKey = this.JSON_INPUT_ITEM_NAMES.REDSHIFT.value
          break
        case this.INPUT_ETL_TYPES.SEGMENT.value:
          requiredInputKey = this.JSON_INPUT_ITEM_NAMES.SEGMENT.value
          break
        case this.INPUT_ETL_TYPES.GCS.value:
          requiredInputKey = this.JSON_INPUT_ITEM_NAMES.GCS.value
          delete input[requiredInputKey].filePath
          delete input[requiredInputKey].uploadedAuthenticationkeyFileName
          delete input[requiredInputKey].uploadedAuthenticationkeyFileValue
          break
        case this.INPUT_ETL_TYPES.SFTP.value:
          requiredInputKey = this.JSON_INPUT_ITEM_NAMES.SFTP.value
          break
        default:
          throw new Error('unknown inputEtlTypes')
      }
      for (const key of Object.keys(this.JSON_INPUT_ITEM_NAMES)) {
        const value = this.JSON_INPUT_ITEM_NAMES[key].value
        if (requiredInputKey !== value) {
          delete input[value]
        }
      }
      // outputの精査
      const output = task.jobTaskReference.output
      let requiredOutputKey = null
      switch (output.etlType) {
        case this.OUTPUT_ETL_TYPES.S3.value:
          requiredOutputKey = this.JSON_OUTPUT_ITEM_NAMES.S3.value
          // S3の場合はfilePathを削除
          delete output[requiredOutputKey].filePath
          break
        case this.OUTPUT_ETL_TYPES.AURORA.value:
          requiredOutputKey = this.JSON_OUTPUT_ITEM_NAMES.AURORA.value
          break
        case this.OUTPUT_ETL_TYPES.REDSHIFT.value:
          requiredOutputKey = this.JSON_OUTPUT_ITEM_NAMES.REDSHIFT.value
          break
        case this.OUTPUT_ETL_TYPES.GCS.value:
          requiredOutputKey = this.JSON_OUTPUT_ITEM_NAMES.GCS.value
          delete output[requiredOutputKey].filePath
          delete output[requiredOutputKey].uploadedAuthenticationkeyFileName
          delete output[requiredOutputKey].uploadedAuthenticationkeyFileValue
          break
        default:
          throw new Error('unknown outputEtlTypes')
      }
      for (const key of Object.keys(this.JSON_OUTPUT_ITEM_NAMES)) {
        const value = this.JSON_OUTPUT_ITEM_NAMES[key].value
        if (requiredOutputKey !== value) {
          delete output[value]
        }
      }
    },
    /**
     * ジョブ1件作成
     * @param  {Object} requestParam リクエストパラメーター
     */
    sendPostRequest (requestParam) {
      this.$store.dispatch('jobs/postJob', requestParam)
        .then(res => {
          this.notifySuccessMessage(this.$t('notify.job') + this.$t('notify.success.create'))
          if (this.isQuickExec) {
            this.$store.dispatch('jobs/execJob', res.data.data.jobId)
              .then(() => {
                this.$router.push({ name: 'Jobs' }, () => {})
              }).catch((err) => {
                this.handleErrorResponse(err)
              }).finally(() => {
                this.isQuickExec = false
              })
          } else {
            this.$router.push({ name: 'Jobs' }, () => {})
          }
        }).catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    /**
     * ジョブ1件更新
     * @param  {Object} requestParam リクエストパラメーター
     */
    sendPutRequest (requestParam) {
      this.$store.dispatch('jobs/putJob', requestParam)
        .then(res => {
          this.notifySuccessMessage(this.$t('notify.job') + this.$t('notify.success.update'))
          if (this.isQuickExec) {
            this.$store.dispatch('jobs/execJob', requestParam.id)
              .then(() => {
                this.$router.push({ name: 'Jobs' }, () => {})
              }).catch((err) => {
                this.handleErrorResponse(err)
              }).finally(() => {
                this.isQuickExec = false
              })
          } else {
            this.$router.push({ name: 'Jobs' }, () => {})
          }
        }).catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    /**
     * ジョブ1件削除
     */
    deleteJob () {
      this.$store.dispatch('jobs/deleteJob', this.formInputJob.id)
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.job') + this.$t('notify.success.delete'))
          this.$router.push({ name: 'Jobs' }, () => {})
        }).catch((err) => {
          this.handleErrorResponse(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.job_actions {
  position: sticky;
  display: inline-block;
  top: 50px;
  z-index: 2;
}
.container {
  padding-bottom: 80px;
}
.modal_text {
  overflow-y: scroll;
  max-height: 60vh;
}
.header-switch_label {
  color: #DCDCDC;
}
</style>
