import axios from '@/axios'
import checkValueUtil from '@/utils/checkValueUtil'

const transactionSummary = {
  namespaced: true,
  state: {
    sortParameter: {
      orderBy: 'updatedAt',
      isDesc: true,
    },
    enabledFilter: true,
    filterParameter: {
      page: 1,
      limit: 50,
      keyword: null,
      periodType: null,
      transactionSummaryType: null,
    },
    loadingTransactionSummaries: false,
  },
  mutations: {
    setOrderBy (state, newVal) {
      state.sortParameter.orderBy = newVal
    },
    setIsDesc (state, newVal) {
      state.sortParameter.isDesc = newVal
    },
    setFilter (state, newVal) {
      state.filterParameter = newVal
    },
    setEnabledFilter (state, newVal) {
      state.enabledFilter = newVal
    },
    setLoadingTransactionSummaries (state, newVal) {
      state.loadingTransactionSummaries = newVal
    },
  },
  getters: {
    getQueryParams (state) {
      const params = Object.assign({}, state.sortParameter, state.filterParameter, { enabledFilter: state.enabledFilter })
      for (const key in params) {
        if (checkValueUtil.isNullOrEmpty(params[key])) delete params[key]
      }
      return params
    },
  },
  actions: {
    async getTransactionSummaries ({ commit, state }) {
      commit('setLoadingTransactionSummaries', true)
      let queryOrderBy = ''
      if (state.sortParameter.orderBy) {
        queryOrderBy = state.sortParameter.orderBy // .replace(/([A-Z])/g, (str) => { return '_' + str.charAt(0).toLowerCase() })
      }

      const queryParameter = {
        isDesc: state.sortParameter.isDesc,
        orderBy: queryOrderBy,
        offset: (state.filterParameter.page - 1) * state.filterParameter.limit,
        limit: state.filterParameter.limit,
        keyword: null,
        periodType: null,
        transactionSummaryType: null,
      }
      if (state.enabledFilter) {
        queryParameter.keyword = state.filterParameter.keyword
        queryParameter.periodType = state.filterParameter.periodType
        queryParameter.transactionSummaryType = state.filterParameter.transactionSummaryType
      }
      return axios.get('/transaction/setting/', { params: queryParameter })
        .then(res => {
          return Promise.resolve({
            transactionSummaryList: res.data.data.transactionSummarySettingList,
            paging: res.data.paging,
          })
        }).catch(err => {
          return Promise.reject(err)
        }).finally(() => {
          commit('setLoadingTransactionSummaries', false)
        })
    },
    async getAllTransactionSummaries ({ commit }) {
      commit('setLoadingTransactionSummaries', true)
      const queryParameter = {
        isDesc: true,
        orderBy: 'transactionSummarySettingId',
        offset: 0,
        limit: 5000,
        keyword: null,
        periodType: null,
        transactionSummaryType: null,
      }
      return axios.get('/transaction/setting/', { params: queryParameter })
        .finally(() => {
          commit('setLoadingTransactionSummaries', false)
        })
    },
    async getTransactionSummary ({ commit }, transactionSummarySettingId) {
      return axios.get('/transaction/setting/' + transactionSummarySettingId + '/')
    },
    async createTransactionSummary ({ commit }, requestParameter) {
      return axios.post('/transaction/setting/', requestParameter)
    },
    async updateTransactionSummary ({ commit }, requestParameter) {
      return axios.put('/transaction/setting/' + requestParameter.transactionSummarySettingId + '/', requestParameter)
    },
    async deleteTransactionSummary ({ commit }, transactionSummarySettingId) {
      return axios.delete('/transaction/setting/' + transactionSummarySettingId + '/')
    },
    updateOrderBy ({ commit }, newVal) {
      commit('setOrderBy', newVal)
    },
    updateIsDesc ({ commit }, newVal) {
      commit('setIsDesc', newVal)
    },
    updateFilter ({ commit }, newVal) {
      commit('setFilter', newVal)
    },
    updateEnabledFilter ({ commit }, newVal) {
      commit('setEnabledFilter', newVal)
    },
  },
}

export default transactionSummary
