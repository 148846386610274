export default {
  data () {
    return {
      SEGMENT_BUILD_TYPE: {
        STANDARD: { text: '標準', value: 1 },
        CUSTOM: { text: 'カスタム', value: 2 },
      },
    }
  },
}
