export default {
  data () {
    return {
      DATA_TYPES: {
        STRING: { text: 'String', label: '文字列', value: 1 },
        LONG: { text: 'Long', label: '数値', value: 2 },
        DATE: { text: 'Date', label: '日付', value: 3 },
        DATETIME: { text: 'DateTime', label: '日時', value: 4 },
      },
    }
  },
}
