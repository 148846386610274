<template>
  <v-card tile elevation="0">
    <information-bar showTotalUser>
      <template #left>
        <v-btn color="primary" to="/segment/setting">
          {{ getLabel() }}
        </v-btn>
      </template>
      <template #right>
        <v-btn class="mr-5" @click="onClickSelectsButton()">{{$t('btn.select')}}</v-btn>
        <v-btn class="ml-5" @click="getSegments()"><v-icon>ic-reload</v-icon></v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <filter-form
        @search="setFilterParameter"
        @reset="resetFilterParameter"
        @switchFilter="val => isFiltering = val"
        :isFiltering="isFiltering"
        :total="totalSegment"
        :filteredTotal="totalFilterdSegment"
        :loading="loadingSegments"
        class="filterForm"
      >
        <v-row>
          <!-- TODO i18n -->
          <v-col class="py-0" cols="6">
            <v-text-field
              :label="'キーワード'"
              :value="filterParameter.keyword"
              @input="preparedFilters.keyword = $event"
              clearable
              name="keyword"
              :data-vv-as="'キーワード'"
              v-validate="'keywordSyntax'"
              :error-messages="errors.collect('keyword')"
              :disabled="!isFiltering" />
          </v-col>
          <!-- TODO i18n -->
          <v-col class="py-0" cols="3">
            <v-select
              :value="filterParameter.createdBy"
              @change="preparedFilters.createdBy = $event"
              :items="accountList"
              label="作成者"
              clearable
              :disabled="!isFiltering" />
          </v-col>
          <v-col class="py-0" cols="3">
            <v-select
              :value="filterParameter.userType"
              @change="preparedFilters.userType = $event"
              :items="enumUtil.convertForSelectList(USER_TYPES)"
              :label="$t(`text.segments.filtering.userType`)"
              clearable
              :disabled="!isFiltering" />
          </v-col>
        </v-row>
      </filter-form>
      <!-- paging/sort/limit -->
      <v-row>
        <v-col>
          <v-select
            v-model="orderBy"
            class="align-center sort pb-3"
            hide-details
            :items="sortableColumns"
            :disabled="loadingSegments"
          >
            <template #prepend>
              <span class="prepend-sort-text">
                {{ $t('text.sort') }}
              </span>
            </template>
            <template #append-outer>
              <v-btn icon @click="isDesc = !isDesc" :disabled="loadingSegments">
                <v-icon v-if="isDesc">ic-sort-desc</v-icon>
                <v-icon v-else>ic-sort-asc</v-icon>
              </v-btn>
            </template>
          </v-select>
        </v-col>
        <v-spacer />
        <v-col class="d-flex align-content-center flex-wrap justify-end">
          {{$t('text.segments.lastUpdated')}}: {{ latestUpdatedDateTime }}
        </v-col>
      </v-row>
      <segments-list
        :segmentsList="modSegments"
        :showSelects="showSelects"
        :selectedSegments="selectedSegments"
        :loading="loadingSegments"
        @checked="selectSegment"
      />
    </v-card-text>

    <!-- 一括削除ボタン -->
    <v-footer fixed v-if="showSelects">
      <v-col cols="11" offset="1" class="d-flex justify-center">
        <v-btn large color="denial" @click="showSelects = false">
          {{$t('btn.cancel')}}
        </v-btn>
        <v-btn
          large
          class="ml-8"
          color="primary"
          @click="bulkDeleteDialog = true"
          :disabled="selectedSegments.length === 0 || !canDelete"
        >
          {{ $t('btn.bulkDelete') }}
        </v-btn>
      </v-col>
    </v-footer>

    <v-dialog v-model="bulkDeleteDialog" width="400">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          {{ $t('text.bulkDeleteConfirm') }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="denial" @click="bulkDeleteDialog = false">{{ $t('btn.no') }}</v-btn>
          <v-btn color="primary" :loading="deletingSegment" @click="onClickBulkDelete">{{ $t('btn.yes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from '@/axios'
import { mapGetters } from 'vuex'
// component
import filterForm from '@/components/common/filterForm'
import segmentsList from '@/components/Segments/segmentsList'
// enum
import DATE_INTERVAL_TYPES from '@/enum/DATE_INTERVAL_TYPES'
import OPERATOR_TYPE from '@/enum/OPERATOR_TYPE'
import SEGMENT_BUILD_TYPE from '@/enum/SEGMENT_BUILD_TYPE'
import USER_TYPES from '@/enum/USER_TYPES'
// util
import displayConverter from '@/utils/displayConverter'
import enumUtil from '@/utils/enumUtil'
import momentUtil from '@/utils/momentUtil'
import notifyUtil from '@/utils/notifyUtil'
import segmentTexts from '@/utils/segmentTexts'
import segmentFilterUtil from '@/utils/segmentFilterUtil'

const DEFAULT_INTERVAL = 7

export default {
  mixins: [
    DATE_INTERVAL_TYPES,
    OPERATOR_TYPE,
    SEGMENT_BUILD_TYPE,
    USER_TYPES,
    displayConverter,
    enumUtil,
    momentUtil,
    notifyUtil,
    segmentTexts,
    segmentFilterUtil,
  ],
  components: {
    filterForm,
    segmentsList,
  },
  data () {
    return {
      totalSegment: 0,
      totalFilterdSegment: 0,
      sortableColumns: [
        // TODO i18n
        { text: this.$t('text.segments.sort.key1'), value: 'createdAt' },
        { text: this.$t('text.segments.sort.key2'), value: 'updatedAt' },
        { text: this.$t('text.segments.sort.key3'), value: 'segmentName' },
        { text: 'ユーザー数', value: 'total' },
      ],
      latestUpdatedDateTime: null,
      accountList: [],
      segments: [],
      segmentUserCntList: [],
      showSelects: false,
      bulkDeleteDialog: false,
      selectedSegments: [],
      preparedFilters: [],
      isFiltering: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['canPost', 'canDelete']),
    ...mapGetters({ queryParams: 'segments/getQueryParams' }),
    modSegments () {
      const tmpSegments = JSON.parse(JSON.stringify(this.segments))
      tmpSegments.forEach((tmpSegment) => {
        if (tmpSegment.segment.buildType === this.SEGMENT_BUILD_TYPE.STANDARD.value) {
          tmpSegment.filterTextObject = this.createSegmentFilterText(JSON.parse(tmpSegment.segment.filterRuleJson))
        }
        // 同じセグメントIDで今日・1週間前の順にデータが入っているため逆順にして最初に一致したものを取得
        const userCnt = this.segmentUserCntList.slice().reverse().find(segmentUserCnt => tmpSegment.segment.segmentId === segmentUserCnt.segmentId)
        if (userCnt) {
          tmpSegment.lastWeekTotal = userCnt.userCount
        }
      })
      return tmpSegments
    },
    orderBy: {
      get () {
        return this.$store.state.segments.sortParameter.orderBy
      },
      set (newVal) {
        this.changeQuery('orderBy', newVal)
      },
    },
    isDesc: {
      get () {
        return this.$store.state.segments.sortParameter.isDesc
      },
      set (newVal) {
        this.changeQuery('isDesc', newVal)
      },
    },
    enabledFilter () {
      return this.$store.state.segments.enabledFilter
    },
    filterParameter () {
      return this.$store.state.segments.filterParameter
    },
    loadingSegments: {
      get () {
        return this.$store.state.segments.loadingSegment || this.$store.state.segmentSummary.loadingSegmentsUserCnt
      },
      set (newVal) {
        this.$store.dispatch('segments/updateLoadingSegment', newVal)
      },
    },
    deletingSegment () {
      return this.$store.state.segments.deletingSegment
    },
  },
  created () {
    this.loadingSegments = true
    // 作成者フィルター用アカウントリスト取得
    axios.get('/account/user/name/').then(res => {
      res.data.data.userNameList.forEach(item => {
        this.accountList.push({ text: item.loginUserName, value: item.loginUserId })
      })
    })

    const tmpQuery = {}
    for (const key in this.$route.query) {
      tmpQuery[key] = this.$route.query[key]
    }

    // クエリが不十分な場合補う
    let isQuerySufficient = true
    for (const key in this.queryParams) {
      if (tmpQuery[key] === null || tmpQuery[key] === undefined) {
        tmpQuery[key] = this.queryParams[key]
        isQuerySufficient = false
      }
    }

    // orderByに指定された文字列がソート基準の選択肢にあるか、もしくはsegment_idなら問題ない
    const validateOrderBy = this.sortableColumns.some((column) => tmpQuery.orderBy === column.value)
    if (!validateOrderBy && tmpQuery.orderBy !== 'segment_id') {
      tmpQuery.orderBy = 'createdAt'
      isQuerySufficient = false
    }
    if (tmpQuery.isDesc !== true && tmpQuery.isDesc !== false && tmpQuery.isDesc !== 'true' && tmpQuery.isDesc !== 'false') {
      tmpQuery.isDesc = true
      isQuerySufficient = false
    }

    // クエリを変更した場合はURLを置き換える
    if (!isQuerySufficient) {
      this.$router.replace({ query: tmpQuery })
        .catch(err => err) // 画面表示時の NavigationDuplicated 対策
      // 置き換わるとbeforeRouteUpdateが呼び出されるのでこちらの処理は中断する
      return
    }

    this.reflectQueryParamsToState(tmpQuery)
    this.preparedFilters = this.filterParameter
    this.isFiltering = this.enabledFilter
    this.getSegments()
  },
  beforeRouteUpdate (to, from, next) {
    // ルート変更に反応する
    if (to.query !== from.query) {
      this.reflectQueryParamsToState(to.query)
      this.getSegments()
    }
    next()
  },
  methods: {
    getLabel () {
      if (this.canPost) {
        return this.$t('btn.segment.create')
      } else {
        return this.$t('btn.segment.search')
      }
    },
    changeQuery (type, value) {
      const queryParams = this.queryParams

      if (type === 'filter') {
        queryParams.keyword = value.keyword
        queryParams.createdBy = value.createdBy
        queryParams.userType = value.userType
        queryParams.enabledFilter = this.isFiltering
      } else {
        queryParams[type] = value
      }

      // クエリパラメーター更新
      // クエリパラメーターを更新するとbeforeRouteUpdateが呼び出される
      this.$router.push({ query: queryParams })
        .catch(err => err) // 画面表示時の NavigationDuplicated 対策
    },
    /**
     * セグメント一覧リスト取得
     */
    getSegments () {
      this.$store.dispatch('segments/getSegments', this.momentUtil.getCurrentDateForSummary())
        .then((res) => {
          this.segments = res.segments
          this.latestUpdatedDateTime = res.latestUpdatedDateTime || '-'
          this.totalSegment = res.paging.totalCount || 0
          this.totalFilterdSegment = res.paging.filteredTotalCount || 0
          // セグメントユーザー数取得
          this.$store.dispatch('segmentSummary/getSegmentsUserCnt', {
            baseDate: this.momentUtil.getCurrentDateIncludeHyphen(),
            interval: DEFAULT_INTERVAL,
          }).then(res => {
            this.segmentUserCntList = res.data.data.segmentUserCntList
          }).catch(err => { throw err })
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    },
    reflectQueryParamsToState (query) {
      this.$store.dispatch('segments/updateOrderBy', query.orderBy)
      this.$store.dispatch('segments/updateIsDesc', JSON.parse(query.isDesc))
      this.$store.dispatch('segments/updateEnabledFilter', JSON.parse(query.enabledFilter))
      const tmpFilterParameter = []
      if (Object.keys(query).length > 0) {
        // filterParameter として許可されているプロパティのみ有効
        for (const key in this.filterParameter) {
          if (query[key] === undefined && query[key] === null) {
            tmpFilterParameter[key] = null
          } else {
            tmpFilterParameter[key] = query[key]
          }
          // this.$route.query には文字列型で入ってるので、数値型にキャストできるなら変換
          if (tmpFilterParameter[key] !== null && tmpFilterParameter[key] !== '' && !Number.isNaN(Number(tmpFilterParameter[key]))) {
            tmpFilterParameter[key] = Number(tmpFilterParameter[key])
          }
          // this.$route.query には文字列型で入ってるので、boolean型にしたいものは変換
          if (tmpFilterParameter[key] === 'true') {
            tmpFilterParameter[key] = true
          }
          if (tmpFilterParameter[key] === 'false') {
            tmpFilterParameter[key] = false
          }
        }
      }
      this.$store.dispatch('segments/updateFilter', tmpFilterParameter)
    },
    /**
     * フィルタリング条件の適用
     */
    setFilterParameter () {
      this.changeQuery('filter', this.preparedFilters)
    },
    /**
     * フィルタリング条件を初期化
     */
    resetFilterParameter () {
      this.preparedFilters = []
      this.setFilterParameter()
    },
    onClickSelectsButton () {
      this.showSelects = !this.showSelects
    },
    selectSegment (value) {
      this.selectedSegments = value
    },
    onClickBulkDelete () {
      this.$store.dispatch('segments/bulkDeleteSegments', this.selectedSegments)
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.segment') + this.$t('notify.success.delete'))
        }).catch(() => {
          this.notifyErrorMessage(this.$t('notify.error.segments.bulkDelete'))
        }).finally(() => {
          this.showSelects = false
          this.bulkDeleteDialog = false
          this.selectedSegments = []
          this.getSegments()
        })
    },
  },
}
</script>
