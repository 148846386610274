import axios from '@/axios'

const pages = {
  namespaced: true,
  state: {
    initFilterParameter: {
      page: 0,
      // 表示件数は固定
      limit: 100,
    },
    sortParameter: {
      // 固定
      orderBy: 'pageId',
      isDesc: false,
    },
    loadingPages: false,
  },
  mutations: {
    setLoadingPages (state, newVal) {
      state.loadingPages = newVal
    },
  },
  actions: {
    getPages ({ commit, state }, url) {
      commit('setLoadingPages', true)
      const queryParameter = {
        offset: state.initFilterParameter.page * state.initFilterParameter.limit,
        limit: state.initFilterParameter.limit,
        orderBy: state.sortParameter.orderBy,
        isDesc: state.sortParameter.isDesc,
        url: url,
      }
      return axios.get('/page/', { params: queryParameter })
        .then(res => {
          // 不完全な URLデコード文字列を除外 (長過ぎると途中で切られるため)
          const utf8uri = new RegExp(
            '%[0-7][0-9A-F]|' +
            '%C[2-9A-F]%[89AB][0-9A-F]|%D[0-9A-F]%[89AB][0-9A-F]|' +
            '%E[0-F](?:%[89AB][0-9A-F]){2}|' +
            '%F[0-7](?:%[89AB][0-9A-F]){3}|' +
            '%F[89AB](?:%[89AB][0-9A-F]){4}|' +
            '%F[CD](?:%[89AB][0-9A-F]){5}', 'ig')
          return Promise.resolve({
            pages: res.data.data.pages.map(page => {
              return { pageId: page.pageId, url: page.url.replace(utf8uri, (whole) => decodeURI(whole)) }
            }),
            paging: res.data.paging,
          })
        }).catch((err) => {
          return Promise.reject(err)
        }).finally(() => {
          commit('setLoadingPages', false)
        })
    },
    getNextPages ({ commit, state }, { url, page }) {
      commit('setLoadingPages', true)
      const queryParameter = {
        offset: (page + 1) * state.initFilterParameter.limit,
        limit: state.initFilterParameter.limit,
        orderBy: state.sortParameter.orderBy,
        isDesc: state.sortParameter.isDesc,
        url: url,
      }
      return axios.get('/page/', { params: queryParameter })
        .then(res => {
          // 不完全な URLデコード文字列を除外 (長過ぎると途中で切られるため)
          const utf8uri = new RegExp(
            '%[0-7][0-9A-F]|' +
            '%C[2-9A-F]%[89AB][0-9A-F]|%D[0-9A-F]%[89AB][0-9A-F]|' +
            '%E[0-F](?:%[89AB][0-9A-F]){2}|' +
            '%F[0-7](?:%[89AB][0-9A-F]){3}|' +
            '%F[89AB](?:%[89AB][0-9A-F]){4}|' +
            '%F[CD](?:%[89AB][0-9A-F]){5}', 'ig')
          return Promise.resolve({
            pages: res.data.data.pages.map(page => {
              return { pageId: page.pageId, url: page.url.replace(utf8uri, (whole) => decodeURI(whole)) }
            }),
            paging: res.data.paging,
          })
        }).catch((err) => {
          return Promise.reject(err)
        }).finally(() => {
          commit('setLoadingPages', false)
        })
    },
  },
}
export default pages
