<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>ジョブ実行履歴・データソース取り込み履歴</h2>
      </v-col>
      <v-col sm="12" md="12" lg="9" class="d-flex justify-space-around">
        <v-chip color="pending">pending</v-chip>
        <v-chip color="ongoing">ongoing</v-chip>
        <v-chip color="success">success</v-chip>
        <v-chip color="fault">fault</v-chip>
        <v-chip color="and">AND</v-chip>
        <v-chip color="or">OR</v-chip>
        <v-chip color="io">入出力</v-chip>
        <v-chip color="sql">sql</v-chip>
      </v-col>
      <v-col sm="8" md="8" lg="6" class="d-flex justify-space-around">
        <v-chip color="attribute">属性</v-chip>
        <v-chip color="webTransaction">Web行動</v-chip>
        <v-chip color="transactionSummary">トランザクションサマリ</v-chip>
      </v-col>
    </v-row>
    <v-divider class="mt-5" />
    <h2>今回使わないもの</h2>
    <v-row class="other">
      <v-col sm="4" md="3" lg="2">
        <h2>default size</h2>
        <v-chip>デフォルト</v-chip>
        <v-chip color="primary">primary</v-chip>
        <v-chip color="secondary">secondary</v-chip>
        <v-chip color="accent">accent</v-chip>
        <v-chip color="error">error</v-chip>
        <v-chip disabled>disabled</v-chip>
      </v-col>
      <v-col sm="4" md="3" lg="2">
        <h2>small</h2>
        <v-chip small>デフォルト</v-chip>
        <v-chip small color="primary">primary</v-chip>
        <v-chip small color="secondary">secondary</v-chip>
        <v-chip small color="accent">accent</v-chip>
        <v-chip small color="error">error</v-chip>
        <v-chip small disabled>disabled</v-chip>
      </v-col>
      <v-col sm="4" md="3" lg="2">
        <h2>large</h2>
        <v-chip large>デフォルト</v-chip>
        <v-chip large color="primary">primary</v-chip>
        <v-chip large color="secondary">secondary</v-chip>
        <v-chip large color="accent">accent</v-chip>
        <v-chip large color="error">error</v-chip>
        <v-chip large disabled>disabled</v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Chips',
}
</script>

<style lang="scss" scoped>
.other .col {
  display:flex;
  flex-flow: column;
  align-items: center;
  justify-content:space-between;
  min-height: 400px;
}
</style>
