import axios from '@/axios'

const totalUser = {
  namespaced: true,
  state: {
    totalUser: {
      userUnknown: {
        lastWeeklyTotal: 0,
        weeklyTotal: 0,
      },
      userCustomer: {
        lastWeeklyTotal: 0,
        weeklyTotal: 0,
      },
    },
  },
  mutations: {
    setCustomerTotalUser (state, data) {
      state.totalUser.userCustomer = data
    },
    setUnknownTotalUser (state, data) {
      state.totalUser.userUnknown = data
    },
  },
  actions: {
    async getCustomerTotalUser ({ commit }) {
      const res = await axios.get('/summary/customer/total/')
      commit('setCustomerTotalUser', res.data.data)
    },
    async getUnknownTotalUser ({ commit }) {
      const res = await axios.get('/summary/unknown/total/')
      commit('setUnknownTotalUser', res.data.data)
    },
  },
}
export default totalUser
