import axios from '@/axios'
import checkValueUtil from '@/utils/checkValueUtil'

function tryParseJSON (jsonString) {
  try {
    const o = JSON.parse(jsonString)
    if (o && typeof o === 'object') {
      return o
    }
  } catch (e) { }

  return null
}

const scvs = {
  namespaced: true,
  state: {
    loadingScvs: true,
    activeColumnIds: tryParseJSON(localStorage.getItem('activeScvColumnIds')),
    sortedColumnIds: tryParseJSON(localStorage.getItem('sortedScvColumnIds')),
    sortParameter: {
      orderBy: undefined,
      isDesc: true,
    },
    enabledFilter: true,
    filterParameter: {
      filters: [],
      page: 1,
      limit: 50,
    },
  },
  mutations: {
    setLoadingScvs (state, newval) {
      state.loadingScvs = newval
    },
    setActiveColumnIds (state, activeColumnIds) {
      // localStorage を通ると Array 構造が失われるので JSON 文字列で格納して取り出す時にパースする
      localStorage.setItem('activeScvColumnIds', JSON.stringify(activeColumnIds))
      state.activeColumnIds = activeColumnIds
    },
    setSortedColumnIds (state, sortedColumnIds) {
      // localStorage を通ると Array 構造が失われるので JSON 文字列で格納して取り出す時にパースする
      localStorage.setItem('sortedScvColumnIds', JSON.stringify(sortedColumnIds))
      state.sortedColumnIds = sortedColumnIds
    },
    setOrderBy (state, newVal) {
      state.sortParameter.orderBy = newVal
    },
    setIsDesc (state, newVal) {
      state.sortParameter.isDesc = newVal
    },
    setFilter (state, newVal) {
      state.filterParameter.filters = newVal
    },
    setPage (state, newVal) {
      state.filterParameter.page = newVal
    },
    setLimit (state, newVal) {
      state.filterParameter.limit = newVal
    },
    setEnabledFilter (state, newVal) {
      state.enabledFilter = newVal
    },
  },
  getters: {
    getQueryParams (state) {
      // state.filterParameter.filtersがArrayのため、Stringに変換してからassignする
      const queryParams = {
        orderBy: state.sortParameter.orderBy,
        isDesc: state.sortParameter.isDesc,
        filters: JSON.stringify(state.filterParameter.filters),
        enabledFilter: state.enabledFilter,
        page: state.filterParameter.page,
        limit: state.filterParameter.limit,
      }
      const params = Object.assign({}, queryParams)
      for (const key in params) {
        if (checkValueUtil.isNullOrEmpty(params[key])) delete params[key]
      }
      return params
    },
  },
  actions: {
    /**
     * SCVリストの取得
     */
    async getScvs ({ commit, state, rootState }) {
      commit('setLoadingScvs', true)
      const queryParameter = {
        isDesc: state.sortParameter.isDesc,
        offset: (state.filterParameter.page - 1) * state.filterParameter.limit,
        limit: state.filterParameter.limit,
        filters: state.enabledFilter ? JSON.stringify(state.filterParameter.filters) : '[]',
      }
      if (state.sortParameter.orderBy) queryParameter.orderBy = state.sortParameter.orderBy
      return await axios.get('/scv/', { params: queryParameter })
        .then(res => {
          return Promise.resolve({
            scvs: res.data.data.scvValueList,
            paging: res.data.paging,
          })
        }).catch(err => {
          return Promise.reject(err)
        }).finally(() => {
          commit('setLoadingScvs', false)
        })
    },
    /**
     * SCV ID で絞って SCVリストの取得
     */
    async getScvsByIds ({ commit }, params) {
      commit('setLoadingScvs', true)
      return await axios.get('/scv/', {
        params: {
          isDesc: params.isDesc,
          orderBy: params.orderBy,
          offset: (params.page - 1) * params.limit,
          limit: params.limit,
          filters: JSON.stringify(params.filters),
          scvIds: params.scvIds,
        },
      }).then(res => {
        return Promise.resolve({
          scvs: res.data.data.scvValueList,
        })
      }).catch(err => {
        return Promise.reject(err)
      }).finally(() => {
        commit('setLoadingScvs', false)
      })
    },
    updateLoadingScvs ({ commit }, newval) {
      commit('setLoadingScvs', newval)
    },
    updateActiveColumnIds ({ commit }, newVal) {
      commit('setActiveColumnIds', newVal)
    },
    updateSortedColumnIds ({ commit }, newVal) {
      commit('setSortedColumnIds', newVal)
    },
    resetActiveColumnIds ({ commit, state, rootState }) {
      // 初期表示カラムの選択
      const defaultActiveColumnIds = rootState.scvSettings.scvSettings
        .filter(scvSetting => scvSetting.scvColumnChoiceSettingList.length > 0 && !scvSetting.custom)
        .map(scvSetting => scvSetting.scvColumnId)
      commit('setActiveColumnIds', defaultActiveColumnIds)
    },
    resetSortedColumnIds ({ commit }) {
      commit('setSortedColumnIds', [])
    },
    updateOrderBy ({ commit }, newVal) {
      commit('setOrderBy', newVal)
    },
    updateIsDesc ({ commit }, newVal) {
      commit('setIsDesc', newVal)
    },
    updateFilter ({ commit }, newVal) {
      commit('setFilter', newVal)
    },
    updatePage ({ commit }, newVal) {
      commit('setPage', newVal)
    },
    updateLimit ({ commit }, newVal) {
      commit('setLimit', newVal)
    },
    updateEnabledFilter ({ commit }, newVal) {
      commit('setEnabledFilter', newVal)
    },
  },
}
export default scvs
