<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn color="primary" :to="{ name: 'TransactionSummarySetting' }" :disabled="!canPost || paging.totalCount >= transactionSummaryLimit" class="mr-3">
          {{ $t(`btn.create`) }}
        </v-btn>
        <v-tooltip top v-if="paging.totalCount >= transactionSummaryLimit">
          <template #activator="{ on }">
            <!-- <v-icon>ic-warnning-S</v-icon> が使えないので暫定措置 -->
            <span v-on="on" class="v-icon notranslate ic ic-warnning-S" style="font-size: 20px;"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
          </template>
          <span>トランザクション設定数が上限に達しています</span>
        </v-tooltip>
      </template>
      <template #right>
        <v-btn @click="getTransactionSummaries()"><v-icon>ic-reload</v-icon></v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <filter-form
        @search="setFilterParameter"
        @reset="resetFilterParameter"
        @switchFilter="val => isFiltering = val"
        :isFiltering="isFiltering"
        :filteredTotal="paging.filteredTotalCount"
        :total="paging.totalCount"
      >
        <v-row>
          <v-col class="py-0" cols="6">
            <v-text-field
              :value="filterParameter.keyword"
              @input="preparedFilters.keyword = $event"
              :label="$t(`text.transactionSummaries.filtering.keyword`)"
              clearable
              :disabled="!isFiltering" />
          </v-col>
          <v-col class="py-0" cols="3">
            <v-select
              :value="filterParameter.periodType"
              @change="preparedFilters.periodType = $event"
              :items="enumUtil.convertForSelectList(TRANSACTION_PERIOD_TYPE)"
              :label="$t(`text.transactionSummaries.filtering.periodType`)"
              clearable
              :disabled="!isFiltering" />
          </v-col>
          <v-col class="py-0" cols="3">
            <v-select
              :value="filterParameter.transactionSummaryType"
              @change="preparedFilters.transactionSummaryType = $event"
              :items="enumUtil.convertForSelectList(TRANSACTION_SUMMARY_TYPE)"
              :label="$t(`text.transactionSummaries.filtering.transactionSummaryType`)"
              clearable
              :disabled="!isFiltering" />
          </v-col>
        </v-row>
      </filter-form>
      <!-- paging/sort/limit -->
      <v-row justify="space-between" class="my-0">
        <v-col cols="4">
          <v-select
            v-model="orderBy"
            class="sort pt-0"
            :disabled="loadingTransactionSummaries"
            hide-details
            :items="sortableColumns"
          >
            <template #prepend>
              <span class="prepend-sort-text">
                {{ $t('text.sort') }}
              </span>
            </template>
            <template #append-outer>
              <v-btn icon @click="isDesc = !isDesc" :disabled="loadingTransactionSummaries">
                <v-icon v-if="isDesc">ic-sort-desc</v-icon>
                <v-icon v-else>ic-sort-asc</v-icon>
              </v-btn>
            </template>
          </v-select>
        </v-col>
        <v-col cols="5">
          <v-pagination :value="filterParameter.page" @input="changeQuery('page', $event)" :length="pages" total-visible="7" :disabled="loadingTransactionSummaries" />
        </v-col>
        <v-spacer />
        <v-col cols="2">
          <v-select
            v-model="limit"
            class="limits pt-0"
            :disabled="loadingTransactionSummaries"
            :items="limits"
            hide-details
          >
            <template #prepend>
              <span class="prepend-sort-text">
                {{ $t('text.limits') }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-progress-linear v-if="loadingTransactionSummaries" indeterminate color="primary" />
      <v-card v-if="!loadingTransactionSummaries && transactionSummarySettingList.length === 0" outlined tile class="mb-2"><v-card-text>{{$t('text.noData')}}</v-card-text></v-card>
      <large-list
        v-for="transactionSummary in transactionSummarySettingList"
        :key="transactionSummary.transactionSummarySettingId"
        :name="transactionSummary.transactionSummaryName"
        :note="transactionSummary.note"
        :to="{ name:'TransactionSummaryEdit', params:{ transactionSummaryId: transactionSummary.transactionSummarySettingId } }"
        :id="transactionSummary.transactionSummarySettingId"
        :loading="loadingTransactionSummaries"
      >
        <template #header>
          <v-row class="header">
            <v-spacer />
            <v-col cols="3">{{ transactionSummary.updatedUserName }}</v-col>
            <v-col cols="3">{{ $t('text.updatedAt') }}&#58;&nbsp;{{ transactionSummary.updatedAt }}</v-col>
          </v-row>
        </template>
        <template #col2>
          <p>{{ $t('text.transactionSummaries.list.col2.type') }}&#58;&nbsp;{{ enumUtil.getEnumFromValue(TRANSACTION_SUMMARY_TYPE, transactionSummary.transactionSummaryType).text }}</p>
          <p class="dataSourceCount">{{ $t('text.transactionSummaries.list.col2.transactionLength') }}&#58;&nbsp;{{ transactionSummary.transactions.length }}</p>
        </template>
        <template #col3>
          <p v-if="transactionSummary.periodType === TRANSACTION_PERIOD_TYPE.ALL_DAYS.value">
            {{ $t('text.transactionSummaries.list.col3.periodType') }}&#58;&nbsp;{{ enumUtil.getEnumFromValue(TRANSACTION_PERIOD_TYPE, transactionSummary.periodType).text }}
          </p>
          <p v-else-if="transactionSummary.periodType === TRANSACTION_PERIOD_TYPE.LAST_DAYS_INCLUDE_CURRENT_DAY.value">
            {{ $t('text.transactionSummaries.list.col3.periodType') }}&#58;&nbsp;{{ $t('text.transactionSummaries.list.col3.beforeDayPrefix') }}{{ transactionSummary.beforeDay }}{{ $t('text.transactionSummaries.list.col3.beforeDaySuffix') }}{{ $t('text.transactionSummaries.list.col3.includeToday') }}
          </p>
          <p v-else-if="transactionSummary.periodType === TRANSACTION_PERIOD_TYPE.LAST_DAYS_EXCLUDE_CURRENT_DAY.value">
            {{ $t('text.transactionSummaries.list.col3.periodType') }}&#58;&nbsp;{{ $t('text.transactionSummaries.list.col3.beforeDayPrefix') }}{{ transactionSummary.beforeDay }}{{ $t('text.transactionSummaries.list.col3.beforeDaySuffix') }}{{ $t('text.transactionSummaries.list.col3.excludeToday') }}
          </p>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn v-on="on" outlined tile plain>
                <v-icon class="mr-1">ic-info</v-icon>
                {{ $t('text.transactionSummaries.list.col3.filterTooltip') }}
              </v-btn>
            </template>
            <div v-for="(transaction, index) in transactionSummary.transactions" :key="transaction.dataSourceId + '_' + transaction.dataSourceName + '_' + index">
              <span>{{ transaction.dataSourceName }}</span><br>
              <span>{{ $t('text.transactionSummaries.list.col3.filterSummaryColumn') }}&#58;&nbsp;{{ transaction.summaryColumn.summaryLogicalName }}</span>
              <ul>
                <li v-for="(filterColumn, index) in transaction.filterColumns" :key="index + '_' + filterColumn.filterPhysicalname">
                  {{ getFilterRuleSentence(filterColumn) }}&nbsp;
                </li>
              </ul>
            </div>
          </v-tooltip>
        </template>
      </large-list>
      <v-row align="center" justify="space-between" class="mt-0">
        <v-col offset="4" cols="4">
          <v-pagination :value="filterParameter.page" @input="changeQuery('page', $event)" :length="pages" total-visible="7" :disabled="loadingTransactionSummaries" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// components
import FilterForm from '@/components/common/filterForm'
import LargeList from '@/components/common/largeList'
// enums
import DATA_TYPES from '@/enum/DATA_TYPES'
import TRANSACTION_PERIOD_TYPE from '@/enum/transactionSummary/TRANSACTION_PERIOD_TYPE'
import TRANSACTION_SUMMARY_TYPE from '@/enum/transactionSummary/TRANSACTION_SUMMARY_TYPE'
// utills
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'
// mixins
import segmentFilterUtil from '@/utils/segmentFilterUtil'

const DEFAULT_PAGE = 1
const DEFAULT_LIMIT = 50

export default {
  name: 'TransactionSummary',
  components: {
    FilterForm,
    LargeList,
  },
  mixins: [
    DATA_TYPES,
    TRANSACTION_PERIOD_TYPE,
    TRANSACTION_SUMMARY_TYPE,
    enumUtil,
    notifyUtil,
    segmentFilterUtil,
  ],
  data () {
    return {
      transactionSummaryLimit: 50,
      paging: {},
      sortableColumns: [
        {
          text: this.$t('text.transactionSummaries.sort.transactionSummaryName'),
          value: 'transactionSummaryName',
        },
        {
          text: this.$t('text.transactionSummaries.sort.transactionSummaryType'),
          value: 'transactionSummaryType',
        },
        {
          text: this.$t('text.transactionSummaries.sort.updatedBy'),
          value: 'updatedBy',
        },
        {
          text: this.$t('text.transactionSummaries.sort.updatedAt'),
          value: 'updatedAt',
        },
      ],
      limits: [
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
      transactionSummarySettingList: [],
      preparedFilters: [],
      isFiltering: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['canPost']),
    ...mapGetters({ queryParams: 'transactionSummary/getQueryParams' }),
    pages () {
      return Math.ceil(this.paging.filteredTotalCount / this.paging.limit) || 1
    },
    limit: {
      get () {
        return this.$store.state.transactionSummary.filterParameter.limit
      },
      set (newVal) {
        this.changeQuery('limit', newVal)
      },
    },
    orderBy: {
      get () {
        return this.$store.state.transactionSummary.sortParameter.orderBy
      },
      set (newVal) {
        this.changeQuery('orderBy', newVal)
      },
    },
    isDesc: {
      get () {
        return this.$store.state.transactionSummary.sortParameter.isDesc
      },
      set (newVal) {
        this.changeQuery('isDesc', newVal)
      },
    },
    enabledFilter () {
      return this.$store.state.transactionSummary.enabledFilter
    },
    filterParameter () {
      return this.$store.state.transactionSummary.filterParameter
    },
    loadingTransactionSummaries () {
      return this.$store.state.transactionSummary.loadingTransactionSummaries
    },
  },
  created () {
    const tmpQuery = {}
    for (const key in this.$route.query) {
      tmpQuery[key] = this.$route.query[key]
    }

    // クエリが不十分な場合補う
    let isQuerySufficient = true
    for (const key in this.queryParams) {
      if (tmpQuery[key] === null || tmpQuery[key] === undefined) {
        tmpQuery[key] = this.queryParams[key]
        isQuerySufficient = false
      }
    }

    // orderByに指定された文字列がソート基準の選択肢にあるか、もしくはtransactionSummarySettingIdなら問題ない
    const validateOrderBy = this.sortableColumns.some((column) => tmpQuery.orderBy === column.value)
    if (!validateOrderBy && tmpQuery.orderBy !== 'transactionSummarySettingId') {
      tmpQuery.orderBy = 'updatedAt'
      isQuerySufficient = false
    }
    if (tmpQuery.isDesc !== true && tmpQuery.isDesc !== false && tmpQuery.isDesc !== 'true' && tmpQuery.isDesc !== 'false') {
      tmpQuery.isDesc = true
      isQuerySufficient = false
    }
    tmpQuery.page = Number(tmpQuery.page)
    if (!Number.isInteger(tmpQuery.page) || tmpQuery.page < 1) {
      tmpQuery.page = DEFAULT_PAGE
      isQuerySufficient = false
    }
    tmpQuery.limit = Number(tmpQuery.limit)
    if (!Number.isInteger(tmpQuery.limit) || tmpQuery.limit < 1 || tmpQuery.limit > 5000) {
      tmpQuery.limit = DEFAULT_LIMIT
      isQuerySufficient = false
    }

    // クエリを変更した場合はURLを置き換える
    if (!isQuerySufficient) {
      this.$router.replace({ query: tmpQuery })
        .catch(err => err) // 画面表示時の NavigationDuplicated 対策
      // 置き換わるとbeforeRouteUpdateが呼び出されるのでこちらの処理は中断する
      return
    }

    this.reflectQueryParamsToState(tmpQuery)
    this.preparedFilters = this.filterParameter
    this.isFiltering = this.enabledFilter
    this.getTransactionSummaries()
  },
  beforeRouteUpdate (to, from, next) {
    // ルート変更に反応する
    if (to.query !== from.query) {
      this.reflectQueryParamsToState(to.query)
      this.getTransactionSummaries()
    }
    next()
  },
  methods: {
    getFilterRuleSentence (filterColumn) {
      const rule = { filterColumn: null }
      rule.filterColumn = filterColumn
      return this.createFilterRuleSentence(rule)
    },
    changeQuery (type, value) {
      const queryParams = this.queryParams

      if (type === 'filter') {
        queryParams.keyword = value.keyword
        queryParams.periodType = value.periodType
        queryParams.transactionSummaryType = value.transactionSummaryType
        queryParams.enabledFilter = this.isFiltering
      } else {
        queryParams[type] = value
      }

      if (type !== 'page') {
        queryParams.page = DEFAULT_PAGE
      }

      // クエリパラメーター更新
      // クエリパラメーターを更新するとbeforeRouteUpdateが呼び出される
      this.$router.push({ query: queryParams })
        .catch(err => err) // 画面表示時の NavigationDuplicated 対策
    },
    getTransactionSummaries () {
      this.$store.dispatch('transactionSummary/getTransactionSummaries')
        .then((res) => {
          this.transactionSummarySettingList = res.transactionSummaryList
          this.paging = res.paging
        }).catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    reflectQueryParamsToState (query) {
      this.$store.dispatch('transactionSummary/updateOrderBy', query.orderBy)
      this.$store.dispatch('transactionSummary/updateIsDesc', JSON.parse(query.isDesc))
      this.$store.dispatch('transactionSummary/updateEnabledFilter', JSON.parse(query.enabledFilter))
      const tmpFilterParameter = []
      if (Object.keys(query).length > 0) {
        // filterParameter として許可されているプロパティのみ有効
        for (const key in this.filterParameter) {
          if (query[key] === undefined && query[key] === null) {
            tmpFilterParameter[key] = null
          } else {
            tmpFilterParameter[key] = query[key]
          }
          // this.$route.query には文字列型で入ってるので、数値型にキャストできるなら変換
          if (tmpFilterParameter[key] !== null && tmpFilterParameter[key] !== '' && !Number.isNaN(Number(tmpFilterParameter[key]))) {
            tmpFilterParameter[key] = Number(tmpFilterParameter[key])
          }
          // this.$route.query には文字列型で入ってるので、boolean型にしたいものは変換
          if (tmpFilterParameter[key] === 'true') {
            tmpFilterParameter[key] = true
          }
          if (tmpFilterParameter[key] === 'false') {
            tmpFilterParameter[key] = false
          }
        }
      }
      this.$store.dispatch('transactionSummary/updateFilter', tmpFilterParameter)
    },
    /**
     * フィルタリング条件の適用
     */
    setFilterParameter () {
      this.changeQuery('filter', this.preparedFilters)
    },
    /**
     * フィルタリング条件の初期化
     */
    resetFilterParameter () {
      this.preparedFilters = []
      this.setFilterParameter()
    },
  },
}
</script>

<style lang="scss" scoped>
.header, .dataSourceCount {
  font-size: 12px;
}
</style>
