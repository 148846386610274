<template>
  <div>
    <!-- リージョン -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-autocomplete
          v-model="region"
          :items="enumUtil.convertForSelectList(AWS_S3_REGION)"
          clearable
          hide-no-data
          :label="$t(`form.region`)"
          name="region"
          :data-vv-as="$t(`form.region`)"
          v-validate="'required'"
          :error-messages="errors.collect('region')" />
      </v-col>
    </v-row>
    <!-- バケット名 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="bucket"
          :label="$t(`form.jobSetting.jobTask.bucket`)"
          name="bucket"
          :data-vv-as="$t(`form.jobSetting.jobTask.bucket`)"
          v-validate="'required'"
          :error-messages="errors.collect('bucket')" />
      </v-col>
    </v-row>
    <!-- ファイル名 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="filePath"
          :label="$t(`form.jobSetting.jobTask.file`)"
          name="filepath"
          :data-vv-as="$t(`form.jobSetting.jobTask.file`)"
          v-validate="'required'"
          :error-messages="errors.collect('filepath')" />
      </v-col>
    </v-row>
    <!-- ヘッダ行有無 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-checkbox
          v-model="skipHeaderLines"
          :label="$t(`form.jobSetting.jobTask.header`)"
          false-value="1"
          true-value="0">
          <v-tooltip slot="append" bottom :max-width="420">
            <template #activator="{ on }">
              <v-icon v-on="on">ic-help-S</v-icon>
            </template>
            <span v-html="$t(`tooltip.jobSetting.hasNotHeaderFile`)" />
          </v-tooltip>
        </v-checkbox>
      </v-col>
    </v-row>
    <!-- アクセスキー -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="accessKey"
          autocomplete="new-password"
          :label="$t(`form.accessKey`)"
          name="accesskey"
          :data-vv-as="$t(`form.accessKey`)"
          v-validate="'required'"
          :error-messages="errors.collect('accesskey')" />
      </v-col>
    </v-row>
    <!-- シークレットキー -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="secretKey"
          autocomplete="new-password"
          type="password"
          :label="$t(`form.secretKey`)"
          name="secretkey"
          :data-vv-as="$t(`form.secretKey`)"
          v-validate="'required'"
          :error-messages="errors.collect('secretkey')" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// enum
import AWS_S3_REGION from '@/enum/jobTask/AWS_S3_REGION'
import JSON_INPUT_ITEM_NAMES from '@/enum/jobTask/JSON_INPUT_ITEM_NAMES'
// util
import enumUtil from '@/utils/enumUtil'

export default {
  mixins: [
    AWS_S3_REGION,
    JSON_INPUT_ITEM_NAMES,
    enumUtil,
  ],
  props: ['inputForm'],
  computed: {
    bucket: {
      get () {
        if (this.checkSettingProp()) {
          return this.inputForm[this.JSON_INPUT_ITEM_NAMES.S3.value].bucket
        }
        return null
      },
      set (value) {
        this.updateValue('bucket', value)
      },
    },
    region: {
      get () {
        if (this.checkSettingProp()) {
          return this.inputForm[this.JSON_INPUT_ITEM_NAMES.S3.value].region
        }
        return null
      },
      set (value) {
        this.updateValue('region', value)
      },
    },
    filePath: {
      get () {
        if (this.checkSettingProp()) {
          return this.inputForm[this.JSON_INPUT_ITEM_NAMES.S3.value].filePath
        }
        return null
      },
      set (value) {
        this.updateValue('filePath', value)
      },
    },
    skipHeaderLines: {
      get () {
        if (this.checkSettingProp()) {
          return this.inputForm[this.JSON_INPUT_ITEM_NAMES.S3.value].skipHeaderLines
        }
        return null
      },
      set (value) {
        this.updateValue('skipHeaderLines', value)
      },
    },
    accessKey: {
      get () {
        if (this.checkSettingProp()) {
          return this.inputForm[this.JSON_INPUT_ITEM_NAMES.S3.value].accessKey
        }
        return null
      },
      set (value) {
        this.updateValue('accessKey', value)
      },
    },
    secretKey: {
      get () {
        if (this.checkSettingProp()) {
          return this.inputForm[this.JSON_INPUT_ITEM_NAMES.S3.value].secretKey
        }
        return null
      },
      set (value) {
        this.updateValue('secretKey', value)
      },
    },
  },
  methods: {
    /**
     * S3のタスクかどうかを判定
     * @return {Boolean}
     */
    checkSettingProp () {
      return this.inputForm && this.inputForm[this.JSON_INPUT_ITEM_NAMES.S3.value]
    },
    /**
     * S3インプットタスクの値をJSONに変換して更新
     * @param  {String} propertyName  更新対象のキー
     * @param  {String} propertyValue 更新対象の値
     */
    updateValue (propertyName, propertyValue) {
      if (!this.inputForm) return

      const json = Object.assign({}, this.inputForm)
      if (!json[this.JSON_INPUT_ITEM_NAMES.S3.value]) {
        this.$set(json, this.JSON_INPUT_ITEM_NAMES.S3.value, {})
      }
      Object.assign(json[this.JSON_INPUT_ITEM_NAMES.S3.value], { [propertyName]: propertyValue })
      this.$emit('update', json)
    },
  },
}
</script>
