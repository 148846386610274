export default {
  data () {
    return {
      MINUTES_LIST: {
        MINUTES_00: { text: '00', value: '00' },
        MINUTES_01: { text: '01', value: '01' },
        MINUTES_02: { text: '02', value: '02' },
        MINUTES_03: { text: '03', value: '03' },
        MINUTES_04: { text: '04', value: '04' },
        MINUTES_05: { text: '05', value: '05' },
        MINUTES_06: { text: '06', value: '06' },
        MINUTES_07: { text: '07', value: '07' },
        MINUTES_08: { text: '08', value: '08' },
        MINUTES_09: { text: '09', value: '09' },
        MINUTES_10: { text: '10', value: '10' },
        MINUTES_11: { text: '11', value: '11' },
        MINUTES_12: { text: '12', value: '12' },
        MINUTES_13: { text: '13', value: '13' },
        MINUTES_14: { text: '14', value: '14' },
        MINUTES_15: { text: '15', value: '15' },
        MINUTES_16: { text: '16', value: '16' },
        MINUTES_17: { text: '17', value: '17' },
        MINUTES_18: { text: '18', value: '18' },
        MINUTES_19: { text: '19', value: '19' },
        MINUTES_20: { text: '20', value: '20' },
        MINUTES_21: { text: '21', value: '21' },
        MINUTES_22: { text: '22', value: '22' },
        MINUTES_23: { text: '23', value: '23' },
        MINUTES_24: { text: '24', value: '24' },
        MINUTES_25: { text: '25', value: '25' },
        MINUTES_26: { text: '26', value: '26' },
        MINUTES_27: { text: '27', value: '27' },
        MINUTES_28: { text: '28', value: '28' },
        MINUTES_29: { text: '29', value: '29' },
        MINUTES_30: { text: '30', value: '30' },
        MINUTES_31: { text: '31', value: '31' },
        MINUTES_32: { text: '32', value: '32' },
        MINUTES_33: { text: '33', value: '33' },
        MINUTES_34: { text: '34', value: '34' },
        MINUTES_35: { text: '35', value: '35' },
        MINUTES_36: { text: '36', value: '36' },
        MINUTES_37: { text: '37', value: '37' },
        MINUTES_38: { text: '38', value: '38' },
        MINUTES_39: { text: '39', value: '39' },
        MINUTES_40: { text: '40', value: '40' },
        MINUTES_41: { text: '41', value: '41' },
        MINUTES_42: { text: '42', value: '42' },
        MINUTES_43: { text: '43', value: '43' },
        MINUTES_44: { text: '44', value: '44' },
        MINUTES_45: { text: '45', value: '45' },
        MINUTES_46: { text: '46', value: '46' },
        MINUTES_47: { text: '47', value: '47' },
        MINUTES_48: { text: '48', value: '48' },
        MINUTES_49: { text: '49', value: '49' },
        MINUTES_50: { text: '50', value: '50' },
        MINUTES_51: { text: '51', value: '51' },
        MINUTES_52: { text: '52', value: '52' },
        MINUTES_53: { text: '53', value: '53' },
        MINUTES_54: { text: '54', value: '54' },
        MINUTES_55: { text: '55', value: '55' },
        MINUTES_56: { text: '56', value: '56' },
        MINUTES_57: { text: '57', value: '57' },
        MINUTES_58: { text: '58', value: '58' },
        MINUTES_59: { text: '59', value: '59' },
      },
    }
  },
}
