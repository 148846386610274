<template>
  <v-container>
    <!--
      href と value によるタブ選択について
      https://github.com/vuetifyjs/vuetify/issues/10181
      https://github.com/vuetifyjs/vuetify/issues/10540
    -->
    <v-tabs v-model="selectedTab">
      <v-tab href="#one">Item one</v-tab>
      <v-tab href="#two">Item two</v-tab>
      <v-tab href="#three">Item three</v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item value="one">
        Content one
      </v-tab-item>
      <v-tab-item value="two">
        Content two
      </v-tab-item>
      <v-tab-item value="three">
        Content three
      </v-tab-item>
    </v-tabs-items>
    <br>
    <v-card width="500">
      <v-card-text class="d-flex justify-space-between">
        <v-btn @click="selectedTab = 'one'">Select one</v-btn>
        <v-btn @click="selectedTab = 'two'">Select two</v-btn>
        <v-btn @click="selectedTab = 'three'">Select three</v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Tabs',
  data () {
    return {
      selectedTab: null,
    }
  },
}
</script>
