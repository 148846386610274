<template>
  <v-container>
    <v-tooltip bottom max-width="900">
      <template #activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on">
          条件を表示
        </v-btn>
      </template>
      ・5日以内にセッション数2回以上 <br>
      ・INTEGRAL-CORE サービスサイト閲覧 <br>
      ・INTEGRAL-CORE Spring Campaign BLOG PV
    </v-tooltip>
  </v-container>
</template>

<script>
export default {
  name: 'Tooltips',
}
</script>
