var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"hide-default-footer":"","items":_vm.dataSourceImportHistories,"loading":_vm.loading,"disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.csvImportType",fn:function(ref){
var item = ref.item;
return [(item.csvImportType === _vm.CSV_IMPORT_TYPE.REPLACEMENT.value)?_c('span',[_vm._v(" "+_vm._s(_vm.CSV_IMPORT_TYPE.REPLACEMENT.text)+" ")]):(item.csvImportType === _vm.CSV_IMPORT_TYPE.DIFFERENCE.value)?_c('span',[_vm._v(" "+_vm._s(_vm.CSV_IMPORT_TYPE.DIFFERENCE.text)+" ")]):_vm._e()]}},{key:"item.targetFilesJson",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.splitComma(item.targetFilesJson)),function(fileName,index){return _c('div',{key:item.dataSourcePeriodicImportHistoryId + index},[_vm._v(" "+_vm._s(fileName)+" ")])})}},{key:"item.dataSourceImportStatus",fn:function(ref){
var item = ref.item;
return [(item.dataSourceImportStatus === _vm.EXEC_STATUS.PROCESSING.value)?_c('v-chip',{attrs:{"color":"ongoing"}},[_c('span',[_vm._v(_vm._s(_vm.EXEC_STATUS.PROCESSING.text))])]):(item.dataSourceImportStatus === _vm.EXEC_STATUS.SUCCESS.value)?_c('v-chip',{attrs:{"color":"success"}},[_c('span',[_vm._v(" "+_vm._s(_vm.EXEC_STATUS.SUCCESS.text)+" ")])]):(item.dataSourceImportStatus === _vm.EXEC_STATUS.FAILED.value)?_c('v-chip',{attrs:{"color":"fault"}},[_c('span',[_vm._v(" "+_vm._s(_vm.EXEC_STATUS.FAILED.text)+" ")])]):_vm._e()]}},{key:"item.errorMessage",fn:function(ref){
var item = ref.item;
return [(item.errorMessage)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("ellipsis")(item.errorMessage,20,'...'))+" ")])]}}],null,true)},[_c('span',{staticClass:"break-all"},[_vm._v(" "+_vm._s(item.errorMessage)+" ")])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }