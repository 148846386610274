import { render, staticRenderFns } from "./customScvColumns.vue?vue&type=template&id=7bace1db&scoped=true&"
import script from "./customScvColumns.vue?vue&type=script&lang=js&"
export * from "./customScvColumns.vue?vue&type=script&lang=js&"
import style0 from "./customScvColumns.vue?vue&type=style&index=0&id=7bace1db&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bace1db",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VDivider,VList,VListItem,VListItemContent,VListItemSubtitle,VPagination,VProgressCircular,VRow})
