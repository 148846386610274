<template>
  <v-card tile elevation="0">
    <information-bar showTotalUser>
      <template #left>
        <v-btn v-if="getRouteToReturn().name === 'SegmentExportScripts'" :to="'/segmentExportScripts/'">{{ $t(`btn.backToList`) }}</v-btn>
        <v-btn v-else :to="getRouteToReturn()">{{ $t(`btn.back`) }}</v-btn>
      </template>
      <template #right>
        <v-btn v-if="$route.name === 'SegmentExportScriptSettingEdit'" class="mx-3" @click="showDeleteScript = true" :disabled="!canDelete || loadingSegmentExportScript" :loading="loadingSegmentExportScript">{{ $t(`btn.delete`) }}</v-btn>
        <v-switch v-model="formParameter.executable" class="header-switch" hide-details>
          <template #label>
            <span>
              {{ formParameter.executable ? $t(`text.segmentExportScriptSetting.executable`) : $t(`text.segmentExportScriptSetting.unexecutable`) }}
            </span>
          </template>
        </v-switch>
      </template>
    </information-bar>
    <v-card-text>
      <h3 class="lead-line mt-6">{{ $t(`text.segmentExportScriptSetting.name`) }}</h3>
      <v-card tile outlined>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="formParameter.segmentExportScriptName"
                :label="$t(`text.segmentExportScriptSetting.name`)"
                name="segmentExportScriptName"
                :data-vv-as="$t(`form.segmentExportScript.segmentExportScriptName`)"
                v-validate="'required|max:200'"
                :error-messages="errors.collect('segmentExportScriptName')"
              />
              <!-- 説明 -->
              <v-textarea
                v-model="formParameter.note"
                rows="4"
                auto-grow
                :label="$t(`form.note`)"
                name="note"
                :data-vv-as="$t(`form.note`)"
                v-validate="'max:1000'"
                :error-messages="errors.collect('note')"
              />
            </v-col>
            <v-col cols="4" class="d-flex flex-column">
              <div>
                {{ $t('text.createdAt') }}&#58;&nbsp;{{ formParameter.createdAt }}
                <br>
                {{ $t('text.createdBy') }}&#58;&nbsp;{{ formParameter.createdUserName }}
              </div>
              <div class="mt-2">
                {{ $t('text.updatedAt') }}&#58;&nbsp;{{ formParameter.updatedAt }}
                <br>
                {{ $t('text.updatedBy') }}&#58;&nbsp;{{ formParameter.updatedUserName }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <h3 class="lead-line mt-6">{{ $t(`text.segmentExportScriptSetting.recurringSetting`) }}</h3>
      <v-card tile outlined>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-radio-group
                v-model.number="formParameter.segmentExportScheduleType"
                mandatory
                color="primary"
                name="recurringType"
                :data-vv-as="$t(`form.segmentExportScript.recurringTypes`)"
                v-validate="'required'"
                :error-messages="errors.collect('recurringType')"
              >
                <v-radio
                  :label="SEGMENT_EXPORT_SCRIPT_RECURRING_TYPES.AFTER_SEGMENT_MANAGER_EXEC.text"
                  :value="SEGMENT_EXPORT_SCRIPT_RECURRING_TYPES.AFTER_SEGMENT_MANAGER_EXEC.value"
                  color="inputSelectionControl"
                />
                <v-radio
                  :label="SEGMENT_EXPORT_SCRIPT_RECURRING_TYPES.DAILY_SCHEDULED.text"
                  :value="SEGMENT_EXPORT_SCRIPT_RECURRING_TYPES.DAILY_SCHEDULED.value"
                  color="inputSelectionControl"
                />
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="8">
              <div v-if="formParameter.segmentExportScheduleType === SEGMENT_EXPORT_SCRIPT_RECURRING_TYPES.DAILY_SCHEDULED.value">
                <b>時刻を選択してください</b>
                <v-row>
                  <v-col cols="2">
                    <v-select
                      v-model="formHour"
                      dense
                      :items="allowedHours"
                      name="time"
                      :data-vv-as="$t(`form.time`)"
                      v-validate="'required'"
                      :error-messages="errors.collect('time')"
                    />
                  </v-col>
                  <!-- <v-col cols="2">
                    <v-select v-model="formHour" dense :items="allowedHours" :suffix="'時'" />
                  </v-col>
                  <v-col cols="2">
                    <v-select v-model="formMinutes" dense :items="allowedMinutes" :suffix="'分'" />
                  </v-col> -->
                  <v-col cols="2">
                    に実施
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <h3 class="lead-line mt-6">{{ $t(`text.segmentExportScriptSetting.targetSegment`) }}</h3>
      <v-card tile outlined>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-select
                v-model="formParameter.segmentId"
                :items="scvSegments"
                :label="$t(`text.segmentExportScriptSetting.segmentName`)"
                name="targetSegment"
                :data-vv-as="$t(`form.segmentExportScript.targetSegment`)"
                v-validate="'required'"
                :error-messages="errors.collect('targetSegment')"
                :disabled="$route.name === 'SegmentExportScriptSettingEdit'"
                :loading="loadingSegment"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <h3 class="lead-line mt-6">{{ $t(`text.segmentExportScriptSetting.script`) }}</h3>
      <v-card tile flat>
        <v-row>
          <!-- Editor -->
          <v-col cols="8">
            <!-- TODO サンプル集置き場が整備されたら表示 -->
            <!-- <v-btn class="ma-2" :to="{ name: 'Manuals' }" target="_blank">{{ $t(`text.segmentExportScriptSetting.showTemplate`) }}</v-btn> -->
            <js-editor class="editor" v-model="formParameter.script" />
          </v-col>
          <!-- Documents -->
          <v-col cols="4">
            <v-tabs v-model="selectedTab">
              <v-tab href="#properties">Customer のプロパティ</v-tab>
              <v-tab href="#methods">メソッド</v-tab>
            </v-tabs>
            <v-tabs-items v-model="selectedTab" style="height: 74vh;">
              <v-tab-item value="properties" class="item-inPageScroll_x text-pre">
                <v-radio-group v-model="selectedPropertyType" row mandatory>
                  <v-radio value="scvColumn" label="SCV項目" />
                  <v-radio value="transactionSummary" label="トランザクションサマリ" />
                </v-radio-group>
                <v-simple-table v-if="selectedPropertyType === 'scvColumn'">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">key</th>
                        <th class="text-left">SCV項目カラム名称</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="scvSetting in scvSettings" :key="scvSetting.scvSettingId">
                        <td>{{ scvSetting.scvPhysicalColumnName }}</td>
                        <td>{{ scvSetting.scvLogicalColumnName }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-simple-table v-else-if="selectedPropertyType === 'transactionSummary'">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">key</th>
                        <th class="text-left">トランザクションサマリ名</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="transactionSummary in transactionSummaries" :key="transactionSummary.transactionSummarySettingId">
                        <td>transaction_summary_{{ transactionSummary.transactionSummarySettingId }}</td>
                        <td>{{ transactionSummary.transactionSummaryName }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
              <v-tab-item value="methods" class="item-inPageScroll_x text-pre">
                <div>
                  <code>countCurrent(): number</code><br>
                  => 実行時に対象となった (セグメントされた) カスタマーの総数を取得します。<br>
                  <br>
                  <code>countPrevious(): number</code><br>
                  => 前回実行時に対象となった (セグメントされた) カスタマーの総数を取得します。<br>
                  <br>
                  <code>readCurrent(callback: (customer: Object) => void, offset: number, limit: number): Promise&lt;any&gt;</code><br>
                  => 実行時に対象となった (セグメントされた) カスタマーを取得します。カスタマーのデータが1件ずつ callback に渡されます。<br>
                  <br>
                  <code>readPrevious(callback: (customer: Object) => void, offset: number, limit: number): Promise&lt;any&gt;</code><br>
                  => 前回実行時に対象となった (セグメントされた) カスタマーを取得します。カスタマーのデータが1件ずつ callback に渡されます。<br>
                  <br>
                  <code>readSegment(): Promise&lt;Segment&gt;</code><br>
                  => 実行対象のセグメントの詳細を取得します<br>
                  <br>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card>
    </v-card-text>
    <v-card-actions>
    </v-card-actions>
    <v-footer fixed>
      <v-col cols="11" offset="1" class="d-flex justify-center">
        <v-btn class="mx-3" large color="denial" @click="onClickCancel">{{ $t('btn.cancel') }}</v-btn>
        <v-btn v-if="$route.name === 'SegmentExportScriptSettingEdit'" class="mx-3" large color="secondary" @click="onClickCopy">{{ $t('btn.copy') }}</v-btn>
        <v-btn class="mx-3" large color="primary" :disabled="disableSaveButton" @click="onClickSave">{{ $t('btn.save') }}</v-btn>
      </v-col>
    </v-footer>

    <!-- 削除確認用モーダル -->
    <v-dialog v-model="showDeleteScript" width="400" overlay-color="#07070820" overlay-opacity="1" :loading="loadingSegmentExportScript">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          {{ $t(`notify.check.delete`, [formParameter.segmentExportScriptName]) }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="denial" @click="showDeleteScript = false">{{ $t('btn.no') }}</v-btn>
          <v-btn color="primary" @click="deleteScript">{{ $t('btn.yes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// components
import jsEditor from '@/components/SegmentExportScriptSetting/jsEditor'
// enum
import HOUR_LIST from '@/enum/HOUR_LIST'
import MINUTES_LIST from '@/enum/MINUTES_LIST'
import SEGMENT_EXPORT_SCRIPT_RECURRING_TYPES from '@/enum/SEGMENT_EXPORT_SCRIPT_RECURRING_TYPES'
import USER_TYPES from '@/enum/USER_TYPES'
// utils
import enumUtil from '@/utils/enumUtil'
import momentUtil from '@/utils/momentUtil'
import notifyUtil from '@/utils/notifyUtil'

export default {
  mixins: [
    HOUR_LIST,
    MINUTES_LIST,
    SEGMENT_EXPORT_SCRIPT_RECURRING_TYPES,
    USER_TYPES,
    enumUtil,
    momentUtil,
    notifyUtil,
  ],
  components: {
    jsEditor,
  },
  data () {
    return {
      prevRoute: null,
      showDeleteScript: false,
      scvSettings: [],
      segmentExportScriptId: this.$route.params.segmentExportScriptId ? Number(this.$route.params.segmentExportScriptId) : null,
      formParameter: {
        segmentExportScriptName: null,
        segmentId: null,
        segmentExportScheduleType: null,
        scheduleTime: null,
        script: null,
        executable: null,
        note: null,
      },
      selectedTab: 'properties',
      selectedPropertyType: 'scvColumn',
      transactionSummaries: [],
      scvSegments: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['canPost', 'canPut', 'canDelete']),
    allowedHours () {
      const allowedHours = this.enumUtil.convertForSelectList(this.HOUR_LIST)
        .map(obj => {
          if (['00', '01', '02'].includes(obj.value)) {
            obj.disabled = true
          }
          obj.text = obj.text + ':00'
          return obj
        })
      return allowedHours
    },
    allowedMinutes () {
      const allowedMinutes = this.enumUtil.convertForSelectList(this.MINUTES_LIST)
        .map(obj => {
          if (!['00'].includes(obj.value)) {
            obj.disabled = true
          }
          return obj
        })
      return allowedMinutes
    },
    formHour: {
      get () {
        return this.formParameter.scheduleTime?.substring(0, 2)
      },
      set (newVal) {
        this.formParameter.scheduleTime = newVal + (this.formParameter.scheduleTime?.substring(2) || ':00:00')
      },
    },
    formMinutes: {
      get () {
        return this.formParameter.scheduleTime?.substring(3, 5)
      },
      set (newVal) {
        this.formParameter.scheduleTime = (this.formParameter.scheduleTime?.substring(0, 3) || '12:') + newVal + ':00'
      },
    },
    disableSaveButton () {
      if (this.$route.name === 'SegmentExportScriptSettingAdd' || this.$route.name === 'SegmentExportScriptSettingCopy') {
        return !this.canPost
      } else if (this.$route.name === 'SegmentExportScriptSettingEdit') {
        return !this.canPut
      }
      return false
    },
    loadingSegmentExportScript: {
      get () {
        return this.$store.state.segmentExportScript.loadingSegmentExportScript
      },
      set (newVal) {
        this.$store.dispatch('segmentExportScript/updateLoadingSegmentExportScript', newVal)
      },
    },
    loadingSegment () {
      return this.$store.state.segments.loadingSegment
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  created () {
    this.$store.dispatch('scvSettings/getValidScvSettings')
      .then((res) => {
        this.scvSettings = JSON.parse(JSON.stringify(res.scvSettings.filter(scvColumn => scvColumn.scvColumnChoiceSettingList.length > 0)))
      })
    this.$store.dispatch('transactionSummary/getAllTransactionSummaries')
      .then((res) => {
        this.transactionSummaries = res.data.data.transactionSummarySettingList
      })

    // edit or copy
    if (this.segmentExportScriptId > 0) {
      this.$store.dispatch('segmentExportScript/getSegmentExportScript', this.segmentExportScriptId)
        .then(res => {
          this.formParameter.segmentExportScriptName = res.data.data.segmentExportScriptName
          this.formParameter.segmentId = res.data.data.segmentId
          this.formParameter.segmentExportScheduleType = res.data.data.segmentExportScheduleType
          this.formParameter.scheduleTime = res.data.data.scheduleTime
          this.formParameter.script = res.data.data.script
          this.formParameter.executable = res.data.data.executable
          this.formParameter.note = res.data.data.note
        }).catch((err) => {
          this.handleErrorResponse(err)
        })
    } else {
      // set initial script
      this.formParameter.script = `'use strict'

const https = require('https');
// INTEGRAL CORE セグメント情報取得用モジュール
const segmentExporter = require('segment-exporter');

// 設定値
const API_ENDPOINT = 'https://example.com/';

// ログ出力用メソッド
function log(message, ...options) {
    console.log('%s - ' + message, (new Date()).toISOString(), ...options);
}

// リクエスト送信用メソッド
function sendRequest(customers, options) {
    return new Promise((resolve, reject) => {
        const req = https.request(options, res => {
            if (res.statusCode !== 200) {
                log('Did not get an OK from the server. Code: %d', res.statusCode);
                res.resume();
                return;
            }
            res.on('data', data => resolve(data))
            res.on('close', () => {
                log('Request has been processed');
                log(JSON.parse(data));
            });
        });
        req.on('error', error => reject(error));
        req.write(JSON.stringify(customers));
        req.end();
    });
}

// 実行環境ログ出力
log('show process.versions');
log('%o', process.versions);

// main 処理
(async () => {
    await (() => new Promise(async (resolve, reject) => {
        console.time('process');
        log('=== Start ===')
        // HTTP リクエスト送信用のオプションを事前に組み立てる
        const url = new URL(API_ENDPOINT);
        const options = {
            hostname: url.hostname,
            port: url.port,
            path: url.pathname + url.search,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        };

        // セグメントに所属するカスタマー情報を取得して HTTP リクエストを送信
        const limit = 1000;
        const total = await segmentExporter.countCurrent();
        log('Total customer count: %d', total);

        for (let i = 0; i < parseInt(total / limit) + 1; i++) {
            const offset = i * limit;
            let customers = [];
            await segmentExporter.readCurrent(customer => {
                customers.push(customer);
            }, offset, limit);
            log('=== Send request (%d) ===', i + 1)
            await sendRequest(customers, options)
                .catch((err) => log(err));
            console.timeLog('process', i + 1);
        }
        log('=== End ===')
        console.timeEnd('process');
    }))();
})().catch(error => {
    log(error);
    process.exit(1);
});
`
    }

    this.$store.dispatch('segments/getAllSegments', this.momentUtil.getCurrentDateForSummary())
      .then((res) => {
        this.scvSegments = res.data.data.segments
          .filter((segment) => {
            return segment.segment.userType === this.USER_TYPES.SCV.value
          })
          .map((segment) => {
            return {
              text: segment.segment.segmentName,
              value: segment.segment.segmentId,
            }
          })
      })
      .catch((err) => {
        this.handleErrorResponse(err)
      })
  },
  methods: {
    /**
     * 戻るボタンの戻り先を取得 (セグメント詳細からも遷移してくるため)
     * @returns {Route} 戻るボタンの遷移先.前画面の Route.name を見て振り分ける
     *     `prevRoute.name === 'SegmentDetail'`: セグメント詳細へ遷移 (パラメータを保持)
     *     `prevRoute.name === 'SegmentExportScripts'`: ジョブ一覧へ遷移 (パラメータを保持)
     *     その他: スクリプト一覧へ遷移 (パラメータなし)
     */
    getRouteToReturn () {
      if (this.prevRoute === null || this.prevRoute.name === null) {
        return { name: 'SegmentExportScripts' }
      } else if (this.prevRoute.name === 'SegmentDetail') {
        return this.prevRoute
      } else if (this.prevRoute.name === 'SegmentExportScripts') {
        return this.prevRoute
      }
      return { name: 'SegmentExportScripts' }
    },
    onClickCancel () {
      this.$router.push({ name: 'SegmentExportScripts' }, () => {})
    },
    onClickCopy () {
      this.$router.push({ name: 'SegmentExportScriptSettingCopy', params: { segmentExportScriptId: this.segmentExportScriptId } }, () => {})
    },
    onClickSave () {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          for (const error of this.errors.items) {
            this.notifyErrorMessage(error.msg)
          }
          return
        }

        if (this.$route.name === 'SegmentExportScriptSettingEdit') {
          this.formParameter.segmentExportScriptId = this.segmentExportScriptId
          this.$store.dispatch('segmentExportScript/putSegmentExportScript', this.formParameter)
            .then(res => {
              this.notifySuccessMessage(this.$t('notify.segmentExportScript') + this.$t('notify.success.update'))
              this.$router.push({ name: 'SegmentExportScripts' }, () => {})
            }).catch((err) => {
              this.handleErrorResponse(err)
            })
        } else {
          this.$store.dispatch('segmentExportScript/postSegmentExportScript', this.formParameter)
            .then(res => {
              this.notifySuccessMessage(this.$t('notify.segmentExportScript') + this.$t('notify.success.create'))
              this.$router.push({ name: 'SegmentExportScripts' }, () => {})
            }).catch((err) => {
              this.handleErrorResponse(err)
            })
        }
      })
    },
    deleteScript () {
      this.$store.dispatch('segmentExportScript/deleteSegmentExportScript', this.segmentExportScriptId)
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.segmentExportScript') + this.$t('notify.success.delete'))
          this.showDeleteScript = false
          this.$router.push({ name: 'SegmentExportScripts' })
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.editor {
  max-height: 79vh !important;
}
.header-switch {
  span {
    color: #DCDCDC;
  }
}
</style>
