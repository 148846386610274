<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn color="primary" :to="'/job/setting'" :disabled="!canPost">
          {{ $t(`btn.create`) }}
        </v-btn>
      </template>
      <template #right>
        <v-btn class="mr-5" @click="showSelect = !showSelect" :disabled="!canDelete">{{ $t('btn.select') }}</v-btn>
        <v-btn class="ml-5" @click="getJobs()"><v-icon>ic-reload</v-icon></v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <filter-form
        @search="setFilterParameter"
        @reset="resetFilterParameter"
        @switchFilter="val => isFiltering = val"
        :isFiltering="isFiltering"
        :filteredTotal="paging.filteredTotalCount"
        :total="paging.totalCount"
      >
        <v-row>
          <v-col class="py-0" cols="6">
            <v-text-field
              :value="filterParameter.name"
              @input="preparedFilters.name = $event"
              :label="$t(`text.jobs.filtering.name`)"
              clearable
              :disabled="!isFiltering" />
          </v-col>
          <v-col class="py-0" cols="3">
            <v-select
              :value="filterParameter.recurringType"
              @change="preparedFilters.recurringType = $event"
              :items="enumUtil.convertForSelectList(JOB_RECURRING_TYPES)"
              :label="$t(`text.jobs.filtering.types`)"
              clearable
              :disabled="!isFiltering" />
          </v-col>
          <v-col class="py-0" cols="3">
            <v-select
              :value="filterParameter.lastStatus"
              @change="preparedFilters.lastStatus = $event"
              :items="enumUtil.convertForSelectList(JOB_EXEC_STATUS)"
              :label="$t(`text.jobs.filtering.lastStatus`)"
              clearable
              :disabled="!isFiltering" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" cols="3">
            <v-select
              :value="filterParameter.createdBy"
              @change="preparedFilters.createdBy = $event"
              :items="filteringAccountList"
              :label="$t(`text.jobs.filtering.createdUserName`)"
              clearable
              :disabled="!isFiltering || !canGetThis('Accounts')" />
          </v-col>
          <v-col class="py-0" cols="3">
            <v-select
              :value="filterParameter.updatedBy"
              @change="preparedFilters.updatedBy = $event"
              :items="filteringAccountList"
              :label="$t(`text.jobs.filtering.updatedUserName`)"
              clearable
              :disabled="!isFiltering || !canGetThis('Accounts')" />
          </v-col>
          <v-col class="py-0" cols="3">
            <v-select
              :value="filterParameter.enabled"
              @change="preparedFilters.enabled = $event"
              :items="filterStanBy"
              :label="$t(`text.jobs.filtering.enabled`)"
              clearable
              :disabled="!isFiltering" />
          </v-col>
        </v-row>
      </filter-form>
      <!-- paging/sort/limit -->
      <v-row justify="space-between" class="my-0">
        <v-col cols="4">
          <v-select
            v-model="orderBy"
            class="sort pt-0"
            hide-details
            :items="sortableColumns"
            :disabled="loadingJobs"
          >
            <template #prepend>
              <span class="prepend-sort-text">
                {{ $t('text.sort') }}
              </span>
            </template>
            <template #append-outer>
              <v-btn icon @click="isDesc = !isDesc" :disabled="loadingJobs">
                <v-icon v-if="isDesc">ic-sort-desc</v-icon>
                <v-icon v-else>ic-sort-asc</v-icon>
              </v-btn>
            </template>
          </v-select>
        </v-col>
        <v-col cols="5">
          <v-pagination :value="filterParameter.page" @input="changeQuery('page', $event)" :length="pages" total-visible="7" :disabled="loadingJobs" />
        </v-col>
        <v-spacer />
        <v-col cols="2">
          <v-select
            v-model="limit"
            class="limits pt-0"
            hide-details
            :items="limits"
            :disabled="loadingJobs"
          >
            <template #prepend>
              <span class="prepend-sort-text">
                {{ $t('text.limits') }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-progress-linear v-if="loadingJobs" indeterminate color="primary" />
      <v-card v-if="!loadingJobs && jobs.length === 0" outlined tile class="mb-2"><v-card-text>{{$t('text.noData')}}</v-card-text></v-card>
      <large-list
        v-for="job in jobs"
        :key="job.id"
        :name="job.jobName"
        :note="job.note"
        :to="{ name:'JobSettingEdit', params:{ jobId: job.id } }"
        :id="job.id"
        :selectedList="selectedJobSettingIdList"
        :showSelects="showSelect"
        :loading="loadingJobs"
        @checked="val => selectedJobSettingIdList = val"
      >
        <template #header>
          <v-row class="align-center">
            <v-col cols="1">
              <v-chip v-if="job.lastExecStatus === JOB_EXEC_STATUS.WAIT.value" color="pending" :to="{ name: 'JobHistory', query: { id: job.id }}">
                {{ enumUtil.getEnumFromValue(JOB_EXEC_STATUS, job.lastExecStatus).text }}
              </v-chip>
              <v-chip v-else-if="job.lastExecStatus === JOB_EXEC_STATUS.EXECUTING.value" color="ongoing" :to="{ name: 'JobHistory', query: { id: job.id }}">
                {{ enumUtil.getEnumFromValue(JOB_EXEC_STATUS, job.lastExecStatus).text }}
              </v-chip>
              <v-chip v-else-if="job.lastExecStatus === JOB_EXEC_STATUS.SUCCESS.value" color="success" :to="{ name: 'JobHistory', query: { id: job.id }}">
                {{ enumUtil.getEnumFromValue(JOB_EXEC_STATUS, job.lastExecStatus).text }}
              </v-chip>
              <v-chip v-else-if="job.lastExecStatus === JOB_EXEC_STATUS.FAIL.value" color="fault" :to="{ name: 'JobHistory', query: { id: job.id }}">
                {{ enumUtil.getEnumFromValue(JOB_EXEC_STATUS, job.lastExecStatus).text }}
              </v-chip>
              <v-chip v-else-if="!job.lastExecStatus" color="pending">
                未実行
              </v-chip>
            </v-col>
            <v-col>
              <span class="mr-1">スケジュール&#58;&nbsp;</span>
              <span v-if="job.recurringType === JOB_RECURRING_TYPES.ONETIME.value">
                {{ enumUtil.getEnumFromValue(JOB_RECURRING_TYPES, job.recurringType).text }}&nbsp;{{ job.executionTime | hideTimeSecond }}
              </span>
              <span v-else-if="job.recurringType === JOB_RECURRING_TYPES.DAILY.value">
                {{ enumUtil.getEnumFromValue(JOB_RECURRING_TYPES, job.recurringType).text }}&nbsp;{{ job.executionTime | showTimeOnly | hideSeconds }}
              </span>
              <v-tooltip v-else-if="job.recurringType === JOB_RECURRING_TYPES.WEEKLY.value" bottom>
                <template #activator="{ on }">
                  <span v-on="on">
                    {{ enumUtil.getEnumFromValue(JOB_RECURRING_TYPES, job.recurringType).text }}&nbsp;{{ job.executionTime | showTimeOnly | hideSeconds }}
                    <v-icon>ic-info</v-icon>
                  </span>
                </template>
                <span class="body-1">
                  {{ getWeeklyScheduleStr(job.jobSettingWeekly) }}
                </span>
              </v-tooltip>
              <v-tooltip v-else-if="job.recurringType === JOB_RECURRING_TYPES.MONTHLY.value" bottom>
                <template #activator="{ on }">
                  <span v-on="on">
                    {{ enumUtil.getEnumFromValue(JOB_RECURRING_TYPES, job.recurringType).text }}&nbsp;{{ job.executionTime | showTimeOnly | hideSeconds }}
                    <v-icon>ic-info</v-icon>
                  </span>
                </template>
                <span class="body-1">
                  {{ getMonthlyScheduleStr(job.jobSettingMonthly) }}
                </span>
              </v-tooltip>
              <span v-else>
                {{ enumUtil.getEnumFromValue(JOB_RECURRING_TYPES, job.recurringType).text }}
              </span>
            </v-col>
            <v-spacer />
            <v-col cols="6" class="d-flex align-center">
              最終ジョブ終了日時&#58;&nbsp;{{ job.lastExecDatetime }}
              <v-btn
                v-show="job.lastExecStatus"
                small
                class="ml-5"
                :to="{ name: 'JobHistory', query: { id: job.id }}">
                実行履歴
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template #col2>
          <div>
            <span class="d-inline-block sub_column2">作成者</span>&#58;&nbsp;{{ job.createdUserName }}<br>
            <span class="d-inline-block sub_column2">更新者</span>&#58;&nbsp;{{ job.updatedUserName }}<br>
            <span class="d-inline-block sub_column2">更新日時</span>&#58;&nbsp;{{ job.updatedAt }}
          </div>
        </template>
        <template #col3>
          <div>
            <span class="d-inline-block sub_column3">タスク数</span>&#58;&nbsp;{{ job.jobTasks.length }}<br>
            <span class="d-inline-block sub_column3">ステータス</span>&#58;&nbsp;{{ job.enabled ? $t('text.jobs.filtering.enabledJob') : $t('text.jobs.filtering.disabledJob') }}<br>
          </div>
        </template>
      </large-list>
      <!-- paging -->
      <v-row>
        <v-col offset="4" cols="5">
          <v-pagination :value="filterParameter.page" @input="changeQuery('page', $event)" :length="pages" total-visible="7" :disabled="loadingJobs" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-footer v-show="showSelect" fixed class="justify-center">
      <v-col cols="11" offset="1" class="d-flex justify-center">
        <v-btn color="secondary" @click="onClickCancelBullDelete()">{{ $t('btn.cancel') }}</v-btn>
        <v-btn color="primary" class="ml-5" @click="showJobBulkDelete = true">{{ $t(`btn.bulkDelete`) }}</v-btn>
      </v-col>
    </v-footer>
    <v-dialog v-model="showJobBulkDelete" width="400">
      <v-card>
        <v-card-title/>
        <v-card-text>
          {{ $t('text.bulkDeleteConfirm') }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="denial" @click="showJobBulkDelete = false">{{ $t('btn.no') }}</v-btn>
          <v-btn color="primary" :loading="bulkDeletingJob" @click="onClickBulkDelete()">{{ $t(`btn.yes`) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// component
import FilterForm from '@/components/common/filterForm'
import LargeList from '@/components/common/largeList'
// enum
import JOB_EXEC_STATUS from '@/enum/JOB_EXEC_STATUS'
import JOB_RECURRING_TYPES from '@/enum/JOB_RECURRING_TYPES'
// util
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'
import scheduleStrUtil from '@/utils/scheduleStrUtil'

const DEFAULT_PAGE = 1
const DEFAULT_LIMIT = 50

export default {
  name: 'Jobs',
  components: {
    FilterForm,
    LargeList,
  },
  mixins: [
    JOB_EXEC_STATUS,
    JOB_RECURRING_TYPES,
    enumUtil,
    notifyUtil,
    scheduleStrUtil,
  ],
  data () {
    return {
      sortableColumns: [
        {
          text: this.$t('text.jobs.sort.jobName'),
          value: 'jobName',
        },
        {
          text: this.$t('text.jobs.sort.recurringType'),
          value: 'recurringType',
        },
        {
          text: this.$t('text.jobs.sort.enabled'),
          value: 'enabled',
        },
        {
          text: this.$t('text.jobs.sort.updatedAt'),
          value: 'updatedAt',
        },
        // API側が可能であれば以下追加したい
        // {
        //   text: this.$t(`text.jobs.sort.lastExecStatus`),
        //   value: 'lastExecStatus'
        // },
        // {
        //   text: this.$t(`text.jobs.sort.lastExecTime`),
        //   value: 'lastExecTime'
        // }
      ],
      limits: [
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
      filterStanBy: [
        { text: this.$t('text.jobs.filtering.enabledJob'), value: true },
        { text: this.$t('text.jobs.filtering.disabledJob'), value: false },
      ],
      jobs: [],
      paging: {},
      selectedJobSettingIdList: [],
      showSelect: false,
      filteringAccountList: [],
      showJobBulkDelete: false,
      preparedFilters: [],
      isFiltering: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['canPost', 'canDelete', 'canGetThis']),
    ...mapGetters({ queryParams: 'jobs/getQueryParams' }),
    pages () {
      return Math.ceil(this.paging.filteredTotalCount / this.paging.limit) || 1
    },
    limit: {
      get () {
        return this.$store.state.jobs.filterParameter.limit
      },
      set (newVal) {
        this.changeQuery('limit', newVal)
      },
    },
    orderBy: {
      get () {
        return this.$store.state.jobs.sortParameter.orderBy
      },
      set (newVal) {
        this.changeQuery('orderBy', newVal)
      },
    },
    isDesc: {
      get () {
        return this.$store.state.jobs.sortParameter.isDesc
      },
      set (newVal) {
        this.changeQuery('isDesc', newVal)
      },
    },
    enabledFilter () {
      return this.$store.state.jobs.enabledFilter
    },
    filterParameter () {
      return this.$store.state.jobs.filterParameter
    },
    loadingJobs () {
      return this.$store.state.jobs.loadingJobs
    },
    bulkDeletingJob () {
      return this.$store.state.jobs.bulkDeletingJob
    },
  },
  created () {
    // フィルタリング用アカウント一覧取得
    if (this.canGetThis('Accounts')) {
      this.$store.dispatch('accounts/getAccountNameAndIdList')
        .then(res => {
          res.data.data.userNameList.forEach(item => {
            this.filteringAccountList.push({ text: item.loginUserName, value: item.loginUserId })
          })
          this.filteringAccountList.push({ text: 'システム管理者', value: 0 })
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    }

    const tmpQuery = {}
    for (const key in this.$route.query) {
      tmpQuery[key] = this.$route.query[key]
    }

    // クエリが不十分な場合補う
    let isQuerySufficient = true
    for (const key in this.queryParams) {
      if (tmpQuery[key] === null || tmpQuery[key] === undefined) {
        tmpQuery[key] = this.queryParams[key]
        isQuerySufficient = false
      }
    }

    // orderByに指定された文字列がソート基準の選択肢にあるなら問題ない
    const validateOrderBy = this.sortableColumns.some((column) => tmpQuery.orderBy === column.value)
    if (!validateOrderBy) {
      tmpQuery.orderBy = 'updatedAt'
      isQuerySufficient = false
    }
    if (tmpQuery.isDesc !== true && tmpQuery.isDesc !== false && tmpQuery.isDesc !== 'true' && tmpQuery.isDesc !== 'false') {
      tmpQuery.isDesc = true
      isQuerySufficient = false
    }
    tmpQuery.page = Number(tmpQuery.page)
    if (!Number.isInteger(tmpQuery.page) || tmpQuery.page < 1) {
      tmpQuery.page = DEFAULT_PAGE
      isQuerySufficient = false
    }
    tmpQuery.limit = Number(tmpQuery.limit)
    if (!Number.isInteger(tmpQuery.limit) || tmpQuery.limit < 1 || tmpQuery.limit > 5000) {
      tmpQuery.limit = DEFAULT_LIMIT
      isQuerySufficient = false
    }

    // クエリを変更した場合はURLを置き換える
    if (!isQuerySufficient) {
      this.$router.replace({ query: tmpQuery })
        .catch(err => err) // 画面表示時の NavigationDuplicated 対策
      // 置き換わるとbeforeRouteUpdateが呼び出されるのでこちらの処理は中断する
      return
    }

    this.reflectQueryParamsToState(tmpQuery)
    this.preparedFilters = this.filterParameter
    this.isFiltering = this.enabledFilter
    this.getJobs()
  },
  beforeRouteUpdate (to, from, next) {
    // ルート変更に反応する
    if (to.query !== from.query) {
      this.reflectQueryParamsToState(to.query)
      this.getJobs()
    }
    next()
  },
  methods: {
    changeQuery (type, value) {
      const queryParams = this.queryParams

      if (type === 'filter') {
        queryParams.name = value.name
        queryParams.recurringType = value.recurringType
        queryParams.lastStatus = value.lastStatus
        queryParams.createdBy = value.createdBy
        queryParams.updatedBy = value.updatedBy
        queryParams.enabled = value.enabled
        queryParams.enabledFilter = this.isFiltering
      } else {
        queryParams[type] = value
      }

      if (type !== 'page') {
        queryParams.page = DEFAULT_PAGE
      }

      // クエリパラメーター更新
      // クエリパラメーターを更新するとbeforeRouteUpdateが呼び出される
      this.$router.push({ query: queryParams })
        .catch(err => err) // 画面表示時の NavigationDuplicated 対策
    },
    /**
     * ジョブ一覧リストの取得
     */
    getJobs () {
      this.$store.dispatch('jobs/getJobs')
        .then(res => {
          this.jobs = res.jobs
          this.paging = res.paging
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    },
    /**
     * ジョブ削除
     */
    deleteJob () {
      this.$store.dispatch('jobs/deleteJob', this.deleteJobId)
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.job') + this.$t('notify.success.delete'))
          this.getJobs()
        }).catch((err) => {
          this.handleErrorResponse(err)
        }).finally(() => {
          this.showJobDelete = false
        })
    },
    /**
     * ジョブ一括削除
     */
    onClickBulkDelete () {
      this.$store.dispatch('jobs/bulkDeleteJob', this.selectedJobSettingIdList)
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.job') + this.$t('notify.success.delete'))
        }).catch(() => {
          this.notifyErrorMessage(this.$t('notify.error.jobs.bulkDelete'))
        }).finally(() => {
          this.showSelect = false
          this.showJobBulkDelete = false
          this.selectedJobSettingIdList = []
          this.getJobs()
        })
    },
    onClickCancelBullDelete () {
      this.showSelect = false
      this.selectedJobSettingIdList = []
    },
    reflectQueryParamsToState (query) {
      this.$store.dispatch('jobs/updateOrderBy', query.orderBy)
      this.$store.dispatch('jobs/updateIsDesc', JSON.parse(query.isDesc))
      this.$store.dispatch('jobs/updateEnabledFilter', JSON.parse(query.enabledFilter))
      const tmpFilterParameter = []
      if (Object.keys(query).length > 0) {
        // filterParameter として許可されているプロパティのみ有効
        for (const key in this.filterParameter) {
          if (query[key] === undefined && query[key] === null) {
            tmpFilterParameter[key] = null
          } else {
            tmpFilterParameter[key] = query[key]
          }
          // this.$route.query には文字列型で入ってるので、数値型にキャストできるなら変換
          if (tmpFilterParameter[key] !== null && tmpFilterParameter[key] !== '' && !Number.isNaN(Number(tmpFilterParameter[key]))) {
            tmpFilterParameter[key] = Number(tmpFilterParameter[key])
          }
          // this.$route.query には文字列型で入ってるので、boolean型にしたいものは変換
          if (tmpFilterParameter[key] === 'true') {
            tmpFilterParameter[key] = true
          }
          if (tmpFilterParameter[key] === 'false') {
            tmpFilterParameter[key] = false
          }
        }
      }
      this.$store.dispatch('jobs/updateFilter', tmpFilterParameter)
    },
    /**
     * フィルタリング条件の適用
     */
    setFilterParameter () {
      this.changeQuery('filter', this.preparedFilters)
    },
    /**
     * フィルタリング条件の初期化
     */
    resetFilterParameter () {
      this.preparedFilters = []
      this.setFilterParameter()
    },
  },
  filters: {
    hideSeconds (str) {
      if (!str || str.length !== 8) return ''
      return str.slice(0, 5)
    },
  },
}
</script>

<style lang="scss" scoped>
.sub_column2 {
  width: 5em;
}
.sub_column3 {
  width: 6em;
}
</style>
