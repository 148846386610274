<template>
  <div>
    <!-- v10.0.0 -->
    <h4 id="v10.0.0">v10.0.0 (2020/10/06~)</h4>
    <h5>全般</h5>
    <ul>
      <li>軽微な不具合を修正しました</li>
    </ul>
    <h5>セグメント一覧</h5>
    <ul>
      <li>パフォーマンスを改善しました。</li>
    </ul>
    <h5>セグメント設定</h5>
    <ul>
      <li>コンバージョン設定が絞り込み条件として設定可能になりました。</li>
      <li>いくつかの不具合を修正しました。</li>
    </ul>
    <h5>ジョブ設定</h5>
    <ul>
      <li>タスク設定のレイアウトを一部修正しました。</li>
    </ul>
    <h5>マスタ一覧</h5>
    <ul>
      <li>いくつかの不具合を修正しました。</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'version10',
  minorVersions: [
    'v10.0.0',
  ],
}
</script>
