<template>
  <v-footer v-show="showSummary" class="px-0 py-1 mb-n3" absolute>
    <v-container fluid>
      <v-row v-if="loading">
        <!-- 読み込み中 -->
        <v-col class="text-center">
          <v-progress-circular indeterminate color="primary"/>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="4">
          <v-list class="pt-0 pb-1">
            <v-list-item class="px-0">
              {{ summaries.session.label }}
              <v-spacer />
              <div class="text-right">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      {{ summaries.session.value }}
                    </span>
                  </template>
                  <span>
                    {{ summaries.session.chipValue }}
                  </span>
                </v-tooltip>
              </div>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="4">
          <v-list class="pt-0 pb-1">
            <v-list-item class="px-0">
              {{ summaries.pvToSession.label }}
              <v-spacer />
              <div class="text-right">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      {{ summaries.pvToSession.value }}
                    </span>
                  </template>
                  <span>
                    {{ summaries.pvToSession.chipValue }}
                  </span>
                </v-tooltip>
              </div>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="4">
          <v-list class="pt-0 pb-1">
            <v-list-item class="px-0">
              {{ summaries.sessionTimeOnSite.label }}
              <v-spacer />
              <div class="text-right">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      {{ summaries.sessionTimeOnSite.value }}
                    </span>
                  </template>
                  <span>
                    {{ summaries.sessionTimeOnSite.chipValue }}
                  </span>
                </v-tooltip>
              </div>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import displayConverter from '@/utils/displayConverter'

export default {
  name: 'UserSummary',
  mixins: [displayConverter],
  props: {
    loading: {
      type: Boolean,
      default () {
        return false
      },
    },
    summaries: {
      type: Object,
    },
    showSummary: {
      type: Boolean,
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/theme.scss';
@include theme(v-footer) using ($integral-core-theme) {
  background-color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'userSummary', 'background');
  box-shadow: 0px -3px 0px map-deep-get($integral-core-theme, 'views', 'userDetail', 'userSummary', 'box-shadow');
  .container {
    .row {
      .col {
        .v-list {
          border-bottom: 1px solid map-deep-get($integral-core-theme, 'views', 'userDetail', 'userSummary', 'border');
          .v-list-item {
            color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'userSummary', 'text') !important;
          }
        }
      }
    }
  }
}

.summary_text {
  padding-top: 0;
}
.v-list {
  background-color: transparent;
  .v-list-item {
    min-height: 19px;
    line-height: 19px;
  }
}
</style>
