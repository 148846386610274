<template>
  <div>
    <h3 class="mt-6 lead-line">統合前データ</h3>
    <v-card outlined tile>
      <v-card-text>
        <v-data-table
          id="scvUnionTable"
          class="item-inPageScroll_x"
          v-model="computedTargetScv"
          :headers="modTableHeaders"
          :items="scvs"
          :loading="loading"
          hide-default-footer
          disable-pagination
          disable-sort
          show-select
          item-key="scvId"
        >
          <template #body="{ items }">
            <tbody>
              <tr v-if="items.length === 0">
                <td>{{$t('text.scvUnion.noCustomer')}}</td>
              </tr>
              <tr v-for="item in items" :key="item.scvId">
                <!-- 統合対象チェックボックス -->
                <td class="text-center checkBox">
                  <v-checkbox
                    v-if="!item.uniond"
                    v-model="computedTargetScv"
                    class="mt-0"
                    :value="item"
                    color="default"
                    hide-details
                  />
                  <v-tooltip v-else bottom>
                    <template #activator="{ on }">
                      <v-checkbox
                        v-on="on"
                        readonly
                        off-icon="mdi-square-off-outline"
                        color="default"></v-checkbox>
                    </template>
                    <span class="break-all">
                      {{ $t('text.scvUnion.alreadySet') }}
                    </span>
                  </v-tooltip>
                </td>
                <!-- メインのSCVを選択 -->
                <td class="radio">
                  <v-radio-group
                    v-model="computedDefaultScv"
                    class="mt-0"
                    name="defaultScv"
                    hide-details
                    @change="defaultChanged">
                    <v-radio :value="item.scvId" color="inputSelectionControl" />
                  </v-radio-group>
                </td>
                <!-- カスタマー詳細表示 -->
                <td class="detail">
                  <v-btn small @click="openDetail(item.scvId)">{{ $t('text.scvUnion.detail') }}</v-btn>
                </td>
                <td v-for="(scvColumn, index) in tableHeaders" :key="scvColumn.scvColumnId">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn-toggle
                        v-model="computedSelectedColumns[index]"
                        tile
                        group
                        dense
                        style="width: 100%;"
                      >
                        <v-btn text :value="item.scvId" class="text-none justify-start" style="width: 100%;" v-on="on">
                          {{ item.scvColumnValueList | getColumn(scvColumn.value) | ellipsis(30,'...') }}
                        </v-btn>
                      </v-btn-toggle>
                    </template>
                    <span class="break-all">
                      {{ item.scvColumnValueList | getColumn(scvColumn.value) }}
                    </span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <h3 class="mt-9 lead-line">統合後データ</h3>
    <v-card outlined tile>
      <v-card-text>
        <v-data-table
          class="item-inPageScroll_x"
          :headers="afterUnionTableHeaders"
          hide-default-footer
          disable-pagination
          disable-sort
        >
          <template #body>
            <tbody>
              <tr>
                <!-- 統合対象チェックボックス -->
                <td class="checkBox">
                </td>
                <!-- メインのSCVを選択 -->
                <td class="radio">
                </td>
                <!-- カスタマー詳細表示 -->
                <td class="detail">
                </td>
                <td v-for="(scvColumn) in tableHeaders" :key="scvColumn.scvColumnId">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <div class="px-1">
                        <span class="unionedScvValue" v-on="on">
                          {{ unionedScv.scvColumnValueList | getColumn(scvColumn.value) | ellipsis(30,'...') }}
                        </span>
                      </div>
                    </template>
                    <span class="break-all">
                      {{ unionedScv.scvColumnValueList | getColumn(scvColumn.value) }}
                    </span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- カスタマー詳細 -->
    <v-navigation-drawer
      v-model="showDetailUserId"
      width="80%"
      app
      fixed
      temporary
      right
    >
      <information-bar>
        <template #left>
          <v-btn @click="showDetailUserId = false">
            {{ $t(`btn.close`) }}
          </v-btn>
        </template>
        <template #right>
          <v-btn class="mx-5" icon x-small :to="{ name: 'UserScvDetail', params: { scvId: showDetailUserId } }" target="_blank"><v-icon>mdi-open-in-new</v-icon></v-btn>
        </template>
      </information-bar>

      <template v-if="showDetailUserId">
        <user-scv-detail
          :scvId="showDetailUserId" />
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
// util
import userScvDetail from '@/views/userScvDetail'

export default {
  name: 'scvUnionTable',
  components: {
    'user-scv-detail': userScvDetail,
  },
  props: {
    tableHeaders: {
      type: Array,
      default () {
        return []
      },
    },
    scvs: {
      type: Array,
      default () {
        return []
      },
    },
    defaultScv: {
      type: String,
      default () {
        return undefined
      },
    },
    targetScv: {
      type: Array,
      default () {
        return []
      },
    },
    selectedColumns: {
      type: Array,
      default () {
        return []
      },
    },
    loading: {
      type: Boolean,
      default () {
        return false
      },
    },
  },
  data () {
    return {
      showDetailUserId: false,
    }
  },
  watch: {
    selectedColumns (newVal) {
      newVal.forEach(scvId => {
        // その SCV がチェックボックスで選択されてなかったら選択する
        if (scvId !== undefined && !this.computedTargetScv.find(scv => scv && scv.scvId === scvId)) {
          this.computedTargetScv.push(this.scvs.find(scv => scv.scvId === scvId))
        }
      })
    },
  },
  computed: {
    afterUnionTableHeaders () {
      const tmpTableHeaders = JSON.parse(JSON.stringify(this.tableHeaders))
      tmpTableHeaders.unshift({ text: '', value: '' }, { text: '', value: '' }, { text: '', value: '' })
      return tmpTableHeaders
    },
    modTableHeaders () {
      const tmpTableHeaders = JSON.parse(JSON.stringify(this.tableHeaders))
      tmpTableHeaders.unshift({ text: this.$t('text.scvUnion.default'), value: '', align: 'center' }, { text: this.$t('text.scvUnion.detail'), value: '', align: 'center' })
      return tmpTableHeaders
    },
    computedDefaultScv: {
      get () {
        return this.defaultScv
      },
      set (newVal) {
        this.$emit('update:defaultScv', newVal)
      },
    },
    computedTargetScv: {
      get () {
        return this.targetScv
      },
      set (newVal) {
        this.$emit('update:targetScv', newVal)
      },
    },
    computedSelectedColumns: {
      get () {
        return this.selectedColumns
      },
      set (newVal) {
        this.$emit('update:selectedColumns', newVal)
      },
    },
    unionedScv () {
      const scvColumnValueList = []
      this.tableHeaders.forEach((column, index) => {
        const selectedScv = this.scvs.find(scv => scv.scvId === this.computedSelectedColumns[index])
        if (!selectedScv) return

        const scvColumnValue = selectedScv.scvColumnValueList.find(scvColumn => scvColumn.scvColumnId === Number(column.value))
        if (!scvColumnValue) return
        scvColumnValueList.push({ scvColumnId: column.value, scvColumnValue: scvColumnValue.scvColumnValue })
      })
      const unionedScv = { scvColumnValueList: scvColumnValueList }
      return unionedScv
    },
  },
  methods: {
    openDetail (scvId) {
      this.showDetailUserId = scvId
    },
    defaultChanged (scvId) {
      if (!this.computedTargetScv.find(scv => scv && scv.scvId === scvId)) {
        this.computedTargetScv.push(this.scvs.find(scv => scv.scvId === scvId))
      }
      const selectedColumns = []
      this.tableHeaders.forEach(column => {
        selectedColumns.push(scvId)
      })
      this.computedSelectedColumns = selectedColumns
    },
  },
  filters: {
    getColumn (scvColumnValueList, scvColumnId) {
      const column = scvColumnValueList.find(scvColumn => scvColumn.scvColumnId === Number(scvColumnId))
      if (!column) return null
      return column.scvColumnValue
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/theme.scss';

@include theme(v-data-table) using ($integral-core-theme) {
  &#scvUnionTable {
    .v-btn {
      &.v-btn--active {
        background-color: map-deep-get($integral-core-theme, 'views', 'scvUnion', 'button-active') !important;
      }
    }
    &::v-deep .v-data-table__checkbox {
      .v-input--selection-controls__input {
        .v-icon {
          color: map-get($integral-core-theme, 'selection-controls', 'icon');
        }
      }
    }
  }
}
.v-data-table {
  &::v-deep .v-input--selection-controls__input {
    margin-right: 0;
  }
}
.unionedScvValue {
  margin: 4px;
  padding: 0 8px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  white-space: nowrap;
}
.v-data-table {
  &::v-deep .v-data-table__wrapper {
    table {
      thead.v-data-table-header {
        white-space: nowrap;
        tr {
          th.text-start {
            span {
              padding-left: 18px;
            }
          }
        }
      }
      tbody {
        tr {
          td.checkBox {
            width: 48px;
            min-width: 48px;
          }
          td.radio {
            width: 48px;
            min-width: 48px;
          }
          td.detail {
            width: 69.22px;
            min-width: 69.22px;
          }
        }
      }
    }
  }
}
</style>
