export default {
  data () {
    return {
      JOB_TASK_TYPES: {
        IO: { text: '入出力', value: 1 },
        SQL: { text: 'SQL', value: 2 },
      },
    }
  },
}
