import Vue from 'vue'
import VueCookie from 'vue-cookie'
import axios from 'axios'
Vue.use(VueCookie)

const baseUrl = '//' + document.location.href.match(/\/\/([^:/]+)/)[1] + '/api'

const http = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

http.interceptors.request.use(function (req) {
  req.headers.common['X-CSRFToken'] = Vue.cookie.get('RESPONSE_TOKEN')
  return req
}, function (error) {
  return Promise.reject(error)
})

export default http
