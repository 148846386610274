<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn color="primary" :to="{ name: 'DataSetAdd' }" :disabled="!isAllAuthorizedThis('DataSetAdd')">
          {{ $t(`btn.dataSet.create`) }}
        </v-btn>
      </template>
      <template #right>
        <v-btn @click="fetchDataSets" :loading="loadingDataSets"><v-icon>ic-reload</v-icon></v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <!-- sort -->
      <v-row justify="space-between" class="my-0">
        <v-col cols="4">
          <v-select
            class="sort pt-0"
            hide-details
            v-model="orderBy"
            :items="sortableColumns"
            :disabled="loadingDataSets"
          >
            <template #prepend>
              <span class="prepend-sort-text">
                {{ $t('text.sort') }}
              </span>
            </template>
            <template #append-outer>
              <v-btn icon @click="isDesc = !isDesc" :disabled="loadingDataSets">
                <v-icon v-if="isDesc">ic-sort-desc</v-icon>
                <v-icon v-else>ic-sort-asc</v-icon>
              </v-btn>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-progress-linear v-if="loadingDataSets" indeterminate color="primary" />
      <v-card v-if="!loadingDataSets && dataSets.length === 0" outlined tile><v-card-text>{{$t('text.noData')}}</v-card-text></v-card>
      <large-list
        v-for="dataSet in dataSets"
        :key="dataSet.dataSetId"
        :name="dataSet.dataSetName"
        :note="dataSet.note"
        :to="'/dataSet/setting/' + dataSet.dataSetId"
      >
        <!-- 登録データソース数 -->
        <template #col2>
          <v-row no-gutters dense>
            <v-col xl="8" lg="9" md="10" sm="12">{{ $t('text.dataSets.dataSourceCount') }}&#58;&nbsp;</v-col>
            <v-col cols="2" class="text-right">{{ dataSet.masterCount + dataSet.transactionCount }}</v-col>
          </v-row>
          <v-row no-gutters dense>
            <v-col xl="8" lg="9" md="10" sm="12">∟ {{ $t('text.dataSets.master') }}&#58;&nbsp;</v-col>
            <v-col cols="2" class="text-right">{{ dataSet.masterCount }}</v-col>
          </v-row>
          <v-row no-gutters dense>
            <v-col xl="8" lg="9" md="10" sm="12">∟ {{ $t('text.dataSets.transaction') }}&#58;&nbsp;</v-col>
            <v-col cols="2" class="text-right">{{ dataSet.transactionCount }}</v-col>
          </v-row>
        </template>
        <!-- 作成日・更新日 -->
        <template #col3>
          {{ $t('text.createdAt') }}&#58;&nbsp;{{dataSet.createdAt}}
          <br>
          {{ $t('text.updatedAt') }}&#58;&nbsp;{{dataSet.updatedAt}}
        </template>
      </large-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// components
import LargeList from '@/components/common/largeList'
// enums
import DATA_SOURCE_TYPES from '@/enum/DATA_SOURCE_TYPES'
// util
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'DataSets',
  components: {
    LargeList,
  },
  mixins: [
    DATA_SOURCE_TYPES,
    notifyUtil,
  ],
  data () {
    return {
      sortableColumns: [
        { text: '作成日時', value: 'createdAt' },
        { text: '更新日時', value: 'updatedAt' },
        { text: '名前', value: 'dataSetName' },
      ],
      dataSets: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['isAllAuthorizedThis']),
    ...mapGetters({ queryParams: 'dataSets/getQueryParams' }),
    loadingDataSets () {
      return this.$store.state.dataSets.loadingDataSets
    },
    orderBy: {
      get () {
        return this.$store.state.dataSets.sortParameter.orderBy
      },
      set (newVal) {
        this.changeQuery('orderBy', newVal)
      },
    },
    isDesc: {
      get () {
        return this.$store.state.dataSets.sortParameter.isDesc
      },
      set (newVal) {
        this.changeQuery('isDesc', newVal)
      },
    },
  },
  created () {
    const tmpQuery = {}
    for (const key in this.$route.query) {
      tmpQuery[key] = this.$route.query[key]
    }

    // クエリが不十分な場合補う
    let isQuerySufficient = true
    for (const key in this.queryParams) {
      if (tmpQuery[key] === null || tmpQuery[key] === undefined) {
        tmpQuery[key] = this.queryParams[key]
        isQuerySufficient = false
      }
    }

    // orderByに指定された文字列がソート基準の選択肢にあるか、もしくはdataSetIdなら問題ない
    const validateOrderBy = this.sortableColumns.some((column) => tmpQuery.orderBy === column.value)
    if (!validateOrderBy && tmpQuery.orderBy !== 'dataSetId') {
      tmpQuery.orderBy = 'createdAt'
      isQuerySufficient = false
    }
    if (tmpQuery.isDesc !== true && tmpQuery.isDesc !== false && tmpQuery.isDesc !== 'true' && tmpQuery.isDesc !== 'false') {
      tmpQuery.isDesc = true
      isQuerySufficient = false
    }

    // クエリを変更した場合はURLを置き換える
    if (!isQuerySufficient) {
      this.$router.replace({ query: tmpQuery })
        .catch(err => err) // 画面表示時の NavigationDuplicated 対策
      // 置き換わるとbeforeRouteUpdateが呼び出されるのでこちらの処理は中断する
      return
    }

    this.reflectQueryParamsToState(tmpQuery)
    this.fetchDataSets()
  },
  beforeRouteUpdate (to, from, next) {
    // ルート変更に反応する
    if (to.query !== from.query) {
      this.reflectQueryParamsToState(to.query)
      this.fetchDataSets()
    }
    next()
  },
  methods: {
    changeQuery (type, value) {
      const queryParams = this.queryParams

      queryParams[type] = value

      // クエリパラメーター更新
      // クエリパラメーターを更新するとbeforeRouteUpdateが呼び出される
      this.$router.push({ query: queryParams })
        .catch(err => err) // 画面表示時の NavigationDuplicated 対策
    },
    fetchDataSets () {
      this.$store.dispatch('dataSets/getDataSets')
        .then((res) => {
          this.dataSets = res
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    reflectQueryParamsToState (query) {
      this.$store.dispatch('dataSets/updateOrderBy', query.orderBy)
      this.$store.dispatch('dataSets/updateIsDesc', JSON.parse(query.isDesc))
    },
  },
}
</script>
