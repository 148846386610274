var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"mb-8",attrs:{"disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":"","headers":_vm.tableHeaders,"items":_vm.jobHistories,"loading":_vm.loading,"fixed-header":""},scopedSlots:_vm._u([{key:"item.jobName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.jobName)+" ")]}},{key:"item.jobSettingId",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","to":{ name: 'JobSettingEdit', params: { jobId: item.jobSettingId }}}},[_vm._v(_vm._s(_vm.$t('text.jobHistories.detail')))])]}},{key:"item.jobResult",fn:function(ref){
var item = ref.item;
return [(item.jobResult === _vm.JOB_EXEC_STATUS.WAIT.value)?_c('v-chip',{attrs:{"readonly":"","color":"pending"}},[_vm._v(" "+_vm._s(_vm.enumUtil.getEnumFromValue(_vm.JOB_EXEC_STATUS, item.jobResult).text)+" ")]):(item.jobResult === _vm.JOB_EXEC_STATUS.EXECUTING.value)?_c('v-chip',{attrs:{"readonly":"","color":"ongoing"}},[_vm._v(" "+_vm._s(_vm.enumUtil.getEnumFromValue(_vm.JOB_EXEC_STATUS, item.jobResult).text)+" ")]):(item.jobResult === _vm.JOB_EXEC_STATUS.SUCCESS.value)?_c('v-chip',{attrs:{"readonly":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.enumUtil.getEnumFromValue(_vm.JOB_EXEC_STATUS, item.jobResult).text)+" ")]):(item.jobResult === _vm.JOB_EXEC_STATUS.FAIL.value)?_c('v-chip',{attrs:{"readonly":"","color":"fault"}},[_vm._v(" "+_vm._s(_vm.enumUtil.getEnumFromValue(_vm.JOB_EXEC_STATUS, item.jobResult).text)+" ")]):_vm._e()]}},{key:"item.jobMessage",fn:function(ref){
var item = ref.item;
return [(item.taskMessage)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(item.jobTaskName)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("ellipsis")(item.taskMessage,20,'...'))+" ")])]}}],null,true)},[_c('div',{staticClass:"break-all"},[_vm._v(" "+_vm._s(item.jobTaskName)+" "),_c('br'),_vm._v(" "+_vm._s(item.taskMessage)+" ")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("ellipsis")(item.jobMessage,20,'...'))+" ")])]}}],null,true)},[_c('span',{staticClass:"break-all"},[_vm._v(" "+_vm._s(item.jobMessage)+" ")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }