export default {
  data () {
    return {
      TRANSACTION_SUMMARY_TYPE: {
        COUNT: { text: '重複あり', value: 1 },
        UNIQUE_VALUES_COUNT: { text: '重複なし', value: 2 },
        SUM: { text: '合計', value: 3 },
        MAX: { text: '最大', value: 4 },
        AVERAGE: { text: '平均', value: 5 },
        MOST_FREQUENT: { text: 'よく使われる値', value: 6 },
        FIRST_VALUE: { text: '初回入力値', value: 7 },
        LAST_VALUE: { text: '最新入力値', value: 8 },
        FIRST_TIMESTAMP: { text: '初回ログ発生日時', value: 9 },
        LAST_TIMESTAMP: { text: '最新ログ発生日時', value: 10 },
      },
    }
  },
}
