export default {
  data () {
    return {
      NEW_RULE: {
        filterColumn: {
          filterType: null,
          filterPhysicalName: '',
          filterLogicalName: '',
          filterValue: '',
          operatorType: null,
          logicalNot: false,
          dataType: null,
        },
      },
      NEW_INTERNAL_FILTER_RULE: {
        internalFilterType: null,
        filterPhysicalName: '',
        filterLogicalName: '',
        filterValue: '',
        operatorType: 7, // フェーズ2では固定値
        dataType: null,
      },
      NEW_GROUP: {
        isGroup: true,
        depth: 1,
        periodType: 1,
        startDate: '',
        endDate: '',
        relativeDayFrom: '',
        relativeDayTo: '',
        dayOfWeek: [],
        dayOfMonth: [],
        logicalOperatorType: 1,
        filterRuleType: null,
        children: [],
      },
      addFilterRule () {
        return JSON.parse(JSON.stringify(this.NEW_RULE))
      },
      addInternalFilterRule () {
        return JSON.parse(JSON.stringify(this.NEW_INTERNAL_FILTER_RULE))
      },
      addFilterRuleGroup () {
        const tmpNewGroup = JSON.parse(JSON.stringify(this.NEW_GROUP))
        tmpNewGroup.children.push(JSON.parse(JSON.stringify(this.NEW_RULE)))
        return tmpNewGroup
      },
      addEmptyFilterRuleGroup () {
        const tmpNewGroup = JSON.parse(JSON.stringify(this.NEW_GROUP))
        tmpNewGroup.filterRuleType = 9 // GROUP
        return tmpNewGroup
      },
    }
  },
}
