import axios from '@/axios'

const dataSet = {
  namespaced: true,
  state: {
    loadingDataSet: false,
    loadingDataSources: false,
  },
  mutations: {
    setLoadingDataSet (state, newVal) {
      state.loadingDataSet = newVal
    },
    setLoadingDataSources (state, newVal) {
      state.loadingDataSources = newVal
    },
  },
  actions: {
    async getDataSet ({ commit }, dataSetId) {
      commit('setLoadingDataSet', true)
      const res = await axios.get('/dataSet/' + dataSetId + '/')
        .finally(() => {
          commit('setLoadingDataSet', false)
        })
      return res.data.data
    },
    /**
     * stateに保存せずデータソース一覧を返却
     */
    getDataSources ({ commit }, dataSetId) {
      commit('setLoadingDataSources', true)
      return axios.get('/dataSet/' + dataSetId + '/dataSources/')
        .finally(() => {
          commit('setLoadingDataSources', false)
        })
    },
    getDataSetLinkRule ({ commit }, dataSetId) {
      return axios.get('/dataSet/' + dataSetId + '/dataSetRowCreateRule/')
    },
    postDataSet ({ commit }, parameter) {
      return axios.post('/dataSet/', {
        dataSetName: parameter.dataSetName,
        note: parameter.note,
      })
    },
    putDataSet ({ commit }, parameter) {
      return axios.put('/dataSet/' + parameter.dataSetId + '/', {
        dataSetName: parameter.dataSetName,
        note: parameter.note,
      })
    },
    deleteDataSet ({ commit }, dataSetId) {
      return axios({ method: 'delete', url: '/dataSet/' + dataSetId + '/' })
    },
  },
}
export default dataSet
