import router from '@/router'
import axios from '@/axios'

const auth = {
  namespaced: true,
  state: {
    loginUserDetail: {
      loginUserId: '',
      loginUserName: '',
      loginUserAddress: '',
      passwordChangedFlg: true,
      mfaRequired: false,
      mfaDeviceRegistered: false,
      mfaAuthenticated: false,
      authority: {
        dataSet: {
          getAuthorized: false,
          postAuthorized: false,
          putAuthorized: false,
          deleteAuthorized: false,
        },
        scvSetting: {
          getAuthorized: false,
          postAuthorized: false,
          putAuthorized: false,
          deleteAuthorized: false,
        },
        customer: {
          getAuthorized: false,
          postAuthorized: false,
          putAuthorized: false,
          deleteAuthorized: false,
        },
        segment: {
          getAuthorized: false,
          postAuthorized: false,
          putAuthorized: false,
          deleteAuthorized: false,
        },
        jobs: {
          getAuthorized: false,
          postAuthorized: false,
          putAuthorized: false,
          deleteAuthorized: false,
        },
        accounts: {
          getAuthorized: false,
          postAuthorized: false,
          putAuthorized: false,
          deleteAuthorized: false,
        },
        role: {
          getAuthorized: false,
          postAuthorized: false,
          putAuthorized: false,
          deleteAuthorized: false,
        },
        segmentExportScript: {
          getAuthorized: false,
          postAuthorized: false,
          putAuthorized: false,
          deleteAuthorized: false,
        },
      },
    },
    loggingIn: false,
    totpVerifying: false,
    loadingLoginUser: false,
  },
  mutations: {
    setLoginUser (state, data) {
      state.loginUserDetail.loginUserId = data.loginUserId
      state.loginUserDetail.loginUserName = data.loginUserName
      state.loginUserDetail.loginUserAddress = data.loginUserEmailAddress
      state.loginUserDetail.loginUserWebWorkspaceName = data.loginUserWebWorkspaceName
      state.loginUserDetail.passwordChangedFlg = data.passwordChangedFlg
      state.loginUserDetail.mfaRequired = data.mfaRequired
      state.loginUserDetail.mfaDeviceRegistered = data.mfaDeviceRegistered
      state.loginUserDetail.mfaAuthenticated = data.mfaAuthenticated
    },
    setAuthority (state, data) {
      state.loginUserDetail.authority.dataSet = data.dataSet
      state.loginUserDetail.authority.scvSetting = data.scvSetting
      state.loginUserDetail.authority.customer = data.customer
      state.loginUserDetail.authority.segment = data.segment
      state.loginUserDetail.authority.jobs = data.jobs
      state.loginUserDetail.authority.accounts = data.accounts
      state.loginUserDetail.authority.role = data.role
      state.loginUserDetail.authority.segmentExportScript = data.segmentExportScript
    },
    setLoggingIn (state, newVal) {
      state.loggingIn = newVal
    },
    setTotpVerifying (state, newVal) {
      state.totpVerifying = newVal
    },
    setLoadingLoginUser (state, newVal) {
      state.loadingLoginUser = newVal
    },
  },
  getters: {
    // 渡された対象の権限範囲に false が一つも含まれていなければ true
    isAllAuthorized: (state) => (targetAuthority) => {
      return Object.entries(targetAuthority).findIndex(([key, value]) => {
        // API のバージョン都合等で該当権限が含まれていない場合は true を返しておく (findeIndex は return が true の場合に該当の index 返し、全て false や undefined だと -1 を返すため)
        return (!state.loginUserDetail.authority[key]) || value.map((crud) => state.loginUserDetail.authority[key][crud]).includes(false)
      }) < 0
    },
    isAllAuthorizedThis: (state, getters, rootState) => (name) => {
      const route = router?.options?.routes?.find(route => route.name === name)
      if (route === undefined) {
        // eslint-disable-next-line no-console
        console.error('router.js に対象のルーティング情報が定義されていません. name = ' + name)
        return false
      }
      if (route.meta?.requiredAuthority === undefined) {
        // 権限を要求しない画面
        return true
      }
      return getters.isAllAuthorized(route.meta.requiredAuthority)
    },
    canDoSomething: (state, getters, rootState) => (crud) => {
      if (rootState.route?.meta?.requiredAuthority === undefined) return true
      const targetAuthority = Object.keys(rootState.route.meta.requiredAuthority).reduce((after, key) => ({
        ...after, [key]: [crud],
      }), {})
      return getters.isAllAuthorized(targetAuthority)
    },
    // 表示中画面の表示権限の管理対象に getAuthorized: false が一つも含まれていなければ true
    canGet: (state, getters, rootState) => {
      return getters.canDoSomething('getAuthorized')
    },
    // 表示中画面の表示権限の管理対象に getAuthorized: false が一つも含まれていなければ true
    canPost: (state, getters, rootState) => {
      return getters.canDoSomething('postAuthorized')
    },
    // 表示中画面の表示権限の管理対象に getAuthorized: false が一つも含まれていなければ true
    canPut: (state, getters, rootState) => {
      return getters.canDoSomething('putAuthorized')
    },
    // 表示中画面の表示権限の管理対象に getAuthorized: false が一つも含まれていなければ true
    canDelete: (state, getters, rootState) => {
      return getters.canDoSomething('deleteAuthorized')
    },
    canDoSomethingThis: (state, getters, rootState) => (name, crud) => {
      const route = router?.options?.routes?.find(route => route.name === name)
      if (route?.meta?.requiredAuthority === undefined) return true
      const targetAuthority = Object.keys(route.meta.requiredAuthority).reduce((after, key) => ({
        ...after, [key]: [crud],
      }), {})
      return getters.isAllAuthorized(targetAuthority)
    },
    // 画面の表示権限の管理対象に getAuthorized: false が一つも含まれていなければ true
    canGetThis: (state, getters, rootState) => (name) => {
      return getters.canDoSomethingThis(name, 'getAuthorized')
    },
    // 画面の表示権限の管理対象に postAuthorized: false が一つも含まれていなければ true
    canPostThis: (state, getters, rootState) => (name) => {
      return getters.canDoSomethingThis(name, 'postAuthorized')
    },
    // 画面の表示権限の管理対象に putAuthorized: false が一つも含まれていなければ true
    canPutThis: (state, getters, rootState) => (name) => {
      return getters.canDoSomethingThis(name, 'putAuthorized')
    },
    // 画面の表示権限の管理対象に deleteAuthorized: false が一つも含まれていなければ true
    canDeleteThis: (state, getters, rootState) => (name) => {
      return getters.canDoSomethingThis(name, 'deleteAuthorized')
    },
  },
  actions: {
    /**
     * ログインユーザー情報を取得
     */
    getLoginUser ({ commit, state }) {
      commit('setLoadingLoginUser', true)
      return axios.get('/login/user/').then((res) => { commit('setLoginUser', res.data.data) })
        .finally(() => {
          commit('setLoadingLoginUser', false)
        })
    },
    /**
     * ログイン処理
     * @param  {Object} requestParameter リクエストパラメーター
     */
    login ({ commit, state, rootState }, requestParameter) {
      commit('setLoggingIn', true)
      return axios.post('/login/', {
        emailAddress: requestParameter.emailAddress,
        password: requestParameter.password,
        companyHash: requestParameter.companyHash,
      }).then(() => {
        // ログイン後にユーザー情報を取得し、初期パスワードの場合はパスワード変更画面へ遷移
        axios.get('/login/user/').then((res) => {
          commit('setLoginUser', res.data.data)
          if (state.loginUserDetail.mfaRequired && !state.loginUserDetail.mfaDeviceRegistered) {
            // MFA 必須でデバイス未登録なのでデバイス登録画面 (QRコード表示) へ
            router.push({ name: 'TotpRegister' })
          } else if (state.loginUserDetail.mfaRequired && state.loginUserDetail.mfaDeviceRegistered && !state.loginUserDetail.mfaAuthenticated) {
            // MFA 必須でデバイス登録済みで MFA 未認証なのでワンタイムパスワード入力画面へ
            router.push({ name: 'TotpLogin' })
          } else if (!state.loginUserDetail.passwordChangedFlg) {
            // パスワード変更が必要なのでパスワード変更画面へ
            router.push({ name: 'PasswordChange' })
          } else if (rootState.route.query.redirect && router.resolve(rootState.route.query.redirect).resolved.name !== 'PageNotFound') {
            // ログイン完了なので、リダイレクト先が見つかればその画面を開く
            router.push({ name: rootState.route.query.redirect })
          } else {
            // リダイレクト先が見つからなければリリースノートを開く
            router.push({ name: 'Release' })
          }
        })
      }).finally(() => {
        commit('setLoggingIn', false)
      })
    },
    /**
     * ログアウト処理
     */
    logout ({ commit }) {
      axios.get('/logout/').then((res) => {
        router.push({ name: 'Login' }, () => {})
        location.reload() // store内で保持している情報を初期化するためリロード
      })
    },
    /**
     * 権限確認リクエスト
     */
    checkAuth ({ commit }) {
      return axios.get('/login/').then((res) => {
        commit('setAuthority', res.data.data.authority)
      })
    },
    /**
     * MFA (TOTP) 用の秘密鍵を取得
     */
    getTotpSeacretKey ({ commit, state }) {
      // 無ければ生成するので PUT
      return axios.put('/authentication/totp/publishKey/')
    },
    /**
     * TOTP 検証処理
     * @param  {Object} requestParameter リクエストパラメーター
     */
    totpVerify ({ commit, state, rootState }, requestParameter) {
      commit('setTotpVerifying', true)
      return axios.post('/authentication/totp/verify/', {
        token: requestParameter.oneTimePassword,
      }).then(() => {
        // ログイン後にユーザー情報を取得し、初期パスワードの場合はパスワード変更画面へ遷移
        axios.get('/login/user/').then((res) => {
          commit('setLoginUser', res.data.data)
          if (!state.loginUserDetail.passwordChangedFlg) {
            // パスワード変更が必要なのでパスワード変更画面へ
            router.push({ name: 'PasswordChange' })
          } else if (rootState.route.query.redirect && router.resolve(rootState.route.query.redirect).resolved.name !== 'PageNotFound') {
            // ログイン完了なので、リダイレクト先が見つかればその画面を開く
            router.push({ name: rootState.route.query.redirect })
          } else {
            // リダイレクト先が見つからなければリリースノートを開く
            router.push({ name: 'Release' })
          }
        })
      }).finally(() => {
        commit('setTotpVerifying', false)
      })
    },
    unregisterDevice ({ commit, state, rootState }, loginUserId) {
      return axios.delete('/account/user/' + loginUserId + '/authentication/totp/')
    },
    unregisterMyDevice ({ commit, state, rootState }) {
      return axios.delete('/authentication/totp/')
    },
  },
}

export default auth
