<template>
  <v-container fluid>
    <v-row>
      <v-col v-if="isActionHistorySearching" class="text-center" cols="12">
        <v-progress-circular indeterminate color="primary"/>
      </v-col>
      <!-- 行動履歴0件 -->
      <v-col v-else-if="actionHistories.length === 0" cols="12">
        <p>{{ $t(`text.userDetail.noActionHistories`) }}</p>
      </v-col>
      <!-- 行動履歴リスト -->
      <v-col v-else cols="12">
        <v-row>
          <!-- セッション -->
          <v-col cols="4">
            <div class="mb-3 pr-2 item-inPageScroll_y">
              <v-card outlined flat tile>
                <v-list two-line subheader dense class="pb-0">
                  <template v-for="(actionHistory, index) in actionHistories">
                    <v-list-item
                      class="pl-0"
                      :key="index + '-sessionId' + actionHistory.sessionId"
                      @click="selectedHistoryIndex = index"
                      :input-value="index == selectedHistoryIndex"
                    >
                      <v-list-item-avatar>
                        <v-icon>mdi-desktop-mac</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="py-3">
                        <v-list-item-title>{{ actionHistory.firstAccessDateTime }}</v-list-item-title>
                        <v-list-item-title class="name">{{ actionHistory.siteName }}</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ $t(`text.userDetail.pvCnt`) }}{{ $t(`text.delimiter`) }}{{ actionHistory.pv }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="index < actionHistories.length - 1" :key="index"/>
                  </template>
                </v-list>
              </v-card>
            </div>
            <v-pagination :value="page" @input="changePage" :length="pages" total-visible="7"/>
          </v-col>
          <!-- セッション詳細 -->
          <v-col cols="8" class="pl-0">
            <div v-if="selectedHistory" class="item-inPageScroll_y">
              <v-timeline align-top dense class="pt-0 mt-1 pr-2">
                <v-timeline-item class="start pb-5">
                  <v-card outlined>
                    <v-card-title class="py-2 px-3">
                      {{ enumUtil.getEnumFromValue(TRAFFIC_TYPES, selectedHistory.trafficType).alias }}
                    </v-card-title>
                  </v-card>
                </v-timeline-item>
                <v-timeline-item
                  class="pb-5"
                  v-for="(action, index) in selectedHistoryactions"
                  :key="index + '-' + action.pageId"
                  :icon="'ic-visibility-S'">
                  <v-card outlined>
                    <v-card-title class="pt-2 px-3">{{ action.pageViewStartTime | filterHHMMSS }}</v-card-title>
                    <v-card-text class="px-3 py-0">{{ action.title }}</v-card-text>
                    <v-card-text class="px-3 pt-0 pb-2 url">{{ action.url }}</v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// enum
import TRAFFIC_TYPES from '@/enum/TRAFFIC_TYPES'
import enumUtil from '@/utils/enumUtil'

export default {
  mixins: [TRAFFIC_TYPES, enumUtil],
  props: {
    actionHistories: {
      type: Array,
      required: true,
      default () {
        return []
      },
    },
    isActionHistorySearching: {
      type: Boolean,
      required: true,
      default: false,
    },
    page: {
      type: Number,
      default () {
        return 1
      },
    },
    paging: {
      type: Object,
      required: true,
      default () {
        return {
          index: 0,
          limit: 0,
          totalCount: 0,
          filteredTotalCount: 0,
        }
      },
    },
  },
  data () {
    return {
      selectedHistoryIndex: 0,
    }
  },
  computed: {
    pages () {
      return Math.ceil(this.paging.filteredTotalCount / this.paging.limit) || 0
    },
    selectedHistory () {
      return this.actionHistories[this.selectedHistoryIndex]
    },
    // TODO reactive な selectedHistory の reverse() 用, API が 昇順 になったら selectedHistory.actions を使えるので不要
    selectedHistoryactions () {
      const history = this.actionHistories[this.selectedHistoryIndex]
      // slice はシャローコピーを返す
      return history.actions.slice().reverse()
    },
  },
  methods: {
    changePage (newVal) {
      this.$emit('changePage', newVal)
    },
  },
  filters: {
    filterHHMMSS (datetime) {
      return datetime ? datetime.slice(11) : ''
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/theme.scss';

// timeline
@include theme(v-timeline) using ($integral-core-theme) {
  &:before {
    background-color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'actionHistory', 'timeline', 'line');
  }
  .v-timeline-item {
    ::v-deep .v-timeline-item__divider .v-timeline-item__dot .v-timeline-item__inner-dot {
      background-color: transparent !important;
    }
    &.start {
      ::v-deep .v-timeline-item__divider .v-timeline-item__dot .v-timeline-item__inner-dot {
        background-color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'actionHistory', 'timeline', 'start-dot', 'background') !important;
      }
    }
    ::v-deep .v-timeline-item__body {
      .v-card {
        color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'actionHistory', 'timeline', 'body', 'title-text');

        .v-card__text.url {
          color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'actionHistory', 'timeline', 'body', 'url-text');
        }
      }
    }
  }
}
// list
@include theme(v-list) using ($integral-core-theme) {
  .v-list-item {
    &.v-list-item--active {
      background-color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'actionHistory', 'list', 'selected', 'background');
      &.v-list-item--link:before {
        background-color: transparent;
      }
      .v-icon {
        color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'actionHistory', 'list', 'selected', 'icon');
      }
      .v-list-item__title, .v-list-item__subtitle {
        color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'actionHistory', 'list', 'selected', 'text');
        &.name {
          color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'actionHistory', 'list', 'selected', 'name-text');
        }
      }
    }
  }
  .v-icon {
    color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'actionHistory', 'list', 'default', 'icon');
  }
  .v-list-item__title, .v-list-item__subtitle {
    color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'actionHistory', 'list', 'default', 'text');
    &.name {
      color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'actionHistory', 'list', 'default', 'name-text');
    }
  }
}

// timeline
.v-timeline {
  &::before {
    left: calc(22px - 1px) !important;
  }
  .v-timeline-item {
    ::v-deep .v-timeline-item__body {
      max-width: calc(100% - 44px);
      margin-top: -4px;
      margin-left: 10px;
      .v-card {
        .v-card__title {
          font-size: 14px;
          line-height: 20px;
          padding-bottom: 6px;
        }
        .v-card__text {
          line-height: 20px;
        }
      }
    }
    ::v-deep .v-timeline-item__divider {
      min-width: 44px;
      .v-timeline-item__dot {
        box-shadow: none;
        height: 28px;
        width: 28px;
        .v-timeline-item__inner-dot {
          height: 20px;
          width: 20px;
          .v-icon {
            font-size: 20px;
          }
        }
      }
    }
  }
}

// list
.v-list-item {
  .v-list-item__avatar {
    margin-left: 2px;
    margin-right: 2px;
    .v-icon {
      font-size: 20px;
    }
  }
  ::v-deep .v-list-item__title {
    font-size: 14px;
    line-height: 18px;
  }
  ::v-deep .v-list-item__subtitle {
    font-size: 12px;
  }
}

.item-inPageScroll_y {
  height: auto;
  max-height: 60vh;
}
</style>
