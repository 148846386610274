import BROWSER_TYPES from '../enum/BROWSER_TYPES'
import DATA_TYPES from '../enum/DATA_TYPES'
import DEVICE_TYPES from '../enum/DEVICE_TYPES'
import INTERNAL_FILTER_TYPES from '../enum/INTERNAL_FILTER_TYPES'
import LOGICAL_OPERATOR_TYPE from '@/enum/LOGICAL_OPERATOR_TYPE'
import OPERATOR_TYPE_WITH_LOGICAL_NOT from '../enum/OPERATOR_TYPE_WITH_LOGICAL_NOT'
import OPERATOR_TYPE from '../enum/OPERATOR_TYPE'
import OS_TYPES from '../enum/OS_TYPES'
import PERIOD_TYPES from '../enum/PERIOD_TYPES'
import moment from 'moment-timezone'
import enumUtil from '../utils/enumUtil'

export default {
  mixins: [
    BROWSER_TYPES,
    DATA_TYPES,
    DEVICE_TYPES,
    INTERNAL_FILTER_TYPES,
    LOGICAL_OPERATOR_TYPE,
    OPERATOR_TYPE,
    OPERATOR_TYPE_WITH_LOGICAL_NOT,
    OS_TYPES,
    PERIOD_TYPES,
    enumUtil,
  ],
  data () {
    return {
      initializeSegmentFiltersText: {
        logicalOperatorType: '',
        filterTextList: [],
      },
      initializeFilterText: {
        period: '',
        filter: '',
      },
    }
  },
  methods: {
    createSegmentFilterText (filterRule) {
      const segmentFiltersText = JSON.parse(JSON.stringify(this.initializeSegmentFiltersText))
      // 各フィルター条件のAND/OR判定
      if (filterRule.logicalOperatorType === this.LOGICAL_OPERATOR_TYPE.AND.value) {
        segmentFiltersText.logicalOperatorType = '以下の条件を全て含む'
      } else if (filterRule.logicalOperatorType === this.LOGICAL_OPERATOR_TYPE.OR.value) {
        segmentFiltersText.logicalOperatorType = '以下の条件のいずれかを含む'
      }
      // 各フィルター条件の文言生成
      filterRule.children.forEach(filter => {
        const filterText = JSON.parse(JSON.stringify(this.initializeFilterText))
        if (Object.prototype.hasOwnProperty.call(filter, 'children')) {
          // グループ条件
          filterText.period = this.createPeriodSentence(filter.periodType, filter.startDate, filter.endDate, filter.relativeDayFrom, filter.relativeDayTo, filter.dayOfMonth, filter.dayOfWeek)
          filterText.internalFilter = this.createInternalFilterRuleSentence(filter)
          filterText.filter = this.createFilterRuleGroupSentence(filter)
        } else {
          // 通常条件
          filterText.period = this.createPeriodSentence(filterRule.periodType, filterRule.startDate, filterRule.endDate, filterRule.relativeDayFrom, filterRule.relativeDayTo, filterRule.dayOfMonth, filterRule.dayOfWeek)
          filterText.internalFilter = this.createInternalFilterRuleSentence(filterRule)
          filterText.filter = this.createFilterRuleSentence(filter)
        }
        segmentFiltersText.filterTextList.push(filterText)
      })
      return segmentFiltersText
    },
    /**
     * 集計期間の文言を生成
     * @param {Number} periodType 期間種別
     * @param {Date}   startDate 絶対開始日付
     * @param {Date}   endDate 絶対終了日付
     * @param {Date}   relativeDayFrom 相対開始日付
     * @param {Date}   relativeDayTo 相対終了日付
     * @param {Array}  dayOfMonth 実行日にち
     * @param {Array}  dayOfWeek 実行曜日
     */
    createPeriodSentence (periodType, startDate, endDate, relativeDayFrom, relativeDayTo, dayOfMonth, dayOfWeek) {
      let periodSentence
      switch (periodType) {
        case this.PERIOD_TYPES.ALL.value:
          periodSentence = '全期間'
          break
        case this.PERIOD_TYPES.TODAY.value:
          periodSentence = '当日'
          break
        case this.PERIOD_TYPES.PREVIOUS_DAY.value:
          periodSentence = '前日'
          break
        case this.PERIOD_TYPES.THIS_MONTH.value:
          periodSentence = '今月'
          break
        case this.PERIOD_TYPES.LAST_MONTH.value:
          periodSentence = '先月'
          break
        case this.PERIOD_TYPES.FOR_1_WEEK.value:
          periodSentence = '直近1週間'
          break
        case this.PERIOD_TYPES.FOR_30_DAYS.value:
          periodSentence = '直近30日'
          break
        case this.PERIOD_TYPES.FOR_90_DAYS.value:
          periodSentence = '直近90日'
          break
        case this.PERIOD_TYPES.CUSTOM.value:
          if (!startDate && !endDate) {
            periodSentence = '全期間'
            break
          }
          if (startDate) {
            periodSentence = moment(startDate.slice(0, 10), 'YYYY-MM-DD').format('YYYY-MM-DD')
          } else {
            periodSentence = ''
          }
          periodSentence += ' 〜 '
          if (endDate) {
            periodSentence += moment(endDate.slice(0, 10), 'YYYY-MM-DD').format('YYYY-MM-DD')
          } else {
            periodSentence += ''
          }
          break
        case this.PERIOD_TYPES.CUSTOM_RELATIVE_PERIOD.value:
          if (!relativeDayFrom && !relativeDayTo) {
            periodSentence = '全期間'
            break
          }
          if (relativeDayFrom) {
            periodSentence = relativeDayFrom + '日前'
          } else {
            periodSentence = ''
          }
          periodSentence += ' 〜 '
          if (relativeDayTo) {
            periodSentence += relativeDayTo + '日前'
          } else {
            periodSentence += ''
          }
          break
        default:
          periodSentence = '-'
      }
      if (dayOfMonth && dayOfMonth.length > 0) {
        periodSentence += ', 月次設定有り'
      }
      if (dayOfWeek && dayOfWeek.length > 0) {
        periodSentence += ', 週次設定有り'
      }
      return periodSentence
    },
    createInternalFilterRuleSentence (group) {
      let internalFilterRule

      // 内部絞り込み条件
      if (group.internalFilterColumn) {
        switch (group.internalFilterColumn.internalFilterType) {
          case this.INTERNAL_FILTER_TYPES.SITE.internalFilterType:
            internalFilterRule = group.internalFilterColumn.filterLogicalName
            break
          case this.INTERNAL_FILTER_TYPES.PAGE.internalFilterType:
            internalFilterRule = group.internalFilterColumn.filterLogicalName
            break
          case this.INTERNAL_FILTER_TYPES.DEVICE_TYPE.internalFilterType:
            internalFilterRule = this.enumUtil.getEnumFromValue(this.DEVICE_TYPES, group.internalFilterColumn.filterValue).text
            break
          case this.INTERNAL_FILTER_TYPES.OS_TYPE.internalFilterType:
            internalFilterRule = this.enumUtil.getEnumFromValue(this.OS_TYPES, group.internalFilterColumn.filterValue).text
            break
          default:
            internalFilterRule = ''
        }
      } else {
        internalFilterRule = ''
      }
      return internalFilterRule
    },
    /**
     * フィルター条件の文言を生成
     * @param {Object} rule フィルター条件
     */
    createFilterRuleSentence (rule) {
      let firstPostPositionalParticle
      let lastPostPositionalParticle
      let internalFilterRule

      // 内部絞り込み条件
      if (rule.internalFilterColumn) {
        switch (rule.internalFilterColumn.internalFilterType) {
          case this.INTERNAL_FILTER_TYPES.SITE.internalFilterType:
          case this.INTERNAL_FILTER_TYPES.PAGE.internalFilterType:
          case this.INTERNAL_FILTER_TYPES.DEVICE_TYPE.internalFilterType:
            internalFilterRule = 'の' + this.enumUtil.getEnumFromValue(this.DEVICE_TYPES, rule.internalFilterColumn.filterValue).text
            break
          case this.INTERNAL_FILTER_TYPES.OS_TYPE.internalFilterType:
            internalFilterRule = 'の' + this.enumUtil.getEnumFromValue(this.OS_TYPES, rule.internalFilterColumn.filterValue).text
            break
          default:
            internalFilterRule = ''
        }
      } else {
        internalFilterRule = ''
      }

      // 演算子別の接続詞
      switch (rule.filterColumn.operatorType) {
        case this.OPERATOR_TYPE.GT.value:
          firstPostPositionalParticle = 'が'
          lastPostPositionalParticle = 'を'
          break
        case this.OPERATOR_TYPE.EQ.value:
        case this.OPERATOR_TYPE.NOT_EQ.value:
          firstPostPositionalParticle = 'が'
          lastPostPositionalParticle = 'と'
          break
        case this.OPERATOR_TYPE.GT_EQ.value:
        case this.OPERATOR_TYPE.LT.value:
        case this.OPERATOR_TYPE.LT_EQ.value:
        case this.OPERATOR_TYPE.PREFIX_MATCH.value:
        case this.OPERATOR_TYPE.BACKWARD_MATCH.value:
        case this.OPERATOR_TYPE.AFTER_YEAR_AGO.value:
        case this.OPERATOR_TYPE.SINCE_YEAR_AGO.value:
        case this.OPERATOR_TYPE.BEFORE_YEAR_AGO.value:
        case this.OPERATOR_TYPE.UNTIL_YEAR_AGO.value:
        case this.OPERATOR_TYPE.YEAR_AGO.value:
        case this.OPERATOR_TYPE.AFTER_MONTH_AGO.value:
        case this.OPERATOR_TYPE.SINCE_MONTH_AGO.value:
        case this.OPERATOR_TYPE.BEFORE_MONTH_AGO.value:
        case this.OPERATOR_TYPE.UNTIL_MONTH_AGO.value:
        case this.OPERATOR_TYPE.MONTH_AGO.value:
        case this.OPERATOR_TYPE.AFTER_DAY_AGO.value:
        case this.OPERATOR_TYPE.SINCE_DAY_AGO.value:
        case this.OPERATOR_TYPE.BEFORE_DAY_AGO.value:
        case this.OPERATOR_TYPE.UNTIL_DAY_AGO.value:
        case this.OPERATOR_TYPE.DAY_AGO.value:
        case this.OPERATOR_TYPE.DAY_NEXT.value:
        case this.OPERATOR_TYPE.DAY_AGO_EXCEPT_YEAR.value:
        case this.OPERATOR_TYPE.DAY_NEXT_EXCEPT_YEAR.value:
          firstPostPositionalParticle = 'が'
          lastPostPositionalParticle = ''
          break
        case this.OPERATOR_TYPE.WITHIN_DAY_AGO.value:
        case this.OPERATOR_TYPE.WITHIN_DAY_NEXT.value:
        case this.OPERATOR_TYPE.WITHIN_DAY_AGO_EXCEPT_YEAR.value:
        case this.OPERATOR_TYPE.WITHIN_DAY_NEXT_EXCEPT_YEAR.value:
          firstPostPositionalParticle = 'が' + this.enumUtil.getEnumFromValue(this.OPERATOR_TYPE, rule.filterColumn.operatorType).prepend
          lastPostPositionalParticle = ''
          break
        case this.OPERATOR_TYPE.PARTIAL_MATCH.value:
        case this.OPERATOR_TYPE.NOT_PARTIAL_MATCH.value:
          firstPostPositionalParticle = 'に'
          lastPostPositionalParticle = 'を'
          break
        case this.OPERATOR_TYPE.IS_NULL.value:
        case this.OPERATOR_TYPE.IS_NOT_NULL.value:
          firstPostPositionalParticle = 'を'
          lastPostPositionalParticle = ''
          break
      }

      // 条件値
      const displayCheckValue = this.createDisplayCheckValue(rule)

      return String(rule.filterColumn.filterLogicalName) +
        firstPostPositionalParticle +
        displayCheckValue +
        lastPostPositionalParticle +
        this.enumUtil.getEnumFromValue(rule.filterColumn.logicalNot ? this.OPERATOR_TYPE_WITH_LOGICAL_NOT : this.OPERATOR_TYPE, rule.filterColumn.operatorType)?.text +
        internalFilterRule
    },
    createFilterRuleGroupSentence (ruleGroup) {
      let filterRuleGroupSentence
      let groupOperatorType
      if (ruleGroup.logicalOperatorType === this.LOGICAL_OPERATOR_TYPE.AND.value) {
        groupOperatorType = 'かつ'
      } else if (ruleGroup.logicalOperatorType === this.LOGICAL_OPERATOR_TYPE.OR.value) {
        groupOperatorType = 'または'
      }
      ruleGroup.children.forEach((rule, index) => {
        if (rule.isGroup) {
          this.createFilterRuleGroupSentence(rule)
        } else {
          if (index === 0) {
            filterRuleGroupSentence = this.createFilterRuleSentence(rule)
          } else {
            filterRuleGroupSentence += groupOperatorType + this.createFilterRuleSentence(rule)
          }
        }
      })
      return filterRuleGroupSentence
    },
    createDisplayCheckValue (rule) {
      // 一致条件が NULL, NOT_NULL の場合は条件値が NULL のため表示しない (OPERATOR_TYPE の text で完結する)
      if (rule.filterColumn.operatorType === this.OPERATOR_TYPE.IS_NULL.value || rule.filterColumn.operatorType === this.OPERATOR_TYPE.IS_NOT_NULL.value) {
        return ''
      }

      let displayCheckValue
      switch (rule.filterColumn.filterPhysicalName) {
        case 'first_device_type':
          displayCheckValue = this.enumUtil.getEnumFromValue(this.DEVICE_TYPES, rule.filterColumn.filterValue).text
          break
        case 'first_os_type':
          displayCheckValue = this.enumUtil.getEnumFromValue(this.OS_TYPES, rule.filterColumn.filterValue).text
          break
        case 'first_browser_type':
          displayCheckValue = this.enumUtil.getEnumFromValue(this.BROWSER_TYPES, rule.filterColumn.filterValue).text
          break
        case 'summary_time_on_site':
          displayCheckValue = rule.filterColumn.filterValue + '秒'
          break
        default:
          displayCheckValue = rule.filterColumn.filterValue
      }
      // 日付の条件値をISO規格から加工
      if (rule.filterColumn.dataType === this.DATA_TYPES.DATE.value) {
        // yyyy-MM-dd
        displayCheckValue = moment(rule.filterColumn.filterValue.slice(0, 10), 'YYYY-MM-DD').format('YYYY-MM-DD')
      }
      if (rule.filterColumn.dataType === this.DATA_TYPES.DATETIME.value) {
        // yyyy-MM-dd HH:mm
        displayCheckValue = moment(rule.filterColumn.filterValue.slice(0, 10), 'YYYY-MM-DD').format('YYYY-MM-DD') + ' ' +
          rule.filterColumn.filterValue.slice(11, 16)
      }
      return displayCheckValue
    },
    getOperatorType (dataType, operatorType, logicalNot) {
      // operatorList格納
      const operatorList = []
      // String (で始まる, で終わる, 含む)
      if (dataType === this.DATA_TYPES.STRING.value) {
        operatorList.push({ text: this.OPERATOR_TYPE.PREFIX_MATCH.text, value: this.OPERATOR_TYPE.PREFIX_MATCH.value })
        operatorList.push({ text: this.OPERATOR_TYPE.BACKWARD_MATCH.text, value: this.OPERATOR_TYPE.BACKWARD_MATCH.value })
        operatorList.push({ text: this.OPERATOR_TYPE.PARTIAL_MATCH.text, value: this.OPERATOR_TYPE.PARTIAL_MATCH.value })
        // Long, Date, DateTime (超過, 以上, 未満, 以下)
      } else if (dataType === this.DATA_TYPES.LONG.value || dataType === this.DATA_TYPES.DATE.value || dataType === this.DATA_TYPES.DATETIME.value) {
        operatorList.push({ text: this.OPERATOR_TYPE.GT.text, value: this.OPERATOR_TYPE.GT.value })
        operatorList.push({ text: this.OPERATOR_TYPE.GT_EQ.text, value: this.OPERATOR_TYPE.GT_EQ.value })
        operatorList.push({ text: this.OPERATOR_TYPE.LT.text, value: this.OPERATOR_TYPE.LT.value })
        operatorList.push({ text: this.OPERATOR_TYPE.LT_EQ.text, value: this.OPERATOR_TYPE.LT_EQ.value })
      }
      // 相対日付
      if (dataType === this.DATA_TYPES.DATE.value || dataType === this.DATA_TYPES.DATETIME.value || this.isRelativeDateOperator(operatorType)) {
        operatorList.push({ text: this.OPERATOR_TYPE.DAY_AGO.text, value: this.OPERATOR_TYPE.DAY_AGO.value })
        operatorList.push({ text: this.OPERATOR_TYPE.DAY_NEXT.text, value: this.OPERATOR_TYPE.DAY_NEXT.value })
        operatorList.push({ text: '(' + this.OPERATOR_TYPE.WITHIN_DAY_AGO.prepend + ')' + this.OPERATOR_TYPE.WITHIN_DAY_AGO.text, value: this.OPERATOR_TYPE.WITHIN_DAY_AGO.value })
        operatorList.push({ text: '(' + this.OPERATOR_TYPE.WITHIN_DAY_NEXT.prepend + ')' + this.OPERATOR_TYPE.WITHIN_DAY_NEXT.text, value: this.OPERATOR_TYPE.WITHIN_DAY_NEXT.value })
        operatorList.push({ text: this.OPERATOR_TYPE.DAY_AGO_EXCEPT_YEAR.text, value: this.OPERATOR_TYPE.DAY_AGO_EXCEPT_YEAR.value })
        operatorList.push({ text: this.OPERATOR_TYPE.DAY_NEXT_EXCEPT_YEAR.text, value: this.OPERATOR_TYPE.DAY_NEXT_EXCEPT_YEAR.value })
        operatorList.push({ text: '(' + this.OPERATOR_TYPE.WITHIN_DAY_AGO_EXCEPT_YEAR.prepend + ')' + this.OPERATOR_TYPE.WITHIN_DAY_AGO_EXCEPT_YEAR.text, value: this.OPERATOR_TYPE.WITHIN_DAY_AGO_EXCEPT_YEAR.value })
        operatorList.push({ text: '(' + this.OPERATOR_TYPE.WITHIN_DAY_NEXT_EXCEPT_YEAR.prepend + ')' + this.OPERATOR_TYPE.WITHIN_DAY_NEXT_EXCEPT_YEAR.text, value: this.OPERATOR_TYPE.WITHIN_DAY_NEXT_EXCEPT_YEAR.value })
      }
      // 共通 (等しい, 持っている)
      operatorList.push({ text: this.OPERATOR_TYPE.EQ.text, value: this.OPERATOR_TYPE.EQ.value })
      operatorList.push({ text: this.OPERATOR_TYPE.IS_NOT_NULL.text, value: this.OPERATOR_TYPE.IS_NOT_NULL.value })
      if (logicalNot) {
        for (const operator of operatorList) {
          if (this.enumUtil.getEnumFromValue(this.OPERATOR_TYPE_WITH_LOGICAL_NOT, operator.value).prepend !== undefined) {
            operator.text = '(' + this.enumUtil.getEnumFromValue(this.OPERATOR_TYPE_WITH_LOGICAL_NOT, operator.value).prepend + ')' +
              this.enumUtil.getEnumFromValue(this.OPERATOR_TYPE_WITH_LOGICAL_NOT, operator.value).text
          } else {
            operator.text = this.enumUtil.getEnumFromValue(this.OPERATOR_TYPE_WITH_LOGICAL_NOT, operator.value).text
          }
        }
      }
      return operatorList
    },
    isRelativeDateOperator (operatorType) {
      return operatorType === this.OPERATOR_TYPE.DAY_AGO.value ||
        operatorType === this.OPERATOR_TYPE.DAY_NEXT.value ||
        operatorType === this.OPERATOR_TYPE.WITHIN_DAY_AGO.value ||
        operatorType === this.OPERATOR_TYPE.WITHIN_DAY_NEXT.value ||
        operatorType === this.OPERATOR_TYPE.DAY_AGO_EXCEPT_YEAR.value ||
        operatorType === this.OPERATOR_TYPE.DAY_NEXT_EXCEPT_YEAR.value ||
        operatorType === this.OPERATOR_TYPE.WITHIN_DAY_AGO_EXCEPT_YEAR.value ||
        operatorType === this.OPERATOR_TYPE.WITHIN_DAY_NEXT_EXCEPT_YEAR.value
    },
    // TODO: convertUtilに移行
    convertToTypeNameByDateType (dataType) {
      if (dataType === this.DATA_TYPES.STRING.value) {
        return 'text'
      } else if (dataType === this.DATA_TYPES.LONG.value) {
        return 'number'
      } else if (dataType === this.DATA_TYPES.DATE.value) {
        return 'date'
      } else if (dataType === this.DATA_TYPES.DATETIME.value) {
        return 'datetime-local'
      }
    },
  },
}
