<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn to="/dataSets">
          {{ $t(`btn.backToList`) }}
        </v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <stepper :item="steps" />
      <h3 class="mt-6 lead-line">{{ $t('text.dataSet.dataSetName') }}</h3>
      <v-card tile outlined>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="requestParameter.dataSetName"
                dense
                :placeholder="$t('form.dataSet.namePlaceholder')"
                name="dataSetName"
                :data-vv-as="$t(`form.dataSet.name`)"
                v-validate="'required|max:200'"
                :error-messages="errors.collect('dataSetName')"
              />
              <v-textarea
                v-model="requestParameter.note"
                outlined
                rows="3"
                label="説明"
                name="note"
                :data-vv-as="$t(`form.dataSet.note`)"
                v-validate="'max:1000'"
                :error-messages="errors.collect('note')"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-auto">
          <v-spacer />
          <v-btn large color="denial" to="/dataSets">{{ $t('btn.cancel') }}</v-btn>
          <v-btn large color="primary" @click="onClickAddDataSet">{{ $t('btn.dataSet.add') }}</v-btn>
          <v-btn large color="primary" @click="onClickNext">{{ $t('btn.dataSet.next') }}</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
// components
import stepper from '@/components/common/stepper'
// util
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'DataSetAdd',
  components: {
    stepper,
  },
  mixins: [
    notifyUtil,
  ],
  data () {
    return {
      requestParameter: {
        dataSetName: '',
        note: '',
      },
      steps: [
        { text: this.$t('form.dataSet.add'), active: true },
        { text: this.$t('form.dataSource.add') },
        { text: this.$t('form.dataSource.connector') },
      ],
    }
  },
  methods: {
    onClickAddDataSet () {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          for (const error of this.errors.items) {
            this.notifyErrorMessage(error.msg)
          }
          return
        }
        this.$store.dispatch('dataSet/postDataSet', this.requestParameter)
          .then((res) => {
            this.notifySuccessMessage(this.$t('notify.dataSet') + this.$t('notify.success.create'))
            this.$router.push('/dataSet/setting/' + res.data.data.dataSetId)
          })
          .catch((err) => {
            this.handleErrorResponse(err)
          })
      })
    },
    onClickNext () {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          for (const error of this.errors.items) {
            this.notifyErrorMessage(error.msg)
          }
          return
        }
        this.$store.dispatch('dataSet/postDataSet', this.requestParameter)
          .then((res) => {
            this.notifySuccessMessage(this.$t('notify.dataSet') + this.$t('notify.success.create'))
            this.$router.push('/dataSet/setting/' + res.data.data.dataSetId + '/dataSource/setting')
          })
          .catch((err) => {
            this.handleErrorResponse(err)
          })
      })
    },
  },
}
</script>
