export default {
  data () {
    return {
      CSV_DOWNLOAD_REQUEST_EXEC_STATUS: {
        WAITING: { text: '実行待ち', value: 1 },
        PROCESSING: { text: '実行中', value: 2 },
        SUCCESS: { text: '成功', value: 3 },
        FAILED: { text: '失敗', value: 9 },
      },
    }
  },
}
