<template>
  <v-container class="item">
    <v-row :align="'center'" fill-height :justify="'center'">
      <v-col class="text-center mt-5">
        <p class="display-4">401</p>
        <p class="display-2">OOPS!</p>
        <p class="title">Sorry! The page you are looking for couldn't open.</p>
        <v-btn color="primary" :to="'/segments'">To Home</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageNotFound',
}
</script>

<style lang="scss" scoped>
.item {
  height: 100%;
}
.v-btn >>> .v-btn__content {
  color: white;
}
</style>
