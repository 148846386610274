<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn to="/dataSets">
          {{ $t(`btn.backToList`) }}
        </v-btn>
      </template>
      <template #right>
        <v-btn @click="showDeleteDataSetDialog" class="mr-4" :disabled="!canDelete">{{ $t('btn.dataSet.delete') }}</v-btn>
        <v-btn @click="fetchDataSources(); fetchDataSet()" :loading="loadingDataSet"><v-icon>ic-reload</v-icon></v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <!-- TODO i18n -->
      <h3 class="lead-line">データセット名</h3>
      <v-card flat tile outlined class="baseInfo px-3 pt-6 pb-4">
        <v-progress-linear v-if="loadingDataSet" indeterminate color="primary" />
        <v-row class="header">
          <v-col cols="8">
            <v-text-field
              class="x-large mt-0"
              v-model="dataSet.dataSetName"
              name="dataSetName"
              :data-vv-as="$t(`form.dataSet.name`)"
              v-validate="'required|max:200'"
              :error-messages="errors.collect('dataSetName')"
            />
          </v-col>
          <v-col cols="3" class="d-flex flex-column">
            <div class="body-2">
              {{ $t('text.createdAt') }}&#58;&nbsp;{{ dataSet.createdAt }}
              <br>
              {{ $t('text.updatedAt') }}&#58;&nbsp;{{ dataSet.updatedAt }}
            </div>
          </v-col>
          <v-col cols="1" class="d-flex flex-column">
            <v-card-actions class="mt-auto ml-auto pa-0">
              <v-icon v-if="!isBaseInfoOpen" @click="toggleBaseInfo">mdi-chevron-down</v-icon>
            </v-card-actions>
          </v-col>
        </v-row>
        <v-row v-if="isBaseInfoOpen" class="mt-0 content">
          <v-col cols="8" class="pt-7">
            <v-textarea
              v-model="dataSet.note"
              outlined
              hide-details
              rows="3"
              :label="$t('form.dataSet.note')"
              name="note"
              :data-vv-as="$t(`form.dataSet.note`)"
              v-validate="'max:1000'"
              :error-messages="errors.collect('note')"
            />
          </v-col>
          <v-col cols="3" class="d-flex flex-column pt-2">
            <div class="body-2">
              {{ $t('text.dataSet.master') }}&#58;&nbsp;{{ dataSet.masterCount }}
              <br>
              {{ $t('text.dataSet.transaction') }}&#58;&nbsp;{{ dataSet.transactionCount }}
            </div>
            <v-card-actions class="mt-auto pb-0 pl-0">
              <v-btn color="denial" @click="fetchDataSet">{{ $t('btn.cancel') }}</v-btn>
              <v-btn color="primary" @click="onClickUpdateDataSet" :disabled="!canPut">{{ $t('btn.update') }}</v-btn>
            </v-card-actions>
          </v-col>
          <v-col cols="1" class="d-flex flex-column">
            <v-card-actions class="mt-auto ml-auto pa-0">
              <v-icon v-if="isBaseInfoOpen" @click="toggleBaseInfo">mdi-chevron-up</v-icon>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
      <!-- TODO i18n -->
      <h4 class="mt-5">データソース</h4>
      <v-tabs v-model="selectedTab">
        <v-tab href="#master">
          <v-icon class="mr-5">ic-customer-master-S</v-icon>
          {{ $t('text.dataSet.master') }}&nbsp;({{ dataSet.masterCount }})
        </v-tab>
        <v-tab href="#transaction">
          <v-icon class="mr-5">ic-transaction-S</v-icon>
          {{ $t('text.dataSet.transaction') }}&nbsp;({{ dataSet.transactionCount }})
        </v-tab>
        <v-btn
          color="primary"
          class="ml-auto"
          :disabled="!canPut"
          :to="'/dataSet/setting/' + dataSetId + '/linkSetting'">
          {{ $t('btn.dataSet.linkSetting') }}
        </v-btn>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item value="master">
          <v-progress-linear v-if="loadingDataSources" indeterminate color="primary" />
          <v-card v-if="!loadingDataSources && dataSources.masterList.length === 0" outlined tile class="mb-2"><v-card-text>{{$t('text.noData')}}</v-card-text></v-card>
          <data-source-list-item
            v-for="dataSource in dataSources.masterList"
            :key="dataSource.dataSourceId"
            :dataSource="dataSource"
            :dataSetId="dataSetId"
            class="mb-2"
            @showDeleteDataSourceDialog="showDeleteDataSourceDialog"
          />
          <v-btn
            fab
            depressed
            :to="'/dataSet/setting/' + dataSetId + '/dataSource/setting?dataSourceType=' + DATA_SOURCE_TYPES.MASTER.value"
            :disabled="!canPut"
          >
            <v-icon>ic-add</v-icon>
          </v-btn>
        </v-tab-item>
        <v-tab-item value="transaction">
          <v-progress-linear v-if="loadingDataSources" indeterminate color="primary" />
          <v-card v-if="!loadingDataSources && dataSources.transactionList.length === 0" outlined tile class="mb-2"><v-card-text>{{$t('text.noData')}}</v-card-text></v-card>
          <data-source-list-item
            v-for="dataSource in dataSources.transactionList"
            :key="dataSource.dataSourceId"
            :dataSource="dataSource"
            :dataSetId="dataSetId"
            class="mb-2"
            @showDeleteDataSourceDialog="showDeleteDataSourceDialog"
          />
          <v-btn
            fab
            depressed
            :to="'/dataSet/setting/' + dataSetId + '/dataSource/setting?dataSourceType=' + DATA_SOURCE_TYPES.TRANSACTION.value"
            :disabled="!canPut"
          >
            <v-icon>ic-add</v-icon>
          </v-btn>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <!-- データセット削除 -->
    <v-dialog v-model="showDeleteDataSet" width="400" overlay-color="#07070820" overlay-opacity="1">
      <v-card>
        <v-card-title/>
        <v-card-text>
          {{ $t('text.dataSet.title') }}
          <br>
          {{ $t(`notify.check.delete`, [dataSet.dataSetName]) }}
        </v-card-text>
        <v-card-text>
          {{ $t('text.dataSet.delete') }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="denial" @click="showDeleteDataSet = false">{{ $t('btn.no') }}</v-btn>
          <v-btn
            color="primary"
            @click="deleteDataSet"
            :disabled="dataSet.masterCount > 0 || dataSet.transactionCount > 0"
          >
            {{ $t('btn.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- データソース削除 -->
    <v-dialog v-model="showDeleteDataSource" width="400" overlay-color="#07070820" overlay-opacity="1">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          {{ $t('text.dataSource.title') }}
          <br>
          {{ $t('notify.check.delete', [selectedDeleteDataSouce.dataSourceName]) }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="denial" @click="showDeleteDataSource = false; selectedDeleteDataSouce = {}">{{ $t('btn.no') }}</v-btn>
          <v-btn
            color="primary"
            @click="deleteDataSource(selectedDeleteDataSouce)"
          >
            {{ $t('btn.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// components
import dataSourceListItem from '@/components/DataSet/dataSourceListItem'
// enums
import DATA_SOURCE_TYPES from '@/enum/DATA_SOURCE_TYPES'
import CONNECTOR_TYPES from '@/enum/CONNECTOR_TYPES'
// util
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'DataSetDetail',
  components: {
    dataSourceListItem,
  },
  mixins: [
    DATA_SOURCE_TYPES,
    CONNECTOR_TYPES,
    notifyUtil,
  ],
  data () {
    return {
      dataSetId: Number(this.$route.params.dataSetId),
      dataSet: {},
      dataSources: {},
      selectedTab: null,
      showDeleteDataSet: false,
      showDeleteDataSource: false,
      selectedDeleteDataSouce: {},
      isBaseInfoOpen: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['canPut', 'canDelete']),
    loadingDataSet () {
      return this.$store.state.dataSet.loadingDataSet
    },
    loadingDataSources () {
      return this.$store.state.dataSet.loadingDataSources
    },
  },
  created () {
    if (this.$route.hash === '#' + this.DATA_SOURCE_TYPES.TRANSACTION.value) this.selectedTab = 'transaction'
    this.fetchDataSet()
    this.fetchDataSources()
  },
  methods: {
    fetchDataSet () {
      this.$store.dispatch('dataSet/getDataSet', this.dataSetId)
        .then((res) => {
          this.dataSet = res
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    fetchDataSources () {
      this.$store.dispatch('dataSet/getDataSources', this.dataSetId)
        .then((res) => {
          this.dataSources = res.data.data
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    onClickUpdateDataSet () {
      this.$validator.validateAll().then((result) => {
        this.$store.dispatch('dataSet/putDataSet', {
          dataSetId: this.dataSetId,
          dataSetName: this.dataSet.dataSetName,
          note: this.dataSet.note,
        })
          .then(() => {
            this.notifySuccessMessage(this.$t('notify.dataSet') + this.$t('notify.success.update'))
            this.fetchDataSet()
          })
          .catch((err) => {
            this.handleErrorResponse(err)
          })
      })
    },
    showDeleteDataSetDialog () {
      this.showDeleteDataSet = true
    },
    deleteDataSet () {
      this.$store.dispatch('dataSet/deleteDataSet', this.dataSet.dataSetId)
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.dataSet') + this.$t('notify.success.delete'))
          this.showDeleteDataSet = false
          this.$router.push({ name: 'DataSets' })
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    showDeleteDataSourceDialog (dataSource) {
      this.selectedDeleteDataSouce = dataSource
      this.showDeleteDataSource = true
    },
    deleteDataSource (dataSource) {
      this.$store.dispatch('dataSource/deleteDataSource', dataSource.dataSourceId)
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.dataSource') + this.$t('notify.success.delete'))
          this.selectedDeleteDataSourceId = null
          this.showDeleteDataSource = false
          this.fetchDataSources()
          // 顧客マスタ数・トランザクション数更新のためデータセットも再取得
          this.fetchDataSet()
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    toggleBaseInfo: function () {
      this.isBaseInfoOpen = !this.isBaseInfoOpen
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-card) using ($integral-core-theme) {
  &.baseInfo {
    color: map-deep-get($integral-core-theme, 'views', 'dataSetDetail', 'text');
  }
}

.baseInfo {
  line-height: 19px;
  .header {
    .v-text-field {
      height: 35px;
      padding-top: 2px;
    }
  }
  .content {
    .v-btn {
      min-width: 92px;
    }
  }
}
.v-tabs {
  .v-icon {
    font-size: 20px;
  }
}
</style>
