<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn :to="'/dataSet/setting/' + dataSetId + $route.hash">
          {{ $t(`btn.dataSource.back`) }}
        </v-btn>
      </template>
      <template #right>
        <v-btn @click="showDeleteDataSource = true" class="mr-4" :disabled="!canDelete || loadingDataSource">{{ $t('btn.dataSource.delete') }}</v-btn>
        <v-btn @click="fetchDataSource" :loading="loadingDataSource"><v-icon>ic-reload</v-icon></v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <div class="dataSetName">
        <span class="label mr-5">{{ $t('text.dataSource.dataSetName') }}&#58;</span>{{ dataSet.dataSetName }}
      </div>
      <v-progress-linear v-if="loadingDataSet || loadingDataSource" indeterminate color="primary" />
      <v-card flat tile outlined class="baseInfo mb-7 py-3 pr-3" >
        <form @submit.prevent="onClickUpdateDataSource()" data-vv-scope="info">
          <v-row>
            <v-col cols="8" class="d-flex align-start header">
              <v-icon large v-if="fetchedDataSource.connectorType === CONNECTOR_TYPES.CSV.value">{{CONNECTOR_TYPES.CSV.icon}}-M</v-icon>
              <v-icon large v-else-if="fetchedDataSource.connectorType === CONNECTOR_TYPES.API.value">{{CONNECTOR_TYPES.API.icon}}-M</v-icon>
              <v-icon large v-else-if="fetchedDataSource.connectorType === CONNECTOR_TYPES.WEB.value">{{CONNECTOR_TYPES.WEB.icon}}-M</v-icon>
              <v-icon large v-else-if="fetchedDataSource.connectorType === CONNECTOR_TYPES.ANDROID.value">{{CONNECTOR_TYPES.ANDROID.icon}}-M</v-icon>
              <v-icon large v-else-if="fetchedDataSource.connectorType === CONNECTOR_TYPES.IOS.value">{{CONNECTOR_TYPES.IOS.icon}}-M</v-icon>
              <div class="flex-grow-1">
                <v-text-field
                  v-model="requestParameter.dataSourceName"
                  :class="{ modified: !loadingDataSource && requestParameter.dataSourceName !== fetchedDataSource.dataSourceName }"
                  :label="$t('form.dataSource.name')"
                  name="dataSourceName"
                  :data-vv-as="$t(`form.dataSource.name`)"
                  v-validate="'required|max:200'"
                  :error-messages="errors.collect('info.dataSourceName')"
                  @click.stop=""
                />
              </div>
            </v-col>
            <v-col cols="3" class="d-flex flex-column">
              <div v-if="isBaseInfoOpen">
                {{ $t("text.createdAt") }}&#58;&nbsp;{{ fetchedDataSource.createdAt }}
                <br />
                {{ $t("text.createdBy") }}&#58;&nbsp;{{ fetchedDataSource.createdUserName }}
              </div>
              <div v-if="isBaseInfoOpen" class="mt-2">
                {{ $t("text.updatedAt") }}&#58;&nbsp;{{ fetchedDataSource.updatedAt }}
                <br />
                {{ $t("text.updatedBy") }}&#58;&nbsp;{{ fetchedDataSource.updatedUserName }}
              </div>
            </v-col>
            <v-col cols="1" class="d-flex flex-column">
              <v-card-actions class="mt-auto ml-auto pa-0">
                <v-icon v-if="!isBaseInfoOpen" @click="toggleBaseInfo">mdi-chevron-down</v-icon>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row v-if="isBaseInfoOpen" class="mt-0">
            <v-col cols="8" class="d-flex align-start pt-0">
              <div class="ml-9 flex-grow-1">
                <v-textarea
                  v-model="requestParameter.note"
                  outlined
                  hide-details
                  rows="3"
                  :class="{ modified: requestParameter.note !== fetchedDataSource.note }"
                  :label="$t('form.dataSource.note')"
                  name="note"
                  :data-vv-as="$t(`form.dataSource.note`)"
                  v-validate="'max:1000'"
                  :error-messages="errors.collect('info.note')"
                />
              </div>
            </v-col>
            <v-col cols="3" class="d-flex flex-column">
              <v-card-actions class="mt-auto px-0">
                <v-btn color="denial" @click="fetchDataSource">{{ $t("btn.cancel") }}</v-btn>
                <v-btn type="submit" color="primary" class="ml-6" :disabled="!canPut">{{ $t('btn.update') }}</v-btn>
              </v-card-actions>
            </v-col>
            <v-col cols="1" class="d-flex flex-column">
              <v-card-actions class="mt-auto ml-auto pa-0">
                <v-icon @click="toggleBaseInfo">mdi-chevron-up</v-icon>
              </v-card-actions>
            </v-col>
          </v-row>
        </form>
      </v-card>
      <v-progress-circular v-if="!fetchedDataSource.dataSourceId" class="d-flex mx-auto" indeterminate color="primary" />
      <data-source-tabs
        v-if="fetchedDataSource.dataSourceId"
        :dataSet="dataSet"
        :dataSource="fetchedDataSource"
        :dataSourceImportHistories="dataSourceImportHistories"
        :dataSourceImportHistoryPaging="dataSourceImportHistoryPaging"
        :loadingDataSourceImportHistories="loadingDataSourceImportHistories"
        :measureSettings="measureSettings"
        :scheduledToBeImportedObjectNameList="scheduledToBeImportedObjectNameList"
        :loading="loadingDataSource"
        @cancel="fetchDataSource"
        @saveSchema="saveDataSourceSchema"
        @saveCsvImportType="saveCsvImportType"
        @saveDomain="saveDataSourceDomain"
        @executeImporter="executeImporter"
        @getDataSourceImportHistories="getDataSourceImportHistories"
      />
    </v-card-text>
    <!-- データソース削除 -->
    <v-dialog v-model="showDeleteDataSource" width="400" overlay-color="#07070820" overlay-opacity="1" :loading="loadingDataSource">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          {{ $t('notify.check.delete', [fetchedDataSource.dataSourceName]) }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="denial" @click="showDeleteDataSource = false">{{ $t('btn.no') }}</v-btn>
          <v-btn
            color="primary"
            @click="deleteDataSource()"
          >
            {{ $t('btn.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// components
import dataSourceTabs from '@/components/DataSource/dataSourceTabs'
// enums
import DATA_SOURCE_TYPES from '@/enum/DATA_SOURCE_TYPES'
import CONNECTOR_TYPES from '@/enum/CONNECTOR_TYPES'
// util
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'DataSourceDetail',
  components: {
    dataSourceTabs,
  },
  mixins: [DATA_SOURCE_TYPES, CONNECTOR_TYPES, notifyUtil],
  data () {
    return {
      dataSetId: Number(this.$route.params.dataSetId),
      dataSet: {},
      dataSourceId: Number(this.$route.params.dataSourceId),
      fetchedDataSource: {},
      dataSourceImportHistories: [],
      dataSourceImportHistoryPaging: {},
      requestParameter: {
        dataSourceId: Number(this.$route.params.dataSourceId),
        dataSourceName: '',
        note: '',
      },
      selectedTab: null,
      showDeleteDataSource: false,
      measureSettings: [],
      isBaseInfoOpen: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['canPut', 'canDelete']),
    loadingDataSet () {
      return this.$store.state.dataSet.loadingDataSet
    },
    loadingDataSource () {
      return this.$store.state.dataSource.loadingDataSource
    },
    scheduledToBeImportedObjectNameList () {
      return this.$store.state.dataSource.scheduledToBeImportedObjectNameList
    },
    loadingDataSourceImportHistories () {
      return this.$store.state.dataSourceImportHistory.loadingDataSourceImportHistories
    },
  },
  created () {
    this.fetchDataSet()
    this.fetchDataSource()
  },
  methods: {
    fetchDataSet () {
      this.$store.dispatch('dataSet/getDataSet', this.dataSetId)
        .then((res) => {
          this.dataSet = res
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    fetchDataSource () {
      this.$store
        .dispatch('dataSource/getDataSource', this.dataSourceId)
        .then(res => {
          this.fetchedDataSource = res.data.data
          this.requestParameter.dataSourceName = this.fetchedDataSource.dataSourceName
          this.requestParameter.note = this.fetchedDataSource.note
          switch (this.fetchedDataSource.connectorType) {
            case this.CONNECTOR_TYPES.CSV.value:
              this.getDataSourceImportHistories()
              this.fetchDataSourceScheduledToBeImportedObjectList()
              break
            case this.CONNECTOR_TYPES.API.value:
              this.getDataSourceImportHistories()
              break
            case this.CONNECTOR_TYPES.WEB.value:
              if (this.fetchedDataSource.domain === '') this.getMeasureSetting()
              break
          }
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    fetchDataSourceScheduledToBeImportedObjectList () {
      this.$store
        .dispatch('dataSource/getScheduledToBeImportedObjectList', this.dataSourceId)
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    /**
     * マスタ取込履歴一覧リストの取得
     */
    getDataSourceImportHistories () {
      this.$store.dispatch('dataSourceImportHistory/getDataSourceImportHistories', this.dataSourceId)
        .then(res => {
          this.dataSourceImportHistories = res.dataSourceImportHistories
          this.dataSourceImportHistoryPaging = res.paging
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    },
    getMeasureSetting () {
      this.$store.dispatch('measureSettings/getMeasureSetting')
        .then((res) => {
          this.measureSettings = res.measureSettings
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    onClickUpdateDataSource () {
      this.$validator.validateAll('info').then((result) => {
        if (!result) return
        this.$store
          .dispatch('dataSource/putDataSource', this.requestParameter)
          .then(() => {
            this.notifySuccessMessage(
              this.$t('notify.dataSource') + this.$t('notify.success.update'),
            )
            this.fetchDataSource()
          })
          .catch((err) => {
            this.handleErrorResponse(err)
          })
      })
    },
    saveDataSourceSchema (dataSourceId, headerMetaColumns, userIdentifyColumn, datetimeIdentifyColumn, transactionIdentifierColumn) {
      this.$store.dispatch('dataSource/putDataSourceConnector', {
        dataSourceId: dataSourceId,
        headerMetaJson: JSON.stringify(headerMetaColumns),
        userIdentifyColumn: userIdentifyColumn,
        datetimeIdentifyColumn: datetimeIdentifyColumn,
        transactionIdentifierColumn: transactionIdentifierColumn,
      })
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.schema') + this.$t('notify.success.update'))
          this.fetchDataSource()
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    saveCsvImportType (csvImportType) {
      this.$store.dispatch('dataSource/putDataSourceConnectorCsvImportType', {
        dataSourceId: this.dataSourceId,
        csvImportType: csvImportType,
      })
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.csvImportType') + this.$t('notify.success.update'))
          this.fetchDataSource()
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    saveDataSourceDomain (dataSourceId, domain, measureSettingId) {
      this.$store.dispatch('dataSource/putDataSourceConnectorDomain', {
        dataSourceId: dataSourceId,
        domain: domain,
        measureSettingId: measureSettingId,
      })
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.dataSourceDomain') + this.$t('notify.success.update'))
          this.fetchDataSource()
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    executeImporter () {
      this.$store.dispatch('dataSource/postImport', {
        dataSourceId: this.dataSourceId,
      })
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.success.executeImporter'))
          this.fetchDataSource()
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    deleteDataSource () {
      this.$store.dispatch('dataSource/deleteDataSource', this.dataSourceId)
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.dataSource') + this.$t('notify.success.delete'))
          this.showDeleteDataSource = false
          this.$router.push({ name: 'DataSetSettingDetail', params: { dataSetId: this.dataSetId } })
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    toggleBaseInfo: function () {
      this.isBaseInfoOpen = !this.isBaseInfoOpen
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-text-field) using ($integral-core-theme) {
  &.modified {
    background-color: map-deep-get($integral-core-theme, 'inputs', 'filled');
  }
}
@include theme(v-textarea) using ($integral-core-theme) {
  &.modified {
    background-color: map-deep-get($integral-core-theme, 'inputs', 'filled');
  }
}
@include theme(v-card) using ($integral-core-theme) {
  .baseInfo {
    color: map-deep-get($integral-core-theme, 'views', 'dataSourceDetail', 'baseInfo', 'text');
    .header {
      .v-icon {
        color: map-deep-get($integral-core-theme, 'views', 'dataSourceDetail', 'baseInfo', 'icon');
      }
    }
  }
}

.dataSetName {
  margin-bottom: 22px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
  .label {
    font-size: 12px;
  }
}
.baseInfo {
  .header {
    height: 68px;
  }
  .v-card__actions {
    .v-btn {
      min-width: 93px;
    }
  }
}
</style>
