import Vue from 'vue'
import Vuex from 'vuex'
import accounts from './modules/accounts'
import accountRole from './modules/accountRole'
import auth from './modules/auth'
import dataSet from './modules/dataSet'
import dataSets from './modules/dataSets'
import dataSource from './modules/dataSource'
import dataSourceImportHistory from './modules/dataSourceImportHistory'
import global from './modules/global'
import jobExecHistory from './modules/jobExecHistory'
import jobs from './modules/jobs'
import measureSettings from './modules/measureSettings'
import pages from './modules/pages'
import scvs from './modules/scvs'
import scvSettings from './modules/scvSettings'
import scvUnion from './modules/scvUnion'
import segmentExportScript from './modules/segmentExportScript'
import segmentExportScriptHistory from './modules/segmentExportScriptHistory'
import segmentExportScripts from './modules/segmentExportScripts'
import segments from './modules/segments'
import segmentSearch from './modules/segmentSearch'
import segmentSetting from './modules/segmentSetting'
import segmentSummary from './modules/segmentSummary'
import segmentUsers from './modules/segmentUsers'
import segmentJobs from './modules/segmentJobs'
import totalUser from './modules/totalUser'
import transactionSummary from './modules/transactionSummary'
import userActionHistory from './modules/userActionHistory'
import userScvDetail from './modules/userScvDetail'
import userInfo from './modules/userInfo'
import userUnknownDetail from './modules/userUnknownDetail'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    accounts: accounts,
    accountRole: accountRole,
    auth: auth,
    dataSet: dataSet,
    dataSets: dataSets,
    dataSource: dataSource,
    dataSourceImportHistory: dataSourceImportHistory,
    global: global,
    jobExecHistory: jobExecHistory,
    jobs: jobs,
    measureSettings: measureSettings,
    pages: pages,
    scvs: scvs,
    scvSettings: scvSettings,
    scvUnion: scvUnion,
    segmentExportScript: segmentExportScript,
    segmentExportScriptHistory: segmentExportScriptHistory,
    segmentExportScripts: segmentExportScripts,
    segments: segments,
    segmentSearch: segmentSearch,
    segmentSetting: segmentSetting,
    segmentSummary: segmentSummary,
    segmentUsers: segmentUsers,
    segmentJobs: segmentJobs,
    totalUser: totalUser,
    transactionSummary: transactionSummary,
    userActionHistory: userActionHistory,
    userScvDetail: userScvDetail,
    userInfo: userInfo,
    userUnknownDetail: userUnknownDetail,
  },
})
