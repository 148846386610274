<template>
  <v-card outlined tile :to="'/dataSet/setting/' + dataSetId + '/dataSource/setting/' + dataSource.dataSourceId + '#' + dataSource.dataSourceType">
    <v-card-title class="pl-9 pt-2 pb-1">{{ dataSource.dataSourceName }}</v-card-title>
    <v-card-text class="d-flex align-top pl-2 pb-3">
      <!-- 削除ボタン -->
      <v-btn fab depressed x-small color="denial" class="mr-2" @click.prevent="showDeleteDataSourceDialog" :disabled="!canDelete">
        <v-icon>ic-close-S</v-icon>
      </v-btn>
      <v-row>
        <v-col cols="5" class="d-flex">
          <v-icon large v-if="dataSource.connectorType === CONNECTOR_TYPES.CSV.value">{{CONNECTOR_TYPES.CSV.icon}}-M</v-icon>
          <v-icon large v-else-if="dataSource.connectorType === CONNECTOR_TYPES.API.value">{{CONNECTOR_TYPES.API.icon}}-M</v-icon>
          <v-icon large v-else-if="dataSource.connectorType === CONNECTOR_TYPES.WEB.value">{{CONNECTOR_TYPES.WEB.icon}}-M</v-icon>
          <v-icon large v-else-if="dataSource.connectorType === CONNECTOR_TYPES.ANDROID.value">{{CONNECTOR_TYPES.ANDROID.icon}}-M</v-icon>
          <v-icon large v-else-if="dataSource.connectorType === CONNECTOR_TYPES.IOS.value">{{CONNECTOR_TYPES.IOS.icon}}-M</v-icon>
          <div class="ml-3">
            <div v-if="dataSource.connectorType === CONNECTOR_TYPES.WEB.value">
              {{ $t('text.dataSources.domain') }}&#58;&nbsp;{{ dataSource.domain }}
            </div>
            <div v-if="dataSource.connectorType === CONNECTOR_TYPES.ANDROID.value ||
              dataSource.connectorType === CONNECTOR_TYPES.IOS.value">
              {{ $t('text.dataSources.bundle') }}&#58;&nbsp;{{ dataSource.domain }}
            </div>
            <div v-if="dataSource.hash">
              {{ $t('text.dataSources.hash') }}&#58;&nbsp;{{ dataSource.hash }}
            </div>
          </div>
        </v-col>
        <v-col class="d-flex">
          <div class="mr-8">
            <div>{{ $t('text.createdAt')}}&#58;&nbsp;{{ dataSource.createdAt }}</div>
            <div>{{ $t('text.updatedAt')}}&#58;&nbsp;{{ dataSource.updatedAt }}</div>
          </div>
          <div>
            <div>{{ $t('text.createdBy')}}&#58;&nbsp;{{ dataSource.createdUserName }}</div>
            <div>{{ $t('text.updatedBy')}}&#58;&nbsp;{{ dataSource.updatedUserName }}</div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import DATA_SOURCE_TYPES from '@/enum/DATA_SOURCE_TYPES'
import CONNECTOR_TYPES from '@/enum/CONNECTOR_TYPES'

export default {
  name: 'dataSourceListItem',
  mixins: [
    DATA_SOURCE_TYPES,
    CONNECTOR_TYPES,
  ],
  props: {
    dataSource: {
      type: Object,
    },
    dataSetId: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters('auth', ['canDelete']),
  },
  methods: {
    showDeleteDataSourceDialog () {
      this.$emit('showDeleteDataSourceDialog', this.dataSource)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-card) using ($integral-core-theme) {
  .v-card__text {
    .row {
      .v-icon {
        color: map-deep-get($integral-core-theme, 'views', 'dataSet', 'dataSourceListItem', 'icon');
      }
    }
  }
}

.v-card__title {
  font-size: 16px;
  line-height: 21px;
}
</style>
