<template>
  <v-card outlined tile class="flex-grow-1">
    <v-card-text class="pa-3">
      <v-row>
        <v-col cols="8">
          <summary-graph
            :chart-data="userGraphData"
            :chart-display-type="chartDisplayType"
            :customize-options="customizeOptions"
            style="height:175px;"
          />
        </v-col>
        <v-col cols="4">
          <v-dialog
            ref="dialog"
            v-model="baseDatePicker"
            :return-value.sync="baseDate"
            width="252px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="baseDate"
                class="mt-0"
                label="基準日"
                append-icon="ic-calendar-S"
                readonly
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="tmpBaseDate"
              locale="ja"
              :day-format="date => new Date(date).getDate()"
              no-title
              width="auto"
              @input="baseDate = tmpBaseDate ,$refs.dialog.save(baseDate)"
            />
          </v-dialog>
          <v-select
            class="mt-0"
            v-model="dateIntervalType"
            :items="enumUtil.convertForSelectList(DATE_INTERVAL_TYPES)"
            label="日付間隔"
            hide-details
          />
          <small class="d-block">表示切替</small>
          <v-radio-group v-model="chartDisplayType" hide-details class="mt-0 pt-2" row dense>
            <v-radio
              v-for="type in CHART_DISPLAY_TYPES"
              :key="type.value"
              :label="type.text"
              :value="type.value"
              color="inputSelectionControl"
            />
          </v-radio-group>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// components
import summaryGraph from '@/components/public/summaryGraph'
// enums
import CHART_DISPLAY_TYPES from '@/enum/CHART_DISPLAY_TYPES'
import DATE_INTERVAL_TYPES from '@/enum/DATE_INTERVAL_TYPES'
import PERIOD_TYPES from '@/enum/PERIOD_TYPES'
// utils
import enumUtil from '@/utils/enumUtil'
import momentUtil from '@/utils/momentUtil'
import notifyUtil from '@/utils/notifyUtil'

const DEFAULT_DATA_POINT = 8 // グラフ用のデータ取得数（実際に表示されるのより1つ多く取得する）

export default {
  name: 'SegmentUserLineChart',
  mixins: [
    CHART_DISPLAY_TYPES,
    DATE_INTERVAL_TYPES,
    PERIOD_TYPES,
    enumUtil,
    momentUtil,
    notifyUtil,
  ],
  components: {
    'summary-graph': summaryGraph,
  },
  props: {
    segment: {
      type: Object,
    },
  },
  data () {
    return {
      baseDate: '',
      baseDatePicker: false,
      chartDisplayType: 1,
      customizeOptions: {
        scales: {
          yAxes: [{
            ticks: {
              suggestedMin: 0,
              precision: 0,
            },
          }],
        },
      },
      dateIntervalType: 1,
      initialize: true,
      tmpBaseDate: '',
      userGraphData: {
        labels: [],
        datasets: [
          {
            label: 'Loading',
            data: [],
          },
        ],
      },
    }
  },
  computed: {
    segmentId () {
      return this.$route.params.segmentId
    },
  },
  watch: {
    baseDate () {
      this.tmpBaseDate = this.baseDate
      this.fetchGraphDailyUsers()
    },
    dateIntervalType () {
      this.fetchGraphDailyUsers()
    },
    segment: {
      handler () {
        const tmpVar = this.baseDate

        if (this.segment.periodType === this.PERIOD_TYPES.CUSTOM.value && this.segment.endDate) {
          // カスタムの場合で終了日が指定されている場合、当日日付とセグメント終了日付の古い方を初期値にする
          const today = this.momentUtil.getCurrentDateIncludeHyphen()
          this.baseDate = this.segment.endDate < today ? this.segment.endDate : today
        } else {
          this.baseDate = this.momentUtil.getCurrentDateIncludeHyphen()
        }

        // baseDateがURL変更前と値が変更されなかった場合はここでデータを再取得させる関数を発火、値が変更された場合はbaseDateのwatcherから発火される
        if (this.baseDate === tmpVar) this.fetchGraphDailyUsers()
      },
      deep: true,
    },
  },
  methods: {
    /**
     * グラフ用セグメントユーザー数取得
     */
    fetchGraphDailyUsers () {
      if (!this.baseDate || !this.dateIntervalType) return

      // ユーザー数情報取得
      this.$store.dispatch('segmentSummary/getSegmentUserDailyWithInterval', {
        segmentId: this.segmentId,
        baseDate: this.baseDate,
        dateIntervalType: this.dateIntervalType,
        dataPoint: DEFAULT_DATA_POINT,
      }).then(res => {
        this.userGraphData = res.data.data
      }).catch((err) => {
        this.handleErrorResponse(err)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-card) using ($integral-core-theme) {
  small {
    color: map-deep-get($integral-core-theme, 'views', 'segmentDetail', 'tab', 'text');
  }
  .v-radio {
    &::v-deep .v-label {
      color: map-deep-get($integral-core-theme, 'views', 'segmentDetail', 'tab', 'label') !important;
    }
  }
}

.v-text-field {
  &::v-deep .v-icon.ic-calendar-S {
    font-size: 20px;
  }
}
small {
  line-height: 16px;
  margin-top: 22px;
}
.v-radio {
  &::v-deep .v-label {
    font-size: 14px;
  }
}
</style>
