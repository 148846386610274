export default {
  data () {
    return {
      AWS_S3_REGION: {
        OHIO: { text: '米国東部 (オハイオ)', value: 'us-east-2' },
        NORTHERN_VIRGINIA: { text: '米国東部（バージニア北部）', value: 'us-east-1' },
        NORTHERN_CALIFORNIA: { text: '米国西部 (北カリフォルニア)', value: 'us-west-1' },
        OREGON: { text: '米国西部 (オレゴン)', value: 'us-west-2' },
        HONG_KONG: { text: 'アジアパシフィック (香港)', value: 'ap-east-1' },
        MUMBAI: { text: 'アジアパシフィック (ムンバイ)', value: 'ap-south-1' },
        OSAKA: { text: 'アジアパシフィック (大阪: ローカル)', value: 'ap-northeast-3' },
        SEOUL: { text: 'アジアパシフィック (ソウル)', value: 'ap-northeast-2' },
        SINGAPORE: { text: 'アジアパシフィック (シンガポール)', value: 'ap-southeast-1' },
        SYDNEY: { text: 'アジアパシフィック (シドニー)', value: 'ap-southeast-2' },
        TOKYO: { text: 'アジアパシフィック (東京)', value: 'ap-northeast-1' },
        CANADA: { text: 'カナダ (中部)', value: 'ca-central-1' },
        BEIJING: { text: '中国 (北京)', value: 'cn-north-1' },
        NINGXIA: { text: '中国 (寧夏)', value: 'cn-northwest-1' },
        FRANKFURT_AM_MAIN: { text: '欧州 (フランクフルト)', value: 'eu-central-1' },
        IRELAND: { text: '欧州 (アイルランド)', value: 'eu-west-1' },
        LONDON: { text: '欧州 (ロンドン)', value: 'eu-west-2' },
        PARIS: { text: 'EU (パリ)', value: 'eu-west-3' },
        STOCKHOLM: { text: '欧州 (ストックホルム)', value: 'eu-north-1' },
        SAO_PAULO: { text: '南米 (サンパウロ)', value: 'sa-east-1' },
      },
    }
  },
}
