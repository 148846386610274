<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn :to="'/dataSet/setting/' + dataSetId">
          {{ $t(`btn.back`) }}
        </v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <stepper :item="steps" class="mb-6" />
      <div class="mb-2">{{ $t('text.dataSet.dataSetName') }}&#58;&nbsp;{{ dataSet.dataSetName }}</div>
      <h3 class="lead-line">{{ $t('text.dataSource.dataSourceName') }}</h3>
      <v-card tile outlined>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="requestParameter.dataSourceName"
                dense
                :placeholder="$t('form.dataSource.namePlaceholder')"
                name="dataSourceName"
                :data-vv-as="$t(`form.dataSource.name`)"
                v-validate="'required|max:200'"
                :error-messages="errors.collect('dataSourceName')"
              />
              <v-textarea
                v-model="requestParameter.note"
                outlined
                rows="3"
                label="説明"
                name="note"
                :data-vv-as="$t(`form.dataSource.note`)"
                v-validate="'max:1000'"
                :error-messages="errors.collect('note')"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- データソース種別選択 -->
      <h3 class="mt-8 mb-4">データソースタイプ</h3>
      <v-card outlined tile>
        <v-card-text>
          <v-item-group
            v-model="requestParameter.dataSourceType"
            mandatory
            class="d-flex"
            @change="changeDataSourceType"
          >
            <v-item
              v-for="DATA_SOURCE_TYPE in DATA_SOURCE_TYPES"
              :key="DATA_SOURCE_TYPE.value"
              :value="DATA_SOURCE_TYPE.value"
              v-slot="{ active, toggle }"
            >
              <v-card
                outlined
                class="mr-4 radioDataSource"
                height="104"
                width="108"
                :color="active ? 'active' : ''"
                @click="toggle"
              >
                <v-icon size="32px">{{ DATA_SOURCE_TYPE.icon }}</v-icon>
                {{ DATA_SOURCE_TYPE.text }}
              </v-card>
            </v-item>
          </v-item-group>
        </v-card-text>
      </v-card>

      <!-- コネクタ選択 -->
      <h3 class="mt-8 mb-4">データソース元の選択</h3>
      <v-card outlined tile>
        <v-card-text>
          <v-item-group
            v-model="requestParameter.connectorType"
            mandatory
            class="d-flex"
          >
            <v-item
              v-for="CONNECTOR_TYPE in CONNECTOR_TYPES"
              :key="CONNECTOR_TYPE.value"
              :value="CONNECTOR_TYPE.value"
              v-slot="{ active, toggle }"
            >
              <v-card
                outlined
                class="mr-4 radioConnector"
                height="104"
                width="104"
                :disabled="!CONNECTOR_TYPE.availableDataSourceType.includes(requestParameter.dataSourceType)"
                :color="active ? 'active' : ''"
                @click="toggle"
              >
                <v-icon size="44px">{{ CONNECTOR_TYPE.icon }}-L</v-icon>
                {{ CONNECTOR_TYPE.text }}
              </v-card>
            </v-item>
          </v-item-group>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-actions class="mt-auto">
      <v-spacer />
      <v-btn large color="denial" :to="'/dataSet/setting/' + dataSetId">{{ $t('btn.cancel') }}</v-btn>
      <v-btn large color="primary" @click="onClickSave">{{ $t('btn.dataSource.add') }}</v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
// components
import stepper from '@/components/common/stepper'
// enums
import CONNECTOR_TYPES from '@/enum/CONNECTOR_TYPES'
import DATA_SOURCE_TYPES from '@/enum/DATA_SOURCE_TYPES'
// util
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'dataSourceAdd',
  components: {
    stepper,
  },
  mixins: [
    CONNECTOR_TYPES,
    DATA_SOURCE_TYPES,
    notifyUtil,
  ],
  data () {
    return {
      dataSetId: Number(this.$route.params.dataSetId),
      dataSet: {},
      steps: [
        { text: this.$t('form.dataSet.add') },
        { text: this.$t('form.dataSource.add'), active: true },
        { text: this.$t('form.dataSource.connector') },
      ],
      requestParameter: {
        dataSetId: Number(this.$route.params.dataSetId),
        dataSourceName: '',
        dataSourceType: Number(this.$route.query.dataSourceType),
        connectorType: null,
        note: '',
      },
    }
  },
  computed: {
    userAuth () {
      return this.$store.state.auth.loginUserDetail.authority.dataSet
    },
  },
  created () {
    this.fetchDataSet()
  },
  methods: {
    fetchDataSet () {
      this.$store.dispatch('dataSet/getDataSet', this.dataSetId)
        .then((res) => {
          this.dataSet = res
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
        .finally(() => {})
    },
    changeDataSourceType () {
      // コネクタが選択不可能なものの場合は CSV にする
      const selectedConnector = Object.values(this.CONNECTOR_TYPES).find(type => type.value === this.requestParameter.connectorType)
      if (!selectedConnector?.availableDataSourceType?.includes(this.requestParameter.dataSourceType)) {
        this.requestParameter.connectorType = this.CONNECTOR_TYPES.CSV.value
      }
    },
    onClickSave () {
      this.$validator.validateAll().then((result) => {
        // バリデーションエラーがあればリクエストは送信しない
        if (!result) {
          for (const error of this.errors.items) {
            this.notifyErrorMessage(error.msg)
          }
          return
        }
        this.$store.dispatch('dataSource/postDataSource', this.requestParameter)
          .then((res) => {
            this.notifySuccessMessage(this.$t('notify.dataSource') + this.$t('notify.success.create'))
            this.$router.push('/dataSet/setting/' + this.dataSetId + '/dataSource/setting/' + res.data.data.dataSourceId + '/connector')
          })
          .catch((err) => {
            this.handleErrorResponse(err)
          })
      })
    },
    isSdk (connectorTypeValue) {
      return connectorTypeValue === this.CONNECTOR_TYPES.ANDROID.value || connectorTypeValue === this.CONNECTOR_TYPES.IOS.value
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

// v-card
@include theme(v-card) using ($integral-core-theme) {
  .radioDataSource, .radioConnector {
    background-color: map-deep-get($integral-core-theme, 'views', 'dataSourceAdd', 'sheets', 'radio-data-source', 'base', 'background');
    border-color: map-deep-get($integral-core-theme, 'views', 'dataSourceAdd', 'sheets', 'radio-data-source', 'base', 'border');
    color: map-deep-get($integral-core-theme, 'views', 'dataSourceAdd', 'sheets', 'radio-data-source', 'base', 'text');
    .v-icon {
      color: map-deep-get($integral-core-theme, 'views', 'dataSourceAdd', 'sheets', 'radio-data-source', 'base', 'icon');
    }

    &.active {
      background-color: map-deep-get($integral-core-theme, 'views', 'dataSourceAdd', 'sheets', 'radio-data-source', 'active', 'background');
      border-color: map-deep-get($integral-core-theme, 'views', 'dataSourceAdd', 'sheets', 'radio-data-source', 'active', 'border');
      color: map-deep-get($integral-core-theme, 'views', 'dataSourceAdd', 'sheets', 'radio-data-source', 'active', 'text');
      .v-icon::before {
        color: map-deep-get($integral-core-theme, 'views', 'dataSourceAdd', 'sheets', 'radio-data-source', 'active', 'icon');
      }
    }
  }
}
.v-card {
  .radioDataSource, .radioConnector {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    font-size: 12px;
    line-height: 14px;
  }
  .radioDataSource .v-icon {
    margin: 16px 0;
  }
  .radioConnector .v-icon {
    margin: 12px 0;
  }
}

</style>
