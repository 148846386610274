<template>
  <v-card outlined tile class="mb-2" :disabled="loading">
    <v-card-text v-if="$slots.header" class="header d-flex align-content-center flex-wrap">
      <slot name="header"/>
    </v-card-text>
    <v-card-text class="main pa-3">
      <router-link :to="to" style="text-decoration: none; color: inherit;">
        <v-row>
          <v-col cols="6">
            <v-row no-gutters>
              <v-col v-if="showSelects" cols="1" class="d-flex align-center justify-center">
                <v-checkbox :input-value="selectedList" :value="id" @change="selected" @click.prevent=""/>
              </v-col>
              <v-col :cols="showSelects ? '11' : '12'">
                <h3 class="name">{{ name }}</h3>
                <div class="note mt-1">
                  <v-tooltip bottom max-width="900">
                    <template #activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{ note }}</span>
                    </template>
                    {{ note }}
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3" class="text-truncate">
            <slot name="col2"/>
          </v-col>
          <v-col cols="3" class="text-truncate">
            <slot name="col3"/>
          </v-col>
        </v-row>
      </router-link>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'LargeList',
  props: {
    name: {
      type: String,
    },
    note: {
      type: String,
    },
    to: {
      type: [Object, String],
    },
    id: {
      type: Number,
    },
    selectedList: {
      type: Array,
      default: function () {
        return []
      },
    },
    showSelects: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
    },
  },
  methods: {
    selected (value) {
      this.$emit('checked', value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-card) using ($integral-core-theme) {
  .header {
    background-color: map-deep-get($integral-core-theme, 'common', 'large-list', 'header-background');
    color: map-deep-get($integral-core-theme, 'common', 'large-list', 'header-text');
  }
  .main {
    color: map-deep-get($integral-core-theme, 'common', 'large-list', 'main');
    .name {
      color: map-deep-get($integral-core-theme, 'common', 'large-list', 'name');
    }
    &:hover {
      background-color: map-deep-get($integral-core-theme, 'common', 'large-list', 'background-hover');
    }
  }
}
.header {
  font-size: 14px;
  line-height: 20px;
  min-height: 36px;
  padding: 0 8px;
}
.main {
  height: 90px;
  padding: 8px 8px;
  .name {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
  }
  .note {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 40px;
    overflow: hidden;
  }
}
</style>
