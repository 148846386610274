import DATA_TYPES from '../enum/DATA_TYPES'

export default {
  mixins: [
    DATA_TYPES,
  ],
  methods: {
    /**
     * Get the input form type according to the data type.
     * If invalid dataType, return 'text'.
     * @param {Number} dataType
     */
    convertToTypeNameByDateType (dataType) {
      switch (dataType) {
        case this.DATA_TYPES.STRING.value:
          return 'text'
        case this.DATA_TYPES.LONG.value:
          return 'number'
        case this.DATA_TYPES.DATE.value:
          return 'date'
        case this.DATA_TYPES.DATETIME.value:
          return 'datetime-local'
        default:
          return 'text'
      }
    },
    /**
     * 日時をIso規格に変換
     * @param  {Date} date 変換対象の日付
     * @return {String} yyyy-MM-dd HH:mm:SS
     */
    convertDateTimeToIso (dateString) {
      const date = new Date(dateString)
      return date.getFullYear() + '-' +
        (('0' + (date.getMonth() + 1)).slice(-2)) + '-' +
        ('0' + date.getDate()).slice(-2) + ' ' +
        ('0' + date.getHours()).slice(-2) + ':' +
        ('0' + date.getMinutes()).slice(-2) + ':' +
        ('0' + date.getSeconds()).slice(-2)
    },
    /**
     * Iso規格の文字列を日時文字列に変換
     * @param  {String} isoString
     * @return {String} yyyy-MM-ddTHH:mm:SS
     */
    convertIsoToDateTimeString (isoString) {
      const date = new Date(isoString)
      return date.getFullYear() + '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
        ('0' + date.getDate()).slice(-2) + 'T' +
        ('0' + date.getHours()).slice(-2) + ':' +
        ('0' + date.getMinutes()).slice(-2) + ':' +
        ('0' + date.getSeconds()).slice(-2)
    },
  },
}
