import BROWSER_TYPES from '../enum/BROWSER_TYPES'
import DEVICE_TYPES from '../enum/DEVICE_TYPES'
import INTERNAL_FILTER_TYPES from '../enum/INTERNAL_FILTER_TYPES'
import OPERATOR_TYPE from '../enum/OPERATOR_TYPE'
import OS_TYPES from '../enum/OS_TYPES'
import PERIOD_TYPES from '../enum/PERIOD_TYPES'
import enumUtil from '../utils/enumUtil'
import moment from 'moment-timezone'

const MAX_CONFIRM_MESSAGE = '登録上限に達しています。追加登録前に不要なセグメント設定を削除する必要があります。'
const MAX_ALERT_MESSAGE = '登録上限数に達しています。登録前に不要なセグメント設定を削除する必要があります。'
const SELECT_BOX_DEFAULT_MESSAGE = '─選択してください─'
const NO_FILTER_RULES = 'フィルタ条件未設定'
const NO_PERIOD_CAUSED_BY_CUSTOM = 'ーー'
const NO_RULE_CAUSED_BY_CUSTOM = 'SQL指定'

export default {
  mixins: [
    BROWSER_TYPES,
    DEVICE_TYPES,
    INTERNAL_FILTER_TYPES,
    OPERATOR_TYPE,
    OS_TYPES,
    PERIOD_TYPES,
    enumUtil,
  ],
  data () {
    return {
      MAX_CONFIRM_MESSAGE,
      MAX_ALERT_MESSAGE,
      SELECT_BOX_DEFAULT_MESSAGE,
      NO_FILTER_RULES,
      NO_PERIOD_CAUSED_BY_CUSTOM,
      NO_RULE_CAUSED_BY_CUSTOM,
    }
  },
  methods: {
    getConfirmMessage (segmentCount) {
      const additionalFeeSegmentCount = segmentCount + 1
      return `現在登録されているセグメント設定は${segmentCount}件です。${additionalFeeSegmentCount}件目を登録する際に料金が加算されます。`
    },
    getFilterRuleSentence (filterRule) {
      let firstPostpositionalParticle
      let lastPostpositionalParticle
      let internalFilterRule
      let displayCheckValue

      if (!filterRule.internalFilterType) internalFilterRule = ''
      else if (filterRule.internalFilterType === this.INTERNAL_FILTER_TYPES.SITE.value ||
          filterRule.internalFilterType === this.INTERNAL_FILTER_TYPES.PAGE.value) {
        internalFilterRule = 'の' + filterRule.internalCheckValueDisplayName
      } else if (filterRule.internalFilterType === this.INTERNAL_FILTER_TYPES.DEVICE_TYPE.value) {
        internalFilterRule = 'の' + this.enumUtil.getEnumFromValue(this.DEVICE_TYPES, filterRule.internalCheckValue).text
      } else if (filterRule.internalFilterType === this.INTERNAL_FILTER_TYPES.OS_TYPE.value) {
        internalFilterRule = 'の' + this.enumUtil.getEnumFromValue(this.OS_TYPES, filterRule.internalCheckValue).text
      } else {
        internalFilterRule = ''
      }

      if (filterRule.operatorType === this.OPERATOR_TYPE.GT.value) {
        firstPostpositionalParticle = 'が'
        lastPostpositionalParticle = 'を'
      } else if (filterRule.operatorType === this.OPERATOR_TYPE.GT_EQ.value ||
                 filterRule.operatorType === this.OPERATOR_TYPE.LT.value ||
                 filterRule.operatorType === this.OPERATOR_TYPE.LT_EQ.value) {
        firstPostpositionalParticle = 'が'
        lastPostpositionalParticle = ''
      } else if (filterRule.operatorType === this.OPERATOR_TYPE.EQ.value ||
                 filterRule.operatorType === this.OPERATOR_TYPE.NOT_EQ.value) {
        firstPostpositionalParticle = 'が'
        lastPostpositionalParticle = 'と'
      } else if (filterRule.operatorType === this.OPERATOR_TYPE.PREFIX_MATCH.value ||
                 filterRule.operatorType === this.OPERATOR_TYPE.BACKWARD_MATCH.value) {
        firstPostpositionalParticle = 'が'
        lastPostpositionalParticle = ''
      } else if (filterRule.operatorType === this.OPERATOR_TYPE.PARTIAL_MATCH.value ||
                 filterRule.operatorType === this.OPERATOR_TYPE.NOT_PARTIAL_MATCH.value) {
        firstPostpositionalParticle = 'に'
        lastPostpositionalParticle = 'を'
      }

      switch (filterRule.filterColumn.filterPhysicalName) {
        case 'first_device_type':
          displayCheckValue = this.enumUtil.getEnumFromValue(this.DEVICE_TYPES, filterRule.checkValue).text
          break
        case 'first_os_type':
          displayCheckValue = this.enumUtil.getEnumFromValue(this.OS_TYPES, filterRule.checkValue).text
          break
        case 'first_browser_type':
          displayCheckValue = this.enumUtil.getEnumFromValue(this.BROWSER_TYPES, filterRule.checkValue).text
          break
        case 'summary_time_on_site':
          displayCheckValue = filterRule.checkValue + '秒'
          break
        default:
          displayCheckValue = filterRule.checkValue
      }
      return String(filterRule.filterColumn.filterColumnName) +
        firstPostpositionalParticle + displayCheckValue + lastPostpositionalParticle + this.enumUtil.getEnumFromValue(this.OPERATOR_TYPE, filterRule.operatorType).text +
        internalFilterRule
    },
    getPeriodText (segment) {
      let str = ''

      // 日付指定以外はtextをそのままセット
      if (segment.periodType !== this.PERIOD_TYPES.CUSTOM.value) {
        str += this.enumUtil.getEnumFromValue(this.PERIOD_TYPES, segment.periodType).text
      }

      // 日付指定の場合のみ日付を付与（例:指定なし 〜 2018-09-11）
      if (this.PERIOD_TYPES.CUSTOM.value === segment.periodType) {
        if (segment.startDate) {
          str += moment(segment.startDate.slice(0, 10), 'YYYY-MM-DD').format('YYYY-MM-DD')
        } else {
          str += '指定なし'
        }
        str += ' 〜 '
        if (segment.endDate) {
          str += moment(segment.endDate.slice(0, 10), 'YYYY-MM-DD').format('YYYY-MM-DD')
        } else {
          str += '指定なし'
        }
      }
      return str
    },
  },
}
