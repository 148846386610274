<template>
  <v-card outlined tile>
    <v-card-text>
      <v-row dense>
        <!-- 名前 -->
        <v-col cols="6" class="column">
          <div class="columnName">名前</div>
          <div class="columnValue">
            <scv-column v-if="isScv" :loading="loading" :scvColumn="scvName" :scvSources="scvNameColumnSources" @overwrite="overwrite" />
            <span v-else>{{ $t(`text.userUnknown`) }}</span>
          </div>
        </v-col>
        <!-- 生年月日 -->
        <v-col cols="6" class="column">
          <div class="columnName">生年月日</div>
          <div class="columnValue">
            <scv-column v-if="isScv" :loading="loading" :scvColumn="scvBirthWithAge" :scvSources="scvBirthColumnSources" @overwrite="overwrite" />
            <v-skeleton-loader v-else ref="skeleton" type="text" boilerplate width="200" />
          </div>
        </v-col>
        <!-- メールアドレス -->
        <v-col cols="6" class="column">
          <div class="columnName">メールアドレス</div>
          <div class="columnValue">
            <scv-column v-if="isScv" :loading="loading" :scvColumn="scvMailAddress" :scvSources="scvMailAddressColumnSources" @overwrite="overwrite" />
            <v-skeleton-loader v-else ref="skeleton" type="text" boilerplate width="200" />
          </div>
        </v-col>
        <!-- 電話 -->
        <v-col cols="6" class="column">
          <div class="columnName">電話</div>
          <div class="columnValue">
            <scv-column v-if="isScv" :loading="loading" :scvColumn="scvTelephone" :scvSources="scvTelephoneColumnSources" @overwrite="overwrite" />
            <v-skeleton-loader v-else ref="skeleton" type="text" boilerplate width="200" />
          </div>
        </v-col>
        <!-- 性別 -->
        <v-col cols="6" class="column">
          <div class="columnName">性別</div>
          <div class="columnValue">
            <scv-column v-if="isScv" :loading="loading" :scvColumn="scvGender" :scvSources="scvGenderColumnSources" @overwrite="overwrite" />
            <v-skeleton-loader v-else ref="skeleton" type="text" boilerplate width="200" />
          </div>
        </v-col>
        <!-- 郵便番号 -->
        <v-col cols="6" class="column">
          <div class="columnName">郵便番号</div>
          <div class="columnValue">
            <scv-column v-if="isScv" :loading="loading" :scvColumn="scvZipcode" :scvSources="scvZipcodeColumnSources" @overwrite="overwrite" />
            <v-skeleton-loader v-else ref="skeleton" type="text" boilerplate width="200" />
          </div>
        </v-col>
        <!-- 住所 -->
        <v-col cols="6" offset="6" class="column">
          <div class="columnName">住所</div>
          <div class="columnValue">
            <scv-column v-if="isScv" :loading="loading" :scvColumn="scvAddress" :scvSources="scvAddressColumnSources" @overwrite="overwrite" />
            <v-skeleton-loader v-else ref="skeleton" type="text" boilerplate width="200" />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// component
import scvColumn from '@/components/UserDetail/scvColumn'
// utils
import momentUtil from '@/utils/momentUtil'
// plugin
import moment from 'moment-timezone'

export default {
  name: 'defaultScvColumns',
  mixins: [
    momentUtil,
  ],
  components: {
    scvColumn,
  },
  data () {
    return {
      unintegrateDialog: false,
    }
  },
  props: {
    loading: {
      type: Boolean,
    },
    scvSettings: {
      type: Array,
    },
    userDetail: {
      type: Object,
    },
    scvDefaultColumnSources: {
      type: Array,
    },
    userType: {
      type: String,
    },
  },
  computed: {
    // TODO ENUM化
    isUnknown () {
      return this.userType === 'unknown'
    },
    isScv () {
      return this.userType === 'scv'
    },
    // TODO ENUM化
    scvName () {
      return this.findScvColumn(1, '-')
    },
    scvBirth () {
      return this.findScvColumn(2, '-')
    },
    scvZipcode () {
      return this.findScvColumn(3, '-')
    },
    scvAddress () {
      return this.findScvColumn(4, '-')
    },
    scvMailAddress () {
      return this.findScvColumn(5, '-')
    },
    scvGender () {
      return this.findScvColumn(6, '-')
    },
    scvLTV () {
      return this.findScvColumn(9, '-')
    },
    scvTelephone () {
      return this.findScvColumn(10, '-')
    },
    scvAge () {
      if (!this.userDetail || !this.userDetail.scvDefaultColumns) {
        return ''
      }

      // scvの生年月日
      const scvBirth = moment(this.scvBirth.scvColumnValue, 'YYYYMMDD')

      if (!scvBirth.isValid()) {
        return ''
      }

      // 今日の日付
      const today = moment(this.momentUtil.getCurrentDate())

      // 年齢を算出
      const age = today.diff(scvBirth, 'years')
      return '(' + age.toString() + ')'
    },
    scvBirthWithAge () {
      if (!this.userDetail || !this.userDetail.scvDefaultColumns) {
        return {}
      }
      const scvColumn = JSON.parse(JSON.stringify(this.scvBirth))
      if (scvColumn.scvColumnValue && this.scvAge) {
        scvColumn.scvColumnValue = scvColumn.scvColumnValue + ' ' + this.scvAge
      }
      return scvColumn
    },
    scvNameColumnSources () {
      return this.findScvColumnSources(1)
    },
    scvBirthColumnSources () {
      return this.findScvColumnSources(2)
    },
    scvZipcodeColumnSources () {
      return this.findScvColumnSources(3)
    },
    scvAddressColumnSources () {
      return this.findScvColumnSources(4)
    },
    scvMailAddressColumnSources () {
      return this.findScvColumnSources(5)
    },
    scvGenderColumnSources () {
      return this.findScvColumnSources(6)
    },
    scvLTVColumnSources () {
      return this.findScvColumnSources(9)
    },
    scvTelephoneColumnSources () {
      return this.findScvColumnSources(10)
    },
  },
  methods: {
    // TODO Util切り出し
    findScvColumn (scvColumnId, defaultValue = '') {
      if (!this.scvSettings || !this.userDetail || !this.userDetail.scvDefaultColumns || !scvColumnId) {
        return {}
      }
      const scvColumnSetting = this.scvSettings.find((col) => col.scvColumnId === scvColumnId)
      const userScvColumn = this.userDetail.scvDefaultColumns.find((col) => col.scvColumnId === scvColumnId)
      return {
        scvColumnId: scvColumnSetting ? scvColumnSetting.scvColumnId : undefined,
        scvColumnName: scvColumnSetting ? scvColumnSetting.scvLogicalColumnName : '',
        scvColumnValue: userScvColumn ? userScvColumn.scvColumnValue : defaultValue,
        isUpdated: userScvColumn ? userScvColumn.updated : false,
      }
    },
    findScvColumnSources (scvColumnId) {
      if (!this.scvDefaultColumnSources || !scvColumnId) {
        return []
      }
      const columnSources = this.scvDefaultColumnSources.find((col) => col.scvColumnId === scvColumnId)
      if (columnSources !== undefined) {
        return columnSources.scvColumnDataSetSources
      }
      return []
    },
    /**
     * SCV項目を上書き
     */
    overwrite (scvColumnId, newValue) {
      this.$emit('overwrite', scvColumnId, newValue)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/theme.scss';

// vuetify のコンポーネントじゃないと theme--light|dark クラスが付かないので親の v-card を指定
@include theme(v-card) using ($integral-core-theme) {
  .columnValue {
    border-bottom: 1px solid map-deep-get($integral-core-theme, 'divider', 'border');
  }
}

.column {
  display: flex;
  align-items: center;

  .columnName {
    width: 100px;
    font-size: 12px;
    line-height: 14px;
  }
  .columnValue {
    flex-grow: 1;
    padding: 6px 0 8px;
    height: 30px;
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
