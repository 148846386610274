<template>
  <v-container>
    <v-tabs v-model="selectedTab">
      <v-tab href="#light_v1.1">light v1.1</v-tab>
      <v-tab href="#dark_v1.1">dark v1.1</v-tab>
      <v-tab href="#light_v1.0">light v1.0</v-tab>
      <v-tab href="#dark_v1.0">dark v1.0</v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item value="light_v1.1">
        <iframe src="/integral-core-icons-light-v1.1/demo.html" frameborder="0" width="100%" height="800"></iframe>
      </v-tab-item>
      <v-tab-item value="dark_v1.1">
        <iframe src="/integral-core-icons-dark-v1.1/demo.html" frameborder="0" width="100%" height="800"></iframe>
      </v-tab-item>
      <v-tab-item value="light_v1.0">
        <iframe src="/integral-core-icons-light-v1.0/demo.html" frameborder="0" width="100%" height="800"></iframe>
      </v-tab-item>
      <v-tab-item value="dark_v1.0">
        <iframe src="/integral-core-icons-dark-v1.0/demo.html" frameborder="0" width="100%" height="800"></iframe>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
export default {
  name: 'Icons',
  data () {
    return {
      selectedTab: null,
    }
  },
}
</script>
