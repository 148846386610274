<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn :to="'/dataSet/setting/' + dataSetId + '/linkSetting'">
          {{ $t(`btn.back`) }}
        </v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <h3 class="lead-line">Web/SDK計測関連カラムと顧客マスタを連結する</h3>
      <h4>できること</h4>
      <div>
        「特定のユーザ(顧客フラグメント)であるか？」 と判断するための顧客フラグメント識別情報をユーザが追加できます。<br>
        → Web計測／SDK計測時に判断できる顧客フラグメントを自由に増やすことができ、より多くの特定されたユーザが蓄積できます。
      </div>

      <div class="sheetHeading">
        <v-spacer class="flex-grow-0" />
        <div>顧客マスタ&nbsp;&#040;<v-icon>ic-file-table-M</v-icon>&nbsp;or&nbsp;<v-icon>ic-API-M</v-icon>&#041;</div>
        <v-spacer class="flex-grow-0" />
        <div>Web/SDK計測カラム</div>
        <v-spacer class="flex-grow-0" />
      </div>

      <v-sheet class="ruleForm">
        <!-- リセットボタン -->
        <v-btn
          fab
          depressed
          x-small
          color="denial"
          @click.stop="originDataSource = {}"
        >
          <v-icon>ic-close-S</v-icon>
        </v-btn>
        <!-- 顧客マスタ -->
        <v-card class="master" outlined tile>
          <v-card-text>
            <v-select
              v-model="originDataSource"
              :label="$t('form.dataSource.name')"
              :prepend-icon="getIcon(originDataSource.connectorType)"
              item-text="dataSourceName"
              item-value="dataSourceId"
              return-object
              :items="originDataSourceList"
            />
            <v-select
              v-model="originDataSource.selectedColumns"
              :label="$t('form.dataSource.columnName')"
              :items="JSON.parse((originDataSource || {}).headerMetaJson || '{}').columns"
              :item-text="item => item.logicalName + ' (' + getDataType(item.dataType).label + ')'"
              item-value="index"
              :item-disabled="isDateTypeNotString"
              prepend-icon="ic-none"
              multiple
              item-color="default"
            >
            </v-select>
          </v-card-text>
        </v-card>

        <v-icon x-large>ic-link-LL</v-icon>

        <v-card outlined tile>
          <v-card-title>
            <v-icon>ic-info</v-icon>
            Web/SDK計測カラムとは？
          </v-card-title>
          <v-card-text>
            アクセスログの計測時に「特定のユーザ(顧客フラグメント)であるか？」を判定するための情報が保持されているカラムのことです。 INTEGRAL-COREが独自に内部で用意しているカラムになります。
          </v-card-text>
        </v-card>

        <div style="width: 100%;"></div>
        <div class="mt-2 ml-8">連結対象には、文字列型のカラムのみ指定できます</div>
      </v-sheet>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :to="'/dataSet/setting/' + dataSetId + '/linkSetting'" color="denial">{{ $t('btn.cancel') }}</v-btn>
      <v-btn @click="onSaveClick" color="primary">{{ $t('btn.save') }}</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from '@/axios'
// enum
import DATA_TYPES from '@/enum/DATA_TYPES'
import DATA_SOURCE_TYPES from '@/enum/DATA_SOURCE_TYPES'
import CONNECTOR_TYPES from '@/enum/CONNECTOR_TYPES'
// util
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'dataSourceWebLinkSetting',
  mixins: [
    DATA_TYPES,
    DATA_SOURCE_TYPES,
    CONNECTOR_TYPES,
    notifyUtil,
  ],
  data () {
    return {
      dataSetId: Number(this.$route.params.dataSetId),
      dataSources: {},
      masterLinkRuleList: [],
      webLinkRuleList: [],
      originDataSource: {},
    }
  },
  computed: {
    originDataSourceList () {
      return this.dataSources.masterList
        .filter(dataSource =>
          dataSource.connectorType !== this.CONNECTOR_TYPES.WEB.value &&
          dataSource.connectorType !== this.CONNECTOR_TYPES.IOS.value &&
          dataSource.connectorType !== this.CONNECTOR_TYPES.ANDROID.value,
        )
    },
    targetDataSourceList () {
      // コネクタが Web計測系のデータソース
      return this.dataSources.masterList
        .filter(dataSource =>
          dataSource.connectorType === this.CONNECTOR_TYPES.WEB.value ||
          dataSource.connectorType === this.CONNECTOR_TYPES.IOS.value ||
          dataSource.connectorType === this.CONNECTOR_TYPES.ANDROID.value,
        )
    },
    dataTypes () {
      const tmpDataTypes = Object.values(this.DATA_TYPES)
      return tmpDataTypes
    },
  },
  created () {
    this.fetchDataSources()
  },
  methods: {
    fetchDataSources () {
      this.$store.dispatch('dataSet/getDataSources', this.dataSetId)
        .then((res) => {
          this.dataSources = res.data.data
          this.fetchDataSetLinkRule()
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
        .finally(() => {})
    },
    fetchDataSetLinkRule () {
      this.$store
        .dispatch('dataSet/getDataSetLinkRule', this.dataSetId)
        .then(res => {
          this.masterLinkRuleList = this.findMasterLinkRuleList(res.data.data.masterLinkRuleList, this.dataSources.masterList)
          this.webLinkRuleList = this.findWebLinkRuleList(res.data.data.masterLinkRuleList, this.dataSources.masterList)
          this.setUpRule()
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    setUpRule () {
      const rule = this.webLinkRuleList[0]
      if (!rule) {
        this.originDataSource = {}
        return
      }
      const dataSource = this.originDataSourceList.find(dataSource => dataSource.dataSourceId === rule.originDataSourceId)
      this.originDataSource = JSON.parse(JSON.stringify(dataSource))
      if (rule.originColumnReference && rule.originColumnReference.indexList) {
        this.originDataSource.selectedColumns = rule.originColumnReference.indexList
      }
    },
    onSaveClick () {
      // 未選択の場合
      if (this.originDataSource === null || this.originDataSource.dataSourceId === undefined) {
        // マスタ間結合ルールなしの場合は削除処理
        if (this.masterLinkRuleList.length === 0) {
          axios({ method: 'delete', url: '/dataSet/' + this.dataSetId + '/dataSetRowCreateRule/' })
            .then(() => {
              this.notifySuccessMessage(this.$t('notify.dataSourceLink') + this.$t('notify.success.delete'))
              this.fetchDataSetLinkRule()
            }).catch(err => {
              this.handleErrorResponse(err)
            })
        } else {
          // マスタ間結合ルール有りの場合はそれだけ送信
          axios.put('/dataSet/' + this.dataSetId + '/dataSetRowCreateRule/', {
            dataSetRowCreateRuleList: this.masterLinkRuleList,
          }).then(() => {
            this.notifySuccessMessage(this.$t('notify.linkRule') + this.$t('notify.success.update'))
            this.fetchDataSetLinkRule()
          }).catch((err) => {
            this.handleErrorResponse(err)
          })
        }
        return
      }

      // validate
      const errors = []
      this.originDataSource.selectedColumns.forEach(index => {
        const column = JSON.parse(this.originDataSource.headerMetaJson)?.columns?.find(col => col.index === index)
        if (column !== undefined && column.dataType !== this.DATA_TYPES.STRING.value) {
          errors.push(this.$t('notify.error.dataSet.linkRule.invalidMeasureRule'))
        }
      })
      if (errors.length > 0) {
        errors.forEach((e, index) => setTimeout(() => { this.notifyErrorMessage(e) }, index * 300))
        return
      }

      // リクエストパラメータの形式に変換
      // Web計測/SDK系 (WEB, iOS, Android) は対象の連結設定を1件しか登録できないので専用のフォームに詰めてあるのを展開する
      let ruleList = this.targetDataSourceList.map(dataSource => {
        return {
          originDataSourceId: this.originDataSource.dataSourceId,
          originColumnReference: { indexList: this.originDataSource.selectedColumns },
          targetDataSourceId: dataSource.dataSourceId,
          targetColumnReference: { indexList: [3] }, // index 3 は customer_identify
        }
      })

      // マスタ間結合のデータセット行構築ルールとマージ (Web系が後ろ)
      ruleList = this.masterLinkRuleList.concat(ruleList)
      axios.put('/dataSet/' + this.dataSetId + '/dataSetRowCreateRule/', {
        dataSetRowCreateRuleList: ruleList,
      }).then(() => {
        this.notifySuccessMessage(this.$t('notify.linkRule') + this.$t('notify.success.update'))
        this.fetchDataSetLinkRule()
      }).catch((err) => {
        this.handleErrorResponse(err)
      })
    },
    findMasterLinkRuleList (ruleList, masterList) {
      return ruleList.filter(rule => {
        const origin = masterList.find(master => master.dataSourceId === rule.originDataSourceId)
        const target = masterList.find(master => master.dataSourceId === rule.targetDataSourceId)
        return origin.connectorType !== this.CONNECTOR_TYPES.WEB.value &&
          origin.connectorType !== this.CONNECTOR_TYPES.IOS.value &&
          origin.connectorType !== this.CONNECTOR_TYPES.ANDROID.value &&
          target.connectorType !== this.CONNECTOR_TYPES.WEB.value &&
          target.connectorType !== this.CONNECTOR_TYPES.IOS.value &&
          target.connectorType !== this.CONNECTOR_TYPES.ANDROID.value
      }).map(rule => {
        return {
          originDataSourceId: rule.originDataSourceId,
          originColumnReference: JSON.parse(rule.originColumnReference),
          targetDataSourceId: rule.targetDataSourceId,
          targetColumnReference: JSON.parse(rule.targetColumnReference),
        }
      })
    },
    findWebLinkRuleList (ruleList, masterList) {
      return ruleList.filter(rule => {
        const origin = masterList.find(master => master.dataSourceId === rule.originDataSourceId)
        const target = masterList.find(master => master.dataSourceId === rule.targetDataSourceId)
        return origin.connectorType === this.CONNECTOR_TYPES.WEB.value ||
          origin.connectorType === this.CONNECTOR_TYPES.IOS.value ||
          origin.connectorType === this.CONNECTOR_TYPES.ANDROID.value ||
          target.connectorType === this.CONNECTOR_TYPES.WEB.value ||
          target.connectorType === this.CONNECTOR_TYPES.IOS.value ||
          target.connectorType === this.CONNECTOR_TYPES.ANDROID.value
      }).map(rule => {
        return {
          originDataSourceId: rule.originDataSourceId,
          originColumnReference: JSON.parse(rule.originColumnReference),
          targetDataSourceId: rule.targetDataSourceId,
          targetColumnReference: JSON.parse(rule.targetColumnReference),
        }
      })
    },
    getDataType (value) {
      return this.dataTypes.find(dataType => dataType.value === value)
    },
    getIcon (type) {
      switch (type) {
        case this.CONNECTOR_TYPES.CSV.value:
          return 'ic-file-table-M'
        case this.CONNECTOR_TYPES.API.value:
          return 'ic-API-M'
        default:
          return 'ic-none'
      }
    },
    isDateTypeNotString (item) {
      return item.dataType !== this.DATA_TYPES.STRING.value
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

#app {
  @include theme(v-sheet) using ($integral-core-theme) {
    &.ruleForm {
      background: map-deep-get($integral-core-theme, 'views', 'masterLinkSetting', 'sheets', 'background') 0% 0% no-repeat padding-box;
    }
  }
}
.sheetHeading {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  padding: 12px 12px 0;
  font-weight: bold;
  font-size: 16px;
}
.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  padding: 12px;

  > .v-card {
    height: 166px;
    flex: 1;
    margin: 0 10px;
  }
}
.v-list {
  &::v-deep .v-list-item {
    &.v-list-item--active::before {
      opacity: 0.12 !important;
    }
    &.v-list-item--highlighted::before {
      opacity: 0.04;
    }
  }
}
</style>
