<template>
  <v-container fluid>
    <div align-self="end" class="text-right pb-1">
      <!-- カラム順番・ON/OFF -->
      <v-btn
        @click="openDrawer = true"
      >
        <v-icon>mdi-tune</v-icon>
        {{ $t('text.scvs.columnDisplaySetting') }}
      </v-btn>
    </div>
    <v-card flat>
      <div class="user">ユーザー</div>
      <v-data-table
        v-if="activeColumnIds"
        :headers="modTableHeaders"
        :items="segmentUsers"
        :loading="loadingSegmentsUsers"
        disable-filtering
        disable-pagination
        disable-sort
        hide-default-footer
        fixed-header
        >
        <template #body="{ items }">
          <tbody class="pb-2">
            <tr v-if="items.length === 0">
              <td class="text-center" :colspan="modTableHeaders.length">{{$t('text.scvs.noResult')}}</td>
            </tr>
            <tr v-for="item in items" :key="item.scvId" @click="onClickTableRow(item.scvId)">
              <td v-for="(header) in modTableHeaders" :key="header.value">
                <!-- 所属セグメント数 -->
                <v-tooltip v-if="header.value === 0" bottom>
                  <template #activator="{ on }">
                    <span v-on="on">{{ item.segments.length }}</span>
                  </template>
                  <ul>
                    <li v-for="segment in item.segments" :key="segment.segmentId">
                      {{ segment.segmentName }}
                    </li>
                  </ul>
                </v-tooltip>
                <!-- SCV項目 -->
                <v-tooltip v-else bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      {{ item.customerColumnList | getColumn(header.value) | ellipsis(30,'...') }}
                    </span>
                  </template>
                  <span class="break-all">
                    {{ item.customerColumnList | getColumn(header.value) }}
                  </span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-row v-else dense :justify="'center'" class="mt-1">
        <v-progress-circular indeterminate color="primary" />
      </v-row>
    </v-card>
    <v-pagination v-model="params.page" :length="pages" total-visible="7" :disabled="loadingSegmentsUsers" @input="getSegmentUsers()" class="pt-7 pb-2"/>
    <!-- セグメントユーザー詳細 -->
    <v-navigation-drawer
      v-model="showScvDetail"
      width="80%"
      app
      fixed
      temporary
      right
    >
      <information-bar>
        <template #left>
          <v-btn @click="showScvDetail = false">
            {{ $t(`btn.close`) }}
          </v-btn>
        </template>
        <template #right>
          <v-btn :to="{ name: 'UserScvDetail', params: { scvId: scvId } }">{{ $t('btn.open') }}</v-btn>
          <v-btn class="mx-5" icon x-small :to="{ name: 'UserScvDetail', params: { scvId: scvId } }" target="_blank"><v-icon>mdi-open-in-new</v-icon></v-btn>
        </template>
      </information-bar>

      <user-scv-detail
        v-if="showScvDetail && scvId"
        :scvId="scvId" />
    </v-navigation-drawer>

    <!-- カラム順番・ON/OFF ドロワー -->
    <column-display-setting
      :openDrawer="openDrawer"
      @switchDrawer="openDrawer = $event"
      :scvColumns="scvColumns"
      @sortScvColumns="scvColumns = $event"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import userScvDetail from '@/views/userScvDetail'
// component
import columnDisplaySetting from '@/components/common/columnDisplaySetting'
// utils
import momentUtil from '@/utils/momentUtil'
import notifyUtil from '@/utils/notifyUtil'

const DEFAULT_PAGE = 1
const DEFAULT_LIMIT = 25

export default {
  components: {
    'user-scv-detail': userScvDetail,
    'column-display-setting': columnDisplaySetting,
  },
  props: {
    segmentId: {
      type: Number,
      default: 0,
    },
  },
  mixins: [
    momentUtil,
    notifyUtil,
  ],
  data () {
    return {
      segmentUsers: [],
      scvColumns: [],
      openDrawer: false,
      showScvDetail: false,
      params: {
        segmentId: '',
        page: DEFAULT_PAGE,
        limit: DEFAULT_LIMIT,
      },
      scvId: null,
      userPaging: {},
    }
  },
  computed: {
    ...mapGetters('auth', ['canGetThis']),
    activeColumnIds: {
      get () {
        return this.$store.state.scvs.activeColumnIds
      },
      set (newVal) {
        this.$store.dispatch('scvs/updateActiveColumnIds', newVal)
      },
    },
    filterdScvColumns () {
      // 所属セグメント数の表示領域 (0) またはアクティブなカラム
      return this.scvColumns.filter((column) => this.activeColumnIds === 0 || this.activeColumnIds.includes(column.value))
    },
    modTableHeaders () {
      const tmpTableHeaders = JSON.parse(JSON.stringify(this.filterdScvColumns))
      return tmpTableHeaders.map(tableHeader => {
        return {
          text: tableHeader.text,
          value: tableHeader.value,
          custom: tableHeader.custom,
          width: '200',
          sortable: false,
        }
      })
    },
    pages () {
      return Math.ceil(this.userPaging.filteredTotalCount / this.userPaging.limit) || 0
    },
    loadingSegmentsUsers () {
      return this.$store.state.segmentUsers.loadingSegmentsUsers
    },
  },
  watch: {
    segmentId: {
      handler () {
        this.getSegmentUsers()
        // SCV詳細で別セグメントを選択時に閉じる
        this.showScvDetail = false
      },
      immediate: true,
    },
    scvColumns () {
      // draggable で scvColumns だけを変更すると v-list-item-group が変更を検知できずハイライトがずれてしまうので、
      // v-model に指定している activeColumnIds を無理やり変更して検知させる
      this.activeColumnIds = JSON.parse(JSON.stringify(this.activeColumnIds))
    },
  },
  created () {
    if (this.canGetThis('scvSetting')) {
      this.$store.dispatch('scvSettings/getValidScvSettings')
        .then((res) => {
          this.scvColumns = JSON.parse(JSON.stringify(res.scvSettings))
            .map((scvSetting) => {
              return { text: scvSetting.scvLogicalColumnName, value: scvSetting.scvColumnId, custom: scvSetting.isCustom }
            })
            .sort((a, b) => this.$store.state.scvs.sortedColumnIds?.indexOf(a.value) - this.$store.state.scvs.sortedColumnIds?.indexOf(b.value))
          this.scvColumns.push({ text: '所属セグメント数', value: 0 })
          if (this.activeColumnIds === null) {
            // 初期表示カラムの選択
            this.$store.dispatch('scvs/resetActiveColumnIds')
          }
        })
    }
  },
  methods: {
    /**
     * セグメントユーザーリストの取得
     */
    getSegmentUsers () {
      this.params.segmentId = this.segmentId
      this.$store.dispatch('segmentUsers/getSegmentsCustomer', this.params)
        .then((res) => {
          this.userPaging = res.paging
          this.segmentUsers = res.userScvList
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    onClickTableRow (scvId) {
      this.scvId = scvId
      this.showScvDetail = true
    },
  },
  filters: {
    getColumn (scvColumnValueList, scvColumnId) {
      const column = scvColumnValueList.find(scvColumn => scvColumn.scvColumnId === Number(scvColumnId))
      if (!column) return null
      return column.scvColumnValue
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

tr {
  cursor: pointer;
}

@include theme(v-data-table) using ($integral-core-theme) {
  tbody tr td {
      border-bottom: 1px solid map-deep-get($integral-core-theme, 'data-table', 'td-border') !important;
  }
}

.user {
  font-size: 12px;
  line-height: 16px;
}
</style>
