<template>
  <v-card class="editorWrapper" tile outlined>
    <div ref="editor" class="monaco-editor"></div>
  </v-card>
</template>

<script>
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api'
// eslint-disable-next-line import/no-webpack-loader-syntax
import ESLintWorker from 'worker-loader!@/workers/eslint.worker'

export default {
  name: 'jsEditor',
  props: {
    value: {
      type: String,
      default () {
        return ''
      },
    },
  },
  data () {
    return {
      monaco: null,
      editor: null,
      model: null,
      subscription: null,
      linterWorker: null,
    }
  },
  watch: {
    '$vuetify.theme.dark': {
      handler () {
        if (this.editor) {
          this.monaco.editor.setTheme(this.$vuetify.theme.dark ? 'vs-dark' : 'vs')
        }
      },
      immediate: true,
    },
    value () {
      if (this.value !== this.editor.getValue()) {
        this.editor.getModel().setValue(this.value)
      }
    },
  },
  async mounted () {
    this.initEditorSetting()

    const el = this.$refs.editor
    this.monaco = monaco

    this.editor = this.monaco.editor.create(el, {
      value: this.value,
      language: 'javascript',
      theme: this.$vuetify.theme.dark ? 'vs-dark' : 'vs',
      wordWrap: true,
      automaticLayout: true,
      minimap: {
        enabled: false,
      },
    })

    window.addEventListener('resize', (e) => {
      this.editor.layout()
    })

    this.setOnDidChangeContent()
  },
  destroyed () {
    this.editor?.dispose()
    this.model?.dispose()
    this.subscription?.dispose()
    this.linterWorker?.terminate()
  },
  methods: {
    /**
     * https://microsoft.github.io/monaco-editor/playground.html#extending-language-services-configure-javascript-defaults
     */
    initEditorSetting () {
      // validation settings
      monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: true,
        noSyntaxValidation: true,
      })

      // compiler options
      monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
        target: monaco.languages.typescript.ScriptTarget.ES2019,
        moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
        module: monaco.languages.typescript.ModuleKind.CommonJS,
        allowNonTsExtensions: true,
        noEmit: true,
        allowJs: true,
      })

      // extra libraries
      const libSource = `
        declare module "segment-exporter" {
          /** 実行時に対象となった (セグメントされた) カスタマーの総数を取得します。 */
          function countCurrent(): Promise<number>;

          /** 前回実行時に対象となった (セグメントされた) カスタマーの総数を取得します。 */
          function countPrevious(): Promise<number>;

          /** 実行時に対象となった (セグメントされた) カスタマーを取得します。カスタマーのデータが1件ずつ callback に渡されます。 */
          function readCurrent(callback: (customer: Object) => void, offset: number, limit: number): Promise<any>;

          /** 前回実行時に対象となった (セグメントされた) カスタマーを取得します。カスタマーのデータが1件ずつ callback に渡されます。 */
          function readPrevious(callback: (customer: Object) => void, offset: number, limit: number): Promise<any>;

          /** 実行対象のセグメントの詳細を取得します */
          function readSegment(): Promise<any>;
        }
      `
      const libUri = 'segment-exporter.d.ts'
      monaco.languages.typescript.javascriptDefaults.addExtraLib(libSource, libUri)
      // When resolving definitions and references, the editor will try to use created models.
      // Creating a model for the library allows "peek definition/references" commands to work with the library.
      this.model = monaco.editor.createModel(libSource, 'typescript', monaco.Uri.parse(libUri))

      this.linterWorker = new ESLintWorker()
      this.linterWorker.addEventListener('message', ({ data }) =>
        this.updateMarkers(data),
      )
    },
    setOnDidChangeContent () {
      this.subscription = this.editor.getModel().onDidChangeContent(() => {
        const value = this.editor.getValue()
        this.lintCode(value)
        this.$emit('input', value)
      })
    },
    updateMarkers ({ markers, version }) {
      requestAnimationFrame(() => {
        const model = this.editor.getModel()

        if (model && model.getVersionId() === version) {
          monaco.editor.setModelMarkers(model, 'eslint', markers)
        }
      })
    },
    lintCode (code) {
      const model = this.editor.getModel()
      monaco.editor.setModelMarkers(model, 'eslint', [])

      this.linterWorker.postMessage({
        code,
        version: model.getVersionId(),
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.editorWrapper {
  height: 100%;
}
.monaco-editor {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
</style>
