<template>
  <v-card tile elevation="0" id="releaseNote">
    <information-bar>
    </information-bar>
    <v-card-text>
      <v-row>
        <v-col cols="10" class="pr-2">
          <h3>{{version}}</h3>
          <v-card outlined tile>
            <v-card-text class="pa-3">
              <component :is="version"></component>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2" class="pl-2 index">
          <div>リリースノート</div>
          <v-card outlined tile>
            <v-card-text class="pa-3">
              <ul class="item-inPageScroll_y">
                <template v-for="components in $options.components">
                  <li v-if="components.name.includes('version')" :key="components.name">
                    <a href="" @click.prevent="changeVersion(components.name)">{{components.name.replace("version", "v")}}</a>
                    <ul v-if="components.name === version" class="pl-4 pt-1">
                      <template v-for="minorVersion in components.minorVersions">
                        <li :key="minorVersion" class="pb-0">
                          <a :href="'#' + minorVersion">{{ minorVersion }}</a>
                        </li>
                      </template>
                    </ul>
                  </li>
                </template>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import version11 from '@/components/Release/v11'
import version10 from '@/components/Release/v10'
import version9 from '@/components/Release/v9'
import version8 from '@/components/Release/v8'
import version7 from '@/components/Release/v7'
import version6 from '@/components/Release/v6'
import version5 from '@/components/Release/v5'

// 最新のバージョン情報
const NEWEST_MEASURE_VERSION = 'version11'

export default {
  name: 'Release',
  components: {
    version11,
    version10,
    version9,
    version8,
    version7,
    version6,
    version5,
  },
  data () {
    return {
      version: NEWEST_MEASURE_VERSION,
      items: [],
    }
  },
  methods: {
    changeVersion (verText) {
      this.version = verText
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-card) using ($integral-core-theme) {
  ul li {
    a {
      color: map-deep-get($integral-core-theme, 'views', 'release', 'link', 'text');
    }
    ul li a {
      text-decoration: underline map-deep-get($integral-core-theme, 'views', 'release', 'link', 'text');
      color: map-deep-get($integral-core-theme, 'views', 'release', 'minor-link', 'text');
    }
  }
}

h3 {
  margin-top: -2px;
  margin-bottom: 7px;
}
.v-card {
  ul {
    padding-left: 0;
    li {
      list-style: none;
      font-size: 16px;
    }
  }
}
.index {
  div {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    padding-top: 2px;
    padding-bottom: 6px;
  }
  .v-card {
    ul li {
      padding-bottom: 10px;
      a {
        text-decoration: none;
        font-weight: 500;
      }
      ul li a {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
</style>
