export default {
  notify: {
    account: 'アカウント',
    accountRole: '権限',
    scvUnion: '統合設定',
    dataSet: 'データセット',
    linkRule: '顧客マスタ連結設定',
    dataSourceLink: '連結設定',
    dataSource: 'データソース',
    schema: 'スキーマ',
    csvImportType: '取込種別',
    dataSourceDomain: 'ドメイン',
    file: 'ファイル',
    job: 'ジョブ',
    master: '顧客マスタ',
    mfaDeviceRegistered: 'MFAデバイス登録',
    password: 'パスワード',
    segment: 'セグメント',
    segmentExportScript: 'セグメントエクスポートスクリプト',
    scvSetting: 'SCV項目',
    transactionSummary: 'トランザクションサマリ',
    success: {
      create: 'の登録が完了しました',
      delete: 'の削除が完了しました',
      update: 'の更新が完了しました',
      change: 'の変更が完了しました',
      upload: 'のアップロードが完了しました',
      copyToClipboard: 'クリップボードにコピーしました',
      csvSchemaInfer: 'CSVのスキーマ解析が完了しました',
      orderCustomScvSetting: 'カスタムSCV項目を並べ替えました',
      setScvUnintegration: 'カスタマー統合の解除を設定しました',
      executeImporter: '取り込み処理を開始しました',
    },
    error: {
      scvUnion: {
        noTarget: '統合対象のカスタマーを選択してください',
        noDefaultScv: '統合には主カスタマーを選択する必要があります',
        defaultScvNotTargeted: '主カスタマーは統合対象に含む必要があります',
        missingColumns: '全ての項目で採用する値を選択してください',
      },
      dataSet: {
        linkRule: {
          invalidMeasureRule: 'Web/SDK 計測連結カラムの連結条件が不正です',
          required: '{index}番目の連結設定のデータソースを選択してください',
          columnsRequired: '{index}番目の連結設定の対象カラムを選択してください',
          sameDataSource: '{index}番目の連結設定は同一のデータソースを指定しています',
        },
        transactionLinkRule: {
          sameTransaction: '1つのトランザクションに複数の連結設定はできません',
        },
      },
      dataSource: {
        requireUserIdentifyColumn: '顧客識別子列の選択は必須です',
        requireDatetimeIdentifyColumn: '日時識別子列の選択は必須です',
        requireTransactionIdentifierColumn: 'トランザクション識別子列の選択は必須です',
        userIdentifyColumnShouldBeStringType: '顧客識別子は文字列型である必要があります',
        datetimeIdentifyColumnShuldBeDateType: '日時識別子列は日時型である必要があります',
        duplicatePhysicalName: '物理名が重複しています(大文字小文字問わず)',
        prohibitedUseDoubleQuotation: '物理名に " ダブルクォーテーションは使用できません',
      },
      file: {
        notMultipleUpload: '一度にアップロード可能なファイルは1件までです',
        notCsv: 'CSVファイルを選択してください',
        notJson: 'JSONファイルを選択してください',
        fileSizeOver200M: '200MB未満のファイルを選択してください',
        columnSizeOver1500: '1500カラム未満のファイルを選択してください',
      },
      form: {
        invalidPeriod: '期間指定の値が不正です',
        invalidValue: '不正な入力が含まれています',
      },
      jobs: {
        noName: 'タスク名が未入力です',
        noTask: 'タスクを1件以上作成してください',
        noAuthFile: '認証キーJSONは必須項目です',
        noQuery: 'クエリは必須です',
        overQuery: '入力できるクエリは1件までです',
        bulkDelete: 'ジョブ設定の削除に一部失敗しました',
      },
      segments: {
        noFilter: 'フィルタ条件を設定してください',
        requireStartDateOrEndDate: '開始日付または終了日付は必須です',
        mustBeStartDateBeforeEndDate: '開始日付は終了日付より前に設定してください',
        requireRelativeDayFromOrRelativeDayTo: '開始日または終了日は必須です',
        mustBeRelativeDayFromBeforeRelativeDayTo: '開始日は終了日より前に設定してください',
        bulkDelete: 'セグメントの削除に一部失敗しました',
        noCsvDownloadColumn: 'ダウンロード項目を1つ以上選択してください',
      },
      scvSetting: {
        dataTypeMismatch: 'データタイプ型が異なります',
        disabledDataSetCOlumn: 'データセットの対象カラムが削除済みのため、登録できません',
      },
      transactionSummary: {
        noTransaction: '集計データは必須項目です',
        mismatchTransactionSummaryType: 'サマリ種別に対して集計不可能な集計対象が含まれています',
      },
    },
    check: {
      delete: '「{0}」を削除してよろしいですか？',
      create: 'を作成してよろしいですか？',
      update: 'を更新してよろしいですか？',
      accounts: {
        recreate: '現在のパスワードを破棄してパスワードを再発行してよろしいですか？',
        unregisterDevice: '2要素認証に用いているデバイスの登録を解除してよろしいですか？',
      },
      segments: {
        update: '以下のフィルタ条件でセグメントを更新してよろしいですか？',
        create: '以下のフィルタ条件でセグメントを作成してよろしいですか？',
      },
    },
  },
}
