<template>
  <div>
    <!-- v.11.14.0 -->
    <h4 id="v11.14.0">v11.14.0 (2022/12/01~)</h4>
    <h5>全般</h5>
    <ul>
      <li>管理画面のUIを調整しました。</li>
    </ul>

    <!-- v.11.13.0 -->
    <h4 id="v11.13.0">v11.13.0 (2022/11/01~)</h4>
    <h5>データセット</h5>
    <h6>トランザクションサマリ設定</h6>
    <ul>
      <li>セグメント設定同様に相対日付の条件指定が可能になりました。</li>
    </ul>

    <!-- v.11.12.0 -->
    <h4 id="v11.12.0">v11.12.0 (2022/10/11~)</h4>
    <h5>セグメント</h5>
    <h6>セグメント詳細</h6>
    <ul>
      <li>CSVファイルダウンロードで、CSVに出力するカラムの選択と並べ替えが可能になりました。</li>
    </ul>
    <h6>セグメント設定</h6>
    <ul>
      <li>「属性」「トランザクションサマリ」に対して年を除外した相対日付の条件指定が可能になりました。</li>
    </ul>

    <!-- v.11.11.0 -->
    <h4 id="v11.11.0">v11.11.0 (2022/08/25~)</h4>
    <h5>セグメント</h5>
    <h6>セグメント詳細</h6>
    <ul>
      <li>セグメントに所属するカスタマーの一覧を、管理画面からCSV形式でダウンロードすることが可能になりました。</li>
    </ul>
    <h6>セグメント設定</h6>
    <ul>
      <li>セグメント設定で、「属性」「トランザクションサマリ」に対して相対日付の条件指定が可能になりました。</li>
    </ul>

    <!-- v.11.10.0 -->
    <h4 id="v11.10.0">v11.10.0 (2022/07/14~)</h4>
    <h5>ジョブ</h5>
    <h6>ジョブ設定</h6>
    <ul>
      <li>SFTP サーバからのデータ取り込みが可能になりました。</li>
    </ul>

    <!-- v.11.9.0 -->
    <h4 id="v11.9.0">v11.9.0 (2022/06/15~)</h4>
    <h5>カスタマー</h5>
    <h6>カスタマー一覧</h6>
    <ul>
      <li>「表示項目設定」の状態が画面をリロードや別タブで表示しても保持されるようになりました。</li>
    </ul>
    <h5>連携ツール</h5>
    <h6>セグメントエクスポートスクリプト</h6>
    <ul>
      <li>任意の JavaScript 外部モジュールを追加できるようになりました。(設定方法の詳細についてはお問い合わせください)</li>
    </ul>

    <!-- v.11.8.0 -->
    <h4 id="v11.8.0">v11.8.0 (2022/05/19~)</h4>
    <h5>データセット</h5>
    <h6>データソース詳細 (CSVコネクタ)</h6>
    <ul>
      <li>事前に取り込み種別 (差分/差替) を明示するように指定方法を変更しました。</li>
      <li>次回の取り込み時の対象ファイルの一覧が表示されるようになりました。</li>
      <li>画面からCSV取り込み処理を実行可能になりました。</li>
    </ul>

    <!-- v.11.7.0 -->
    <h4 id="v11.7.0">v11.7.0 (2022/04/20~)</h4>
    <h5>ログイン</h5>
    <ul>
      <li>MFA (多要素認証) の対応を追加しました。</li>
    </ul>
    <h5>連携ツール</h5>
    <h6>セグメントエクスポートスクリプト追加・編集</h6>
    <ul>
      <li>トランザクションサマリの出力のサポートを追加しました。</li>
    </ul>
    <h5>セグメント</h5>
    <h6>セグメント作成</h6>
    <ul>
      <li>セグメント設定の上限が契約企業個別に設定できるようになりました。</li>
    </ul>

    <!-- v.11.6.0 -->
    <h4 id="v11.6.0">v11.6.0 (2022/03/10~)</h4>
    <h5>セグメント</h5>
    <h6>セグメント作成・編集</h6>
    <ul>
      <li>ページ絞り込み機能を追加しました。</li>
    </ul>
    <h5>データセット</h5>
    <ul>
      <li>いくつかの不具合を修正しました。</li>
    </ul>

    <!-- v.11.5.0 -->
    <h4 id="v11.5.0">v11.5.0 (2022/02/24~)</h4>
    <h5>全般</h5>
    <ul>
      <li>管理画面のUIを調整しました。</li>
    </ul>
    <h5>セグメント</h5>
    <h6>セグメント作成・編集</h6>
    <ul>
      <li>NULL に対するフィルタ条件を追加しました。</li>
    </ul>
    <h5>ジョブ</h5>
    <h6>ジョブ履歴</h6>
    <ul>
      <li>詳細ボタンからジョブ編集画面へ移動することが可能になりました。</li>
    </ul>

    <!-- v.11.4.0 -->
    <h4 id="v11.4.0">v11.4.0 (2022/01/19~)</h4>
    <h5>全般</h5>
    <ul>
      <li>管理画面のUIを調整しました。</li>
    </ul>
    <h5>連携ツール</h5>
    <h6>エクスポートスクリプト一覧</h6>
    <ul>
      <li>セグメントエクスポートスクリプト機能を追加しました。</li>
    </ul>

    <!-- v.11.3.2 -->
    <h4 id="v11.3.2">v11.3.2 (2021/09/28~)</h4>
    <h5>SCV設定</h5>
    <ul>
      <li>項目に採用する値を同一データセット内から優先度をつけてカラムを選択できるようになりました。</li>
    </ul>
    <h5>データセット</h5>
    <ul>
      <li>同一データセットに複数のユーザー定義マスタデータソースを定義できるようになりました。</li>
      <li>ユーザー定義マスタデータソース同士の結合が可能になりました。</li>
    </ul>
    <!-- v.11.3.0 -->
    <h4 id="v11.3.0">v11.3.0 (2021/06/24~)</h4>
    <h5>全般</h5>
    <ul>
      <li>管理画面のUIを変更しました。</li>
    </ul>
    <h5>権限一覧</h5>
    <ul>
      <li>機能別の権限設定機能が追加されました。</li>
    </ul>
    <!-- v.11.2.0 -->
    <h4 id="v11.2.0">v11.2.0 (2021/04/14~)</h4>
    <h5>セグメント設定</h5>
    <ul>
      <li>標準セグメント設定にて、入力されたフィルター条件の検索クエリのサンプル出力機能が追加されました。</li>
      <li>フィルター条件にトランザクションサマリが追加されました。</li>
      <li>SQLセグメントに実行スケジュール指定が可能になりました。</li>
    </ul>
    <h5>データセット一覧</h5>
    <ul>
      <li>ユーザー定義マスタデータソースとトランザクションデータソースを連結する機能が追加されました。</li>
      <li>いくつかの不具合を修正しました。</li>
    </ul>
    <h5>トランザクションサマリ一覧</h5>
    <ul>
      <li>トランザクションサマリ設定機能が追加されました。</li>
    </ul>

    <!-- v11.1.0 -->
    <h4 id="v11.1.0">v11.1.0 (2021/02/09~)</h4>
    <h5>全般</h5>
    <ul>
      <li>
        セグメントのフィルター条件の表示形式を変更しました。
      </li>
    </ul>
    <h5>SCV設定</h5>
    <ul>
      <li>SCV項目の物理名の表示を追加しました。</li>
      <li>いくつかの不具合を修正しました。</li>
    </ul>
    <h5>セグメント一覧</h5>
    <ul>
      <li>最終解析時刻の表示を追加しました。</li>
    </ul>
    <h5>セグメント設定</h5>
    <ul>
      <li>OR検索を追加しました。</li>
      <li>フィルター条件をグループ化して設定可能になりました。グループ化された条件は優先度の高い条件として解析されます。</li>
      <li>フィルター条件の期間指定機能を拡張しました。</li>
      <li>セグメント解析機能の更新に伴い、セグメント設定上限数が10件となりました。</li>
    </ul>
    <h5>データセット一覧</h5>
    <ul>
      <li>いくつかの不具合を修正しました。</li>
    </ul>

    <!-- v11.0.0 -->
    <h4 id="v11.0.0">v11.0.0 (2020/12/09~)</h4>
    <h5>全般</h5>
    <ul>
      <li>各検索機能の不具合を修正しました。</li>
    </ul>
    <h5>カスタマー検索</h5>
    <ul>
      <li>いくつかの不具合を修正しました。</li>
    </ul>
    <h5>SCV設定</h5>
    <ul>
      <li>SCV項目の物理名の表示を追加しました。</li>
      <li>いくつかの不具合を修正しました。</li>
    </ul>
    <h5>カスタマー詳細</h5>
    <ul>
      <li>いくつかの不具合を修正しました。</li>
    </ul>
    <h5>カスタマー統合設定</h5>
    <ul>
      <li>カスタマー統合機能が追加されました。</li>
    </ul>
    <h5>セグメント一覧</h5>
    <ul>
      <li>最終解析時刻の表示を追加しました。</li>
    </ul>
    <h5>セグメント設定</h5>
    <ul>
      <li>いくつかの不具合を修正しました。</li>
    </ul>
    <h5>データセット一覧</h5>
    <ul>
      <li>データセット・データソース機能が追加されました。</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'version11',
  minorVersions: [
    'v11.14.0',
    'v11.13.0',
    'v11.12.0',
    'v11.11.0',
    'v11.10.0',
    'v11.9.0',
    'v11.8.0',
    'v11.7.0',
    'v11.6.0',
    'v11.5.0',
    'v11.4.0',
    'v11.3.2',
    'v11.3.0',
    'v11.2.0',
    'v11.1.0',
    'v11.0.0',
  ],
}
</script>
