<template>
  <v-card tile class="filterForm mb-5">
    <v-expansion-panels accordion flat>
      <v-expansion-panel>
        <v-expansion-panel-header class="pa-3">
          <v-row class="justify-start">
            <v-col>
              <v-icon>ic-filter-S</v-icon>フィルタリング
              <v-btn :class="{ filterLabel: true, filterActive: isFiltering }" class="ml-7" text small @click.stop="switchFilter()">
                フィルター{{ isFiltering ? 'ON' : 'OFF' }}
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <slot />
          <v-card-actions class="px-0 pb-0">
            <v-spacer />
            <div
              v-if="total !== undefined && total !== null && filteredTotal !== undefined && filteredTotal !== null"
              class="mr-4 count"
            >
              {{ filteredTotal }}<span> {{ $t(`text.itemCnt.filtered`) }}&nbsp;&#047;&nbsp;{{ $t(`text.itemCnt.all`) }}</span>{{ total }}<span> {{ $t(`text.itemCnt.count`) }}</span>
            </div>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn class="mr-3" v-on="on" @click="reset()">
                  {{ $t(`btn.reset`) }}
                </v-btn>
              </template>
              <span>
                {{ $t(`tooltip.resetFilter`) }}
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn color="secondary" v-on="on" @click="search()" :disabled="loading">
                  {{ $t(`btn.setting`) }}
                </v-btn>
              </template>
              <span>
                {{ $t(`tooltip.setFilter`) }}
              </span>
            </v-tooltip>
          </v-card-actions>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
export default {
  data () {
    return {
      expanded: true,
    }
  },
  props: {
    isFiltering: {
      type: Boolean,
      default () {
        return true
      },
    },
    total: {
      type: Number,
    },
    filteredTotal: {
      type: Number,
    },
    loading: {
      type: Boolean,
      default () {
        return false
      },
    },
  },
  methods: {
    reset () {
      this.$emit('reset')
    },
    search () {
      this.$emit('search')
    },
    switchFilter () {
      this.$emit('switchFilter', !this.isFiltering)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

#app {
  @include theme(v-expansion-panels) using($integral-core-theme) {
    .v-expansion-panel {
      background-color: map-deep-get($integral-core-theme, 'common', 'filter-form', 'background');
      border: 1px solid map-deep-get($integral-core-theme, 'common', 'filter-form', 'border');
      color: map-deep-get($integral-core-theme, 'common', 'filter-form', 'text');
      .v-expansion-panel-header {
        .v-icon {
          color: map-deep-get($integral-core-theme, 'common', 'filter-form', 'icon');
        }
        .v-btn {
          background-color: map-deep-get($integral-core-theme, 'common', 'filter-form', 'background');
          &.filterLabel {
            color: map-deep-get($integral-core-theme, 'common', 'filter-form', 'text-off');
            &.filterActive {
              color: map-deep-get($integral-core-theme, 'common', 'filter-form', 'text-on');
            }
          }
        }
      }
    }
  }
}
.v-expansion-panels.theme--light {
  .v-expansion-panel-content {
    .count {
      color: #5A5959;
    }
  }
}
.v-card.theme--light {
  box-shadow: 3px 3px 0px #1A110D29 !important;
}
.v-card.theme--dark {
  box-shadow: 0px 3px 6px #00000029 !important;
}

.v-expansion-panel {
  .v-expansion-panel-header {
    min-height: 48px;
    font-weight: 700;
    .v-btn {
      font-weight: 700 !important;
      letter-spacing: 0;
    }
  }
  .v-expansion-panel-content {
    &::v-deep .v-expansion-panel-content__wrap {
      padding: 12px;
    }
  }
}
.v-card__actions {
  padding-top: 22px;
  .count {
    font-weight: 700;
    span {
      font-size: 12px;
    }
  }
}
</style>
