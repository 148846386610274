import axios from '@/axios'
import checkValueUtil from '@/utils/checkValueUtil'

const segments = {
  namespaced: true,
  state: {
    sortParameter: {
      orderBy: 'createdAt',
      isDesc: true,
    },
    enabledFilter: true,
    filterParameter: {
      createdBy: null,
      keyword: null,
      userType: null,
    },
    initFilterParameter: {
      createdBy: null,
      keyword: null,
      userType: null,
    },
    loadingSegment: true,
    deletingSegment: false,
    loadingCsvDownloadHistory: false,
    checkingInProcessCreation: false,
  },
  mutations: {
    setOrderBy (state, newVal) {
      state.sortParameter.orderBy = newVal
    },
    setIsDesc (state, newVal) {
      state.sortParameter.isDesc = newVal
    },
    filterReset (state) {
      Object.assign(state.filterParameter, JSON.parse(JSON.stringify(state.initFilterParameter)))
    },
    setFilter (state, newVal) {
      state.filterParameter = newVal
    },
    setEnabledFilter (state, newVal) {
      state.enabledFilter = newVal
    },
    setLoadingSegment (state, newval) {
      state.loadingSegment = newval
    },
    setDeletingSegments (state, newval) {
      state.deletingSegment = newval
    },
    setLoadingCsvDownloadHistory (state, newval) {
      state.loadingCsvDownloadHistory = newval
    },
    setCheckingInProcessCreation (state, newval) {
      state.checkingInProcessCreation = newval
    },
  },
  getters: {
    getQueryParams (state) {
      const params = Object.assign({}, state.sortParameter, state.filterParameter, { enabledFilter: state.enabledFilter })
      for (const key in params) {
        if (checkValueUtil.isNullOrEmpty(params[key])) delete params[key]
      }
      return params
    },
  },
  actions: {
    /**
     * セグメント一覧リスト取得
     * @param  {String}  targetDate サマリの基準日
     * @return {Promise}
     */
    async getSegments ({ commit, state, rootState }, targetDate) {
      commit('setLoadingSegment', true)
      let queryOrderBy = ''
      if (state.sortParameter.orderBy) {
        // キャメルからスネークに変換
        queryOrderBy = state.sortParameter.orderBy.replace(/([A-Z])/g, (str) => { return '_' + str.charAt(0).toLowerCase() })
      }

      const queryParameter = {
        targetDate: targetDate,
        orderBy: queryOrderBy,
        isDesc: state.sortParameter.isDesc,
        keyword: state.enabledFilter ? state.filterParameter.keyword : null,
        createdBy: state.enabledFilter ? state.filterParameter.createdBy : null,
        userType: state.enabledFilter ? state.filterParameter.userType : null,
      }

      return axios.get('/segments/', { params: queryParameter })
        .then(res => {
          return Promise.resolve({
            segments: res.data.data.segments,
            latestUpdatedDateTime: res.data.data.latestUpdatedDateTime,
            paging: res.data.paging,
          })
        }).finally(() => {
          commit('setLoadingSegment', false)
        })
    },
    /**
     * セグメント一覧全件リスト取得
     * stateに保存しない
     * @param  {String}  targetDate サマリの基準日
     * @return {Promise}
     */
    async getAllSegments ({ commit, state, rootState }, targetDate) {
      commit('setLoadingSegment', true)

      return axios.get('/segments/', {
        params: {
          targetDate: targetDate,
          orderBy: 'created_at',
          isDesc: true,
          keyword: null,
          createdBy: null,
          userType: null,
        },
      }).finally(() => {
        commit('setLoadingSegment', false)
      })
    },
    /**
     * セグメント1件取得
     * @param  {Object}  params リクエストパラメーター
     * @return {Promise}
     */
    async getSegment ({ commit }, params) {
      commit('setLoadingSegment', true)
      return await axios.get('/segments/' + params.segmentId + '/', {
        params: {
          targetDate: params.targetDate,
        },
      }).then((res) => {
        return Promise.resolve({
          segment: res.data.data,
        })
      }).catch((error) => {
        return Promise.reject(error)
      //   if (error.response.status === 404) router.push({ name: 'PageNotFound' }, () => {})
      }).finally(() => {
        commit('setLoadingSegment', false)
      })
    },
    /**
     * セグメント一括削除
     * @param  {Array.<Number>}  segmentIdList 削除対象のセグメントIDリスト
     * @return {Promise}
     */
    async bulkDeleteSegments ({ commit }, segmentIdList) {
      commit('setDeletingSegments', true)
      const requestList = []
      for (const segmentId of segmentIdList) {
        requestList.push(axios.delete('/segments/' + segmentId + '/'))
      }
      commit('setDeletingSegments', false)
      return Promise.all(requestList)
    },
    async csvDownloadRequest ({ commit }, { segmentId, requestColumns }) {
      await axios.post('/segment/' + segmentId + '/csv/request/', {
        requestColumnList: JSON.stringify(requestColumns),
      })
    },
    async getCsvDownloadHistory ({ commit }, params) {
      commit('setLoadingCsvDownloadHistory', true)
      const DEFAULT_ORDER_BY = 'startDatetime'
      return await axios.get('/segment/' + params.segmentId + '/csv/request/history/', {
        params: {
          offset: (params.page - 1) * params.limit,
          limit: params.limit,
          orderBy: DEFAULT_ORDER_BY,
        },
      }).then((res) => {
        return Promise.resolve({
          segmentCustomerCsvRequestHistoryList: res.data.data.segmentCustomerCsvRequestHistoryList,
          filteredTotalCount: res.data.paging.filteredTotalCount,
        })
      }).catch((error) => {
        return Promise.reject(error)
      }).finally(() => {
        commit('setLoadingCsvDownloadHistory', false)
      })
    },
    async checkInProcessCreation ({ commit }, segmentId) {
      commit('setCheckingInProcessCreation', true)
      return await axios.get('/segment/' + segmentId + '/csv/request/monitoring/')
        .then((res) => {
          return Promise.resolve({
            proceed: res.data.data.proceed,
          })
        }).catch((error) => {
          return Promise.reject(error)
        }).finally(() => {
          commit('setCheckingInProcessCreation', false)
        })
    },
    async deleteCsvFile ({ commit }, segmentCustomerCsvRequestHistoryId) {
      await axios.put('/segment/' + segmentCustomerCsvRequestHistoryId + '/csv/disable/')
    },
    updateOrderBy ({ commit }, newVal) {
      commit('setOrderBy', newVal)
    },
    updateIsDesc ({ commit }, newVal) {
      commit('setIsDesc', newVal)
    },
    updateFilter ({ commit }, newVal) {
      commit('setFilter', newVal)
    },
    filterReset ({ commit }) {
      commit('filterReset')
    },
    updateEnabledFilter ({ commit }, newVal) {
      commit('setEnabledFilter', newVal)
    },
    updateLoadingSegment ({ commit }, newval) {
      commit('setLoadingSegment', newval)
    },
  },
}
export default segments
