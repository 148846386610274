import axios from '@/axios'

const segmentUsers = {
  namespaced: true,
  state: {
    loadingSegmentsUsers: false,
  },
  mutations: {
    setLoadingSegmentsUsers (state, newVal) {
      state.loadingSegmentsUsers = newVal
    },
  },
  actions: {
    /**
     * セグメントユーザーリスト取得
     * @param  {Object}  params リクエストパラメーター
     * @return {Promise}
     */
    async getSegmentsCustomer ({ commit, dispatch, state }, params) {
      commit('setLoadingSegmentsUsers', true)
      return await axios.get('/segments/' + params.segmentId + '/customer/', {
        params: {
          offset: (params.page - 1) * params.limit,
          limit: params.limit,
        },
      }).then((res) => {
        return Promise.resolve({
          paging: res.data.paging,
          userScvList: res.data.data.customerList,
        })
      }).finally(() => {
        commit('setLoadingSegmentsUsers', false)
      })
    },
    async getSegmentsUnknown ({ commit, dispatch, state }, params) {
      commit('setLoadingSegmentsUsers', true)
      return await axios.get('/segments/' + params.segmentId + '/unknown/', {
        params: {
          offset: (params.page - 1) * params.limit,
          limit: params.limit,
        },
      }).then((res) => {
        return Promise.resolve({
          paging: res.data.paging,
          userUnknownList: res.data.data.unknownList,
        })
      }).finally(() => {
        commit('setLoadingSegmentsUsers', false)
      })
    },
  },
}
export default segmentUsers
