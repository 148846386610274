<template>
  <div>
    <div
      v-if="index !== 0"
      :key="index + '_chip'"
      class="my-3 d-flex justify-center align-content-center"
    >
      <v-chip v-if="targetTransaction.logicalOperatorType === LOGICAL_OPERATOR_TYPE.AND.value" color="and">AND</v-chip>
      <v-chip v-else-if="targetTransaction.logicalOperatorType === LOGICAL_OPERATOR_TYPE.OR.value" color="or">OR</v-chip>
    </div>
    <div class="d-flex align-center form my-3" :key="index + '_form'">
      <!-- 削除ボタン -->
      <v-btn fab depressed x-small color="denial" @click.stop="$emit('removeRule')">
        <v-icon>ic-close-S</v-icon>
      </v-btn>
      <v-card outlined tile class="ml-2">
        <v-card-text class="filter-form pa-3">
          <v-row>
            <v-col class="py-0" cols="5">
              <v-select
                v-model="filterPhysicalName"
                dense
                :items="filterColumnList"
                item-text="logicalName"
                item-value="physicalName"
                return-object
                :name="'filterColumn'  + index"
                :data-vv-as="$t('form.transactionSummary.filterColumn')"
                v-validate="'required'"
                :error-messages="errors.collect('filterColumn' + index)"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field
                v-if="operatorType === OPERATOR_TYPE.IS_NOT_NULL.value"
                class="mt-0"
                dense
                disabled
                :value="logicalNot ? OPERATOR_TYPE_WITH_LOGICAL_NOT.IS_NULL.symbol : OPERATOR_TYPE.IS_NOT_NULL.symbol"
              />
              <v-dialog
                v-else-if="filterColumn.dataType === DATA_TYPES.DATE.value && !this.isRelativeDateOperator(this.operatorType)"
                ref="dateDialog"
                :return-value.sync="filterColumn.filterValue"
                width="252px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="filterValue"
                    dense
                    append-icon="ic-calendar-S"
                    clearable
                    readonly
                    :name="'filterValue' + index"
                    :data-vv-as="$t('form.transactionSummary.filterValue')"
                    v-validate="'required'"
                    :error-messages="errors.collect('filterValue' + index)"
                    :type="'date'"
                  />
                </template>
                <v-date-picker
                  v-model="filterValue"
                  @input="$refs.dateDialog.save(filterValue)"
                  no-title
                  locale="ja"
                  :day-format="date => new Date(date).getDate()"
                  width="auto"
                />
              </v-dialog>
              <datetime-picker
                ref="datetimePicker"
                v-else-if="filterColumn.dataType === DATA_TYPES.DATETIME.value && !this.isRelativeDateOperator(this.operatorType)"
                v-model="filterValue"
                dense
                :name="'filterValue' + index"
                :data-vv-as="$t('form.transactionSummary.filterValue')"
                v-validate="'required'"
                :error-messages="errors.collect('filterValue' + index)"
              />
              <v-text-field
                v-else
                v-model="filterValue"
                dense
                :name="'filterValue' + index"
                :data-vv-as="$t('form.transactionSummary.filterValue')"
                v-validate="filterColumn.dataType === DATA_TYPES.LONG.value ? 'required|min_value:0' : 'required'"
                :error-messages="errors.collect('filterValue' + index)"
                :type="valueType"
              />
            </v-col>
            <v-col cols="3" class="py-0">
              <v-select
                v-model="operatorType"
                dense
                :items="operatorTypeList"
                :name="'filterOperator' + index"
                :data-vv-as="$t('form.transactionSummary.filterOperator')"
                v-validate="'required'"
                :error-messages="errors.collect('filterOperator' + index)"
              >
                <template v-slot:prepend-item>
                  <v-list-item class="px-0">
                    <v-list-item-content class="px-4">
                      <v-switch
                        v-model="logicalNot"
                        label="論理否定"
                        hide-details
                        dense
                        class="pt-0 mt-0"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// components
import datetimePicker from '@/components/public/datetimePicker'
// enums
import DATA_TYPES from '@/enum/DATA_TYPES'
import LOGICAL_OPERATOR_TYPE from '@/enum/LOGICAL_OPERATOR_TYPE'
import OPERATOR_TYPE from '@/enum/OPERATOR_TYPE'
import OPERATOR_TYPE_WITH_LOGICAL_NOT from '@/enum/OPERATOR_TYPE_WITH_LOGICAL_NOT'
// utils
import convertUtil from '@/utils/convertUtil'
import segmentFilterUtil from '@/utils/segmentFilterUtil'

export default {
  name: 'transactionRule',
  mixins: [
    DATA_TYPES,
    convertUtil,
    LOGICAL_OPERATOR_TYPE,
    OPERATOR_TYPE,
    OPERATOR_TYPE_WITH_LOGICAL_NOT,
    segmentFilterUtil,
  ],
  components: {
    datetimePicker,
  },
  props: {
    index: {
      type: Number,
    },
    targetTransaction: {
      type: Object,
    },
    filterColumn: {
      type: Object,
    },
    dataSourceList: {
      type: Array,
    },
  },
  data () {
    return {
      terms: 1,
    }
  },
  computed: {
    filterColumnList () {
      let columnList = []
      if (this.targetTransaction.dataSourceId && this.dataSourceList?.length > 0) {
        columnList = JSON.parse(JSON.stringify(this.dataSourceList.find(dataSource => dataSource.dataSourceId === this.targetTransaction.dataSourceId)?.headerMetaJson?.columns))
      }
      return columnList
    },
    valueType () {
      return this.convertToTypeNameByDateType(this.filterColumn.dataType)
    },
    filterPhysicalName: {
      get () {
        return this.filterColumn.filterPhysicalName
      },
      set (newVal) {
        const newFilterColumn = {
          filterValue: null,
          operatorType: null,
          filterPhysicalName: newVal.physicalName,
          filterLogicalName: newVal.logicalName,
          dataType: newVal.dataType,
          logicalNot: false,
        }
        this.$emit('changeFilterColumn', newFilterColumn)
      },
    },
    operatorType: {
      get () {
        return this.filterColumn.operatorType
      },
      set (newVal) {
        if (this.isRelativeDateOperator(newVal)) {
          if (this.filterColumn.dataType !== this.DATA_TYPES.LONG.value) {
            this.filterValue = null
          }
          this.$emit('changeFilterColumn', { operatorType: newVal, dataType: this.DATA_TYPES.LONG.value })
        } else {
          const dataType = this.filterColumnList.find((filterColumn) => filterColumn.physicalName === this.filterPhysicalName).dataType
          if (this.filterColumn.dataType !== dataType) {
            this.filterValue = null
          }
          this.$emit('changeFilterColumn', { operatorType: newVal, dataType: dataType })
        }
      },
    },
    filterValue: {
      get () {
        return this.filterColumn.filterValue
      },
      set (newVal) {
        this.$emit('changeFilterColumn', { filterValue: newVal })
      },
    },
    logicalNot: {
      get () {
        return this.filterColumn.logicalNot
      },
      set (newVal) {
        this.$emit('changeFilterColumn', { logicalNot: newVal })
      },
    },
    operatorTypeList () {
      if (this.filterPhysicalName !== null) {
        return this.getOperatorType(this.filterColumn.dataType, this.operatorType, this.logicalNot)
      }
      return []
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-list) using ($integral-core-theme) {
  .v-input--switch {
    ::v-deep .v-label {
      color: map-deep-get($integral-core-theme, 'inputs', 'select', 'list') !important;
    }
  }
}

.form {
  padding-left: 3px;
  .v-card {
    width: 100%;
  }
}
.filter-form {
  min-width: 640px;
  height: 43px;
  ::v-deep .v-text-field {
    margin-top: 0;
    .v-input__slot {
      height: 32px;
      margin-bottom: 8px;
      .v-input__append-inner {
        align-self: center;
      }
      input[type="datetime-local"] {
        width: 138px;
      }
    }
  }
}
.v-list {
  .v-input--switch {
    ::v-deep .v-label {
      font-size: 14px;
    }
  }
}
</style>
