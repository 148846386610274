import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/'

/* components */
import Accounts from '@/views/accounts'
import AccountSetting from '@/views/accountSetting'
import AccountRoles from '@/views/accountRoles'
import AccountRoleSetting from '@/views/accountRoleSetting'
import DataSets from '@/views/dataSets'
import DataSetAdd from '@/views/dataSetAdd'
import DataSetDetail from '@/views/dataSetDetail'
import DataSourceAdd from '@/views/dataSourceAdd'
import DataSourceConnectorSetting from '@/views/dataSourceConnectorSetting'
import DataSourceDetail from '@/views/dataSourceDetail'
import DataSourceLinkSetting from '@/views/dataSourceLinkSetting'
import DataSourceMasterLinkSetting from '@/views/dataSourceMasterLinkSetting'
import DataSourceTransactionLinkSetting from '@/views/dataSourceTransactionLinkSetting'
import DataSourceWebLinkSetting from '@/views/dataSourceWebLinkSetting'
import JobHistory from '@/views/jobHistory'
import Jobs from '@/views/jobs'
import JobSetting from '@/views/jobSetting'
import Login from '@/views/login'
import Manuals from '@/views/manuals'
import PageNotFound from '@/views/pageNotFound'
import PageUnAuthorized from '@/views/pageUnAuthorized'
import PatternLibrary from '@/views/patternLibrary'
import PasswordChange from '@/views/passwordChange'
import Release from '@/views/release'
import scvs from '@/views/scvs'
import scvSetting from '@/views/scvSetting'
import scvUnion from '@/views/scvUnion'
import SegmentExportScriptHistory from '@/views/segmentExportScriptHistory'
import SegmentExportScripts from '@/views/segmentExportScripts'
import SegmentExportScriptSetting from '@/views/segmentExportScriptSetting'
import Segments from '@/views/segments'
import SegmentSetting from '@/views/segmentSetting'
import SegmentDetail from '@/views/segmentDetail'
import TotpRegister from '@/views/totpRegister'
import TotpLogin from '@/views/totpLogin'
import TransactionSummary from '@/views/transactionSummary'
import TransactionSummarySetting from '@/views/transactionSummarySetting'
import UserScvDetail from '@/views/userScvDetail'
import UserUnknownDetail from '@/views/userUnknownDetail'

Vue.use(Router)

const GET = 'getAuthorized'
const POST = 'postAuthorized'
const PUT = 'putAuthorized'

const router = new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash,
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '*',
      name: 'PageNotFound',
      component: PageNotFound,
      meta: {
        pageName: '指定されたURLは存在しませんでした。',
      },
    },
    {
      path: '*',
      name: 'PageUnAuthorized',
      component: PageUnAuthorized,
      meta: {
        hasNavigation: true,
        pageName: '指定されたURLを表示する権限が有りません。',
      },
    },
    {
      path: '/',
      // TODO ダッシュボードができたらダッシュボードに変更
      redirect: '/login',
    },
    {
      path: '/dataSets',
      name: 'DataSets',
      component: DataSets,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { dataSet: [GET] },
        pageName: 'データセット一覧',
      },
    },
    {
      path: '/dataSet/setting',
      name: 'DataSetAdd',
      component: DataSetAdd,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { dataSet: [GET, POST] },
        pageName: 'データセット新規作成',
      },
    },
    {
      path: '/dataSet/setting/:dataSetId',
      name: 'DataSetSettingDetail',
      component: DataSetDetail,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { dataSet: [GET] },
        pageName: 'データセット詳細',
      },
    },
    {
      path: '/dataSet/setting/:dataSetId/dataSource/setting',
      name: 'DataSourceAdd',
      component: DataSourceAdd,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { dataSet: [GET, POST] },
        pageName: 'データソース追加',
      },
    },
    {
      path: '/dataSet/setting/:dataSetId/dataSource/setting/:dataSourceId/connector',
      name: 'DataSourceConnectorSetting',
      component: DataSourceConnectorSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { dataSet: [GET, PUT] },
        pageName: 'コネクタ設定',
      },
    },
    {
      path: '/dataSet/setting/:dataSetId/dataSource/setting/:dataSourceId',
      name: 'DataSourceSettingDetail',
      component: DataSourceDetail,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { dataSet: [GET] },
        pageName: 'データソース詳細',
      },
    },
    {
      path: '/dataSet/setting/:dataSetId/linkSetting',
      name: 'DataSourceLinkSetting',
      component: DataSourceLinkSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { dataSet: [GET, PUT] },
        pageName: '顧客マスタ連結設定',
      },
    },
    {
      path: '/dataSet/setting/:dataSetId/masterLinkSetting',
      name: 'DataSourceMasterLinkSetting',
      component: DataSourceMasterLinkSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { dataSet: [GET, PUT] },
        pageName: '顧客マスタ連結設定（顧客マスタ）',
      },
    },
    {
      path: '/dataSet/setting/:dataSetId/transactionLinkSetting',
      name: 'DataSourceTransactionLinkSetting',
      component: DataSourceTransactionLinkSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { dataSet: [GET, PUT] },
        pageName: '顧客マスタ連結設定（トランザクション）',
      },
    },
    {
      path: '/dataSet/setting/:dataSetId/webLinkSetting',
      name: 'DataSourceWebLinkSetting',
      component: DataSourceWebLinkSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { dataSet: [GET, PUT] },
        pageName: '顧客マスタ連結設定（Web/iOS/Android)',
      },
    },
    {
      path: '/scvs',
      name: 'scvs',
      component: scvs,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { customer: [GET], scvSetting: [GET] },
        pageName: 'カスタマー一覧',
      },
    },
    {
      path: '/scvUnion',
      name: 'scvUnion',
      component: scvUnion,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { customer: [GET, POST], scvSetting: [GET] },
        pageName: 'カスタマー統合',
      },
    },
    {
      path: '/job/setting',
      name: 'JobSetting',
      component: JobSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { jobs: [GET, POST] },
        pageName: 'ジョブ設定',
      },
    },
    {
      path: '/job/setting/:jobId',
      name: 'JobSettingEdit',
      component: JobSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { jobs: [GET] },
        pageName: 'ジョブ編集',
      },
    },
    {
      path: '/jobs',
      name: 'Jobs',
      component: Jobs,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { jobs: [GET] },
        pageName: 'ジョブ一覧',
      },
    },
    {
      path: '/job/histories',
      name: 'JobHistory',
      component: JobHistory,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { jobs: [GET] },
        pageName: 'ジョブ履歴',
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        beforeLogin: true,
        hasNavigation: false,
        hidePageTitle: true,
        requiresAuth: false,
        pageName: 'ログイン',
      },
    },
    {
      path: '/manuals',
      name: 'Manuals',
      component: Manuals,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        pageName: '各種手順書',
      },
    },
    {
      path: '/account/users',
      name: 'Accounts',
      component: Accounts,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { accounts: [GET] },
        pageName: 'アカウント一覧',
      },
    },
    {
      path: '/account/user/setting',
      name: 'AccountSetting',
      component: AccountSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { accounts: [GET, POST] },
        pageName: 'アカウント設定',
      },
    },
    {
      path: '/account/user/setting/:accountId',
      name: 'AccountEdit',
      component: AccountSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { accounts: [GET] },
        pageName: 'アカウント設定',
      },
    },
    {
      path: '/account/roles',
      name: 'AccountRoles',
      component: AccountRoles,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { role: [GET] },
        pageName: '権限一覧',
      },
    },
    {
      path: '/account/role/setting',
      name: 'AccountRoleSetting',
      component: AccountRoleSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { role: [GET, POST] },
        pageName: '権限設定',
      },
    },
    {
      path: '/account/role/setting/:roleId',
      name: 'AccountRoleEdit',
      component: AccountRoleSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { role: [GET, PUT] },
        pageName: '権限設定',
      },
    },
    {
      path: '/password/change',
      name: 'PasswordChange',
      component: PasswordChange,
      meta: {
        hasNavigation: false,
        requiresAuth: false,
        pageName: 'パスワード変更',
      },
    },
    {
      path: '/patternLibrary',
      name: 'PatternLibrary',
      component: PatternLibrary,
      meta: {
        hasNavigation: true,
        requiresAuth: false,
        pageName: 'パターンライブラリ',
      },
    },
    {
      path: '/release',
      name: 'Release',
      component: Release,
      meta: {
        hasNavigation: true,
        requiresAuth: false,
        pageName: 'リリース履歴',
      },
    },
    {
      path: '/scv/setting',
      name: 'scvSetting',
      component: scvSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { scvSetting: [GET] },
        pageName: 'SCV設定',
      },
    },
    {
      path: '/segmentExportScriptHistory/:segmentExportScriptId',
      name: 'SegmentExportScriptHistory',
      component: SegmentExportScriptHistory,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { segmentExportScript: [GET] },
        pageName: 'セグメントエクスポートスクリプト履歴',
      },
    },
    {
      path: '/segmentExportScripts',
      name: 'SegmentExportScripts',
      component: SegmentExportScripts,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { segmentExportScript: [GET] },
        pageName: 'セグメントエクスポートスクリプト一覧',
      },
    },
    {
      path: '/segmentExportScript/setting',
      name: 'SegmentExportScriptSettingAdd',
      component: SegmentExportScriptSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { segmentExportScript: [GET] },
        pageName: 'セグメントエクスポートスクリプト追加',
      },
    },
    {
      path: '/segmentExportScript/setting/:segmentExportScriptId',
      name: 'SegmentExportScriptSettingEdit',
      component: SegmentExportScriptSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { segmentExportScript: [GET] },
        pageName: 'セグメントエクスポートスクリプト編集',
      },
    },
    {
      path: '/segmentExportScript/setting/:segmentExportScriptId/copy',
      name: 'SegmentExportScriptSettingCopy',
      component: SegmentExportScriptSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { segmentExportScript: [GET, POST] },
        pageName: 'セグメントエクスポートスクリプト複製',
      },
    },
    {
      path: '/segments',
      name: 'Segments',
      component: Segments,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { segment: [GET] },
        pageName: 'セグメント一覧',
      },
    },
    {
      path: '/segment/setting',
      name: 'SegmentSetting',
      component: SegmentSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { segment: [GET] },
        pageName: 'セグメント作成',
      },
    },
    {
      path: '/segment/setting/sql',
      name: 'SegmentSQLSetting',
      component: SegmentSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { segment: [GET] },
        pageName: 'セグメント作成 (SQL)',
      },
    },
    {
      path: '/segment/setting/:segmentId/copy',
      name: 'SegmentSettingCopy',
      component: SegmentSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { segment: [GET, POST] },
        pageName: 'セグメント複製',
      },
    },
    {
      path: '/segment/setting/:segmentId',
      name: 'SegmentSettingEdit',
      component: SegmentSetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { segment: [GET] },
        pageName: 'セグメント編集',
      },
    },
    {
      path: '/segment/:segmentId/',
      name: 'SegmentDetail',
      component: SegmentDetail,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { segment: [GET] },
        pageName: 'セグメント詳細',
      },
    },
    {
      path: '/authentication/totp',
      name: 'TotpLogin',
      component: TotpLogin,
      meta: {
        beforeLogin: true,
        hasNavigation: false,
        hidePageTitle: true,
        requiresAuth: false,
        pageName: 'ワンタイムパスワード入力',
      },
    },
    {
      path: '/authentication/totp/register',
      name: 'TotpRegister',
      component: TotpRegister,
      meta: {
        beforeLogin: true,
        hasNavigation: false,
        hidePageTitle: true,
        requiresAuth: false,
        pageName: 'ワンタイムパスワードデバイス登録',
      },
    },
    {
      path: '/transactionSummary',
      name: 'TransactionSummary',
      component: TransactionSummary,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { dataSet: [GET] },
        pageName: 'トランザクションサマリ一覧',
      },
    },
    {
      path: '/transactionSummary/setting',
      name: 'TransactionSummarySetting',
      component: TransactionSummarySetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { dataSet: [GET, POST] },
        pageName: 'トランザクションサマリ設定',
      },
    },
    {
      path: '/transactionSummary/setting/:transactionSummaryId',
      name: 'TransactionSummaryEdit',
      component: TransactionSummarySetting,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { dataSet: [GET] },
        pageName: 'トランザクションサマリ編集',
      },
    },
    {
      path: '/userDetail/scv/:scvId',
      name: 'UserScvDetail',
      component: UserScvDetail,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { customer: [GET], scvSetting: [GET] },
        pageName: 'カスタマー詳細',
      },
    },
    {
      path: '/userDetail/unknown/:unknownId',
      name: 'UserUnknownDetail',
      component: UserUnknownDetail,
      meta: {
        hasNavigation: true,
        requiresAuth: true,
        requiredAuthority: { customer: [GET] },
        pageName: '匿名客詳細',
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (!to.meta.requiresAuth) {
    if (to.meta.hasNavigation) {
      // サイドメニュー表示の場合は、表示できるメニューの範囲を取得するために権限取得してから画面表示
      store.dispatch('auth/checkAuth').finally(() => next())
    } else {
      // 認証が不要な画面はそのまま表示する
      next()
    }
  } else {
    // セッション (ログイン状態) と権限を確認
    store.dispatch('auth/checkAuth').then(res => {
      if (to.meta.requiredAuthority !== undefined && !store.getters['auth/isAllAuthorized'](to.meta.requiredAuthority)) {
        next({ name: 'PageUnAuthorized' })
      } else {
        next()
      }
    }).catch(() => {
      // `GET: /login/` error.response.status !== 200
      next({ path: '/login' + to.name ? '?redirect=' + to.name : '' })
      location.reload()
    })
  }
})
export default router
