<template>
  <div>
    <!-- v6.1.2 -->
    <h4 id="v6.1.2">
      v6.1.2 (2019/10/24~)
    </h4>
    <h5> セグメント詳細</h5>
    <ul>
      <li>
        関連ジョブタブでページネーションが動作しない不具合を修正しました。
      </li>
    </ul>
    <!-- v6.1.1 -->
    <h4 id="v6.1.1">
      v6.1.1 (2019/10/23~)
    </h4>
    <h5> セグメント詳細</h5>
    <ul>
      <li>
        セグメントに関連する顧客を表示した場合、一部表示が崩れている不具合を修正しました。
      </li>
    </ul>

    <!-- v6.1.0 -->
    <h4 id="v6.1.0">
      v6.1.0 (2019/10/07~)
    </h4>
    <h5> サイドバー</h5>
    <ul>
      <li>
        ジョブ管理のアイコンを変更しました。
      </li>
    </ul>
    <h5> 顧客情報設定</h5>
    <ul>
      <li>
        顧客マスタCSVのアップロード時に、ドラッグ＆ドロップによるファイル選択が可能となりました。
      </li>
    </ul>
    <h5>ジョブ設定</h5>
    <ul>
      <li>入出力タスクのINPUT/OUTPUTに「GCS」を選択した際の認証用JSONファイルアップロード時に、ドラッグ＆ドロップによるファイル選択が可能となりました。</li>
      <li>入出力タスクのINPUT/OUTPUTに「S3」または「GCS」を選択した際、OUTPUTのファイル名の末尾にジョブ実行日付を付与できるようになりました。</li>
      <li>クラウドストレージ間のファイル転送の容量の上限を上げました。</li>
    </ul>

    <!-- v6.0.0 -->
    <h4 id="v6.0.0">
      v6.0.0 (2019/09/24~)
    </h4>
    <h5> セグメント一覧</h5>
    <ul>
      <li>
        フィルタリングとソート機能が追加されました。
      </li>
    </ul>
    <h5> セグメント詳細</h5>
    <ul>
      <li>
        フィルタリングとソート機能が追加されました。
      </li>
      <li>
        選択したセグメントに関連づけられたジョブを確認できるようになりました。<br>
        表示されているジョブを直接削除することも可能です。
      </li>
    </ul>
    <h5> サイト一覧</h5>
    <ul>
      <li>
        一覧情報に登録日/更新日列が追加されました。
      </li>
    </ul>
    <h5> コンバージョン一覧</h5>
    <ul>
      <li>
        一覧情報に登録日/更新日列が追加されました。
      </li>
    </ul>
    <h5> ジョブ一覧</h5>
    <ul>
      <li>
        フィルタリングとソート機能が追加されました。
      </li>
    </ul>
    <h5>ジョブ設定</h5>
    <ul>
      <li>入出力タスクのINPUTに、セグメント設定で作成したセグメントを利用できるようになりました。<br>
        セグメントに関連づけられた顧客/匿名客の情報を、設定したOUTPUT先に出力できます。</li>
      <li>入出力タスクのINPUT/OUTPUTに、GCS（Google Cloud Strage）を利用できるようになりました。</li>
    </ul>
    <h5>アカウント管理</h5>
    <ul>
      <li>アカウント管理画面が追加されました。管理者権限以上のユーザーが、ユーザーアカウントの作成、編集、削除を行えます。</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'version6',
  minorVersions: [
    'v6.1.2',
    'v6.1.1',
    'v6.1.0',
    'v6.0.0',
  ],
}
</script>
