export default {
  data () {
    return {
      JSON_OUTPUT_ITEM_NAMES: {
        S3: { text: 'S3', value: 'outputS3' },
        AURORA: { text: 'MySQL', value: 'outputAurora' },
        REDSHIFT: { text: 'Redshift', value: 'outputRedshift' },
        GCS: { text: 'GCS', value: 'outputGcs' },
      },
    }
  },
}
