<template>
  <v-card outlined tile>
    <v-card-text class="pb-3 header">
      {{ $t(`text.jobSetting.taskListHelp`) }}
    </v-card-text>
    <v-list nav dense>
      <draggable v-model="taskList">
        <template v-for="(jobTask, index) in taskList">
          <v-list-item
            :key="jobTask.index"
            :class="[{'v-item--active': jobTask.jobTaskOrder===activateJobTaskOrder, 'error__task': jobTask.hasError}]"
            @click="onClickJobTaskListItem(index)"
            @drop.stop="dropTask(index)"
            ripple>
            <v-list-item-content>
              <v-row :align="'center'" dense>
                <v-col cols="2">{{ jobTask.jobTaskOrder }}</v-col>
                <v-col cols="10" v-if="jobTask.jobTaskType == JOB_TASK_TYPES.IO.value">
                  <v-chip color="io">
                    {{ $t('text.jobSetting.ioType') }}
                  </v-chip>
                </v-col>
                <v-col cols="9" v-else-if="jobTask.jobTaskType == JOB_TASK_TYPES.SQL.value">
                  <v-chip color="sql">
                    {{ $t('text.jobSetting.sqlType') }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-list-item-content>
            <v-list-item-content :class="{'white--text': jobTask.jobTaskOrder===activateJobTaskOrder || jobTask.hasError}">
              {{ jobTask.jobTaskName ? jobTask.jobTaskName : $t(`text.jobSetting.newTask`) }}
            </v-list-item-content>
            <v-list-item-action>
              <v-icon v-show="taskList.length > 1" @click.stop="onClickDeleteButton(index)">ic-close-S</v-icon>
            </v-list-item-action>
            <v-list-item-action>
              <v-icon small :class="['draggableIcon', {'white--text': jobTask.jobTaskOrder === activateJobTaskOrder || jobTask.hasError}]">ic-handle-S</v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
      </draggable>
      <v-list-item inactive class="action">
        <v-list-item-content class="d-flex justify-center">
          <v-btn
            fab
            depressed
            center
            @click="onClickAddNewButton()">
            <v-icon>ic-add</v-icon>
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
// enum
import JOB_TASK_TYPES from '@/enum/jobTask/JOB_TASK_TYPES'
import INPUT_ETL_TYPES from '@/enum/jobTask/INPUT_ETL_TYPES'
import OUTPUT_ETL_TYPES from '@/enum/jobTask/OUTPUT_ETL_TYPES'
// plugin
import draggable from 'vuedraggable'

export default {
  name: 'JobTaskList',
  mixins: [
    JOB_TASK_TYPES,
    INPUT_ETL_TYPES,
    OUTPUT_ETL_TYPES,
  ],
  components: { draggable },
  props: [
    /** タスク一覧 */
    'jobTaskList',
    /** 選択中のタスクの実行順番目 */
    'activateJobTaskOrder',
  ],
  data () {
    return {
      isInitialRendering: true,
    }
  },
  computed: {
    taskList: {
      get () {
        return this.jobTaskList
      },
      set (val) {
        this.$emit('updateTaskList', val)
      },
    },
  },
  watch: {
    taskList: {
      handler () {
        // 変更があった場合に番号を振り直す
        let order = 1
        this.taskList.forEach(task => {
          task.jobTaskOrder = order
          order++
        })
      },
      deep: true,
    },
  },
  created () {
    // 登録の場合の初期表示時にタスクを1件新規作成する
    if (this.isInitialRendering && !this.$route.params.jobId) {
      this.onClickAddNewButton()
      this.isInitialRendering = false
    }
    // タスク一覧の初期表示時に1番上のタスクを選択する
    this.$emit('selectTask', 0)
  },
  methods: {
    /**
     * 新規タスクの生成
     */
    createDefaultJobTask () {
      return {
        jobTaskSettingId: null,
        jobTaskName: '',
        jobTaskOrder: 0,
        jobTaskType: this.JOB_TASK_TYPES.IO.value,
        jobTaskReference: {
          input: {
            etlType: this.INPUT_ETL_TYPES.S3.value,
          },
          output: {
            etlType: this.OUTPUT_ETL_TYPES.S3.value,
          },
        },
        jobTaskCommand: null,
        hasError: false, // タスクのフォームバリデーション用
      }
    },
    /**
    * ドラッグ＆ドロップしたタスクを選択状態にする
    * @param {Number} index ドラッグ＆ドロップしたタスクのインデックス
    */
    dropTask (index) {
      this.$emit('selectTask', index)
    },
    /**
    * タスク追加ボタンクリック時にタスクを1件追加
    */
    onClickAddNewButton () {
      this.taskList.push(this.createDefaultJobTask())
      const taskNum = this.taskList.length
      // 新規タスクを選択状態にする
      if (this.taskList.length === 1) {
        this.$emit('selectTask', 0)
      } else {
        this.$emit('selectTask', taskNum - 1)
      }
    },
    /**
    * タスク削除ボタンクリック時に対象のタスクを配列から除去
    * @param index 削除ボタンがクリックされたタスクのインデックス
    */
    onClickDeleteButton (index) {
      // 選択中のタスクを削除した場合は先頭のタスク設定をフォームに詰める
      const originList = JSON.parse(JSON.stringify(this.taskList))
      this.taskList.splice(index, 1)

      if (this.activateJobTaskOrder === originList[index].jobTaskOrder) {
        this.$emit('reset')
        const taskNum = this.taskList.length
        if (index === 0 || taskNum === 1) {
          // 一番上のタスクを削除した場合とタスク数が1つの場合、一番上を選択
          this.$emit('selectTask', 0)
        } else if (taskNum <= index) {
          // 一番下のタスクを削除した場合、一番下を選択
          this.$emit('selectTask', taskNum - 1)
        } else {
          // その他、削除した位置に新しく来たタスクを選択
          this.$emit('selectTask', index)
        }
      }
    },
    /**
     * タスク選択処理
     * @param  {Number} index クリックされたタスクのインデックス
     */
    onClickJobTaskListItem (index) {
      // 選択状態でないタスクを選択した場合はバリデーションエラーをリセット
      if (!this.isInitialRendering && (index + 1) !== this.activateJobTaskOrder) {
        this.$emit('reset')
      }
      this.$emit('selectTask', index)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/theme.scss';

.draggableIcon {
  cursor: move;
}
.error__task {
  background-color: map-get($red, '_400') !important;
}

#app {
  @include theme(v-card) using ($integral-core-theme) {
    .header {
      color: map-deep-get($integral-core-theme, 'views', 'jobSetting', 'header', 'bold');
    }
  }
  @include theme(v-list) using ($integral-core-theme) {
    &.v-list--nav {
      .v-list-item {
        color: map-deep-get($integral-core-theme, 'lists', 'nav', 'text') !important;
        &.v-item--active {
          &, .v-icon::before {
            color: map-deep-get($integral-core-theme, 'lists', 'nav', 'text-active') !important;
          }
        }
        border-radius: 4px;
        word-break: break-word;
        &.action {
          border: none;
        }
      }
    }
  }
}
</style>
