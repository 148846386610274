import axios from '@/axios'

const userActionHistory = {
  namespaced: true,
  state: {
    loadingActionHistory: false,
  },
  mutations: {
    setLoadingActionHistory (state, newVal) {
      state.loadingActionHistory = newVal
    },
  },
  actions: {
    getCustomerActionHistory ({ commit }, params) {
      commit('setLoadingActionHistory', true)
      return axios.get(
        '/summary/customer/users/' + params.customerId + '/actions/', {
          params: {
            periodType: params.periodType,
            startDate: params.startDate,
            endDate: params.endDate,
            baseDate: params.baseDate,
            offset: (params.page - 1) * params.limit,
            limit: params.limit,
          },
        }).finally(() => {
        commit('setLoadingActionHistory', false)
      })
    },
    getUnknownActionHistory ({ commit }, params) {
      commit('setLoadingActionHistory', true)
      return axios.get(
        '/summary/unknown/users/' + params.unknownId + '/actions/', {
          params: {
            periodType: params.periodType,
            startDate: params.startDate,
            endDate: params.endDate,
            baseDate: params.baseDate,
            offset: (params.page - 1) * params.limit,
            limit: params.limit,
          },
        }).finally(() => {
        commit('setLoadingActionHistory', false)
      })
    },
    updateLoadingActionHistory ({ commit }, newval) {
      commit('setLoadingActionHistory', newval)
    },
  },
}
export default userActionHistory
