import axios from '@/axios'
import checkValueUtil from '@/utils/checkValueUtil'

const jobExecHistory = {
  namespaced: true,
  state: {
    sortParameter: {
      orderBy: 'endDatetime',
      isDesc: true,
    },
    enabledFilter: true,
    filterParameter: {
      page: 1,
      status: null,
      limit: 50,
      name: null,
    },
    loadingJobExecHistories: false,
  },
  mutations: {
    setOrderBy (state, newVal) {
      state.sortParameter.orderBy = newVal
    },
    setIsDesc (state, newVal) {
      state.sortParameter.isDesc = newVal
    },
    setFilter (state, newVal) {
      state.filterParameter = newVal
    },
    setEnabledFilter (state, newVal) {
      state.enabledFilter = newVal
    },
    setLoadingJobExecHistories (state, newVal) {
      state.loadingJobExecHistories = newVal
    },
  },
  getters: {
    getQueryParams (state) {
      const params = Object.assign({}, state.sortParameter, state.filterParameter, { enabledFilter: state.enabledFilter })
      for (const key in params) {
        if (checkValueUtil.isNullOrEmpty(params[key])) delete params[key]
      }
      return params
    },
  },
  actions: {
    /**
     * ジョブ実行履歴リストを取得
     */
    async getJobExecHistories ({ commit, state, rootState }) {
      commit('setLoadingJobExecHistories', true)
      let queryOrderBy = 'end_datetime'
      if (state.sortParameter.orderBy) {
        // キャメルからスネークに変換
        queryOrderBy = state.sortParameter.orderBy.replace(/([A-Z])/g, (str) => { return '_' + str.charAt(0).toLowerCase() })
      }

      const queryParameter = {
        isDesc: state.sortParameter.isDesc,
        orderBy: queryOrderBy,
        offset: (state.filterParameter.page - 1) * state.filterParameter.limit,
        limit: state.filterParameter.limit,
        id: rootState.route.query.id,
      }
      if (state.enabledFilter) {
        queryParameter.name = state.filterParameter.name
        queryParameter.status = state.filterParameter.status
      }

      return await axios.get('/jobs/jobExecHistories/', {
        params: queryParameter,
      }).then((res) => {
        return Promise.resolve({
          jobExecHistories: res.data.data.jobExecHistories,
          paging: res.data.paging,
        })
      }).catch((err) => {
        // ここでキャッチすることで呼び出し元にエラーが伝播されなくなるのでエラーをreturnする
        return Promise.reject(err)
      }).finally(() => {
        commit('setLoadingJobExecHistories', false)
      })
    },
    updateOrderBy ({ commit }, newVal) {
      commit('setOrderBy', newVal)
    },
    updateIsDesc ({ commit }, newVal) {
      commit('setIsDesc', newVal)
    },
    updateFilter ({ commit }, newVal) {
      commit('setFilter', newVal)
    },
    updateEnabledFilter ({ commit }, newVal) {
      commit('setEnabledFilter', newVal)
    },
  },
}
export default jobExecHistory
