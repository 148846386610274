import axios from '@/axios'

const dataSource = {
  namespaced: true,
  state: {
    loadingDataSource: false,
    loadingDataSourceList: false,
    scheduledToBeImportedObjectNameList: [],
    infering: false,
  },
  mutations: {
    setLoadingDataSource (state, newVal) {
      state.loadingDataSource = newVal
    },
    setLoadingDataSourceList (state, newVal) {
      state.loadingDataSourceList = newVal
    },
    setObjectList (state, res) {
      state.scheduledToBeImportedObjectNameList = res.data.data.objectNameList
    },
    setInfering (state, newVal) {
      state.infering = newVal
    },
  },
  actions: {
    getDataSourceList ({ commit }, dataSourceId) {
      commit('setLoadingDataSourceList', true)
      return axios.get('/dataSource/')
        .finally(() => {
          commit('setLoadingDataSourceList', false)
        })
    },
    getDataSource ({ commit }, dataSourceId) {
      commit('setLoadingDataSource', true)
      return axios.get('/dataSource/' + dataSourceId + '/')
        .finally(() => {
          commit('setLoadingDataSource', false)
        })
    },
    async getScheduledToBeImportedObjectList ({ commit }, dataSourceId) {
      return await axios.get('/dataSource/' + dataSourceId + '/import/')
        .then(res => {
          commit('setObjectList', res)
        }).catch(err => {
          return Promise.reject(err)
        })
    },
    postDataSource ({ commit }, postParameter) {
      return axios.post('/dataSource/', {
        dataSetId: postParameter.dataSetId,
        dataSourceName: postParameter.dataSourceName,
        dataSourceType: postParameter.dataSourceType,
        connectorType: postParameter.connectorType,
        measureSettingId: postParameter.measureSettingId,
        domain: postParameter.domain,
        shouldSanitize: true, // 新規作成時は指定しない. 常時ON
      })
    },
    postImport ({ commit }, postParameter) {
      return axios.post('/dataSource/' + postParameter.dataSourceId + '/import/')
    },
    putDataSource ({ commit }, putParameter) {
      return axios.put('/dataSource/' + putParameter.dataSourceId + '/', {
        dataSourceName: putParameter.dataSourceName,
        note: putParameter.note,
      })
    },
    putDataSourceConnector ({ commit }, putParameter) {
      return axios.put('/dataSource/' + putParameter.dataSourceId + '/connector/', {
        headerMetaJson: putParameter.headerMetaJson,
        userIdentifyColumn: putParameter.userIdentifyColumn,
        datetimeIdentifyColumn: putParameter.datetimeIdentifyColumn,
        transactionIdentifierColumn: putParameter.transactionIdentifierColumn,
      })
    },
    putDataSourceConnectorDomain ({ commit }, putParameter) {
      return axios.put('/dataSource/' + putParameter.dataSourceId + '/connector/', {
        domain: putParameter.domain,
        measureSettingId: putParameter.measureSettingId,
      })
    },
    putDataSourceConnectorCsvImportType ({ commit }, putParameter) {
      return axios.put('/dataSource/' + putParameter.dataSourceId + '/csvImportType/', {
        csvImportType: putParameter.csvImportType,
      })
    },
    deleteDataSource ({ commit }, dataSourceId) {
      return axios({ method: 'delete', url: '/dataSource/' + dataSourceId + '/' })
    },
    async uploadDataSource ({ commit, state }, requestParameter) {
      const res = await axios.post('/dataSource/' + requestParameter.dataSourceId + '/upload/' + requestParameter.importType + '/', requestParameter.file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return res
    },
    async infer ({ commit, state }, requestParameter) {
      commit('setInfering', true)
      const res = await axios.post('/dataSource/csv/infer/', requestParameter.file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).finally(() => {
        commit('setInfering', false)
      })
      return res
    },
  },
}
export default dataSource
