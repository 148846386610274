<template>
  <v-sheet class="stepper">
    <div v-for="(step, index) in item" :key="index" :class="{ step: true, active: step.active }">
      {{step.text}}
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'stepper',
  props: {
    item: {
      type: Array,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(stepper) using ($integral-core-theme) {
  .step {
    color: map-deep-get($integral-core-theme, 'stepper', 'text');
    background-color: map-deep-get($integral-core-theme, 'stepper', 'base');
    &.active {
      background-color: map-deep-get($integral-core-theme, 'stepper', 'active');
    }

    &:not(:last-child)::after {
      border: 20px solid transparent;
      border-left: 20px solid map-deep-get($integral-core-theme, 'stepper', 'base');
    }
    &.active:not(:last-child)::after {
      border-left-color: map-deep-get($integral-core-theme, 'stepper', 'active');
    }
  }
}
.stepper {
  display: flex;
  flex-wrap: wrap;
  .step {
    position: relative;
    width: 33%;
    text-align: center;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    font-size: 14px;
    line-height: 14px;
    &.active {
      z-index: 1;
    }

    &:not(:last-child)::before,
    &:not(:last-child)::after {
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      left: 100%;
      content: '';
      margin: auto;
    }

    &.active:not(:last-child)::before {
      border-left: none;
    }
  }
}
</style>
