<template>
  <div>
    <v-row>
      <v-col class="pb-0" cols="3">
        <v-select
          v-model="filterParameter.status"
          hide-details
          :items="enumUtil.convertForSelectList(EXEC_STATUS)"
          :label="$t(`text.dataSourceImportHistories.filtering.status`)"
          clearable />
      </v-col>
      <v-spacer />
      <v-col class="pb-0" cols="auto" align-self="start">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn class="mr-3" v-on="on" @click="resetFilterParameter()">
              {{ $t(`btn.reset`) }}
            </v-btn>
          </template>
          <span>
            {{ $t(`tooltip.resetFilter`) }}
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn class="mr-3" color="secondary" v-on="on" @click="getDataSourceImportHistories()">
              {{ $t(`btn.setting`) }}
            </v-btn>
          </template>
          <span>
            {{ $t(`tooltip.setFilter`) }}
          </span>
        </v-tooltip>
      </v-col>
    </v-row>
    <!-- sort/paging/limit -->
    <v-row align="center" justify="space-between" class="mb-0">
      <v-col cols="4">
        <v-select
          v-model="orderBy"
          class="align-center sort mt-1"
          hide-details
          :items="sortableColumns"
          :disabled="loading"
          @change="changeSort"
        >
          <template #prepend>
            <span class="prepend-sort-text">
              {{ $t('text.sort') }}
            </span>
          </template>
          <template #append-outer>
            <v-btn icon @click="isDesc = !isDesc; changeSort()" :disabled="loading">
              <v-icon v-if="isDesc">ic-sort-desc</v-icon>
              <v-icon v-else>ic-sort-asc</v-icon>
            </v-btn>
          </template>
        </v-select>
      </v-col>
      <v-col cols="5" class="mt-3">
        <v-pagination :value="filterParameter.page" @input="changePage($event)" :length="pages" total-visible="7" :disabled="loading" />
      </v-col>
      <v-spacer />
      <v-col cols="2">
        <v-select
          v-model="limit"
          class="align-center limits mt-1"
          hide-details
          :items="limits"
          :disabled="loading"
        >
          <template #prepend>
            <span class="prepend-sort-text">
              {{ $t('text.limits') }}
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <dataSourceImport-histories-table
      :dataSourceImportHistories="dataSourceImportHistories"
      :loading="loading"
    />
    <v-row>
      <v-col offset="4" cols="5">
        <v-pagination :value="filterParameter.page" @input="changePage($event)" :length="pages" total-visible="7" :disabled="loading" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// component
import dataSourceImportHistoriesTable from '@/components/DataSourceImportHistory/dataSourceImportHistoriesTable'
// enum
import EXEC_STATUS from '@/enum/EXEC_STATUS'
// util
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'

const DEFAULT_PAGE = 1

export default {
  name: 'dataSourceImportHistory',
  mixins: [
    EXEC_STATUS,
    enumUtil,
    notifyUtil,
  ],
  components: {
    dataSourceImportHistoriesTable,
  },
  props: {
    dataSourceImportHistories: {
      type: Array,
    },
    paging: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
  data () {
    return {
      sortableColumns: [
        {
          text: this.$t('table.dataSourceImportHistories.column3'),
          value: 'targetFilesJson',
        },
        {
          text: this.$t('table.dataSourceImportHistories.column4'),
          value: 'dataSourceImportStatus',
        },
        {
          text: this.$t('table.dataSourceImportHistories.column5'),
          value: 'startDatetime',
        },
        {
          text: this.$t('table.dataSourceImportHistories.column6'),
          value: 'endDatetime',
        },
      ],
      limits: [
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
    }
  },
  computed: {
    pages () {
      return Math.ceil(this.paging.filteredTotalCount / this.paging.limit) || 1
    },
    limit: {
      get () {
        return this.$store.state.dataSourceImportHistory.filterParameter.limit
      },
      set (limit) {
        this.changeLimit(limit)
      },
    },
    orderBy: {
      get () {
        return this.$store.state.dataSourceImportHistory.sortParameter.orderBy
      },
      set (newVal) {
        this.$store.dispatch('dataSourceImportHistory/updateOrderBy', newVal)
      },
    },
    isDesc: {
      get () {
        return this.$store.state.dataSourceImportHistory.sortParameter.isDesc
      },
      set (newVal) {
        this.$store.dispatch('dataSourceImportHistory/updateIsDesc', newVal)
      },
    },
    filterParameter: {
      get () {
        return this.$store.state.dataSourceImportHistory.filterParameter
      },
      set (newVal) {
        this.$store.dispatch('dataSourceImportHistory/updateFilter', newVal)
      },
    },
  },
  created () {
    this.getDataSourceImportHistories()
  },
  methods: {
    /**
     * マスタ取込履歴一覧リストの取得
     */
    getDataSourceImportHistories () {
      this.$emit('getDataSourceImportHistories')
    },
    /**
     * ソートの切り替え
     */
    changeSort () {
      this.filterParameter.page = DEFAULT_PAGE
      this.getDataSourceImportHistories()
    },
    /**
     * 表示件数の切り替え
     */
    changeLimit (limit) {
      this.filterParameter.limit = limit
      this.filterParameter.page = DEFAULT_PAGE
      this.getDataSourceImportHistories()
    },
    /**
     * フィルタリング条件の初期化
     */
    resetFilterParameter () {
      this.$store.dispatch('dataSourceImportHistory/filterReset')
    },
    changePage (newVal) {
      this.filterParameter.page = newVal
      this.getDataSourceImportHistories()
    },
  },
}
</script>
