export default {
  data () {
    return {
      JOB_EXEC_STATUS: {
        WAIT: { text: '実行待ち', value: 1 },
        EXECUTING: { text: '実行中', value: 2 },
        SUCCESS: { text: '成功', value: 3 },
        FAIL: { text: '失敗', value: 4 },
      },
    }
  },
}
