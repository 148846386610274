<template>
  <div>
    <v-row class="periodType">
      <v-col cols="4">
        <v-select
          dense
          v-model="periodType"
          :items="enumUtil.convertForSelectList(PERIOD_TYPES)"
          :label="$t(`text.periodType`)"
          hide-details
        />
      </v-col>
      <v-col
        cols="3"
        v-if="periodType === PERIOD_TYPES.CUSTOM.value"
      >
        <v-dialog
          ref="dialog"
          v-model="startDatePicker"
          :return-value.sync="startDate"
          width="252px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-on="on"
              ref="startDate"
              v-model="startDate"
              :label="$t(`text.startDate`)"
              prepend-icon="ic-calendar-S"
              dense
              clearable
              readonly
              name="startDate"
            />
          </template>
          <v-date-picker
            v-model="startDate"
            no-title
            locale="ja"
            :day-format="date => new Date(date).getDate()"
            width="auto"
            @input="$refs.dialog.save(startDate)"
          />
        </v-dialog>
      </v-col>
      <v-col
        cols="3"
        v-if="periodType === PERIOD_TYPES.CUSTOM.value"
      >
        <v-dialog
          ref="dialog2"
          v-model="endDatePicker"
          :return-value.sync="endDate"
          width="252px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-on="on"
              ref="endDate"
              v-model="endDate"
              :label="$t(`text.endDate`)"
              prepend-icon="ic-calendar-S"
              dense
              clearable
              readonly
              name="endDate"
            />
          </template>
          <v-date-picker
            v-model="endDate"
            no-title
            locale="ja"
            :day-format="date => new Date(date).getDate()"
            @input="$refs.dialog2.save(endDate)"
            width="auto"
          />
        </v-dialog>
      </v-col>
      <template v-if="periodType === PERIOD_TYPES.CUSTOM_RELATIVE_PERIOD.value">
        <v-col
          cols="2"
        >
          <v-text-field
            ref="dayFrom"
            v-model="relativeDayFrom"
            suffix="日前"
            dense
            type="number"
            min="1"
            max="36500"
            name="relativeDayFrom"
            data-vv-as="開始"
            v-validate="'max_value:36500|min_value:1'"
            :error-messages="errors.collect('relativeDayFrom')"
          />
        </v-col>
        <v-col
          cols="1"
          class="text-center"
        >
          〜
        </v-col>
        <v-col
          cols="2"
        >
          <v-text-field
            ref="dayTo"
            v-model="relativeDayTo"
            suffix="日前"
            dense
            type="number"
            min="0"
            max="36500"
            name="relativeDayTo"
            data-vv-as="終了"
            v-validate="'max_value:36500|min_value:0'"
            :error-messages="errors.collect('relativeDayTo')"
          />
        </v-col>
      </template>
    </v-row>
    <div class="mt-4 label">{{ $t('text.segmentSetting.execWeekly') }}</div>
    <v-row no-gutters dense>
      <v-col cols="2">
        <v-checkbox
          class="pt-0"
          hide-details
          dense
          color="default"
          :label="$t(`form.selectAll`)"
          v-model="checkedAllDayOfWeek"
        />
      </v-col>
    </v-row>
    <v-row no-gutters dense>
      <v-col cols="1" v-for="weekday in weekList" :key="weekday.value">
        <v-checkbox
          class="pt-0"
          v-model="dayOfWeek"
          hide-details
          dense
          color="default"
          :label="weekday.text"
          :value="weekday.value"
        />
      </v-col>
    </v-row>
    <v-row class="dayOfMonth">
      <v-col cols="6">
        <v-select
          v-model="dayOfMonth"
          multiple
          label="日付指定"
          :placeholder="$t(`form.segmentSetting.segmentFilter.column.selectPlaceholder`)"
          persistent-placeholder
          :items="enumUtil.convertForSelectList(dayList)"
          dense
          hide-details>
          <template #prepend-item>
            <v-list-item ripple @click="selectAllDay">
              <v-list-item-action>
                <v-icon>{{ dayOfMonthIcon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t(`form.selectAll`) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </template>
          <template v-slot:selection="{ index }">
            <span v-if="index === 0">
              {{ dayList[dayOfMonth[index] - 1].text }}
            </span>
            <span v-if="index !== 0 && index <= 5">
              , {{ dayList[dayOfMonth[index] - 1].text }}
            </span>
            <span v-if="index === 6" class="grey--text caption">
              (+{{ dayOfMonth.length - 5 }} others)
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// components
import ruleGroupForm from './ruleGroupForm.vue'
// enum
import LOGICAL_OPERATOR_TYPE from '@/enum/LOGICAL_OPERATOR_TYPE'
import PERIOD_TYPES from '@/enum/PERIOD_TYPES'
// mixins
import segmentFilterRule from '@/mixins/segmentFilterRule'
// util
import enumUtil from '@/utils/enumUtil'

export default {
  name: 'periodForm',
  mixins: [
    LOGICAL_OPERATOR_TYPE,
    PERIOD_TYPES,
    segmentFilterRule,
    enumUtil,
  ],
  props: {
    group: {
      type: Object,
    },
  },
  data () {
    return {
      startDatePicker: false,
      endDatePicker: false,
      weekList: [
        { text: '日', value: 0 },
        { text: '月', value: 1 },
        { text: '火', value: 2 },
        { text: '水', value: 3 },
        { text: '木', value: 4 },
        { text: '金', value: 5 },
        { text: '土', value: 6 },
      ],
      dayList: [
        { text: '1日', value: 1 },
        { text: '2日', value: 2 },
        { text: '3日', value: 3 },
        { text: '4日', value: 4 },
        { text: '5日', value: 5 },
        { text: '6日', value: 6 },
        { text: '7日', value: 7 },
        { text: '8日', value: 8 },
        { text: '9日', value: 9 },
        { text: '10日', value: 10 },
        { text: '11日', value: 11 },
        { text: '12日', value: 12 },
        { text: '13日', value: 13 },
        { text: '14日', value: 14 },
        { text: '15日', value: 15 },
        { text: '16日', value: 16 },
        { text: '17日', value: 17 },
        { text: '18日', value: 18 },
        { text: '19日', value: 19 },
        { text: '20日', value: 20 },
        { text: '21日', value: 21 },
        { text: '22日', value: 22 },
        { text: '23日', value: 23 },
        { text: '24日', value: 24 },
        { text: '25日', value: 25 },
        { text: '26日', value: 26 },
        { text: '27日', value: 27 },
        { text: '28日', value: 28 },
        { text: '29日', value: 29 },
        { text: '30日', value: 30 },
        { text: '31日', value: 31 },
      ],
    }
  },
  watch: {
    periodType: function (newVal, oldVal) {
      if (oldVal === this.PERIOD_TYPES.CUSTOM.value) {
        this.startDate = null
        this.endDate = null
      } else if (oldVal === this.PERIOD_TYPES.CUSTOM_RELATIVE_PERIOD.value) {
        this.relativeDayFrom = null
        this.relativeDayTo = null
      }
    },
  },
  created () {
    this.injectParentValidator()
  },
  computed: {
    periodType: {
      get () {
        return this.group.periodType
      },
      set (newVal) {
        this.$emit('changePeriodType', newVal)
      },
    },
    startDate: {
      get () {
        return this.group.startDate
      },
      set (newVal) {
        this.$emit('changeStartDate', newVal)
      },
    },
    endDate: {
      get () {
        return this.group.endDate
      },
      set (newVal) {
        this.$emit('changeEndDate', newVal)
      },
    },
    relativeDayFrom: {
      get () {
        return this.group.relativeDayFrom
      },
      set (newVal) {
        this.$emit('changeRelativeDayFrom', newVal)
      },
    },
    relativeDayTo: {
      get () {
        return this.group.relativeDayTo
      },
      set (newVal) {
        this.$emit('changeRelativeDayTo', newVal)
      },
    },
    dayOfWeek: {
      get () {
        return this.group.dayOfWeek
      },
      set (newVal) {
        newVal.sort((x, y) => x - y)
        this.$emit('changeDayOfWeek', newVal)
      },
    },
    // 曜日全選択チェックフラグ
    checkedAllDayOfWeek: {
      get () {
        return this.dayOfWeek.length >= Object.keys(this.weekList).length
      },
      set (newVal) {
        if (newVal) {
          // 実行曜日を格納
          const weekCheckedValList = []
          for (const key of Object.keys(this.weekList)) {
            weekCheckedValList.push(this.weekList[key].value)
          }
          this.dayOfWeek = weekCheckedValList
        } else {
          this.dayOfWeek = []
        }
      },
    },
    dayOfMonth: {
      get () {
        return this.group.dayOfMonth
      },
      set (newVal) {
        newVal.sort((x, y) => x - y)
        this.$emit('changeDayOfMonth', newVal)
      },
    },
    // 日にち全選択チェックフラグ
    checkedAllDayOfMonth () {
      return this.dayOfMonth.length === Object.keys(this.dayList).length
    },
    // 日にち一つ以上選択チェックフラグ
    checkedSomeDayOfMonth () {
      return this.dayOfMonth.length > 0 && !this.checkedAllDayOfMonth
    },
    // 日にち選択での全選択項目のアイコン
    dayOfMonthIcon () {
      // 全選択、一つ以上選択、未選択でアイコンを切り替え
      if (this.checkedAllDayOfMonth) return 'mdi-checkbox-marked-outline'
      if (this.checkedSomeDayOfMonth) return 'mdi-checkbox-intermediate'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    injectParentValidator () {
      let validator = null
      let vm = this
      while (!validator) {
        vm = vm.$parent
        if (!vm) {
          return
        }
        if (vm.$options.name === ruleGroupForm.name) {
          validator = vm.$validator
        }
      }
      this.$validator = validator
    },
    /**
     * 実行日を格納
     */
    selectAllDay () {
      this.$nextTick(() => {
        const dayCheckedValList = []
        if (this.dayOfMonth.length === 0) {
          for (const key of Object.keys(this.dayList)) {
            dayCheckedValList.push(this.dayList[key].value)
          }
        }
        this.dayOfMonth = dayCheckedValList
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-input--checkbox) using ($integral-core-theme) {
  ::v-deep .v-label {
    color: map-deep-get($integral-core-theme, 'views', 'segmentSetting', 'segmentForms', 'periodForm', 'checkbox-label') !important;
  }
}

.periodType {
  height: 46px;
}
.label {
  font-size: 10.5px;
  line-height: 16px;
}
.v-input--checkbox {
  ::v-deep .v-label {
    font-size: 14px;
  }
}
.dayOfMonth {
  margin-top: 14px;
}
</style>
