<template>
  <v-card class="mb-3" outlined>
    <v-card-title class="py-3 pl-2 pr-3">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            v-on="on"
            fab
            depressed
            color="denial"
            x-small
            @click.stop="$emit('removeGroup')"
          >
            <v-icon>ic-close-S</v-icon>
          </v-btn>
        </template>
        <span>グループを削除</span>
      </v-tooltip>
      <v-chip v-if="isTransactionSummaryTypeMismatched" color="attribute" class="ml-2 error error--text">{{ dataSourceName }}：{{ transaction.summaryColumn.summaryLogicalName }}</v-chip>
      <v-chip v-else color="attribute" class="ml-2">{{ dataSourceName }}：{{ transaction.summaryColumn.summaryLogicalName }}</v-chip>
      <v-spacer />
      <v-btn small @click="$emit('editGroup')">{{ $t('btn.change') }}</v-btn>
    </v-card-title>
    <v-divider class="ml-9"></v-divider>
    <v-card-text class="pl-7">
      <div v-if="transaction.filterColumns.length >= 1" class="ml-3 pt-0 mt-n1">
        条件として、以下の項目を満たす
        <template v-for="(filterColumn, index) in transaction.filterColumns">
          <div
            v-if="index !== 0"
            :key="index + '_chip'"
            class="my-2 d-flex justify-center align-content-center"
          >
            <v-chip v-if="transaction.logicalOperatorType === LOGICAL_OPERATOR_TYPE.AND.value" color="and">AND</v-chip>
            <v-chip v-else-if="transaction.logicalOperatorType === LOGICAL_OPERATOR_TYPE.OR.value" color="or">OR</v-chip>
          </div>
          <v-sheet class="px-3 py-2 mt-3 ruleForm" :key="index + '_ruleForm'">
            {{ getFilterRuleSentence(filterColumn) }}
          </v-sheet>
        </template>
      </div>
      <div v-else class="ml-3 pt-0 mt-n1">
        条件なし
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
// enums
import LOGICAL_OPERATOR_TYPE from '@/enum/LOGICAL_OPERATOR_TYPE'
// mixins
import segmentFilterUtil from '@/utils/segmentFilterUtil'

export default {
  name: 'targetDataSourceForm',
  props: {
    transaction: {
      type: Object,
    },
    totalTransactionCount: {
      type: Number,
      default () {
        return 0
      },
    },
    dataSourceList: {
      type: Array,
    },
    isTransactionSummaryTypeMismatched: {
      type: Boolean,
    },
  },
  mixins: [
    LOGICAL_OPERATOR_TYPE,
    segmentFilterUtil,
  ],
  computed: {
    dataSourceName () {
      return this.dataSourceList.find(dataSource => dataSource.dataSourceId === this.transaction.dataSourceId)?.dataSourceName
    },
  },
  methods: {
    getFilterRuleSentence (filterColumn) {
      const rule = { filterColumn: null }
      rule.filterColumn = filterColumn
      return this.createFilterRuleSentence(rule)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-sheet) using ($integral-core-theme) {
  &.ruleForm {
    background-color: map-deep-get($integral-core-theme, 'views', 'segmentSetting', 'sheets', 'segment-rule', 'background');
    color: map-deep-get($integral-core-theme, 'views', 'segmentSetting', 'sheets', 'segment-rule', 'text');
  }
}
@include theme(v-chip) using ($integral-core-theme) {
  &.error {
    background-color: map-deep-get($integral-core-theme, 'chips', 'attribute', 'background') !important;
  }
}

.v-card__title {
  .v-chip:hover::before {
    opacity: 0;
  }
}
.ruleForm {
  font-weight: bold;
  line-height: 16px;
  border: none;
}
</style>
