<template>
  <v-container>
    <h2 color="white" class="mt-5 text-center">2要素認証設定</h2>
    <p>INTEGRAL-CORE を利用するには、2要素認証の設定が必要です。</p>
    <div id="canvas" class="mb-4"></div>
    <p>QRコードを Authenticatorアプリで読み込んでください。</p>
  </v-container>
</template>

<script>
import QRCode from 'qrcode'

const ISSUER = encodeURIComponent('INTEGRAL-CORE')

export default {
  name: 'TotpQrCode',
  props: {
    loginUserDetail: {
      type: Object,
    },
    totpSecretKey: {
      type: String,
    },
  },
  computed: {
    otpauthUri () {
      if (this.loginUserDetail?.loginUserAddress === null || this.loginUserDetail?.loginUserAddress === undefined || this.totpSecretKey === null || this.totpSecretKey === '') {
        return ''
      }
      return `otpauth://totp/${ISSUER}:${encodeURIComponent(this.loginUserDetail?.loginUserAddress)}?secret=${this.totpSecretKey}&issuer=${ISSUER}&algorithm=SHA1&digits=6&period=30`
    },
  },
  watch: {
    otpauthUri () {
      if (this.otpauthUri === '') return

      QRCode.toString(this.otpauthUri, function (error, qrcode) {
        if (error) return
        document.getElementById('canvas').innerHTML = qrcode
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 350px;
  min-width: 350px;
  margin-right: 0;
  padding: 0;
  h2 {
    color: map-get($shades, 'white') !important;
  }
  p {
    color: map-get($gray, '_300') !important;
  }
  #canvas {
    margin: 0 auto;
    width: 60%;
    min-width: 100px;
  }
}
</style>
