<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn :to="'/dataSet/setting/' + dataSetId + '/linkSetting'">
          {{ $t(`btn.back`) }}
        </v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <h3 class="lead-line">顧客マスタ同士を連結する</h3>
      <h4>できること</h4>
      <p v-html="$t('text.dataSourceLinkSetting.masterLinkSettingHint')"></p>

      <div class="sheetHeading">
        <v-spacer class="flex-grow-0" />
        <div>顧客マスタ&nbsp;&#040;<v-icon>ic-file-table-M</v-icon>&nbsp;or&nbsp;<v-icon>ic-API-M</v-icon>&#041;</div>
        <v-spacer class="flex-grow-0" />
        <div>顧客マスタ&nbsp;&#040;<v-icon>ic-file-table-M</v-icon>&nbsp;or&nbsp;<v-icon>ic-API-M</v-icon>&#041;</div>
        <v-spacer class="flex-grow-0" />
      </div>

      <v-sheet class="ruleForm" v-for="(dataSource, index) in selectedDataSourceList" :key="'target_' + index">
        <!-- 削除ボタン -->
        <v-btn
          fab
          depressed
          x-small
          color="denial"
          @click.stop="selectedDataSourceList.splice(index, 1)"
        >
          <v-icon>ic-close-S</v-icon>
        </v-btn>
        <!-- 顧客マスタ -->
        <v-card outlined tile>
          <v-card-text>
            <v-select
              v-model="dataSource.origin"
              :label="$t('form.dataSource.name')"
              :items="JSON.parse(JSON.stringify(selectableDataSourceList))"
              item-text="dataSourceName"
              item-value="dataSourceId"
              return-object
              :prepend-icon="getIcon(dataSource.origin.connectorType)"
              name="origin"
              :data-vv-as="$t('form.dataSource.name')"
              v-validate="'required'"
              :error-messages="errors.collect('origin')"
            />
            <v-select
              v-model="dataSource.origin.selectedColumns"
              :label="$t('form.dataSource.columnName')"
              :items="JSON.parse((dataSource.origin || {}).headerMetaJson || '{}').columns"
              :item-text="item => item.logicalName + ' (' + getDataType(item.dataType).label + ')'"
              item-value="index"
              :item-disabled="isDateTypeNotString"
              prepend-icon="ic-none"
              multiple
              item-color="default"
              name="originColumn"
              :data-vv-as="$t('form.dataSource.columnName')"
              v-validate="'required'"
              :error-messages="errors.collect('originColumn')"
            >
            </v-select>
          </v-card-text>
        </v-card>

        <v-icon x-large>ic-link-LL</v-icon>

        <!-- トランザクション -->
        <v-card outlined tile>
          <v-card-text>
            <v-select
              v-model="dataSource.target"
              :label="$t('form.dataSource.name')"
              :items="JSON.parse(JSON.stringify(selectableDataSourceList))"
              item-text="dataSourceName"
              item-value="dataSourceId"
              return-object
              :prepend-icon="getIcon(dataSource.target.connectorType)"
              name="target"
              :data-vv-as="$t('form.dataSource.name')"
              v-validate="'required'"
              :error-messages="errors.collect('target')"
            />
            <v-select
              v-model="dataSource.target.selectedColumns"
              :label="$t('form.dataSource.columnName')"
              :items="JSON.parse((dataSource.target || {}).headerMetaJson || '{}').columns"
              :item-text="item => item.logicalName + ' (' + getDataType(item.dataType).label + ')'"
              item-value="index"
              :item-disabled="isDateTypeNotString"
              prepend-icon="ic-none"
              multiple
              item-color="default"
              name="targetColumn"
              :data-vv-as="$t('form.dataSource.columnName')"
              v-validate="'required'"
              :error-messages="errors.collect('targetColumn')"
            >
            </v-select>
          </v-card-text>
        </v-card>

        <div style="width: 100%;"></div>
        <div class="mt-2 ml-8">連結対象には、文字列型のカラムのみ指定できます</div>
      </v-sheet>
      <v-btn fab depressed @click="selectedDataSourceList.push({origin: {}, target: {}})">
        <v-icon>ic-add</v-icon>
      </v-btn>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :to="'/dataSet/setting/' + dataSetId + '/linkSetting'" color="denial">{{ $t('btn.cancel') }}</v-btn>
      <v-btn @click="onSaveClick" color="primary">{{ $t('btn.save') }}</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from '@/axios'
// enum
import DATA_TYPES from '@/enum/DATA_TYPES'
import DATA_SOURCE_TYPES from '@/enum/DATA_SOURCE_TYPES'
import CONNECTOR_TYPES from '@/enum/CONNECTOR_TYPES'
// util
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'dataSourceMasterLinkSetting',
  mixins: [
    DATA_TYPES,
    DATA_SOURCE_TYPES,
    CONNECTOR_TYPES,
    notifyUtil,
  ],
  data () {
    return {
      dataSetId: Number(this.$route.params.dataSetId),
      dataSources: {},
      masterLinkRuleList: [],
      webLinkRuleList: [],
      selectedDataSourceList: [{ origin: {}, target: {} }],
    }
  },
  computed: {
    selectableDataSourceList () {
      return this.dataSources.masterList
        .filter(dataSource =>
          dataSource.connectorType !== this.CONNECTOR_TYPES.WEB.value &&
          dataSource.connectorType !== this.CONNECTOR_TYPES.IOS.value &&
          dataSource.connectorType !== this.CONNECTOR_TYPES.ANDROID.value,
        )
    },
    dataTypes () {
      const tmpDataTypes = Object.values(this.DATA_TYPES)
      return tmpDataTypes
    },
  },
  created () {
    this.fetchDataSources()
  },
  methods: {
    fetchDataSources () {
      this.$store.dispatch('dataSet/getDataSources', this.dataSetId)
        .then((res) => {
          this.dataSources = res.data.data
          this.fetchDataSetLinkRule()
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
        .finally(() => {})
    },
    fetchDataSetLinkRule () {
      this.$store
        .dispatch('dataSet/getDataSetLinkRule', this.dataSetId)
        .then(res => {
          this.masterLinkRuleList = this.findMasterLinkRuleList(res.data.data.masterLinkRuleList, this.dataSources.masterList)
          this.webLinkRuleList = this.findWebLinkRuleList(res.data.data.masterLinkRuleList, this.dataSources.masterList)
          this.setUpRule()
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    setUpRule () {
      this.selectedDataSourceList = []
      this.masterLinkRuleList.forEach(rule => {
        // Update link rule.
        // 構築ルールのデータソースID からセレクトボックスが返す形式のデータソースオブジェクトをコピー
        const origin = JSON.parse(JSON.stringify(this.dataSources.masterList.find(dataSource => dataSource.dataSourceId === rule.originDataSourceId)))
        const target = JSON.parse(JSON.stringify(this.dataSources.masterList.find(dataSource => dataSource.dataSourceId === rule.targetDataSourceId)))

        // set originColumnReference
        origin.selectedColumns = rule.originColumnReference.indexList
        // set targetColumnReference
        target.selectedColumns = rule.targetColumnReference.indexList

        this.selectedDataSourceList.push({ origin: origin, target: target })
      })
      if (this.selectedDataSourceList.length === 0) {
        this.selectedDataSourceList.push({ origin: {}, target: {} })
      }
    },
    onSaveClick () {
      // 未選択の場合
      if (this.selectedDataSourceList.length === 0) {
        // Web系データソースとの結合ルールなしの場合は削除処理
        if (this.webLinkRuleList.length === 0) {
          axios({ method: 'delete', url: '/dataSet/' + this.dataSetId + '/dataSetRowCreateRule/' })
            .then(() => {
              this.notifySuccessMessage(this.$t('notify.dataSourceLink') + this.$t('notify.success.delete'))
              this.fetchDataSetLinkRule()
            }).catch(err => {
              this.handleErrorResponse(err)
            })
        } else {
          // Web系データソースとの結合ルール有りの場合はそれだけ送信
          axios.put('/dataSet/' + this.dataSetId + '/dataSetRowCreateRule/', {
            dataSetRowCreateRuleList: this.webLinkRuleList,
          }).then(() => {
            this.notifySuccessMessage(this.$t('notify.linkRule') + this.$t('notify.success.update'))
            this.fetchDataSetLinkRule()
          }).catch((err) => {
            this.handleErrorResponse(err)
          })
        }
        return
      }

      // validate
      const errors = []
      this.selectedDataSourceList.forEach((pair, index) => {
        const number = index + 1
        if (pair.origin.dataSourceId === undefined || pair.target.dataSourceId === undefined) {
          errors.push(this.$t('notify.error.dataSet.linkRule.required', { index: number }))
          return
        }
        if (pair.origin.dataSourceId === pair.target.dataSourceId) {
          errors.push(this.$t('notify.error.dataSet.linkRule.sameDataSource', { index: number }))
          return
        }
        if (pair.origin.selectedColumns === undefined || pair.target.selectedColumns === undefined ||
            pair.origin.selectedColumns.length === 0 || pair.target.selectedColumns.length === 0) {
          errors.push(this.$t('notify.error.dataSet.linkRule.columnsRequired', { index: number }))
        }
      })
      if (errors.length > 0) {
        errors.forEach((e, index) => setTimeout(() => { this.notifyErrorMessage(e) }, index * 300))
        return
      }

      // リクエストパラメータの形式に変換
      // Web計測/SDK系 (WEB, iOS, Android) は対象の連結設定を1件しか登録できないので専用のフォームに詰めてあるのを展開する
      let ruleList = this.selectedDataSourceList.map(pair => {
        return {
          originDataSourceId: pair.origin.dataSourceId,
          originColumnReference: { indexList: pair.origin.selectedColumns },
          targetDataSourceId: pair.target.dataSourceId,
          targetColumnReference: { indexList: pair.target.selectedColumns },
        }
      })

      // マスタ間結合のデータセット行構築ルールとマージ (Web系が後ろ)
      ruleList = ruleList.concat(this.webLinkRuleList)

      axios.put('/dataSet/' + this.dataSetId + '/dataSetRowCreateRule/', {
        dataSetRowCreateRuleList: ruleList,
      }).then(() => {
        this.notifySuccessMessage(this.$t('notify.linkRule') + this.$t('notify.success.update'))
        this.fetchDataSetLinkRule()
      }).catch((err) => {
        this.handleErrorResponse(err)
      })
    },
    findMasterLinkRuleList (ruleList, masterList) {
      return ruleList.filter(rule => {
        const origin = masterList.find(master => master.dataSourceId === rule.originDataSourceId)
        const target = masterList.find(master => master.dataSourceId === rule.targetDataSourceId)
        return origin.connectorType !== this.CONNECTOR_TYPES.WEB.value &&
          origin.connectorType !== this.CONNECTOR_TYPES.IOS.value &&
          origin.connectorType !== this.CONNECTOR_TYPES.ANDROID.value &&
          target.connectorType !== this.CONNECTOR_TYPES.WEB.value &&
          target.connectorType !== this.CONNECTOR_TYPES.IOS.value &&
          target.connectorType !== this.CONNECTOR_TYPES.ANDROID.value
      }).map(rule => {
        return {
          originDataSourceId: rule.originDataSourceId,
          originColumnReference: JSON.parse(rule.originColumnReference),
          targetDataSourceId: rule.targetDataSourceId,
          targetColumnReference: JSON.parse(rule.targetColumnReference),
        }
      })
    },
    findWebLinkRuleList (ruleList, masterList) {
      return ruleList.filter(rule => {
        const origin = masterList.find(master => master.dataSourceId === rule.originDataSourceId)
        const target = masterList.find(master => master.dataSourceId === rule.targetDataSourceId)
        return origin.connectorType === this.CONNECTOR_TYPES.WEB.value ||
          origin.connectorType === this.CONNECTOR_TYPES.IOS.value ||
          origin.connectorType === this.CONNECTOR_TYPES.ANDROID.value ||
          target.connectorType === this.CONNECTOR_TYPES.WEB.value ||
          target.connectorType === this.CONNECTOR_TYPES.IOS.value ||
          target.connectorType === this.CONNECTOR_TYPES.ANDROID.value
      }).map(rule => {
        return {
          originDataSourceId: rule.originDataSourceId,
          originColumnReference: JSON.parse(rule.originColumnReference),
          targetDataSourceId: rule.targetDataSourceId,
          targetColumnReference: JSON.parse(rule.targetColumnReference),
        }
      })
    },
    getDataType (value) {
      return this.dataTypes.find(dataType => dataType.value === value)
    },
    getIcon (type) {
      switch (type) {
        case this.CONNECTOR_TYPES.CSV.value:
          return 'ic-file-table-M'
        case this.CONNECTOR_TYPES.API.value:
          return 'ic-API-M'
        default:
          return 'ic-none'
      }
    },
    isDateTypeNotString (item) {
      return item.dataType !== this.DATA_TYPES.STRING.value
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

#app {
  @include theme(v-sheet) using ($integral-core-theme) {
    &.ruleForm {
      background: map-deep-get($integral-core-theme, 'views', 'masterLinkSetting', 'sheets', 'background') 0% 0% no-repeat padding-box;
    }
  }
}
.sheetHeading {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  padding: 12px 12px 0;
  font-weight: bold;
  font-size: 16px;
}
.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  padding: 12px;

  > .v-card {
    height: 166px;
    flex: 1;
    margin: 0 10px;
  }
}
.v-list {
  &::v-deep .v-list-item {
    &.v-list-item--active::before {
      opacity: 0.12 !important;
    }
    &.v-list-item--highlighted::before {
      opacity: 0.04;
    }
  }
}
</style>
