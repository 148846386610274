<template>
  <div class="d-flex align-center form">
    <!-- 削除ボタン -->
    <v-btn fab depressed x-small color="denial" @click.stop="onClickRemoveRuleButton">
      <v-icon>ic-close-S</v-icon>
    </v-btn>
    <v-card outlined tile class="ml-2">
      <v-card-text class="filter-form pa-3">
        <v-row>
          <!-- フィルタリング対象 -->
          <v-col v-if="ruleType !== this.FILTER_RULE_TYPE.TRANSACTION_SUMMARY.value" cols="5" class="py-0">
            <v-select
              :value="filterPhysicalName"
              @change="$emit('changeFilterColumn', $event)"
              :placeholder="$t(`form.segmentSetting.segmentFilter.column.selectPlaceholder`)"
              class="mt-0"
              dense
              :items="filterColumnList"
              item-text="filterLogicalName"
              item-value="filterPhysicalName"
              return-object
              :loading="loading"
              :readonly="loading"
              :name="'filterPhysicalName' + index"
              :data-vv-as="$t(`form.segmentSetting.segmentFilter.column.target`)"
              v-validate="'required'"
              :error-messages="errors.collect('filterPhysicalName' + index)"
            />
          </v-col>
          <!-- 条件値 -->
          <v-col cols="4" class="py-0">
            <v-text-field
              v-if="operatorType === this.OPERATOR_TYPE.IS_NULL.value"
              class="mt-0"
              dense
              disabled
              :value="OPERATOR_TYPE.IS_NULL.symbol"
            />
            <v-text-field
              v-else-if="operatorType === this.OPERATOR_TYPE.IS_NOT_NULL.value"
              class="mt-0"
              dense
              disabled
              :value="rule.filterColumn.logicalNot ? OPERATOR_TYPE_WITH_LOGICAL_NOT.IS_NULL.symbol : OPERATOR_TYPE.IS_NOT_NULL.symbol"
            />
            <v-dialog
              v-else-if="valueType === 'date' && !this.isRelativeDateOperator(this.operatorType)"
              ref="dateDialog"
              :return-value.sync="filterValue"
              width="252px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-on="on"
                  v-model="filterValue"
                  dense
                  :placeholder="$t(`form.segmentSetting.segmentFilter.column.inputPlaceholder`)"
                  append-icon="ic-calendar-S"
                  clearable
                  readonly
                  :name="'filterValue' + index"
                  :data-vv-as="$t('form.segmentSetting.segmentFilter.column.value')"
                  v-validate="'required'"
                  :error-messages="errors.collect('filterValue' + index)"
                  :type="valueType"
                />
              </template>
              <v-date-picker
                v-model="filterValue"
                no-title
                locale="ja"
                :day-format="date => new Date(date).getDate()"
                @input="$refs.dateDialog.save(filterValue)"
                width="auto"
              />
            </v-dialog>
            <datetime-picker
              ref="datetimePicker"
              v-else-if="valueType === 'datetime-local' && !this.isRelativeDateOperator(this.operatorType)"
              v-model="filterValue"
              dense
              :placeholder="$t(`form.segmentSetting.segmentFilter.column.inputPlaceholder`)"
              :name="'filterValue' + index"
              :data-vv-as="$t('form.segmentSetting.segmentFilter.column.value')"
              v-validate="'required'"
              :error-messages="errors.collect('filterValue' + index)"
            />
            <v-text-field
              v-else-if="rule.filterColumn.dataType === 1"
              v-model="filterValue"
              :placeholder="$t(`form.segmentSetting.segmentFilter.column.inputPlaceholder`)"
              class="mt-0"
              dense
              :name="'filterValue' + index"
              :data-vv-as="$t(`form.segmentSetting.segmentFilter.column.value`)"
              v-validate="'required'"
              :error-messages="errors.collect('filterValue' + index)"
              :type="valueType"
            />
            <v-text-field
              v-else
              v-model="filterValue"
              :placeholder="$t(`form.segmentSetting.segmentFilter.column.inputPlaceholder`)"
              class="mt-0"
              dense
              :name="'filterValue' + index"
              :data-vv-as="$t(`form.segmentSetting.segmentFilter.column.value`)"
              v-validate="'required|min_value:0'"
              :error-messages="errors.collect('filterValue' + index)"
              :type="valueType"
              min="0"
            />
          </v-col>
          <!-- 一致条件 -->
          <v-col cols="3" class="py-0">
            <v-select
              :value="operatorType"
              @change="$emit('changeOperatorType', $event)"
              :placeholder="$t(`form.segmentSetting.segmentFilter.column.selectPlaceholder`)"
              class="mt-0"
              dense
              :items="operatorTypeList"
              :name="'operatorType' + index"
              :data-vv-as="$t(`form.segmentSetting.segmentFilter.column.operator`)"
              v-validate="'required'"
              :error-messages="errors.collect('operatorType' + index)"
            >
              <template v-slot:prepend-item>
                <v-list-item class="px-0">
                  <v-list-item-content class="px-4">
                    <v-switch
                      :input-value="rule.filterColumn.logicalNot"
                      @change="$emit('changeLogicalNot', $event)"
                      label="論理否定"
                      hide-details
                      dense
                      class="pt-0 mt-0"
                    />
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// components
import datetimePicker from '@/components/public/datetimePicker'
import ruleGroupForm from './ruleGroupForm.vue'
// enum
import DATA_TYPES from '@/enum/DATA_TYPES'
import FILTER_TYPE from '@/enum/FILTER_TYPE'
import FILTER_RULE_TYPE from '@/enum/FILTER_RULE_TYPE'
import OPERATOR_TYPE from '@/enum/OPERATOR_TYPE'
// util
import segmentFilterUtil from '@/utils/segmentFilterUtil'

export default {
  name: 'ruleForm',
  mixins: [
    DATA_TYPES,
    FILTER_TYPE,
    FILTER_RULE_TYPE,
    OPERATOR_TYPE,
    segmentFilterUtil,
  ],
  components: {
    datetimePicker,
  },
  props: {
    rule: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
    filterColumnList: {
      type: Array,
    },
    ruleType: {
      type: Number,
    },
    index: {
      type: Number,
    },
  },
  data () {
    return {
      filterRuleType: null,
      fixedFilterRuleType: null,
    }
  },
  created () {
    if (this.rule.filterRuleType > 0) {
      this.fixedFilterRuleType = this.rule.filterRuleType
    }
    this.injectParentValidator()
  },
  computed: {
    operatorTypeList () {
      if (this.rule.filterColumn.filterType) {
        return this.getOperatorType(this.rule.filterColumn.dataType, this.rule.filterColumn.operatorType, this.rule.filterColumn.logicalNot)
      }
      return []
    },
    valueType () {
      return this.convertToTypeNameByDateType(this.rule.filterColumn.dataType)
    },
    filterPhysicalName () {
      return this.rule.filterColumn.filterPhysicalName
    },
    filterValue: {
      get () {
        return this.rule.filterColumn.filterValue
      },
      set (newVal) {
        this.$emit('changeFilterValue', newVal)
      },
    },
    operatorType () {
      return this.rule.filterColumn.operatorType
    },
  },
  methods: {
    injectParentValidator () {
      let validator = null
      let vm = this
      while (!validator) {
        vm = vm.$parent
        if (!vm) {
          return
        }
        if (vm.$options.name === ruleGroupForm.name) {
          validator = vm.$validator
        }
      }
      this.$validator = validator
    },
    /**
     * ユーザー属性項目削除
     */
    onClickRemoveRuleButton () {
      this.$emit('remove')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-list) using ($integral-core-theme) {
  .v-input--switch {
    ::v-deep .v-label {
      color: map-deep-get($integral-core-theme, 'inputs', 'select', 'list') !important;
    }
  }
}

.form {
  padding-left: 3px;
}
.v-card {
  width: 100%;
}
.filter-form {
  min-width: 640px;
  height: 43px;
  ::v-deep .v-text-field {
    margin-top: 0;
    .v-input__slot {
      height: 32px;
      margin-bottom: 8px;
      .v-input__append-inner {
        align-self: center;
      }
      input[type="datetime-local"] {
        width: 138px;
      }
    }
  }
}
.v-list {
  .v-input--switch {
    ::v-deep .v-label {
      font-size: 14px;
    }
  }
}
</style>
