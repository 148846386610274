<template>
  <v-dialog
    ref="dialog"
    width="504px"
    @input="updateParam"
  >
    <template #activator="{ on }">
      <v-text-field
        v-on="on"
        :value="value"
        :dense="dense"
        :hide-details="hideDetails"
        :label="label"
        :placeholder="placeholder"
        append-icon="ic-calendar-S"
        clearable
        readonly
        :error-messages="errorMessages"
        type="datetime-local"
        @click:clear="clear"
      />
    </template>
    <div class="d-flex">
      <v-date-picker
        v-model="date"
        no-title
        locale="ja"
        :day-format="date => new Date(date).getDate()"
        width="252"
      />
      <v-card flat width="252" class="d-flex flex-column timeCard">
        <v-spacer />
        <v-card-text>
          <b>時刻を選択してください</b>
          <v-row class="pt-2">
            <v-col cols="5" offset="1">
              <v-select v-model="hours" dense :items="enumUtil.convertForSelectList(HOUR_LIST)" :suffix="'時'" />
            </v-col>
            <v-col cols="5">
              <v-select v-model="minutes" dense :items="enumUtil.convertForSelectList(MINUTES_LIST)" :suffix="'分'" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-spacer />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="$refs.dialog.isActive = false">{{ $t(`btn.ok`) }}</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
// enum
import HOUR_LIST from '@/enum/HOUR_LIST'
import MINUTES_LIST from '@/enum/MINUTES_LIST'
// util
import convertUtil from '@/utils/convertUtil'
import enumUtil from '@/utils/enumUtil'

export default {
  mixins: [
    HOUR_LIST,
    MINUTES_LIST,
    convertUtil,
    enumUtil,
  ],
  props: {
    value: String,
    dense: Boolean,
    hideDetails: Boolean,
    label: String,
    placeholder: String,
    errorMessages: Array,
  },
  data () {
    return {
      time: '',
    }
  },
  computed: {
    date: {
      get () {
        const datetime = isNaN(Date.parse(this.value)) ? new Date() : new Date(this.value)
        return datetime.getFullYear() + '-' +
          (('0' + (datetime.getMonth() + 1)).slice(-2)) + '-' +
          ('0' + datetime.getDate()).slice(-2)
      },
      set (newVal) {
        this.$emit('input', this.convertIsoToDateTimeString(new Date(newVal + ' ' + this.hours + ':' + this.minutes + ':00')))
      },
    },
    hours: {
      get () {
        const datetime = isNaN(Date.parse(this.value)) ? new Date() : new Date(this.value)
        return ('0' + datetime.getHours()).slice(-2)
      },
      set (newVal) {
        this.$emit('input', this.convertIsoToDateTimeString(new Date(this.date + ' ' + newVal + ':' + this.minutes + ':00')))
      },
    },
    minutes: {
      get () {
        const datetime = isNaN(Date.parse(this.value)) ? new Date() : new Date(this.value)
        return ('0' + datetime.getMinutes()).slice(-2)
      },
      set (newVal) {
        this.$emit('input', this.convertIsoToDateTimeString(new Date(this.date + ' ' + this.hours + ':' + newVal + ':00')))
      },
    },
  },
  methods: {
    updateParam () {
      this.$emit('input', this.convertIsoToDateTimeString(new Date(this.date + ' ' + this.hours + ':' + this.minutes + ':00')))
    },
    clear () {
      this.$emit('input', null)
    },
    async validateDatetimePicker () {
      const result = await this.$validator.validateAll()
      if (!result) return false
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
#app {
  .v-dialog .v-card.timeCard {
    border-left: none;
  }
  .v-text-field ::v-deep input {
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }
}
</style>
