<template>
  <v-card tile elevation="0">
    <information-bar v-if="isNotCalledByOtherPages">
      <template #left>
        <v-btn :to="previousRoute">
          {{ $t(`btn.back`) }}
        </v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <v-row>
        <v-col cols="3" class="pr-1">
          <user-base-info
            userType="unknown"
            :loading="loadingUserUnknownDetail"
            :userDetail="unknownDetail"
          />
        </v-col>
        <v-col cols="9">
          <default-scv-columns
            class="mb-4"
            userType="unknown"
            :loading="loadingUserUnknownDetail"
            :userDetail="unknownDetail"
          />
          <v-tabs v-model="selectedTab">
            <v-tab href="#action">{{ $t('text.userDetail.actionHistory')}}</v-tab>
            <v-tab href="#segment">所属セグメント({{(unknownDetail.userDetailSegments || {}).length}})</v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTab" class="px-0">
            <v-tab-item value="action">
              <!-- 期間指定 -->
              <period-filter-select
                :periodFilter="{ periodType: periodType, startDate: startDate, endDate: endDate }"
                :isSearching="isSearching"
                :showSummary="showSummary"
                @search="filterdSearch"
                @switchSummary="val => showSummary = val"
              />
              <!-- 行動履歴 -->
              <action-history
                :action-histories="actionHistories"
                :is-action-history-searching="loadingActionHistory"
                :paging="actionHistoryPaging"
                :page="page"
                @changePage="handleChangeHistoryPage" />
              <!-- サマリ -->
              <user-summary
                :loading="loadingUserSummary"
                :summaries="summaries"
                :showSummary="showSummary"
              />
            </v-tab-item>
            <v-tab-item value="segment">
              <user-segments :segments="unknownDetail.userDetailSegments" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// components
import actionHistory from '@/components/UserDetail/actionHistory'
import defaultScvColumns from '@/components/UserDetail/defaultScvColumns'
import periodFilterSelect from '@/components/UserDetail/periodFilterSelect'
import userBaseInfo from '@/components/UserDetail/userBaseInfo'
import userSegments from '@/components/UserDetail/userSegments'
import userSummary from '@/components/UserDetail/userSummary'
// enums
import PERIOD_TYPES from '@/enum/PERIOD_TYPES'
import USER_TYPES from '@/enum/USER_TYPES'
// utils
import displayConverter from '@/utils/displayConverter'
import momentUtil from '@/utils/momentUtil'
import notifyUtil from '@/utils/notifyUtil'

const ACTION_HISTORY_PAGE = 1
const ACTION_HISTORY_INDEX = 1
const ACTION_HISTORY_LIMIT = 10

export default {
  name: 'UserUnknownDetail',
  props: {
    unknownId: {
      type: String,
    },
  },
  mixins: [
    PERIOD_TYPES,
    USER_TYPES,
    displayConverter,
    momentUtil,
    notifyUtil,
  ],
  components: {
    actionHistory,
    defaultScvColumns,
    periodFilterSelect,
    userBaseInfo,
    userSegments,
    userSummary,
  },
  data () {
    return {
      unknownDetail: {
        lastAccessDateStr: '',
        firstAccessDateStr: '',
        unknownPromotionDateStr: '',
      },
      selectedTab: 'action',
      summaries: {
        session: {
          label: this.$t('text.summaries.session'),
          value: 0,
        },
        sessionTimeOnSite: {
          label: this.$t('text.summaries.sessionTimeOnSite'),
          value: '0秒',
        },
        pvToSession: {
          label: this.$t('text.summaries.pvToSession'),
          value: 0,
        },
      },
      historyPage: 1,
      // 期間指定
      periodType: 1,
      startDate: '',
      endDate: '',
      // 行動履歴
      actionHistories: [],
      // 行動履歴ページング
      actionHistoryPaging: {
        index: ACTION_HISTORY_INDEX,
        limit: ACTION_HISTORY_LIMIT,
        totalCount: 0,
        filteredTotalCount: 0,
      },
      page: ACTION_HISTORY_PAGE,
      showSummary: false,
    }
  },
  computed: {
    isNotCalledByOtherPages () {
      return this.$route.name === 'UserUnknownDetail'
    },
    isSearching () {
      return this.loadingUserSummary || this.loadingActionHistory
    },
    userId () {
      if (this.$route.name === 'UserUnknownDetail') {
        return this.$route.params.unknownId
      } else {
        return this.unknownId
      }
    },
    previousRoute () {
      const from = this.$store.state.route.from
      // 前の画面がない (直接流入・リロードなど) 場合はセグメント一覧へ戻す
      if (from === undefined || from.name === null) {
        return { name: 'Segments' }
      }
      // 前の画面があればその画面に返す
      return from
    },
    loadingUserSummary: {
      get () {
        return this.$store.state.segmentSearch.loadingUserSummary
      },
      set (newVal) {
        this.$store.dispatch('segmentSearch/updateLoadingUserSummary', newVal)
      },
    },
    loadingActionHistory: {
      get () {
        return this.$store.state.userActionHistory.loadingActionHistory
      },
      set (newVal) {
        this.$store.dispatch('userActionHistory/updateLoadingActionHistory', newVal)
      },
    },
    loadingUserUnknownDetail () {
      return this.$store.state.userUnknownDetail.loadingUserUnknownDetail
    },
  },
  watch: {
    unknownId () {
      this.getUserUnknownDetail()
    },
  },
  created () {
    this.loadingUserSummary = true
    this.loadingActionHistory = true
    const queryParams = this.$route.query
    if (queryParams.periodType) {
      this.periodType = queryParams.periodType
    }
    if (queryParams.startDate) {
      this.startDate = queryParams.startDate
    }
    if (queryParams.endDate) {
      this.endDate = queryParams.endDate
    }
    this.getUserUnknownDetail()
  },
  methods: {
    getUserUnknownDetail () {
      this.$store.dispatch('userUnknownDetail/getUserUnknownDetail', this.userId)
        .then((res) => {
          this.unknownDetail = res.userUnknownDetail
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
      this.search()
    },
    /**
     * サマリと行動履歴取得の前処理
     */
    search () {
      // バリデーション（期間指定：日付指定・開始日、終了日が入力されている場合）
      if (this.periodType === this.PERIOD_TYPES.CUSTOM.value && (this.startDate && this.endDate) && (this.startDate > this.endDate)) {
        this.notifyErrorMessage(this.$t('notify.error.form.invalidPeriod'))
        return
      }

      // 実績取得
      this.fetchSummaries()
      // 行動履歴取得
      this.fetchHistories()
    },
    /**
     * 期間指定の設定
     * @param  {Object} filterParams 設定期間
     */
    filterdSearch (filterParams) {
      this.periodType = filterParams.periodType
      this.startDate = filterParams.startDate
      this.endDate = filterParams.endDate
      this.search()
    },
    /**
     * サマリの値を初期化
     */
    initializeSummaries () {
      for (const key of Object.keys(this.summaries)) {
        this.summaries[key].value = 0
      }
    },
    /**
     * サマリの値を格納
     */
    fetchSummaries () {
      // 初期化
      this.initializeSummaries()

      // 実績取得
      this.$store.dispatch('segmentSearch/searchUserSummary', {
        userId: this.userId,
        userType: this.USER_TYPES.USER_UNKNOWN.value,
        periodType: this.periodType,
        startDate: this.startDate,
        endDate: this.endDate,
        baseDate: this.momentUtil.getCurrentDateIncludeHyphen(),
      }).then((res) => {
        const data = res.data.data
        this.summaries.session.value = this.addKIfOverKilo(data.session)
        this.summaries.sessionTimeOnSite.value = this.timeSecFilter(data.sessionTimeAvg)
        this.summaries.pvToSession.value = this.addKIfOverKilo(data.session ? Math.floor(data.pv / data.session * 10) / 10 : 0)
        // ツールチップ用実データ
        this.summaries.session.chipValue = this.addComma(data.session)
        this.summaries.sessionTimeOnSite.chipValue = this.timeSecFilter(data.sessionTimeAvg)
        this.summaries.pvToSession.chipValue = this.addComma(data.session ? Math.floor(data.pv / data.session * 10) / 10 : 0)
      }).catch((err) => {
        this.handleErrorResponse(err)
      })
    },
    /**
     * 行動履歴を取得
     */
    fetchHistories () {
      // 初期化
      this.actionHistories = []

      // 行動履歴取得
      this.$store.dispatch('userActionHistory/getUnknownActionHistory', {
        unknownId: this.userId,
        periodType: this.periodType,
        startDate: this.startDate,
        endDate: this.endDate,
        baseDate: this.momentUtil.getCurrentDateIncludeHyphen(),
        page: this.page,
        limit: ACTION_HISTORY_LIMIT,
      }).then((res) => {
        this.actionHistories = res.data.data.sessions
        this.actionHistoryPaging = res.data.paging
      }).catch((err) => {
        this.handleErrorResponse(err)
      })
    },
    handleChangeHistoryPage (page) {
      this.page = page
      this.fetchHistories()
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/theme.scss';

@include theme(v-tabs-items) using ($integral-core-theme) {
  ::v-deep p {
    color: map-deep-get($integral-core-theme, 'views', 'userDetail', 'actionHistory', 'text');
  }
}
</style>
