import axios from '@/axios'
import checkValueUtil from '@/utils/checkValueUtil'

const segmentExportScriptHistory = {
  namespaced: true,
  state: {
    sortParameter: {
      orderBy: 'endDatetime',
      isDesc: true,
    },
    enabledFilter: true,
    filterParameter: {
      page: 1,
      execStatus: null,
      limit: 50,
      name: null,
    },
    loadingSegmentExportScriptHistories: true,
  },
  mutations: {
    setOrderBy (state, newVal) {
      state.sortParameter.orderBy = newVal
    },
    setIsDesc (state, newVal) {
      state.sortParameter.isDesc = newVal
    },
    setFilter (state, newVal) {
      state.filterParameter = newVal
    },
    setEnabledFilter (state, newVal) {
      state.enabledFilter = newVal
    },
    setLoadingSegmentExportScriptHistories (state, newval) {
      state.loadingSegmentExportScriptHistories = newval
    },
  },
  getters: {
    getQueryParams (state) {
      const params = Object.assign({}, state.sortParameter, state.filterParameter, { enabledFilter: state.enabledFilter })
      for (const key in params) {
        if (checkValueUtil.isNullOrEmpty(params[key])) delete params[key]
      }
      return params
    },
  },
  actions: {
    /**
     * セグメントエクスポートスクリプト実行履歴リストを取得
     */
    async getSegmentExportScriptHistories ({ commit, state, rootState }, segmentExportScriptId) {
      commit('setLoadingSegmentExportScriptHistories', true)
      let queryOrderBy = 'end_datetime'
      if (state.sortParameter.orderBy) {
        // キャメルからスネークに変換
        queryOrderBy = state.sortParameter.orderBy.replace(/([A-Z])/g, (str) => { return '_' + str.charAt(0).toLowerCase() })
      }

      const queryParameter = {
        isDesc: state.sortParameter.isDesc,
        orderBy: queryOrderBy,
        offset: (state.filterParameter.page - 1) * state.filterParameter.limit,
        limit: state.filterParameter.limit,
        id: rootState.route.query.id,
      }
      if (state.enabledFilter) {
        queryParameter.name = state.filterParameter.name
        queryParameter.execStatus = state.filterParameter.execStatus
      }

      return await axios.get('/segmentExportScript/history/' + segmentExportScriptId + '/', {
        params: queryParameter,
      }).then((res) => {
        return Promise.resolve({
          segmentExportScriptHistories: res.data.data.segmentExportScriptsHistoryList,
          paging: res.data.paging,
        })
      }).catch((err) => {
        // ここでキャッチすることで呼び出し元にエラーが伝播されなくなるのでエラーをreturnする
        return Promise.reject(err)
      }).finally(() => {
        commit('setLoadingSegmentExportScriptHistories', false)
      })
    },
    updateOrderBy ({ commit }, newVal) {
      commit('setOrderBy', newVal)
    },
    updateIsDesc ({ commit }, newVal) {
      commit('setIsDesc', newVal)
    },
    updateFilter ({ commit }, newVal) {
      commit('setFilter', newVal)
    },
    updateEnabledFilter ({ commit }, newVal) {
      commit('setEnabledFilter', newVal)
    },
    updateLoadingSegmentExportScriptHistories ({ commit }, newval) {
      commit('setLoadingSegmentExportScriptHistories', newval)
    },
  },
}
export default segmentExportScriptHistory
