export default {
  /**
   * 空文字, null, undefinedの場合にtrueを返す
   * @param {*} value
   */
  isNullOrEmpty: function (value) {
    return value === '' || value === null || value === undefined
  },
  /**
   * Verify that the value is a number.
   * 0 is true.
   * '0', NaN, Infinity, undefined, null is false.
   * @param {any} value
   */
  isNumber (value) {
    return typeof value === 'number' && isFinite(value)
  },
}
