const DATETIME_STRING_LENGTH = 8

export function showTimeOnly (datetimeStr) {
  if (!datetimeStr || datetimeStr.length !== DATETIME_STRING_LENGTH) {
    return ''
  }
  return datetimeStr.slice(-8)
}

export function hideTimeSecond (datetimeStr) {
  if (!datetimeStr || datetimeStr.length !== DATETIME_STRING_LENGTH) {
    return ''
  }
  return datetimeStr.slice(0, -3)
}
