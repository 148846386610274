import btn from './messages/btn'
import form from './messages/form'
import http from './messages/http'
import notify from './messages/notify'
import table from './messages/table'
import text from './messages/text'
import title from './messages/title'
import tooltip from './messages/tooltip'
import $vuetify from './messages/vuetify'

export default {
  ...btn,
  ...form,
  ...http,
  ...notify,
  ...table,
  ...text,
  ...title,
  ...tooltip,
  ...$vuetify,
}
