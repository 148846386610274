<template>
  <v-card tile elevation="0">
    <information-bar>
      <template #left>
        <v-btn :to="{ name: 'Jobs'}">
          {{ $t(`btn.jobHistory.backToList`) }}
        </v-btn>
      </template>
      <template #right>
        <v-btn @click="getJobExecHistories()" :loading="loadingJobExecHistories"><v-icon>ic-reload</v-icon></v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <h3 class="lead-line" v-if="hasJobId">{{ jobName }}</h3>
      <filter-form
        @search="setFilterParameter"
        @reset="resetFilterParameter"
        @switchFilter="val => isFiltering = val"
        :isFiltering="isFiltering"
        :filteredTotal="paging.filteredTotalCount"
        :total="paging.totalCount"
      >
        <v-row>
          <v-col class="py-0" cols="6" v-if="!hasJobId">
            <v-text-field
              :value="filterParameter.name"
              @input="preparedFilters.name = $event"
              :label="$t(`text.jobHistories.filtering.name`)"
              clearable
              :disabled="!isFiltering" />
          </v-col>
          <v-col class="py-0" cols="3">
            <v-select
              :value="filterParameter.status"
              @change="preparedFilters.status = $event"
              :items="enumUtil.convertForSelectList(JOB_EXEC_STATUS)"
              :label="$t(`text.jobHistories.filtering.status`)"
              clearable
              :disabled="!isFiltering" />
          </v-col>
        </v-row>
      </filter-form>
      <!-- paging/sort/limit -->
      <v-row justify="space-between" class="my-0">
        <v-col cols="4">
          <v-select
            v-model="orderBy"
            class="sort pt-0"
            hide-details
            :items="sortableColumns"
            :disabled="loadingJobExecHistories"
          >
            <template #prepend>
              <span class="prepend-sort-text">
                {{ $t('text.sort') }}
              </span>
            </template>
            <template #append-outer>
              <v-btn icon @click="isDesc = !isDesc" :disabled="loadingJobExecHistories">
                <v-icon v-if="isDesc">ic-sort-desc</v-icon>
                <v-icon v-else>ic-sort-asc</v-icon>
              </v-btn>
            </template>
          </v-select>
        </v-col>
        <v-col cols="5">
          <v-pagination :value="filterParameter.page" @input="changeQuery('page', $event)" :length="pages" total-visible="7" :disabled="loadingJobExecHistories" />
        </v-col>
        <v-spacer />
        <v-col cols="2">
          <v-select
            v-model="limit"
            class="limits pt-0"
            hide-details
            :items="limits"
            :disabled="loadingJobExecHistories"
          >
            <template #prepend>
              <span class="prepend-sort-text">
                {{ $t('text.limits') }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <job-histories-table
        :jobHistories="jobExecHistories"
        :loading="loadingJobExecHistories"
      />
      <!-- paging -->
      <v-row>
        <v-col offset="4" cols="5">
          <v-pagination :value="filterParameter.page" @input="changeQuery('page', $event)" :length="pages" total-visible="7" :disabled="loadingJobExecHistories" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// component
import FilterForm from '@/components/common/filterForm'
import JobHistoriesTable from '@/components/JobHistory/jobHistoriesTable'
// enum
import JOB_EXEC_STATUS from '@/enum/JOB_EXEC_STATUS'
// util
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'

const DEFAULT_PAGE = 1
const DEFAULT_LIMIT = 50

export default {
  name: 'JobHistory',
  mixins: [
    JOB_EXEC_STATUS,
    enumUtil,
    notifyUtil,
  ],
  components: {
    FilterForm,
    JobHistoriesTable,
  },
  data () {
    return {
      jobId: null,
      jobName: null,
      sortableColumns: [
        {
          text: this.$t('text.jobHistories.sort.jobName'),
          value: 'jobName',
        },
        {
          text: this.$t('text.jobHistories.sort.createDatetime'),
          value: 'createDatetime',
        },
        {
          text: this.$t('text.jobHistories.sort.startDatetime'),
          value: 'startDatetime',
        },
        {
          text: this.$t('text.jobHistories.sort.endDatetime'),
          value: 'endDatetime',
        },
        {
          text: this.$t('text.jobHistories.sort.jobResult'),
          value: 'jobResult',
        },
      ],
      limits: [
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
      jobExecHistories: [],
      paging: {},
      preparedFilters: [],
      isFiltering: true,
    }
  },
  computed: {
    ...mapGetters({ queryParams: 'jobExecHistory/getQueryParams' }),
    pages () {
      return Math.ceil(this.paging.filteredTotalCount / this.paging.limit) || 1
    },
    limit: {
      get () {
        return this.$store.state.jobExecHistory.filterParameter.limit
      },
      set (newVal) {
        this.changeQuery('limit', newVal)
      },
    },
    orderBy: {
      get () {
        return this.$store.state.jobExecHistory.sortParameter.orderBy
      },
      set (newVal) {
        this.changeQuery('orderBy', newVal)
      },
    },
    isDesc: {
      get () {
        return this.$store.state.jobExecHistory.sortParameter.isDesc
      },
      set (newVal) {
        this.changeQuery('isDesc', newVal)
      },
    },
    hasJobId () {
      if (this.jobId) {
        return true
      }
      return false
    },
    enabledFilter () {
      return this.$store.state.jobExecHistory.enabledFilter
    },
    filterParameter () {
      return this.$store.state.jobExecHistory.filterParameter
    },
    loadingJobExecHistories () {
      return this.$store.state.jobExecHistory.loadingJobExecHistories
    },
  },
  created () {
    if (this.$route.query.id) {
      this.jobId = this.$route.query.id
      this.$store.dispatch('jobs/getJob', this.$route.query.id)
        .then((res) => {
          this.jobName = res.job.jobName
        })
    }

    const tmpQuery = {}
    for (const key in this.$route.query) {
      tmpQuery[key] = this.$route.query[key]
    }

    // クエリが不十分な場合補う
    let isQuerySufficient = true
    for (const key in this.queryParams) {
      if (tmpQuery[key] === null || tmpQuery[key] === undefined) {
        tmpQuery[key] = this.queryParams[key]
        isQuerySufficient = false
      }
    }

    // orderByに指定された文字列がソート基準の選択肢にあるなら問題ない
    const validateOrderBy = this.sortableColumns.some((column) => tmpQuery.orderBy === column.value)
    if (!validateOrderBy) {
      tmpQuery.orderBy = 'createDatetime'
      isQuerySufficient = false
    }
    if (tmpQuery.isDesc !== true && tmpQuery.isDesc !== false && tmpQuery.isDesc !== 'true' && tmpQuery.isDesc !== 'false') {
      tmpQuery.isDesc = true
      isQuerySufficient = false
    }
    tmpQuery.page = Number(tmpQuery.page)
    if (!Number.isInteger(tmpQuery.page) || tmpQuery.page < 1) {
      tmpQuery.page = DEFAULT_PAGE
      isQuerySufficient = false
    }
    tmpQuery.limit = Number(tmpQuery.limit)
    if (!Number.isInteger(tmpQuery.limit) || tmpQuery.limit < 1 || tmpQuery.limit > 5000) {
      tmpQuery.limit = DEFAULT_LIMIT
      isQuerySufficient = false
    }

    // クエリを変更した場合はURLを置き換える
    if (!isQuerySufficient) {
      this.$router.replace({ query: tmpQuery })
        .catch(err => err) // 画面表示時の NavigationDuplicated 対策
      // 置き換わるとbeforeRouteUpdateが呼び出されるのでこちらの処理は中断する
      return
    }

    this.reflectQueryParamsToState(tmpQuery)
    this.preparedFilters = this.filterParameter
    this.isFiltering = this.enabledFilter
    this.getJobExecHistories()
  },
  beforeRouteUpdate (to, from, next) {
    // ルート変更に反応する
    if (to.query !== from.query) {
      this.reflectQueryParamsToState(to.query)
      this.getJobExecHistories()
    }
    next()
  },
  methods: {
    changeQuery (type, value) {
      const queryParams = this.queryParams

      // ジョブ一覧からジョブを指定して履歴を表示する場合はクエリパラメータに id が付く
      // filterParameter に入れるとぺーじをまたいで状態が保持されてしまい、削除する手段を用意しないといけなくなるので入れない
      if (this.$route.query.id) {
        queryParams.id = this.$route.query.id
      }

      if (type === 'filter') {
        if (!this.hasJobId) {
          queryParams.name = value.name
        }
        queryParams.status = value.status
        queryParams.enabledFilter = this.isFiltering
      } else {
        queryParams[type] = value
      }

      if (type !== 'page') {
        queryParams.page = DEFAULT_PAGE
      }

      // クエリパラメーター更新
      // クエリパラメーターを更新するとbeforeRouteUpdateが呼び出される
      this.$router.push({ query: queryParams })
        .catch(err => err) // 画面表示時の NavigationDuplicated 対策
    },
    /**
     * ジョブ実行履歴一覧リストの取得
     */
    getJobExecHistories () {
      this.$store.dispatch('jobExecHistory/getJobExecHistories')
        .then(res => {
          this.jobExecHistories = res.jobExecHistories
          this.paging = res.paging
        }).catch(err => {
          this.handleErrorResponse(err)
        })
    },
    reflectQueryParamsToState (query) {
      this.$store.dispatch('jobExecHistory/updateOrderBy', query.orderBy)
      this.$store.dispatch('jobExecHistory/updateIsDesc', JSON.parse(query.isDesc))
      this.$store.dispatch('jobExecHistory/updateEnabledFilter', JSON.parse(query.enabledFilter))
      const tmpFilterParameter = []
      if (Object.keys(query).length > 0) {
        // filterParameter として許可されているプロパティのみ有効
        for (const key in this.filterParameter) {
          if (query[key] === undefined && query[key] === null) {
            tmpFilterParameter[key] = null
          } else {
            tmpFilterParameter[key] = query[key]
          }
          // this.$route.query には文字列型で入ってるので、数値型にキャストできるなら変換
          if (tmpFilterParameter[key] !== null && tmpFilterParameter[key] !== '' && !Number.isNaN(Number(tmpFilterParameter[key]))) {
            tmpFilterParameter[key] = Number(tmpFilterParameter[key])
          }
          // this.$route.query には文字列型で入ってるので、boolean型にしたいものは変換
          if (tmpFilterParameter[key] === 'true') {
            tmpFilterParameter[key] = true
          }
          if (tmpFilterParameter[key] === 'false') {
            tmpFilterParameter[key] = false
          }
        }
      }
      this.$store.dispatch('jobExecHistory/updateFilter', tmpFilterParameter)
    },
    /**
     * フィルタリング条件の適用
     */
    setFilterParameter () {
      this.changeQuery('filter', this.preparedFilters)
    },
    /**
     * フィルタリング条件の初期化
     */
    resetFilterParameter () {
      this.preparedFilters = []
      this.setFilterParameter()
    },
  },
}
</script>
