<template>
  <v-card :width="cardWidth" outlined tile @submit.prevent="changePassword()" class="mx-auto">
    <v-container>
      <v-subheader class="remind_change_password" v-if="!passwordChangedFlg">
        <span v-html="$t(`form.changePassword.remind`)" />
      </v-subheader>
      <!-- フォームにフォーカスが当たっている時にエンターキーが入力された時にchangePasswordメソッドを発火 -->
      <v-card-text @keypress.enter="changePassword()">
        <!-- 入力フォーム -->
        <v-text-field
          :append-icon="currentPasswordForm ? 'ic-visibility-S' : 'ic-visibility-off-S'"
          @click:append="currentPasswordForm = !currentPasswordForm"
          :type="currentPasswordForm ? 'text' : 'password'"
          :label="$t(`form.changePassword.currentpassword`)"
          v-model="currentPassword"
          name="currentPassword"
          :data-vv-as="$t(`form.changePassword.currentpassword`)"
          v-validate="'required'"
          :error-messages="errors.collect('currentPassword')" />
        <v-text-field
          :append-icon="newPasswordForm ? 'ic-visibility-S' : 'ic-visibility-off-S'"
          @click:append="newPasswordForm = !newPasswordForm"
          :type="newPasswordForm ? 'text' : 'password'"
          :label="$t(`form.changePassword.newPassword`)"
          :hint="$t(`form.changePassword.passwordFormat`)"
          persistent-hint
          v-model="newPassword"
          name="newPassword"
          autocomplete="new-password"
          :data-vv-as="$t(`form.changePassword.newPassword`)"
          v-validate.continues="{
            required: true,
            min:10,
            excluded:[userMailAddress,currentPassword],
            passwordSyntax: true,
            passwordASCIIOnlySyntax: true
          }"
          :error-count="5"
          :error-messages="errors.collect('newPassword')" />
        <v-text-field
          :append-icon="newPasswordConfirmForm ? 'ic-visibility-S' : 'ic-visibility-off-S'"
          @click:append="newPasswordConfirmForm = !newPasswordConfirmForm"
          :type="newPasswordConfirmForm ? 'text' : 'password'"
          :label="$t(`form.changePassword.confirmNewPassword`)"
          v-model="newPasswordConfirm"
          name="confirmNewPassword"
          autocomplete="new-password"
          :data-vv-as="$t(`form.changePassword.confirmNewPassword`)"
          v-validate.continues="{ is: newPassword, required: true }"
          :error-count="2"
          :error-messages="errors.collect('confirmNewPassword')" />
      </v-card-text>
      <!-- パスワード変更ボタン -->
      <v-card-actions>
        <v-spacer />
        <v-btn color="denial" large :to="$store.state.route.from">{{ $t(`btn.cancel`) }}</v-btn>
        <v-btn color="primary" large @click="changePassword()" :loading="changingPassword || loadingLoginUser">
          {{ $t(`btn.passwordChange.change`) }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
// util
import notifyUtil from '@/utils/notifyUtil'

// ログインフォームの幅固定
const cardWidth = 350

export default {
  mixins: [notifyUtil],
  // 初期値設定
  data () {
    return {
      mailAddress: null,
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      cardWidth,
      changeInputType: false,
      currentPasswordForm: false,
      newPasswordForm: false,
      newPasswordConfirmForm: false,
    }
  },
  computed: {
    passwordChangedFlg () {
      return this.$store.state.auth.loginUserDetail.passwordChangedFlg
    },
    userMailAddress () {
      return this.$store.state.auth.loginUserDetail.loginUserAddress
    },
    loadingLoginUser () {
      return this.$store.state.auth.loadingLoginUser
    },
    changingPassword () {
      return this.$store.state.userInfo.changingPassword
    },
  },
  methods: {
    /**
     * パスワード入力欄を初期化する
     */
    initPasswordInput () {
      this.currentPassword = ''
      this.newPassword = ''
      this.newPasswordConfirm = ''
      this.$validator.reset()
    },
    /**
     * パスワード変更リクエストを送信する
     */
    changePassword () {
      this.$validator.validate().then((result) => {
        // バリデーションエラーがあれば中断
        if (!result) return

        this.$store.dispatch('userInfo/changePassword', {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          newPasswordConfirm: this.newPasswordConfirm,
        }).then((res) => {
          this.$store.dispatch('auth/getLoginUser').finally(() => {
            // 成功時に初期化
            this.initPasswordInput()
            this.notifySuccessMessage(this.$t('notify.password') + this.$t('notify.success.change'))
            this.$store.dispatch('auth/logout')
          })
        }).catch((err) => {
          this.handleErrorResponse(err)
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.remind_change_password {
  color: red;
}
</style>
