<template>
  <v-card tile elevation="0">
    <information-bar showTotalUser>
      <template #left>
        <v-btn :to="{ name: 'Segments', query: segmentsParams }">
          {{ $t(`btn.backToList`) }}
        </v-btn>
      </template>
      <template #right v-if="segment && segment.segmentId !== undefined">
        <v-btn
          class="mr-3"
          :to="{ name: 'SegmentSettingEdit', params: { segmentId: segment.segmentId } }"
        >
          {{ getLabel() }}
        </v-btn>
        <v-btn
          class="mr-3"
          :disabled="!canPost"
          :to="{ name: 'SegmentSettingCopy', params: { segmentId: segment.segmentId } }"
        >
          {{ $t('btn.copy') }}
        </v-btn>
        <v-btn
          :disabled="!canDelete || loadingSegment"
          @click="showModal = true"
        >
          {{ $t('btn.delete') }}
        </v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <!-- 基本情報 -->
      <v-card outlined tile class="mb-6">
        <v-card-text class="pb-3">
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="segment.segmentName"
                readonly
                label="セグメント名"
              />
              <v-textarea
                v-model="segment.note"
                outlined
                readonly
                rows="3"
                label="説明"
                hide-details
              />
            </v-col>
            <v-col cols="4" class="d-flex flex-column">
              <div>
                {{ $t('text.createdAt') }}&#58;&nbsp;{{ segment.createdAt }}
                <br>
                {{ $t('text.createdBy') }}&#58;&nbsp;{{ segment.createdBy }}
              </div>
              <div class="mt-2">
                {{ $t('text.updatedAt') }}&#58;&nbsp;{{ segment.updatedAt }}
                <br>
                {{ $t('text.updatedBy') }}&#58;&nbsp;{{ segment.updatedBy }}
              </div>
              <v-card-actions class="mt-auto pl-0 pb-0">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn small v-on="on" @click="downloadIuidList">{{ $t('btn.segment.download') }}</v-btn>
                  </template>
                  <span>{{ $t('text.segmentDetail.downLoadNotice') }}</span>
                </v-tooltip>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

        <!-- タブ -->
      <v-tabs v-model="selectedTab">
        <v-tab href="#users" v-html="$t('text.segments.tab.userAndFilter')"></v-tab>
        <v-tab href="#jobs" :disabled="!canGetThis('Jobs')">{{ $t(`text.segments.tab.job`) }}</v-tab>
        <v-tab href="#exportScripts" :disabled="!canGetThis('SegmentExportScripts')">{{ $t(`text.segments.tab.exportScript`) }}</v-tab>
        <v-tab href="#csvDownload" v-if="segment.userType === USER_TYPES.SCV.value">{{ $t(`text.segments.tab.csvDownload`) }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab" class="pa-0">
        <!-- ユーザー数 / フィルタ条件 -->
        <v-tab-item value="users">
          <div v-if="!loadingSegment">
            <inner-info :segment="segment"/>
            <inner-unknown v-if="segment.userType === USER_TYPES.USER_UNKNOWN.value" :segmentId="segment.segmentId"/>
            <inner-scv v-else-if="segment.userType === USER_TYPES.SCV.value" :segmentId="segment.segmentId" />
          </div>
          <v-row v-else :justify="'center'" class="ma-3">
            <v-progress-circular indeterminate color="primary" />
          </v-row>
        </v-tab-item>
        <!-- 関連ジョブ -->
        <v-tab-item value="jobs">
          <inner-job v-if="!loadingSegment" :segmentId="segment.segmentId"/>
          <v-row v-else :justify="'center'" class="ma-3">
            <v-progress-circular indeterminate color="primary" />
          </v-row>
        </v-tab-item>
        <!-- エクスポートスクリプト -->
        <v-tab-item value="exportScripts">
          <inner-export-script v-if="!loadingSegment" :segmentId="segment.segmentId" />
          <v-row v-else :justify="'center'" class="ma-3">
            <v-progress-circular indeterminate color="primary" />
          </v-row>
        </v-tab-item>
        <!-- CSVダウンロード -->
        <v-tab-item value="csvDownload">
          <inner-csv-download v-if="!loadingSegment" :segmentId="segment.segmentId"/>
          <v-row v-else :justify="'center'" class="ma-3">
            <v-progress-circular indeterminate color="primary" />
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <!-- 削除モーダル -->
    <v-dialog
      v-model="showModal"
      width="400"
      overlay-color="#07070820"
      overlay-opacity="1"
      :loading="loadingSegment"
    >
      <v-card>
        <v-card-title>
          <!-- dialog 内の v-card には上部余白の為必要 -->
        </v-card-title>
        <v-card-text>
          ID：{{ segment.segmentId }}
          <br>
          {{ $t(`notify.check.delete`, [segment.segmentName]) }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="denial" @click="showModal = false">{{ $t(`btn.no`) }}</v-btn>
          <v-btn color="primary" @click="deleteSegment()" :disabled="!canDelete">{{ $t(`btn.yes`) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from '@/axios'
import { mapGetters } from 'vuex'
// components
import innerExportScript from '@/components/SegmentDetail/innerExportScript'
import innerInfo from '@/components/SegmentDetail/innerInfo'
import innerJob from '@/components/SegmentDetail/innerJob'
import innerUnknown from '@/components/SegmentDetail/innerUnknown'
import innerScv from '@/components/SegmentDetail/innerScv'
import innerCsvDownload from '@/components/SegmentDetail/innerCsvDownload'
// enums
import CONFIRM_MESSAGES from '@/enum/notify/CONFIRM_MESSAGES'
import USER_TYPES from '@/enum/USER_TYPES'

// utils
import momentUtil from '@/utils/momentUtil'
import notifyUtil from '@/utils/notifyUtil'

export default {
  name: 'SegmentDetail',
  mixins: [
    CONFIRM_MESSAGES,
    USER_TYPES,
    momentUtil,
    notifyUtil,
  ],
  components: {
    innerExportScript,
    innerInfo,
    innerJob,
    innerUnknown,
    innerScv,
    innerCsvDownload,
  },
  data () {
    return {
      segment: {},
      targetDate: '',
      showModal: false,
      selectedTab: 'users',
    }
  },
  computed: {
    ...mapGetters('auth', ['canPost', 'canPut', 'canDelete', 'canGetThis']),
    ...mapGetters(
      {
        segmentsParams: 'segments/getQueryParams',
      },
    ),
    loadingSegment () {
      return this.$store.state.segments.loadingSegment
    },
  },
  created () {
    this.targetDate = this.momentUtil.getCurrentDateForSummary()
    // 指定のセグメント取得
    this.getSegment()
  },
  beforeDestroy () {
    this.segment = {}
  },
  methods: {
    getLabel () {
      if (this.canPost) {
        return this.$t('btn.edit')
      } else {
        return this.$t('btn.segment.search')
      }
    },
    /**
     * セグメント1件取得
     */
    getSegment () {
      this.$store.dispatch('segments/getSegment', { segmentId: Number(this.$route.params.segmentId), targetDate: this.targetDate })
        .then((res) => {
          this.segment = res.segment
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    /**
     * セグメント1件削除
     */
    deleteSegment () {
      this.$store.dispatch('segmentSetting/deleteSegment', this.segment.segmentId)
        .then(() => {
          this.showModal = false
          this.notifySuccessMessage(this.$t('notify.segment') + this.$t('notify.success.delete'))
          this.$router.push({ name: 'Segments' }, () => {})
        }).catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    /**
     * iuidリストのダウンロード
     */
    downloadIuidList () {
      this.isDownload = true
      axios.get('/segments/' + this.segment.segmentId + '/iuidList/', {
        responseType: 'blob',
      }).then((res) => {
        const today = this.momentUtil.getCurrentDate()
        // ファイル名を「セグメント名_yyyyMMdd.csv」にする
        const fileName = this.segment.segmentName + '_' + today + '.csv'
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      }).catch((err) => {
        this.handleErrorResponse(err)
      }).finally(() => {
        this.isDownload = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

@include theme(v-tabs-items) using ($integral-core-theme) {
  &::v-deep .cardTitle {
    color: map-deep-get($integral-core-theme, 'views', 'segmentDetail', 'tab', 'text');
    &.customer {
      color: map-deep-get($integral-core-theme, 'views', 'segmentDetail', 'customer', 'text');
    }
  }
  &::v-deep .caution {
    color: map-deep-get($integral-core-theme, 'views', 'segmentDetail', 'tab', 'text');
  }
}

.panel--hide {
  height: 100vh;
}
::v-deep .v-data-table__wrapper {
  // v-data-tableはheightしか設定できないため
  max-height: 400px;
  th.column {
    // progress barもスクロールされてしまうため
    top: 48px !important;
  }
}
</style>
