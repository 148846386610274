<template>
  <v-container fluid>
    <!-- sort/limit -->
    <v-row justify="space-between" class="pb-3">
      <v-col cols="4">
        <v-select
          v-model="orderBy"
          class="sort pt-0"
          hide-details
          :items="sortableColumns"
          :disabled="loadingSegmentExportScripts"
          @change="fetchSegmentExportScripts"
        >
          <template #prepend>
            <span class="prepend-sort-text">
              {{ $t('text.sort') }}
            </span>
          </template>
          <template #append-outer>
            <v-btn icon @click="isDesc = !isDesc; fetchSegmentExportScripts()" :disabled="loadingSegmentExportScripts">
              <v-icon v-if="isDesc">ic-sort-desc</v-icon>
              <v-icon v-else>ic-sort-asc</v-icon>
            </v-btn>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-progress-linear v-if="loadingSegmentExportScripts" indeterminate color="primary" />
    <large-list
      v-for="(script) in segmentExportScripts"
      :key="script.segmentExportScriptId"
      :name="script.segmentExportScriptName"
      :note="script.note"
      :to="'/segmentExportScript/setting/' + script.segmentExportScriptId"
    >
      <!-- ヘッダー -->
      <template #header>
        <v-row class="align-center">
          <v-col cols="1">
            <v-chip
              v-if="script.lastSegmentExportScriptExecHistory === null || script.lastSegmentExportScriptExecHistory.execStatus === undefined"
              color="pending"
              :to="{ name: 'SegmentExportScriptHistory', params: { segmentExportScriptId: script.segmentExportScriptId }}"
            >
              未実行
            </v-chip>
            <v-chip
              v-else-if="script.lastSegmentExportScriptExecHistory.execStatus === EXEC_STATUS.PROCESSING.value"
              color="ongoing"
              :to="{ name: 'SegmentExportScriptHistory', params: { segmentExportScriptId: script.segmentExportScriptId }}"
            >
              {{ enumUtil.getEnumFromValue(EXEC_STATUS, script.lastSegmentExportScriptExecHistory.execStatus).text }}
            </v-chip>
            <v-chip
              v-else-if="script.lastSegmentExportScriptExecHistory.execStatus === EXEC_STATUS.SUCCESS.value"
              color="success"
              :to="{ name: 'SegmentExportScriptHistory', params: { segmentExportScriptId: script.segmentExportScriptId }}"
            >
              {{ enumUtil.getEnumFromValue(EXEC_STATUS, script.lastSegmentExportScriptExecHistory.execStatus).text }}
            </v-chip>
            <v-chip
              v-else-if="script.lastSegmentExportScriptExecHistory.execStatus === EXEC_STATUS.FAILED.value"
              color="fault"
              :to="{ name: 'SegmentExportScriptHistory', params: { segmentExportScriptId: script.segmentExportScriptId }}"
            >
              {{ enumUtil.getEnumFromValue(EXEC_STATUS, script.lastSegmentExportScriptExecHistory.execStatus).text }}
            </v-chip>
          </v-col>
          <v-col cols="9">
            {{ $t('text.segmentExportScripts.segmentName') }}&#58;&nbsp;{{ script.segmentName }}
          </v-col>
          <v-col cols="2" class="text-right">
            <v-btn
              v-show="script.lastSegmentExportScriptExecHistory !== null && script.lastSegmentExportScriptExecHistory.execStatus !== undefined"
              small
              :to="{ name: 'SegmentExportScriptHistory', params: { segmentExportScriptId: script.segmentExportScriptId }}"
              class="mr-2"
            >
              {{ $t(`text.segmentExportScripts.execHistory`) }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <!-- col2 -->
      <template #col2>
        {{ $t('text.createdAt') }}&#58;&nbsp;{{ script.createdAt }}
        <br>
        {{ $t('text.updatedAt') }}&#58;&nbsp;{{ script.updatedAt }}
      </template>
      <!-- col3 -->
      <template #col3>
        {{ $t(`text.segmentExportScripts.scriptStatus`) }}&#58;&nbsp;{{ script.executable ? $t('text.segmentExportScripts.executable') : $t('text.segmentExportScripts.unexecutable') }}
        <br>
        {{ $t(`text.segmentExportScripts.recurringTypes`) }}&#58;&nbsp;{{ enumUtil.getEnumFromValue(SEGMENT_EXPORT_SCRIPT_RECURRING_TYPES, script.segmentExportScheduleType).text }}
      </template>
    </large-list>
    <v-card v-if="segmentExportScripts.length === 0" outlined tile>
      <v-card-text>{{ $t('text.noData') }}</v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
// components
import LargeList from '@/components/common/largeList'
// enum
import EXEC_STATUS from '@/enum/EXEC_STATUS'
import SEGMENT_EXPORT_SCRIPT_RECURRING_TYPES from '@/enum/SEGMENT_EXPORT_SCRIPT_RECURRING_TYPES'
// util
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'

export default {
  components: {
    LargeList,
  },
  mixins: [
    enumUtil,
    notifyUtil,
    EXEC_STATUS,
    SEGMENT_EXPORT_SCRIPT_RECURRING_TYPES,
  ],
  props: {
    segmentId: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      executableOptions: [
        { text: this.$t('text.segmentExportScripts.executable'), value: true },
        { text: this.$t('text.segmentExportScripts.unexecutable'), value: false },
      ],
      sortableColumns: [
        {
          text: this.$t('text.segmentExportScripts.sort.createdAt'),
          value: 'createdAt',
        },
        {
          text: this.$t('text.segmentExportScripts.sort.updatedAt'),
          value: 'updatedAt',
        },
        {
          text: this.$t('text.segmentExportScripts.sort.scriptStatus'),
          value: 'executable',
        },
      ],
      totalCount: null,
      segmentExportScripts: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['canDelete', 'canGetThis']),
    orderBy: {
      get () {
        return this.$store.state.segmentExportScripts.sortParameter.orderBy
      },
      set (newVal) {
        this.$store.dispatch('segmentExportScripts/updateOrderBy', newVal)
      },
    },
    isDesc: {
      get () {
        return this.$store.state.segmentExportScripts.sortParameter.isDesc
      },
      set (newVal) {
        this.$store.dispatch('segmentExportScripts/updateIsDesc', newVal)
      },
    },
    loadingSegmentExportScripts: {
      get () {
        return this.$store.state.segmentExportScripts.loadingSegmentExportScripts
      },
      set (newVal) {
        this.$store.dispatch('segmentExportScripts/updateLoadingSegmentExportScripts', newVal)
      },
    },
  },
  created () {
    this.loadingSegmentExportScripts = true
    this.$store.dispatch('segmentExportScripts/filterReset')
    this.fetchSegmentExportScripts()
  },
  methods: {
    fetchSegmentExportScripts () {
      this.$store.dispatch('segmentExportScripts/getExportScriptsBySegmentId', this.segmentId)
        .then((res) => {
          this.segmentExportScripts = res.segmentExportScripts
        })
        .catch((err) => {
          this.handleErrorResponse(err)
        })
    },
  },
}
</script>
