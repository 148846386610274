<template>
  <v-card tile elevation="0">
    <information-bar showTotalUser>
      <template #left>
        <v-btn color="primary" @click="$vuetify.theme.dark = !$vuetify.theme.dark">light/dark</v-btn>
      </template>
      <template #right>
        <v-btn @click="$vuetify.theme.dark = !$vuetify.theme.dark"><v-icon>mdi-theme-light-dark</v-icon></v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <v-row>
        <v-col cols="2">
          <v-list dense subheader outlined>
            <v-list-item-group v-model="currentPattern" @change="selectPattern">
              <v-list-item v-for="(pattern, index) in patterns" :key="index" :value="pattern">
                {{pattern}}
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col cols="10">
          <keep-alive>
            <component :is="currentPatternComponent"></component>
          </keep-alive>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Auth from '@/components/Patterns/auth'
import Buttons from '@/components/Patterns/buttons'
import Cards from '@/components/Patterns/cards'
import Chips from '@/components/Patterns/chips'
import Dialogs from '@/components/Patterns/dialogs'
import Icons from '@/components/Patterns/icons'
import Inputs from '@/components/Patterns/inputs'
import LargeLists from '@/components/Patterns/largeLists'
import Lists from '@/components/Patterns/lists'
import Pagination from '@/components/Patterns/pagination'
import Tables from '@/components/Patterns/tables'
import Tabs from '@/components/Patterns/tabs'
import Tooltips from '@/components/Patterns/tooltips'
import Typography from '@/components/Patterns/typography'

export default {
  name: 'PatternLibrary',
  components: {
    Auth,
    Buttons,
    Cards,
    Chips,
    Dialogs,
    Icons,
    Inputs,
    LargeLists,
    Lists,
    Pagination,
    Tables,
    Tabs,
    Tooltips,
    Typography,
  },
  data () {
    return {
      currentPattern: '',
      patterns: [
        'Auth',
        'Buttons',
        'Cards',
        'Chips',
        'Dialogs',
        'Icons',
        'Inputs',
        'LargeLists',
        'Lists',
        'Pagination',
        'Tables',
        'Tabs',
        'Tooltips',
        'Typography',
      ],
    }
  },
  created () {
    const hash = this.$route.hash
    if (hash) {
      this.currentPattern = hash.split('#')[1]
    }
  },
  computed: {
    currentPatternComponent: function () {
      // convert camlCased to kebab-case
      return this.currentPattern?.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
    },
  },
  methods: {
    selectPattern (selected) {
      if (selected) {
        this.$router.push({ hash: '#' + selected })
      } else {
        this.$router.push({ hash: '' })
      }
    },
  },
}
</script>
