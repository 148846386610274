export default {
  data () {
    return {
      USER_TYPES: {
        USER_UNKNOWN: { text: '匿名客', value: 1 },
        SCV: { text: 'カスタマー', value: 3 },
      },
    }
  },
}
