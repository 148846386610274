import axios from '@/axios'

const segmentSummary = {
  namespaced: true,
  state: {
    loadingSegmentsUserCnt: true,
  },
  mutations: {
    setLoadingSegmentsUserCnt (state, newval) {
      state.loadingSegmentsUserCnt = newval
    },
  },
  actions: {
    /**
     * セグメント日別ユーザー数取得
     * @param  {Object} params リクエストパラメーター
     * @return {Promise}
     */
    getSegmentUserDailyWithInterval ({ commit }, params) {
      return axios.get('/segments/' + params.segmentId + '/userDaily/', {
        params: {
          baseDate: params.baseDate,
          dateIntervalType: params.dateIntervalType,
          dataPoint: params.dataPoint,
        },
      })
    },
    /**
     * 企業内全セグメントユーザー数一覧取得
     * @param {Object} params リクエストパラメーター
     * @return {Promise}
     */
    getSegmentsUserCnt ({ commit }, params) {
      commit('setLoadingSegmentsUserCnt', true)
      return axios.get('/segments/userDaily/total/', {
        params: {
          baseDate: params.baseDate,
          interval: params.interval,
        },
      }).finally(() => {
        commit('setLoadingSegmentsUserCnt', false)
      })
    },
  },
}

export default segmentSummary
