<template>
  <v-card tile elevation="0">
    <information-bar showTotalUser>
      <template #left>
        <v-btn v-if="segmentId === undefined" :to="'/segments/'">
          {{ $t(`btn.backToList`) }}
        </v-btn>
        <v-btn v-else :to="'/segment/' + segmentId">
          {{ $t(`btn.back`) }}
        </v-btn>
      </template>
      <template #right>
        <v-btn color="secondary" @click="$refs.searchComponent.searchSegmentUsers()" :loading="isProcessing">
          {{ $t(`btn.segment.search`) }}
        </v-btn>
      </template>
    </information-bar>
    <v-card-text>
      <segment-search
        ref="searchComponent"
        :build-type="selectedBuildType"
        :is-processing="isProcessing"
        @updateLoadingStatus="loadingSegmentSetting = $event"
        @createQuery="createQueryHandler($event)"
        @searchSegment="searchSegmentHandler($event)"
        @createSegment="createSegmentHandler($event)"
        @updateSegment="updateSegmentHandler($event)"
        @buildTypeChanged="buildTypeChangedHandler($event)" />
    </v-card-text>

    <v-footer v-if="isStandard" fixed>
      <v-col cols="11" offset="1" class="d-flex justify-center">
        <v-btn class="mr-8" large color="denial" :to="segmentId ? '/segment/' + segmentId : '/segments'">
          {{ $t(`btn.cancel`) }}
        </v-btn>
        <v-btn class="mr-8" large v-if="isStandard" @click="$refs.searchComponent.createSearchQuery()" :loading="this.isProcessing">
          {{ $t(`btn.segment.query`) }}
        </v-btn>
        <v-btn class="mr-8" large color="primary" v-if="this.isUpdateMode" @click="$refs.searchComponent.validateAndBuildingRequestParameter()" :disabled="!canPut">
          {{ $t(`btn.segment.update`) }}
        </v-btn>
        <v-btn class="mr-4" large color="primary" v-else @click="$refs.searchComponent.validateAndBuildingRequestParameter()" :disabled="(!isSQLSetting && segmentCount >= segmentRegistrationLimit) || !canPost">
          {{ $t(`btn.segment.create`) }}
        </v-btn>
        <v-tooltip top v-if="!isSQLSetting && !this.isUpdateMode && segmentCount >= segmentRegistrationLimit">
          <template #activator="{ on }">
            <!-- <v-icon>ic-warnning-S</v-icon> が使えないので暫定措置 -->
            <span v-on="on" class="v-icon notranslate ic ic-warnning-S" style="font-size: 20px;"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
          </template>
          <span>セグメント作成可能上限数に達しているため、作成できません。作成可能上限数は{{ segmentRegistrationLimit }}件です</span>
        </v-tooltip>
      </v-col>
    </v-footer>

    <!-- ユーザー検索結果 -->
    <v-dialog
      v-model="isSearched"
      width="800"
      overlay-color="#07070820"
      overlay-opacity="1"
      persistent
    >
      <segment-result
        :is-searched="isSearched"
        :build-type="selectedBuildType"
        :is-searching-users="loadingSearch"
        :is-search-users-failed="isSearchUsersFailed"
        :search-condition="searchCondition"
        :user-total="totalUserNum"
        :segmentUserTotal="segmentUserNum"
        :segment-summary-data="segmentSummaryData"
        :user-list="userList"
        @close="isSearched = false"
      />
    </v-dialog>

    <!-- フィルター条件クエリ -->
    <v-dialog
      v-model="showSearchQuery"
      width="40%"
      overlay-color="#07070820"
      overlay-opacity="1"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t('text.segmentSetting.searchQuery') }}
        </v-card-title>
        <v-card-text>
          <v-textarea
            filled
            :value="searchQuery"
            readonly
            no-resize
            rows="15">
            <v-tooltip slot="append-outer" bottom>
              <template #activator="{ on }">
                <v-btn v-on="on" icon color="primary"
                  v-clipboard:copy="searchQuery"
                  v-clipboard:success="copySuccess"
                  v-clipboard:error="copyError">
                  <v-icon>ic-copy-clipboard-S</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t(`btn.clipboardCopy`)}}
              </span>
            </v-tooltip>
          </v-textarea>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn class="mr-3" color="primary" @click="showSearchQuery = false">{{ $t(`btn.ok`) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from '@/axios'
import { mapGetters } from 'vuex'
// components
import searchComponent from '@/components/SegmentSetting/search'
import resultComponent from '@/components/SegmentSetting/result'
// enum
import SEGMENT_SUMMARIES from '@/enum/SEGMENT_SUMMARIES'
import SEGMENT_BUILD_TYPE from '@/enum/SEGMENT_BUILD_TYPE'
import USER_TYPES from '@/enum/USER_TYPES'
// mixins
import segmentFilterRule from '@/mixins/segmentFilterRule'
// util
import displayConverter from '@/utils/displayConverter'
import momentUtil from '@/utils/momentUtil'
import notifyUtil from '@/utils/notifyUtil'
import segmentTexts from '@/utils/segmentTexts'

const SEGMENT_EDIT_PAGE_NAME = 'SegmentSettingEdit'
const SEGMENT_SQL_SETTING_PAGE_NAME = 'SegmentSQLSetting'

export default {
  components: {
    'segment-search': searchComponent,
    'segment-result': resultComponent,
  },
  mixins: [
    SEGMENT_SUMMARIES,
    SEGMENT_BUILD_TYPE,
    USER_TYPES,
    segmentFilterRule,
    displayConverter,
    momentUtil,
    notifyUtil,
    segmentTexts,
  ],
  data () {
    return {
      /**
       * 検索済みフラグ
       * @type {Boolean}
       **/
      isSearched: false,
      /**
       * 構築種別：セグメント作成なのかセグメント作成(SQL)なのか
       * @type {Number}
       */
      selectedBuildType: 1,
      /**
       * セグメント作成/更新中フラグ
       * @type {Boolean}
       */
      isRequestSegment: false,
      /**
       * ユーザー検索失敗フラグ
       * @type {Boolean}
       */
      isSearchUsersFailed: false,
      /**
       * 検索時の条件
       * @type {Object}
       */
      searchCondition: {
        segmentName: null,
        userType: null,
        buildType: null,
        filterQuery: null,
        filterRuleJson: null,
        baseDate: null,
      },
      /**
      * 総ユーザー数
      * @type {Number}
      */
      totalUserNum: 0,
      /**
      * セグメントに紐付く総ユーザー数
      * @type {Number}
      */
      segmentUserNum: 0,
      /**
       * 取得した該当ユーザー一覧
       * @type {Array}
       */
      userList: [],
      /**
       * 取得したセグメント実績データ
       * @type {Object}
       */
      segmentSummaryData: {},
      /**
       * セグメント設定取得中フラグ
       * @type {Boolean}
       */
      loadingSegmentSetting: false,
      /**
       * 契約企業内の登録済みセグメント数
       * @type {Number}
       */
      segmentCount: 0,
      /**
       * 契約企業内のセグメント登録可能上限数
       * @type {Number}
       */
      segmentRegistrationLimit: 0,
      /**
       * フィルター条件から生成されたクエリ
       * @type {String}
       */
      searchQuery: null,
      /**
       * クエリ表示モーダル管理フラグ
       * @type {Boolean}
       */
      showSearchQuery: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['canPost', 'canPut']),
    /**
     * APIからのレスポンス待ちフラグ
     * @type {Boolean}
     */
    isProcessing () {
      return (this.isRequestSegment || this.loadingSearch)
    },
    isUpdateMode () {
      return this.$route.name === SEGMENT_EDIT_PAGE_NAME
    },
    isSQLSetting () {
      return this.$route.name === SEGMENT_SQL_SETTING_PAGE_NAME
    },
    isStandard () {
      return this.selectedBuildType === this.SEGMENT_BUILD_TYPE.STANDARD.value
    },
    /**
     * 編集中のセグメントID
     * @type {Number}
     */
    segmentId () {
      return this.$route.params.segmentId
    },
    loadingSearch () {
      return this.$store.state.segmentSearch.loadingSearch
    },
  },
  watch: {
    // ルートの変更の検知
    $route: {
      handler () {
        this.isSearched = false
        // 違う URL で同一 View を利用しているため切り替え
        if (this.isSQLSetting) {
          this.selectedBuildType = this.SEGMENT_BUILD_TYPE.CUSTOM.value
        } else {
          this.selectedBuildType = this.SEGMENT_BUILD_TYPE.STANDARD.value
        }
      },
    },
  },
  created () {
    // カスタムセグメント
    if (this.isSQLSetting) {
      this.selectedBuildType = this.SEGMENT_BUILD_TYPE.CUSTOM.value
    }

    // サマリ初期化
    const summaries = {}
    for (const key of Object.keys(this.SEGMENT_SUMMARIES)) {
      const enumObj = this.SEGMENT_SUMMARIES[key]
      summaries[enumObj.value] = {}
      summaries[enumObj.value].label = enumObj.text
      summaries[enumObj.value].value = 0
    }
    this.segmentSummaryData = summaries

    this.fetchSegmentCountAndAlert()
  },
  methods: {
    /**
     * セグメント数を取得し次の登録で課金プランが変更になる場合に警告を出力する
     */
    fetchSegmentCountAndAlert () {
      // 更新モードはセグメント数が不要なので取得しない
      if (this.isUpdateMode) return
      axios.get('/segments/total/standard/')
        .then((res) => {
          this.segmentCount = res.data.data.total
          this.segmentRegistrationLimit = res.data.data.segmentRegistrationLimit
        }).catch((err) => {
          this.handleErrorResponse(err)
        })
    },
    /**
     * 標準セグメントのフィルター条件から検索クエリを生成して表示
     */
    createQueryHandler (createQueryParams) {
      this.isRequestSegment = true
      // URLは仮置
      axios.put('/segments/search/query/', {
        userType: createQueryParams.userType,
        baseDate: createQueryParams.baseDate,
        filterRuleJson: JSON.stringify(createQueryParams.filterRuleJson),
      })
        .then(res => {
          this.searchQuery = res.data.data.query
          this.showSearchQuery = true
        }).catch((err) => {
          this.handleErrorResponse(err)
        }).finally(() => {
          this.isRequestSegment = false
        })
    },
    /**
     * 検索ボタン押下時ハンドラ
     * @param {Object} searchParams 検索条件パラム
     **/
    searchSegmentHandler (searchParams) {
      // 検索条件保持
      this.searchCondition = searchParams

      // ユーザー数検索
      this.searchSegmentUser(searchParams)
    },
    /**
     * セグメント作成ボタン押下時ハンドラ
     * @param {Object} createParams セグメント作成時パラム
     */
    createSegmentHandler (createParams) {
      this.isRequestSegment = true
      this.$store.dispatch('segmentSetting/postSettingSegment', createParams)
        .then((res) => {
          this.notifySuccessMessage(this.$t('notify.segment') + this.$t('notify.success.create'))
          // 登録完了時にセグメント数更新と必要であれば警告
          this.fetchSegmentCountAndAlert()
          this.$router.push({ name: 'Segments' }, () => {})
        }).catch((err) => {
          this.handleErrorResponse(err)
        }).finally(() => {
          this.isRequestSegment = false
        })
    },
    /**
     * セグメント更新ボタン押下時ハンドラ
     * @param {Object} updateParams セグメント更新時パラム
     */
    updateSegmentHandler (updateParams) {
      this.isRequestSegment = true
      this.$store.dispatch('segmentSetting/putSettingSegment', updateParams)
        .then(() => {
          this.notifySuccessMessage(this.$t('notify.segment') + this.$t('notify.success.update'))
          this.$router.push({ name: 'Segments' }, () => {})
        }).catch((err) => {
          this.handleErrorResponse(err)
        }).finally(() => {
          this.isRequestSegment = false
        })
    },
    /**
     * 検索条件の構築種別変更時ハンドラ
     * @param {Number} buildType 構築種別
     */
    buildTypeChangedHandler (buildType) {
      this.selectedBuildType = buildType
    },
    /**
     * セグメント該当ユーザーを検索する
     * @param {Object} searchParams 検索パラメータ
     */
    searchSegmentUser (searchParams) {
      // 初期化
      this.totalUserNum = 0
      this.segmentUserNum = 0
      this.userList = []

      // 検索済みフラグを変更
      this.isSearched = true
      // 検索
      this.$store.dispatch('segmentSearch/searchSegmentUser', searchParams)
        .then((res) => {
          this.isSearchUsersFailed = false
          const data = res.data.data
          switch (data.userType) {
            case this.USER_TYPES.USER_UNKNOWN.value:
              this.totalUserNum = this.$store.state.totalUser.totalUser.userUnknown.total
              break
            case this.USER_TYPES.SCV.value:
              this.totalUserNum = this.$store.state.totalUser.totalUser.userCustomer.total
          }
          this.segmentUserNum = data.segmentUserTotal
          this.userList = data.userList
        }).catch((err) => {
          this.isSearchUsersFailed = true
          this.handleErrorResponse(err)
        })
    },
    copySuccess () {
      this.notifySuccessMessage(this.$t('notify.success.copyToClipboard'))
    },
    copyError (e) {
      this.notifyErrorMessage(e)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/theme.scss';

@include theme(v-card) using ($integral-core-theme) {
  &::v-deep .caption {
    color: map-deep-get($integral-core-theme, 'views', 'segmentSetting', 'caption', 'text');
  }
}
</style>
