<template>
  <div>
    <!-- リージョン -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-autocomplete
          v-model="region"
          :items="enumUtil.convertForSelectList(AWS_S3_REGION)"
          clearable
          hide-no-data
          :label="$t(`form.region`)"
          name="region"
          :data-vv-as="$t(`form.region`)"
          v-validate="'required'"
          :error-messages="errors.collect('region')" />
      </v-col>
    </v-row>
    <!-- バケット名 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="bucket"
          :label="$t(`form.jobSetting.jobTask.bucket`)"
          name="bucket"
          :data-vv-as="$t(`form.jobSetting.jobTask.bucket`)"
          v-validate="'required'"
          :error-messages="errors.collect('bucket')" />
      </v-col>
    </v-row>
    <!-- ファイル名 -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="filePath"
          :label="$t(`form.jobSetting.jobTask.file`)"
          name="filepath"
          :data-vv-as="$t(`form.jobSetting.jobTask.file`)"
          v-validate="'required'"
          :error-messages="errors.collect('filepath')">
          <v-tooltip slot="append" bottom content-class="placeholderTooltip">
            <template #activator="{ on }">
              <v-icon v-on="on">ic-help-S</v-icon>
            </template>
            <v-card tile flat>
              <v-card-title>利用可能なプレースホルダー</v-card-title>
              <v-card-text class="pa-3">
                <v-data-table
                  :headers="[{ text: 'プレースホルダ', value: 'placeholder' }, { text: '出力', value: 'output' },]"
                  :items="[
                    { placeholder: '%%env.yyyy%%', output: '年(YYYY)' },
                    { placeholder: '%%env.mm%%', output: '月(MM)' },
                    { placeholder: '%%env.dd%%', output: '日(DD)' },
                    { placeholder: '%%env.hh%%', output: '時(HH)' },
                    { placeholder: '%%env.mi%%', output: '分(MI)' },
                    { placeholder: '%%env.ss%%', output: '秒(SS)' },
                    { placeholder: '%%job_setting_id%%', output: 'JOB設定ID' },
                    { placeholder: '%%job_task_setting_id%%', output: 'JOBタスク設定ID' },
                  ]"
                  hide-default-header
                  hide-default-footer
                  disable-pagination
                  disable-sort
                />
              </v-card-text>
            </v-card>
          </v-tooltip>
        </v-text-field>
      </v-col>
    </v-row>
    <!-- アクセスキー -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="accessKey"
          autocomplete="new-password"
          :label="$t(`form.accessKey`)"
          name="accesskey"
          :data-vv-as="$t(`form.accessKey`)"
          v-validate="'required'"
          :error-messages="errors.collect('accesskey')" />
      </v-col>
    </v-row>
    <!-- シークレットキー -->
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-text-field
          v-model="secretKey"
          autocomplete="new-password"
          type="password"
          :label="$t(`form.secretKey`)"
          name="secretkey"
          :data-vv-as="$t(`form.secretKey`)"
          v-validate="'required'"
          :error-messages="errors.collect('secretkey')" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// enum
import AWS_S3_REGION from '@/enum/jobTask/AWS_S3_REGION'
import JSON_OUTPUT_ITEM_NAMES from '@/enum/jobTask/JSON_OUTPUT_ITEM_NAMES'
// util
import enumUtil from '@/utils/enumUtil'

export default {
  mixins: [
    AWS_S3_REGION,
    JSON_OUTPUT_ITEM_NAMES,
    enumUtil,
  ],
  props: ['outputForm'],
  computed: {
    bucket: {
      get () {
        if (this.checkSettingProp()) {
          return this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.S3.value].bucket
        }
        return null
      },
      set (value) {
        this.updateValue('bucket', value)
      },
    },
    region: {
      get () {
        if (this.checkSettingProp()) {
          return this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.S3.value].region
        }
        return null
      },
      set (value) {
        this.updateValue('region', value)
      },
    },
    filePath: {
      get () {
        if (this.checkSettingProp()) {
          return this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.S3.value].filePath
        }
        return null
      },
      set (value) {
        this.updateValue('filePath', value)
      },
    },
    accessKey: {
      get () {
        if (this.checkSettingProp()) {
          return this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.S3.value].accessKey
        }
        return null
      },
      set (value) {
        this.updateValue('accessKey', value)
      },
    },
    secretKey: {
      get () {
        if (this.checkSettingProp()) {
          return this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.S3.value].secretKey
        }
        return null
      },
      set (value) {
        this.updateValue('secretKey', value)
      },
    },
  },
  methods: {
    /**
     * S3のタスクか判定
     * @return {Boolean}
     */
    checkSettingProp () {
      return this.outputForm && this.outputForm[this.JSON_OUTPUT_ITEM_NAMES.S3.value]
    },
    /**
     * S3アウトプットタスクの値をJSONに変換して更新
     * @param  {String} propertyName  更新対象のキー
     * @param  {String} propertyValue 更新対象の値
     */
    updateValue (propertyName, propertyValue) {
      if (!this.outputForm) return

      const json = Object.assign({}, this.outputForm)
      if (!json[this.JSON_OUTPUT_ITEM_NAMES.S3.value]) {
        this.$set(json, this.JSON_OUTPUT_ITEM_NAMES.S3.value, {})
      }
      Object.assign(json[this.JSON_OUTPUT_ITEM_NAMES.S3.value], { [propertyName]: propertyValue })
      this.$emit('update', json)
    },
  },
}
</script>

<style lang="scss" scoped>
.placeholderTooltip.menuable__content__active {
  opacity: 1!important;
}
</style>
