<template>
  <v-card outlined tile>
    <v-card-text>
      <v-form v-if="scvSetting">
        <!-- カラム名称 -->
        <v-row>
          <v-col cols="6" class="pb-0">
            <v-text-field
              v-model="formParameter.scvLogicalColumnName"
              :readonly="!scvSetting.isCustom"
              :label="$t(`form.scvSetting.scvColumnName`)"
              name="scvColumnName"
              :data-vv-as="$t(`form.scvSetting.scvColumnName`)"
              v-validate="'required|max:200'"
              :error-messages="errors.collect('scvColumnName')" />
          </v-col>
        </v-row>
        <v-row dense v-if="scvSetting.isCustom">
          <v-col cols="12">
            <v-radio-group
              v-model.number="formParameter.dataType"
              row
              hide-details
              mandatory
              class="dataType align-center"
              :label="$t(`form.scvSetting.dataType`)"
              :disabled="isUpdateMode"
              name="scvDataType"
              :data-vv-as="'required'"
              :error-messages="errors.collect('scvDataType')"
            >
              <v-radio
                v-for="type in SCV_DATA_TYPES"
                :key="type.value"
                :label="type.text"
                :value="type.value"
                color="inputSelectionControl"
              />
              <div class="caption">※設定後データ型は変更できません</div>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-switch
              dense
              :input-value="scvSetting.isScvIdentify"
              @click="showScvIdentifyModal = true"
              readonly
              hide-details
              :label="$t('text.scvSetting.setScvIdentify')"
              class="overline"
            />
          </v-col>
          <!-- TODO: Phase2の隠し機能 -->
          <!-- <v-col cols="6">
            <v-switch
              v-model="scvSetting.isSync"
              hide-details
              :label="$t('text.scvSetting.scvSync')"
            />
          </v-col> -->
        </v-row>
        <v-row>
          <!-- データセット一覧 -->
          <v-col cols="6 pt-1">
            <div class="subtitle-1 pb-2">{{ $t(`text.scvSetting.scvSettingMasterList.title`)}}</div>
            <scv-setting-master-list
              :dataSetRowInfoList="dataSetRowInfoList"
              :scvSetting="scvSetting"
              :selectedDataSetId="selectedDataSetId"
              :selectedCandidateIndex="selectedCandidateIndex"
              v-on="$listeners"
            />
          </v-col>
          <!-- 設定値 -->
          <v-col cols="6 pt-1">
            <div class="subtitle-1 pb-2">設定値</div>
            <div class="caption pb-2" v-html="$t(`text.scvSetting.scvSettingDetail.hint`)" />
            <v-list nav class="pa-0">
              <v-list-item-group v-model="computedSelectedDataSetId">
                <draggable v-model="formParameter.scvColumnChoiceSettingList">
                  <v-list-item
                    v-for="(scvColumnChoiceSetting, index) in formParameter.scvColumnChoiceSettingList"
                    :key="scvColumnChoiceSetting.dataSetId"
                    :value="scvColumnChoiceSetting.dataSetId"
                    ripple
                    class="px-3"
                  >
                    <v-list-item-content class="align-self-start flex-grow-0" style="flex-basis: 20px;">
                      <div class="mr-2">
                        {{ index + 1 }}
                      </div>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <div v-on="on" class="text-truncate">
                            {{ scvColumnChoiceSetting.dataSetName }}
                          </div>
                        </template>
                        <span class="break-all">
                          {{ scvColumnChoiceSetting.dataSetName }}
                        </span>
                      </v-tooltip>
                      <template v-for="(canditate, index2) in scvColumnChoiceSetting.headerMeta">
                        <v-divider v-if="index2 !== 0" :key="index + '_' + index2 + '_divider'" />
                        <div class="mt-1" :key="index + '_' + index2">
                          <span v-for="(column, index3) in canditate" :key="column.idx + column.physicalName">
                            <span v-show="index3 !== 0">&nbsp;&#043;&nbsp;</span>
                            <template v-if="column.disabled === true">
                              <v-tooltip bottom>
                                <template #activator="{ on }">
                                  <span v-on="on" class="red--text">
                                    {{ column.logicalName }}
                                  </span>
                                </template>
                                {{ $t('tooltip.scvSetting.disabledDataSetColumn') }}
                              </v-tooltip>
                            </template>
                            <template v-else-if="column.dataType !== formParameter.dataType">
                              <v-tooltip bottom>
                                <template #activator="{ on }">
                                  <span v-on="on" class="red--text">
                                    {{ column.logicalName }}
                                  </span>
                                </template>
                                {{ $t('tooltip.scvSetting.dataTypeMismatch') }}
                              </v-tooltip>
                            </template>
                            <template v-else>
                              {{ column.logicalName }}
                            </template>
                          </span>
                        </div>
                      </template>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon @click.stop="onClickDeleteButton(index)">ic-close-S</v-icon>
                    </v-list-item-action>
                    <v-list-item-icon class="mr-0 ml-3 mt-3">
                      <v-icon class="draggableIcon" small>ic-handle-S</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </draggable>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="pb-6">
      <v-spacer></v-spacer>
      <v-btn color="denial" @click="$emit('cancel')">{{ $t(`btn.cancel`) }}</v-btn>
      <v-btn color="primary" @click="onClickSaveButton">{{ $t(`btn.save`) }}</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-dialog v-model="showScvIdentifyModal" width="450">
      <v-card>
        <v-card-title>{{ $t(`text.scvSetting.scvIdentify`) }}</v-card-title>
        <v-card-text>
          {{ $t('text.scvSetting.scvIdentifyConfirm') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="denial" @click="showScvIdentifyModal = false">{{ $t(`btn.cancel`) }}</v-btn>
          <v-btn color="primary" :disabled="!canPut" @click="onClickScvIdentifyUpdate">{{ $t(`btn.update`) }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
// components
import scvSettingMasterList from './scvSettingMasterList'
// enums
import SCV_DATA_TYPES from '@/enum/scvSetting/SCV_DATA_TYPES'
// util
import enumUtil from '@/utils/enumUtil'
import notifyUtil from '@/utils/notifyUtil'
import draggable from 'vuedraggable'

export default {
  name: 'scvSettingDetail',
  components: {
    draggable,
    scvSettingMasterList,
  },
  mixins: [
    SCV_DATA_TYPES,
    enumUtil,
    notifyUtil,
  ],
  props: {
    scvSetting: {
      type: Object,
      default () {
        return {
          scvColumnId: null,
          scvColumnLogicalName: null,
          scvColumnIndex: null,
          dataType: null,
          isScvIdentify: false,
          isCustom: false,
          scvColumnChoiceSettingList: [],
        }
      },
    },
    selectedDataSetId: {
      type: Number,
    },
    selectedCandidateIndex: {
      type: Number,
    },
    dataSetRowInfoList: {
      type: Array,
      default () {
        return []
      },
    },
  },
  data () {
    return {
      showScvIdentifyModal: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['canPut']),
    formParameter: {
      get () {
        return this.scvSetting
      },
      set (newVal) {
        this.$emit('updateScvSetting', newVal)
      },
    },
    computedSelectedDataSetId: {
      get () {
        return this.selectedDataSetId
      },
      set (newVal) {
        this.$emit('selectDataSetId', newVal)
      },
    },
    isRequest () {
      return this.formParameter.dataType && this.formParameter.scvColumnName && this.formParameter.scvColumnChoiceSettingList.length > 0
    },
    isUpdateMode () {
      if (this.scvSetting.scvColumnId) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    'scvSetting.isSync' (newVal) {
      // TODO: Phase2の隠し機能
      // リアルタイム同期を切り替えるAPIをここで投げる
    },
  },
  methods: {
    onClickDeleteButton (index) {
      this.$emit('removeSetting', index)
    },
    onClickScvIdentifyUpdate () {
      this.$emit('switchScvIdentify')
      this.showScvIdentifyModal = false
    },
    onClickSaveButton () {
      this.$validator.validateAll()
        .then(result => {
          if (!result) return

          this.$emit('addPriority')
          const formParameter = JSON.parse(JSON.stringify(this.scvSetting))
          // バリデート
          const errors = []
          // データ型の異なるカラムを選択していないかチェック
          formParameter.scvColumnChoiceSettingList.forEach(scvColumnChoiceSetting => {
            if (scvColumnChoiceSetting.headerMeta.disabled === true) {
              errors.push(scvColumnChoiceSetting.dataSetName + ': ' + this.$t('notify.error.scvSetting.disabledDataSetCOlumn'))
            }
            scvColumnChoiceSetting.headerMeta.forEach(candidate => {
              candidate.forEach(column => {
                if (formParameter.dataType !== column.dataType) {
                  errors.push(scvColumnChoiceSetting.dataSetName + ': ' + this.$t('notify.error.scvSetting.dataTypeMismatch'))
                }
              })
            })
          })
          if (errors.length > 0) {
            errors.forEach(error => this.notifyErrorMessage(error))
            return
          }
          formParameter.scvColumnChoiceSettingList.forEach((scvColumnChoiceSetting, index) => {
            scvColumnChoiceSetting.headerMeta.forEach((candidate, index) => {
              // 不要なプロパティを削除
              candidate.forEach(column => {
                if (Object.prototype.hasOwnProperty.call(column, 'isScvColumn')) delete column.isScvColumn
              })
              // 設定が空の候補を削除
              if (candidate.length === 0) {
                scvColumnChoiceSetting.headerMeta.splice(index, 1)
              }
            })
          })
          this.$emit('save', formParameter)
        })
    },
    updateCandidateIndex (index) {
      this.$emit('updateCandidateIndex', index)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/theme.scss';
@include theme(v-input--radio-group) using ($integral-core-theme) {
  &::v-deep .v-input__control {
    .v-radio {
      .v-label {
        color: map-deep-get($integral-core-theme, 'views', 'scvSetting', 'scvSettingDetail', 'scvDataType', 'label', 'color') !important;
      }
    }
  }
  &::v-deep .v-input__append-outer {
    color: map-deep-get($integral-core-theme, 'views', 'scvSetting', 'scvSettingDetail', 'scvDataType', 'append', 'color');
  }
}

.v-text-field {
  &::v-deep input {
    padding: 5px 0;
  }
}
.dataType.v-input--radio-group {
  &::v-deep .v-input__control {
    width: auto;
    .v-label {
      font-size: 12px;
      line-height: 100%;
      padding-right: 34px;
    }
    .v-radio {
      .v-input--selection-controls__ripple {
        top: calc(50% - 18px);
        left: -10px;
        width: 22px;
        height: 22px;
      }
      .v-input--selection-controls__input {
        height: auto;
        width: auto;
        .v-icon {
          font-size: 16px;
        }
      }
      .v-label {
        font-size: 14px;
        line-height: 100%;
        padding-right: 0px;
      }
    }
  }
}
.draggableIcon {
  cursor: move;
}
.v-input--switch {
  padding-left: 2px;
  &::v-deep .v-label {
    font-size: 14px;
    top: -2px;
    padding-left: 2px;
  }
}
.v-input--is-readonly {
  &::v-deep .v-input__slot::after {
    border-style: none;
  }
}
.v-list-item {
  .v-list-item__action {
    align-self: start;
    min-width: 20px;
    .v-icon {
      font-size: 20px;
    }
  }
  .v-list-item__icon {
    min-width: 20px;
    .v-icon {
      font-size: 20px !important;
    }
  }
}
</style>
