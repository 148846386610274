export default {
  data () {
    return {
      TRAFFIC_TYPES: {
        DIRECT: { text: '直接流入', alias: '直接流入', value: 1 },
        SEARCH_SITE: { text: '検索サイト', alias: '検索サイトから流入', value: 2 },
        AD: { text: '広告', alias: '広告から流入', value: 3 },
        SNS: { text: 'SNS', alias: 'SNSから流入', value: 4 },
        OTHER: { text: '他社リンク', alias: '他社リンクから流入', value: 5 },
        SELF_SITE: { text: '自サイト', alias: '自サイトから流入', value: 6 },
        HOUSE_SITE: { text: '自社サイト', alias: '自社サイトから流入', value: 7 },
      },
    }
  },
}
